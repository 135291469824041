import react, { useState, useEffect } from "react";
import styled from "styled-components";
import DialogContent from "@mui/material/DialogContent";
import { Close } from "@mui/icons-material";
import { DialogTitle, IconButton } from "@mui/material";
import { API } from "aws-amplify";
import config, { Theme } from "../../../config/aws-exports";
import data from "./coursefeedbacklist.json";
import FeedbackItem from "./FeedbackItem";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import BackDrop from "@material-ui/core/Backdrop/Backdrop";
import { CircularProgress } from "@material-ui/core";
export const StyledGrid = styled.div`
  padding: 1.5rem;
  display: grid;
  grid-template-columns: 350px 100px 100px 130px 100px 100px;
  grid-template-rows: auto;
  grid-row-gap: 0.5rem;
  text-align: center;
  overflow-x: auto;
  .led {
    text-align: left;
  }
  .left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
`;
const quizStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    "& .MuiTypography-body1": {
      flexGrow: "1",
    },
    "& .MuiFormControlLabel-root": {
      marginRight: "0",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  displayFlex: {
    display: "flex",
    alignItems: "center",
    gap: "2rem",
    width: "100%",
    justifyContent: "center",
  },
  flexBasis: {
    flexBasis: "15%",
  },
  textCenter: {
    textAlign: "center",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  container: {
    display: "flex",
    gap: "2rem",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  quizButtonsContainer: {
    display: "flex",
    gap: "2rem",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: "30px",
  },
  input: {
    display: "none",
  },
  uploadBtn: {
    color: "#fff",
    background: "#01498e",
    padding: ".5rem 5rem",
    fontSize: "1.2rem",
    "&:hover": {
      backgroundColor: "#01498e",
      color: "#fff",
      boxShadow: "#fff",
    },
  },
  fontSize: {},
  small: {
    fontSize: "1rem",
  },
}));

function CourseFeedback({ handleCourseFeedBackClose, coursefeedData }) {

  const classes = quizStyles();
  const [answers, setAnswers] = useState([]);
  const [feedbackresult, setFeedbackResult] = useState({});
  const [loading, setLoading] = useState([]);

  useEffect(() => {
    getFeedbackDetails();
  }, []);

  async function getFeedbackDetails() {
    setLoading(true);
    const bodyParam = {
      body: {
        sid: coursefeedData?.tid,
        ur_id: coursefeedData?.ur_id,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/getFeedBack",
        bodyParam
      );
      
      setFeedbackResult(response?.body);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  }

  const handleMultipleRadioChange = (questionNumber, sqi, option, type) => {

    const find = answers.find(
      (ans) => ans.question === questionNumber && ans.subQuestion === sqi + 1
    );
    const newAnswer = {
      type,
      question: questionNumber,
      subQuestion: sqi + 1,
      answer: option,
    };
    
    if (!find) {
      setAnswers((prevAnswers) => [...prevAnswers, newAnswer]);
    } else {
      setAnswers((prevAnswers) => {
        return prevAnswers.map((ans) =>
          ans.question === questionNumber && ans.subQuestion === sqi + 1
            ? { ...ans, answer: option }
            : ans
        );
      });
    }
  };

  const handleRadioChange = (questionNumber, option, type) => {
    const find = answers.find((ans) => ans.question === questionNumber);
    const newAnswer = {
      type,
      question: questionNumber,
      answer: option,
    };
    console.log(find);
    if (!find) {
      setAnswers((prevAnswers) => [...prevAnswers, newAnswer]);
    } else {
      setAnswers((prevAnswers) => {
        return prevAnswers.map((ans) =>
          ans.question === questionNumber ? { ...ans, answer: option } : ans
        );
      });
    }
  };

  const handleTextChange = (questionNumber, value, type) => {
    const find = answers.find((ans) => ans.question === questionNumber);
    const newAnswer = {
      type,
      question: questionNumber,
      answer: value,
    };

    if (!find) {
      setAnswers((prevAnswers) => [...prevAnswers, newAnswer]);
    } else {
      setAnswers((prevAnswers) => {
        return prevAnswers.map((ans) =>
          ans.question === questionNumber ? { ...ans, answer: value } : ans
        );
      });
    }
  };

  return (
    <>
     <BackDrop
        sx={{ color: "#fff" }}
        open={loading}
        className={classes.backdrop}
      >
        <CircularProgress color="inherit" />
      </BackDrop>
      { !loading && <div>
      <DialogTitle id="responsive-dialog-title" sx={{ position: "relative" }}>
        <div style={{ display: 'flex',justifyContent:'space-between' }}>
          <p>User Feedback</p>
          <IconButton
            onClick={() => {
              handleCourseFeedBackClose();
            }}
            sx={{ position: "absolute", right: 10, top: 0 }}
          >
            <Close sx={{ fontSize: "2rem" }} />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          height: '500px',
          overflowY:'auto'
        }}
      >
        {data["course"]["feedbackquestions"].map((questions, fqi) => {
          return (
            <FeedbackItem
              {...questions}
              fqi={fqi}
              handleMultipleRadioChange={handleMultipleRadioChange}
              handleRadioChange={handleRadioChange}
              handleTextChange={handleTextChange}
              feedbackresult={feedbackresult}
            />
          );
        })}
      </DialogContent>
      </div> }
    </>
  );
}

export default CourseFeedback;