// Dependencies imports
import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import Table from "@mui/material/Table";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import { useHistory } from "react-router-dom";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { API } from "aws-amplify";
import Breadcrumb from "../../Breadcrumbs/Breadcrumbs";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../../redux/auth/authSlice";
import Skeleton from "@material-ui/lab/Skeleton";
import MarkPopular from "./MarkPopular";
import conStyle from "./contentAndColleteral.module.scss";
import video from "../../../assets/objects/video.png";
import audio from "../../../assets/objects/audio.png";
import pdf from "../../../assets/objects/pdf.png";
import inter from "../../../assets/objects/inter.png";
import youtube from "../../../assets/objects/youtube.png";
import vimeo from "../../../assets/objects/vimeo.png";
import quiz from "../../../assets/objects/quiz.png";
import html from "../../../assets/objects/html.png";
import scorm from "../../../assets/objects/scorm.png";

// Local imports
import { ReactComponent as Edit } from "../../../assets/icons/mode_black_24dp.svg";
import { ReactComponent as Delete } from "../../../assets/icons/delete_outline_black_24dp.svg";
import { ReactComponent as EditGrey } from "../../../assets/icons/greayEdit.svg";
import { ReactComponent as DeleteGrey } from "../../../assets/icons/greyDelete.svg";
import { Constants } from "../../../config/Constants";
import { CustomButton } from "../../CustomButton/CustomButton";
import AddSpeaker from "./AddSpeaker";
import image1 from "./2.png";
import RegisteredUsers from "./ListOfUsers";
import DeleteSession from "./DeleteSession";
import ListOfTopics from "./listTopics";
import AddTopic from "./AddTopic";
import config from "../../../config/aws-exports";
import AddProgram from "./AddProgram";
import EditProgram from "./EditProgram";
import AddContent from "./AddContent";

import BackDrop from "@material-ui/core/Backdrop/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import ObjectPlayer from "./ObjectPlayer";
import EditContent from "./EditContent";
import Publish from "./publish";

import ContentAndColleteral from "./contentAndColleteral";
import {
  DataGrid,
  GridToolbar,
  GridToolbarContainer,
} from "@material-ui/data-grid";

// Style imports
import contentStyle from "./content.module.scss";

const modal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function CustomToolbar() {
  return (
    <GridToolbarContainer
      style={{ display: "flex", justifyContent: "flex-end" }}
    >
      <GridToolbar />
    </GridToolbarContainer>
  );
}

// Styles for material components
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
    "& .super-app-theme--header": {
      backgroundColor: Constants.blue_color,
      fontSize: "16px",
      color: "rgb(255,255,255)",
      fontFamily: "nunito",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
    cursor: "pointer",
  },
}));

const programNames = {
  color: Constants.blue_color,
  fontSize: "14px",
  fontWeight: "300",
  "&:hover": {
    cursor: "pointer",
    transform: "scale(1.05)",
  },
};

const divprogramNames = {
  width: 80,
  color: Constants.blue_color,
  fontSize: "14px",
  fontWeight: "300",
  "&:hover": {
    cursor: "pointer",
    transform: "scale(1.05)",
  },
};

const header = {
  color: "white",
  fontSize: "14px",
  fontWeight: "bold",
};

export default function Content() {
  const theme = useTheme();
  const history = useHistory();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [openAddLive, setOpenAddLive] = useState(false);
  const [value, setValue] = useState("2");
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [tabledata, setTableData] = useState([]);
  const [tabledataCourse, setTableDataCourse] = useState([]);
  const [openList, setOpenList] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editopen, setEditOpen] = useState(false);
  const [dataToEdit, setDataToEdit] = useState({});
  const [speakerList, setSpeakerList] = useState([]);
  let userDetails = useSelector(authData);
  const dispatch = useDispatch();
  const [contentOpen, setContentOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [contentObjects, setContentObjects] = useState([]);
  const [openPopular, setOpenPopular] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [editOpenObj, setEditObjOpen] = React.useState(false);
  const [oIndex, setOIndex] = useState(0);
  const [curObject, setcurObject] = useState({});
  const curObRef = useRef({});
  const [topicRowData, setTopicRowData] = useState({});
  const [tenants, setTenants] = useState([]);

  useEffect(() => {
    getPrograms();
    getObjects();
    getTopicList();
    breadcrumb();
    getAllTenants();
    objectsTableView();
    return () => {};
  }, [value]);

  async function getPrograms() {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        schema: Constants.SCHEMA_NAME,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log(bodyParam.body);
      const programs = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_PROGRAMS,
        bodyParam
      );
      setPrograms(programs.programs);
      console.log("programs", programs);
      //alert("tenants", JSON.stringify(tenants));
    } catch (error) {
      console.error(error);
    }
  }
  async function getObjects() {
    setLoading(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        admin: true,
        schema: Constants.SCHEMA_NAME,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log(bodyParam.body);
      const objects = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_CONTENT_OBJECT,
        bodyParam
      );

      console.log("objects", objects);
      setContentObjects(objects.rows);
      setLoading(false);
      //alert("tenants", JSON.stringify(tenants));
    } catch (error) {
      console.error(error);
    }
  }
  async function getTopicList() {
    let sdata = { ...userDetails };
    setLoading(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        tid: sdata.categorydata.id,
        admin: true,
        schema: Constants.SCHEMA_NAME,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log(JSON.stringify(bodyParam.body));
      const list = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_CATEGORY_DATA,
        bodyParam
      );
      console.log("tableee", list.courses);
      setTableDataCourse(list.courses);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
    getObjects();
  };
  function handleEditOpen(item) {
    setEditOpen(true);
    setDataToEdit(item);
  }
  /* const handleEditClose = () => {
    setEditOpen(false);
  }; */
  const handleAddLiveOpen = () => setOpenAddLive(true);
  const handleAddLiveClose = () => setOpenAddLive(false);
  const handleOpenPopular = () => setOpenPopular(true);
  const handleClosePopular = () => setOpenPopular(false);

  const handleAddContent = () => {
    let sdata = { ...userDetails };
    let temp = [...sdata.breadcrumb];
    temp[2] = {
      name: "Course Creation",
      path: "/category/content/coursecreation",
    };

    sdata.breadcrumb = temp;
    dispatch(awsSignIn(sdata));
    setContentOpen(true);
  };
  const handleAddContentClose = () => {
    setContentOpen(false);
  };

  function breadcrumb() {
    let sdata = { ...userDetails };
    if (sdata.breadcrumb == undefined) {
      sdata.breadcrumb = [];
      let temp = [...sdata.breadcrumb];
      temp[0] = {
        name: "Category",
        path: "/category",
      };
      temp[1] = {
        name: userDetails.categorydata.name,
        path: "/category/content",
      };
      sdata.breadcrumb = temp;
    } else {
      let temp = [...sdata.breadcrumb];
      temp[0] = {
        name: "Category",
        path: "/category",
      };
      temp[1] = {
        name: "Total Users",
        path: "/category/content",
      };
      sdata.breadcrumb = temp;
    }
  }

  function Tableview() {
    console.log("tabledata" + JSON.stringify(tabledata));
    return (
      <TableBody>
        {(rowsPerPage > 0
          ? programs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          : programs
        ).map((item) => {
          return (
            <TableRow>
              <TableCell style={{ width: "5%", position: "relative" }}>
                {" "}
                <img
                  style={{
                    width: "150px",
                    height: "80px",
                    borderRadius: "10px",
                  }}
                  src={
                    item.timgname === null
                      ? image1
                      : `https://${
                          config.DOMAIN
                        }/${config.aws_org_id.toLowerCase()}-resources/images/program-images/${
                          item.ptimgname
                        }`
                  }
                  alt=""
                ></img>
              </TableCell>
              <TableCell
                style={{ width: "5%" }}
                // onClick={() => onSessionTitleClick(item)}
              >
                <h3 style={programNames}>{item.pname}</h3>
              </TableCell>
              {userDetails.role === "Admin" ? (
                userDetails.role === "Manager" ? null : item.TENANTS ===
                    undefined ||
                  item.TENANTS === null ||
                  item?.TENANTS?.length === 0 ? (
                  <TableCell style={{ width: "5%" }}>
                    <Edit
                      className={contentStyle.icons}
                      onClick={() => handleEditOpen(item)}
                    />
                  </TableCell>
                ) : (
                  <TableCell style={{ width: "5%" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "15px",
                        flexDirection: "column",
                      }}
                    >
                      <EditGrey
                        style={{ cursor: "not-allowed" }}
                        //className={contentStyle.icons}
                        //onClick={() => handleEditOpen(item)}
                        // setDataToEdit(item)
                      />
                    </div>
                  </TableCell>
                )
              ) : null}
            </TableRow>
          );
        })}
      </TableBody>
    );
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //Speaker

  async function objectsTableView() {
    setLoading(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        admin: true,
        schema: Constants.SCHEMA_NAME,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log(bodyParam.body);
      const objects = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_CONTENT_OBJECT,
        bodyParam
      );

      console.log("objects", objects);
      setContentObjects(objects.rows);
      setLoading(false);
      //alert("tenants", JSON.stringify(tenants));

      const Objectslist = objects.rows;
      console.log("list111", Objectslist);
      let trows = [];
      if (Objectslist.length > 0) {
        for (let i = 0; i < Objectslist.length; i++) {
          if (userDetails.categorydata.id == Objectslist[i].cgry_id) {
            let obj = {};
            obj.id = i;
            obj.title = Objectslist[i];
            obj.modified_by = Objectslist[i].modified_by;
            obj.modified_on = moment(
              parseInt(Objectslist[i].modified_on)
            ).format("DD-MM-YYYY");
            obj.publish = Objectslist[i];
            obj.edit = Objectslist[i];
            obj.tags = Objectslist[i].ttags;
            obj.curObj = Objectslist[i];

            trows.push(obj);
          }
        }
      }
      const tcolumns = [
        {
          field: "title",
          headerName: "Object title",
          flex: 1,
          headerClassName: "super-app-theme--header",

          renderCell: (params) => (
            <div
              /*  onClick={() => console.log("hi")} */
              className={conStyle.nuggetObject}
              onClick={() => onClickObject(params.row.curObj)}
            >
              <div className={conStyle.objImg}>
                {renderSwitch(params.value.otype)}
              </div>
              <div className={conStyle.title}>{params.value.oname}</div>
            </div>
          ),
        },
        {
          field: "modified_by",
          headerName: "Modified By",
          flex: 1,
          headerClassName: "super-app-theme--header",
          width: 400,
        },
        {
          field: "modified_on",
          headerName: "Modified On",
          flex: 1,
          headerClassName: "super-app-theme--header",
          width: 400,
        },
        {
          field: "tags",
          headerName: "Tags",
          flex: 1,
          headerClassName: "super-app-theme--header",
          width: 400,
          renderCell: (params) => (
            <div
              style={{
                display: "flex",
                maxWidth: "300px",
                flexWrap: "wrap",
                gap: "10px",
              }}
            >
              {params.value.length === 0 ? (
                <div>No Tags</div>
              ) : (
                params.value.map((item) => (
                  <button
                    style={{
                      maxWidth: "160px",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {item}
                  </button>
                ))
              )}
            </div>
          ),
        },

        {
          field: "publish",
          headerName: "Publish",
          flex: 1,
          headerClassName: "super-app-theme--header",
          disableExport: true,
          renderCell: (params) => (
            <button
              className={
                params.value.tenantid === undefined ||
                params.value.tenantid.length === 0
                  ? conStyle.addbutton
                  : conStyle.addbuttongreen
              }
              onClick={() => {
                handlePublishOpen(params.value);
              }}
            >
              Publish/Unpublish
            </button>
          ),
        },
        {
          field: "edit",
          headerName: "Edit/Delete",
          flex: 1,
          headerClassName: "super-app-theme--header",
          disableExport: true,
          renderCell: (params) => (
            <>
              {params.value.tenantid.length === 0 ? (
                <Edit
                  className={conStyle.icons}
                  onClick={() => onClickObjectEdit(params.row.edit)}
                />
              ) : (
                <EditGrey style={{ cursor: "not-allowed" }} />
              )}
            </>
          ),
        },
      ];
      setRows(trows);
      setColumns(tcolumns);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  }

  function onClickObject(objid) {
    console.log("hiii");
    console.log("jjj", objid);
    let temp = 0;
    /* for (let i = 0; i <= contentObjects.length; i++) {
      if (objid == contentObjects[i].objid) {
        temp = i;
        break;
      }
    } */
    //alert(temp);
    //setOIndex(temp);
    setcurObject(objid);

    curObRef.current = objid;

    setOpen(true);
  }

  function onClickObjectEdit(objid) {
    console.log("ddd", objid);
    let temp = 0;
    /*  for (let i = 0; i <= contentObjects.length; i++) {
      if (objid == contentObjects[i].objid) {
        temp = i;
        break;
      }
    }
    //alert(temp);
    setOIndex(temp); */
    setcurObject(objid);

    curObRef.current = objid;

    setEditObjOpen(true);
  }

  const handleEditClose = () => {
    setEditOpen(false);
  };

  const handleCloseEditObj = () => {
    setEditObjOpen(false);
  };

  function handlePublishOpen(item) {
    item.tenants = item.tenantid;
    setTopicRowData(item);

    setEditOpen(true);
    setDataToEdit(item);
  }

  async function getAllTenants() {
    setLoading(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        schema: Constants.SCHEMA_NAME,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log(JSON.stringify(bodyParam.body));
      const list = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_TENANTS,
        bodyParam
      );

      console.log(JSON.stringify(list));
      setTenants(list.body);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  }
  const handleClose = () => setOpen(false);

  const renderSwitch = (param) => {
    if (param === "video") {
      return <img src={video} style={{ height: "32px" }} />;
    } else if (param === "audio") {
      return <img src={audio} style={{ height: "32px" }} />;
    } else if (param === "quiz") {
      return <img src={quiz} style={{ height: "32px" }} />;
    } else if (param === "html") {
      return <img src={html} style={{ height: "32px" }} />;
    } else if (param === "Interactivity" || param === "interactivity") {
      return <img src={inter} style={{ height: "32px" }} />;
    } else if (param === "pdf") {
      return <img src={pdf} style={{ height: "32px" }} />;
    } else if (param === "youtube") {
      return <img src={youtube} style={{ height: "32px" }} />;
    } else if (param === "vimeo") {
      return <img src={vimeo} style={{ height: "32px" }} />;
    } else if (param === "scorm") {
      return <img src={scorm} style={{ height: "32px" }} />;
    } else {
      return param;
    }
  };
  const handleAddCourse = () => {
    let sdata = { ...userDetails };
    let temp = [...sdata.breadcrumb];
    temp[2] = {
      name: "Course Creation",
      path: "/category/content/coursecreation",
    };

    sdata.breadcrumb = temp;
    dispatch(awsSignIn(sdata));
    history.push("/category/content/coursecreation");
    // setContentOpen(true);
  };
  return (
    <div className={contentStyle.main}>
      <Breadcrumb Bindex={1} />
      <div className={contentStyle.topbar}>
        <h3 className={contentStyle.heading}> </h3>
        {value === "1" ? (
          userDetails.role === "Admin" ? (
            userDetails.role === "Manager" ? null : (
              <div className={contentStyle.buttonholder}>
                <button
                  className={contentStyle.addbutton}
                  onClick={handleAddLiveOpen}
                >
                  Add Program
                </button>
              </div>
            )
          ) : null
        ) : value === "2" ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "30px",
            }}
          >
            <button
              className={contentStyle.addbutton}
              onClick={handleOpenPopular}
            >
              Mark Popular
            </button>

            <button
              className={contentStyle.addbutton}
              onClick={() => handleAddCourse()}
            >
              Add Course
            </button>
          </div>
        ) : value === "3" ? (
          <button className={contentStyle.addbutton} onClick={handleAddContent}>
            Add Content colletral
          </button>
        ) : null}
      </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openAddLive}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openAddLive}>
          <AddProgram
            handleAddLiveClose={handleAddLiveClose}
            getPrograms={getPrograms}
            speakerList={speakerList}
          />
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={contentOpen}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={contentOpen}>
          <AddContent
            handleAddContentClose={handleAddContentClose}
            objectsTableView={objectsTableView}
          />
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={editopen}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={editopen}>
          <EditProgram
            // handleEditClose={handleEditClose}
            dataToEdit={dataToEdit}
            getPrograms={getPrograms}
            speakerList={speakerList}
          />
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openPopular}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openPopular}>
          <MarkPopular
            handleClosePopular={handleClosePopular}
            tabledataCourse={tabledataCourse}
          />
        </Fade>
      </Modal>
      <Paper>
        <div className={contentStyle.tabsholder}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                indicatorColor="primary"
                textColor="inherit"
                variant="fullWidth"
              >
                {/* <Tab label="Programs" value="1" /> */}
                {userDetails.role === "Admin" ? (
                  userDetails.role === "Manager" ? null : (
                    <Tab label="Courses" value="2" />
                  )
                ) : null}
                <Tab label="Content & Collaterals" value="3" />
              </Tabs>
            </Box>

            {/* <TabPanel value="1">
              <TableContainer>
                <Table>
                  <TableHead style={{ background: "#285780" }}>
                    <TableRow>
                      <TableCell style={header}> Image</TableCell>
                      <TableCell style={header}>Program Name</TableCell>
                      <TableCell style={header}>Edit</TableCell>
                    </TableRow>
                  </TableHead>
                  {loading ? null : <Tableview />}
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[
                          5,
                          10,
                          25,
                          { label: "All", value: -1 },
                        ]}
                        colSpan={10}
                        count={tabledata.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: { "aria-label": "rows per page" },
                          native: true,
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </TabPanel> */}
            <TabPanel value="2">
              <ListOfTopics />
            </TabPanel>
            <TabPanel value="3">
              <div style={{ height: "600px" }}>
                {/*   <div style={{ width: "100%" }}>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Skeleton
              variant="rect"
              width="100%"
              height={300}
              style={{ marginBottom: "40px" }}
            />{" "}
          </div>
        ) : (
          <div
            style={{ height: 550, width: "100%", overflowY: "auto" }}
            className={classes.root}
          >
            <TableContainer>
              <Table>
                <TableHead style={{ background: config.platform_main_theme }}>
                  <TableRow>
                    <TableCell style={header}> Title</TableCell>
                    <TableCell style={header}>Last Modified by</TableCell>
                    <TableCell style={header}>Last Modified on</TableCell>
                    <TableCell style={header}>Publish/Unpublish</TableCell>
                    <TableCell style={header}>Edit</TableCell>
                  </TableRow>
                </TableHead>
                {loading ? null : <Tableview />}
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[
                        5,
                        10,
                        25,
                        { label: "All", value: -1 },
                      ]}
                      colSpan={5}
                      count={contentObjects.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: { "aria-label": "rows per page" },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </div>
        )}
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modal}>
          <ObjectPlayer
            handleClose={handleClose}
            oIndex={oIndex}
            setOIndex={setOIndex}
            curObject={curObject}
            setcurObject={setcurObject}
          />
        </Box>
      </Modal>

      <Modal
        open={editOpenObj}
        onClose={handleCloseEditObj}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modal}>
          <EditContent
            handleCloseEditObj={handleCloseEditObj}
            oIndex={oIndex}
            setOIndex={setOIndex}
            curObject={curObject}
            setcurObject={setcurObject}
            getObjects={getObjects}
          />
        </Box>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={editopen}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={editopen}>
          <Publish
            handleEditClose={handleEditClose}
            tenants={tenants}
            topicRowData={topicRowData}
            object={true}
            getObjects={getObjects}
          />
        </Fade>
      </Modal> */}
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={modal}>
                    <ObjectPlayer
                      handleClose={handleClose}
                      oIndex={oIndex}
                      setOIndex={setOIndex}
                      curObject={curObject}
                      setcurObject={setcurObject}
                    />
                  </Box>
                </Modal>

                <Modal
                  open={editOpenObj}
                  onClose={handleCloseEditObj}
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  className={classes.modal}
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                >
                  <Fade in={editOpenObj}>
                    <EditContent
                      handleCloseEditObj={handleCloseEditObj}
                      oIndex={oIndex}
                      setOIndex={setOIndex}
                      curObject={curObject}
                      setcurObject={setcurObject}
                      objectsTableView={objectsTableView}
                    />
                  </Fade>
                </Modal>

                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  className={classes.modal}
                  open={editopen}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                >
                  <Fade in={editopen}>
                    <Publish
                      handleEditClose={handleEditClose}
                      tenants={tenants}
                      topicRowData={topicRowData}
                      object={true}
                      getObjects={objectsTableView}
                    />
                  </Fade>
                </Modal>
                {loading === false ? (
                  <div
                    style={{ height: 600, width: "100%", overflowY: "auto" }}
                    className={classes.root}
                  >
                    <DataGrid
                      rows={rows}
                      columns={columns}
                      className={classes[".MuiDataGrid-root"]}
                      /* components={{
                      Toolbar: CustomToolbar,
                    }} */
                      rowHeight={100}
                      disableSelectionOnClick
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Skeleton variant="rect" width="100%" height={600} />{" "}
                  </div>
                )}
              </div>
            </TabPanel>
          </TabContext>
        </div>
      </Paper>
    </div>
  );
}
