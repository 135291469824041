import { HashRouter as Router, Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { authData } from "./redux/auth/authSlice";
import Login from "./components/pages/Login/login";

import DashboardLayout from "./Layout/DashboardLayout/layout";
import ContentLayout from "./Layout/ContentLayout";
import UspContentLayout from "./Layout/UspContentLayout";
import CategoryLayout from "./Layout/CategoryLayout";
import TenantsLayout from "./Layout/TenantsLayout";
import EngageLayout from "./Layout/EngageLayout";
import EventsLayout from "./Layout/EventsLayout";
import UserRoleLayout from "./Layout/UserRoleLayout";
import ViewTenantLayout from "./Layout/viewTenantLayout";
import UserInfoLayout from "./Layout/userInfoLayout";
import ListTopicsLayout from "./Layout/listTopicsLayout";
import CourseUsersLayout from "./Layout/courseUsersLayout";
import TotalUsersLayout from "./Layout/TotalUsersLayout";
import TotalContentLayout from "./Layout/TotalContentLayout";
import TotalProgramsLayout from "./Layout/TotalProgramsLayout";
import TotalCoursesLayout from "./Layout/TotalCoursesLayout";
import TotalLiveSessionLayout from "./Layout/TotalLiveSessionLayout";
import UsersTodayLayout from "./Layout/UsersTodayLayout";
import SessionsTodayLayout from "./Layout/SessionsTodayLayout";
import ContentTodayLayout from "./Layout/ContentTodayLayout";
import StandAloneCoursesLayout from "./Layout/StandAloneCoursesLayout";
import StandAloneContentLayout from "./Layout/StandAloneContentLayout";
import SessionViewLayout from "./Layout/SessionViewLayout";
import ViewTopicLayout from "./Layout/viewTopicLayout";
import ReportLayout from "./Layout/ReportLayout";
import CourseCreationLayout from "./Layout/CourseCreationLayout";
import UserEngagementLayout from "./Layout/userengagementLayout";
import UsersLayout from "./Layout/UsersLayout";
import UserInfoDashboardLayout from "./Layout/userInfoDasboardLayout";
import AddContentLayout from "./Layout/AddContentLayout";
import ProformaInvoice from "./Layout/ProformaInvoice";
import ObjectUsersLayout from "./Layout/objectUsersLayout";
import TotalAttendanceLayout from "./Layout/TotalAttendanceLayout";
import TotalRevenueLayout from "./Layout/TotalRevenueLayout";
import "./App.scss";
import FeedbackLayout from "./Layout/FeedbackLayout";
function App() {
  const userDetails = useSelector(authData);

  return (
    <div className="App">
      <Router>
        {userDetails === 0 ? (
          <Switch>
            <Route exact path="/">
              <Login />
            </Route>
            <Route exact path="*">
              <Login />
            </Route>
          </Switch>
        ) : (
          <Switch>
            {/* <Route exact path="/">
             { userDetails !== <Login /> }
            </Route> */}
            <Route exact path="/">
              <DashboardLayout />
            </Route>
            <Route exact path="/landingscreen">
              <DashboardLayout />
            </Route>
            <Route exact path="/landingscreen/totalusers">
              <TotalUsersLayout />
            </Route>
            <Route exact path="/landingscreen/totalprograms">
              <TotalProgramsLayout />
            </Route>
            <Route exact path="/landingscreen/totalcourses">
              <TotalCoursesLayout />
            </Route>
            <Route exact path="/landingscreen/totallivesession">
              <TotalLiveSessionLayout />
            </Route>
            <Route exact path="/landingscreen/totalattendance">
              <TotalAttendanceLayout />
            </Route>
            <Route exact path="/landingscreen/totalrevenue">
              <TotalRevenueLayout />
            </Route>
            <Route exact path="/landingscreen/totalcontent">
              <TotalContentLayout />
            </Route>
            <Route exact path="/landingscreen/userstoday">
              <UsersTodayLayout />
            </Route>
            <Route exact path="/landingscreen/todaysession">
              <SessionsTodayLayout />
            </Route>
            <Route exact path="/landingscreen/contenttoday">
              <ContentTodayLayout />
            </Route>
            <Route exact path="/category/content">
              <ContentLayout />
            </Route>
            <Route exact path="/uspcontent">
              <UspContentLayout />
            </Route>
            <Route exact path="/category">
              <CategoryLayout />
            </Route>
            <Route exact path="/uspcontent/sessionView">
              <SessionViewLayout />
            </Route>
            <Route exact path="/uspcontent/coursecreation">
              <CourseCreationLayout />
            </Route>
            <Route exact path="/organization">
              <TenantsLayout />
            </Route>
            <Route exact path="/engage">
              <EngageLayout />
            </Route>
            <Route exact path="/events">
              <EventsLayout />
            </Route>
            <Route exact path="/report">
              <ReportLayout />
            </Route>
            <Route exact path="/report/users">
              <FeedbackLayout />
            </Route>
            <Route exact path="/userRole">
              <UserRoleLayout />
            </Route>
            <Route exact path="/organization/viewTenant">
              <ViewTenantLayout />
            </Route>
            <Route exact path="/organization/viewTenant/userInfo">
              <UserInfoLayout />
            </Route>
            <Route exact path="/listTopics">
              <ListTopicsLayout />
            </Route>
            <Route exact path="/organization/viewTenant/courseusers">
              <CourseUsersLayout />
            </Route>
            <Route exact path="/organization/viewTenant/objectUsers">
              <ObjectUsersLayout />
            </Route>
            <Route exact path="/uspcontent/viewtopic">
              <ViewTopicLayout />
            </Route>
            <Route exact path="/landingscreen/totalcourses/standalonecourses">
              <StandAloneCoursesLayout />
            </Route>
            <Route exact path="/landingscreen/totalcontent/standalonecontent">
              <StandAloneContentLayout />
            </Route>
            <Route exact path="/engage/userengagement">
              <UserEngagementLayout />
            </Route>
            <Route exact path="/users">
              <UsersLayout />
            </Route>
            <Route exact path="/addcontent">
              <AddContentLayout />
            </Route>
            <Route exact path="/proformaInvoice">
              <ProformaInvoice />
            </Route>
            <Route exact path="/landingscreen/userInfo">
              <UserInfoDashboardLayout />
            </Route>
          </Switch>
        )}
      </Router>
    </div>
  );
}

export default App;
