import React from "react";
import SideNav from "../components/SideNav/sideNav";
import Header from "../components/Header/hearder";
import { Wrapper } from "./layout.styles";
import TotalAttendance from "../components/pages/Dashboard/TotalAttendance";


const TotalAttendanceLayout = () => {
  return (
    <React.Fragment>
      <Header />
      <Wrapper>
        <SideNav />
        {/* <div style={{margin: "70px 0px 0px 200px", width: "100%"}}>
        <TotalUsers />
        </div> */}
        <div style={{ margin: "70px 0px 0px 200px", width: "100%" }}>

        <TotalAttendance/>
        </div>
      </Wrapper>
    </React.Fragment>
  );
};

export default TotalAttendanceLayout;
