// Dependencies imports
import React, { useState } from "react";
import swal from "sweetalert";
import Paper from "@material-ui/core/Paper";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { Constants } from "../../../config/Constants";
import config from "../../../config/aws-exports";
import { API } from "aws-amplify";
import { useSelector } from "react-redux";
import { authData } from "../../../redux/auth/authSlice";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

// Local imports

// Styles imports
import Add from "./DeleteEventTenant.module.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
  },
}));

const DeleteUser = (props) => {
  let { handleDeleteClose , deleteData, getUsers } = props;
  const [userNameErr, setUserNameErr] = useState("");
  const [lastNameErr, setLastNameErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [pnoErr, setPnoErr] = useState("");
  const [genderErr, setGenderErr] = useState("");
  const [spin, setSpin] = useState(false);
  const classes = useStyles();
  const [initialuid, setInitialUid] = useState(deleteData.ur_id);
  const [initialTenant, setInitialTenant] = useState(deleteData.tenant);
  const [userName, setUserName] = useState(deleteData.firstname);
  const [lastName, setLastName] = useState(deleteData.lastname);
  const [email, setEmail] = useState(deleteData.email);
  const [pno, setPno] = useState(deleteData.cno);
  const [gender, setGender] = useState(deleteData.gen);
  const [address, setAddress] = useState(deleteData.address);
  const [urole, setUrole] = useState(deleteData.urole);
  const [loading, setLoading] = useState(false);
  // alert("1"+JSON.stringify(editEventData))

  let userDetails = useSelector(authData);
  console.log("deleteData", deleteData);

  const onsubmit = () => {
    deleteUser();
  };

  async function deleteUser() {
    setSpin(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        ur_id: initialuid,
        schema: Constants.SCHEMA_NAME,
        atype: 2
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.ADD_USER,
        bodyParam
        //`${Constants.GET_MY_PROGRAMS}`, bodyParam,
      );
      // const users = response;
      handleDeleteClose();
      getUsers();
      swal({
        title: "Success",
        text: response.message,
        icon: "success",
        dangerMode: false,
      }).then((willDelete) => {
        if (willDelete) {
            handleDeleteClose();
            getUsers();
        }
      });
      setSpin(false);
    } catch (error) {
      swal({
        title: "Oooops!",
        text: "Soomthing wrong!",
        icon: "warning",
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          setSpin(false);
        }
      });
      console.log("getUserError", error);
    }
    console.log("Mount");
  }

  return (
    <div>
      {spin === false ? (
        <div className={Add.mainpopup} style={{ width: "100%" }}>
          {/* <h3 className={Add.header}>Delete Event</h3> */}
          <div className={Add.form}>
            <form>
              <div className={Add.header}>
                Are you sure you want to delete ?
              </div>
              <div
                className={Add.buttonholder}
                style={{
                  display: "flex",
                  gap: "50px",
                  justifyContent: "center",
                  padding: "10px",
                }}
              >
                <button
                  className={Add.buttonno}
                  onClick={() => handleDeleteClose()}
                >
                  No
                </button>
                <button
                  className={Add.buttonyes}
                  //style={{ background: "orange" }}
                  onClick={onsubmit}
                >
                  Yes
                </button>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <div className={classes.root}>
          <CircularProgress style={{ color: Constants.main_color_1 }} />
        </div>
      )}
    </div>
  );
};

export default DeleteUser;