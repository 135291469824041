//Dependencies imports
import React from "react";


// Local imports
import {ReactComponent as Close} from "../../../assets/icons/close_black_24dp.svg"
import config from "../../../config/aws-exports";

// Style imports
import Speaker from './SpeakerView.module.scss';


const SpeakerView = (props) => {
const {handleClose, speakerData} = props;
console.log("speakerData",speakerData);

    return(
        <div className={Speaker.main}>
            <div className={Speaker.topbar}>
                <Close className={Speaker.closeicon} onClick={handleClose} />
            </div>
            <div className={Speaker.firstblock}>
                <div className={Speaker.leftblock}>
                    <img 
                    alt=""
                    src={`https://${
                      config.DOMAIN
                    }/${config.aws_org_id.toLowerCase()}-resources/images/speaker-images/${
                      speakerData.timgname
                    }`}
                    style={{ width: "150px", height: "120px", borderRadius: "10px" }}
                    />
                </div>
                <div className={Speaker.rightblock}>
                    <h3 className={Speaker.speakername}>{speakerData.name}</h3>
                    <h4 className={Speaker.speakerdesig}>{speakerData.designation}</h4>
                </div>
            </div>
            <div className={Speaker.bottombar}>
                <p>{speakerData.bio}</p>
            </div>
            
        </div>
    )
};

export default SpeakerView;