// Dependencies imports
import React, { useState, useEffect } from "react";
import {
  DataGrid,
  GridToolbar,
  GridToolbarContainer,
} from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import Paper from "@mui/material/Paper";
import { API } from "aws-amplify";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

// Local imports
import { Constants } from "../../../config/Constants";
import SendNotification from "./SendNotification";
import config from "../../../config/aws-exports";
import { awsSignIn, authData } from "../../../redux/auth/authSlice";

// Style imports
import Eng from "./Engage.module.scss";

// Custom Toolbard to export and filter for datagrid
function CustomToolbar() {
  return (
    <GridToolbarContainer
      style={{ display: "flex", justifyContent: "flex-end" }}
    >
      <GridToolbar />
    </GridToolbarContainer>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& .super-app-theme--header": {
      backgroundColor: Constants.blue_color,
      fontSize: "16px",
      color: "rgb(255,255,255)",
      fontFamily: "nunito",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
    cursor: "pointer",
  },
}));

// Start of main Engage Component
export default function Engage() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [tenantsnotify, setTenantsnotify] = useState({});
  const [rows, setRows] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [spin, setSpin] = useState(false);
  const [tenantslist, setTenantsList] = useState([]);
  const [singleUser, setSingleUser] = useState(false);
  let userDetails = useSelector(authData);
  const [tenantname, setTenantName] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();

  // Useeffect for api call
  useEffect(() => {
    getTenants();
    return () => {};
  }, []);

  // function to get Tenants list
  async function getTenants() {
    setSpin(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        schema: Constants.SCHEMA_NAME
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log(bodyParam.body);
      const ten = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_TENANTS,
        bodyParam
      );
      setTenantsList(ten.body);
      if (ten !== undefined && ten !== null) {
        const tenants = ten.body;
        let trows = [];
        for (let i = 0; i < tenants.length; i++) {
          let obj = {};
          obj.id = i;
          obj.tenant = tenants[i].did;
          trows.push(obj);
        }
        setRows(trows);
        setSpin(false);
      }
    } catch (error) {
      console.error(error);
      setSpin(false);
    }
  }

  const tcolumns = [
    {
      field: "tenant",
      headerName: "Organization Name",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
  ];

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCellClick = (param, event) => {
    console.log("row data on click", tenantslist[param.row.id].oid);
    let sdata = { ...userDetails };
    sdata.BOID = tenantslist[param.row.id].oid;
    if (sdata.breadcrumb == undefined) {
      sdata.breadcrumb = [];
      let temp = [...sdata.breadcrumb];
      temp[0] = {
        name: "Engage",
        path: "/engage",
      };
      temp[1] = {
        name: param.row.tenant,
        path: "/engage/userengagement",
      };
      sdata.breadcrumb = temp;
    } else {
      let temp = [...sdata.breadcrumb];
      temp[0] = {
        name: "Engage",
        path: "/engage",
      };
      temp[1] = {
        name: param.row.tenant,
        path: "/engage/userengagement",
      };
      sdata.breadcrumb = temp;
    }

    dispatch(awsSignIn(sdata));

    history.push("/engage/userengagement");
    // console.log(event);
  };
  function checking(index1) {
    let rowObj = {};
    let rowObjName = {};
    let tenantlist = [];
    let tenentname = [];

    console.log("batch", index1);
    for (let i = 0; i < index1.length; i++) {
      rowObj = tenantslist[index1[i]].st_id;
      rowObjName = tenantslist[index1[i]].oid;
      tenantlist.push(rowObj);
      tenentname.push(rowObjName);
    }

    console.log("batch1", tenantlist);
    setTenantName(tenentname);
    setTenantsnotify(tenantlist);
  }
  // Start of main return
  return (
    <div className={Eng.main}>
      <Backdrop className={classes.backdrop} open={spin}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <SendNotification
            handleClose={handleClose}
            tenantsnotify={tenantsnotify}
            userList={false}
            singleUser={singleUser}
            tenantname={tenantname}
          />
        </Fade>
      </Modal>
      <div className={Eng.topbar}>
        {/* <h2 className={Eng.heading}>Engage</h2> */}
        {selectionModel !== undefined && selectionModel.length !== 0 ? (
          <button className={Eng.send} onClick={() => handleOpen()}>
            Send Notification
          </button>
        ) : null}
      </div>
      <Paper>
        <div
          style={{ height: 600, width: "100%", overflowY: "auto" }}
          className={classes.root}
        >
          <DataGrid
            rows={rows}
            columns={tcolumns}
            className={classes[".MuiDataGrid-root"]}
            components={{
              Toolbar: CustomToolbar,
            }}
            checkboxSelection
            disableSelectionOnClick
            onRowSelected={(e) => console.log(e)}
            onSelectionModelChange={(newSelection) => {
              console.log("j", newSelection);
              setSelectionModel(newSelection);
              checking(newSelection);
            }}
            selectionModel={selectionModel}
            onCellClick={handleCellClick}
          />
        </div>
      </Paper>
    </div>
  );
}
