import React, { useState, useRef, useEffect } from "react";
import { CustomButton } from "../../CustomButton/CustomButton";
import Button from "@mui/material/Button";

import AddSpeakerStyle from "./AddSpeaker.module.scss";

import { styled } from "@mui/system";
import swal from "sweetalert2";
import { Constants } from "../../../config/Constants";

import axios from "axios";
import config from "../../../config/aws-exports";
import { API } from "aws-amplify";
import { ReactComponent as Close } from "../../../assets/icons/close_black_24dp.svg";
import BackDrop from "@material-ui/core/Backdrop/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
}));

const EditSpeaker = (props) => {
  let {
    handleEditSpeakersClose,
    dataToEditS,
    setpeakerTable,
    setLoading,
    loading,
  } = props;
  const classes = useStyles();

  const [speakerNameErr, setSpeakerNameErr] = useState(false);
  const [speakerPicErr, setSpeakerPicErr] = useState(false);
  const [speakerBioErr, setSpeakerBioErr] = useState(false);
  const [speakerDesErr, setSpeakerDesErr] = useState(false);
  const [speakerimg, setSpeakerimg] = useState(null);
  const [speakerFileName, setSpeakerFileName] = useState(null);
  const [userName, setUserName] = useState("");
  const [designation, setDesignation] = useState("");
  const [biography, setBiography] = useState("");
  const [imageChange, setImageChange] = useState(false);
  const refselectedSpeakerFile = useRef();
  //const [spin, setSpin] = useState(false);

  console.log("dataTo", dataToEditS);
  useEffect(() => {
    setUserName(dataToEditS.sp_name);
    setDesignation(dataToEditS.designation);
    setBiography(dataToEditS.bio);

    setSpeakerimg(dataToEditS.oimgname);
  }, []);
  function handleSave() {
    console.log("hello");
    console.log(userName, "df");
    if (userName === "" || userName.replace(/\s/g, "").length === 0) {
      setSpeakerNameErr(true);
      setSpeakerPicErr(false);
      setSpeakerBioErr(false);
      setSpeakerDesErr(false);
    } /* else if (subject === "" || subject.replace(/\s/g, "").length === 0) {
      setSpeakerNameErr(false);
      setSpeakerPicErr(true);
      setSpeakerBioErr(false);
      setSpeakerDesErr(false);
    }  */ else if (
      designation === "" ||
      designation.replace(/\s/g, "").length === 0
    ) {
      setSpeakerNameErr(false);
      setSpeakerPicErr(false);
      setSpeakerBioErr(false);
      setSpeakerDesErr(true);
    } else if (biography === "" || biography.replace(/\s/g, "").length === 0) {
      setSpeakerNameErr(false);
      setSpeakerPicErr(false);
      setSpeakerBioErr(true);
      setSpeakerDesErr(false);
    } else {
      setSpeakerNameErr(false);
      setSpeakerPicErr(false);
      setSpeakerBioErr(false);
      setSpeakerDesErr(false);
      // setSpin(true);
      let obj = {};
      obj.name = userName;
      obj.designation = designation;
      obj.bio = biography;
      // alert(JSON.stringify(obj));
      if (imageChange === true) {
        handleimageUpload();
      } else {
        addSpeaker();
      }
    }
  }
  const Input = styled("input")({
    display: "none",
  });

  const handleimage = (event) => {
    setImageChange(true);
    setSpeakerimg({ file: event.target.files[0] });
    refselectedSpeakerFile.current = event.target.files[0];
    //console.log("image", image);
  };

  function handleimageUpload() {
    if (speakerimg.file == null) {
      return;
    }

    if (speakerimg.file.size > 5242880) {
      swal(
        "Oops!",
        "Image size is too large. File size cannot be greater than 5mb.",
        "error"
      );
      return;
    }
    if (speakerimg.file !== undefined) {
      let uniqueStamp = new Date().getTime();
      setSpeakerFileName(uniqueStamp + ".png");
      let imageName = uniqueStamp + ".png";
      var body = {
        type: "speakerimage",
        oid: config.aws_org_id,
        filetype: refselectedSpeakerFile.current.type,
        filename: imageName,
      };

      console.log("bodyImg", body);
      uploadfile(body, refselectedSpeakerFile.current);
      addSpeaker(imageName);
    }
  }
  async function uploadfile(body, file) {
    //setIstLoading(true);
    const bodyParam = {
      body,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const preSignedData = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_PRESIGNED_URL,
        /* "/getpreSignedURL", */
        bodyParam
      );

      axios
        .put(preSignedData, file, {
          headers: {
            "Content-Type": file.type,
            "Cache-Control": "no-cache no-store must-revalidate",
          },
        })
        .then((response) => {
          console.log("file upload success");
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
      //setIstLoading(false);
    } catch (error) {
      // alert(JSON.stringify(error));
      console.error(error);
    }
  }

  const addSpeaker = async (imageName) => {
    setLoading(true);
    let uniqueStamp = new Date().getTime();
    /*     let imageObj = {};
    if (imageChange === true) {
      imageObj.image = {
        timestampname: imageName,
        filename: speakerimg.file.name,
      };
    } else {
      imageObj.image = {
        timestampname: dataToEditS.image.timestampname,
        filename: dataToEditS.image.filename,
      };
    } */

    const bodyParam = {
      body: {
        sp_id: dataToEditS.sp_id,
        sp_name: userName,
        designation: designation,
        bio: biography,
        timgname: imageChange ? imageName : dataToEditS.timgname,
        oimgname: imageChange ? speakerimg.file.name : dataToEditS.oimgname,
        mt_id: 1,
        atype: 1,
        schema: Constants.SCHEMA_NAME,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.UPDATE_SPEAKER,
        bodyParam
      );
      handleEditSpeakersClose();
      setpeakerTable();
      swal
        .fire({
          title: "Success",
          text: "Speaker Edited successfully",
          icon: "success",
          dangerMode: false,
        })
        .then((willDelete) => {
          if (willDelete) {
            setLoading(false);
            setpeakerTable();
          }
        });
    } catch (error) {
      swal
        .fire({
          title: "Oooops!",
          text: "Soomthing wrong!",
          icon: "warning",
          dangerMode: true,
        })
        .then((willDelete) => {
          if (willDelete) {
            setLoading(false);
          }
        });
      console.log("getUserError", error);
    }
  };

  return (
    <div className={AddSpeakerStyle.maindiv}>
      <div className={AddSpeakerStyle.maincontainer}>
        <BackDrop
          sx={{ color: "#fff" }}
          open={loading}
          className={classes.backdrop}
        >
          <CircularProgress color="inherit" />
        </BackDrop>
        <div className={AddSpeakerStyle.closeholder}>
          <h3 className={AddSpeakerStyle.heading}>Edit Speaker</h3>
          <Close
            className={AddSpeakerStyle.closeicon}
            onClick={handleEditSpeakersClose}
          />
        </div>
        <div className={AddSpeakerStyle.field}>
          <label
            style={{
              fontSize: "16px",
              fontWeight: "500px",
              fontFamily: "nunito",
            }}
            for="name"
            required
          >
            Name
          </label>
          <input
            style={{
              border: "none",
              width: "73%",
              boxShadow: "0px 0px 5px 0px lightgrey ",
              background: "white",
              height: "30px",
              marginBottom: "20px",
              fontSize: "15px",
              paddingLeft: "10px",
              fontWeight: "200px",
            }}
            type="text"
            id="name"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            required
          />
        </div>
        <div
          style={{
            color: "#cc0000",
            marginBottom: "12px",
            marginTop: "-10px",
            textAlign: "start",
            marginLeft: "30%",
            fontSize: "12px",
          }}
        >
          {speakerNameErr ? "Please enter name" : null}
        </div>
        <div className={AddSpeakerStyle.field}>
          <label
            style={{
              fontSize: "16px",
              fontWeight: "500px",
              fontFamily: "nunito",
            }}
            for="name"
            required
          >
            Image
          </label>

          <div
            style={{
              height: "150px",
              width: "73%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              border: "1px dashed #000",
              marginBottom: "40px",
            }}
          >
            <label htmlFor="contained-button-file">
              <Input
                accept="image/*"
                id="contained-button-file"
                multiple
                type="file"
                onChange={(e) => handleimage(e)}
              />
              <span className={AddSpeakerStyle.savebutton}>Upload Image</span>
              <h5>
                {" "}
                {imageChange === true
                  ? speakerimg !== null && speakerimg.file !== undefined
                    ? speakerimg.file.name
                    : ""
                  : speakerimg !== null && speakerimg !== undefined
                  ? speakerimg
                  : ""}
              </h5>
            </label>
          </div>
        </div>
        <div
          style={{
            color: "#cc0000",
            marginBottom: "12px",
            marginTop: "-10px",
            textAlign: "start",
            marginLeft: "30%",
            fontSize: "12px",
          }}
        >
          {speakerPicErr ? "Please upload a image" : null}
        </div>
        <div className={AddSpeakerStyle.field}>
          <label
            style={{
              fontSize: "16px",
              fontWeight: "500px",
              fontFamily: "nunito",
            }}
            for="role"
          >
            Designation
          </label>
          <textarea
            style={{
              border: "none",
              width: "73%",
              boxShadow: "0px 0px 5px 0px lightgrey",
              background: "white",
              height: "70px",
              marginBottom: "20px",
              fontSize: "15px",
              paddingLeft: "10px",
              fontWeight: "200px",
            }}
            id="address"
            rows="10"
            value={designation}
            onChange={(e) => setDesignation(e.target.value)}
          />
        </div>

        <div
          style={{
            color: "#cc0000",
            marginBottom: "12px",
            marginTop: "-10px",
            textAlign: "start",
            marginLeft: "30%",
            fontSize: "12px",
          }}
        >
          {speakerDesErr ? "please enter speaker designation" : null}
        </div>
        <div className={AddSpeakerStyle.field}>
          <label
            style={{
              fontSize: "16px",
              fontWeight: "500px",
              fontFamily: "nunito",
            }}
            for="role"
          >
            Biography
          </label>
          <textarea
            style={{
              border: "none",
              width: "73%",
              boxShadow: "0px 0px 5px 0px lightgrey",
              background: "white",
              height: "120px",
              marginBottom: "20px",
              fontSize: "15px",
              paddingLeft: "10px",
              fontWeight: "200px",
            }}
            id="address"
            rows="10"
            value={biography}
            onChange={(e) => setBiography(e.target.value)}
          />
        </div>
        <div
          style={{
            color: "#cc0000",
            marginBottom: "12px",
            marginTop: "-10px",
            textAlign: "start",
            marginLeft: "30%",
            fontSize: "12px",
          }}
        >
          {speakerBioErr ? "please enter speaker biography" : null}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
            padding: "0px 20px",
          }}
        >
          <button
            onClick={handleEditSpeakersClose}
            style={{ float: "right" }}
            className={AddSpeakerStyle.closebutton}
          >
            Close
          </button>
          <button
            className={AddSpeakerStyle.savebutton}
            onClick={() => {
              handleSave();
            }}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditSpeaker;
