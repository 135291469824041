// Dependencies imports
import React, { useEffect, useState } from "react";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import { API } from "aws-amplify";
import { useSelector, useDispatch } from "react-redux";
import swal from "sweetalert";
import BackDrop from "@material-ui/core/Backdrop/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

// Local imports
import config from "../../../config/aws-exports";
import { Constants } from "../../../config/Constants";
import { awsSignIn, authData } from "../../../redux/auth/authSlice";

// Style imports
import Assign from "./AssignUsers.module.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
  formControl: {
    border: "1px solid #6a6a6a",
    height: "fit-content",
    width: "100%",
    borderRadius: "5px",
    fontSize: "14px",
    fontFamily: "nunito",
    padding: "5px",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  program: {
    fontSize: "14px",
    color: "black",
    fontWeight: "400",
    paddingLeft: "5px",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AssignUsers = ({ getSessionList, handleCloseAssign, assignData }) => {
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [userName, setUserName] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [spin, setSpin] = useState(true);
  
  const [displayName, setDisplayName] = useState([]);
  let userDetails = useSelector(authData);
  console.log("userDetails", userDetails);

  useEffect(() => {
    getUsers();
    console.log("assigndata", assignData);
  }, []);

  console.log("users", users);
  console.log("userName", userName);
  console.log("selectdUsers", selectedUsers);

  async function getUsers() {
    setSpin(true);
    const bodyParam = {
      body: {
        oid: userDetails.locale,
        schema: Constants.SCHEMA_NAME,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log(bodyParam.body);
      const userData = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_USERS,
        bodyParam
      );
      const userDetail = userData.body;
      let users = [];
      for (let i=0; i< userDetail.length ; i++){
        if(userDetail[i].ur !== "Manager"){
          users.push(userDetail[i]);
        }
      };
      setUsers(users);
      getAssignedUsers(users);  

      //alert("tenants", JSON.stringify(tenants));
    } catch (error) {
      console.error(error);
    }
  }


  async function getAssignedUsers(users) {
    setSpin(true);
    const bodyParam = {
      body: {
        sid: assignData.sid
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log(bodyParam.body);
      const assigned = await API.post(
        config.aws_cloud_logic_custom_name,
        '/getAssignedUsers',
        bodyParam
      );
      let temparr = [];
      let assignedUsers = assigned.body;
      for (let i = 0; i < users.length; i++) {
      let obj = {};
      obj.emailid = users[i].emailid;
      
      for (let k = 0; k < assignedUsers.length; k++) {
        if (obj.emailid === assignedUsers[k]) {
          temparr.push(users[i]);
        }
      }

      
      
    }

    let listArray = [];
    for(let i=0; i<users.length; i++){
    if(!assignedUsers.includes(users[i].emailid)){
      listArray.push(users[i])
    }
    }
    setFiltered(listArray);
    setSelectedUsers(temparr);
    setUserName(assignedUsers);
    setSpin(false);
    } catch (error) {
      console.error(error);
      setSpin(false);
    }
  }

  const assignUsers = async () => {
    setSpin(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        tenant: userDetails.locale,
        Memail: userDetails.email,
        ulist: selectedUsers,
        sid: assignData.sid,
        schema: Constants.SCHEMA_NAME,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.BULK_SESSION_REGISTRATION,
        bodyParam
      );
      handleCloseAssign();
      getSessionList();
      swal({
        title: "Success",
        text: "Users Assigned successfully",
        icon: "success",
        dangerMode: false,
        closeOnClickOutside: false,
      }).then((willDelete) => {
        if (willDelete) {
          setSpin(false);
        }
      });
    } catch (error) {
      handleCloseAssign();
      swal({
        title: "Oooops!",
        text: "Soomthing wrong!",
        icon: "warning",
        dangerMode: true,
        closeOnClickOutside: false,
      }).then((willDelete) => {
        if (willDelete) {
          setSpin(false);
        }
      });
      console.log("getUserError", error);
    }
  };

  const handleChange = (event) => {
    console.log("multi" + JSON.stringify(event.target.value));
    let selectArray = event.target.value;
    let temparr = [];
    for (let i = 0; i < users.length; i++) {
      let obj = {};
      obj.emailid = users[i].emailid;
      for (let k = 0; k < selectArray.length; k++) {
        if (obj.emailid === selectArray[k]) {
          temparr.push(users[i]);
        }
      }
    }
    setSelectedUsers(temparr);
    setUserName(event.target.value);
    // setProgramName(prn)
  };

  return (
    <div className={Assign.mainpopup}>
      <BackDrop sx={{ color: "#fff" }} open={spin} className={classes.backdrop}>
        <CircularProgress color="inherit" />
      </BackDrop>
      <h3 className={Assign.heading}>Assign Users</h3>
      {assignData.paid === "false" ? null : 
      <div className={Assign.slotsholder}>
        <h4 className={Assign.total}>Total Slots Remaining:</h4>
        <h5 className={Assign.count}>{assignData.tslots}</h5>
      </div>}
      <div className={Assign.userselectholder}>
        <FormControl className={classes.formControl}>
          <InputLabel
            id="demo-mutiple-checkbox-label"
            className={classes.program}
          >
            Select Users
          </InputLabel>

          <Select
            labelId="demo-mutiple-checkbox-label"
            id="demo-mutiple-checkbox"
            multiple
            value={userName}
            onChange={handleChange}
            input={<Input />}
            renderValue={(selected) => {
              selected.join(", ");
              let temp = ["Select Users"];
              for (let i = 0; i < selected.length; i++) {
                // temp.push(selected[i]);
              }
              return temp;
            }}
            MenuProps={MenuProps}
          >
            {filtered.map((item) => (
              <MenuItem key={item} value={item.emailid}>
                <Checkbox key={item} checked={userName.indexOf(item.emailid) > -1} />
                <ListItemText key={item} primary={item.first_name + " " + item.last_name + ": " + item.emailid} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <h3 style={{ fontSize: "14px" }}>Selected Users</h3>
      <div
        style={{
          textAlign: "start",
          fontSize: "14px",
          height: "150px",
          overflowY: "scroll",
        }}
      >
        <ol>
          {selectedUsers.map((item) => (
            <li key={item} style={{ paddingTop: "5px" }}>{item.first_name + " " + item.last_name + ": " + item.emailid}</li>
          ))}
        </ol>
      </div>
      <div className={Assign.buttonholder}>
        <button className={Assign.cancel} onClick={handleCloseAssign}>
          Cancel
        </button>
        <button className={Assign.submit} onClick={() => assignUsers()}>
          Submit
        </button>
      </div>
    </div>
  );
};

export default AssignUsers;
