import React, { useState, useEffect } from "react";
import userInfoStyle from "./userInfoDashboard.module.scss";
import Avatar from "@mui/material/Avatar";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import timer from "../../../assets/images/timer.png";
import inProgress from "../../../assets/images/inprogress.png";
import completed from "../../../assets/images/tcompleted.png";
import badge from "../../../assets/images/badges.png";
import Rating from "@mui/material/Rating";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import BackDrop from "@material-ui/core/Backdrop/Backdrop";
import {
  DataGrid,
  GridToolbar,
  GridToolbarContainer,
} from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/core/styles";
//import UserProgress from "./UserProgress";
import { useSelector, useDispatch } from "react-redux";
import image1 from "./2.png";
import { awsSignIn, authData } from "../../../redux/auth/authSlice";
import { Constants } from "../../../config/Constants";
import config from "../../../config/aws-exports";
import { API } from "aws-amplify";
import Breadcrumb from "../../Breadcrumbs/Breadcrumbs";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getNativeSelectUtilityClasses } from "@mui/material";
import UserProgress from '../Tenants/UserProgress';
import video from "../../../assets/objects/video.png";
import audio from "../../../assets/objects/audio.png";
import pdf from "../../../assets/objects/pdf.png";
import inter from "../../../assets/objects/inter.png";
import youtube from "../../../assets/objects/youtube.png";
import vimeo from "../../../assets/objects/vimeo.png";
import quiz from "../../../assets/objects/quiz.png";
import html from "../../../assets/objects/html.png";

const useStyles = makeStyles((theme) =>({
  root: {
    "& .super-app-theme--header": {
      backgroundColor: Constants.blue_color,
      fontSize: "16px",
      color: "rgb(255,255,255)",
      fontFamily: "nunito",
    },
    "& .MuiDialog-root": {
      width: 1000,
    },
  },
  "& .MuiDialog-paper": {
    maxWidth: "none",
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
    cursor: "pointer",
  },
}));

function CustomToolbar() {
  return (
    <GridToolbarContainer
      style={{ display: "flex", justifyContent: "flex-end" }}
    >
      <GridToolbar />
    </GridToolbarContainer>
  );
}
export default function UserInfoDashboard() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [crows, setCrows] = useState([]);
  const [reportInfo, setReportInfo] = useState();
  const [userData, setUserData] = useState();
  const [spin, setSpin] = useState(true);
  const [completedCount, setCompletedCount] = useState(0);
  const [registeredCount, setRegisteredCount] = useState(0);
  const [value, setValue] = useState("1");
  let userDetails = useSelector(authData);
  console.log("datauser", userDetails);
  const dispatch = useDispatch();

  useEffect(() => {
    getUserReport();
    breadcrumb();
    return () => {};
  }, []);

  async function getUserReport() {
    setSpin(true);
    const bodyParam = {
      body: {
        ur_id: userDetails.user.ur_id,
        schema: Constants.SCHEMA_NAME,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log(bodyParam.body);
      const reportInfo = await API.post(
        config.aws_cloud_logic_custom_name,
        "/getUserProgressInfo",
        bodyParam
      );
      setRegisteredCount(reportInfo.body.regcount);
      setCompletedCount(reportInfo.body.completed);
      mapTableData(reportInfo.body.registered);
      setSpin(false);
      // getContentReport()
    } catch (error) {
      console.error(error);
    }
  };


  function mapTableData(value) {
    let trows = [];
    for (let i = 0; i < value.length; i++) {
      let obj = {};
      obj.id = i;
      obj.col1 = value[i].timgname;
      obj.col2 = value[i].sn;
      obj.col3 = value[i].attend === null ? "Registered" : "Completed";
      trows.push(obj);
    }
    setRows(trows);
  }

  const tcolumns = [
    {
      field: "col1",
      headerName: "Session Image",
      flex: 1,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <img
          style={{
            width: "100px",
            height: "auto",
            borderRadius: "10px",
          }}
          src={
            params.value === null
              ? image1
              : `https://${
                  config.DOMAIN
                }/${config.aws_org_id.toLowerCase()}-resources/images/session-images/${
                  params.value
                }`
          }
          alt=""
        ></img>
      ),
    },
    {
      field: "col2",
      headerName: "Session Name",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "col3",
      headerName: "Status",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
  ];

  function breadcrumb() {
    let sdata = { ...userDetails };
    let temp = [...sdata.breadcrumb];

    temp[2] = {
      name: userDetails.user.firstname + " " + userDetails.user.lastname,
      path: "/landingscreen/userInfo",
    };

    sdata.breadcrumb = temp;

    dispatch(awsSignIn(sdata));
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={userInfoStyle.main}>
      <BackDrop
        sx={{ color: "#fff" }}
        open={spin}
        className={classes.backdrop}
      >
        <CircularProgress color="inherit" />
      </BackDrop>
      <Breadcrumb Bindex={2} />
      <div>
        <Paper elevation={0}>
          <div className={userInfoStyle.infoPaper}>
            <Avatar style={{ width: "100px", height: "100px" }} />
            <div className={userInfoStyle.coursecardaddress}>
              {userDetails.user.firstname === undefined ||
              userDetails.user.firstname === "" ? (
                <div></div>
              ) : (
                <h3 className={userInfoStyle.username}>
                  {userDetails.user.firstname + " " + userDetails.user.lastname}
                </h3>
              )}
              {userDetails.user.col2 === undefined ||
              userDetails.user.col2 === "" ? (
                <div></div>
              ) : (
                <h4 className={userInfoStyle.useremail}>
                  {userDetails.user.col2}
                </h4>
              )}
            </div>
            <div className={userInfoStyle.pipeline}></div>
            <div className={userInfoStyle.coursecard}>
              <img
                src={timer}
                width="20px"
                height="auto"
                style={{ margin: "10px 0 0 0" }}
                alt=""
              />
              {userDetails.user.col4 === undefined ||
              userDetails.user.col4 === "" ? (
                <div></div>
              ) : (
                <h3 className={userInfoStyle.username}>
                  {userDetails.user.col4}
                </h3>
              )}
              <h4 className={userInfoStyle.useremail}>Last active</h4>
            </div>
            <div className={userInfoStyle.coursecard}>
              <img
                src={inProgress}
                width="20px"
                height="auto"
                style={{ margin: "10px 0 0 0" }}
                alt=""
              />
              <h3 className={userInfoStyle.username}>{registeredCount}</h3>
              <h4 className={userInfoStyle.useremail}>Sessions Registered</h4>
            </div>
            <div className={userInfoStyle.coursecard}>
              <img
                src={completed}
                width="20px"
                height="auto"
                style={{ margin: "10px 0 0 0" }}
                alt=""
              />
              <h3 className={userInfoStyle.username}>{completedCount}</h3>
              <h4 className={userInfoStyle.useremail}>Sessions Completed</h4>
            </div>
            {/* <div className={userInfoStyle.coursecard}>
              <img
                src={badge}
                width="20px"
                height="auto"
                style={{ margin: "10px 0 0 0" }}
                alt=""
              />
              <h3 className={userInfoStyle.username}>2</h3>
              <h4 className={userInfoStyle.useremail}>Certificate</h4>
            </div> */}
          </div>
        </Paper>
      </div>
      <div className={userInfoStyle.tabsholder}>
        <Paper>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                indicatorColor="primary"
                textColor="inherit"
                variant="fullWidth"
              >
                <Tab label="Sessions" value="1" />
                {/* <Tab label="Content & Collaterals" value="2" /> */}
              </Tabs>
            </Box>
            <TabPanel value="1">
              <div
                style={{
                  height: 300,
                  width: "100%",
                  overflowY: "auto"
                }}
                className={classes.root}
              >
                <Paper>
                  <div
                    style={{ height: 300, width: "100%", overflowY: "auto" }}
                    className={classes.root}
                  >
                    <DataGrid
                      rows={rows}
                      columns={tcolumns}
                      className={classes[".MuiDataGrid-root"]}
                      disableSelectionOnClick
                      // components={{
                      //   Toolbar: CustomToolbar,
                      // }}
                    />
                  </div>
                </Paper>
              </div>
            </TabPanel>
          </TabContext>
        </Paper>
      </div>
    </div>
  );
}
