import React from "react";
import CourseUsers from "../components/pages/Tenants/courseusers";
import SideNav from "../components/SideNav/sideNav";
import Header from "../components/Header/hearder";
import { Wrapper } from "./layout.styles";

const CourseUsersLayout = () => {
  return (
    <React.Fragment>
      <Header />
      <Wrapper>
        <SideNav />
        <div style={{margin: "70px 0px 0px 200px", width: "100%"}}>
        <CourseUsers />
        </div>
      </Wrapper>
    </React.Fragment>
  );
};

export default CourseUsersLayout;
