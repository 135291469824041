import React, { useState, useEffect } from "react";

import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Constants } from "../../../config/Constants";
import contentStyle from "./content.module.scss";
import { ReactComponent as Edit } from "../../../assets/icons/mode_black_24dp.svg";
import { ReactComponent as Delete } from "../../../assets/icons/delete_outline_black_24dp.svg";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import EditSpeaker from "./EditSpeaker";
import DeleteSpeaker from "./DeleteSpeaker";
import {
  DataGrid,
  GridToolbar,
  GridToolbarContainer,
} from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/core/styles";
import AddSpeaker from "./AddSpeaker";
import config from "../../../config/aws-exports";
import { API } from "aws-amplify";
import { CustomButton } from "../../CustomButton/CustomButton";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
    "& .super-app-theme--header": {
      backgroundColor: Constants.blue_color,
      fontSize: "16px",
      color: "rgb(255,255,255)",
      fontFamily: "nunito",
    },
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
    cursor: "pointer",
  },
}));

function CustomToolbar() {
  return (
    <GridToolbarContainer
      style={{ display: "flex", justifyContent: "flex-end" }}
    >
      <GridToolbar />
    </GridToolbarContainer>
  );
}

export default function SpeakersGrid(props) {
  let { speakerData, getSpeaker } = props;
  console.log("sp", speakerData);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [dataToEdit, setDataToEdit] = useState({});
  const [dataToDelete, setDataToDelete] = useState({});
  const [EditSpeakerOpen, setEditSpeakerOpen] = useState(false);
  const [deleteSpeaker, setDeleteSpeaker] = useState(false);
  const [loading, setLoading] = useState(true);
  const [addSpeakerOpen, setAddSpeakerOpen] = useState(false);

  useEffect(() => {
    setpeakerTable();

    return () => {};
  }, []);

  const handleAddSpeakersOpen = () => setAddSpeakerOpen(true);
  const handleAddSpeakersClose = () => setAddSpeakerOpen(false);

  async function setpeakerTable() {
    /*  const bodyParam = {
      body: {
        oid: config.aws_org_id,
        action: "get",
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const speakerData = await API.post(
        config.aws_cloud_logic_custom_name,
        "/Speakers",
        bodyParam
      );
      console.log("speaker", speakerData); */

    const speakerslist = speakerData;
    console.log("list", speakerslist);
    let trows = [];
    for (let i = 0; i < speakerslist.length; i++) {
      let obj = {};
      obj.id = i;
      obj.speakerImage = speakerslist[i].image.timestampname;
      obj.speakerName = speakerslist[i].name;
      obj.desg = speakerslist[i].designation;
      obj.sessionCount = speakerslist[i].speaker_sessioncount;
      obj.params = speakerslist[i];

      trows.push(obj);
    }
    const tcolumns = [
      {
        field: "speakerImage",
        headerName: "Speaker Image",
        flex: 1,
        headerClassName: "super-app-theme--header",
        disableExport: true,
        renderCell: (params) => (
          <img
            alt=""
            src={`https://${
              config.DOMAIN
            }/${config.aws_org_id.toLowerCase()}-resources/images/speaker-images/${
              params.value
            }`}
            style={{ width: "150px", height: "120px", borderRadius: "10px" }}
          />
        ),
      },
      {
        field: "speakerName",
        headerName: "Speaker Name",
        flex: 1,
        headerClassName: "super-app-theme--header",
        width: 400,
      },
      {
        field: "desg",
        headerName: "Speaker Designation",
        flex: 1,
        headerClassName: "super-app-theme--header",
        width: 400,
      },
      {
        field: "sessionCount",
        headerName: "Count of sessions assigned to",
        flex: 1,
        headerClassName: "super-app-theme--header",
        width: 400,
      },

      {
        field: "params",
        headerName: "Edit/Delete",
        flex: 1,
        headerClassName: "super-app-theme--header",
        disableExport: true,
        renderCell: (params) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
              flexDirection: "column",
            }}
          >
            <Edit
              className={contentStyle.icons}
              onClick={() => handleEditSpeakersOpen(params.value)}
            />
            <Delete
              className={contentStyle.icons}
              onClick={() => handledeleteSpeakersOpen(params.value)}
            />
          </div>
        ),
      },
    ];
    setRows(trows);
    setColumns(tcolumns);
    setLoading(false);
    /* } catch (error) {
      console.log("getUserError", error);
    } */
  }

  function handleEditSpeakersClose() {
    setEditSpeakerOpen(false);
  }
  function handleEditSpeakersOpen(params) {
    console.log("params", params);
    setDataToEdit(params);
    setEditSpeakerOpen(true);
  }
  function handledeleteSpeakersClose() {
    setDeleteSpeaker(false);
  }
  function handledeleteSpeakersOpen(params) {
    console.log("params", params);
    setDataToDelete(params);
    setDeleteSpeaker(true);
  }

  return (
    <div>
      <button
        // className={contentStyle.addbutton}
        style={{
          float: "right",
          marginTop: "-120px",
          fontSize: "14px",
          fontWeight: "bold",
          height: "35px",
        }}
        onClick={handleAddSpeakersOpen}
      >
        Add Speakers
      </button>
      <div style={{ width: "100%" }}>
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      {loading ? null : (
        <div
          style={{ height: 500, width: "100%", overflowY: "auto" }}
          className={classes.root}
        >
          <DataGrid
            rows={rows}
            columns={columns}
            rowHeight={150}
            className={classes[".MuiDataGrid-root"]}
            components={{
              Toolbar: CustomToolbar,
            }}
            disableSelectionOnClick
          />
        </div>
      )}
      <Dialog
        fullScreen={fullScreen}
        open={EditSpeakerOpen}
        aria-labelledby="responsive-dialog-title-2"
        maxWidth="md"
      >
        <EditSpeaker
          handleEditSpeakersClose={handleEditSpeakersClose}
          dataToEdit={dataToEdit}
          setpeakerTable={setpeakerTable}
          setLoading={setLoading}
          loading={loading}
        />
      </Dialog>
      <Dialog
        fullScreen={fullScreen}
        open={deleteSpeaker}
        aria-labelledby="responsive-dialog-title-2"
        maxWidth="md"
      >
        <DeleteSpeaker
          handledeleteSpeakersClose={handledeleteSpeakersClose}
          dataToDelete={dataToDelete}
          setpeakerTable={setpeakerTable}
        />
      </Dialog>
      <Dialog
        fullScreen={fullScreen}
        open={addSpeakerOpen}
        aria-labelledby="responsive-dialog-title-2"
        maxWidth="md"
      >
        <AddSpeaker
          handleAddSpeakersClose={handleAddSpeakersClose}
          setpeakerTable={setpeakerTable}
          setLoading={setLoading}
          loading={loading}
        />
      </Dialog>
    </div>
  );
}
