import { Box } from "@mui/system";
import React, { useContext, useState } from "react";

import { Auth } from "aws-amplify";
import swal from "@sweetalert/with-react";
import config from "../../config/aws-exports";
import { awsSignIn } from "../../redux/auth/authSlice";
import { API } from "aws-amplify";
import { Constants } from "../../config/Constants";

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { CircularProgress, Typography } from "@mui/material";
import { CustomButton } from "../CustomButton/CustomButton";
import {
  inputBoxStyle,
  inputIconStyle,
  displayCenter,
  SignInAndSignUpValues,
} from "./SignInAndSignUp";

import { useHistory } from "react-router-dom";
import CustomInput from "../CustomInput/CustomInput";
import "react-phone-number-input/style.css";

import { useForm } from "../Hooks/useForm";
import ResetPassword from "./ResetPassword";
import ForgotPassword from "./ForgotPassword";
import validateResetPasswordEmail from "./SignInSignUpValidationRules/ResetPasswordValidationEmail";
// import ReactCodeInput from "react-verification-code-input";
import { Theme } from "../../config/aws-exports";
import { useDispatch } from "react-redux";
import validate from "./SignInSignUpValidationRules/SignInValidationRules";
import cognitoValidation from "./SignInSignUpValidationRules/SignInCognitoValidationRules";
import { HeadingPrimary } from "../Styles/Heading";
import Swal from "sweetalert2";
import validateResetPassword from "./SignInSignUpValidationRules/ResetPasswordValidation";
const SignIn = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    values,
    handleChange,
    setValues,
    handleClickShowPassword,
    handleMouseDownPassword,
  } = useForm({ showPassword: false });

  const { setSignUp, setSignIn } = useContext(SignInAndSignUpValues);

  const [forgotPassword, setForgotPassword] = useState(false);
  const [resetPasswordOpenFirst, setResetPasswordOpenFirst] = useState(false);
  const [signInForm, setSignInForm] = useState(true);
  const [emailVerification, setEmailVerification] = useState(false);

  // const [verificationCode, setVerificationCode] = useState("");

  const [errors, setErrors] = useState({});

  const [btnLoading, setBtnLoading] = useState(false);
  const [emailVerifyLoader, setEmailVerifyLoader] = useState(false);
  const [resetPasswordLoader, setResetPasswordLoader] = useState(false);
  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(Object.keys(validate(values)).length);
    if (Object.keys(validate(values)).length !== 0) {
      setErrors(validate(values));
      return;
    } else {
      setBtnLoading(true);
      try {
        const user = await Auth.signIn(values.username, values.password);
        console.log("user", user);

        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          setSignInForm(false);
          setResetPasswordOpenFirst(true);
          setSignUp(false);
          setValues({ ...values, showPassword: false });
          setForgotPassword(false);
          setBtnLoading(false);
        }
        let userGroup =
          user.signInUserSession.accessToken.payload["cognito:groups"];
        // if (userGroup === undefined || userGroup === null) {
        //   swal("User Not Authorized");
        // }
        console.log("uG", userGroup);

        const info = await Auth.currentUserInfo();
        let userData = info.attributes;
        userData.username = info.username;
        userData.selectedTenant = "ALL ORGANIZATION";
        userData.buttonText = "ALL ORGANIZATION";
        userData.id = info.id;

        if (
          userGroup !== undefined &&
          userGroup !== null &&
          userGroup[0] === config.aws_org_id + "-Admin"
        ) {
          userData.role = "Admin";
          setBtnLoading(false);
          console.log(JSON.stringify(userData));
          dispatch(awsSignIn(userData));
          console.log("signin-info", info);
          console.log("user", user);
          history.push("/landingscreen");
        } else {
          getUserRole();

          async function getUserRole() {
            const bodyParam = {
              body: {
                email: values.username,
                schema: Constants.SCHEMA_NAME,
                oid: userData.locale,
              },
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
              },
            };
            try {
              console.log(bodyParam.body);
              const userRole = await API.post(
                config.aws_cloud_logic_custom_name,
                "/getUserRole",
                bodyParam
              );
              userData.eid = user.username;
              userData.role = userRole.body[0]?.ur;
              userData.selectedTenant = userData.locale;
              userData.st_id = userRole.body[0]?.st_id;

              setBtnLoading(false);
              console.log(JSON.stringify(userData));
              dispatch(awsSignIn(userData));
              console.log("signin-info", info);
              console.log("user", user);
              history.push("/landingscreen");
            } catch (error) {
              console.error(error);
            }
          }
        }
      } catch (error) {
        console.error("ree", error);
        setBtnLoading(false);
        setErrors(cognitoValidation(error));
      }
    }
  };
  const resetPasswordFC = async (event) => {
    event.preventDefault();
    if (Object.keys(validateResetPassword(values)).length !== 0) {
      setErrors(validateResetPassword(values));
      return;
    } else {
      try {
        setResetPasswordLoader(true);
        await Auth.signIn(values.username, values.password)
          .then((users) => {
            if (users.challengeName === "NEW_PASSWORD_REQUIRED") {
              Auth.completeNewPassword(users, values.confirmPassword, {
                name: values.name,
              })
                .then(() => {
                  console.log("change");
                  setResetPasswordLoader(false);
                  setSignInForm(true);
                  setResetPasswordOpenFirst(false);
                  setValues({});
                  swal({
                    title: "Success",
                    text: "Password changed successfully!",
                    icon: "success",
                    dangerMode: false,
                    closeOnClickOutside: false,
                  }).then((willDelete) => {
                    if (willDelete) {
                      setResetPasswordLoader(false);
                      setSignInForm(true);
                      setResetPasswordOpenFirst(false);
                      setValues({});
                    }
                  });
                })
                .catch((error) => {
                  setResetPasswordLoader(false);
                  setErrors(cognitoValidation(error));
                });
            }
          })
          .catch((error) => {
            setResetPasswordLoader(false);
            setErrors(cognitoValidation(error));
          });
      } catch (error) {
        setResetPasswordLoader(false);
        setErrors(cognitoValidation(error));
      }
    }
  };

  const passwordResetFC = async (event) => {
    // alert("submitHandle");
    // alert(Object.keys(validateResetPasswordEmail(values)).length !== 0);
    event.preventDefault();
    if (Object.keys(validateResetPasswordEmail(values)).length !== 0) {
      setErrors(validateResetPasswordEmail(values));
      return;
    } else {
      setResetPasswordLoader(true);
      try {
        await Auth.forgotPasswordSubmit(
          values.verificationEmail,
          values.verificationCode,
          values.confirmPassword
        )
          .then(() => {
            setResetPasswordLoader(false);
            setSignInForm(true);
            setForgotPassword(false);
            // alert("hey");
            swal({
              title: "Success",
              text: "Changed password Successfully",
              icon: "success",
              dangerMode: false,
              closeOnClickOutside: false,
            }).then((willDelete) => {
              if (willDelete) {
                setResetPasswordLoader(false);
                setSignInForm(true);
                setResetPasswordOpenFirst(false);
                setValues({});
              }
            });
          })
          .catch((error) => {
            setResetPasswordLoader(false);
            // alert("hi");
            console.error(error);
            setErrors(cognitoValidation(error));
          });
      } catch (error) {
        setResetPasswordLoader(false);
        setErrors(cognitoValidation(error));
      }
    }
  };

  return (
    <React.Fragment>
      {signInForm && (
        <React.Fragment>
          <Box sx={{ textAlign: "center" }}>
            <HeadingPrimary>Login to your account</HeadingPrimary>
          </Box>
          <form
            onSubmit={handleSubmit}
            noValidate
            autoComplete="off"
            style={displayCenter}
          >
            <Box component="div" sx={inputBoxStyle}>
              <CustomInput
                id="input-username"
                placeholder="Username"
                type="email"
                value={values.username || ""}
                error={errors.email}
                onChange={handleChange("username")}
              >
                <PersonOutlineOutlinedIcon sx={inputIconStyle} />
              </CustomInput>
            </Box>

            <Box component="div" sx={inputBoxStyle}>
              <CustomInput
                id="input-Password"
                placeholder="Password"
                type="password"
                inputValues={values}
                value={values.password || ""}
                handleClickShowPassword={handleClickShowPassword}
                handleMouseDownPassword={handleMouseDownPassword}
                onChange={handleChange("password")}
                error={errors.password}
              >
                <LockOutlinedIcon sx={inputIconStyle} />
              </CustomInput>
              <Typography
                onClick={() => {
                  setForgotPassword(true);
                  setSignInForm(false);
                }}
                variant="caption"
                display="block"
                gutterBottom
                sx={{
                  fontSize: "1rem",
                  textAlign: "right",
                  cursor: "pointer",
                }}
              >
                Forgot password?
              </Typography>
            </Box>

            <Box component="div" sx={{ textAlign: "center" }}>
              {btnLoading ? (
                <CustomButton disabled loading="true">
                  <CircularProgress color="inherit" size={20} />
                </CustomButton>
              ) : (
                <CustomButton type="submit" primary>
                  Login
                </CustomButton>
              )}
            </Box>
          </form>
        </React.Fragment>
      )}

      {forgotPassword && !resetPasswordOpenFirst && (
        <ForgotPassword
          emailVerifyLoader={emailVerifyLoader}
          setEmailVerifyLoader={setEmailVerifyLoader}
          setEmailVerification={setEmailVerification}
          passwordResetFC={passwordResetFC}
          setForgotPassword={setForgotPassword}
          setResetPasswordOpenFirst={setResetPasswordOpenFirst}
          values={values}
          handleChange={handleChange}
          resetPasswordLoader={resetPasswordLoader}
          emailVerification={emailVerification}
          setSignInForm={setSignInForm}
          handleClickShowPassword={handleClickShowPassword}
          handleMouseDownPassword={handleMouseDownPassword}
          setErrors={setErrors}
          errors={errors}
        />
      )}

      {resetPasswordOpenFirst && (
        <ResetPassword
          resetPasswordFC={resetPasswordFC}
          resetPasswordLoader={resetPasswordLoader}
          emailVerification={emailVerification}
          setEmailVerification={setEmailVerification}
          setSignInForm={setSignInForm}
          setResetPasswordOpenFirst={setResetPasswordOpenFirst}
          handleChange={handleChange}
          values={values}
          setValues={setValues}
          setForgotPassword={setForgotPassword}
          handleClickShowPassword={handleClickShowPassword}
          handleMouseDownPassword={handleMouseDownPassword}
          setErrors={setErrors}
          errors={errors}
        />
      )}
    </React.Fragment>
  );
};

export default SignIn;
