
import Header from "@editorjs/header";
import List from "@editorjs/list";
import Code from "@editorjs/code";
import Paragraph from '@editorjs/paragraph';

export const EDITOR_JS_TOOLS = {
  list: List,
  code: Code,
  image: Image,
  paragraph: Paragraph,
  header: Header,
};
