// Dependencies import
import React, {useState} from 'react';
import { API, JS } from "aws-amplify";
import swal from "sweetalert";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

// Local imports
import { ReactComponent as Close } from "../../../assets/icons/close_black_24dp.svg";
import config from "../../../config/aws-exports";
import { Constants } from '../../../config/Constants';

// Style imports
import Add from './AddTenant.module.scss';


const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      heigth: "100%",
      alignItems: "center",
      justifyContent: "center",
      background: "transparent",
    },
  }));

const AddTenant = (props) => {
    let {handleAddClose, getTenants} = props;
    const [tenantName, setTenantName] = useState("");
    const [disName, setDisName] = useState("");
    const [tenNameErr, setTenNameErr] = useState("");
    const [disNameErr, setDisNameErr] = useState("");
    const [spin,setSpin] = useState(false);
    const classes = useStyles();
    

    const onChangeHandlerTenantName = (event) => {
        setTenantName(event.target.value);
      };

    const onChangeHandlerDisName = (event) => {
        setDisName(event.target.value);
      };


      const onsubmit = () => {
        let formIsValid = true;
        setTenNameErr("");
        setDisNameErr("");
    
        if (
          tenantName === "" ||
          tenantName === null ||
          (tenantName != undefined && tenantName.trim() === "")
        ) {
          formIsValid = false;
          setTenNameErr("Please enter Organization name");
          return;
        }
        if (
          disName === "" ||
          disName === null ||
          (disName != undefined && disName.trim() === "")
        ) {
          formIsValid = false;
          setDisNameErr("Please enter Display name");
          return;
        }
    
        if (formIsValid == true) {
          addSubTenant();
        }
        };

        async function addSubTenant() {
            setSpin(true);
            const bodyParam = {
              body: {
                st_id : 0,
                oid: tenantName,
                did: disName,
                mt_id: 1,
                atype: 0,
                schema: Constants.SCHEMA_NAME,
              },
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
              },
            };
            console.log("bd" + JSON.stringify(bodyParam.body));
            try {
              const response = await API.post(
                config.aws_cloud_logic_custom_name,
                Constants.UPDATE_TENANT,
                bodyParam
                //`${Constants.GET_MY_PROGRAMS}`, bodyParam,
              );
              // const users = response;
              console.log(JSON.stringify(response));
              handleAddClose();
              getTenants();
              swal({
                title: "Success",
                text: "Organization added Successfully",
                icon: "success",
                dangerMode: false,
                closeOnClickOutside: false,
              }).then((willDelete) => {
                if (willDelete) {
                  setSpin(false);
                }
              });
            } catch (error) {
              setSpin(false);
              swal({
                title: "Oooops!",
                text: "Something went wrong!",
                icon: "warning",
                dangerMode: true,
                closeOnClickOutside: false,
              }).then((willDelete) => {
                if (willDelete) {
                  setSpin(false);
                }
              });
              console.log("getUserError", error);
            }
            console.log("Mount");
          }

    return(
        <div className={Add.maindiv}>
            {spin === false ? (
            <div className={Add.maincontainer}>
            <div className={Add.topbar}>
            <h3 className={Add.heading}>Add Organization</h3>
            <Close className={Add.close} onClick={handleAddClose}/>
            </div>
            
            <div className={Add.form}>
            <div className={Add.field}>
              <label
                style={{
                  fontSize: "14px",
                  fontWeight: "400px",
                  width: "20%",
                  textAlign: "right"
                }}
                for="name"
                required
              >
                Organization Name
                <span style={{ color: "red", marginTop: "-10px" }}>*</span>
              </label>
              <input
                style={{
                  border: "1px solid lightgrey",
                  borderRadius: "5px",
                  width: "70%",
                  background: "white",
                  height: "30px",
                  marginBottom: "20px",
                  fontSize: "15px",
                  paddingLeft: "10px",
                  fontWeight: "200px",
                }}
                type="text"
                id="name"
                onChange={onChangeHandlerTenantName}
                required
              />
            </div>
            <div
              style={{
                color: "#cc0000",
                marginBottom: "10px",
                marginTop: "-10px",
                textAlign: "start",
                marginLeft: "20%",
              }}
            >
              {tenNameErr}
            </div>
            <div className={Add.field}>
              <label
                style={{
                  fontSize: "14px",
                  fontWeight: "400px",
                  width: "20%",
                  textAlign: "right"
                }}
                for="name"
                required
              >
                Display Name
                <span style={{ color: "red", marginTop: "-10px" }}>*</span>
              </label>
              <input
                style={{
                  border: "1px solid lightgrey",
                  borderRadius: "5px",
                  width: "70%",
                  background: "white",
                  height: "30px",
                  marginBottom: "20px",
                  fontSize: "15px",
                  paddingLeft: "10px",
                  fontWeight: "200px",
                }}
                type="text"
                id="name"
                onChange={onChangeHandlerDisName}
                required
              />
            </div>
            <div
              style={{
                color: "#cc0000",
                marginBottom: "10px",
                marginTop: "-10px",
                textAlign: "start",
                marginLeft: "20%",
              }}
            >
              {disNameErr}
            </div>
            </div>
            <div className={Add.buttonholder}>
                <button className={Add.cancel} onClick={handleAddClose}>Cancel</button>
                <button type="submit" className={Add.submit} onClick={onsubmit}>Submit</button>
            </div>
            </div>
            ) : (
                <div className={classes.root}>
                  <CircularProgress style={{ color: config.main_color_1 }} />
                </div>
              )}
        </div>
    );
};

export default AddTenant;