import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import { authData } from "../../redux/auth/authSlice";
import Bread from './Breadcrumbs.module.scss';

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(2),
        '& > * + *': {
            marginBottom: theme.spacing(3),
        },
    },
}));

const Breadcrumb = ({ Bindex }) => {
    const [breadcrumbs, setBreadcrumbs] = useState([]);
    const classes = useStyles();
    let userDetails = useSelector(authData);

    useEffect(() => {
        breadcrumb();
    }, []);

    function breadcrumb() {
        let breadcrumb = [];
        if (userDetails.breadcrumb !== undefined) {
            breadcrumb = { ...userDetails.breadcrumb };
        }
        if (Bindex != undefined) {
            let temp = [];
            for (let i = 0; i <= Bindex; i++) {
                temp.push(breadcrumb[i]);
            }
            if (temp !== undefined) {
                setBreadcrumbs(temp);
            }
        }
    }

    return (
        <div className={classes.root}>
            {breadcrumbs !== undefined ?
                <Breadcrumbs separator="›" aria-label="breadcrumb">
                    {
                        breadcrumbs?.map((item) => {
                            return (
                                <Link className={Bread.bread} to={item?.path} >
                                    {item?.name}
                                </Link>
                            )
                        })};
                </Breadcrumbs>
                : null}
        </div>
    );
};

export default Breadcrumb;