import { API } from "aws-amplify";
import config from "../../config/aws-exports";
import { useQuery } from "@tanstack/react-query";
const getCities = async (country_code, state_code) => {
  try {
    return API.get(
      config.aws_cloud_logic_custom_name,
      `/countries/${country_code}/states/${state_code}/cities`
    );
  } catch (error) {
    // console.error(error)
    throw error;
  }
};

const useCities = ({ country_code, state_code }) => {
  console.log({ country_code, state_code });
  return useQuery({
    queryKey: [`/countries/${country_code}/states/${state_code}/cities`],
    enabled: country_code && state_code ? true : false,
    queryFn: () => getCities(country_code, state_code),
  });
};

export default useCities;
