// Dependencies imports
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { API } from "aws-amplify";
import BackDrop from "@material-ui/core/Backdrop/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";

// Local imports
import Breadcrumb from "../../Breadcrumbs/Breadcrumbs";
import { awsSignIn, authData } from "../../../redux/auth/authSlice";
import image1 from "./2.png";
import { Constants } from "../../../config/Constants";
import config from "../../../config/aws-exports";
import SpeakerView from "./SpeakerView";

// Style imports
import Session from "./SessionView.module.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
    "& .super-app-theme--header": {
      backgroundColor: Constants.blue_color,
      fontSize: "16px",
      color: "rgb(255,255,255)",
      fontFamily: "nunito",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
    cursor: "pointer",
  },
}));

const SessionView = () => {
  let userDetails = useSelector(authData);
  const [speakerData, setSpeakerData] = useState({});
  const [open, setOpen] = useState(false);
  const [speakerName, setSpeakerName] = useState([]);
  const [spin, setSpin] = useState(true);
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    breadcrumb();
    getSpeakerData();
    return () => {};
  }, []);

  async function getSpeakerData() {
    setSpin(true);
    const bodyParam = {
      body: {
        tenantId: userDetails.sessiondata.speaker_id,
        schema: Constants.SCHEMA_NAME,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const speakerData = await API.post(
        config.aws_cloud_logic_custom_name,
        "/getSpeakerData",
        bodyParam
      );
      if (speakerData !== undefined || speakerData !== null) {
        setSpeakerName(speakerData.body);
        setSpin(false);
      }
    } catch (error) {
      console.error(error);
      setSpin(false);
    }
  }

  console.log("session data", userDetails.sessiondata);

  function breadcrumb() {
    let sdata = { ...userDetails };
    if (sdata.breadcrumb == undefined) {
      sdata.breadcrumb = [];
      let temp = [...sdata.breadcrumb];
      temp[0] = {
        name: "Content",
        path: "/uspcontent",
      };
      temp[1] = {
        name: userDetails.sessiondata.sn,
        path: "/uspcontent/sessionView",
      };
      sdata.breadcrumb = temp;
    } else {
      let temp = [...sdata.breadcrumb];
      temp[0] = {
        name: "Content",
        path: "/uspcontent",
      };
      temp[1] = {
        name: userDetails.sessiondata.sn,
        path: "/uspcontent/sessionView",
      };
      sdata.breadcrumb = temp;
    }
    dispatch(awsSignIn(sdata));
  }

  function Duration({ start, end }) {
    var startTime = moment(start);
    var endTime = moment(end);
    // var hoursDiff = endTime.diff(startTime, "hours");
    // console.log("Hours:" + hoursDiff);
    var minutesDiff = endTime.diff(startTime, "minutes");
    var hours = Math.floor(minutesDiff / 60);
    var minutes = minutesDiff % 60;
    // var secondsDiff = endTime.diff(startTime, "seconds");
    // console.log("Seconds:" + secondsDiff);
    return `${hours}h ${minutes}m`;
  }

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function speakerClick(value) {
    setSpeakerData(value);
    handleOpen();
  }

  return (
    <div className={Session.main}>
      <Breadcrumb Bindex={1} />
      <h3 className={Session.heading}>{userDetails.sn}</h3>
      <BackDrop sx={{ color: "#fff" }} open={spin} className={classes.backdrop}>
        <CircularProgress color="inherit" />
      </BackDrop>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <SpeakerView handleClose={handleClose} speakerData={speakerData} />
        </Fade>
      </Modal>

      <div className={Session.dataholder}>
        <Paper style={{ width: "70%" }}>
          <div className={Session.leftdiv}>
            <div className={Session.leftside}>
              <div
                className={Session.fields}
                style={{ alignItems: "flex-start" }}
              >
                <h4
                  className={`${Session.lefthead}`}
                  style={{ width: "100px", marginTop: "5px" }}
                >
                  Session date:
                </h4>
                {userDetails?.sessiondata?.date_list &&
                userDetails?.sessiondata?.date_list[0].combineStartTime &&
                userDetails?.sessiondata?.date_list[0].combineEndTime ? (
                  <table>
                    <tbody>
                      <tr>
                        {userDetails.sessiondata?.date_list?.map((item) => {
                          return (
                            <tr>
                              <td>
                                {moment(item.combineStartTime).format(
                                  "DD/MM/YYYY"
                                )}
                                ; &nbsp;
                                {moment(item.combineStartTime).format("LT")}-
                                {moment(item.combineEndTime).format("LT")}
                              </td>
                            </tr>
                          );
                        })}
                      </tr>
                    </tbody>
                  </table>
                ) : userDetails.sessiondata.startdate != null &&
                  userDetails.sessiondata.enddate != null ? (
                  <p>
                    {`${moment(
                      parseInt(userDetails.sessiondata.startdate)
                    ).format("DD-MM-YYYY")}`}
                    ;<span style={{ marginLeft: "5px" }}></span>
                    {`${moment(
                      parseInt(userDetails.sessiondata.startdate)
                    ).format("LT")}`}
                    <span style={{ marginLeft: "5px" }}></span>
                    to
                    <span style={{ marginLeft: "5px" }}></span>
                    {`${moment(
                      parseInt(userDetails.sessiondata.enddate)
                    ).format("LT")}`}
                  </p>
                ) : (
                  <p className={Session.righttext}>Yet to be added</p>
                )}
              </div>
              {/* <div className={Session.fields}>
                <h4 className={Session.lefthead}>Duration :</h4>
                {userDetails.sessiondata.startdate === null ? (
                  <h4 className={Session.righttext}>Yet to be added</h4>
                ) : (
                  <Duration
                    start={parseInt(userDetails.sessiondata.startdate)}
                    end={parseInt(userDetails.sessiondata.enddate)}
                  />
                )}
              </div> */}
              <div className={Session.bigfields}>
                <h4 className={Session.lefthead}>Speaker :</h4>
                <div className={Session.speakerlist}>
                  {speakerName.length !== 0 ? (
                    speakerName.map((value, index) => {
                      return (
                        <h4
                          onClick={() => speakerClick(value)}
                          key={index}
                          className={Session.speakers}
                        >
                          {value.name}
                        </h4>
                      );
                    })
                  ) : (
                    <h4 className={Session.speakers}></h4>
                  )}
                </div>
              </div>
            </div>
            <div className={Session.rightside}>
              {(userDetails.sessiondata.priceinr !== "" &&
                userDetails.sessiondata.priceinr !== null &&
                userDetails.sessiondata.priceinr !== 0) ||
              (userDetails.sessiondata.priceusd !== "" &&
                userDetails.sessiondata.priceusd !== null &&
                userDetails.sessiondata.priceusd !== 0) ? (
                <div className={Session.fields}>
                  <h4 className={Session.lefthead}>Fees :</h4>
                  <p>
                    {" "}
                    INR {userDetails.sessiondata.priceinr} (Inc. of GST) / $
                    {userDetails.sessiondata.priceusd}{" "}
                  </p>
                </div>
              ) : null}
              {(userDetails.sessiondata.earlypriceinr !== "" &&
                userDetails.sessiondata.earlypriceinr !== null &&
                userDetails.sessiondata.earlypriceinr !== 0) ||
              (userDetails.sessiondata.earlypriceusd !== "" &&
                userDetails.sessiondata.earlypriceusd !== null &&
                userDetails.sessiondata.earlypriceusd !== 0) ? (
                <div className={Session.fields}>
                  <h4 className={Session.lefthead}>Early Bird Offer:</h4>
                  <p>
                    {" "}
                    INR {userDetails.sessiondata.earlypriceinr} (Inc. of GST) /
                    ${userDetails.sessiondata.earlypriceusd} till{" "}
                    {moment(parseInt(userDetails.sessiondata.earlydate)).format(
                      "DD MMM YYYY"
                    )}
                  </p>
                </div>
              ) : null}
              {userDetails.sessiondata.perdis !== "" &&
              userDetails.sessiondata.perdis !== null &&
              userDetails.sessiondata.perdis !== 0 ? (
                <div className={Session.fields}>
                  <h4 className={Session.lefthead}>Percentage Discount:</h4>
                  <p>
                    {userDetails.sessiondata.perdis}
                    {" %"}
                  </p>
                </div>
              ) : null}
              {(userDetails.sessiondata.priceafterinr !== "" &&
                userDetails.sessiondata.priceafterinr != null &&
                userDetails.sessiondata.priceafterinr !== 0) ||
              (userDetails.sessiondata.priceafterusd !== "" &&
                userDetails.sessiondata.priceafterusd != null &&
                userDetails.sessiondata.priceafterusd !== 0) ? (
                <div className={Session.fields}>
                  <h4 className={Session.lefthead}>Price after Discount:</h4>
                  <p>
                    {" "}
                    INR {userDetails.sessiondata.priceafterinr} (Inc. of GST) /
                    ${userDetails.sessiondata.priceafterusd}{" "}
                  </p>
                </div>
              ) : null}
              {userDetails.sessiondata.location_value !== "" ? (
                <div className={Session.fields}>
                  <h4 className={Session.lefthead}>Location :</h4>
                  <p>{userDetails.sessiondata.location_value}</p>
                </div>
              ) : null}
              {userDetails?.sessiondata?.note !== "" &&
                userDetails?.sessiondata?.note !== null && (
                  <div className={Session.fields}>
                    <h4 className={Session.lefthead}>
                      <span>Note</span>
                      <span>:</span>
                    </h4>
                    <p>{userDetails?.sessiondata?.note}</p>
                  </div>
                )}
            </div>
          </div>
        </Paper>
        <div className={Session.imageholder}>
          <img
            src={
              userDetails.sessiondata.timgname === null
                ? image1
                : `https://${
                    config.DOMAIN
                  }/${config.aws_org_id.toLowerCase()}-resources/images/session-images/${
                    userDetails.sessiondata.timgname
                  }`
            }
            alt={userDetails.sn}
            className={Session.image}
          />
        </div>
      </div>
      <Paper style={{ width: "100%" }}>
        <div className={Session.lowersection}>
          <div className={Session.holder}>
            <h3 className={Session.subheading}>Overview</h3>
            <p className={Session.para}>{userDetails.sessiondata.sdesc}</p>
          </div>
          {userDetails.sessiondata.lobj !== "" ? (
            <div className={Session.holder}>
              <h3 className={Session.subheading}>Learning Objectives</h3>
              <p>Upon completion of this course, you will be able to:</p>
              <ul>
                {userDetails?.sessiondata?.lobj?.split("\n").map((obj) => (
                  <li key={obj}>{obj}</li>
                ))}
              </ul>
            </div>
          ) : null}
          {userDetails.sessiondata.taudi !== "" ? (
            <div className={Session.holder}>
              <h3 className={Session.subheading}>Target Audience</h3>
              <ul>
                {userDetails?.sessiondata?.taudi?.split("\n").map((obj) => (
                  <li key={obj}>{obj}</li>
                ))}
              </ul>
            </div>
          ) : null}
        </div>
      </Paper>
    </div>
  );
};

export default SessionView;
