import React, { useEffect, useState, useRef } from "react";
import {
  DataGrid,
  GridToolbar,
  GridToolbarContainer,
} from "@material-ui/data-grid";
import { API } from "aws-amplify";
import Publish from "./publish";
import Backdrop from "@material-ui/core/Backdrop";
import { ReactComponent as Edit } from "../../../assets/icons/mode_black_24dp.svg";
import { ReactComponent as EditGrey } from "../../../assets/icons/greayEdit.svg";

import Skeleton from "@material-ui/lab/Skeleton";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core/styles";
import { Constants } from "../../../config/Constants";
import config from "../../../config/aws-exports";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import Table from "@mui/material/Table";
import conStyle from "./contentAndColleteral.module.scss";
import video from "../../../assets/objects/video.png";
import audio from "../../../assets/objects/audio.png";
import pdf from "../../../assets/objects/pdf.png";
import inter from "../../../assets/objects/inter.png";
import youtube from "../../../assets/objects/youtube.png";
import vimeo from "../../../assets/objects/vimeo.png";
import quiz from "../../../assets/objects/quiz.png";
import html from "../../../assets/objects/html.png";
import Modal from "@mui/material/Modal";

import Box from "@mui/material/Box";
import ObjectPlayer from "./ObjectPlayer";
import moment from "moment";
import Fade from "@material-ui/core/Fade";
import EditContent from "./EditContent";

const modal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function CustomToolbar() {
  return (
    <GridToolbarContainer
      style={{ display: "flex", justifyContent: "flex-end" }}
    >
      <GridToolbar />
    </GridToolbarContainer>
  );
}

// Styles for material components
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
    "& .super-app-theme--header": {
      backgroundColor: Constants.blue_color,
      fontSize: "16px",
      color: "rgb(255,255,255)",
      fontFamily: "nunito",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
    cursor: "pointer",
  },
}));

const programNames = {
  color: Constants.blue_color,
  fontSize: "14px",
  fontWeight: "300",
  "&:hover": {
    cursor: "pointer",
    transform: "scale(1.05)",
  },
};

const divprogramNames = {
  width: 80,
  color: Constants.blue_color,
  fontSize: "14px",
  fontWeight: "300",
  "&:hover": {
    cursor: "pointer",
    transform: "scale(1.05)",
  },
};

const header = {
  color: "white",
  fontSize: "14px",
  fontWeight: "bold",
};

export default function ContentAndColleteral(props) {
  let { loading, contentObjects, setLoading ,getObjects} = props;

  const theme = useTheme();
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [open, setOpen] = React.useState(false);
  const [editOpenObj, setEditObjOpen] = React.useState(false);
  const [oIndex, setOIndex] = useState(0);
  const [curObject, setcurObject] = useState({});
  const curObRef = useRef({});
  const [editopen, setEditOpen] = useState(false);
  const [tenants, setTenants] = useState([]);
  const [dataToEdit, setDataToEdit] = useState({});
  const [topicRowData, setTopicRowData] = useState({});

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
     objectsTableView(contentObjects);
    getAllTenants();
   
    return () => {};
  }, []);


  
  function onClickObject(objid) {
    console.log("hiii");
    //alert(JSON.stringify(rowData));
    let temp = 0;
    for (let i = 0; i <= contentObjects.length; i++) {
      if (objid == contentObjects[i].objid) {
        temp = i;
        break;
      }
    }
    //alert(temp);
    setOIndex(temp);
    setcurObject(contentObjects[temp]);

    curObRef.current = contentObjects[temp];

    setOpen(true);
  }

  function objectsTableView(contentObjects) {
    const Objectslist = contentObjects;
    console.log("list111", contentObjects);
    let trows = [];
    if(contentObjects.length > 0){
    for (let i = 0; i < contentObjects.length; i++) {
      let obj = {};
      obj.id = i;
      obj.title = contentObjects[i];
      obj.modified_by = contentObjects[i].modified_by;
      obj.modified_on = moment(parseInt(contentObjects[i].modified_on)).format(
        "DD-MM-YYYY"
      );
      obj.publish = contentObjects[i];
      obj.edit = contentObjects[i];

      trows.push(obj);
    }
    }
    const tcolumns = [
      {
        field: "title",
        headerName: "Object title",
        flex: 1,
        headerClassName: "super-app-theme--header",
        disableExport: true,
        renderCell: (params) => (
          <div
            /*  onClick={() => console.log("hi")} */
            className={conStyle.nuggetObject}
            onClick={() => onClickObject(params.value.objid)}
          >
            <div className={conStyle.objImg}>
              {renderSwitch(params.value.otype)}
            </div>
            <div className={conStyle.title}>{params.value.oname}</div>
          </div>
        ),
      },
      {
        field: "modified_by",
        headerName: "Modified By",
        flex: 1,
        headerClassName: "super-app-theme--header",
        width: 400,
      },
      {
        field: "modified_on",
        headerName: "Modified On",
        flex: 1,
        headerClassName: "super-app-theme--header",
        width: 400,
      },

      {
        field: "publish",
        headerName: "Publish",
        flex: 1,
        headerClassName: "super-app-theme--header",
        disableExport: true,
        renderCell: (params) => (
          <button
            className={
              params.value.tenantid === undefined ||
              params.value.tenantid.length === 0
                ? conStyle.addbutton
                : conStyle.addbuttongreen
            }
            onClick={() => {
              handlePublishOpen(params.value);
            }}
          >
            Publish/Unpublish
          </button>
        ),
      },
      {
        field: "edit",
        headerName: "Edit/Delete",
        flex: 1,
        headerClassName: "super-app-theme--header",
        disableExport: true,
        renderCell: (params) => (
          <>
            {params.value.tenantid.length === 0 ? (
              <Edit
                className={conStyle.icons}
                onClick={() => onClickObjectEdit(params.value.objid)}
              />
            ) : (
              <EditGrey style={{ cursor: "not-allowed" }} />
            )}
          </>
        ),
      },
    ];
    setRows(trows);
    setColumns(tcolumns);
    setLoading(false);
  }

  function onClickObjectEdit(objid) {
    //alert(JSON.stringify(rowData));
    let temp = 0;
    for (let i = 0; i <= contentObjects.length; i++) {
      if (objid == contentObjects[i].objid) {
        temp = i;
        break;
      }
    }
    //alert(temp);
    setOIndex(temp);
    setcurObject(contentObjects[temp]);

    curObRef.current = contentObjects[temp];

    setEditObjOpen(true);
  }

  const handleEditClose = () => {
    setEditOpen(false);
  };

  const handleCloseEditObj = () => {
    setEditObjOpen(false);
  };

  function handlePublishOpen(item) {
    item.tenants = item.tenantid;
    setTopicRowData(item);

    setEditOpen(true);
    setDataToEdit(item);
  }

  async function getAllTenants() {
    setLoading(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        schema: Constants.SCHEMA_NAME,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log(JSON.stringify(bodyParam.body));
      const list = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_TENANTS,
        bodyParam
      );

      console.log(JSON.stringify(list));
      setTenants(list.body);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  }

  const renderSwitch = (param) => {
    if (param === "video") {
      return <img src={video} style={{ height: "32px" }} />;
    } else if (param === "audio") {
      return <img src={audio} style={{ height: "32px" }} />;
    } else if (param === "quiz") {
      return <img src={quiz} style={{ height: "32px" }} />;
    } else if (param === "html") {
      return <img src={html} style={{ height: "32px" }} />;
    } else if (param === "Interactivity" || param === "interactivity") {
      return <img src={inter} style={{ height: "32px" }} />;
    } else if (param === "pdf") {
      return <img src={pdf} style={{ height: "32px" }} />;
    } else if (param === "youtube") {
      return <img src={youtube} style={{ height: "32px" }} />;
    } else if (param === "vimeo") {
      return <img src={vimeo} style={{ height: "32px" }} />;
    } else {
      return param;
    }
  };
  const handleClose = () => setOpen(false);

  function Tableview() {
    return (
      <TableBody>
        {(rowsPerPage > 0
          ? contentObjects.slice(
              page * rowsPerPage,
              page * rowsPerPage + rowsPerPage
            )
          : contentObjects
        ).map((item) => {
          return (
            <TableRow>
              <TableCell style={{ width: "25%", position: "relative" }}>
                <div
                  className={conStyle.nuggetObject}
                  onClick={() => onClickObject(item.objid)}
                >
                  <div className={conStyle.objImg}>
                    {renderSwitch(item.otype)}
                  </div>
                  <div className={conStyle.title}>{item.oname}</div>
                </div>
              </TableCell>
              <TableCell>{item.modified_by}</TableCell>
              <TableCell>{`${moment(parseInt(item.modified_on)).format(
                "DD-MM-YYYY"
              )}`}</TableCell>
              <TableCell>
                <button
                  onClick={() => {
                    handlePublishOpen(item);
                  }}
                >
                  Publish/Unpublish
                </button>
              </TableCell>
              <TableCell>
                <Edit
                  className={conStyle.icons}
                  onClick={() => onClickObjectEdit(item.objid)}
                />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    );
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div style={{ height: "600px" }}>
      
      {/*   <div style={{ width: "100%" }}>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Skeleton
              variant="rect"
              width="100%"
              height={300}
              style={{ marginBottom: "40px" }}
            />{" "}
          </div>
        ) : (
          <div
            style={{ height: 550, width: "100%", overflowY: "auto" }}
            className={classes.root}
          >
            <TableContainer>
              <Table>
                <TableHead style={{ background: config.platform_main_theme }}>
                  <TableRow>
                    <TableCell style={header}> Title</TableCell>
                    <TableCell style={header}>Last Modified by</TableCell>
                    <TableCell style={header}>Last Modified on</TableCell>
                    <TableCell style={header}>Publish/Unpublish</TableCell>
                    <TableCell style={header}>Edit</TableCell>
                  </TableRow>
                </TableHead>
                {loading ? null : <Tableview />}
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[
                        5,
                        10,
                        25,
                        { label: "All", value: -1 },
                      ]}
                      colSpan={5}
                      count={contentObjects.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: { "aria-label": "rows per page" },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </div>
        )}
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modal}>
          <ObjectPlayer
            handleClose={handleClose}
            oIndex={oIndex}
            setOIndex={setOIndex}
            curObject={curObject}
            setcurObject={setcurObject}
          />
        </Box>
      </Modal>

      <Modal
        open={editOpenObj}
        onClose={handleCloseEditObj}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modal}>
          <EditContent
            handleCloseEditObj={handleCloseEditObj}
            oIndex={oIndex}
            setOIndex={setOIndex}
            curObject={curObject}
            setcurObject={setcurObject}
            getObjects={getObjects}
          />
        </Box>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={editopen}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={editopen}>
          <Publish
            handleEditClose={handleEditClose}
            tenants={tenants}
            topicRowData={topicRowData}
            object={true}
            getObjects={getObjects}
          />
        </Fade>
      </Modal> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modal}>
          <ObjectPlayer
            handleClose={handleClose}
            oIndex={oIndex}
            setOIndex={setOIndex}
            curObject={curObject}
            setcurObject={setcurObject}
          />
        </Box>
      </Modal>

      <Modal
        open={editOpenObj}
        onClose={handleCloseEditObj}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modal}>
          <EditContent
            handleCloseEditObj={handleCloseEditObj}
            oIndex={oIndex}
            setOIndex={setOIndex}
            curObject={curObject}
            setcurObject={setcurObject}
            getObjects={getObjects}
          />
        </Box>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={editopen}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={editopen}>
          <Publish
            handleEditClose={handleEditClose}
            tenants={tenants}
            topicRowData={topicRowData}
            object={true}
            getObjects={getObjects}
          />
        </Fade>
      </Modal>
      {loading === false ?
      
      <DataGrid
        rows={rows}
        columns={columns}
        className={classes[".MuiDataGrid-root"]}
        components={{
          Toolbar: CustomToolbar,
        }}
        rowHeight={100}
        disableSelectionOnClick
      />
:     (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Skeleton variant="rect" width="100%" height={600} />{" "}
        </div>
)}
    </div>
  );
}
