// Dependencies imports
import React, { useState, useEffect } from "react";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import swal from "sweetalert";
import { API } from "aws-amplify";
import BackDrop from "@material-ui/core/Backdrop/Backdrop";

// Local imports
import config from "../../../config/aws-exports";

// Style imports
import Mark from "./MarkPopular.module.scss";
import { Constants } from "../../../config/Constants";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
  formControl: {
    border: "1px solid #6a6a6a",
    height: "fit-content",
    width: "100%",
    borderRadius: "5px",
    fontSize: "14px",
    fontFamily: "nunito",
    padding: "5px",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  program: {
    fontSize: "14px",
    color: "black",
    fontWeight: "400",
    paddingLeft: "5px",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MarkPopularCourse = ({ tabledata, handleClosePopular }) => {
  console.log("table", tabledata);
  const classes = useStyles();
  const [sessionList, setSessionList] = useState([]);
  const [sessionName, setSessionName] = useState([]);
  const [sessionError, setSessionError] = useState("");
  const [selectedPopular, setSelectedPopular] = useState([]);
  const [spin, setSpin] = useState(false);

  useEffect(() => {
    getPopular();

    return () => {};
  }, []);

  const getPopular = async () => {
    setSpin(true);
    const bodyParam = {
      body: {
        ptype: "getCourse",
        schema: Constants.SCHEMA_NAME,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_POPULAR_COURSE_OR_SESSION,
        bodyParam
      );

      console.log("response", response);
      tabledataValues(response.body.ids);
      setSelectedPopular(response.body.ids);
    } catch (error) {
      console.log("getUserError", error);
    }
  };

  function tabledataValues(response) {
    let sessionList = [];
    let sessionName = [];
    let temparr = [];
    if (tabledata !== undefined) {
      for (let i = 0; i < tabledata.length; i++) {
        let obj = {};
        obj.SN = tabledata[i].tn;
        obj.SID = tabledata[i].id;
        for (let k = 0; k < response.length; k++) {
          if (obj.SID === response[k]) {
            temparr.push(obj.SN);
          }
        }
        sessionList.push(obj);
        // console.log("sessionList", obj.SN);
        sessionName.push(obj.SN);
      }
    }
    setSessionList(tabledata);
    setSessionName(temparr);
    setSpin(false);
  }

  function validatePopular() {
    let formIsValid = true;
    setSessionError("");

    if (sessionName.length === 0) {
      formIsValid = false;
      setSessionError("Please select atleast one popular course");
      return;
    }
    if (formIsValid === true) {
      pushPopular();
    }
  }

  console.log("sessionList", sessionList);
  console.log("sessionname", sessionName);

  const pushPopular = async () => {
    setSpin(true);
    const bodyParam = {
      body: {
        ptype: "course",
        ids: selectedPopular,
        schema: Constants.SCHEMA_NAME,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.POPULAR_SESSION,
        bodyParam
      );
      handleClosePopular();
      swal({
        title: "Success",
        text: "Course marked popular",
        icon: "success",
        dangerMode: false,
        closeOnClickOutside: false,
      }).then((willDelete) => {
        if (willDelete) {
          setSpin(false);
        }
      });
    } catch (error) {
      swal({
        title: "Oooops!",
        text: "Soomthing wrong!",
        icon: "warning",
        dangerMode: true,
        closeOnClickOutside: false,
      }).then((willDelete) => {
        if (willDelete) {
          setSpin(false);
        }
      });
      console.log("getUserError", error);
    }
  };

  const handleChange = (event) => {
    let selectArray = event.target.value;
    let temparr = [];
    for (let i = 0; i < sessionList.length; i++) {
      let obj = {};
      obj.SN = sessionList[i].tn;
      obj.SID = sessionList[i].id;
      for (let k = 0; k < selectArray.length; k++) {
        if (obj.SN === selectArray[k]) {
          temparr.push(sessionList[i].id);
        }
      }
    }
    console.log("temparr", temparr);
    setSelectedPopular(temparr);
    setSessionName(event.target.value);
  };

  console.log("sessiondata", sessionList);
  console.log("personname", sessionName);
  console.log("selectedPop", selectedPopular);

  const arrayDateFilter = sessionList.filter((innArr, index) => {
    /*     let startDate = innArr.DTLS.STARTDATE;
    let convertedDate = moment(startDate).format("L");
    console.log("trueSTA", startDate);
    console.log("trueCON", convertedDate); */

    // console.log("true", convertedDate === myDateT);
    if (!innArr.tenants == undefined || !innArr.tenants?.length == 0) {
      return innArr;
    }
  });
  console.log("jkdsfhj", arrayDateFilter);

  return (
    <div className={Mark.mainpopup}>
      <BackDrop sx={{ color: "#fff" }} open={spin} className={classes.backdrop}>
        <CircularProgress color="inherit" />
      </BackDrop>
      <h4 className={Mark.heading}>Mark Popular Course</h4>
      <div
        style={{
          color: "#cc0000",
          textAlign: "center",
          fontSize: "10px",
        }}
      >
        {sessionError}
      </div>
      {/*  <div style={{ paddingBottom: "5px" }}> Select Sessions</div> */}
      <FormControl className={classes.formControl}>
        {/* <InputLabel
          id="demo-mutiple-checkbox-label"
          className={classes.program}
        >
          Select Sessions
        </InputLabel> */}

        <Select
          labelId="demo-mutiple-checkbox-label"
          id="demo-mutiple-checkbox"
          multiple
          value={sessionName}
          onChange={handleChange}
          input={<Input />}
          renderValue={(selected) => {
            selected.join(", ");
            let temp = ["Course List"];
            /*  for (let i = 0; i < selected.length; i++) {
              temp.push(selected[i]);
            } */
            return temp;
          }}
          MenuProps={MenuProps}
        >
          {arrayDateFilter.map((item) => (
            <MenuItem key={item} value={item.tn}>
              <Checkbox checked={sessionName.indexOf(item.tn) > -1} />
              <ListItemText primary={item.tn} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <h3 style={{ textAlign: "center", fontSize: "14px" }}>Selected Course</h3>
      <div style={{ textAlign: "left", fontSize: "12px" }}>
        <ol>
          {sessionName.map((item) => (
            <li>{item}</li>
          ))}
        </ol>
      </div>
      <div className={Mark.buttonholder}>
        <button className={Mark.cancel} onClick={handleClosePopular}>
          Cancel
        </button>
        <button className={Mark.submit} onClick={() => validatePopular()}>
          Submit
        </button>
      </div>
    </div>
  );
};

export default MarkPopularCourse;
