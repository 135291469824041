import React, { useState, useEffect, useRef } from "react";

import Table from "@mui/material/Table";
import { API } from "aws-amplify";
import config from "../../../config/aws-exports";
import Button from "@mui/material/Button";
import swal from "sweetalert2";
import swal1 from "@sweetalert/with-react";
import Checkbox from "@mui/material/Checkbox";
import { CSVLink } from "react-csv";

import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import BackDrop from "@material-ui/core/Backdrop/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { Constants } from "../../../config/Constants";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { authData } from "../../../redux/auth/authSlice";

// Local imports
import { ReactComponent as Close } from "../../../assets/icons/close_black_24dp.svg";
import Feedback from "./Feedback";
import { FileDownload } from "@mui/icons-material";

// Styles imports
import Ap from "./ApproveUsers.module.scss";
import Li from "./ListofUsers.module.scss";
import CertificatePopUp from "./CertificatePopUp";
import { Box } from "@mui/material";
import ProInvoice from "../ProformaInvoice/ProInvoice";
import listTopStyle from "./listTopics.module.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
}));

const header = {
  color: "white",
  fontSize: "16px",
  fontWeight: "400px",
};

export default function RegisteredUsers(props) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  let { handleCloseList, listForReg, tenantoid, tenant, listSessions } = props;
  const [page, setPage] = useState(0);
  const [imageExtentionError, setImageExtentionError] = useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [invoiceimg, setInvoiceimg] = useState(null);

  const [invoiceFileName, setInvoiceFileName] = useState(null);
  const [certificateFileName, setCertificateFileName] = useState(null);
  const [registeredUsers, setRegisteredUsers] = useState([]);

  const [spin, setSpin] = useState(false);
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [invoiceOpen, setInvoiceOpen] = useState(false);
  const [viewingState, setViewingState] = useState({});
  const [certificateOpen, setCertificateOpen] = useState(false);
  const [userDetailsReg, setUserDetailsReg] = useState({});
  const refselectedInvoiceFile = useRef();
  const [newArray, setNewArray] = useState([]);
  const [feedData, setFeedData] = useState({});
  const [inputValue, setInputValue] = useState([]);

  let userDetails = useSelector(authData);
  const [openProforma, setOpenProforma] = useState(false);
  const [userInvoiceData, setUserInvoiceData] = useState({});
  const [actaualAmt, setActualAmt] = useState("");
  const [actaualAmtUnit, setActualAmtUnit] = useState("");
  const [data, setData] = useState([]);
  const [headers, setHeaders] = useState();
  function handleOpenProforma(item) {
    setOpenProforma(true);
    setUserInvoiceData(item);
  }

  const handleCloseProforma = () => {
    setOpenProforma(false);
  };

  useEffect(() => {
    setHeaders([
      { label: "First Name", key: "first_name" },
      { label: "Last Name", key: "last_name" },
      { label: "Email", key: "emailid" },
      { label: "Attendance", key: "attendance" },
      { label: "Actual Amount", key: "actual_amount" },
    ]);
    
    if (listForReg.paid == true || listForReg.paid == "true") {
      setHeaders(prevHeaders => [
        ...prevHeaders,
        { label: "Payment Method", key: "paymentstatus" }
      ]);
    }
    getRegisteredUsers();
  }, []);

  const handleViewClick = (item) => {

    setViewingState(item);
    setInvoiceOpen(true);
  };

  const handleFeedBackOpen = (item) => {
    setFeedbackOpen(true);
    setFeedData(item);
   
  };

  const handleFeedBackClose = () => {
    setFeedbackOpen(false);
  };

  const handleClickCertificateOpen = (item) => {
    setUserDetailsReg(item);
    setCertificateOpen(true);
  };

  async function getRegisteredUsers() {
    setSpin(true);
    const bodyParam = {
      body: {
        sid: listForReg.sid,
        type: "session",
        oid: config.aws_org_id,
        typeusers: "reg_users",
        tenantoid: tenantoid,
        tenant: tenant,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const reg = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_REGISTERED_USERS,
        bodyParam
      );
      
      setData(funData(reg.body));
      for (let i = 0; i < reg.body.length; i++) {
        reg.body[i].updated_amount = false;
      }
      setRegisteredUsers(reg.body);
      CopyOldAttendence(reg.body);
      setSpin(false);
     
    } catch (error) {
      
      console.error(error);
      setSpin(false);
    }
  }
  const funData = (data) => {
    
    let datalist = data.map((item) => {
    
      item.paymentstatus = item?.pstatus == 0 ? "NEFT": item?.pstatus == 1 ? "RazorPay": item?.pstatus == 2 ? "Pay Later": "Payment in process";
      item.attendance = item?.attend == 1 ? "Attended": "Not Attended";
      return item;
    });
    return datalist;
  };
  const getExtension = (filename) => {
    let parts = filename.split(".");
    return parts[parts.length - 1];
  };

  const isDoc = (filename) => {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "pdf":
        return true;
      default:
        return false;
    }
  };

  const handleimageRE = (event, item) => {
    
    if (!isDoc(event.target.files[0].name)) {
      swal1("Wrong file format! Accepted file format: .pdf");
      return;
    }
    setImageExtentionError("");
    setSpin(true);

    setInvoiceimg({ file: event.target.files[0] });
    refselectedInvoiceFile.current = event.target.files[0];
    handleimageUpload(event.target.files[0], item);
  };

  function handleimageUpload(imageGot, item) {
  
    if (imageGot == null) {
      return;
    }

    if (imageGot.size > 5242880) {
      swal1(
        "Oops!",
        "File size is too large. File size cannot be greater than 5mb.",
        "error"
      );
      return;
    }
    if (imageGot !== undefined) {
      let uniqueStamp = new Date().getTime();
      let invoiceID = uniqueStamp + ".pdf";
      setInvoiceFileName(uniqueStamp + ".pdf");

      var body = {
        type: "uploadInvoice",
        oid: config.aws_org_id,
        filetype: imageGot.type,
        filename: invoiceID,
      };

      uploadfile(body, imageGot, invoiceID, item);
    }
  }

  async function uploadfile(body, file, invoiceID, item) {
   
    const bodyParam = {
      body,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const preSignedData = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_PRESIGNED_URL,
        bodyParam
      );

      axios
        .put(preSignedData, file, {
          headers: {
            "Content-Type": file.type,
            "Cache-Control": "no-cache no-store must-revalidate",
          },
        })
        .then(() => {
          
          uploadInvoice(invoiceID, item);
        })

        .catch((error) => {
          console.error("There was an error!", error);
        });

    } catch (error) {
     
      console.error(error);
    }
  }

  async function uploadInvoice(invoiceID, item) {
   
    const bodyParam = {
      body: {
        sid: listForReg.sid,
        ur_id: item.ur_id,
        invoiceID: invoiceID,
        oid: config.aws_org_id,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const upload = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.UPLOAD_INVOICE,

        bodyParam
      );

      refselectedInvoiceFile.current = null;
      getRegisteredUsers();
      setSpin(false);
      swal1("Uploded Sucessfully");
     
    } catch (error) {
      
      swal1("Uploded unsucessfull");
      console.error(error);
      setSpin(false);
    }
  }

  async function updateAttendence(unmark) {
    setSpin(true);
  
    const bodyParam = {
      body: {
        sid: listForReg.sid,
        unmark: unmark,
        mark: newArray,
        oid: config.aws_org_id,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      await API.post(
        config.aws_cloud_logic_custom_name,
        "/markAttendence ",

        bodyParam
      );

      getRegisteredUsers();
      setSpin(false);
      swal1("Attendence marked Sucessfully");
      
    } catch (error) {
      
      setSpin(false);
    }
  }

  async function uploadfileCertificate(body, file, CertID, item) {
   
    const bodyParam = {
      body,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const preSignedData = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_PRESIGNED_URL,
        bodyParam
      );

      axios
        .put(preSignedData, file, {
          headers: {
            "Content-Type": file.type,
            "Cache-Control": "no-cache no-store must-revalidate",
          },
        })
        .then(() => {
          uploadCertificate(CertID, item);
        })

        .catch((error) => {
          console.error("There was an error!", error);
        });

    } catch (error) {
      console.error(error);
    }
  }
  function CopyOldAttendence(registeredUsersGot) {
    let temp = [];
    if (registeredUsersGot !== null) {
      if (registeredUsersGot !== undefined && registeredUsersGot.length > 0) {
        for (let i = 0; i < registeredUsersGot.length; i++) {
          if (registeredUsersGot[i].attend) {
            temp.push(registeredUsersGot[i].ur_id.toString());
          }
        }
      }
    }
    setNewArray(temp);
  }

  const handleClick = (item) => {
    let tempArray = [...newArray];
    if (tempArray.includes(item)) {
      var index = tempArray.indexOf(item);
      tempArray.splice(index, 1);
    } else {
      tempArray.push(item);
    }
    setNewArray(tempArray);
  };

  async function uploadCertificate(CertID, item) {
    item.certid = CertID;
    const bodyParam = {
      body: {
        sessionID: listForReg.SID,
        uData: item,

        eid: item.eid,
        tenant: item.tenant,

        oid: config.aws_org_id,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      await API.post(
        config.aws_cloud_logic_custom_name,
        "/uploadCert",

        bodyParam
      );
      getRegisteredUsers();
      setSpin(false);

      swal
        .fire({
          title: "Success",
          text: "Uploaded successfully",
          icon: "success",
          dangerMode: false,
        })
        .then((willDelete) => {
          if (willDelete) {
            setSpin(false);
          }
        });
      
    } catch (error) {
     
      swal
        .fire({
          title: "Oooops!",
          text: "Soomthing wrong!",
          icon: "warning",
          dangerMode: true,
        })
        .then((willDelete) => {
          if (willDelete) {
            setSpin(false);
          }
        });
      console.error(error);
      setSpin(false);
    }
  }

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function markAttendance() {
    let unmark = [];
    for (let i = 0; i < registeredUsers.length; i++) {
      if (!newArray.includes(registeredUsers[i].ur_id.toString())) {
        unmark.push(registeredUsers[i].ur_id.toString());
      }
    }
    updateAttendence(unmark);
  }

  const disableCheckBox = (item) => {
  
    if (item?.date_list && item?.date_list.length > 0) {
      if (
        new Date().getTime() >=
        new Date(
          item?.date_list[item?.date_list?.length - 1].combineEndTime
        ).getTime()
      ) {
        return false;
      } else {
        return true;
      }
    }
    return true;
  };
  const handleSave = async () => {
    let newArr = [];
    let upd_value = [...registeredUsers];
    upd_value?.forEach((value) => {
      if (value.actual_amount) {
        let obj = {};
        obj["amount"] = parseFloat(value?.actual_amount);
        obj["ur_id"] = value?.ur_id;
        newArr.push(obj);
      }
    });

    const bodyParam = {
      body: {
        type: "session",
        id: listForReg.sid,
        userJson: newArr,
        schema: "uspsm",
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.UPDATE_ACTUAL_AMOUNT,
        bodyParam
      );
      
      swal1("Success", "Amount Updated Successfully", "success");
      listSessions();
    } catch (error) {
      console.error(error);
    }
    setInputValue("");
  };
 
 
  return (
    <div className={Ap.maincontainer}>
      <BackDrop sx={{ color: "#fff" }} open={spin} className={classes.backdrop}>
        <CircularProgress color="inherit" />
      </BackDrop>
      <div className={Ap.topbar}>
        <h3 className={Ap.header}>Registered Participants</h3>
        <Close className={Ap.closeicon} onClick={() => handleCloseList()} />
      </div>
      {userDetails.role !== "Manager" &&
        listForReg.startdate != null &&
        listForReg.date_list == null && (
          <div className={Ap.attendencediv}>
            {tenantoid === false ? (
              newArray.length === 0 ? (
                <div></div>
              ) : parseInt(listForReg.startdate) > new Date().getTime() ? (
                "Session not started"
              ) : (
                <div>
                <button
                  className={Li.addbutton}
                  onClick={() => markAttendance()}
                >
                  Submit Attendance
                </button>
                
              </div>
              )
            ) : null}
          </div>
        )}
      {/* {showButton ? (
        <div className={Ap.attendencediv}>
          <button className={Li.savebutton} onClick={handleSave}>
            Save
          </button>
        </div>
      ) : null} */}
      {userDetails.role !== "Manager" &&
        listForReg.date_list != null &&
        listForReg.startdate == null && (
          <div className={Ap.attendencediv}>
            {tenantoid === false ? (
              newArray.length === 0 ? (
                <div></div>
              ) : new Date(listForReg.date_list.combineStartTime).getTime() >
                new Date().getTime() ? (
                "Session not started"
              ) : (
                <div>
                <button
                  className={Li.addbutton}
                  onClick={() => markAttendance()}
                >
                  Submit Attendance
                </button>
              </div>
              )
            ) : null}
             <Button color="primary">
                <CSVLink
                  data={data}
                  headers={headers}
                  filename="Registered-Users"
                  className={listTopStyle.exp_btn}
                >
                   <FileDownload />
                  Export
                </CSVLink>
              </Button>
          </div>
        )}

      <Dialog
        open={feedbackOpen}
        aria-labelledby="responsive-dialog-title"
        scroll="paper"
        maxWidth="lg"
      >
        <Feedback
          handleFeedBackClose={handleFeedBackClose}
          feedData={feedData}
        />
      </Dialog>
      
      <Table>
        <TableHead sx={{ background: "#285780" }}>
          <TableRow>
            <TableCell style={header}>First Name</TableCell>
            <TableCell style={header}>Last Name</TableCell>
            <TableCell style={header}>Email</TableCell>
            {tenantoid === true ? (
              <TableCell style={header}>Feedback</TableCell>
            ) : null}

            {userDetails.role === "Manager" ? null : tenantoid === false ? (
              listForReg.paid === "false" ? null : (
                <TableCell style={header}>Invoice</TableCell>
              )
            ) : null}

            {tenantoid === false ? (
              listForReg.paid === "false" ? null : (
                <TableCell style={header}>Payment Method</TableCell>
              )
            ) : null}
            {tenantoid === false ? (
              <TableCell style={header}>Assigness</TableCell>
            ) : null}
            {userDetails.role === "Manager" ? null : tenantoid === false ? (
              <TableCell style={header}>Attendance</TableCell>
            ) : null}
            <TableCell style={header}>Certificate</TableCell>
            <TableCell style={header}>Actual Amount</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {(rowsPerPage > 0
            ? registeredUsers?.slice(
              page * rowsPerPage,
              page * rowsPerPage + rowsPerPage
            )
            : registeredUsers
          )?.map((item, i) => (
            <TableRow>
              <TableCell>{item?.first_name}</TableCell>
              <TableCell>{item?.last_name}</TableCell>
              <TableCell>{item?.emailid}</TableCell>
              {tenantoid === true ? (
                <TableCell>
                  {item?.fbstatus || item?.feedback ? (
                    <h3 style={{ fontSize: "14px", fontWeight: "300" }}>
                      No Feedback
                    </h3>
                  ) : (
                    <button
                      onClick={() => handleFeedBackOpen(item)}
                      className={Ap.feedbackbutton}
                    >
                      View Feedback
                    </button>
                  )}
                </TableCell>
              ) : null}

              {userDetails.role === "Manager" ? null : tenantoid === false ? (
                listForReg.paid === "false" ? null : item?.inid === undefined ||
                  item?.inid === null ? (
                  <TableCell>
                    <div>
                      <button
                        className={Li.addbutton}
                        onClick={() => {
                          handleOpenProforma(item);
                        }}
                      >
                        Update Invoice
                      </button>
                     
                    </div>
                  </TableCell>
                ) : (
                  <TableCell>
                    {item.invoice_dtls === null ? (
                      <div>
                        <div>
                          <button
                            className={Li.addbutton}
                            onClick={() => handleViewClick(item)}
                            style={{
                              marginTop: "10px",
                              marginBottom: "10px",
                            }}
                          >
                            View
                          </button>
                        </div>
                        <div>
                          <span>To Reupload </span>
                          <input
                            accept=".pdf"
                           
                            multiple
                            type="file"
                            
                            onChange={(e) => handleimageRE(e, item)}
                          />
                        </div>
                      </div>
                    ) : (
                      <div>
                        <button
                          className={Li.addbutton}
                          onClick={() => {
                            handleOpenProforma(item);
                          }}
                        >
                          Update Invoice
                        </button>
                      </div>
                    )}
                  </TableCell>
                )
              ) : null}

              {tenantoid === false ? (
                listForReg.paid === "false" ? null : (
                  <TableCell>
                    {item.pstatus === 0 ? "NEFT" : null}
                    {item.pstatus === 1 ? "RazorPay" : null}
                    {item.pstatus === 2 ? "Pay Later" : null}{" "}
                    {item.pstatus === 10 ? "Payment in process" : null}
                  </TableCell>
                )
              ) : null}
              {tenantoid === false ? (
                <TableCell>{item.assign}</TableCell>
              ) : null}
              {userDetails.role === "Manager" ? null : tenantoid === false ? (
                <TableCell>
                  {" "}
                 
                  <Checkbox
                    disabled={disableCheckBox(item)}
                    checked={newArray.includes(item.ur_id.toString())}
                    onChange={(e) => handleClick(item.ur_id.toString())}
                  />
                
                </TableCell>
              ) : null}

              
              {item.aprstatus === 1 &&
                item.attend &&
                (item.fbstatus || item?.feedback) ? (
               
                <TableCell>
                  <button
                    className={Li.addbutton}
                    onClick={() => {
                      handleClickCertificateOpen(item);
                    }}
                  >
                    View certificate
                  </button>
                </TableCell>
              ) : (
                <TableCell>
                  <p>No certificates yet</p>
                </TableCell>
              )}
              <TableCell>
                <Box sx={{ width: 350 }}>
                 
                  {`${item.invoice_dtls?.unit || ""} ${item.actual_amount || ""
                    }`}
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
              colSpan={tenantoid === true ? 5 : 7}
              count={registeredUsers?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { "aria-label": "rows per page" },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>

      <Dialog
        fullScreen={fullScreen}
        open={invoiceOpen}
        aria-labelledby="responsive-dialog-title-2"
        maxWidth="md"
      >
        <div style={{ padding: "20px" }}>
          <Close
            className={Ap.closeicon}
            style={{ float: "right" }}
            onClick={() => setInvoiceOpen(false)}
          />
          <h5>Invoice - {viewingState.first_name} </h5>
          <iframe
            style={{
              width: "750px",
              height: "800px",
              borderRadius: "10px",
            }}
            src={`https://${config.DOMAIN
              }/${config.aws_org_id.toLowerCase()}-resources/documents/invoices/${viewingState.inid
              }`}
            height="100%"
            width="100%"
            title="pdfView"
          />
         
        </div>
      </Dialog>
      
      <div style={{ padding: "20px" }}>
        <CertificatePopUp
          open={certificateOpen}
          setOpen={setCertificateOpen}
          details={userDetailsReg}
          sData={listForReg}
        />
      </div>
     
      <ProInvoice
        handleCloseProforma={handleCloseProforma}
        open={openProforma}
        // openProformaEdit={openProformaEdit}
        listForReg={listForReg}
        userInvoiceData={userInvoiceData}
        setActualAmt={setActualAmt}
        setActualAmtUnit={setActualAmtUnit}
        getRegisteredUsers={getRegisteredUsers}
        listSessions={listSessions}
      />
     
    </div>
  );
}
