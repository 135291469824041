export default function validateResetPassword(values) {
  let errors = {};
  var regularExpression =
    /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/;
  if (!values.resetPassword) {
    errors.resetPassword = "New password is required";
  } else if (values.resetPassword !== values.confirmPassword) {
    errors.resetPassword = "Passwords don't Match!";
  } else {
    if (!regularExpression.test(values.resetPassword)) {
      errors.resetPassword = "Match password pattern";
    }
  }
  if (!values.confirmPassword) {
    errors.passwordConfirm = "Confirmation password is required";
  }
  if (!values.name) {
    errors.name = "Please enter a name";
  }

  return errors;
}
