import React from "react";
import ELearning from "../components/pages/Content/ELearning";
import SideNav from "../components/SideNav/sideNav";
import Header from "../components/Header/hearder";
import { Wrapper } from "./layout.styles";

const CategoryLayout = () => {
  return (
    <React.Fragment>
      <Header />
      <Wrapper>
        <SideNav />
        <div style={{ margin: "70px 0px 0px 200px", width: "100%" }}>
          <ELearning />
        </div>
      </Wrapper>
    </React.Fragment>
  );
};

export default CategoryLayout;
