import React, { useEffect, useState } from "react";

import { ReactComponent as Close } from "../../../assets/icons/close_black_24dp.svg";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../../redux/auth/authSlice";
import User from "./AddUsers.module.scss";
import { Constants } from "../../../config/Constants";
import config from "../../../config/aws-exports";
import { API } from "aws-amplify";
import swal from "sweetalert";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
  },
}));

const CreateUser = (props) => {
  let { handleAddClose, getUser, rows } = props;
  const [userNameErr, setUserNameErr] = useState("");
  const [lastNameErr, setLastNameErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [pnoErr, setPnoErr] = useState("");
  const [genderErr, setGenderErr] = useState("");
  const [spin, setSpin] = useState(false);
  const classes = useStyles();
  const [userName, setUserName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [pno, setPno] = useState("");
  const [gender, setGender] = useState("");
  const [address, setAddress] = useState("");

  let userDetails = useSelector(authData);

  const onChangeHandlerUser = (event) => {
    setUserName(event.target.value);
  };

  const onChangeHandlerLastName = (event) => {
    setLastName(event.target.value);
  };

  const onChangeHandlerEmail = (event) => {
    setEmail(event.target.value);
  };
  const onChangeHandlerPno = (event) => {
    setPno(event.target.value);
  };
  const onChangeHandlerGender = (event) => {
    setGender(event.target.value);
  };
  const onChangeHandlerAddress = (event) => {
    setAddress(event.target.value);
  };
  // useEffect(() => {
  //     getUsersDetails();
  //   }, []);
  const onsubmit = () => {
    console.log("data" + JSON.stringify(rows));
    let formIsValid = true;
    setUserNameErr("");
    setLastNameErr("");
    setEmailErr("");
    setPnoErr("");
    setGenderErr("");

    if (
      userName === "" ||
      userName === null ||
      (userName != undefined && userName.trim() === "")
    ) {
      formIsValid = false;
      setUserNameErr("Please enter user name");
      return;
    }
    if (
      lastName === "" ||
      lastName === null ||
      (lastName != undefined && lastName.trim() === "")
    ) {
      formIsValid = false;
      setLastNameErr("Please enter Last name");
      return;
    }
    if (
      email === "" ||
      email === null ||
      (email != undefined && email.trim() === "")
    ) {
      formIsValid = false;
      setEmailErr("*Please enter your email-ID.");
      return;
    }
    if (
      pno !== null &&
      pno !== undefined &&
      pno.trim() !== "" &&
      pno.length < 10
    ) {
      formIsValid = false;
      setPnoErr(
        "Please enter a valid phone number (should be atleast 10 digits)"
      );
      return;
    }
    if (gender === "" || gender === null) {
      formIsValid = false;
      setGenderErr("Please select gender");
      return;
    }
    if (userName !== "") {
      if (!userName.match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        setUserNameErr("*Please enter alphabet characters only.");
        return;
      }
    }
    if (email !== "") {
      //regular expression for email validation
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(email)) {
        formIsValid = false;
        setEmailErr("*Please enter valid email-ID.");
        return;
      }
    }
    if (rows != undefined && rows.length > 0) {
      for (let i = 0; i < rows.length; i++) {
        if (email == rows[i].col2) {
          setEmailErr("User Already Exists as " + rows[i].col3);
          return;
        }
      }
    }

    if (formIsValid == true) {
      Updateuser();
    }

    async function Updateuser() {
      setSpin(true);
      const bodyParam = {
        body: {
          oid: config.aws_org_id,
          ur_id: 0,
          tenant: userDetails.locale,
          emailid: email.toLowerCase(),
          first_name: userName,
          last_name: lastName,
          dev: 2,
          gen: parseInt(gender),
          urole: "User",
          st_id: userDetails.st_id,
          address: address,
          uid: "",
          cno: pno === "" ? null : pno,
          schema: Constants.SCHEMA_NAME,
          atype: 0,
          //   groups:[null]
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      console.log("bd" + JSON.stringify(bodyParam.body));
      try {
        const response = await API.post(
          config.aws_cloud_logic_custom_name,
          Constants.ADD_USER,
          bodyParam
          //`${Constants.GET_MY_PROGRAMS}`, bodyParam,
        );
        // const users = response;
        console.log(JSON.stringify(response));
        getUser();
        handleAddClose();
          swal({
            title: "Success",
            text: response.message,
            icon: "success",
            dangerMode: false,
          }).then((willDelete) => {
            if (willDelete) {
              setSpin(false);
            }
          });
          
      } catch (error) {
        setSpin(false);
        handleAddClose();
        swal({
          title: "Oooops!",
          text: "Something went wrong!",
          icon: "warning",
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            setSpin(false);
          }
        });
        console.log("getUserError", error);
      }
      console.log("Mount");
    }
  };

  return (
    <div className={User.maindiv}>
      {spin === false ? (
        <div className={User.maincontainer}>
          <div className={User.iconcontainer}>
          <h3 className={User.create}>Add user</h3>
            <Close className={User.closebutton} onClick={handleAddClose} />
          </div>
          
          <div className={User.form}>
            <div className={User.field}>
              <label
                style={{
                  fontSize: "16px",
                  fontWeight: "500px",
                  fontFamily: "nunito",
                }}
                for="name"
                required
              >
                Name
                <span style={{ color: "red", marginTop: "-10px" }}>*</span>
              </label>
              <input
                style={{
                  border: "1px solid lightgrey",
                  borderRadius: "5px",
                  width: "80%",
                  background: "white",
                  height: "30px",
                  marginBottom: "20px",
                  fontSize: "15px",
                  paddingLeft: "10px",
                  fontWeight: "200px",
                }}
                type="text"
                id="name"
                onChange={onChangeHandlerUser}
                value={userName}
                required
              />
            </div>
            <div
              style={{
                color: "#cc0000",
                marginBottom: "12px",
                marginTop: "-10px",
                textAlign: "start",
                marginLeft: "20%",
              }}
            >
              {userNameErr}
            </div>
            <div className={User.field}>
              <label
                style={{
                  fontSize: "14px",
                  fontWeight: "400px",
                }}
                for="name"
                required
              >
                Last Name
                <span style={{ color: "red", marginTop: "-10px" }}>*</span>
              </label>
              <input
                style={{
                  border: "1px solid lightgrey",
                  borderRadius: "5px",
                  width: "80%",
                  background: "white",
                  height: "30px",
                  marginBottom: "20px",
                  fontSize: "15px",
                  paddingLeft: "10px",
                  fontWeight: "200px",
                }}
                type="text"
                id="name"
                onChange={onChangeHandlerLastName}
                value={lastName}
                required
              />
            </div>
            <div
              style={{
                color: "#cc0000",
                marginBottom: "12px",
                marginTop: "-10px",
                textAlign: "start",
                marginLeft: "20%",
              }}
            >
              {lastNameErr}
            </div>
            <div className={User.field}>
              <label
                style={{
                  fontSize: "16px",
                  fontWeight: "500px",
                  fontFamily: "nunito",
                }}
                for="email"
                required
              >
                Email
                <span style={{ color: "red", marginTop: "-10px" }}>*</span>
              </label>
              <input
                style={{
                    border: "1px solid lightgrey",
                    borderRadius: "5px",
                    width: "80%",
                    background: "white",
                    height: "30px",
                    marginBottom: "20px",
                    fontSize: "15px",
                    paddingLeft: "10px",
                    fontWeight: "200px",
                }}
                type="email"
                id="email"
                onChange={onChangeHandlerEmail}
                value={email}
                required
              />
            </div>
            <div
              style={{
                color: "#cc0000",
                marginBottom: "12px",
                marginTop: "-10px",
                textAlign: "start",
                marginLeft: "20%",
              }}
            >
              {emailErr}
            </div>

            <div className={User.field}>
              <label
                style={{
                  fontSize: "16px",
                  fontWeight: "500px",
                  fontFamily: "nunito",
                }}
                for="contact"
              >
                Ph. No
              </label>
              <input
                style={{
                    border: "1px solid lightgrey",
                    borderRadius: "5px",
                    width: "80%",
                    background: "white",
                    height: "30px",
                    marginBottom: "20px",
                    fontSize: "15px",
                    paddingLeft: "10px",
                    fontWeight: "200px",
                }}
                type="number"
                id="contact"
                onChange={onChangeHandlerPno}
                value={pno}
              />
            </div>
            <div
              style={{
                color: "#cc0000",
                marginBottom: "12px",
                marginTop: "-10px",
                textAlign: "start",
                marginLeft: "20%",
              }}
            >
              {pnoErr}
            </div>

            <div className={User.field}>
              <label
                style={{
                  fontSize: "16px",
                  fontWeight: "500px",
                  fontFamily: "nunito",
                }}
                for="gender"
              >
                Gender
              </label>
              <select
                style={{
                    border: "1px solid lightgrey",
                    borderRadius: "5px",
                    width: "80%",
                    background: "white",
                    height: "30px",
                    marginBottom: "20px",
                    fontSize: "15px",
                    paddingLeft: "10px",
                    fontWeight: "200px",
                }}
                name="gender"
                id="gender"
                onChange={onChangeHandlerGender}
                value={gender}
              >
                <option value= "0">Select</option>
                <option value="1">Male</option>
                <option value="2">Female</option>
              </select>
            </div>
            <div
              style={{
                color: "#cc0000",
                marginBottom: "12px",
                marginTop: "-10px",
                textAlign: "start",
                marginLeft: "20%",
              }}
            >
              {genderErr}
            </div>

            <div className={User.field}>
              <label
                style={{
                  fontSize: "16px",
                  fontWeight: "500px",
                  fontFamily: "nunito",
                }}
                for="address"
              >
                Address
              </label>
              <textarea
                style={{
                    border: "1px solid lightgrey",
                    borderRadius: "5px",
                    width: "80%",
                    background: "white",
                    height: "30px",
                    marginBottom: "20px",
                    fontSize: "15px",
                    paddingLeft: "10px",
                    fontWeight: "200px",
                }}
                id="address"
                rows="4"
                onChange={onChangeHandlerAddress}
                value={address}
              />
            </div>
            <button
              type="submit"
              onClick={onsubmit}
              className={User.btn_color}
            >Submit</button>
          </div>
        </div>
      ) : (
        <div className={classes.root}>
          <CircularProgress style={{ color: config.main_color_1 }} />
        </div>
      )}
    </div>
  );
};

export default CreateUser;
