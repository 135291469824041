import React, { useState, useRef, useEffect } from "react";
import AddCon from "./AddContent.module.scss";
import { ReactComponent as Close } from "../../../assets/icons/close_black_24dp.svg";
import { API } from "aws-amplify";
import config from "../../../config/aws-exports";
import {Constants} from "../../../config/Constants";
import Video from "../../../assets/svgjs/Video";
import Review from "../../../assets/svgjs/Review";
import Html from "../../../assets/svgjs/Html";
import QuizIc from "../../../assets/svgjs/Quiz";
import PdfIcon from "../../../assets/svgjs/Pdf";
import Audio from "../../../assets/svg/Audio.svg";
import YouTubeVideo from "../../../assets/svgjs/Youtube";
import Vimeo from "../../../assets/svgjs/Vimeo";
import RegexTextField from "./CourseCreation/RegexTextField";
import { EDITOR_JS_TOOLS } from "./CourseCreation/EditorConstants";
import swal from "sweetalert2";
import JSZip from "jszip";
import edjsHTML from "editorjs-html";
import axios from "axios";
import { awsSignIn, authData } from "../../../redux/auth/authSlice";
import { useSelector, useDispatch } from "react-redux";
import CreateQuiz from "../../CreateQuizObject/CreateQuiz";
import EditQuiz from "../../CreateQuizObject/EditQuiz";
import BackDrop from "@material-ui/core/Backdrop/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  Modal,
  Button,
  makeStyles,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import produce from "immer";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #fff",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "1100px",
    height: "660px",
    borderRadius: ".8rem",
    overflowX: "hidden",
    overflowY: "auto",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.73)",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "98%",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function EditContent(props) {
  let { handleCloseEditObj, objectsTableView, curObject } = props;
  const classes = useStyles();
  const [selectedObj, setSelectedObj] = useState({});
  const [selectType, setSelectType] = useState(true);
  const onlyAlphanumericRegex = /[^0-9]/gi;
  const refobject = useRef({});
  const [tagArray, setTagArray] = useState([]);
  const [typesButtons, setTypeButtons] = useState(true);
  const [quizQuestion, setQuizQuestion] = useState([]);
  const [quizQueRes, setQuizRes] = useState([]);
  const instanceRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState();
  const [shareable, setShareable] = useState(false);
  const [tag, setTag] = useState("");
  const [qtype, setQtype] = useState("");
  const [changeFile, setChangeFile] = useState(false);
  const [editQuiz, setEditQuiz] = useState(false);

  let userDetails = useSelector(authData);
  const [loading, setLoading] = useState(false);
  console.log("user", userDetails);
  const iniotypes = [
    { type: "Narrative", id: "2", img: "html" },
    { type: "PDF", id: "3", img: "pdf" },
    { type: "Quiz", id: "4", img: "quiz" },
    { type: "Interactivity", id: "5", img: "Interactivity" },
    { type: "Video", id: "6", img: "video" },
    { type: "Audio", id: "7", img: "audio" },
    { type: "Youtube", id: "8", img: "youtube" },
    { type: "Vimeo", id: "9", img: "vimeo" },
    { type: "Scorm", id: "10", img: "scorm" },
  ];
  const [otypes, setOtypes] = useState(iniotypes);
  const renderSwitch = (param) => {
    if (param === "video") {
      return (
        <Video
          className="video-size"
          cls1="cls-1--v"
          cls2="cls-2--v"
          style={{ height: "32px" }}
        />
      );
    } else if (param === "audio") {
      return <img src={Audio} style={{ height: "32px" }} alt="audio" />;
    } else if (param === "quiz") {
      return <QuizIc className="quizAct" />;
    } else if (param === "html") {
      return (
        <Html
          className="video-size"
          cls1="cls-1--H"
          cls2="cls-2--H"
          cls3="cls-3--H"
        />
      );
    } else if (param === "Interactivity") {
      return <Review className="video-size" cls1="cls-1--R" cls2="cls-2--R" />;
    } else if (param === "pdf") {
      return <PdfIcon className="pdfAct" />;
    } else if (param === "youtube") {
      return <YouTubeVideo />;
    } else if (param === "vimeo") {
      return <Vimeo color="#f18121" />;
    }
  };

  const selectObj = () => {
    /*  alert(curObject.otype); */
    if (curObject.otype === "pdf") {
      sobjecttype("3", undefined);
    } else if (curObject.otype === "html") {
      sobjecttype("2", undefined);
    } else if (curObject.otype === "Interactivity") {
      sobjecttype("5", undefined);
    } else if (curObject.otype === "video") {
      sobjecttype("6", undefined);
    } else if (curObject.otype === "audio") {
      sobjecttype("7", undefined);
    } else if (curObject.otype === "youtube") {
      sobjecttype("8", undefined);
    } else if (curObject.otype === "vimeo") {
      sobjecttype("9", undefined);
    } else if (curObject.otype === "scorm") {
      sobjecttype("10", undefined);
    } else if (curObject.otype === "quiz") {
      /*  alert(curObject.otype); */
      getQuiz(curObject);
    }
  };

  useEffect(() => {
    selectObj();
    if (curObject.otype !== "quiz") {
      let mins = Math.floor(curObject.dur / 60);
      let secs = curObject.dur - mins * 60;

      setShareable(curObject.shareable);
      setTagArray(curObject.ttags);
      /*  setSelectedObj((prev) =>
        produce(prev, (temp) => {
          temp.otherdata.dur = curObject.dur;
        })
      ); */
      /*  setSelectedObj((prev) =>
        produce(prev, (temp) => {
          temp.otherdata.min = mins;
        })
      ); */
      setSelectedObj((prev) =>
        produce(prev, (temp) => {
          temp.otherdata.sec = secs.toString();
          temp.otherdata.min = mins.toString();
        })
      );
      setSelectedFile(curObject.ourl);

      if (curObject.otype === "youtube" || curObject.otype === "vimeo")
        setSelectedObj((prev) =>
          produce(prev, (temp) => {
            temp.otherdata.embeddedcode = curObject.ourl;
          })
        );
    }
  }, []);

  async function getQuiz(obj) {
    const bodyParam = {
      body: {
        quizid: obj.objid,
        action: "get",
        oid: config.aws_org_id,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        `/getQuiz`,
        bodyParam
      );

      const { qitems, qtype } = response;
      console.log("res", response);
      console.log("qitems", qitems);
      console.log("qtype", qtype);
      setQtype(qtype);
      setQuizRes(response);
      sobjecttype("4", response);
    } catch (error) {
      // alert(JSON.stringify(error));
      console.error(error);
    }
  }

  const sobjecttype = function (id, Quizresponse) {
    console.log("queeeee", quizQueRes);
    let obj = {};
    obj.otherdata = {
      title: curObject.oname,
      desc: curObject.odesc,
      dur: curObject.dur,
      files: "",
    };
    if (id == "3" || id == 3) {
      obj.pdft = true;
      obj.sobject = "PDF";
      obj.afile = ".pdf";
      obj.otype = "pdf";
      //obj.data = [{"ptitle":"","pdur":"","pfile":""}];
    } else if (id == "7" || id == 7) {
      obj.audiot = true;
      obj.sobject = "Audio";
      obj.otherdata.ytcode = "";
      obj.afile = "audio/*";
      obj.otype = "audio";
    } else if (id == "2" || id == 2) {
      obj.htmlt = true;
      obj.sobject = "Narrative";
      obj.selecthtmltype = true;
      obj.afile = ".html";
      obj.otype = "html";
      //obj.htmldata =  [{"quiloutput":"","htitle":"","hdur":"","hdursecs":"","hfile":""}];
      obj.otherdata.quiloutput = "";
    } else if (id == "5" || id == 5) {
      obj.htmlt = true;
      obj.sobject = "Interactivity";
      obj.selecthtmltype = true;
      obj.afile = ".html, application/zip, application/x-zip-compressed, .zip";
      obj.otype = "Interactivity";
      //obj.htmldata =  [{"quiloutput":"","htitle":"","hdur":"","hdursecs":"","hfile":""}];
      obj.otherdata.quiloutput = "";
    } else if (id == "4" || id == 4) {
      /*   alert("type", id); */
      obj.quizQuestion = Quizresponse.qitems;
      obj.cutoff = Quizresponse.cutoff;
      obj.qtitle = curObject.oname;
      setEditQuiz(true);
      obj.quizt = true;
      obj.sobject = "Quiz";
      obj.qid = "0";
      obj.itemsPerPage = 1;
      obj.noques = false;
      obj.quizid = 1;
      obj.otype = "quiz";
      console.log("444444", obj.quizquestions);
    } else if (id === "8" || id === 8) {
      obj.sobject = "youtube";
      obj.stype = "youtube";
      obj.otype = "youtube";
    } else if (id === "9" || id === 9) {
      obj.sobject = "vimeo";
      obj.stype = "vimeo";
      obj.otype = "vimeo";
    } else if (id === "10" || id === 10) {
      obj.htmlt = true;
      obj.sobject = "scorm";
      obj.selecthtmltype = true;
      obj.afile = "application/zip, application/x-zip-compressed, .zip";
      obj.otype = "scorm";
      obj.otherdata.quiloutput = "";
    } else {
      obj.videot = true;
      obj.sobject = "Video";
      obj.afile = "video/*";
      obj.otype = "video";
    }

    obj.seltype = true;
    obj.error1 = false;
    setSelectedObj(obj);
    if (id != "2" && id != 2) {
      setSelectType(false);
    }
  };
  const isImage = (filename) => {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "jpg":
      case "png":
      case "jpeg":
        return true;
      default:
        return false;
    }
  };
  function isVideo(filename) {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "m4v":
      case "avi":
      case "mpg":
      case "mp4":
        // etc
        return true;
      default:
        return false;
    }
  }
  function isInteractivity(filename) {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "zip":
      case "html":
        return true;
      default:
        return false;
    }
  }
  function isAudio(filename) {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "mp3":
      case "wav":
      case "msv":
      case "m4p":
        return true;
      default:
        return false;
    }
  }
  function isPDF(filename) {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "pdf":
        return true;
      default:
        return false;
    }
  }
  function isHTML(filename) {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "html":
        return true;
      default:
        return false;
    }
  }
  function isScorm(filename) {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "zip":
        return true;
      default:
        return false;
    }
  }
  function getExtension(filename) {
    var parts = filename.split(".");
    return parts[parts.length - 1];
  }

  const changeHandler = (event) => {
    setChangeFile(true);
    // setSelectedFile(new File([""], "filename"));
    if (event.target.files[0] !== undefined) {
      if (
        !isVideo(event.target.files[0].name) &&
        selectedObj.afile === "video/*"
      ) {
        swal("Oops!", "Select correct file format..!", "error");
        return;
      }
      if (
        !isInteractivity(event.target.files[0].name) &&
        selectedObj.afile ===
          ".html, application/zip, application/x-zip-compressed, .zip"
      ) {
        swal("Oops!", "Select correct file format..!", "error");
        return;
      }
      if (
        !isScorm(event.target.files[0].name) &&
        selectedObj.afile ===
          "application/zip, application/x-zip-compressed, .zip"
      ) {
        swal("Oops!", "Select correct file format..!", "error");
        return;
      }

      if (!isPDF(event.target.files[0].name) && selectedObj.afile === ".pdf") {
        swal("Oops!", "Select correct file format..!", "error");
        return;
      }
      if (
        !isHTML(event.target.files[0].name) &&
        selectedObj.afile === ".html"
      ) {
        swal("Oops!", "Select correct file format..!", "error");
        return;
      }
      if (
        !isAudio(event.target.files[0].name) &&
        selectedObj.afile === "audio/*"
      ) {
        swal("Oops!", "Select correct file format..!", "error");
        return;
      }
      let uri = URL.createObjectURL(event.target.files[0]);
      console.log(event.target.files[0]);

      setSelectedFile(event.target.files[0]);

      setSelectedObj((prevObj) =>
        produce(prevObj, (selectedObj) => {
          selectedObj.otherdata.files = event.target.files[0].name;
        })
      );
      /* selectedObj.otherdata.files = event.target.files[0].name;
      setSelectedObj(selectedObj); */
    }
  };

  const finalSave = async (quizImagesPresent = false) => {
    console.log("not going");
    if (quizImagesPresent === false) {
      setLoading(true);
      let objid = curObject.objid;
      setSelectedObj((prevObj) =>
        produce(prevObj, (selectedObjt) => {
          selectedObjt.otherdata.dur =
            Number(selectedObj.otherdata.min) * 60 +
            Number(selectedObj.otherdata.sec);
        })
      );

      let file;
      let id = Math.floor(Math.random() * (999 - 100 + 1) + 100);
      let filename = "";
      if (changeFile === true) {
        if (selectedObj.stype !== undefined && selectedObj.stype == "chtml") {
          filename = objid + `.html`;
          //Number(Math.floor(Math.random() * (999 - 100 + 1) + 100)) + ".html";
        } else if (selectedObj.otype == "quiz") {
          filename =
            //Number(Math.floor(Math.random() * (999 - 100 + 1) + 100)) + ".json";
            objid + `.json`;
        } else if (selectedFile !== undefined) {
          filename = objid + `.${getExtension(selectedObj.otherdata.files)}`;
        }
      } else {
        filename = curObject.ourl;
      }

      let object = {
        otitle: selectedObj.otherdata.title,
        odescription: selectedObj.otherdata.desc,
        otype: selectedObj.otype,
        oduration: selectedObj.otherdata.dur,
        ourl: filename,
        filechanges: true,
        newfile: filename,
      };
      if (
        selectedObj.quizdata != undefined &&
        selectedObj.quizdata.cutoff != undefined
      ) {
        object.cutoff = selectedObj.quizdata.cutoff;
      }

      //alert(selectedObj.otherdata.quesType)
      if (selectedObj.otherdata.quesType !== undefined) {
        object.qtype = selectedObj.otherdata.quesType;
      }

      refobject.current = object;

      var body = {
        filename: filename,
        type: "1",
        oid: config.aws_org_id,
        //folder: folder,
      };
      if (selectedObj.stype !== undefined && selectedObj.stype == "chtml") {
        body.filetype = "text/html";
        body.otype = selectedObj.otype;
        const savedData = await instanceRef.current.save();
        console.log(savedData);
        const edjsParser = edjsHTML();
        // blocks
        object.stype = "chtml";
        file = edjsParser.parse(savedData);
        file = JSON.parse(JSON.stringify(file));
        let tempFile = [];
        let html = ` 
      <!DOCTYPE html>
        <html lang="en">
        <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
        </head>
      <body>
      `;
        for (let i = 0; i < file.length; i++) {
          html = html + file[i];
          tempFile.push(file[i]);
        }
        html =
          html +
          `
       </body>
      </html>
      `;
        file = html;
        object.fileHtml = tempFile;
        console.log(file);
      } else if (selectedObj.otype == "quiz") {
        file = selectedObj.quizdata;
        object.file = selectedObj.quizdata;
        body.filetype = "application/json";
        body.type = "1";
        body.oid = config.aws_org_id;
        body.otype = selectedObj.otype;
        console.log("file to send", file);
      } else if (
        selectedObj.otype == "youtube" ||
        selectedObj.otype == "vimeo"
      ) {
        object.embeddedcode = selectedObj.otherdata.embeddedcode;
        object.url = "";
      } else {
        file = selectedFile;
        console.log("file to send", file);
        object.file = selectedFile;
        body.filetype = selectedFile.type;
        body.otype = selectedObj.otype;
      }

      //var typejson = {"type" :"uploadfile","file":selectedFile,"body":body };
      if (changeFile === true || selectedObj.otype == "quiz") {
        uploadfile(body, file, objid);
      } else {
        addContent(null, selectedObj, curObject.objid, null);
      }
    } else {
      for (let i = 0; i < selectedObj.quizdata.qitems.length; i++) {
        uploadQuizImage(
          selectedObj.quizdata.qitems[i],
          curObject.objid,
          i,
          selectedObj.quizdata.qitems.length
        );
      }
    }
  };

  async function uploadQuizImage(quest, objid, i, qlength) {
    console.log("quest", quest);
    console.log("questi", i);
    console.log("questl", qlength);
    if (quest.image === true) {
      const bodyParam = {
        body: {
          filetype: quest.file.type,
          filename: quest.imageurl,
          type: "quizobject",
          objid: objid,
          oid: config.aws_org_id,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      try {
        console.log(bodyParam.body);
        const preSignedData = await API.post(
          config.aws_cloud_logic_custom_name,
          //Constants.GET_PRESIGNED_URL,
          "/getPreSignedURL",
          bodyParam
        );
        console.log(bodyParam.body);

        console.log(preSignedData, quest.file);
        axios
          .put(preSignedData, quest.file, {
            headers: {
              "Content-Type": quest.file.type,
              "Cache-Control": "no-cache no-store must-revalidate",
            },
          })
          .then((response) => {
            console.log("success");
            console.log("question deletebef", selectedObj.quizdata.qitems[i]);
            delete selectedObj.quizdata.qitems[i].file;
            console.log("question delete", selectedObj.quizdata.qitems[i]);
            if (i == qlength - 1) {
              //alert("now");
              console.log("here1");
              finalSave();
            }
          })

          .catch((error) => {
            console.error("There was an error!", error);
          });
      } catch (error) {
        // alert(JSON.stringify(error));
        console.error(error);
      }
    } else {
      if (i == qlength - 1) {
        //alert("now");
        console.log("here");
        finalSave();
      }
    }
  }

  const checkImage = () => {
    /*  if (changeFile === false) {
      console.log("file12", changeFile);
      setSelectedFile(curObject.ourl);
    } */
    checkData();
  };

  const checkData = async () => {
    // console.log(JSON.stringify(selectedObj.stype,null,2))

    if (
      selectedObj.otherdata.title === undefined ||
      selectedObj.otherdata.title == "" ||
      selectedObj.otherdata.title.replace(/\s/g, "").length === 0
    ) {
      setSelectedObj((prevselectedObj) =>
        produce(prevselectedObj, (selectedObj) => {
          selectedObj.otherdata.eval = false;
          selectedObj.otherdata.dsval = false;
          selectedObj.otherdata.mval = false;
          selectedObj.otherdata.sval = false;
          selectedObj.otherdata.tval = true;
        })
      );
      console.log(selectedObj);
      return;
    }

    if (
      selectedObj.otherdata.desc === undefined ||
      selectedObj.otherdata.desc == "" ||
      selectedObj.otherdata.desc.replace(/\s/g, "").length === 0
    ) {
      setSelectedObj((prevselectedObj) =>
        produce(prevselectedObj, (selectedObj) => {
          selectedObj.otherdata.dsval = true;
          selectedObj.otherdata.tval = false;
          selectedObj.otherdata.eval = false;
          selectedObj.otherdata.mval = false;
          selectedObj.otherdata.sval = false;
        })
      );
      return;
    }
    if (
      selectedObj.otherdata.min === undefined ||
      selectedObj.otherdata.min == "" ||
      selectedObj.otherdata.min.replace(/\s/g, "").length === 0
    ) {
      setSelectedObj((prevselectedObj) =>
        produce(prevselectedObj, (selectedObj) => {
          selectedObj.otherdata.dsval = false;
          selectedObj.otherdata.tval = false;
          selectedObj.otherdata.eval = false;
          selectedObj.otherdata.mval = true;
        })
      );
      return;
    }
    if (
      selectedObj.otherdata.sec === undefined ||
      selectedObj.otherdata.sec == "" ||
      selectedObj.otherdata.sec.replace(/\s/g, "").length === 0
    ) {
      setSelectedObj((prevselectedObj) =>
        produce(prevselectedObj, (selectedObj) => {
          selectedObj.otherdata.dsval = false;
          selectedObj.otherdata.tval = false;
          selectedObj.otherdata.mval = false;
          selectedObj.otherdata.eval = false;
          selectedObj.otherdata.sval = true;
        })
      );
      return;
    }
    if (
      selectedObj.sobject !== "youtube" &&
      selectedObj.sobject !== "vimeo" &&
      selectedObj.stype !== "chtml"
    ) {
      /*  alert("file"); */
      console.log("sel", selectedFile);
      if (changeFile === true) {
        if (selectedFile === undefined) {
          setSelectedObj((prevselectedObj) =>
            produce(prevselectedObj, (selectedObj) => {
              selectedObj.otherdata.dsval = false;
              selectedObj.otherdata.tval = false;
              selectedObj.otherdata.mval = false;
              selectedObj.otherdata.sval = false;
              selectedObj.otherdata.eval = false;
              //selectedObj.ferror = true;
            })
          );
          return;
        }
      }
    }

    if (selectedObj.stype !== undefined && selectedObj.stype == "chtml") {
      const savedData = await instanceRef.current.save();

      if (savedData.blocks.length === 0) {
        setSelectedObj((prevselectedObj) =>
          produce(prevselectedObj, (selectedObj) => {
            selectedObj.otherdata.dsval = false;
            selectedObj.otherdata.tval = false;
            selectedObj.otherdata.mval = false;
            selectedObj.otherdata.sval = false;
            selectedObj.otherdata.eval = false;
            //selectedObj.ferror = true;
          })
        );
        return;
      }
    } else if (
      selectedObj.sobject == "Interactivity" ||
      selectedObj.sobject == "scorm" ||
      (selectedFile === undefined &&
        selectedObj.sobject !== "youtube" &&
        selectedObj.sobject !== "vimeo")
    ) {
      if (selectedFile === undefined) {
        setSelectedObj((prevselectedObj) =>
          produce(prevselectedObj, (selectedObj) => {
            selectedObj.otherdata.dsval = false;
            selectedObj.otherdata.tval = false;
            selectedObj.otherdata.mval = false;
            selectedObj.otherdata.sval = false;
            selectedObj.otherdata.eval = false;
            //selectedObj.ferror = true;
          })
        );
        return;
      }
      if (changeFile === true) {
        JSZip.loadAsync(selectedFile).then(function (content) {
          var tmp = JSON.stringify(content);
          let fileexist = false;
          var t1 = tmp.toString();
          t1 = JSON.parse(t1);

          // console.log(t1);
          if (selectedObj.sobject == "scorm") {
            try {
              if (t1.files["rxd/indexAPI.html"].name) {
                console.log(t1.files["rxd/indexAPI.html"].name);
                fileexist = true;
              }
            } catch (e) {
              fileexist = false;
            }
          } else {
            try {
              if (t1.files["index.html"].name) {
                console.log(t1.files["index.html"].name);
                fileexist = true;
              }
            } catch (e) {
              fileexist = false;
            }
          }
          if (!fileexist) {
            setSelectedObj((prevselectedObj) =>
              produce(prevselectedObj, (selectedObj) => {
                selectedObj.otherdata.dsval = false;
                selectedObj.otherdata.tval = false;
                selectedObj.otherdata.mval = false;
                selectedObj.otherdata.sval = false;
                selectedObj.otherdata.eval = false;
                //selectedObj.ferror = true;
              })
            );
            swal("Oops!", "Select correct file format..!", "error");
            return;
          } else {
            finalSave();
            //getObjectid();
          }
        });
      } else {
        finalSave();
      }
    } else if (
      selectedFile === undefined &&
      selectedObj.stype !== "vimeo" &&
      selectedObj.stype !== "youtube"
    ) {
      setSelectedObj((prevselectedObj) =>
        produce(prevselectedObj, (selectedObj) => {
          selectedObj.otherdata.dsval = false;
          selectedObj.otherdata.tval = false;
          selectedObj.otherdata.mval = false;
          selectedObj.otherdata.sval = false;
          selectedObj.otherdata.eval = false;
          //selectedObj.ferror = true;
        })
      );
      return;
    } else if (selectedObj.stype == "vimeo" || selectedObj.stype == "youtube") {
      if (
        selectedObj.otherdata.embeddedcode === undefined ||
        selectedObj.otherdata.embeddedcode == "" ||
        selectedObj.otherdata.embeddedcode.replace(/\s/g, "").length === 0
      ) {
        selectedObj.otherdata.dsval = false;
        selectedObj.otherdata.tval = false;
        selectedObj.otherdata.mval = false;
        selectedObj.otherdata.sval = false;
        selectedObj.otherdata.eval = true;

        setSelectedObj((prevselectedObj) =>
          produce(prevselectedObj, (selectedObj) => {
            selectedObj.otherdata.dsval = false;
            selectedObj.otherdata.tval = false;
            selectedObj.otherdata.mval = false;
            selectedObj.otherdata.sval = false;
            selectedObj.otherdata.eval = true;
          })
        );
        console.log(selectedObj);
        return;
      }
    }
    if (
      selectedObj.sobject !== "Interactivity" &&
      selectedObj.sobject !== "scorm"
    ) {
      finalSave();
    }
    console.log(selectedObj);
  };

  async function uploadfile(body, file, objid) {
    body.OBJID = objid;
    const bodyParam = {
      body,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    let refObj = { ...refobject.current };
    console.log(JSON.stringify(refObj));
    try {
      console.log(JSON.stringify(bodyParam.body));
      const preSignedData = await API.post(
        config.aws_cloud_logic_custom_name,
        //Constants.GET_PRESIGNED_URL,
        "/getPreSignedURL",
        bodyParam
      );
      let ftype = "";
      if (selectedObj.stype !== undefined && selectedObj.stype == "chtml") {
        ftype = "text/html";
      } else if (selectedObj.otype == "quiz") {
        ftype = "application/json";

        refObj.oduration = file.qitems.length * 60;
      } else if (selectedFile !== undefined) {
        ftype = selectedFile.type;
      } else {
        ftype = "application/json";
      }
      console.log(preSignedData, file);
      axios
        .put(preSignedData, file, {
          headers: {
            "Content-Type": ftype,
            "Cache-Control": "no-cache no-store must-revalidate",
          },
        })
        .then((response) => {
          if (
            selectedObj.otype === "Interactivity" ||
            selectedObj.otype === "scorm"
          ) {
            uploadInteractivity(selectedObj, body, objid);
          }
          addContent(preSignedData, selectedObj, objid, body);
          console.log("ourl", preSignedData);
          console.log("obj", selectedObj);
          console.log("body123", body);
          //setObjOpen(false);
          setQuizQuestion([]);

          handleCloseEditObj();
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    } catch (error) {
      // alert(JSON.stringify(error));
      console.error(error);
    }
    setSelectedFile(undefined);
  }

  async function uploadInteractivity(selectedObj, body, objid) {
    let bodytype = {};
    bodytype.otype = selectedObj.otype;
    bodytype.oid = config.aws_org_id;
    bodytype.OBJID = objid;
    bodytype.filename = body.filename;
    bodytype.schema = Constants.SCHEMA_NAME;

    const bodyParam = {
      body: bodytype,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const swiper = await API.post(
        config.aws_cloud_logic_custom_name,
        //Constants.GET_PRESIGNED_URL,
        Constants.UPLOAD_OBJECT_SWIPER,
        bodyParam
      );
    } catch (error) {
      console.error(error);
    }
  }

  /* async function getObjectid() {
    let bodytype = {};
    bodytype.type = "object";
    bodytype.schema = Constants.SCHEMA_NAME;
    const bodyParam = {
      body: bodytype,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const objid = await API.post(
        config.aws_cloud_logic_custom_name,
        //Constants.GET_PRESIGNED_URL,
        Constants.GET_TOPIC_ID,
        bodyParam
      );
      //finalSave();
      console.log("id", objid);
      finalSave(objid.body[0].objid);
    } catch (error) {
      console.error(error);
    }
  } */

  async function addContent(preSignedData, selectedObj, objid, body) {
    console.log("ourl", preSignedData);
    console.log("obj123", selectedObj);
    let bodyObj = {};

    if (selectedObj.otype === "youtube" || selectedObj.otype === "vimeo") {
      bodyObj.ourl = selectedObj.otherdata.embeddedcode;
    } else {
      if (changeFile === true) {
        bodyObj.ourl = body.filename;
      } else {
        bodyObj.ourl = curObject.ourl;
      }
    }
    /* {
        "atype": 1,
        "oid": null,
        "objid": "OB00008",
        "tenantid": null,
        "cgry_id": null,
        "ttags": null,
        "cby": null,
        "dur": null,
        "modified_by": "Danya shree",
        "ourl": null,
        "otype": null,
        "shareable": null,
        "oname": null,
        "odesc": null,
        "modified_on": null,
        "c_date": null,
        "cgry_name": null
      } */

    bodyObj.atype = 1;
    bodyObj.oid = config.aws_org_id;
    bodyObj.objid = curObject.objid;
    bodyObj.tenantid = [];
    bodyObj.cgry_id = null;
    bodyObj.ttags = tagArray;
    bodyObj.cby = null;
    bodyObj.dur = selectedObj.otherdata.dur;
    bodyObj.modified_by = userDetails.role;

    bodyObj.otype = selectedObj.otype;
    bodyObj.shareable = shareable;
    bodyObj.oname = selectedObj.otherdata.title;
    bodyObj.odesc = selectedObj.otherdata.desc;
    bodyObj.modified_on = new Date().getTime();
    bodyObj.c_date = null;
    bodyObj.cgry_name = null;
    bodyObj.schema = Constants.SCHEMA_NAME;
    const bodyParam = {
      body: bodyObj,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        //Constants.GET_PRESIGNED_URL,
        Constants.ADD_CONTENT,
        bodyParam
      );
      objectsTableView();
      handleCloseEditObj();
      swal
        .fire({
          title: "Success",
          text: "Content edited successfully",
          icon: "success",
          dangerMode: false,
          closeOnClickOutside: false,
        })
        .then((willDelete) => {
          if (willDelete) {
            setLoading(false);
          }
        });
    } catch (error) {
      console.error(error);
      swal
        .fire({
          title: "Oooops!",
          text: "Soomthing wrong!",
          icon: "warning",
          dangerMode: true,
          closeOnClickOutside: false,
        })
        .then((willDelete) => {
          if (willDelete) {
            setLoading(false);
          }
        });
    }
    console.log("data", bodyObj);
  }
  const onChangeHandlerTags = (event) => {
    console.log("tv", event.target.value);
    console.log(event);

    let speakArray = [...tagArray];
    let obj;

    obj = tag;

    let flag = false;
    for (let j = 0; j < speakArray.length; j++) {
      if (speakArray[j] == obj) {
        flag = true;
      }
    }
    if (flag === false) {
      speakArray.push(obj.toString());
    }
    setTagArray(speakArray);
    setTag("");
  };
  const removeSpeaker = (value) => {
    let updatedArray = [...tagArray];
    updatedArray = updatedArray.filter(
      (deleting) => deleting !== value.toString()
    );

    setTagArray(updatedArray);
    console.log("updatedspeakerarry", tagArray);
  };
  return (
    <div className={AddCon.maincontainer} style={{ width: "100%" }}>
      <BackDrop
        sx={{ color: "#fff" }}
        open={loading}
        className={classes.backdrop}
      >
        <CircularProgress color="inherit" />
      </BackDrop>
      <div className={AddCon.closeholder}>
        <h3 className={AddCon.heading}>Edit Content and Colletral</h3>
        <Close className={AddCon.closeicon} onClick={handleCloseEditObj} />
      </div>
      <div>
        <>
          <>
            {selectedObj.seltype && selectedObj.sobject != "Quiz" && (
              <div>
                <div style={{ margin: "20px 60px", overflow: "hidden" }}>
                  {selectedObj.error1 && (
                    <p className={AddCon.verror1}>
                      {selectedObj.sobject}
                      title cannot be empty
                    </p>
                  )}

                  <form autoComplete="off">
                    <TextField
                      id="outlined-margin-normal"
                      className={classes.textField}
                      error={selectedObj.otherdata.tval}
                      helperText={
                        selectedObj.otherdata.tval ? "Please Enter Title" : ""
                      }
                      margin="normal"
                      variant="outlined"
                      value={selectedObj.otherdata.title}
                      onChange={(e) => {
                        setSelectedObj({
                          ...selectedObj,
                          otherdata: {
                            ...selectedObj.otherdata,
                            title: e.target.value,
                          },
                        });
                      }}
                    />
                    <TextField
                      label="Description"
                      id="outlined-margin-normal"
                      className={classes.textField}
                      error={selectedObj.otherdata.dsval}
                      helperText={
                        selectedObj.otherdata.dsval
                          ? "Please Enter Description"
                          : ""
                      }
                      margin="normal"
                      variant="outlined"
                      value={selectedObj.otherdata.desc}
                      onChange={(e) => {
                        setSelectedObj({
                          ...selectedObj,
                          otherdata: {
                            ...selectedObj.otherdata,
                            desc: e.target.value,
                          },
                        });
                      }}
                    />
                    <RegexTextField
                      regex={onlyAlphanumericRegex}
                      label="Duration"
                      error={selectedObj.otherdata.mval}
                      helperText={
                        selectedObj.otherdata.mval ? "Please Enter Min" : ""
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">Min</InputAdornment>
                        ),
                      }}
                      value={selectedObj.otherdata.min}
                      onChange={(e) => {
                        setSelectedObj((prev) =>
                          produce(prev, (temp) => {
                            temp.otherdata.min = e.target.value;
                          })
                        );
                      }}
                    />
                    <RegexTextField
                      regex={onlyAlphanumericRegex}
                      label=""
                      error={selectedObj.otherdata.sval}
                      helperText={
                        selectedObj.otherdata.sval ? "Please Enter Sec" : ""
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">Sec</InputAdornment>
                        ),
                      }}
                      value={selectedObj.otherdata.sec}
                      onChange={(e) => {
                        setSelectedObj((prev) =>
                          produce(prev, (temp) => {
                            temp.otherdata.sec = e.target.value;
                          })
                        );
                      }}
                    />
                    {(selectedObj.sobject === "youtube" ||
                      selectedObj.sobject === "vimeo") && (
                      <TextField
                        label="Embed ID"
                        id="outlined-margin-normal"
                        className={classes.textField}
                        error={selectedObj.otherdata.eval}
                        helperText={
                          selectedObj.otherdata.eval
                            ? "Please Enter Embed ID"
                            : ""
                        }
                        margin="normal"
                        variant="outlined"
                        value={selectedObj.otherdata.embeddedcode}
                        onChange={(e) => {
                          setSelectedObj((prev) =>
                            produce(prev, (temp) => {
                              temp.otherdata.embeddedcode = e.target.value;
                            })
                          );
                        }}
                      />
                    )}
                  </form>
                </div>

                {selectedObj.stype == "chtml" && (
                  <>
                    {selectedObj.ferror && (
                      <p
                        style={{
                          color: "red",
                          margin: "0 60px",
                          textAlign: "left",
                        }}
                      >
                        {" "}
                        Please enter some data.{" "}
                      </p>
                    )}
                    <div
                      style={{
                        height: "260px",
                        border: "1px solid #ddd",
                        margin: "0px 60px",
                        padding: "6px 28px",
                        overflow: "hidden auto",
                      }}
                    >
                      <createReactEditorJS
                        tools={EDITOR_JS_TOOLS}
                        instanceRef={(instance) =>
                          (instanceRef.current = instance)
                        }
                      />
                    </div>
                  </>
                )}

                {(selectedObj.stype === undefined ||
                  (selectedObj.stype != "chtml" &&
                    selectedObj.stype != "youtube" &&
                    selectedObj.stype != "vimeo")) && (
                  <>
                    <p className={AddCon.pvalid}>
                      Please select a{" "}
                      {selectedObj.sobject == "Narrative"
                        ? "HTML"
                        : selectedObj.sobject}{" "}
                      to upload
                    </p>
                    <div className={AddCon.insidediv}>
                      <div className={AddCon.centeritem}>
                        <input
                          type="file"
                          id="files"
                          style={{
                            visibility: "hidden",
                            position: "absolute",
                          }}
                          onChange={changeHandler}
                          accept={selectedObj.afile}
                        />
                        <label for="files">
                          <Button
                            variant="contained"
                            color="primary"
                            component="span"
                          >
                            Browse
                          </Button>
                        </label>
                      </div>

                      {selectedObj.otherdata.files != "" && (
                        <p className={AddCon.pfile}>
                          {selectedObj.otherdata.files}{" "}
                        </p>
                      )}
                      {selectedObj.otherdata.files == "" && (
                        <p className={AddCon.pfile}>
                          {selectedObj.sobject}
                          file not selected{" "}
                        </p>
                      )}
                      {selectedObj.ferror && (
                        <p className={AddCon.pfile} style={{ color: "red" }}>
                          {" "}
                          Please select file{" "}
                        </p>
                      )}
                    </div>
                  </>
                )}
                <div className={AddCon.outer}>
                  <p className={AddCon.lable}> Shareable:</p>
                  <div className={AddCon.options}>
                    <button
                      className={
                        shareable === "false" || shareable === false
                          ? AddCon.classtagCk
                          : AddCon.classtag
                      }
                      style={{ textAlign: "center" }}
                      value={true}
                      onClick={(e) => setShareable(e.target.value)}
                    >
                      Yes
                    </button>
                    <button
                      className={
                        shareable === "true" || shareable === true
                          ? AddCon.classtagCk
                          : AddCon.classtag
                      }
                      style={{ textAlign: "center" }}
                      value={false}
                      onClick={(e) => setShareable(e.target.value)}
                    >
                      No
                    </button>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <p className={AddCon.lable} style={{ paddingLeft: "40px" }}>
                    {" "}
                    Tags:
                  </p>
                  <TextField
                    label="Tags"
                    id="outlined-margin-normal"
                    className={classes.textField}
                    value={tag}
                    margin="normal"
                    variant="outlined"
                    onChange={(event) => setTag(event.target.value)}
                  />
                  {/* <input
                    placeholder="Tags"
                    className={AddCon.textbox}
                    value={tag}
                    onChange={(event) => setTag(event.target.value)}
                  /> */}
                  <button
                    style={{ padding: "8px", marginTop: "28px" }}
                    className={AddCon.add}
                    onClick={onChangeHandlerTags}
                  >
                    Add
                  </button>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    gap: "5px",
                    margin: "1rem 0",
                    maxWidth: "500px",
                  }}
                >
                  {tagArray.map((value, id) => {
                    return (
                      <div className={AddCon.speakertag} key={id}>
                        <h4 className={AddCon.speakername}>{value}</h4>
                        <Close
                          className={AddCon.cancelicon}
                          onClick={() => {
                            console.log("id", id);
                            removeSpeaker(value);
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {console.log("selllll", selectedObj)}
            {selectedObj.sobject == "Quiz" && (
              <EditQuiz
                selectedObj={selectedObj}
                setSelectedObj={setSelectedObj}
                //getObjectid={getObjectid}
                //folder={folder}
                finalSave={finalSave}
                editQuiz={editQuiz}
                qtype={qtype}
                setTypeButtons={setTypeButtons}
                typesButtons={false}
                quizQuestion={quizQuestion}
                setQuizQuestion={setQuizQuestion}
                setLoading={setLoading}
                loading={loading}
              />
            )}
          </>
        </>
      </div>
      <div>
        {!selectType && selectedObj.sobject != "Quiz" && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => checkImage()}
          >
            Save
          </Button>
        )}
      </div>
    </div>
  );
}
