import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import quizStyle from "./QuizView.module.scss";
import config from "../../../config/aws-exports";
import { Constants } from "../../../config/Constants";
import { produce } from "immer";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextareaAutosize,
  Typography,
  withStyles,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { Image } from "semantic-ui-react";
import { Skeleton } from "@material-ui/lab";
import { green } from "@material-ui/core/colors";
import CloseIcon from "@material-ui/icons/Close";
import { useSelector } from "react-redux";
import { API } from "aws-amplify";
import { authData, awsSignIn } from "../../../redux/auth/authSlice";
import { useDispatch } from "react-redux";

const CustomButton = withStyles({
  root: {
    boxShadow: "none",
    textTransform: "none",
    fontSize: 16,
    padding: "6px 15px",
    border: "1px solid",
    lineHeight: 1.5,
    backgroundColor: "#01498e",
    borderColor: "#fff",
    color: "#fff",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      backgroundColor: "#01498e",
      border: "1px solid #fff",
      color: "#fff",
      boxShadow: "#fff",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#fff",
      borderColor: "#01498e",
    },
    "&:focus": {
      //   boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
  },
})(Button);

const useStyles = makeStyles((theme) => ({
  paper: {
    "& .MuiDialog-paperWidthSm": {
      minWidth: "800px",
      padding: "30px 80px",
    },
  },
}));
export default function QuizView({
  handleClose,
  scroll,
  assessId,
  instructions,
  type,

  setInstructionsView,
  instructionsView,
  fullScreen,
  courseId,
  curObject,
}) {
  const classes = useStyles();
  console.log("quiz");
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [correct, setCorrect] = useState(false);
  const [checkboxes, setCheckboxes] = useState([]);
  const [quizQuestions, setQuizQuestions] = useState([]);
  const [score, setScore] = useState(0);
  const [fileName, setFileName] = useState("");
  const userDetails = useSelector(authData);
  console.log(userDetails);
  const userDeatils = useSelector(authData);
  const dispatch = useDispatch();
  const [questions, setQuestions] = React.useState([]);
  const [imgUrl, setImgUrl] = React.useState("");
  const [qLoading, setQLoading] = React.useState(true);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    getQuiz(curObject);
  }, []);

  async function getQuiz(obj) {
    const bodyParam = {
      body: {
        quizid: obj.objid,
        action: "get",
        oid: config.aws_org_id,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        `/getQuiz`,
        bodyParam
      );

      const { qitems, qtype } = response;
      console.log("res", response);
      console.log("qitems", qitems);
      console.log("qtype", qtype);
      setQuestions(qitems);
      // setQLoading(false);
      let ques = { ...userDeatils };
      if (response.qitems === undefined || response === undefined) {
        ques.questions = [];
        dispatch(awsSignIn(ques));
      } else {
        ques.questions = qitems;
        dispatch(awsSignIn(ques));
      }
      setOpen(true);
      /*  const imgUrl = `https://${
        Constants.DOMAIN
      }/${config.aws_org_id.toLowerCase()}-resources/images/assessment-images/${courseId}/${id}/`;
      setImgUrl(imgUrl); */
      setQLoading(false);
    } catch (error) {
      // alert(JSON.stringify(error));
      console.error(error);
    }
  }

  const handleAnswerOptionClick = (correct, index) => {
    // questions[currentQuestion].response = index;
    let qdata = [...quizQuestions];

    qdata = produce(qdata, (draft) => {
      draft[currentQuestion].response = index;
    });
    setQuizQuestions(qdata);
    console.log(questions);

    // alert(JSON.stringify(correct));
    setCorrect(correct);
  };

  const nextQuestion = () => {
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < questions.length) {
      setCurrentQuestion(nextQuestion);
    }
  };
  const prevQuestion = () => {
    const prevQuestion = currentQuestion - 1;

    setCurrentQuestion(prevQuestion);
  };

  function setCheckbox(index, checked) {
    console.log(checkboxes);
    let newCheckboxes = [...checkboxes];

    if (newCheckboxes[index].checked === undefined) {
      newCheckboxes = produce(newCheckboxes, (draft) => {
        draft[index].checked = false;
      });

      // newCheckboxes[index].checked = false;
    }
    // newCheckboxes[index].checked = checked;
    newCheckboxes = produce(newCheckboxes, (draft) => {
      draft[index].checked = checked;
    });
    setCheckboxes(newCheckboxes);
    let tmp = 0;
    for (let k = 0; k < checkboxes.length; k++) {
      if (
        checkboxes[k].checked !== undefined &&
        checkboxes[k].checked === true
      ) {
        tmp = 1;
        setCorrect(true);
      }
      if (tmp === 0 && k == checkboxes.length - 1) {
        setCorrect(undefined);
      }
    }
  }
  const handleAnswerBtnClick = () => {
    let qdata = [...quizQuestions];
    if (correct === "true" || correct === true) {
      setScore(score + 1);
    }
    // ansaddedtmp();
    const nextQuestion = currentQuestion + 1;

    if (nextQuestion < questions.length) {
      setCurrentQuestion(nextQuestion);
      if (
        questions[nextQuestion].atype == 3 ||
        questions[nextQuestion].atype == 5
      ) {
        setCheckboxes(questions[nextQuestion].iopts);
      }
    } else {
      handleClose();
      setCurrentQuestion(0);
      setQuizQuestions(userDetails.questions);
    }
    setCorrect(undefined);
  };
  return (
    <>
      {qLoading ? (
        <div></div>
      ) : (
        <Dialog
          open={open}
          onClose={handleClose}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className={classes.paper}
          fullScreen={fullScreen}
          onBackdropClick="false"
        >
          <DialogTitle id="scroll-dialog-title">
            <h3
              style={{
                fontSize: "1.8rem",
                color: "#3372b5",
                fontWeight: "600",
              }}
            >
              {" "}
              {"Quiz"}
            </h3>
            <IconButton
              style={{ position: "absolute", top: "0", right: "0" }}
              component="span"
              onClick={() => {
                handleClose();
                setCurrentQuestion(0);
                setQuizQuestions(userDetails.questions);
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          {
            <React.Fragment>
              <DialogContent dividers={scroll === "paper"}>
                {(quizQuestions.length !== 0 ||
                  userDetails.questions !== 0 ||
                  userDetails.questions !== undefined ||
                  quizQuestions[currentQuestion].istem !== undefined ||
                  quizQuestions[currentQuestion].atype !== undefined) && (
                  <div>
                    <div>
                      <>
                        <div>
                          <>
                            <div className={quizStyle.questionsection}>
                              <div className={quizStyle.questioncount}>
                                <span>Question {currentQuestion + 1}</span>/
                                {quizQuestions.length !== 0
                                  ? quizQuestions.length
                                  : 1}
                              </div>
                              <div className={quizStyle.questiontext}>
                                {quizQuestions.length !== 0 ||
                                quizQuestions !== undefined ||
                                quizQuestions[currentQuestion] !== undefined ? (
                                  quizQuestions[currentQuestion].atype ==
                                    undefined ||
                                  quizQuestions[currentQuestion].atype == 6 ? (
                                    <></>
                                  ) : (
                                    <div style={{ fontSize: "15px" }}>
                                      {" "}
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            quizQuestions[currentQuestion]
                                              .istem,
                                        }}
                                      ></p>
                                    </div>
                                  )
                                ) : null}

                                {quizQuestions[currentQuestion].img ? (
                                  <div className="img_container">
                                    <img
                                      src={`${`https://${
                                        Constants.DOMAIN
                                      }/${config.aws_org_id.toLowerCase()}-resources/images/assessment-images/${courseId}/${assessId}/`}${
                                        quizQuestions[currentQuestion].img
                                      }`}
                                      alt="item"
                                    />
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            {quizQuestions[currentQuestion].atype == 7 ? (
                              <>
                                <TextareaAutosize
                                  className={quizStyle.textarea}
                                  rowsMax={6}
                                  aria-label="maximum height"
                                  placeholder="minimum 6 rows"
                                  onChange={() => {
                                    setCorrect("true");
                                  }}
                                />
                              </>
                            ) : (
                              <>
                                {quizQuestions[currentQuestion].atype == 1 ||
                                quizQuestions[currentQuestion].atype == 2 ||
                                quizQuestions[currentQuestion].atype == 4 ? (
                                  <>
                                    <div className={quizStyle.answersection}>
                                      <div
                                        className={
                                          "btn-group " +
                                          quizStyle.btngroupvertical
                                        }
                                      >
                                        {quizQuestions[
                                          currentQuestion
                                        ].iopts.map(
                                          (answerOption, index, arrayobj) => (
                                            <>
                                              {quizQuestions[currentQuestion]
                                                .atype == 4 ? (
                                                <>
                                                  <div>
                                                    <input
                                                      type="radio"
                                                      className="radio_opacity"
                                                      name="options"
                                                      id={answerOption.url}
                                                    />
                                                    <label
                                                      className={
                                                        quizStyle.labelstyle +
                                                        " " +
                                                        quizStyle.buttonQuiz
                                                      }
                                                      for={answerOption.url}
                                                      onClick={() =>
                                                        handleAnswerOptionClick(
                                                          answerOption.correct,
                                                          index
                                                        )
                                                      }
                                                    >
                                                      <Image
                                                        src={
                                                          imgUrl +
                                                          answerOption.url
                                                        }
                                                        rounded
                                                      />
                                                    </label>
                                                  </div>
                                                </>
                                              ) : (
                                                <>
                                                  <input
                                                    type="radio"
                                                    className="radio_opacity"
                                                    name="options"
                                                    id={answerOption.content}
                                                    value={answerOption.content}
                                                    checked={
                                                      answerOption.Selected
                                                    }
                                                    onChange={() => {
                                                      let qdata = [
                                                        ...quizQuestions,
                                                      ];
                                                      for (
                                                        let i = 0;
                                                        i <
                                                        quizQuestions[
                                                          currentQuestion
                                                        ].iopts.length;
                                                        i++
                                                      ) {
                                                        setQuizQuestions(
                                                          (currentQues) =>
                                                            produce(
                                                              currentQues,
                                                              (draft) => {
                                                                draft[
                                                                  currentQuestion
                                                                ].iopts[
                                                                  i
                                                                ].Selected = false;
                                                              }
                                                            )
                                                        );

                                                        // quizQuestions[
                                                        //   currentQuestion
                                                        // ].iopts[i].Selected = false;
                                                      }

                                                      setQuizQuestions(
                                                        (currentQues) =>
                                                          produce(
                                                            currentQues,
                                                            (draft) => {
                                                              draft[
                                                                currentQuestion
                                                              ].iopts[
                                                                index
                                                              ].Selected = true;
                                                            }
                                                          )
                                                      );
                                                    }}
                                                  />
                                                  <label
                                                    className={
                                                      quizStyle.labelstyle +
                                                      " " +
                                                      quizStyle.buttonQuiz
                                                    }
                                                    for={answerOption.content}
                                                    onClick={() =>
                                                      handleAnswerOptionClick(
                                                        answerOption.correct,
                                                        index
                                                      )
                                                    }
                                                  >
                                                    {answerOption.content}
                                                  </label>
                                                </>
                                              )}
                                            </>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    {quizQuestions[currentQuestion].atype ==
                                      3 ||
                                    quizQuestions[currentQuestion].atype ==
                                      5 ? (
                                      <div className={quizStyle.answersection}>
                                        <FormControl component="fieldset">
                                          <FormGroup aria-label="position" row>
                                            {quizQuestions[
                                              currentQuestion
                                            ].iopts.map(
                                              (
                                                answerOption,
                                                index,
                                                arrayobj
                                              ) => (
                                                <>
                                                  {quizQuestions[
                                                    currentQuestion
                                                  ].atype == 5 ? (
                                                    <>
                                                      <div>
                                                        <FormControlLabel
                                                          className={
                                                            quizStyle.buttonQuiz
                                                          }
                                                          value={
                                                            <Image
                                                              src={
                                                                imgUrl +
                                                                answerOption.url
                                                              }
                                                              rounded
                                                            />
                                                          }
                                                          control={
                                                            <Checkbox color="primary" />
                                                          }
                                                          label={
                                                            <Image
                                                              src={
                                                                imgUrl +
                                                                answerOption.url
                                                              }
                                                              rounded
                                                            />
                                                          }
                                                          onChange={(e) => {
                                                            setCheckbox(
                                                              index,
                                                              e.target.checked
                                                            );
                                                          }}
                                                          labelPlacement="end"
                                                        />
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <FormControlLabel
                                                        className={
                                                          quizStyle.buttonQuiz
                                                        }
                                                        value={
                                                          answerOption.content
                                                        }
                                                        control={
                                                          <Checkbox color="primary" />
                                                        }
                                                        label={
                                                          answerOption.content
                                                        }
                                                        onChange={(e) => {
                                                          setCheckbox(
                                                            index,
                                                            e.target.checked
                                                          );
                                                        }}
                                                        labelPlacement="end"
                                                      />
                                                    </>
                                                  )}
                                                </>
                                              )
                                            )}
                                          </FormGroup>
                                        </FormControl>
                                      </div>
                                    ) : (
                                      <>
                                        {quizQuestions[currentQuestion].atype ==
                                        8 ? (
                                          <>
                                            <div className="file-input">
                                              <input
                                                type="file"
                                                id="file"
                                                name="file"
                                                className="file"
                                                onChange={(event) => {
                                                  setFileName(
                                                    event.target.files[0].name
                                                  );
                                                }}
                                                multiple
                                                required
                                              />
                                              <label for="file">
                                                Select file
                                                <p className="file-name2">
                                                  {fileName !== undefined ||
                                                  fileName !== null ||
                                                  fileName !== ""
                                                    ? fileName
                                                    : ""}
                                                </p>
                                              </label>
                                            </div>

                                            {currentQuestion + 1 ===
                                            quizQuestions.length ? (
                                              <CustomButton
                                                fullWidth
                                                type="submit"
                                                onClick={() => {
                                                  handleClose();
                                                  setCurrentQuestion(0);
                                                  setQuizQuestions(
                                                    userDetails.questions
                                                  );
                                                }}
                                              >
                                                Submit
                                              </CustomButton>
                                            ) : (
                                              <CustomButton
                                                fullWidth
                                                type="submit"
                                              >
                                                Next
                                              </CustomButton>
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            {quizQuestions[currentQuestion]
                                              .atype == 6 ? (
                                              <>
                                                <p
                                                  className={quizStyle.matchThe}
                                                  dangerouslySetInnerHTML={{
                                                    __html:
                                                      quizQuestions[
                                                        currentQuestion
                                                      ].istem,
                                                  }}
                                                ></p>{" "}
                                                {quizQuestions[
                                                  currentQuestion
                                                ].iopts.map(
                                                  (
                                                    answerOption,
                                                    index,
                                                    arrayobj
                                                  ) => (
                                                    <>
                                                      {quizQuestions[
                                                        currentQuestion
                                                      ].atype == 4 ? (
                                                        <>
                                                          <div>
                                                            <input
                                                              type="radio"
                                                              className="radio_opacity"
                                                              name="options"
                                                              id={
                                                                answerOption.content
                                                              }
                                                            />
                                                            <label
                                                              className={
                                                                quizStyle.labelstyle +
                                                                " " +
                                                                quizStyle.buttonQuiz
                                                              }
                                                              for={
                                                                answerOption.content
                                                              }
                                                              onClick={() =>
                                                                handleAnswerOptionClick(
                                                                  answerOption.correct,
                                                                  index
                                                                )
                                                              }
                                                            >
                                                              <Image
                                                                src={
                                                                  imgUrl +
                                                                  answerOption.url
                                                                }
                                                                rounded
                                                              />
                                                            </label>
                                                          </div>
                                                        </>
                                                      ) : (
                                                        <>
                                                          <input
                                                            type="radio"
                                                            className="radio_opacity"
                                                            name="options"
                                                            id={
                                                              answerOption.content
                                                            }
                                                          />
                                                          <label
                                                            className={
                                                              quizStyle.labelstyle +
                                                              " " +
                                                              quizStyle.buttonQuiz
                                                            }
                                                            for={
                                                              answerOption.content
                                                            }
                                                            onClick={() =>
                                                              handleAnswerOptionClick(
                                                                answerOption.correct,
                                                                index
                                                              )
                                                            }
                                                          >
                                                            {
                                                              answerOption.content
                                                            }
                                                          </label>
                                                        </>
                                                      )}
                                                    </>
                                                  )
                                                )}
                                              </>
                                            ) : (
                                              <>d</>
                                            )}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                            {quizQuestions[currentQuestion].atype == 8 ? (
                              <></>
                            ) : (
                              <>
                                <CustomButton
                                  className={quizStyle.nextbtn}
                                  disabled={correct === undefined}
                                  onClick={() => {
                                    handleAnswerBtnClick();
                                  }}
                                >
                                  {currentQuestion == quizQuestions.length - 1
                                    ? "Submit"
                                    : "Next"}
                                </CustomButton>
                              </>
                            )}
                          </>
                        </div>
                      </>
                    </div>
                  </div>
                )}
              </DialogContent>
            </React.Fragment>
          }
        </Dialog>
      )}
    </>
  );
}
