//Dependencies imports
import React, { useState, useEffect } from "react";
import {
  DataGrid,
  GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import config from "../../../config/aws-exports";
import { Constants } from "../../../config/Constants";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../../redux/auth/authSlice";
import { API } from "aws-amplify";
import moment from "moment";
import Backdrop from "@material-ui/core/Backdrop/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Rating from "@mui/material/Rating";
import FeedbackPopUp from "./FeedbackPopUp";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import NothiongHereYet from "../../../assets/Nothing Here Yet.gif";
import SessionReport from "../Report/SeesionReport";

//Style imports
import Rep from "./Report.module.scss";
import { Button } from "@mui/material";
import { CSVLink } from "react-csv";
import { FileDownload } from "@mui/icons-material";
import { useHistory } from "react-router-dom";

// const CustomExportButton = () => {
//   return (
//     <Button color="primary">
//     <CSVLink
//       // data={data}
//       // rows={rows}
//       filename="session-report"
//       className={Rep.exp_btn}
//     >
//       <FileDownload />
//       Export
//     </CSVLink>
//   </Button>
//   )
// };

// function CustomToolbar() {
//   return (
//     <GridToolbarContainer
//       style={{ display: "flex", justifyContent: "flex-end" }}
//     >
//       <GridToolbarColumnsButton />
//       <GridToolbarFilterButton />
//       <GridToolbarDensitySelector />
//       <CustomExportButton />
//       {/* <GridToolbarExport /> */}
//       {/* <GridToolbar /> */}
//     </GridToolbarContainer>
//   );
// }

// Styles for material components
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
    "& .super-app-theme--header": {
      backgroundColor: Constants.blue_color,
      fontSize: "16px",
      color: "rgb(255,255,255)",
      fontFamily: "nunito",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
    cursor: "pointer",
  },
}));

function sessionDate(data) {
  if (
    data?.date_list &&
    (data?.date_list?.length === 0 || data?.date_list[0].date === "")
  ) {
    return "yet to be added.";
  }

  if (data?.startdate) {
    return `${moment(parseInt(data?.startdate)).format("DD-MM-YYYY")}`;
  }

  if (
    data?.date_list &&
    data?.date_list?.length > 0 &&
    data?.date_list[0].date !== ""
  ) {
    return `${moment(
      new Date(data?.date_list[0]?.combineStartTime).getTime()
    ).format("DD-MM-YYYY")} 
    `;
  }
  if (!data?.date_list || !data?.startdate) {
    return "yet to be added.";
  }
}
function sessionFinanceDate(data) {
  if (
    data?.date_list &&
    (data?.date_list?.length === 0 || data?.date_list[0].date === "")
  ) {
    return "yet to be added.";
  }

  if (data?.startdate && data?.enddate) {
    return `${moment(parseInt(data?.startdate)).format("DD-MM-YYYY")} [${moment(
      parseInt(data?.startdate)
    ).format("LT")} to ${moment(parseInt(data?.enddate)).format("LT")}]`;
  }

  if (
    data?.date_list &&
    data?.date_list?.length > 0 &&
    data?.date_list[0].date !== ""
  ) {
    return `${moment(
      new Date(data?.date_list[0]?.combineStartTime).getTime()
    ).format("DD-MM-YYYY")} 
    to ${moment(
      new Date(
        data?.date_list[data?.date_list?.length - 1]?.combineEndTime
      ).getTime()
    ).format("DD-MM-YYYY")}
    `;
  }
  if (!data?.date_list || !data?.startdate) {
    return "yet to be added.";
  }
}

const Report = () => {
  const [value, setValue] = useState("1");
  const [rows, setRows] = useState([]);
  const [rows1, setRows1] = useState([]);
  const [frows, setFrows] = useState([]);
  const [fees, setFees] = useState();
  const classes = useStyles();
  const [spin, setSpin] = useState(true);
  const [tableData, setTableData] = useState([]);
  console.log({ tableData });
  const [startData, setStartData] = useState([]);
  const [topicRows, setTopicRows] = useState([]);
  const [topicData, setTopicData] = useState([]);
  const [feedback, setFeedback] = useState([]);
  const [openFeedback, setOpenFeedback] = useState(false);
  const [click, setClick] = useState(true);
  let userDetails = useSelector(authData);
  console.log({ userDetails });

  useEffect(() => {
    getSessionList();
    getTopicList();
  }, [value]);

  //Function to change values on tab change
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  async function getSessionList() {
    setSpin(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        schema: Constants.SCHEMA_NAME,
        st_id: userDetails.st_id,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log(bodyParam.body);
      const list = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.LIST_SESSION_ALL,
        bodyParam
      );
      console.log("list123", list.body);
      setTableData(list.body);
      const sessionList = list.body;
      console.log({ sessionList });
      let trows = [];
      for (let i = 0; i < sessionList.length; i++) {
        let obj = {};
        obj.id = i;
        obj.sid = sessionList[i].sid;
        obj.sessionImage = sessionList[i].timgname;
        obj.sessionid = sessionList[i].msid;
        obj.sessionName = sessionList[i].sn;
        obj.sessionDate = sessionDate(sessionList[i]);
        obj.speakerName = sessionList[i]?.sp?.map((item) => item?.name);
        obj.total_feedback = sessionList[i]?.feedback_rating.total_feedback;
        obj.avg_session_rating =
          sessionList[i]?.feedback_rating.avg_session_rating;
        obj.avg_instructor_rating =
          sessionList[i]?.feedback_rating.avg_instructor_rating;
        obj.avg_impact_rating =
          sessionList[i]?.feedback_rating.avg_impact_rating;
        obj.interested = sessionList[i]?.ei_count;
        obj.registered = sessionList[i]?.approved_count;
        trows.push(obj);
      }
      setRows(trows);
      setSpin(false);
      let trows1 = [];
      for (let i = 0; i < sessionList.length; i++) {
        let obj = {};
        obj.sessionName = sessionList[i].sn;
        obj.sessionid = sessionList[i].msid;
        obj.sessionDate = sessionDate(sessionList[i]);
        obj.speakerName = sessionList[i]?.sp?.map((item) => item?.name);
        obj.total_feedback = sessionList[i]?.feedback_rating.total_feedback;
        obj.avg_session_rating =
          sessionList[i]?.feedback_rating.avg_session_rating;
        obj.avg_instructor_rating =
          sessionList[i]?.feedback_rating.avg_instructor_rating;
        obj.avg_impact_rating =
          sessionList[i]?.feedback_rating.avg_impact_rating;
        obj.interested = sessionList[i]?.ei_count;
        obj.registered = sessionList[i]?.approved_count;
        trows1.push(obj);
      }
      setRows1(trows1);
    } catch (error) {
      console.error(error);
    }
  }

  const headers = [
    { label: "Session Name", key: "sessionName" },
    { label: "Session Id", key: "sessionid" },
    { label: "Session Date", key: "sessionDate" },
    { label: "Speaker Name", key: "speakerName" },
    { label: "Total No of Feedback", key: "total_feedback" },
    { label: "Average Session Rating", key: "avg_session_rating" },
    { label: "Average Instructor Rating", key: "avg_instructor_rating" },
    { label: "Average Impact Rating", key: "avg_impact_rating" },
    { label: "Interested Participants", key: "interested" },
    { label: "Registered Participants", key: "registered" },
  ];
  const dispatch = useDispatch();
  let history = useHistory();
  const handleTotalNumberOfFeedback = (param) => {
    let sdata = { ...userDetails };
    sdata.params = param;
    dispatch(awsSignIn(sdata));
    console.log(sdata);
    history.push("/report/users");
  };

  function handleFeedbackClose() {
    setOpenFeedback(false);
  }
  const tcolumns = [
    {
      field: "sessionid",
      headerName: "Session ID",
      flex: 1,
      headerClassName: "super-app-theme--header",
      minWidth: 150,
    },
    {
      field: "sessionImage",
      headerName: "Image",
      flex: 1,
      headerClassName: "super-app-theme--header",
      disableExport: true,
      minWidth: 150,
      renderCell: (params) => (
        <img
          alt=""
          src={`https://${
            config.DOMAIN
          }/${config.aws_org_id.toLowerCase()}-resources/images/session-images/${
            params.value
          }`}
          style={{ width: "100px", height: "auto", borderRadius: "10px" }}
        />
      ),
    },
    {
      field: "sessionName",
      headerName: "Session Title",
      flex: 1,
      headerClassName: "super-app-theme--header",
      minWidth: 250,
    },
    {
      field: "sessionDate",
      headerName: "Session Date",
      flex: 1,
      headerClassName: "super-app-theme--header",
      minWidth: 250,
      renderCell: (params) => {
        <div>{params.value}</div>;
      },
    },
    {
      field: "speakerName",
      headerName: "Speaker Name",
      flex: 1,
      headerClassName: "super-app-theme--header",
      minWidth: 200,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            maxWidth: "190px",
            flexWrap: "wrap",
            gap: "10px",
          }}
        >
          {params?.value?.map((item) => (
            <button
              style={{
                maxWidth: "190px",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {item}
            </button>
          ))}
        </div>
      ),
    },
    {
      field: "total_feedback",
      headerName: "Total No. of Feedback",
      flex: 1,
      headerClassName: "super-app-theme--header",
      minWidth: 250,
      renderCell: (param) => (
        <div
          onClick={() => handleTotalNumberOfFeedback(param)}
          style={{
            display: "flex",
            width: "50%",
            margin: "0 auto",
            flexWrap: "wrap",
            gap: "10px",
          }}
        >
          {param.row.total_feedback}
        </div>
      ),
    },
    // {
    //   field: "avg_session_rating",
    //   headerName: "Avg Session Rating",
    //   flex: 1,
    //   headerClassName: "super-app-theme--header",
    //   minWidth: 150,
    //   renderCell: (params) => (
    //     <Rating
    //       name="half-rating"
    //       defaultValue={params?.row?.avg_session_rating}
    //       precision={0.5}
    //       readOnly
    //     />
    //   ),
    // },
    // {
    //   field: "avg_instructor_rating",
    //   headerName: "Avg Instructor Rating",
    //   flex: 1,
    //   headerClassName: "super-app-theme--header",
    //   minWidth: 200,
    //   renderCell: (params) => (
    //     <Rating
    //       name="half-rating"
    //       defaultValue={params?.row?.avg_instructor_rating}
    //       precision={0.5}
    //       readOnly
    //     />
    //   ),
    // },
    // {
    //   field: "avg_impact_rating",
    //   headerName: "Avg Impact Rating",
    //   flex: 1,
    //   headerClassName: "super-app-theme--header",
    //   minWidth: 200,
    //   renderCell: (params) => (
    //     <Rating
    //       name="half-rating"
    //       defaultValue={params?.row?.avg_impact_rating}
    //       precision={0.5}
    //       readOnly
    //     />
    //   ),
    // },

    // {
    //   field: "avg_session_rating",
    //   headerName: "Avg Session Rating",
    //   flex: 1,
    //   headerClassName: "super-app-theme--header",
    //   minWidth: 200,
    // },
    // {
    //   field: "avg_session_rating",
    //   headerName: "Avg Session Rating",
    //   flex: 1,
    //   headerClassName: "super-app-theme--header",
    //   minWidth: 200,
    // },
    // {
    //   field: "avg_session_rating",
    //   headerName: "Avg Session Rating",
    //   flex: 1,
    //   headerClassName: "super-app-theme--header",
    //   minWidth: 200,
    // },
    // {
    //   field: "avg_session_rating",
    //   headerName: "Avg Session Rating",
    //   flex: 1,
    //   headerClassName: "super-app-theme--header",
    //   minWidth: 200,
    // },

    {
      field: "interested",
      headerName: "Interested Participants",
      flex: 1,
      headerClassName: "super-app-theme--header",
      minWidth: 150,
    },
    {
      field: "registered",
      headerName: "Registered Participants",
      flex: 1,
      headerClassName: "super-app-theme--header",
      minWidth: 150,
    },
  ];

  async function getTopicList() {
    setSpin(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        schema: Constants.SCHEMA_NAME,
        admin: true,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log(bodyParam.body);
      const list = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_CATEGORY_DATA,
        bodyParam
      );
      console.log("list123", list.courses);
      setTopicData(list.courses);
      const topicList = list.courses;
      let trows = [];
      for (let i = 0; i < topicList.length; i++) {
        let obj = {};
        obj.id = i;
        obj.courseImage = topicList[i].tid;
        obj.courseName = topicList[i].tn;
        obj.registered = topicList[i].registered_count;
        trows.push(obj);
      }
      setTopicRows(trows);
      setSpin(false);
    } catch (error) {
      console.error(error);
    }
  }

  //Getting financial reports
  async function getRegisteredUsers(val, price) {
    setSpin(true);
    const bodyParam = {
      body: {
        sid: val,
        oid: config.aws_org_id,
        typeusers: "reg_users",
        tenantoid: false,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const reg = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_REGISTERED_USERS,

        bodyParam
      );

      console.log("reg", reg);
      const regList = reg.body;
      console.log({ regList });
      let frows = [];
      for (let i = 0; i < regList.length; i++) {
        let obj = {};
        obj.id = i;
        obj.firstname = regList[i].first_name;
        obj.lastname = regList[i].last_name;
        obj.email = regList[i].emailid;
        obj.assigne = regList[i].assign;
        obj.attend =
          regList[i].attend === null
            ? "Not Marked"
            : regList[i].attend > 0
            ? "Attended"
            : "Not Attended";
        obj.sessionfees = regList[i].actual_amount
          ? regList[i].actual_amount
          : "yet to be added.";
        obj.paymentstatus = price === "Free Session" ? "Free Session" : "Paid";
        frows.push(obj);
      }
      setFrows(frows);
      setSpin(false);
    } catch (error) {
      console.error(error);
      setSpin(false);
    }
  }

  const fcolumns = [
    {
      field: "firstname",
      headerName: "First Name",
      flex: 1,
      headerClassName: "super-app-theme--header",
      width: 400,
    },
    {
      field: "lastname",
      headerName: "Last Name",
      flex: 1,
      headerClassName: "super-app-theme--header",
      minWidth: 100,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      headerClassName: "super-app-theme--header",
      minWidth: 250,
    },
    {
      field: "assign",
      headerName: "Assign",
      flex: 1,
      headerClassName: "super-app-theme--header",
      width: 400,
    },
    {
      field: "attend",
      headerName: "Attended",
      flex: 1,
      headerClassName: "super-app-theme--header",
      minWidth: 100,
    },
    {
      field: "sessionfees",
      headerName: "Session Fees",
      flex: 1,
      headerClassName: "super-app-theme--header",
      minWidth: 250,
    },
    {
      field: "paymentstatus",
      headerName: "Payment Status",
      flex: 1,
      headerClassName: "super-app-theme--header",
      minWidth: 200,
    },
  ];

  function handleSelect(val) {
    console.log("value", val);

    let value = "";
    if (val.priceinr === null) {
      value = "Free Session";
    } else {
      let amount;
      let currency = "INR";
      const event = new Date();
      event.setHours(0);
      event.setMinutes(0);
      event.setSeconds(0);
      event.setMilliseconds(0);
      if (
        val?.earlypriceinr &&
        val?.earlypriceusd &&
        val?.earlydate &&
        event.getTime() < parseInt(val?.earlydate)
      ) {
        if (currency === "USD") {
          amount = val?.earlypriceusd;
          value = amount.toString();
        } else {
          amount = val?.earlypriceinr;
          value = amount;
        }
      } else if (val?.perdis !== null && val?.perdis !== 0) {
        if (currency === "USD") {
          amount = val?.priceafterusd;
          value = amount.toString();
        } else {
          amount = val?.priceafterinr;
          value = amount.toString();
        }
      } else {
        if (currency === "USD") {
          amount = val?.priceusd;
          value = amount.toString();
        } else {
          amount = val?.priceinr;
          value = amount.toString();
        }
      }
      setFees(amount);
    }
    getRegisteredUsers(val.sid, value);
  }

  const CustomExportButton = () => {
    return (
      <Button color="primary">
        <CSVLink
          data={rows1}
          headers={headers}
          filename="session-report"
          className={Rep.exp_btn}
        >
          <FileDownload />
          Export
        </CSVLink>
      </Button>
    );
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <CustomExportButton />
      </GridToolbarContainer>
    );
  }
  function CustomToolbar1() {
    return (
      <GridToolbarContainer
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <GridToolbar />
      </GridToolbarContainer>
    );
  }

  return (
    <div className={Rep.main}>
      {/* feedback */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openFeedback}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openFeedback}>
          <FeedbackPopUp
            handleRegClose={handleFeedbackClose}
            feedback={feedback}
          />
        </Fade>
      </Modal>
      {/* feedback end */}
      <Backdrop sx={{ color: "#fff" }} open={spin} className={classes.backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Paper>
        <TabContext value={value}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            indicatorColor="primary"
            textColor="inherit"
            variant="fullWidth"
          >
            <Tab label="Session Reports" value="1" />
            <Tab label="Finance Reports" value="2" />
            <Tab label="Session Report" value="3" />
            <Tab label="Participant Details" value="4" />
            <Tab label="Revenue" value="5" />
            <Tab label="Company Details" value="6" />
          </Tabs>
          <TabPanel value="1">
            {rows.length === 0 ? (
              <div
                style={{
                  height: "60vh",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  marginBottom: "80px",
                }}
              >
                <img src={NothiongHereYet} alt="Nothing Here" />
              </div>
            ) : (
              <div
                style={{ height: 535, width: "100%" }}
                className={classes.root}
              >
                <DataGrid
                  rows={rows}
                  columns={tcolumns}
                  rowHeight={90}
                  className={classes[".MuiDataGrid-root"]}
                  components={{
                    Toolbar: CustomToolbar,
                  }}
                  disableSelectionOnClick
                />
              </div>
            )}
          </TabPanel>
          <TabPanel value="2">
            <div className={Rep.financemain}>
              <h2 className={Rep.sessionlabel}>Select Session: </h2>
              <select
                className={Rep.sessionSelect}
                onChange={(e) => {
                  handleSelect(tableData[e.target.value]);
                }}
              >
                <option value="Select" disabled selected>
                  Select Session
                </option>
                {tableData?.map((item, i) => {
                  return (
                    <option value={i} key={item + i + ""}>
                      {item.sn}: {sessionFinanceDate(item)}
                    </option>
                  );
                })}
              </select>
            </div>

            {frows.length === 0 ? (
              <div
                style={{
                  height: 170,
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  marginBottom: "200px",
                  marginTop: "100px",
                }}
              >
                <img src={NothiongHereYet} alt="Nothing Here" />
              </div>
            ) : (
              <div
                style={{ height: 460, width: "100%", paddingTop: "20px" }}
                className={classes.root}
              >
                <DataGrid
                  rows={frows}
                  columns={fcolumns}
                  rowHeight={70}
                  className={classes[".MuiDataGrid-root"]}
                  components={{
                    Toolbar: CustomToolbar1,
                  }}
                  disableSelectionOnClick
                  // scrollbarSize={20}
                />
              </div>
            )}
          </TabPanel>
          <TabPanel value="6">
            <SessionReport type="company_details" tableData={tableData}></SessionReport>
          </TabPanel>
          <TabPanel value="4">
            <SessionReport type="participant_details" tableData={tableData}></SessionReport>
          </TabPanel>
          <TabPanel value="5">
            <SessionReport type="revenue" tableData={tableData}></SessionReport>
          </TabPanel>
          <TabPanel value="3">
            <SessionReport type="session" tableData={tableData}></SessionReport>
          </TabPanel>
        </TabContext>
      </Paper>
    </div>
  );
};

export default Report;
