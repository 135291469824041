import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import ConTenantStyle from "./ContentTenant.module.scss";
import Paper from "@mui/material/Paper";
//import image from "../../../assets/images/2.png";
import Rating from "@mui/material/Rating";
import { Link } from "react-router-dom";
import config from "../../../config/aws-exports";
import useFetch from "../../Hooks/useFetch";
import {
  DataGrid,
  GridToolbar,
  GridToolbarContainer,
} from "@material-ui/data-grid";
import Backdrop from "@material-ui/core/Backdrop";
import Skeleton from "@material-ui/lab/Skeleton";

import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import Table from "@mui/material/Table";
import { API } from "aws-amplify";
import moment from "moment";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
//import TopCoursesChart from "../Dashboard/TopCoursesChart";
import PouplarCoursesChart from "./popularCourseschart";

// Local imports
import { Constants } from "../../../config/Constants";
import { useHistory } from "react-router-dom";
import RegisteredUsers from "../Content/ListOfUsers";
import ApproveUsers from "../Content/ApproveUsers";
import InterestedUsers from "../Content/InterestedUsers";

import image1 from "../../../assets/images/2.png";

import shortid from "shortid";
import { Bar } from "react-chartjs-2";

import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../../redux/auth/authSlice";

const programNames = {
  color: Constants.blue_color,
  fontFamily: "nunito",
  fontSize: "15px",
  fontWeight: "500",
};

const header = {
  color: "white",
  fontFamily: "nunito",
  fontSize: "16px",
  fontWeight: "400",
};

const useStyles = makeStyles((theme) => ({
  root: {
    "& .super-app-theme--header": {
      backgroundColor: Constants.blue_color,
      fontSize: "16px",
      color: "rgb(255,255,255)",
      fontFamily: "nunito",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
    cursor: "pointer",
  },
  ".css-13xfq8m-MuiTabPanel-root": {
    padding: 0,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function CustomToolbar() {
  return (
    <GridToolbarContainer
      style={{ display: "flex", justifyContent: "flex-end" }}
    >
      <GridToolbar />
    </GridToolbarContainer>
  );
}

export default function ContentTenant() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const userDetails = useSelector(authData);
  const [topics, setTopics] = useState([]);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [rowsC, setRowsC] = useState([]);
  const [columnsC, setColumnsC] = useState([]);

  const [value, setValue] = useState("3");
  const [loading, setLoading] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const [tenantsPub, setTenantsPub] = useState([]);
  const [tenantsPubCourse, setTenantsPubCourse] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [objects, setObjectsList] = useState([]);
  const [course, setCourseList] = useState([]);
  const [spin, setSpin] = useState(true);
  const [sessions, setSessions] = useState([]);
  const [openList, setOpenList] = useState(false);
  const [listForReg, setListForReg] = useState({});
  const [openApprove, setOpenApprove] = useState(false);
  const [waitingData, setWaitingData] = useState({});
  const [openInterested, setOpenInterested] = useState(false);
  const [interestedData, setInterestedData] = useState({});

  const [graphData, setGraphData] = useState({
    labels: [],
    data: [],
  });

  let tempArray = [];

  useEffect(() => {
    // getContentList();
    // getCourseList();
    getTodaySession();
  }, []);
  async function getTodaySession() {
    setSpin(true);
    const bodyParam = {
      body: {
        oid: userDetails.tenantdata.tenantname,
        schema: Constants.SCHEMA_NAME,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log(bodyParam.body);
      const Session = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_SESSION,
        bodyParam
      );
      if (Session !== undefined && Session !== null) {
        setSessions(Session.body);
        setSpin(false);
        const listAll = Session.body;
        console.log("rows", listAll);
        /* var myDateT = moment().format("L");
        const arrayDateFilter = listAll.filter((innArr, index) => {
          let startDate = innArr.startdate;
          let convertedDate = moment(parseInt(startDate)).format("L");
          console.log("trueSTA", startDate);
          console.log("trueCON", convertedDate);

          console.log("true", convertedDate === myDateT);
          if (convertedDate == myDateT) {
            return innArr;
          }
        }); */

        let trows = [];
        for (let i = 0; i < listAll.length; i++) {
          let obj = {};
          obj.id = i;
          obj.sessionImage = listAll[i].timgname;
          obj.name = listAll[i].sn;
          // obj.datetime = `${moment(parseInt(listAll[i].startdate)).format(
          //   "DD-MM-YYYY"
          // )} ${moment(parseInt(listAll[i].startdate)).format("LT")}`;
          console.log("date_list==",listAll)
          if (
            listAll[i].date_list &&
            listAll[i].date_list.length > 0 &&
            listAll[i]?.date_list[0].combineStartTime
          ) {
            obj.datetime = ` ${moment(
                new Date(listAll[i]["date_list"][0].combineStartTime).getTime()
              ).format("DD-MM-YYYY")} `;
          } else if (listAll[i].startdate && listAll[i].enddate) {
            obj.datetime = `
              ${moment(new Date(parseInt(listAll[i].startdate)).getTime()).format(
                "DD-MM-YYYY"
              )}`;
          } else {
            obj.datetime = "Yet to de added.";
          }
          obj.ei_count = listAll[i].ei_count;
          obj.registered = listAll[i].approved_count;
          obj.waiting_app = listAll[i].waiting_app;
          obj.sessionDet = listAll[i];

          trows.push(obj);
        }
        const tcolumns = [
          {
            field: "sessionImage",
            headerName: "Session Image",
            flex: 1,
            headerClassName: "super-app-theme--header",
            disableExport: true,
            renderCell: (params) => (
              <img
                alt=""
                src={
                  params.value === null
                    ? image1
                    : `https://${
                        config.DOMAIN
                      }/${config.aws_org_id.toLowerCase()}-resources/images/session-images/${
                        params.value
                      }`
                }

                style={{
                  margin: "10px",
                  width: "100px",
                  height: "auto"
                }}
              />
            ),
          },
          {
            field: "name",
            headerName: "Live Session Name",
            flex: 1,
            headerClassName: "super-app-theme--header",
          },
          {
            field: "datetime",
            headerName: "Date and Time",
            flex: 1,
            headerClassName: "super-app-theme--header",
          },
          {
            field: "registered",
            headerName: "Registered Users",
            flex: 1,
            headerClassName: "super-app-theme--header",
            renderCell: (params) => (
              <div
                onClick={() => {
                  handleRegClick(params);
                }}
              >
                {params.value}
              </div>
            ),
          },
          {
            field: "ei_count",
            headerName: "Expressed Interest",
            flex: 1,
            headerClassName: "super-app-theme--header",
            renderCell: (params) => (
              <div
                onClick={() => {
                  handleOpenInterested(params);
                }}
              >
                {params.value}
              </div>
            ),
          },
          {
            field: "waiting_app",
            headerName: "Waiting for approval",
            flex: 1,
            headerClassName: "super-app-theme--header",
            renderCell: (params) => (
              <div
                onClick={() => {
                  handleApproveUserOpen(params);
                }}
              >
                {params.value}
              </div>
            ),
          },
        ];
        //alert(JSON.stringify(trows));
        setRows(trows);
        setColumns(tcolumns);
        setSpin(false);
      }
    } catch (error) {
      console.error(error);
    }
  }

  function handleRegClick(params) {
    console.log("paramsss", params.row.sessionDet);
    setOpenList(true);
    setListForReg(params.row.sessionDet);
  }

  const handleApproveUserOpen = (params) => {
    setOpenApprove(true);
    setWaitingData(params.row.sessionDet);
  };

  const handleOpenInterested = (params) => {
    setOpenInterested(true);
    setInterestedData(params.row.sessionDet);
  };

  const handleCloseList = () => setOpenList(false);
  const handleApproveUserClose = () => setOpenApprove(false);
  const handleCloseInterested = () => setOpenInterested(false);

  // async function getContentList() {
  //   setLoading(true);
  //   const bodyParam = {
  //     body: {
  //       oid: userDetails.tenantdata.tenantname,
  //       schema: Constants.SCHEMA_NAME,
  //     },
  //     headers: {
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //     },
  //   };
  //   try {
  //     console.log(bodyParam.body);
  //     const list = await API.post(
  //       config.aws_cloud_logic_custom_name,
  //       Constants.LIST_CONTENT,
  //       bodyParam
  //     );
  //     console.log("list", list);
  //     setObjectsList(list.body);
  //     /* setTableData(list.rows); */
  //     let Objectslist = list.body;
  //     let trows = [];
  //     for (let i = 0; i < Objectslist.length; i++) {
  //       let obj = {};
  //       obj.id = i;
  //       obj.title = Objectslist[i].objectname;
  //       obj.views =
  //         Objectslist[i].total_views === null ? 0 : Objectslist[i].total_views;
  //       obj.uniqueViews = Objectslist[i].unique_views;
  //       obj.bounceViews =
  //         Objectslist[i].bounce_views === null
  //           ? 0
  //           : Objectslist[i].bounce_views;
  //       obj.usersRated = Objectslist[i].users_rated;
  //       obj.tenant = Objectslist[i].tenant;
  //       obj.objid = Objectslist[i].objid;

  //       obj.rating = Objectslist[i].rating;

  //       trows.push(obj);
  //     }
  //     const tcolumns = [
  //       {
  //         field: "title",
  //         headerName: "Title",
  //         flex: 1,
  //         headerClassName: "super-app-theme--header",
  //         width: 400,
  //         editable: true,
  //         renderCell: (params) => (
  //           <div
  //             onClick={() => {
  //               handleObjClick(params);
  //             }}
  //           >
  //             <Link
  //               to="/tenants/viewTenant/objectUsers"
  //               style={{
  //                 color: "black",
  //                 textDecoration: "none",
  //                 fontFamily: "14px",
  //                 //fontWeight: "bold",
  //               }}
  //             >
  //               {params.value}
  //             </Link>
  //           </div>
  //         ),
  //       },
  //       {
  //         field: "views",
  //         headerName: "# Views",
  //         flex: 1,
  //         headerClassName: "super-app-theme--header",
  //       },
  //       {
  //         field: "uniqueViews",
  //         headerName: "# Unique Views",
  //         flex: 1,
  //         headerClassName: "super-app-theme--header",
  //       },
  //       {
  //         field: "bounceViews",
  //         headerName: "#  Users Views",
  //         flex: 1,
  //         headerClassName: "super-app-theme--header",
  //       },
  //       {
  //         field: "usersRated",
  //         headerName: "#  Users Rated",
  //         flex: 1,
  //         headerClassName: "super-app-theme--header",
  //       },

  //       {
  //         field: "rating",
  //         headerName: "Ratings",
  //         flex: 1,
  //         headerClassName: "super-app-theme--header",
  //         width: 400,
  //         renderCell: (params) => (
  //           <Rating name="read-only" value={params.value} readOnly />
  //         ),
  //       },
  //     ];
  //     setRows(trows);
  //     setColumns(tcolumns);
  //     setLoading(false);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }
  // async function getCourseList() {
  //   setLoading(true);
  //   const bodyParam = {
  //     body: {
  //       oid: userDetails.tenantdata.tenantname,
  //       org: config.aws_org_id,
  //       schema: Constants.SCHEMA_NAME,
  //     },
  //     headers: {
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //     },
  //   };
  //   try {
  //     console.log(bodyParam.body);
  //     const listC = await API.post(
  //       config.aws_cloud_logic_custom_name,
  //       Constants.LIST_COURSES,
  //       bodyParam
  //     );
  //     console.log("list", listC);

  //     setCourseList(listC.body);
  //     /* setTableData(list.rows); */
  //     let ObjectslistC = listC.body;
  //     let trowsC = [];
  //     for (let i = 0; i < ObjectslistC.length; i++) {
  //       let obj = {};
  //       obj.id = i;
  //       obj.col1 = ObjectslistC[i].tid;
  //       obj.title = ObjectslistC[i].topicname;
  //       obj.sub_cnt = ObjectslistC[i].sub_cnt;
  //       obj.comp_cnt = ObjectslistC[i].comp_cnt;
  //       obj.rating = ObjectslistC[i].rating;
  //       obj.tid = ObjectslistC[i].tid;
  //       obj.tenant = ObjectslistC[i].tenant;

  //       trowsC.push(obj);
  //     }
  //     const tcolumnsC = [
  //       {
  //         field: "col1",
  //         headerName: "Image",
  //         flex: 1,
  //         headerClassName: "super-app-theme--header",
  //         width: 400,
  //         renderCell: (params) => (
  //           <img
  //             style={{
  //               width: "150px",
  //               height: "80px",
  //               borderRadius: "10px",
  //             }}
  //             src={`https://${
  //               config.DOMAIN
  //             }/${config.aws_org_id.toLowerCase()}-resources/images/topic-images/${
  //               params.value
  //             }.png`}
  //             alt=""
  //           ></img>
  //         ),
  //       },
  //       {
  //         field: "title",
  //         headerName: "Course Title",
  //         flex: 1,
  //         headerClassName: "super-app-theme--header",
  //         width: 400,
  //         editable: true,
  //         renderCell: (params) => (
  //           <div
  //             onClick={() => {
  //               handleCourseClick(params);
  //             }}
  //           >
  //             <Link
  //               to="/tenants/viewTenant/courseusers"
  //               style={{
  //                 color: "black",
  //                 textDecoration: "none",
  //                 fontFamily: "14px",
  //                 //fontWeight: "bold",
  //               }}
  //             >
  //               {params.value}
  //             </Link>
  //           </div>
  //         ),
  //       },
  //       {
  //         field: "sub_cnt",
  //         headerName: "# Users started",
  //         flex: 1,
  //         headerClassName: "super-app-theme--header",
  //       },
  //       {
  //         field: "comp_cnt",
  //         headerName: "# Users completed",
  //         flex: 1,
  //         headerClassName: "super-app-theme--header",
  //       },

  //       {
  //         field: "rating",
  //         headerName: "Ratings",
  //         flex: 1,
  //         headerClassName: "super-app-theme--header",
  //         width: 400,
  //         renderCell: (params) => (
  //           <Rating name="read-only" value={params.value} readOnly />
  //         ),
  //       },
  //     ];
  //     setRowsC(trowsC);
  //     setColumnsC(tcolumnsC);
  //     setSpin(false);
  //     setLoading(false);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }
  async function unPublishContent(array) {
    setLoading(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        objects: array,
        tenantid: userDetails.tenantdata.tenant,
        schema: Constants.SCHEMA_NAME,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log(bodyParam.body);
      const list = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.UNPUBLISH_CONTENT,
        bodyParam
      );
      console.log("list", list);
      //getContentList();
      setSelectionModel([]);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  }

  async function unPublishCourse(array) {
    setLoading(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        objects: array,
        tenantid: userDetails.tenantdata.tenant,
        schema: Constants.SCHEMA_NAME,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log(bodyParam.body);
      const list = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.UNPUBLISH_COURSE,
        bodyParam
      );
      console.log("list", list);
      //getCourseList();
      setSelectionModel([]);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  }

  function checking(index1) {
    let tenantlist = [];

    console.log("batch", index1);
    for (let i = 0; i < index1.length; i++) {
      let rowObj = {};
      rowObj.id = rows[index1[i]].objid;
      rowObj.t = rows[index1[i]].tenant;
      tenantlist.push(rowObj);
    }

    console.log("batch1", tenantlist);
    console.log("bat", JSON.stringify(tenantlist));
    setTenantsPub(tenantlist);
  }
  function checkingCourse(index1) {
    let tenantlist = [];

    console.log("batch", index1);
    for (let i = 0; i < index1.length; i++) {
      let rowObj = {};
      rowObj.id = rowsC[index1[i]].tid;
      rowObj.t = rowsC[index1[i]].tenant;
      tenantlist.push(rowObj);
    }

    console.log("batch1", tenantlist);
    console.log("bat", JSON.stringify(tenantlist));
    setTenantsPubCourse(tenantlist);
  }

  const handleObjClick = (params) => {
    console.log(params.row);
    let details = { ...userDetails };

    details.OBJID = params.row.objid;
    details.objectName = params.row.title;

    let temp = [...details.breadcrumb];

    temp[2] = {
      name: params.row.title,
      path: "/tenants/viewTenant/objectUsers",
    };

    details.breadcrumb = temp;

    dispatch(awsSignIn(details));
  };

  const handleCourseClick = (param, event) => {
    console.log(param.row);
    let details = { ...userDetails };
    details.courseID = param.row.tid;
    details.topicName = param.row.title;

    let temp = [...details.breadcrumb];

    temp[2] = {
      name: param.row.title,
      path: "/tenants/viewTenant/courseusers",
    };

    details.breadcrumb = temp;

    dispatch(awsSignIn(details));
    // history.push("/tenants/viewTenant/courseusers");
    // console.log(event);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function save() {
    console.log("arraybef", tenantsPub);
    let array = JSON.parse(JSON.stringify(tenantsPub));
    //let array = tenantsPub.map((a) => ({ ...a }));

    /*  array = array.concat([...tenantsPub]); */
    console.log("a", array);
    for (let i = 0; i < array.length; i++) {
      let tenantss = array[i];
      console.log("tenantss", tenantss);
      for (let j = 0; j < tenantss.t.length; j++) {
        let tenant = tenantss.t[j];
        console.log("tenant", tenant);
        if (tenant === userDetails.tenantdata.st_id.toString()) {
          var index = tenantss.t.indexOf(tenant);
          tenantss.t.splice(index, 1);
        }
      }
    }

    /*   array.map((tenantss) => {
      tenantss.t.map((tenant) => {
        if (tenant === userDetails.tenantdata.st_id.toString()) {
          var index = tenantss.t.indexOf(tenant);
          tenantss.t.splice(index, 1);
        }
      });
    }); */
    console.log("array", JSON.stringify(array));
    unPublishContent(array);
  }
  function saveCourse() {
    console.log("arraybef", tenantsPubCourse);
    let array = JSON.parse(JSON.stringify(tenantsPubCourse));
    //let array = tenantsPub.map((a) => ({ ...a }));

    /*  array = array.concat([...tenantsPub]); */
    console.log("a", array);
    for (let i = 0; i < array.length; i++) {
      let tenantss = array[i];
      console.log("tenantss", tenantss);
      for (let j = 0; j < tenantss.t.length; j++) {
        let tenant = tenantss.t[j];
        console.log("tenant", tenant);
        if (tenant === userDetails.tenantdata.st_id.toString()) {
          var index = tenantss.t.indexOf(tenant);
          tenantss.t.splice(index, 1);
        }
      }
    }

    /*   array.map((tenantss) => {
      tenantss.t.map((tenant) => {
        if (tenant === userDetails.tenantdata.st_id.toString()) {
          var index = tenantss.t.indexOf(tenant);
          tenantss.t.splice(index, 1);
        }
      });
    }); */
    console.log("array", JSON.stringify(array));
    unPublishCourse(array);
  }

  function getSessionList() {}

  return (
    <div>
      <div style={{ padding: "20px" }}>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",

            justifyContent: "center",
            gap: "30px",
            width: "100%",
          }}
        >
          <div>
            <Paper className={ConTenantStyle.paper1}>
              <div style={{ display: "grid" }}>
                <div className={ConTenantStyle.header}>TOTAL LIVE SESSION</div>
                <div className={ConTenantStyle.count}>{sessions.length}</div>
              </div>
            </Paper>
          </div>
          {/*    <div>
            <Paper className={ConTenantStyle.paper2}>
              <div className={ConTenantStyle.header}>POPULAR COURSES</div>
              {spin === false ? (
                <PouplarCoursesChart
                  courses={course}
                  style={{ width: "100%" }}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Skeleton
                    variant="rect"
                    width="100%"
                    height={100}
                    style={{ marginBottom: "40px" }}
                  />{" "}
                </div>
              )}
            </Paper>
          </div> */}
        </Box>
      </div>

      <div>
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              indicatorColor="primary"
              textColor="inherit"
              variant="fullWidth"
            >
              {/*  <Tab label="Courses" value="1" /> */}
              {/* <Tab label="Courses" value="1" /> */}
              {/* <Tab label="Content and Colleteral" value="2" /> */}
              <Tab label="Live sessions" value="3" />
            </Tabs>
          </Box>
          {/*   <TabPanel
            value="1"
            className={classes[".css-13xfq8m-MuiTabPanel-root"]}
          >
            <Paper>
              <div
                style={{ height: 400, width: "100%", overflowY: "auto" }}
                className={classes.root}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    padding: "10px 5px",
                  }}
                >
                  <button
                    onClick={() => {
                      saveCourse();
                    }}
                    className={ConTenantStyle.addbutton}
                  >
                    UnPublish
                  </button>
                </div>
                {loading === false ? (
                  <DataGrid
                    rows={rowsC}
                    columns={columnsC}
                    className={classes[".MuiDataGrid-root"]}
                    rowHeight={100}
                    components={{
                      Toolbar: CustomToolbar,
                    }}
                    disableSelectionOnClick
                    checkboxSelection
                    onRowSelected={(e) => console.log("qq", e)}
                    onSelectionModelChange={(newSelection) => {
                      console.log("j", newSelection);
                      setSelectionModel(newSelection);
                      checkingCourse(newSelection);
                    }}
                    selectionModel={selectionModel}
                    //onCellClick={handleCourseClick}
                  />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Skeleton variant="rect" width="100%" height={600} />{" "}
                  </div>
                )}
              </div>
            </Paper>
          </TabPanel> */}
          {/* <TabPanel
            value="2"
            className={classes[".css-13xfq8m-MuiTabPanel-root"]}
          >
            <Paper>
              <div
                style={{ height: 400, width: "100%", overflowY: "auto" }}
                className={classes.root}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    padding: "10px 5px",
                  }}
                >
                  <button
                    onClick={() => {
                      save();
                    }}
                    className={ConTenantStyle.addbutton}
                  >
                    unPublish
                  </button>
                </div>
                {loading === false ? (
                  <DataGrid
                    rows={rows}
                    columns={columns}
                    className={classes[".MuiDataGrid-root"]}
                    components={{
                      Toolbar: CustomToolbar,
                    }}
                    disableSelectionOnClick
                    checkboxSelection
                    onRowSelected={(e) => console.log("qq", e)}
                    onSelectionModelChange={(newSelection) => {
                      console.log("j", newSelection);
                      setSelectionModel(newSelection);
                      checking(newSelection);
                    }}
                    selectionModel={selectionModel}
                    //onCellClick={handleObjClick}
                  />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Skeleton variant="rect" width="100%" height={600} />{" "}
                  </div>
                )}
              </div>
            </Paper>
          </TabPanel> */}
          {/* <TabPanel
            value="1"
            className={classes[".css-13xfq8m-MuiTabPanel-root"]}
          >
            <Paper elevation={3} className={classes.graph}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead style={{ background: "#285780" }}>
                    <TableRow>
                      <TableCell style={header}> Image</TableCell>
                      <TableCell style={header}>Name</TableCell>
                      <TableCell style={header}>Date and Time</TableCell>

                      <TableCell style={header}>
                        No of users expressed interest
                      </TableCell>
                      <TableCell style={header}>
                        No of users registered
                      </TableCell>
                      <TableCell style={header}>
                        No of users in waiting list
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <Tableview />
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[
                          5,
                          10,
                          25,
                          { label: "All", value: -1 },
                        ]}
                        colSpan={3}
                        count={tabledata.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: { "aria-label": "rows per page" },
                          native: true,
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Paper>
          </TabPanel> */}
          <TabPanel
            value="3"
            className={classes[".css-13xfq8m-MuiTabPanel-root"]}
          >
            <div
              style={{ height: 400, width: "100%", overflowY: "auto" }}
              className={classes.root}
            >
              <DataGrid
                rows={rows}
                columns={columns}
                className={classes[".MuiDataGrid-root"]}
                components={{
                  Toolbar: CustomToolbar,
                }}
                disableSelectionOnClick
              />
            </div>
          </TabPanel>
        </TabContext>
      </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openList}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openList}>
          <RegisteredUsers
            handleCloseList={handleCloseList}
            listForReg={listForReg}
            tenantoid={true}
            tenant={userDetails.tenantdata.tenantname}
          />
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openApprove}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openApprove}>
          <ApproveUsers
            handleApproveUserClose={handleApproveUserClose}
            waitingData={waitingData}
            getSessionList={getSessionList}
            tenantoid={true}
            tenant={userDetails.tenantdata.tenantname}
          />
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openInterested}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openInterested}>
          <InterestedUsers
            handleCloseInterested={handleCloseInterested}
            interestedData={interestedData}
            tenantoid={true}
            tenant={userDetails.tenantdata.tenantname}
          />
        </Fade>
      </Modal>
    </div>
  );
}
