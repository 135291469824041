// Dependencies import
import React, { useState, useRef, useEffect } from "react";
import { styled } from "@mui/system";
import swal from "sweetalert2";
import { API } from "aws-amplify";
import config from "../../../config/aws-exports";
import axios from "axios";
import { Button } from "@mui/material";
import BackDrop from "@material-ui/core/Backdrop/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
// Local imports
import { ReactComponent as Close } from "../../../assets/icons/close_black_24dp.svg";
import { Constants } from "../../../config/Constants";
import { generate } from "shortid";
import moment from "moment";

// Style imports
import Add from "./SessionAdd.module.scss";
import { isPdf } from "./SessionAdd";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
}));

const EditSession = ({
  handleEditClose,
  dataToEdit,
  getSessionList,
  speakerList,
}) => {
  console.log("datato", dataToEdit);
  console.log("speaker", speakerList);
  const [name, setName] = useState("");
  const [image, setImage] = useState(null);
  const [startdate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [sessionTypeCheck, setSessionTypeCheck] = useState(true);
  const [paymentTypeCheck, setPaymentTypeCheck] = useState(true);
  const [speaker, setSpeaker] = useState("");
  const [speakerType, setSpeakerType] = useState("");
  const [slots, setSlots] = useState(0);
  const [dumslots, setDumslots] = useState(0);
  const [oldSlots, setOldSlots] = useState(0);
  const [slides, setSlides] = useState([]);
  const [sessionType, setSessionType] = useState("online");
  const [regStartDate, setRegStartDate] = useState("");
  const [webLink, setWebLink] = useState("");
  const [webID, setWebID] = useState("");
  const [passcode, setPasscode] = useState("");
  const [note, setNote] = useState("");
  const [paid, setPaid] = useState("no");
  const [actualPriceINR, setActualPriceINR] = useState("");
  const [actualPriceUSD, setActualPriceUSD] = useState("");
  const [priceAftINR, setPriceAftINR] = useState("");
  const [priceAftUSD, setPriceAftUSD] = useState("");
  const [earlyBirdINR, setEarlyBirdINR] = useState("");
  const [earlyBirdUSD, setEarlyBirdUSD] = useState("");
  const [perDis, setPerDis] = useState("");
  const [earlyDate, setEarlyDate] = useState("");
  const [payLater, setPayLater] = useState("");
  const [payWithin, setPayWithin] = useState("");
  const [des, setDes] = useState("");
  const [object, setObject] = useState("");
  const [offline, setOffline] = useState(false);
  const [locationValue, setLocationValue] = useState("");
  const [courseID, setCourseID] = useState("");
  const [courseIdError, setCourseIdError] = useState("");
  const [location, setLocation] = useState(false);
  const [target, setTarget] = useState("");
  const [nameError, setNameError] = useState("");
  const [imageError, setImageError] = useState("");
  const [sessionStartTimeError, setSessionStartTimeError] = useState("");
  const [sessionEndTimeError, setSessionEndTimeError] = useState("");
  const [speakerError, setSpeakerError] = useState("");
  const [slotsError, setSlotsError] = useState("");
  const [speakerTypeError, setSpeakerTypeError] = useState("");
  const [slidesError, setSlidesError] = useState("");
  const [locationError, setLocationError] = useState("");
  const [sessionTypeError, setSessionTypeError] = useState("");
  const [webLinkError, setWebLinkError] = useState("");
  const [webLinkFormatError, setWebLinkFormatError] = useState("");
  const [wrongStartDateError, setWrongStartDateError] = useState("");
  const [paidError, setPaidError] = useState("");
  const [actualPriceError, setActualPriceError] = useState("");
  const [view, setView] = useState(false);
  const [imageFileName, setImageFileName] = useState(null);
  const [sessionFileName, setSessionFileName] = useState(null);
  const [payLaterDateError, setPayLaterDateError] = useState("");
  const [imageExtentionError, setImageExtentionError] = useState("");
  const [docExtend, setDocExtend] = useState("");
  const [docExtentionError, setDocExtentionError] = useState("");
  const [onChangeSlide, setOnChangeSlides] = useState(false);
  const [spin, setSpin] = useState(false);
  const [speakerArray, setSpeakerArray] = useState([]);
  const refselectedImageFile = useRef();
  const refselectedSlideFile = useRef();
  const [changeimage, setChangeImage] = useState(false);
  const [changeSlide, setChangeSlide] = useState(false);
  const [earlyError, setEarlyError] = useState("");
  const [earlyDateError, setEarlyDateError] = useState("");
  const [startTimeError, setStartTimeError] = useState("");
  const [wrongDateError, setWrongDateError] = useState("");
  const [totalSlotsError, setTotalSlotsError] = useState("");
  const [regStartDateError, setRegStartDateError] = useState("");
  const [payLaterError, setPayLaterError] = useState("");
  const [topayment, setTopayment] = useState(false);
  const [radioOffilne, setRadioOffline] = useState(false);
  const [radioHybrid, setRadioHybrid] = useState(false);
  const [defaultSpeaker, setDefaultSpeaker] = useState(true);
  const [agendaFileChanges, setAgendaFileChanges] = useState(false);
  const [agendaFiles, setAgendaFiles] = useState([]);
  const classes = useStyles();
  const [slotsFree, setSlotsFree] = useState(
    dataToEdit.paid == "true" ? false : true
  );
  const [dateList, setDateList] = useState([
    {
      id: generate(),
      date: "",
      sTime: "",
      eTime: "",
      min: new Date().toISOString().slice(0, 10),
    },
  ]);
  const [certificateFile, setCertificateFile] = useState();
  const [tempCfile, setTempCfile] = useState([]);
  useEffect(() => {
    if (dataToEdit.slides == null) {
      dataToEdit.slides = [];
      setSlides([]);
    }
    setName(dataToEdit.sn);
    setCourseID(dataToEdit.msid);
    setSpeakerArray(dataToEdit.speaker_id);
    setSpeakerType(dataToEdit.speaker_type);
    setOldSlots(dataToEdit.tslots);
    setDumslots(dataToEdit.tslots);
    setAgendaFiles(dataToEdit?.agenda?.map((a) => ({ name: a })));
    setSlots(dataToEdit.tslots);
    setWebLink(dataToEdit.weblink);
    setWebID(dataToEdit.web_id);
    setPasscode(dataToEdit.web_pass);
    setNote(dataToEdit.note);
    /*  if (dataToEdit.DTLS.PAID === "yes") { */
    setActualPriceINR(dataToEdit.priceinr);
    setActualPriceUSD(dataToEdit.priceusd);
    setPerDis(dataToEdit.perdis);
    setPriceAftINR(dataToEdit.priceafterinr);
    setPriceAftUSD(dataToEdit.priceafterusd);

    setEarlyBirdINR(dataToEdit.earlypriceinr || "");
    setEarlyBirdUSD(dataToEdit.earlypriceusd || "");
    if (
      !dataToEdit.location_value === "" ||
      !dataToEdit.location_value === undefined ||
      !dataToEdit.location_value === null
    ) {
      setLocationValue(dataToEdit.location_value);
    } else {
      setLocationValue("");
    }

    /*  } */

    if (dataToEdit.pay_within == null) {
      setPayWithin(null);
    } else {
      setPayWithin(convert1(dataToEdit.pay_within));
    }

    setStartDate(
      dataToEdit.startdate === null ? null : convert(dataToEdit.startdate)
    );
    setEndDate(
      dataToEdit.enddate === null ? null : convert(dataToEdit.enddate)
    );
    setDes(dataToEdit.sdesc);
    setObject(dataToEdit.lobj);
    setTarget(dataToEdit.taudi);

    if (dataToEdit.regdate === null) {
    } else {
      setRegStartDate(convert1(dataToEdit.regdate));
    }

    if (dataToEdit.oimgname === null) {
    } else {
      setImage(dataToEdit.oimgname);
    }
    if (dataToEdit.slides === null) {
    } else {
      setSlides(dataToEdit.slides);
    }
    console.log(slides);
    if (dataToEdit.stype === 1) {
      setSessionType("online");
      setLocation(false);
      setSessionTypeCheck(true);
      setRadioOffline(false);
      setOffline(false);
      setRadioHybrid(false);
    } else if (dataToEdit.stype === 2) {
      setSessionType("offline");
      setSessionTypeCheck(false);
      setOffline(true);
      setLocation(true);
      setRadioOffline(true);
      setRadioHybrid(false);
    } else if (dataToEdit.stype === 3) {
      setSessionType("Hybrid");
      setOffline(false);
      setLocation(true);
      setRadioHybrid(true);
      setRadioOffline(false);
      setSessionTypeCheck(false);
    }

    setPayLater(dataToEdit.paylater);
    setEarlyDate(convert1(dataToEdit.earlydate));
    setPayWithin(convert1(dataToEdit.pay_within));
    if (dataToEdit.paid == "true") {
      setPaid("yes");
      setView(true);
      setPaymentTypeCheck(false);
      setTopayment(true);
    } else if (dataToEdit.paid == "false") {
      setPaid("no");
      setView(false);
      setPaymentTypeCheck(true);
    }
    if (dataToEdit.stype === 2 || dataToEdit.stype === 3) {
      setLocation(true);
      setLocationValue(dataToEdit.location_value);
    }
    if (dataToEdit.stype === 1) {
      setSessionType("online");
    }
    if (dataToEdit?.date_list) {
      setDateList(dataToEdit?.date_list);
    }

    return () => {};
  }, []);

  function convert(timestamp) {
    let ts = parseInt(timestamp);
    var date_not_formatted = new Date(ts);

    var formatted_string = date_not_formatted.getFullYear() + "-";

    if (date_not_formatted.getMonth() < 9) {
      formatted_string += "0";
    }
    formatted_string += date_not_formatted.getMonth() + 1;
    formatted_string += "-";

    if (date_not_formatted.getDate() < 10) {
      formatted_string += "0";
    }
    formatted_string += date_not_formatted.getDate();
    formatted_string += "T";
    if (date_not_formatted.getHours() < 10) {
      formatted_string += "0";
    }
    formatted_string += date_not_formatted.getHours();
    formatted_string += ":";
    if (date_not_formatted.getMinutes() < 10) {
      formatted_string += "0";
    }
    formatted_string += date_not_formatted.getMinutes();
    console.log("sss", formatted_string);

    return formatted_string;
  }
  function convert1(timestamp) {
    let ts = parseInt(timestamp);
    var date_not_formatted = new Date(ts);

    var formatted_string = date_not_formatted.getFullYear() + "-";

    if (date_not_formatted.getMonth() < 9) {
      formatted_string += "0";
    }
    formatted_string += date_not_formatted.getMonth() + 1;
    formatted_string += "-";

    if (date_not_formatted.getDate() < 10) {
      formatted_string += "0";
    }
    formatted_string += date_not_formatted.getDate();

    console.log("sss", formatted_string);

    return formatted_string;
  }

  const onChangeHandlerName = (event) => {
    setName(event.target.value);
  };
  const getExtension = (filename) => {
    let parts = filename.split(".");
    return parts[parts.length - 1];
  };

  const isImage = (filename) => {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "jpg":
      case "png":
      case "jpeg":
        return true;
      default:
        return false;
    }
  };

  const isDoc = (filename) => {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "pdf":
        return true;
      case "mp4":
        return true;
      case "mov":
        return true;
      case "jpg":
      case "png":
      case "jpeg":
        return true;
      default:
        return false;
    }
  };

  const onChangeHandlerImage = (event) => {
    setChangeImage(true);
    if (!isImage(event.target.files[0].name)) {
      setImageExtentionError(
        "Wrong file format! Accepted file format: .png/.jpeg/.jpg"
      );
      return;
    }
    setImageExtentionError("");
    setImage({ file: event.target.files[0] });
    refselectedImageFile.current = event.target.files[0];
    console.log("image", image);
  };
  const onChangeHandlerAgenda = (event) => {
    setDocExtentionError("");
    const filesArray = Array.from(event.target.files);

    filesArray.forEach((file) => {
      console.log(file.name);
      if (!isPdf(file.name)) {
        return setDocExtentionError(
          "Wrong file format! Accepted file format: .pdf"
        );
      }
    });
    setAgendaFileChanges(true);
    setAgendaFiles(filesArray);
  };

  const finalSessionImage = () => {
    if (image.file == null) {
      return;
    }
    if (image.file.size > 5242880) {
      setImageError("Please upload a session image less than 5mb");
      swal(
        "Oops!",
        "Image size is too large. File size cannot be greater than 5mb.",
        "error"
      );
      return;
    }
    if (image.file !== undefined) {
      let uniqueStamp = new Date().getTime();
      setImageFileName(dataToEdit.sid + ".png");

      let imageName = dataToEdit.sid + ".png";

      //setSelectedCourseFile((currentState) => (event.target.files[0]));
      var body = {
        type: "sessionimage",
        oid: config.aws_org_id,
        filetype: refselectedImageFile.current.type,
        filename: dataToEdit.sid + ".png",
      };

      console.log("bodyImg", body);
      uploadfile(body, refselectedImageFile.current);
      if (changeSlide === true) {
        finalSessionSlide(imageName);
      } else {
        addSession(imageName, dataToEdit);
      }
    }
  };

  const onChangeHandlerSlides = (event) => {
    setChangeSlide(true);
    if (!isDoc(event.target.files[0].name)) {
      setDocExtentionError(
        "Wrong file format! Accepted file format: .pdf, .mp4, .jpg, .jpeg, .png"
      );
      return;
    }
    setDocExtentionError("");
    setOnChangeSlides(true);
    //setSlides({ file: event.target.files[0] });
    console.log(event.target.files[0]);
    slides.push(event.target.files[0].name);
    refselectedSlideFile.current = event.target.files[0];

    if (
      certificateFile !== undefined &&
      Array.isArray(certificateFile) &&
      certificateFile.length > 0
    ) {
      setCertificateFile((cFile) => [...cFile, event.target.files[0]]);
    } else {
      setTempCfile([event.target.files[0]]);
      setCertificateFile([event.target.files[0]]);
    }
  };

  const finalSessionSlide = (imageName) => {
    for (var a = 0; a < certificateFile.length; a++) {
      let uniqueStamp = new Date().getTime();
      let fileExtension = certificateFile[a].name.substr(
        certificateFile[a].name.lastIndexOf(".") + 1
      );
      let slideName = dataToEdit.sid + uniqueStamp + "." + fileExtension;
      let index = slides.indexOf(certificateFile[a].name);
      if (index != -1) {
        slides.splice(index, 1);
      } else {
      }
      slides.push(slideName);
      var body = {
        type: "sessiondoc",
        filetype: certificateFile[a].type,
        filename: slideName,
        oid: config.aws_org_id,
      };
      uploadfile(body, certificateFile[a]);
    }
    // if (slides.file.size > 5242880) {
    //   setSlidesError("File size cannot be greater than 5mb.");
    //   swal(
    //     "Oops!",
    //     "File size is too large. File size cannot be greater than 5mb.",
    //     "error"
    //   );
    //   return;
    // }
    // let uniqueStamp = new Date().getTime();
    // let slideName = dataToEdit.sid + ".pdf";
    // if (slides.file !== undefined) {
    //   setSessionFileName(dataToEdit.sid + ".pdf");
    //   //setSelectedCourseFile((currentState) => (event.target.files[0]));
    //   var body = {
    //     type: "sessiondoc",
    //     filetype: refselectedSlideFile.current.type,
    //     filename: dataToEdit.sid + ".pdf",
    //     oid: config.aws_org_id,
    //   };

    //   console.log("bodyImg", body);
    //   uploadfile(body, refselectedSlideFile.current);

    //}
    addSession(imageName, slides);
  };

  async function uploadfile(body, file) {
    // setIstLoading(true);
    const bodyParam = {
      body,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const preSignedData = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_PRESIGNED_URL,
        bodyParam
      );
      console.log("file", file);
      axios
        .put(preSignedData, file, {
          headers: {
            "Content-Type": file.type,
            "Cache-Control": "no-cache no-store must-revalidate",
          },
        })
        .then((response) => {
          console.log("file upload success");
        })
        .catch((error) => {
          console.error(error);
        });

      //   setIstLoading(false);
    } catch (error) {
      console.error(error);
    }
  }

  const onChangeHandlerSessionStartDate = (event) => {
    setStartDate(event.target.value);
  };

  const onChangeHandlerSessionEndDate = (event) => {
    setEndDate(event.target.value);
  };

  const onChangeHandlerSpeakerType = (event) => {
    setSpeakerType(event.target.value);
  };

  const onChangeHandlerSpeaker = (event) => {
    setDefaultSpeaker(false);
    console.log("tv", event.target.value);
    console.log(event);
    let i = event.target.value;
    let speakArray = [...speakerArray];
    let obj;

    obj = speakerList[i].sp_id;

    let flag = false;
    for (let j = 0; j < speakArray.length; j++) {
      if (speakArray[j] == obj) {
        flag = true;
      }
    }
    if (flag === false) {
      speakArray.push(obj.toString());
    }
    setSpeakerArray(speakArray);
  };

  console.log("speakerarray", speakerArray);

  const removeSpeaker = (value) => {
    setDefaultSpeaker(true);
    console.log("id", value);

    let updatedArray = [...speakerArray];
    console.log("updated", updatedArray);
    updatedArray = updatedArray.filter(
      (deleting) => deleting !== value.toString()
    );

    setSpeakerArray(updatedArray);
    console.log("updatedspeakerarry", speakerArray);
  };

  const onChangeHandlerSlots = (event) => {
    setSlots(event.target.value);
    setDumslots(event.target.value);
  };

  const onChangeHandleLocation = (event) => {
    setLocationValue(event.target.value);
  };

  const onChangeHandlerRegStartDate = (event) => {
    setRegStartDate(event.target.value);
  };

  const onChangeHandlerWebLink = (event) => {
    setWebLink(event.target.value);
  };

  const onChangeHandlerWebID = (event) => {
    setWebID(event.target.value);
  };
  const onChangeHandlerNote = (event) => {
    setNote(event.target.value);
  };

  const onChangeHandlerPasscode = (event) => {
    setPasscode(event.target.value);
  };

  const onChangeHandlerPaid = (event) => {
    console.log("no", event.target.value);
    setPaid(event.target.value);
    if (topayment === true) {
      setTopayment(false);
    } else {
      setTopayment(true);
    }
  };

  const onChangeHandlerActualPriceINR = (event) => {
    setActualPriceINR(event.target.value);
  };

  const onChangeHandlerActualPriceUSD = (event) => {
    setActualPriceUSD(event.target.value);
  };

  const onChangeHandlerPerDis = (event) => {
    setPerDis(event.target.value);
    let discountedPriceINR =
      ((100 - event.target.value) / 100) * actualPriceINR;
    let discountedPriceUSD =
      ((100 - event.target.value) / 100) * actualPriceUSD;
    setPriceAftINR(discountedPriceINR);
    setPriceAftUSD(discountedPriceUSD);
  };

  const onChangeHandlerEarlyBirdINR = (event) => {
    setEarlyBirdINR(event.target.value);
  };

  const onChangeHandlerEarlyBirdUSD = (event) => {
    setEarlyBirdUSD(event.target.value);
  };

  const onChangeHandlerPayLater = (event) => {
    if (event.target.checked === true) {
      setPayLater(true);
    } else {
      setPayLater(false);
    }
  };
  const onChangeHandlerEarlyDate = (event) => {
    setEarlyDate(event.target.value);
  };

  const onChangeHandlerPayWithin = (event) => {
    setPayWithin(event.target.value);
  };

  const onChangeHandlerDes = (event) => {
    setDes(event.target.value);
  };

  const onChangeHandlerObject = (event) => {
    setObject(event.target.value);
  };

  const onChangeHandlerTarget = (event) => {
    setTarget(event.target.value);
  };

  // function handleSessionType(e) {
  //   // setRadioOffline(false);
  //   // setRadioHybrid(false);
  //   setSessionType(e.target.value);
  //   // setSessionTypeCheck(false);
  // }

  function handleOnlineChange(e) {
    setSessionType(e.target.value);
    setLocation(false);
    setSessionTypeCheck(true);
    setRadioOffline(false);
    setOffline(false);
    setRadioHybrid(false);
  }

  function handleOfflineChange(e) {
    setSessionType(e.target.value);
    setSessionTypeCheck(false);
    setOffline(true);
    setLocation(true);
    setRadioOffline(true);
    setRadioHybrid(false);
  }

  function handleHybridChange(e) {
    setSessionType(e.target.value);
    setOffline(false);
    setLocation(true);
    setRadioHybrid(true);
    setRadioOffline(false);
    setSessionTypeCheck(false);
  }

  function validURL(str) {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  }

  const submitForm = () => {
    let formIsValid = true;
    /*   setNameError("");
    setImageError("");
    setSessionStartTimeError("");
    setSessionEndTimeError("");
    setSpeakerError("");
    setSlotsError("");
    setSlidesError("");
    setLocationError("");
    setSessionTypeError("");
    setWebLinkError("");
    setWebLinkFormatError("");
    setPaidError("");
    setActualPriceError(""); */

    setNameError("");
    setCourseIdError("");
    setImageError("");
    setEarlyError("");
    setEarlyDateError("");
    setSessionStartTimeError("");
    setStartTimeError("");
    setWrongDateError("");
    setTotalSlotsError("");
    setSessionEndTimeError("");
    setSpeakerTypeError("");
    setSpeakerError("");
    setRegStartDateError("");
    setSlotsError("");
    setSlidesError("");
    setLocationError("");
    setSessionTypeError("");
    setWebLinkError("");
    setWebLinkFormatError("");
    setPaidError("");
    setActualPriceError("");
    setPayLaterError("");
    setDocExtend("");
    setPayLaterDateError("");
    /* setImageExtentionError("");
    setDocExtentionError("");
    setDocExtend("");
    setDocExtentionError(""); */

    // const dateField = dateList?.filter((date) => date.date === "");
    if (name === "" || name === null || name.trim() === "") {
      formIsValid = false;
      setNameError("Please enter session name");
      return;
    }
    if (courseID === "" || courseID === null || courseID.trim() === "") {
      formIsValid = false;
      setCourseIdError("Please enter Course ID");
      return;
    }

    // if (dateField?.length > 0) {
    //   formIsValid = false;
    //   setWrongStartDateError("Please enter start date.");
    //   return;
    // }
    // if (image === null || image.file === undefined) {
    //   formIsValid = false;
    //   setImageError("Please upload a session image");
    //   return;
    // }
    // if (startdate === "" || startdate === null) {
    //   formIsValid = false;
    //   setSessionStartTimeError("Please select session start time");
    //   return;
    // }
    // if (new Date().getTime() > new Date(startdate).getTime()) {
    //   formIsValid = false;
    //   setStartTimeError("Start Time should be in the future");
    //   return;
    // }

    // if ((startdate !== null) && (endDate === "" || endDate === null)) {
    //   formIsValid = false;
    //   setSessionEndTimeError("Please select session end time");
    //   return;
    //  }
    // if (
    //   (startdate !== "" || startdate !== null) &&
    //   new Date(endDate).getTime() < new Date(startdate).getTime()
    // ) {
    //   formIsValid = false;
    //   setWrongDateError("End time should be greater then start time");
    //   return;
    // }
    /* if (speakerArray.length === 0) {
      formIsValid = false;
      setSpeakerError("Please select a speaker");
      return;
    } */
    if (slots !== "") {
      if (slots < 0) {
        formIsValid = false;
        setTotalSlotsError("Slots cannot be in negative");
        return;
      }
    }
    if (paid === "yes" && slots === 0) {
      formIsValid = false;
      setTotalSlotsError("Slots cannot be in 0 if the session is paid");
      return;
    }
    if (paid === "yes") {
      if (slots < oldSlots) {
        formIsValid = false;
        setTotalSlotsError("Slots cannot be less than current value");
        return;
      }
    }
    if (location === true) {
      if (locationValue === "" || locationValue.trim("") === "") {
        formIsValid = false;
        setLocationError("Please enter the location");
        return;
      }
    }
    if (regStartDate !== "") {
      if (
        new Date(regStartDate).getTime() >
        new Date(dateList[0].combineStartTime).getTime()
      ) {
        formIsValid = false;
        setRegStartDateError(
          "Registration start date should be before the session"
        );
        return;
      }
    }

    if (offline === false) {
      //   if (webLink === "" || webLink === null) {
      //     formIsValid = false;
      //     setWebLinkError("Please add a webinar link");
      //     return;
      //   }
      // }
      // if (offline === false && webLink !== "") {
      //   if (!validURL(webLink)) {
      //     formIsValid = false;
      //     setWebLinkFormatError("Please enter right URL format");
      //     return;
      //   }
    }

    if (paid === "yes") {
      if (actualPriceINR === "" || actualPriceINR === null) {
        formIsValid = false;
        setActualPriceError("Please add the actual sesssion price");
        return;
      }
    }
    if (payLater === true) {
      if (payWithin === "") {
        formIsValid = false;
        setPayLaterError("Please select a date");
        return;
      }
      if (
        new Date(dateList[0].combineStartTime).getTime() <
        new Date(payWithin).getTime()
      ) {
        formIsValid = false;
        setPayLaterDateError("Date should be before the session");
        return;
      }
    }
    /*   if (paid === "" || paid === null) {
      formIsValid = false;
      setPaidError("Please select the session payment");
      return;
    } */

    if (earlyBirdINR !== "") {
      if (earlyDate === "") {
        formIsValid = false;
        setEarlyError("Please select early bird end date");
        return;
      }
      if (
        new Date(earlyDate).getTime() >
        new Date(dateList[0].combineStartTime).getTime()
      ) {
        formIsValid = false;
        setEarlyDateError("Date should be before the session date");
        return;
      }
    }

    if (formIsValid === true) {
      if (agendaFileChanges) {
        agendaFiles.forEach(async (e) => {
          var body = {
            type: "agendadoc",
            filetype: e.type,
            filename: e.name,
            oid: config.aws_org_id,
            sid: dataToEdit.sid,
          };
          await uploadfile(body, e);
        });
      }
      if (changeimage === true) {
        finalSessionImage();
      } else if (changeSlide === true) {
        finalSessionSlide();
      } else {
        addSession();
      }
    }
  };

  const addSession = async (imgName, sldName) => {
    setSpin(true);
    let bodyObj = {};
    if (changeimage === true) {
      bodyObj.timgname = imgName;
      bodyObj.oimgname = image.file.name.replace("'", "''");
    } else {
      bodyObj.timgname = dataToEdit.timgname;
      bodyObj.oimgname = dataToEdit.oimgname;
    }

    // if (changeSlide === true) {
    //   // bodyObj.slide_oname = slides.file.name.replace("'", "''");
    //   bodyObj.slides = sldName;
    // } else {
    //   // bodyObj.slide_oname = dataToEdit.slide_oname;
    //   // bodyObj.slide_tname = dataToEdit.slide_tname;
    // }
    bodyObj.startdate = new Date(startdate).getTime();
    bodyObj.enddate = new Date(endDate).getTime();
    bodyObj.speakerType = speakerType;
    bodyObj.speaker_id = speakerArray;
    bodyObj.tslots = parseInt(slots);
    bodyObj.oldtslots = parseInt(oldSlots);
    bodyObj.stype = sessionType;
    if (sessionType == "online") {
      bodyObj.stype = 1;
    } else if (sessionType == "offline") {
      bodyObj.stype = 2;
    } else if (sessionType == "Hybrid") {
      bodyObj.stype = 3;
    }
    bodyObj.regdate =
      regStartDate !== null ? new Date(regStartDate).getTime() : null;
    bodyObj.web_id = webID;
    bodyObj.weblink = webLink;
    bodyObj.web_pass = passcode;
    bodyObj.note = note;

    if (paid == "no") {
      bodyObj.paid = false;
    } else {
      bodyObj.paid = true;
    }

    if (paid === "no") {
      bodyObj.perdis = null;
      bodyObj.priceinr = null;
      bodyObj.priceusd = null;
      bodyObj.priceafterinr = null;
      bodyObj.priceafterusd = null;
      bodyObj.earlypriceinr = null;
      bodyObj.earlypriceusd = null;
      bodyObj.earlydate = null;
      bodyObj.paylater = null;
      bodyObj.pay_within = null;
    } else {
      bodyObj.perdis = perDis;

      bodyObj.priceinr = parseInt(actualPriceINR);
      bodyObj.priceusd = parseInt(actualPriceUSD);
      bodyObj.priceafterinr = parseInt(priceAftINR);
      bodyObj.priceafterusd = parseInt(priceAftUSD);
      bodyObj.earlypriceinr = parseInt(earlyBirdINR);
      bodyObj.earlypriceusd = parseInt(earlyBirdUSD);
    }
    bodyObj.earlydate = new Date(earlyDate).getTime();
    bodyObj.paylater = payLater;
    bodyObj.pay_within = new Date(payWithin).getTime();

    bodyObj.location_value = locationValue;
    bodyObj.sdesc = des;
    bodyObj.lobj = object;
    bodyObj.taudi = target;
    bodyObj.sn = name.replaceAll("'", "''");
    bodyObj.sid = dataToEdit.sid;
    bodyObj.msid = courseID.replaceAll("'", "''");
    bodyObj.atype = 1;
    bodyObj.mt_id = 1;
    bodyObj.date_list = JSON.stringify(
      dateList.filter((item) => item.date !== "")
    );
    bodyObj.slides = slides;
    bodyObj.agenda = agendaFiles?.map((a) => a.name);
    bodyObj.schema = Constants.SCHEMA_NAME;

    const bodyParam = {
      body: bodyObj,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    console.log(JSON.stringify(bodyParam.body));
    try {
      await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.EDIT_SESSION,
        bodyParam
      );
      handleEditClose();
      getSessionList();
      swal
        .fire({
          title: "Success",
          text: "Live Session Edited successfully",
          icon: "success",
          dangerMode: false,
        })
        .then((willDelete) => {
          if (willDelete) {
            setSpin(false);
            window.location.reload();
          }
        });
    } catch (error) {
      swal
        .fire({
          title: "Oooops!",
          text: "Soomthing wrong!",
          icon: "warning",
          dangerMode: true,
        })
        .then((willDelete) => {
          if (willDelete) {
            setSpin(false);
          }
        });
      console.log("getUserError", error);
    }
  };

  const Input = styled("input")({
    display: "none",
  });
  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };
  const disablePastDateTime = () => {
    // const today = new Date();
    // const dd = String(today.getDate()).padStart(2, "0");
    // const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    // const yyyy = today.getFullYear();
    // return (
    //   yyyy +
    //   "-" +
    //   mm +
    //   "-" +
    //   dd +
    //   "T" +
    //   today.getHours() +
    //   ":" +
    //   today.getMinutes()
    // );
    var today = new Date().toISOString().slice(0, 16);
    return today;
  };
  const onChangeHandlerSessionDate = (e, idx) => {
    // setStartDate(event.target.value);
    const { value } = e.target;
    let data = [...dateList];

    const combineStartTime = moment(value + " " + data[idx].sTime).format();
    const combineEndTime = moment(value + " " + data[idx].eTime).format();
    data[idx].combineStartTime = combineStartTime;
    data[idx].combineEndTime = combineEndTime;

    data[idx].date = value;

    if (data[idx].date === "") {
      data[idx].sTime = "";
      data[idx].eTime = "";
      data[idx].combineStartTime = undefined;
      data[idx].combineEndTime = undefined;
    }

    setDateList(data);
  };
  const onChangeHandlerSessionStartTime = (e, idx) => {
    const { value } = e.target;
    let data = [...dateList];
    const combineStartTime = moment(data[idx].date + " " + value).format();

    if (new Date().getTime() > new Date(combineStartTime).getTime()) {
      setStartTimeError("Start Time should be in the future");
      data[idx].sTime = "";
      return;
    } else {
      setStartTimeError("");
    }
    data[idx].combineStartTime = combineStartTime;
    data[idx].sTime = value;

    setDateList(data);
  };
  const onChangeHandlerSessionEndTime = (e, idx) => {
    const { value } = e.target;
    let data = [...dateList];
    const combineEndTime = moment(data[idx].date + " " + value).format();

    if (
      new Date(combineEndTime).getTime() <=
      new Date(data[idx].combineStartTime).getTime()
    ) {
      setWrongDateError("End time should be greater then start time");
      data[idx].eTime = "";
      return;
    } else {
      setWrongDateError("");
    }
    data[idx].combineEndTime = combineEndTime;
    data[idx].eTime = value;
    setDateList(data);
  };

  const deleteDateFromList = (id) => {
    setDateList((prevDateList) => {
      return prevDateList.filter((item) => item.id !== id);
    });
  };

  return (
    <div className={Add.maincontainer}>
      <BackDrop sx={{ color: "#fff" }} open={spin} className={classes.backdrop}>
        <CircularProgress color="inherit" />
      </BackDrop>
      <div className={Add.closeholder}>
        <h3 className={Add.heading}>Edit Live Session</h3>
        <Close className={Add.closeicon} onClick={handleEditClose} />
      </div>

      <div className={Add.formfieldsholder}>
        <div className={Add.leftside}>
          <div
            style={{
              color: "#cc0000",
              textAlign: "start",
              fontSize: "10px",
            }}
          >
            {nameError}
          </div>
          <div className={Add.inputblock}>
            <div className={Add.labelholder}>
              <label for="name" className={Add.label} required>
                Name<span style={{ color: "red", marginTop: "-10px" }}>*</span>
              </label>
            </div>
            <div className={Add.inputholder}>
              <input
                type="text"
                id="name"
                className={Add.inputfield}
                onChange={onChangeHandlerName}
                value={name}
              ></input>
            </div>
          </div>
          <div
            style={{
              color: "#cc0000",
              textAlign: "start",
              fontSize: "10px",
            }}
          >
            {courseIdError}
          </div>
          <div className={Add.inputblock}>
            <div className={Add.labelholder}>
              <label for="sessionid" className={Add.label} required>
                Session Id
                <span style={{ color: "red", marginTop: "-10px" }}>*</span>
              </label>
            </div>
            <div className={Add.inputholder}>
              <input
                type="text"
                id="sessionid"
                className={Add.inputfield}
                maxLength="25"
                onChange={(e) => setCourseID(e.target.value)}
                value={courseID}
              ></input>
            </div>
          </div>
          <div
            style={{
              color: "#cc0000",
              textAlign: "start",
              fontSize: "10px",
            }}
          >
            {imageError}
          </div>
          <div
            style={{
              color: "#cc0000",
              textAlign: "start",
              marginLeft: "30%",
              fontSize: "10px",
            }}
          >
            {imageExtentionError}
          </div>
          <div className={Add.inputblock}>
            <div className={Add.labelholder}>
              <label for="image" className={Add.label} required>
                Image<span style={{ color: "red", marginTop: "-10px" }}>*</span>
              </label>
            </div>
            <div className={Add.inputholder}>
              <div className={Add.fileupload}>
                <label htmlFor="session-image" className={Add.labelnot}>
                  <Input
                    accept="image/*"
                    id="session-image"
                    type="file"
                    onChange={(e) => onChangeHandlerImage(e)}
                  />
                  <span className={Add.add}>Upload</span>
                  <h5
                    style={{ fontSize: "10px", color: "grey" }}
                    className={Add.fileEllipsis}
                  >
                    {changeimage === true
                      ? image !== null && image.file !== undefined
                        ? image.file.name
                        : ""
                      : image !== null && image !== undefined
                      ? image
                      : ""}
                  </h5>
                </label>
              </div>
            </div>
          </div>
          <div
            style={{
              color: "#cc0000",
              textAlign: "start",
              fontSize: "10px",
            }}
          >
            {sessionStartTimeError}
          </div>
          <div
            style={{
              color: "#cc0000",
              textAlign: "start",
              fontSize: "10px",
            }}
          >
            {startTimeError}
          </div>
          <div
            style={{
              color: "#cc0000",
              textAlign: "start",
              fontSize: "10px",
            }}
          >
            {wrongStartDateError}
          </div>
          <div className={Add.inputblock}>
            <div className={`${Add.labelholder} ${Add.labelholderBT}`}>
              <label for="sessiondate" className={Add.label} required>
                Session date & time
              </label>
              <button
                disabled={
                  dateList[0].date !== "" &&
                  dateList[0].sTime !== "" &&
                  dateList[0].eTime !== ""
                    ? false
                    : true
                }
                onClick={() => {
                  setDateList((prevDateList) => {
                    let date = new Date(prevDateList[dateList.length - 1].date);
                    date.setDate(date.getDate() + 1);
                    const dd = String(date.getDate()).padStart(2, "0");
                    const mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
                    const yyyy = date.getFullYear();
                    date = yyyy + "-" + mm + "-" + dd;
                    return [
                      ...prevDateList,
                      {
                        id: generate(),
                        date: "",
                        sTime: "",
                        eTime: "",
                        min: date,
                      },
                    ];
                  });
                }}
              >
                Add
              </button>
            </div>

            {dateList?.map((item, index) => {
              return (
                <div className={Add.inputholder} key={item.id}>
                  <input
                    type="date"
                    id={`sessiondate-${generate()}`}
                    name="sessiondate"
                    onChange={(e) => onChangeHandlerSessionDate(e, index)}
                    value={item.date}
                    min={item.min}
                  />
                  <input
                    type="time"
                    id={`sTime-${generate()}`}
                    name="sTime"
                    disabled={item.date !== "" ? false : true}
                    onChange={(e) => onChangeHandlerSessionStartTime(e, index)}
                    value={item.sTime}
                  />
                  <input
                    type="time"
                    id={`eTime-${generate()}`}
                    name="eTime"
                    disabled={
                      item.date !== "" && item.sTime !== "" ? false : true
                    }
                    onChange={(e) => onChangeHandlerSessionEndTime(e, index)}
                    value={item.eTime}
                  />
                  {index !== 0 && (
                    <button onClick={() => deleteDateFromList(item.id)}>
                      Clear
                    </button>
                  )}
                </div>
              );
            })}
          </div>
          {/* <div
            style={{
              color: "#cc0000",
              textAlign: "start",
            
              fontSize: "10px",
            }}
          >
            {sessionEndTimeError}
          </div> */}
          <div
            style={{
              color: "#cc0000",
              textAlign: "start",
              fontSize: "10px",
            }}
          >
            {wrongDateError}
          </div>
          {/* <div className={Add.inputblock}>
            <div className={Add.labelholder}>
              <label for="starttime" className={Add.label} required>
                Session End date & time
              </label>
            </div>
            <div className={Add.inputholder}>
              <input
                type="datetime-local"
                id="starttime"
                className={Add.inputfield}
                onChange={onChangeHandlerSessionEndDate}
                value={endDate}
                min={disablePastDateTime()}
              ></input>
            </div>
          </div> */}
          <div
            style={{
              color: "#cc0000",
              textAlign: "start",
              fontSize: "10px",
            }}
          >
            {speakerTypeError}
          </div>
          <div className={Add.inputblock}>
            <div className={Add.labelholder}>
              <label for="speakertype" className={Add.label} required>
                Speaker Type
                <span style={{ color: "red", marginTop: "-10px" }}>*</span>
              </label>
            </div>
            <div className={Add.inputholder}>
              <select
                id="speakertype"
                className={Add.inputfield}
                onChange={onChangeHandlerSpeakerType}
                value={speakerType}
              >
                <option disabled selected={defaultSpeaker}>
                  Select Speaker Type
                </option>
                <option value="Speaker">Speaker</option>
                <option value="Approved Instructor">Approved Instructor</option>
              </select>
            </div>
          </div>
          <div
            style={{
              color: "#cc0000",
              textAlign: "start",
              fontSize: "10px",
            }}
          >
            {speakerError}
          </div>
          <div className={Add.inputblock}>
            <div className={Add.labelholder}>
              <label for="speaker" className={Add.label} required>
                Speaker
                <span style={{ color: "red", marginTop: "-10px" }}>*</span>
              </label>
            </div>
            <div className={Add.inputholder}>
              <select
                id="speaker"
                className={Add.inputfield}
                onChange={onChangeHandlerSpeaker}
                //value={speaker}
              >
                <option disabled selected={defaultSpeaker}>
                  Select Speaker
                </option>
                {speakerList.map((speaker, i) => {
                  return <option value={i}>{speaker.sp_name}</option>;
                })}
              </select>
            </div>
          </div>
          <div className={Add.speakerlists}>
            {speakerList.map((value, id) => {
              if (speakerArray.includes(value.sp_id.toString())) {
                return (
                  <div className={Add.speakertag} key={id}>
                    <h4 className={Add.speakername}>{value.sp_name}</h4>
                    {speakerList.length === 1 ? (
                      <></>
                    ) : (
                      <Close
                        className={Add.cancelicon}
                        onClick={() => {
                          removeSpeaker(value.sp_id);
                        }}
                      />
                    )}
                  </div>
                );
              }
            })}
          </div>

          <div
            style={{
              color: "#cc0000",
              textAlign: "start",
              fontSize: "10px",
            }}
          >
            {slidesError}
          </div>
          <div
            style={{
              color: "#cc0000",
              textAlign: "start",
              marginLeft: "40%",
              fontSize: "10px",
            }}
          >
            {docExtentionError}
          </div>
          <div className={Add.inputblock}>
            <div className={Add.labelholder}>
              <label for="summary" className={Add.label} required>
                Summary slides
              </label>
            </div>
            <div className={Add.holder}>
              <label htmlFor="sessionSlides">
                <Input
                  accept=".pdf,video/*,image/*"
                  id="sessionSlides"
                  type="file"
                  onChange={(e) => onChangeHandlerSlides(e)}
                />
                <span className={Add.add}>Upload</span>
                {/* <h5 className={Add.fileEllipsis}>
                 
                  {slides.length > 0 && slides.map((slide, i) => {
                    <h4 className={Add.speakername}>{slide}</h4>
                  })}
                </h5> */}
                <div className={Add.speakerlists} style={{ marginTop: "15px" }}>
                  {Array.isArray(slides) &&
                    slides.length > 0 &&
                    slides.map((cfile) => {
                      return (
                        <div
                          // style={{
                          //   display: "flex",
                          //   justifyContent: "space-between",
                          //   gap: "20px",
                          //   border: "none",
                          //   margin: "10px",
                          //   borderRadius: "5px",
                          //   boxShadow: "0px 0px 5px 0px lightgrey ",
                          // }}
                          className={Add.speakertag}
                        >
                          <h3 className={Add.speakername}>{cfile}</h3>
                        </div>
                      );
                    })}
                </div>
              </label>
            </div>
          </div>

          <div className={Add.inputblock}>
            <div className={Add.labelholder}>
              <label for="agenda" className={Add.label} required>
                Agenda
              </label>
            </div>
            <div className={Add.holder}>
              <label htmlFor="agenda">
                <Input
                  accept=".pdf"
                  id="agenda"
                  type="file"
                  onChange={(e) => onChangeHandlerAgenda(e)}
                />
                <span className={Add.add}>Upload</span>
              </label>
            </div>
            <h5 className={Add.fileEllipsis}>
              {agendaFiles?.map((agenda, key) => (
                <div key={key}>
                  <h3 className={Add.speakername}>{agenda.name}</h3>
                </div>
              ))}
            </h5>
          </div>
          <div
            style={{
              color: "#cc0000",
              textAlign: "start",
              fontSize: "10px",
            }}
          >
            {sessionTypeError}
          </div>
          <div className={Add.inputblock}>
            <div className={Add.labelholder}>
              <label for="coursetype" className={Add.label} required>
                Session Type
                <span style={{ color: "red", marginTop: "-10px" }}>*</span>
              </label>
            </div>
            <div className={Add.checkholder}>
              <div>
                <input
                  type="radio"
                  value="online"
                  name="sessiontype"
                  className={Add.radioinput}
                  checked={sessionTypeCheck}
                  onChange={(event) => handleOnlineChange(event)}
                ></input>
                <span className={Add.span}>Live Web</span>
              </div>
              <div>
                <input
                  type="radio"
                  value="offline"
                  name="sessiontype"
                  className={Add.radioinput}
                  checked={radioOffilne}
                  onChange={(event) => handleOfflineChange(event)}
                ></input>
                <span className={Add.span}>Classroom</span>
              </div>
              <div>
                <input
                  type="radio"
                  value="Hybrid"
                  name="sessiontype"
                  checked={radioHybrid}
                  className={Add.radioinput}
                  onChange={(event) => handleHybridChange(event)}
                ></input>
                <span className={Add.span}>Hybrid</span>
              </div>
            </div>
          </div>
          {location === true ? (
            <>
              <div
                style={{
                  color: "#cc0000",
                  textAlign: "start",
                  fontSize: "10px",
                }}
              >
                {locationError}
              </div>
              <div className={Add.inputblock}>
                <div className={Add.labelholder}>
                  <label for="location" className={Add.label} required>
                    Location
                    <span style={{ color: "red", marginTop: "-10px" }}>*</span>
                  </label>
                </div>
                <div className={Add.inputholder}>
                  <textarea
                    rows={4}
                    id="location"
                    className={Add.textfield}
                    onChange={onChangeHandleLocation}
                    value={locationValue}
                  ></textarea>
                </div>
              </div>
            </>
          ) : null}
          <div
            style={{
              color: "#cc0000",
              textAlign: "start",
              marginLeft: "35%",
              fontSize: "10px",
            }}
          >
            {regStartDateError}
          </div>
          <div className={Add.inputblock}>
            <div className={Add.labelholder}>
              <label for="registrationdate" className={Add.label} required>
                Registration start date
              </label>
            </div>
            <div className={Add.inputholder}>
              <input
                type="date"
                id="registrationdate"
                className={Add.inputfield}
                onChange={onChangeHandlerRegStartDate}
                value={regStartDate}
                min={disablePastDate()}
                disabled={
                  dateList[0].date !== "" &&
                    dateList[0].sTime !== "" &&
                    dateList[0].eTime !== ""
                    ? false
                    : true
                }
              ></input>
            </div>
          </div>
          {offline === false ? (
            <>
              <div
                style={{
                  color: "#cc0000",
                  textAlign: "start",
                  fontSize: "10px",
                }}
              >
                {webLinkError}
              </div>
              <div
                style={{
                  color: "#cc0000",
                  textAlign: "start",
                  fontSize: "10px",
                }}
              >
                {webLinkFormatError}
              </div>
              <div className={Add.inputblock}>
                <div className={Add.labelholder}>
                  <label for="webinarlink" className={Add.label}>
                    Webinar Link
                    {/* <span style={{ color: "red", marginTop: "-10px" }}>*</span> */}
                  </label>
                </div>
                <div className={Add.inputholder}>
                  <input
                    type="text"
                    id="webinarlink"
                    className={Add.inputfield}
                    onChange={onChangeHandlerWebLink}
                    value={webLink}
                  ></input>
                </div>
              </div>
              <div className={Add.inputblock}>
                <div className={Add.labelholder}>
                  <label for="webinarid" className={Add.label}>
                    Webinar ID
                  </label>
                </div>
                <div className={Add.inputholder}>
                  <input
                    type="text"
                    id="webinarid"
                    className={Add.inputfield}
                    onChange={onChangeHandlerWebID}
                    value={webID}
                  ></input>
                </div>
              </div>
              <div className={Add.inputblock}>
                <div className={Add.labelholder}>
                  <label for="passcode" className={Add.label}>
                    Passcode
                  </label>
                </div>
                <div className={Add.inputholder}>
                  <input
                    type="text"
                    id="passcode"
                    className={Add.inputfield}
                    onChange={onChangeHandlerPasscode}
                    value={passcode}
                  ></input>
                </div>
              </div>
              <div className={Add.inputblock}>
                <div className={Add.labelholder}>
                  <label for="note" className={Add.label}>
                    Note
                  </label>
                </div>
                <div className={Add.inputholder}>
                  <input
                    type="text"
                    id="note"
                    className={Add.inputfield}
                    onChange={onChangeHandlerNote}
                    value={note}
                  ></input>
                </div>
              </div>
            </>
          ) : null}
        </div>
        <div className={Add.rightside}>
          <div
            style={{
              color: "#cc0000",
              textAlign: "start",
              fontSize: "10px",
            }}
          >
            {paidError}
          </div>
          <div className={Add.inputblock}>
            <div className={Add.labelholder}>
              <label for="paidcourse" className={Add.label} required>
                Paid Session
                <span style={{ color: "red", marginTop: "-10px" }}>*</span>
              </label>
            </div>
            <div className={Add.checkholder}>
              <div>
                <input
                  type="radio"
                  value="yes"
                  name="paylater"
                  checked={topayment}
                  className={Add.radioinput}
                  onChange={(e) => {
                    onChangeHandlerPaid(e);
                    setView(true);
                    setPaymentTypeCheck(false);
                    setSlotsFree(false);
                    setSlots(dumslots);
                  }}
                ></input>
                <span className={Add.span}>Yes</span>
              </div>
              <div>
                <input
                  type="radio"
                  value="no"
                  name="paylater"
                  className={Add.radioinput}
                  checked={paymentTypeCheck}
                  onChange={(e) => {
                    onChangeHandlerPaid(e);
                    setView(false);
                    setPaymentTypeCheck(true);
                    setSlotsFree(true);
                    setSlots(0);
                  }}
                ></input>
                <span className={Add.span}>No</span>
              </div>
            </div>
          </div>
          {view === true ? (
            <>
              <div
                style={{
                  color: "#cc0000",
                  textAlign: "start",
                  fontSize: "10px",
                }}
              >
                {totalSlotsError}
              </div>
              <div className={Add.inputblock}>
                <div className={Add.labelholder}>
                  <label for="totalslots" className={Add.label} required>
                    Total slots
                  </label>
                </div>
                <div className={Add.inputholder}>
                  <input
                    type="number"
                    id="totalslots"
                    className={Add.inputfield}
                    onChange={onChangeHandlerSlots}
                    disabled={slotsFree}
                    value={slots}
                  ></input>
                </div>
              </div>
              <div
                style={{
                  color: "#cc0000",
                  textAlign: "start",
                  fontSize: "10px",
                }}
              >
                {actualPriceError}
              </div>
              <div className={Add.inputblock}>
                <div className={Add.labelholder}>
                  <label for="price" className={Add.label}>
                    Actual Price in INR
                    <span style={{ color: "red", marginTop: "-10px" }}>*</span>
                  </label>
                </div>
                <div className={Add.inputholder}>
                  <input
                    type="number"
                    id="price"
                    className={Add.inputfield}
                    onChange={onChangeHandlerActualPriceINR}
                    value={actualPriceINR}
                  ></input>
                </div>
              </div>
              <div className={Add.inputblock}>
                <div className={Add.labelholder}>
                  <label for="price" className={Add.label}>
                    Actual Price in USD
                  </label>
                </div>
                <div className={Add.inputholder}>
                  <input
                    type="number"
                    id="price"
                    className={Add.inputfield}
                    onChange={onChangeHandlerActualPriceUSD}
                    value={actualPriceUSD}
                  ></input>
                </div>
              </div>
              <div className={Add.inputblock}>
                <div className={Add.labelholder}>
                  <label for="discount" className={Add.label}>
                    Percentage discount
                  </label>
                </div>
                <div className={Add.inputholder}>
                  <input
                    type="number"
                    id="discount"
                    className={Add.inputfield}
                    onChange={onChangeHandlerPerDis}
                    value={perDis}
                  ></input>
                </div>
              </div>
              <div className={Add.inputblock}>
                <div className={Add.labelholder}>
                  <label for="afterdiscount" className={Add.label}>
                    Price after discount in INR
                  </label>
                </div>
                <div className={Add.inputholder}>
                  <input
                    type="number"
                    id="afterdiscount"
                    className={Add.inputfield}
                    // onChange={onChangeHandlerPriceAftINR}
                    value={priceAftINR}
                  ></input>
                </div>
              </div>
              <div className={Add.inputblock}>
                <div className={Add.labelholder}>
                  <label for="afterdiscount" className={Add.label}>
                    Price after discount in USD
                  </label>
                </div>
                <div className={Add.inputholder}>
                  <input
                    type="number"
                    id="afterdiscount"
                    className={Add.inputfield}
                    //  onChange={onChangeHandlerPriceAftUSD}
                    value={priceAftUSD}
                  ></input>
                </div>
              </div>
              <div className={Add.inputblock}>
                <div className={Add.labelholder}>
                  <label for="birdprice" className={Add.label}>
                    Early bird price in INR
                  </label>
                </div>
                <div className={Add.inputholder}>
                  <input
                    type="number"
                    id="birdprice"
                    className={Add.inputfield}
                    onChange={onChangeHandlerEarlyBirdINR}
                    value={earlyBirdINR || ""}
                  ></input>
                </div>
              </div>
              <div className={Add.inputblock}>
                <div className={Add.labelholder}>
                  <label for="birdprice" className={Add.label}>
                    Early bird price in USD
                  </label>
                </div>
                <div className={Add.inputholder}>
                  <input
                    type="number"
                    id="birdprice"
                    className={Add.inputfield}
                    onChange={onChangeHandlerEarlyBirdUSD}
                    value={earlyBirdUSD || ""}
                  ></input>
                </div>
              </div>
              <div
                style={{
                  color: "#cc0000",
                  textAlign: "start",
                  fontSize: "10px",
                }}
              >
                {earlyError}
              </div>
              <div
                style={{
                  color: "#cc0000",
                  textAlign: "start",
                  fontSize: "10px",
                }}
              >
                {earlyDateError}
              </div>
              <div
                style={{
                  color: "#cc0000",
                  textAlign: "start",
                  fontSize: "10px",
                }}
              >
                {payLaterError}
              </div>
              <div
                style={{
                  color: "#cc0000",
                  textAlign: "start",
                  fontSize: "10px",
                }}
              >
                {payLaterDateError}
              </div>
              <div className={Add.inputblock}>
                <div className={Add.labelholder}>
                  <label for="enddate" className={Add.label}>
                    Early bird price end date
                  </label>
                </div>
                <div className={Add.inputholder}>
                  <input
                    type="date"
                    id="enddate"
                    className={Add.inputfield}
                    onChange={onChangeHandlerEarlyDate}
                    value={earlyDate}
                  ></input>
                </div>
              </div>
              <div className={Add.inputblock}>
                <div className={Add.labelholder}>
                  <label for="paylater" className={Add.label}>
                    Enable pay later
                  </label>
                </div>
                <div className={Add.paywithindate}>
                  <input
                    type="checkbox"
                    id="paylate"
                    onChange={(e) => onChangeHandlerPayLater(e)}
                    value={payLater}
                    checked={payLater}
                  ></input>
                  {/*   <div
                    style={{
                      color: "#cc0000",
                      textAlign: "start",
                    
                      fontSize: "10px",
                    }}
                  >
                    {payLaterError}
                  </div>
                  <div
                    style={{
                      color: "#cc0000",
                      textAlign: "start",
                    
                      fontSize: "10px",
                    }}
                  >
                    {payLaterDateError}
                  </div> */}
                  <div className={Add.paywithholder}>
                    <label for="paylater" className={Add.label}>
                      Pay within date
                    </label>
                    <input
                      type="date"
                      id="paylater"
                      className={Add.smallfield}
                      onChange={onChangeHandlerPayWithin}
                      value={payWithin}
                    ></input>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
      <div className={Add.bottomfields}>
        <label for="description" required className={Add.label}>
          Description
        </label>
        <textarea
          id="description"
          className={Add.textarea}
          rows={3}
          onChange={onChangeHandlerDes}
          value={des}
        />
        <label for="learning" required className={Add.label}>
          Learning objectives
        </label>
        <textarea
          id="learning"
          className={Add.textarea}
          rows={3}
          onChange={onChangeHandlerObject}
          value={object}
        />
        <label for="audience" required className={Add.label}>
          Target audience
        </label>
        <textarea
          id="audience"
          className={Add.textarea}
          rows={3}
          onChange={onChangeHandlerTarget}
          value={target}
        />
      </div>

      <div className={Add.buttonholder}>
        <button className={Add.cancel} onClick={handleEditClose}>
          Cancel
        </button>
        <button className={Add.add} type="submit" onClick={submitForm}>
          Edit
        </button>
      </div>
    </div>
  );
};

export default EditSession;
