// Dependencies imports
import React, { useState } from "react";
import swal from "sweetalert";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { API } from "aws-amplify";
import BackDrop from "@material-ui/core/Backdrop/Backdrop";

// Local imports
import config from "../../../config/aws-exports";
import { Constants } from "../../../config/Constants";

// Style imports
import Del from "./DeleteSession.module.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
}));

const RegistrationStart = ({
  handleRegistrationClose,
  sessionData,
  getSessionList,
}) => {
  const [spin, setSpin] = useState(false);
  const classes = useStyles();

  const onsubmit = async () => {
    setSpin(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        sid: sessionData.sid,
        start_registration:
          sessionData?.reg_start != null ? !sessionData?.reg_start : true,
        schema: Constants.SCHEMA_NAME,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      await API.post(
        config.aws_cloud_logic_custom_name,
        "/startSessionRegistration",
        bodyParam
      );
      handleRegistrationClose();
      getSessionList();
      swal({
        title: "Success",
        text: `Registration ${
          sessionData?.reg_start == null || sessionData?.reg_start === false
            ? "started"
            : "Ended"
        } successfully`,
        icon: "success",
        dangerMode: false,
        closeOnClickOutside: false,
      }).then((willDelete) => {
        if (willDelete) {
          setSpin(false);
        }
      });
    } catch (error) {
      swal({
        title: "Oooops!",
        text: "Soomthing wrong!",
        icon: "warning",
        dangerMode: true,
        closeOnClickOutside: false,
      }).then((willDelete) => {
        if (willDelete) {
          setSpin(false);
        }
      });
      console.log("getUserError", error);
    }
  };

  return (
    <div className={Del.mainpopup}>
      <BackDrop sx={{ color: "#fff" }} open={spin} className={classes.backdrop}>
        <CircularProgress color="inherit" />
      </BackDrop>
      <h3 className={Del.heading}>
        Are you sure you want to{" "}
        {sessionData?.reg_start == null || sessionData?.reg_start === false
          ? "start"
          : "end"}{" "}
        Registration for this session?
      </h3>
      <div className={Del.buttonholder}>
        <button className={Del.buttonyes} onClick={onsubmit}>
          Yes
        </button>
        <button className={Del.buttonno} onClick={handleRegistrationClose}>
          No
        </button>
      </div>
    </div>
  );
};

export default RegistrationStart;
