// Dependencies import
import React, { useState, useRef } from "react";
import { styled } from "@mui/system";
import swal from "sweetalert2";
import swal1 from "@sweetalert/with-react";

import { API } from "aws-amplify";
import config from "../../../config/aws-exports";
import axios from "axios";
import { Button } from "@mui/material";
import BackDrop from "@material-ui/core/Backdrop/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../../redux/auth/authSlice";

// Local imports
import { ReactComponent as Close } from "../../../assets/icons/close_black_24dp.svg";
import { Constants } from "../../../config/Constants";

// Style imports
import Add from "./AddProgram.module.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
}));

const AddProgram = ({ handleAddLiveClose, getPrograms }) => {
  const [name, setName] = useState("");
  const [image, setImage] = useState(null);
  let userDetails = useSelector(authData);
  // const [startdate, setStartDate] = useState("");
  // const [endDate, setEndDate] = useState("");
  // const [imageExtend, setImageExtend] = useState("");
  const [imageExtentionError, setImageExtentionError] = useState("");
  // const [docExtend, setDocExtend] = useState("");
  // const [docExtentionError, setDocExtentionError] = useState("");
  // const [sessionTypeCheck, setSessionTypeCheck] = useState(true);
  // const [paymentTypeCheck, setPaymentTypeCheck] = useState(true);
  // const [startTimeError, setStartTimeError] = useState("");
  // const [wrongDateError, setWrongDateError] = useState("");
  // const [slots, setSlots] = useState(0);
  // const [slides, setSlides] = useState("");
  // const [onChangeSlide, setOnChangeSlides] = useState(false);
  // const [sessionType, setSessionType] = useState("online");
  // const [regStartDate, setRegStartDate] = useState("");
  // const [webLink, setWebLink] = useState("");
  // const [webID, setWebID] = useState("");
  // const [passcode, setPasscode] = useState("");
  // const [regStartDateError, setRegStartDateError] = useState("");
  // const [paid, setPaid] = useState("no");
  // const [actualPriceINR, setActualPriceINR] = useState("");
  // const [actualPriceUSD, setActualPriceUSD] = useState("");
  // const [perDis, setPerDis] = useState("");
  // const [priceAftINR, setPriceAftINR] = useState(0);
  // const [priceAftUSD, setPriceAftUSD] = useState(0);
  // const [earlyBirdINR, setEarlyBirdINR] = useState("");
  // const [earlyBirdUSD, setEarlyBirdUSD] = useState("");
  // const [earlyDate, setEarlyDate] = useState("");
  // const [payLater, setPayLater] = useState(false);
  // const [payWithin, setPayWithin] = useState("");
  // const [des, setDes] = useState("");
  // const [object, setObject] = useState("");
  // const [offline, setOffline] = useState(false);
  // const [locationValue, setLocationValue] = useState("");
  // const [location, setLocation] = useState(false);
  // const [target, setTarget] = useState("");
  const [nameError, setNameError] = useState("");
  const [imageError, setImageError] = useState("");
  // const [totalSlotsError, setTotalSlotsError] = useState("");
  // const [sessionStartTimeError, setSessionStartTimeError] = useState("");
  // const [sessionEndTimeError, setSessionEndTimeError] = useState("");
  // const [payLaterError, setPayLaterError] = useState("");
  // const [speakerError, setSpeakerError] = useState("");
  // const [earlyError, setEarlyError] = useState("");
  // const [earlyDateError, setEarlyDateError] = useState("");
  // const [slotsError, setSlotsError] = useState("");
  // const [slidesError, setSlidesError] = useState("");
  // const [locationError, setLocationError] = useState("");
  // const [sessionTypeError, setSessionTypeError] = useState("");
  // const [webLinkError, setWebLinkError] = useState("");
  // const [webLinkFormatError, setWebLinkFormatError] = useState("");
  // const [paidError, setPaidError] = useState("");
  // const [actualPriceError, setActualPriceError] = useState("");
  // const [payLaterDateError, setPayLaterDateError] = useState("");
  // const [view, setView] = useState(false);
  const [imageFileName, setImageFileName] = useState(null);
  // const [sessionFileName, setSessionFileName] = useState(null);
  const [spin, setSpin] = useState(false);
  // const [speakerArray, setSpeakerArray] = useState([]);

  const refselectedImageFile = useRef();
  // const refselectedSlideFile = useRef();
  const classes = useStyles();

  const onChangeHandlerName = (event) => {
    setName(event.target.value);
    console.log("categorydata",userDetails.categorydata.ct_id);
  };

  const getExtension = (filename) => {
    let parts = filename.split(".");
    return parts[parts.length - 1];
  };

  const isImage = (filename) => {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "jpg":
      case "png":
      case "jpeg":
        return true;
      default:
        return false;
    }
  };

  // const isDoc = (filename) => {
  //   var ext = getExtension(filename);
  //   switch (ext.toLowerCase()) {
  //     case "pdf":
  //       return true;
  //     default:
  //       return false;
  //   }
  // };

  const onChangeHandlerImage = (event) => {
    if (!isImage(event.target.files[0].name)) {
      setImageExtentionError(
        "Wrong file format! Accepted file format: .png/.jpeg/.jpg"
      );
      return;
    }
    setImageExtentionError("");
    setImage({ file: event.target.files[0] });
    refselectedImageFile.current = event.target.files[0];
  };

  // const onChangeHandlerSlides = (event) => {
  //   if (!isDoc(event.target.files[0].name)) {
  //     setDocExtentionError("Wrong file format! Accepted file format: .pdf");
  //     return;
  //   }
  //   setDocExtentionError("");
  //   setOnChangeSlides(true);
  //   setSlides({ file: event.target.files[0] });
  //   refselectedSlideFile.current = event.target.files[0];
  //   console.log("slides", slides.file);
  // };

  const finalSessionImage = () => {
    if (image.file == null) {
      return;
    }
    if (image.file.size > 5242880) {
      swal1(
        "Oops!",
        "Image size is too large. File size cannot be greater than 5mb.",
        "error"
      );
      return;
    }
    if (image.file !== undefined) {
      let uniqueStamp = new Date().getTime();
      setImageFileName(uniqueStamp + ".png");

      let imageName = uniqueStamp + ".png";
      //setSelectedCourseFile((currentState) => (event.target.files[0]));
      var body = {
        type: "program",
        oid: config.aws_org_id,
        filetype: "image/png",
        filename: uniqueStamp + '.png' ,
      };

      console.log("bodyImg", body);
      uploadfile(body, refselectedImageFile.current);
      addSession(imageName);
    }
  };

  // const finalSessionSlide = (imageName) => {
  //   if (slides.file == null) {
  //     return;
  //   }
  //   if (slides.file.size > 5242880) {
  //     swal1(
  //       "Oops!",
  //       "File size is too large. File size cannot be greater than 5mb.",
  //       "error"
  //     );
  //     return;
  //   }
  //   let uniqueStamp = new Date().getTime();
  //   let slideName = uniqueStamp + ".pdf";
  //   if (slides.file !== undefined) {
  //     setSessionFileName(uniqueStamp + ".pdf");
  //     //setSelectedCourseFile((currentState) => (event.target.files[0]));
  //     var body = {
  //       type: "sessiondoc",
  //       filetype: refselectedSlideFile.current.type,
  //       filename: uniqueStamp + ".pdf",
  //       oid: config.aws_org_id,
  //     };

  //     console.log("bodyImg", body);
  //     uploadfile(body, refselectedSlideFile.current);
  //   }
  //   addSession(imageName, slideName);
  // };

  async function uploadfile(body, file) {
    // setIstLoading(true);
    const bodyParam = {
      body,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const preSignedData = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_PRESIGNED_URL,
        bodyParam
      );
      console.log("file", file);
      axios
        .put(preSignedData, file, {
          headers: {
            "Content-Type": "image/png",
            "Cache-Control": "no-cache no-store must-revalidate",
          },
        })
        .then((response) => {
          console.log("file upload success");
        })
        .catch((error) => {
          console.error(error);
        });
      console.log("img", imageFileName);
      //   setIstLoading(false);
    } catch (error) {
      console.error(error);
    }
  }

  // const onChangeHandlerSessionStartDate = (event) => {
  //   setStartDate(event.target.value);
  // };

  // const onChangeHandlerSessionEndDate = (event) => {
  //   setEndDate(event.target.value);
  // };

  // const onChangeHandlerSpeaker = (event) => {
  //   console.log("tv", event.target.value);
  //   console.log(event);
  //   let i = event.target.value;
  //   let speakArray = [...speakerArray];
  //   let obj;

  //   obj = speakerList[i].sp_id;
  //   let flag = false;
  //   for (let j = 0; j < speakArray.length; j++) {
  //     if (speakArray[j] == obj) {
  //       flag = true;
  //     }
  //   }
  //   if (flag === false) {
  //     speakArray.push(obj.toString());
  //   }
  //   setSpeakerArray(speakArray);
  // };

  // console.log("speakerarray", speakerArray);

  // const removeSpeaker = (value) => {
  //   let updatedArray = [...speakerArray];
  //   updatedArray = updatedArray.filter(
  //     (deleting) => deleting !== value.toString()
  //   );

  //   setSpeakerArray(updatedArray);
  //   console.log("updatedspeakerarry", speakerArray);
  // };

  // const onChangeHandlerSlots = (event) => {
  //   setSlots(event.target.value);
  // };

  // const onChangeHandleLocation = (event) => {
  //   setLocationValue(event.target.value);
  // };

  // const onChangeHandlerRegStartDate = (event) => {
  //   setRegStartDate(event.target.value);
  // };

  // const onChangeHandlerWebLink = (event) => {
  //   setWebLink(event.target.value);
  // };

  // const onChangeHandlerWebID = (event) => {
  //   setWebID(event.target.value);
  // };

  // const onChangeHandlerPasscode = (event) => {
  //   setPasscode(event.target.value);
  // };

  // const onChangeHandlerPaid = (event) => {
  //   setPaid(event.target.value);
  // };

  // const onChangeHandlerActualPriceINR = (event) => {
  //   setActualPriceINR(event.target.value);
  // };

  // const onChangeHandlerActualPriceUSD = (event) => {
  //   setActualPriceUSD(event.target.value);
  // };

  // const onChangeHandlerPerDis = (event) => {
  //   setPerDis(event.target.value);
  //   let discountedPriceINR =
  //     ((100 - event.target.value) / 100) * actualPriceINR;
  //   let discountedPriceUSD =
  //     ((100 - event.target.value) / 100) * actualPriceUSD;
  //   setPriceAftINR(discountedPriceINR);
  //   setPriceAftUSD(discountedPriceUSD);
  // };

  // const onChangeHandlerEarlyBirdINR = (event) => {
  //   setEarlyBirdINR(event.target.value);
  // };

  // const onChangeHandlerEarlyBirdUSD = (event) => {
  //   setEarlyBirdUSD(event.target.value);
  // };

  // const onChangeHandlerEarlyDate = (event) => {
  //   setEarlyDate(event.target.value);
  // };

  // const onChangeHandlerPayLater = (event) => {
  //   if (event.target.checked === true) {
  //     setPayLater(true);
  //   } else {
  //     setPayLater(false);
  //   }
  // };

  // const onChangeHandlerPayWithin = (event) => {
  //   setPayWithin(event.target.value);
  // };

  // const onChangeHandlerDes = (event) => {
  //   setDes(event.target.value);
  // };

  // const onChangeHandlerObject = (event) => {
  //   setObject(event.target.value);
  // };

  // const onChangeHandlerTarget = (event) => {
  //   setTarget(event.target.value);
  // };

  // function handleSessionType(e) {
  //   setSessionType(e.target.value);
  //   setSessionTypeCheck(false);
  // }

  // function validURL(str) {
  //   var pattern = new RegExp(
  //     "^(https?:\\/\\/)?" + // protocol
  //       "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
  //       "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
  //       "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
  //       "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
  //       "(\\#[-a-z\\d_]*)?$",
  //     "i"
  //   ); // fragment locator
  //   return !!pattern.test(str);
  // }

  const submitForm = () => {
    let formIsValid = true;
    setNameError("");
    setImageError("");
    // setEarlyError("");
    // setEarlyDateError("");
    // setSessionStartTimeError("");
    // setStartTimeError("");
    // setWrongDateError("");
    // setTotalSlotsError("");
    // setSessionEndTimeError("");
    // setSpeakerError("");
    // setRegStartDateError("");
    // setSlotsError("");
    // setSlidesError("");
    // setLocationError("");
    // setSessionTypeError("");
    // setWebLinkError("");
    // setWebLinkFormatError("");
    // setPaidError("");
    // setActualPriceError("");
    // setPayLaterError("");
    // setDocExtend("");
    // setDocExtentionError("");
    // setPayLaterDateError("");
    // setImageExtentionError("");
    // setDocExtentionError("");

    if (name === "" || name === null || name.trim() === "") {
      formIsValid = false;
      setNameError("Please enter session name");
      return;
    }
    if (image === null || image.file === undefined) {
      formIsValid = false;
      setImageError("Please upload a session image");
      return;
    }
    // if (startdate === "" || startdate === null) {
    //   formIsValid = false;
    //   setSessionStartTimeError("Please select session start time");
    //   return;
    // }
    // if (new Date().getTime() > new Date(startdate).getTime()) {
    //   formIsValid = false;
    //   setStartTimeError("Start Time should be in the future");
    //   return;
    // }
    // if (endDate === "" || endDate === null) {
    //   formIsValid = false;
    //   setSessionEndTimeError("Please select session end time");
    //   return;
    // }
    // if (new Date(endDate).getTime() < new Date(startdate).getTime()) {
    //   formIsValid = false;
    //   setWrongDateError("End time should be greater then start time");
    //   return;
    // }
    // if (speakerArray.length === 0) {
    //   formIsValid = false;
    //   setSpeakerError("Please select a speaker");
    //   return;
    // }
    // if (slots !== "") {
    //   if (slots < 0) {
    //     formIsValid = false;
    //     setTotalSlotsError("Slots cannot be in negative");
    //     return;
    //   }
    // }
    // if (location === true) {
    //   if (locationValue.trim("") === "" || locationValue === "") {
    //     console.log("location error");
    //     formIsValid = false;
    //     setLocationError("Please enter the location");
    //     return;
    //   }
    // }
    // if (regStartDate !== "") {
    //   if (new Date(regStartDate).getTime() > new Date(startdate).getTime()) {
    //     console.log("reg start date error");
    //     formIsValid = false;
    //     setRegStartDateError(
    //       "Registration start date should be before the session"
    //     );
    //     return;
    //   }
    // }
    // if (offline === false) {
    //   if (webLink === "" || webLink === null) {
    //     console.log("weblink error");
    //     formIsValid = false;
    //     setWebLinkError("Please add a webinar link");
    //     return;
    //   }
    // }
    // if (offline === false && webLink !== "") {
    //   if (!validURL(webLink)) {
    //     formIsValid = false;
    //     setWebLinkFormatError("Please enter right URL format");
    //     return;
    //   }
    // }
    // if (paid === "yes") {
    //   if (actualPriceINR === "" || actualPriceINR === null) {
    //     console.log("actual price error");
    //     formIsValid = false;
    //     setActualPriceError("Please add the actual sesssion price");
    //     return;
    //   }
    // }
    // if (payLater === true) {
    //   if (payWithin === "") {
    //     console.log("paywithin error");
    //     formIsValid = false;
    //     setPayLaterError("Please select a date");
    //     return;
    //   }
    //   if (new Date(startdate).getTime() < new Date(payWithin).getTime()) {
    //     formIsValid = false;
    //     setPayLaterDateError("Date should be before the session");
    //     return;
    //   }
    // }
    // if (earlyBirdINR !== "") {
    //   if (earlyDate === "") {
    //     formIsValid = false;
    //     setEarlyError("Please select early bird end date");
    //     return;
    //   }
    //   if (new Date(earlyDate).getTime() > new Date(startdate).getTime()) {
    //     formIsValid = false;
    //     setEarlyDateError("Date should be before the session date");
    //     return;
    //   }
    // }
    if (formIsValid === true) {
      finalSessionImage();
      /* addSession(); */
    }
  };

  const addSession = async (imgName) => {
    let uniqueStamp = new Date().getTime();

    setSpin(true);
    let bodyObj = {};
    bodyObj.pid = 0;
    bodyObj.pname = name;
    bodyObj.ptimgname = imgName;
    bodyObj.poimgname = image.file.name;
    bodyObj.createdon = new Date().getTime();
    bodyObj.subcnt = null ;
    bodyObj.cmpcnt = null;
    bodyObj.cgryid = userDetails.categorydata.ct_id;
    bodyObj.atype = 0;
    bodyObj.schema = Constants.SCHEMA_NAME;

    const bodyParam = {
      body: bodyObj,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.UPDATE_PROGRAMS,
        bodyParam
      );
      handleAddLiveClose();
      getPrograms();
      swal
        .fire({
          title: "Success",
          text: response.message,
          icon: "success",
          dangerMode: false,
          closeOnClickOutside: false,
        })
        .then((willDelete) => {
          if (willDelete) {
            setSpin(false);
          }
        });
    } catch (error) {
      swal
        .fire({
          title: "Oooops!",
          text: "Soomthing wrong!",
          icon: "warning",
          dangerMode: true,
          closeOnClickOutside: false,
        })
        .then((willDelete) => {
          if (willDelete) {
            setSpin(false);
          }
        });
    }
  };

  const Input = styled("input")({
    display: "none",
  });

  // const disablePastDate = () => {
  //   const today = new Date();
  //   const dd = String(today.getDate()).padStart(2, "0");
  //   const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  //   const yyyy = today.getFullYear();
  //   return yyyy + "-" + mm + "-" + dd;
  // };

  return (
    <div className={Add.maincontainer}>
      <BackDrop sx={{ color: "#fff" }} open={spin} className={classes.backdrop}>
        <CircularProgress color="inherit" />
      </BackDrop>
      <div className={Add.closeholder}>
        <h3 className={Add.heading}>Add Program</h3>
        <Close className={Add.closeicon} onClick={handleAddLiveClose} />
      </div>

      <div className={Add.formfieldsholder}>
        <div className={Add.leftside}>
          <div
            style={{
              color: "#cc0000",
              textAlign: "start",
              marginLeft: "50%",
              fontSize: "10px",
            }}
          >
            {nameError}
          </div>
          <div className={Add.inputblock}>
            <div className={Add.labelholder}>
              <label for="name" className={Add.label} required>
                Program Name<span style={{ color: "red", marginTop: "-10px" }}>*</span>
              </label>
            </div>
            <div className={Add.inputholder}>
              <input
                type="text"
                id="name"
                className={Add.inputfield}
                onChange={onChangeHandlerName}
                value={name}
              ></input>
            </div>
          </div>
          <div
            style={{
              color: "#cc0000",
              textAlign: "start",
              marginLeft: "50%",
              fontSize: "10px",
            }}
          >
            {imageError}
          </div>

          <div
            style={{
              color: "#cc0000",
              textAlign: "start",
              marginLeft: "30%",
              fontSize: "10px",
            }}
          >
            {imageExtentionError}
          </div>
          <div className={Add.inputblock}>
            <div className={Add.labelholder}>
              <label for="image" className={Add.label} required>
                Program Image<span style={{ color: "red", marginTop: "-10px" }}>*</span>
              </label>
            </div>
            <div className={Add.inputholder}>
              <div className={Add.fileupload}>
                <label htmlFor="session-image" className={Add.labelnot}>
                  <Input
                    accept="image/*"
                    id="session-image"
                    type="file"
                    onChange={(e) => onChangeHandlerImage(e)}
                  />
                  <span className={Add.add}>Upload</span>
                  <h5 style={{ fontSize: "10px", color: "grey" }}>
                    {image !== null && image.file !== undefined
                      ? image.file.name
                      : ""}
                  </h5>
                </label>
              </div>
            </div>
          </div>
          {/* <div
            style={{
              color: "#cc0000",
              textAlign: "start",
              marginLeft: "50%",
              fontSize: "10px",
            }}
          >
            {sessionStartTimeError}
          </div>
          <div
            style={{
              color: "#cc0000",
              textAlign: "start",
              marginLeft: "50%",
              fontSize: "10px",
            }}
          >
            {startTimeError}
          </div>
          <div className={Add.inputblock}>
            <div className={Add.labelholder}>
              <label for="sessiondate" className={Add.label} required>
                Session Start date
                <span style={{ color: "red", marginTop: "-10px" }}>*</span>
              </label>
            </div>
            <div className={Add.inputholder}>
              <input
                type="datetime-local"
                id="sessiondate"
                className={Add.inputfield}
                onChange={onChangeHandlerSessionStartDate}
                value={startdate}
                min={disablePastDate()}
              ></input>
            </div>
          </div>
          <div
            style={{
              color: "#cc0000",
              textAlign: "start",
              marginLeft: "50%",
              fontSize: "10px",
            }}
          >
            {sessionEndTimeError}
          </div>
          <div
            style={{
              color: "#cc0000",
              textAlign: "start",
              marginLeft: "50%",
              fontSize: "10px",
            }}
          >
            {wrongDateError}
          </div>
          <div className={Add.inputblock}>
            <div className={Add.labelholder}>
              <label for="starttime" className={Add.label} required>
                Session End Time
                <span style={{ color: "red", marginTop: "-10px" }}>*</span>
              </label>
            </div>
            <div className={Add.inputholder}>
              <input
                type="datetime-local"
                id="starttime"
                className={Add.inputfield}
                onChange={onChangeHandlerSessionEndDate}
                value={endDate}
                min={disablePastDate()}
              ></input>
            </div>
          </div>
          <div
            style={{
              color: "#cc0000",
              textAlign: "start",
              marginLeft: "50%",
              fontSize: "10px",
            }}
          >
            {speakerError}
          </div>
          <div className={Add.inputblock}>
            <div className={Add.labelholder}>
              <label for="speaker" className={Add.label} required>
                Speaker
                <span style={{ color: "red", marginTop: "-10px" }}>*</span>
              </label>
            </div>
            <div className={Add.inputholder}>
              <select
                id="speaker"
                className={Add.inputfield}
                onChange={onChangeHandlerSpeaker}
                // value={speaker.name}
              >
                <option disabled selected>
                  Select Speaker
                </option>
                {speakerList.map((speaker, i) => {
                  return <option value={i}>{speaker.sp_name}</option>;
                })}
              </select>
            </div>
          </div>
          <div className={Add.speakerlists}>
            {speakerList.map((value, id) => {
              if (speakerArray.includes(value.sp_id.toString())) {
                return (
                  <div className={Add.speakertag} key={id}>
                    <h4 className={Add.speakername}>{value.sp_name}</h4>
                    <Close
                      className={Add.cancelicon}
                      onClick={() => {
                        console.log("id", id);
                        removeSpeaker(value.sp_id);
                      }}
                    />
                  </div>
                );
              }
            })}
          </div>
          <div
            style={{
              color: "#cc0000",
              textAlign: "start",
              marginLeft: "50%",
              fontSize: "10px",
            }}
          >
            {totalSlotsError}
          </div>
          <div className={Add.inputblock}>
            <div className={Add.labelholder}>
              <label for="totalslots" className={Add.label} required>
                Total slots
              </label>
            </div>
            <div className={Add.inputholder}>
              <input
                type="number"
                id="totalslots"
                className={Add.inputfield}
                onChange={onChangeHandlerSlots}
                value={slots}
              ></input>
            </div>
          </div>
          <div
            style={{
              color: "#cc0000",
              textAlign: "start",
              marginLeft: "50%",
              fontSize: "10px",
            }}
          >
            {slidesError}
          </div>
          <div
            style={{
              color: "#cc0000",
              textAlign: "start",
              marginLeft: "40%",
              fontSize: "10px",
            }}
          >
            {docExtentionError}
          </div>
          <div className={Add.inputblock}>
            <div className={Add.labelholder}>
              <label for="summary" className={Add.label} required>
                Summary slides
              </label>
            </div>
            <div className={Add.holder}>
              <label htmlFor="sessionSlides">
                <Input
                  accept=".pdf"
                  id="sessionSlides"
                  type="file"
                  onChange={(e) => onChangeHandlerSlides(e)}
                />
                <span className={Add.add}>Upload</span>
                <h5>
                  {" "}
                  {slides !== null && slides.file !== undefined
                    ? slides.file.name
                    : ""}
                </h5>
              </label>
            </div>
          </div>
          <div
            style={{
              color: "#cc0000",
              textAlign: "start",
              marginLeft: "50%",
              fontSize: "10px",
            }}
          >
            {sessionTypeError}
          </div>
          <div className={Add.inputblock}>
            <div className={Add.labelholder}>
              <label for="coursetype" className={Add.label} required>
                Session Type
                <span style={{ color: "red", marginTop: "-10px" }}>*</span>
              </label>
            </div>
            <div className={Add.checkholder}>
              <div>
                <input
                  type="radio"
                  value="online"
                  name="sessiontype"
                  className={Add.radioinput}
                  checked={sessionTypeCheck}
                  onChange={(e) => {
                    handleSessionType(e);
                    setSessionTypeCheck(true);
                    setOffline(false);
                    setLocation(false);
                  }}
                ></input>
                <span className={Add.span}>Online</span>
              </div>
              <div>
                <input
                  type="radio"
                  value="offline"
                  name="sessiontype"
                  className={Add.radioinput}
                  onChange={(e) => {
                    handleSessionType(e);
                    setOffline(true);
                    setLocation(true);
                  }}
                ></input>
                <span className={Add.span}>Offline</span>
              </div>
              <div>
                <input
                  type="radio"
                  value="Hybrid"
                  name="sessiontype"
                  className={Add.radioinput}
                  onChange={(e) => {
                    handleSessionType(e);
                    setOffline(false);
                    setLocation(true);
                  }}
                ></input>
                <span className={Add.span}>Hybrid</span>
              </div>
            </div>
          </div>
          {location === true ? (
            <>
              <div
                style={{
                  color: "#cc0000",
                  textAlign: "start",
                  marginLeft: "50%",
                  fontSize: "10px",
                }}
              >
                {locationError}
              </div>
              <div className={Add.inputblock}>
                <div className={Add.labelholder}>
                  <label for="location" className={Add.label} required>
                    Location
                    <span style={{ color: "red", marginTop: "-10px" }}>*</span>
                  </label>
                </div>
                <div className={Add.inputholder}>
                  <textarea
                    rows={4}
                    id="location"
                    className={Add.textfield}
                    onChange={(e) => onChangeHandleLocation(e)}
                    value={locationValue}
                  ></textarea>
                </div>
              </div>
            </>
          ) : null}
        </div>
        <div className={Add.rightside}>
          <div
            style={{
              color: "#cc0000",
              textAlign: "start",
              marginLeft: "35%",
              fontSize: "10px",
            }}
          >
            {regStartDateError}
          </div>
          <div className={Add.inputblock}>
            <div className={Add.labelholder}>
              <label for="registrationdate" className={Add.label} required>
                Registration start date
              </label>
            </div>
            <div className={Add.inputholder}>
              <input
                type="date"
                id="registrationdate"
                className={Add.inputfield}
                onChange={onChangeHandlerRegStartDate}
                value={regStartDate}
                min={disablePastDate()}
              ></input>
            </div>
          </div>
          {offline === false ? (
            <>
              <div
                style={{
                  color: "#cc0000",
                  textAlign: "start",
                  marginLeft: "50%",
                  fontSize: "10px",
                }}
              >
                {webLinkError}
              </div>
              <div
                style={{
                  color: "#cc0000",
                  textAlign: "start",
                  marginLeft: "50%",
                  fontSize: "10px",
                }}
              >
                {webLinkFormatError}
              </div>
              <div className={Add.inputblock}>
                <div className={Add.labelholder}>
                  <label for="webinarlink" className={Add.label}>
                    Webinar Link
                    <span style={{ color: "red", marginTop: "-10px" }}>*</span>
                  </label>
                </div>
                <div className={Add.inputholder}>
                  <input
                    type="text"
                    id="webinarlink"
                    className={Add.inputfield}
                    onChange={onChangeHandlerWebLink}
                    value={webLink}
                  ></input>
                </div>
              </div>
              <div className={Add.inputblock}>
                <div className={Add.labelholder}>
                  <label for="webinarid" className={Add.label}>
                    Webinar ID
                  </label>
                </div>
                <div className={Add.inputholder}>
                  <input
                    type="text"
                    id="webinarid"
                    className={Add.inputfield}
                    onChange={onChangeHandlerWebID}
                    value={webID}
                  ></input>
                </div>
              </div>
              <div className={Add.inputblock}>
                <div className={Add.labelholder}>
                  <label for="passcode" className={Add.label}>
                    Passcode
                  </label>
                </div>
                <div className={Add.inputholder}>
                  <input
                    type="text"
                    id="passcode"
                    className={Add.inputfield}
                    onChange={onChangeHandlerPasscode}
                    value={passcode}
                  ></input>
                </div>
              </div>
            </>
          ) : null}
          <div
            style={{
              color: "#cc0000",
              textAlign: "start",
              marginLeft: "50%",
              fontSize: "10px",
            }}
          >
            {paidError}
          </div>
          <div className={Add.inputblock}>
            <div className={Add.labelholder}>
              <label for="paidcourse" className={Add.label} required>
                Paid Session
                <span style={{ color: "red", marginTop: "-10px" }}>*</span>
              </label>
            </div>
            <div className={Add.checkholder}>
              <div>
                <input
                  type="radio"
                  value="yes"
                  name="paylater"
                  className={Add.radioinput}
                  onChange={(e) => {
                    onChangeHandlerPaid(e);
                    setView(true);
                    setPaymentTypeCheck(false);
                  }}
                ></input>
                <span className={Add.span}>Yes</span>
              </div>
              <div>
                <input
                  type="radio"
                  value="no"
                  name="paylater"
                  className={Add.radioinput}
                  checked={paymentTypeCheck}
                  onChange={(e) => {
                    onChangeHandlerPaid(e);
                    setView(false);
                    setPaymentTypeCheck(true);
                  }}
                ></input>
                <span className={Add.span}>No</span>
              </div>
            </div>
          </div>
          {view === true ? (
            <>
              <div
                style={{
                  color: "#cc0000",
                  textAlign: "start",
                  marginLeft: "50%",
                  fontSize: "10px",
                }}
              >
                {actualPriceError}
              </div>
              <div className={Add.inputblock}>
                <div className={Add.labelholder}>
                  <label for="price" className={Add.label}>
                    Actual Price in INR
                    <span style={{ color: "red", marginTop: "-10px" }}>*</span>
                  </label>
                </div>
                <div className={Add.inputholder}>
                  <input
                    type="number"
                    id="price"
                    className={Add.inputfield}
                    onChange={onChangeHandlerActualPriceINR}
                    value={actualPriceINR}
                  ></input>
                </div>
              </div>
              <div className={Add.inputblock}>
                <div className={Add.labelholder}>
                  <label for="price" className={Add.label}>
                    Actual Price in USD
                  </label>
                </div>
                <div className={Add.inputholder}>
                  <input
                    type="number"
                    id="price"
                    className={Add.inputfield}
                    onChange={onChangeHandlerActualPriceUSD}
                    value={actualPriceUSD}
                  ></input>
                </div>
              </div>
              <div className={Add.inputblock}>
                <div className={Add.labelholder}>
                  <label for="discount" className={Add.label}>
                    Percentage discount
                  </label>
                </div>
                <div className={Add.inputholder}>
                  <input
                    type="number"
                    id="discount"
                    className={Add.inputfield}
                    onChange={onChangeHandlerPerDis}
                    value={perDis}
                  ></input>
                </div>
              </div>
              <div className={Add.inputblock}>
                <div className={Add.labelholder}>
                  <label for="afterdiscount" className={Add.label}>
                    Price after discount in INR
                  </label>
                </div>
                <div className={Add.inputholder}>
                  <input
                    type="number"
                    id="afterdiscount"
                    className={Add.inputfield}
                    value={priceAftINR}
                  ></input>
                </div>
              </div>
              <div className={Add.inputblock}>
                <div className={Add.labelholder}>
                  <label for="afterdiscount" className={Add.label}>
                    Price after discount in USD
                  </label>
                </div>
                <div className={Add.inputholder}>
                  <input
                    type="number"
                    id="afterdiscount"
                    className={Add.inputfield}
                    value={priceAftUSD}
                  ></input>
                </div>
              </div>
              <div className={Add.inputblock}>
                <div className={Add.labelholder}>
                  <label for="birdprice" className={Add.label}>
                    Early bird price in INR
                  </label>
                </div>
                <div className={Add.inputholder}>
                  <input
                    type="number"
                    id="birdprice"
                    className={Add.inputfield}
                    onChange={onChangeHandlerEarlyBirdINR}
                    value={earlyBirdINR}
                  ></input>
                </div>
              </div>

              <div className={Add.inputblock}>
                <div className={Add.labelholder}>
                  <label for="birdprice" className={Add.label}>
                    Early bird price in USD
                  </label>
                </div>
                <div className={Add.inputholder}>
                  <input
                    type="number"
                    id="birdprice"
                    className={Add.inputfield}
                    onChange={onChangeHandlerEarlyBirdUSD}
                    value={earlyBirdUSD}
                  ></input>
                </div>
              </div>
              <div
                style={{
                  color: "#cc0000",
                  textAlign: "start",
                  marginLeft: "50%",
                  fontSize: "10px",
                }}
              >
                {earlyError}
              </div>
              <div
                style={{
                  color: "#cc0000",
                  textAlign: "start",
                  marginLeft: "50%",
                  fontSize: "10px",
                }}
              >
                {earlyDateError}
              </div>
              <div className={Add.inputblock}>
                <div className={Add.labelholder}>
                  <label for="enddate" className={Add.label}>
                    Early bird price end date
                  </label>
                </div>
                <div className={Add.inputholder}>
                  <input
                    type="date"
                    id="enddate"
                    className={Add.inputfield}
                    onChange={onChangeHandlerEarlyDate}
                    value={earlyDate}
                  ></input>
                </div>
              </div>
              <div
                style={{
                  color: "#cc0000",
                  textAlign: "start",
                  marginLeft: "50%",
                  fontSize: "10px",
                }}
              >
                {payLaterError}
              </div>
              <div
                style={{
                  color: "#cc0000",
                  textAlign: "start",
                  marginLeft: "50%",
                  fontSize: "10px",
                }}
              >
                {payLaterDateError}
              </div>
              <div className={Add.inputblock}>
                <div className={Add.labelholder}>
                  <label for="paylater" className={Add.label}>
                    Enable pay later
                  </label>
                </div>
                <div className={Add.paywithindate}>
                  <input
                    type="checkbox"
                    id="paylate"
                    onChange={(e) => onChangeHandlerPayLater(e)}
                    value={payLater}
                  ></input>

                  <div className={Add.paywithholder}>
                    <label for="paylater" className={Add.label}>
                      Pay within date
                    </label>
                    <input
                      type="date"
                      id="paylater"
                      className={Add.smallfield}
                      onChange={onChangeHandlerPayWithin}
                      value={payWithin}
                    ></input>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
      <div className={Add.bottomfields}>
        <label for="description" required className={Add.label}>
          Description
        </label>
        <textarea
          id="description"
          className={Add.textarea}
          rows={3}
          onChange={onChangeHandlerDes}
          value={des}
        />
        <label for="learning" required className={Add.label}>
          Learning objectives
        </label>
        <textarea
          id="learning"
          className={Add.textarea}
          rows={3}
          onChange={onChangeHandlerObject}
          value={object}
        />
        <label for="audience" required className={Add.label}>
          Target audience
        </label>
        <textarea
          id="audience"
          className={Add.textarea}
          rows={3}
          onChange={onChangeHandlerTarget}
          value={target}
        />
      </div> */}
      <div className={Add.buttonholder}>
        <button className={Add.cancel} onClick={handleAddLiveClose}>
          Cancel
        </button>
        <button className={Add.add} type="submit" onClick={submitForm}>
          Add
        </button>
      </div>
    </div>
    </div>
    </div>
  );
};

export default AddProgram;
