// Dependencies imports
import React, { useState, useEffect } from "react";
import {
  DataGrid,
  GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/styles";
import Paper from "@mui/material/Paper";
import { awsSignIn, authData } from "../../../redux/auth/authSlice";
import { useSelector, useDispatch } from "react-redux";
import { API } from "aws-amplify";
import config from "../../../config/aws-exports";
// Local imports
import { Constants } from "../../../config/Constants";
import CourseRegUsers from "./CourseRegUsers";
import CourseApproval from "./CourseApproval";
import { Link, useHistory } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
// Style imports
import listTopStyle from "./listTopics.module.scss";
import CoursePublish from "./CoursePublish";
import image from "./2.png";
import moment from "moment";
import MarkPopular from "./MarkPopular";
import MarkPopularCourse from "./MarkPopularCourse";
import { Button } from "@material-ui/core";
import { FileDownload } from "@mui/icons-material";
import { CSVLink } from "react-csv";
import NothiongHereYet from '../../../assets/Nothing Here Yet.gif';

import Tooltip from '@mui/material/Tooltip';

// Custom Toolbard to export and filter for datagrid

const useStyles = makeStyles({
  root: {
    "& .super-app-theme--header": {
      backgroundColor: Constants.blue_color,
      fontSize: "16px",
      color: "rgb(255,255,255)",
      fontFamily: "nunito",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
    cursor: "pointer",
  },
});

const funData = (data) => {
  let datalist = data.map((item) => {
    let obj = {};
    obj.coursename = item.tn;
    obj.lastmodifiedby = item.cby;
    obj.lastmodifiedon = moment(parseInt(item.con)).format("DD-MM-YYYY");
    obj.tags = item?.cgry_taggs ? item?.cgry_taggs.join(",") : "";
    obj.waitinglist = item.waiting_count;
    obj.resgisteredusers = item.registered_count;
    return obj;
  });
  console.log(data);
  return datalist;
};
// Start of main Tenant Component
export default function ListTopics({ searchText, open, close ,listForReg}) {
  console.log(listForReg)
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [topics, setTopics] = useState([]);
  console.log(topics)
  const [tenants, setTenants] = useState([]);
  const [editopen, setEditOpen] = useState(false);
  const [dataToEdit, setDataToEdit] = useState({});
  const [topicRowData, setTopicRowData] = useState({});
  // const [listForReg, setListForReg] = useState({});
  const [openWait, setOpenWait] = useState(false);
  const [openReg, setOpenReg] = useState(false);
  const [topicId, setTopicId] = useState("");
  const [data, setData] = useState([]);
  const [topicName, setTopicName] = useState("");
  const [spin, setSpin] = useState(false);

  let userDetails = useSelector(authData);
  let tenantoid = false;
  const dispatch = useDispatch();

  //const trows = [];
  useEffect(() => {
    getTopicList();
    getAllTenants();
    breadcrumb();
    return () => {};
  }, []);

  function breadcrumb() {
    let sdata = { ...userDetails };
    if (sdata.breadcrumb == undefined) {
      sdata.breadcrumb = [];
      let temp = [...sdata.breadcrumb];
      temp[0] = {
        name: "Courses",
        path: "/uspcontent",
      };
      temp[1] = {
        name: sdata?.categorydata?.name,
        path: "/uspcontent/viewtopic",
      };
      sdata.breadcrumb = temp;
    } else {
      let temp = [...sdata.breadcrumb];
      temp[0] = {
        name: "Courses",
        path: "/uspcontent",
      };
      temp[1] = {
        name: sdata?.categorydata?.name,
        path: "/uspcontent/viewtopic",
      };
      sdata.breadcrumb = temp;
    }
  }

  function viewtopic(item) {
    // console.log(item);
    let userdata = { ...userDetails };
    userdata.tid = item.id;
    userdata.topic = item.row;
    console.log(userdata.breadcrumb);
    let temp = userdata.breadcrumb ? [...userdata.breadcrumb] : [];
    temp[0] = {
      name: "USP Content",
      path: "/uspcontent?tab=3",
    };
    temp[1] = {
      name: item.row.tn,
      path: "/uspcontent/viewtopic",
    };

    userdata.breadcrumb = temp;
    dispatch(awsSignIn(userdata));
    history.push("/uspcontent/viewtopic");
  }

  const handleEditClose = () => {
    setEditOpen(false);
  };

  function CustomToolbar() {
    const headers = [
      { label: "Course Name", key: "coursename" },
      { label: "Last Modified By", key: "lastmodifiedby" },
      { label: "Last Modified On", key: "lastmodifiedon" },
      { label: "Tags", key: "tags" },
      { label: "Waiting List", key: "waitinglist" },
      { label: "Registered Users", key: "resgisteredusers" },
    ];

    return (
      <GridToolbarContainer
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <Button color="primary">
          <CSVLink
            data={data}
            headers={headers}
            filename="course-details"
            className={listTopStyle.exp_btn}
          >
            <FileDownload />
            Export
          </CSVLink>
        </Button>
      </GridToolbarContainer>
    );
  }

  async function getTopicList() {
    let sdata = { ...userDetails };
    setLoading(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        tid: sdata?.categorydata?.id,
        admin: true,
        schema: Constants.SCHEMA_NAME,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      // console.log(JSON.stringify(bodyParam.body));
      const list = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_CATEGORY_DATA,
        bodyParam
      );

      // setCatdata(list.topics);
      const rows = list.courses.map((item, index) => {
        var obj = {};
        obj.col1 = item.tid;
        obj.id = item.tid;
        obj.tn = item.tn;
        obj.cby = item.cby ? item.cby : "Admin";
        var months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];

        obj.con = moment(parseInt(item.mdate)).format("DD-MM-YYYY");

        obj.tags = item?.cgry_taggs === undefined ? [] : item?.cgry_taggs;
        obj.waiting = item;
        obj.registered = item;
        obj.publish = item?.tenants?.length;
        obj.tenants = item?.tenants;
        console.log("item", item);
        return obj;
      });
      setData(funData(list.courses));
      // console.log(JSON.stringify(rows));
      setTopics(rows);
      setLoading(false);
      setSpin(false);
    } catch (error) {
      console.error(error);
    }
  }
  async function getAllTenants() {
    setLoading(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        schema: Constants.SCHEMA_NAME,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      // console.log(JSON.stringify(bodyParam.body));
      const list = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_TENANTS,
        bodyParam
      );

      // console.log(JSON.stringify(list));
      setTenants(list.body);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  }
  const tcolumns = [
    {
      field: "col1",
      headerName: "Image",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 180,
      disableExport: true,
      renderCell: (params) => (
        <img
          style={{
            width: "130px",
            height: "80px",
            borderRadius: "10px",
          }}
          src={`https://${
            config.DOMAIN
          }/${config.aws_org_id.toLowerCase()}-resources/images/topic-images/${
            params.value
          }.png`}
          alt=""
        ></img>
      ),
    },
    {
      field: "tn",
      headerName: "Course Name",
      flex: 2,
      minWidth: 250,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <span
          onClick={() => viewtopic(params)}
          style={{
            color: Constants.blue_color,
            textDecoration: "none",
            fontFamily: "14px",
            fontWeight: "bold",
          }}
        >
          {/* {params.value} */}
          <Tooltip title={params.value} placement="top-start">
        <h4 style={{textTransform:'capitalize'}}>{params.value}</h4>
      </Tooltip>
        </span>
      ),
    },
    {
      field: "cby",
      headerName: "Last Modified By",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "con",
      headerName: "Last Modified On",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 220,
    },
    {
      field: "tags",
      headerName: "Tags",
      minWidth: 120,
      flex: 1,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            maxWidth: "190px",
            flexWrap: "wrap",
            gap: "10px",
            // width: "50%",
            // margin: '0 auto',
          }}
        >
          {params?.value?.map((item) => (
            <button
              style={{
                maxWidth: "190px",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {item}
            </button>
          ))}
        </div>
      ),
    },
    {
      field: "waiting",
      headerName: "Waiting List",
      flex: 1,
      minWidth: 170,
      headerClassName: "super-app-theme--header",
      renderCell: (param) => (
        <div
          onClick={() => handleWaitingList(param)}
          style={{
            display: "flex",
            width: "50%",
            margin: "0 auto",
            flexWrap: "wrap",
            gap: "10px",
          }}
        >
          {param.value.waiting_count}
        </div>
      ),
    },
    {
      field: "registered",
      headerName: "Registered Users",
      flex: 1,
      minWidth: 210,
      headerClassName: "super-app-theme--header",
      renderCell: (param) => (
        <div
          onClick={() => handleRegisteredList(param)}
          style={{
            display: "flex",
            width: "50%",
            margin: "0 auto",
            flexWrap: "wrap",
            gap: "10px",
          }}
        >
          {param.value.registered_count}
        </div>
      ),
    },
    {
      field: "organisation published",
      headerName: "Organisation Published",
      minWidth: 250,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            width: "50%",
            margin: "0 auto",
          }}
        >
          {params.row.tenants.length}
        </div>
      ),
    },
    {
      field: "publish",
      headerName: "Publish",
      disableExport: true,
      headerClassName: "super-app-theme--header",
      minWidth: 140,
      flex: 1,
      renderCell: (params) => (
        <button
          className={
            !params?.row?.tenants?.length
              ? listTopStyle.addbutton
              : listTopStyle.addbuttongreen
          }
          onClick={() => handlePublishOpen(params)}
        >
          Publish
          <br />
          Unpublish
        </button>
      ),
    },
  ];

  function handleWaitingList(param) {
    setTopicId(param.value.tid);
    // console.log("tid", param.value.tid);
    setTopicName(param.value.tn);
    handleWaitOpen();
    console.log({ param });
  }

  function handlePublishOpen(item) {
    // console.log(item);
    setTopicRowData(item.row);
    setEditOpen(true);
    setDataToEdit(item);
  }

  function handleRegOpen() {
    setOpenReg(true);
  }

  function handleRegClose() {
    setOpenReg(false);
  }

  function handleWaitOpen() {
    setOpenWait(true);
  }

  function handleWaitClose() {
    setOpenWait(false);
  }

  function handleRegisteredList(param) {
    setTopicId(param.value.tid);
    handleRegOpen();
  }

  const filterCourses = topics.filter((tabledata) =>
    tabledata?.tn?.toLowerCase().includes(searchText?.toLowerCase())
  );
  console.log(filterCourses)
  // Start of main return
  return (
    <div className={listTopStyle.main}>
            <Backdrop sx={{ color: "#fff" }} open={spin} className={classes.backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={editopen}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={editopen}>
          <CoursePublish
            handleEditClose={handleEditClose}
            tenants={tenants}
            topicRowData={topicRowData}
            object={false}
            getObjects={getTopicList}
          />
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openReg}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openReg}>
          <CourseRegUsers
            topicId={topicId}
            handleRegClose={handleRegClose}
            tenantoid={tenantoid}
            getTopicList={getTopicList}
            listForReg={listForReg}
            topics={topics}
          />
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openWait}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openWait}>
          <CourseApproval
            tn={topicName}
            topicId={topicId}
            tenantoid={tenantoid}
            handleWaitClose={handleWaitClose}
            getTopicList={getTopicList}
          />
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <MarkPopularCourse handleClosePopular={close} tabledata={topics} />
        </Fade>
      </Modal>
      <div
        style={{ height: 450, width: "100%", overflow: "auto" }}
        className={classes.root}
      >
        {
          filterCourses.length <= 0 ? (
            <div style={{
              height: "50vh",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              // marginBottom:'400px'
            }}>
              <img src={NothiongHereYet} alt="Nothing Here" />
            </div>
          ) : (<DataGrid
          rows={filterCourses}
          columns={tcolumns}
          rowHeight={100}
          className={classes[".MuiDataGrid-root"]}
          components={{
            Toolbar: CustomToolbar,
          }}
          disableSelectionOnClick
        />)
        }
      </div>
    </div>
  );
}
