import React from "react";

const ContentSvg = ({ className, cls1, cls2, cls3, cls4 }) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      className={className}
    >
      <defs></defs>

      {/* <path
        className={cls1}
        d="M70.3,53.2H47c-1,0-1.9-1.4-1.9-3.2s.8-3.2,1.9-3.2H70.3c1,0,1.9,1.4,1.9,3.2S71.4,53.2,70.3,53.2Z"
      /> */}
      {/* <path
        className={cls1}
        d="M51.5,21.5V49.9c0,1.3-1.4,2.3-3.2,2.3s-3.2-1-3.2-2.3V21.5c0-1.3,1.4-2.3,3.2-2.3S51.5,20.2,51.5,21.5Z"
      /> */}
      <circle className={cls2} cx="50.21" cy="50.6" r="37.2" />
      <path
        className={cls1}
        d="M59.63,33.27H34.76V67.93h30.9V39.29A6.1,6.1,0,0,0,59.63,33.27Z"
      />
      <rect className={cls3} x="39.32" y="38.92" width="11.88" height="2.66" />
      <rect className={cls3} x="39.32" y="54.75" width="21.56" height="2.66" />
      <rect className={cls3} x="39.32" y="46.74" width="21.56" height="2.66" />
    </svg>
  );
};

export default ContentSvg;
