import React, {useState , useEffect} from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import config from "../../config/aws-exports";
import { awsSignOut, authData } from "../../redux/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { Auth } from "aws-amplify";
import {Link, useHistory} from 'react-router-dom';

export default function Header() {
  const dispatch = useDispatch();
  let userDetails = useSelector(authData);
  const navigate = useHistory();
  console.log("userdetails", userDetails);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  function logout() {
    Auth.signOut();
    dispatch(awsSignOut());
    navigate.push("/");
  };

  return (
    <div style={{ position: "fixed", top: 0, zIndex: 100, width: "100%" }}>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="static"
          sx={{ backgroundImage: config.platform_main_theme }}
        >
          <Toolbar>
            <Box sx={{ flexGrow: 1 }}>
              <div style={{ width: "100px" }}>
                <Link to="/landingscreen"><img height="50px" width="auto" src={`https://${config.DOMAIN}/${config.aws_org_id.toLowerCase()}-resources/images/org-images/logo-dark.png`} alt="logo" /></Link>
              </div>
            </Box>

            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <Typography
                  variant="h6"
                  component="div"
                  style={{ paddingRight: "10px", color: "white", fontWeight: "100" }}
                >
                  {userDetails.name === undefined ? "" : userDetails.name}
                </Typography>
                <AccountCircle style={{ color: "white" }} />
              </IconButton>
              <Menu
                style={{
                  marginTop: "40px"
                }}
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={logout}>Logout</MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
      </Box>
    </div>
  );
}
