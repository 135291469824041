import React, { useState, useRef, useEffect } from "react";
import prefStyle from "./addPrefrences.module.scss";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import { makeStyles } from "@material-ui/core/styles";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import { ReactComponent as Close } from "../../../../assets/icons/close_black_24dp.svg";
import { Constants } from "../../../../config/Constants";
import config from "../../../../config/aws-exports";
import { API } from "aws-amplify";
import swal from "sweetalert";
import axios from "axios";
import BackDrop from "@material-ui/core/Backdrop/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Button } from "@material-ui/core";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  formControl: {
    border: "1px solid #6a6a6a",
    height: "fit-content",
    width: "100%",
    borderRadius: "5px",
    fontSize: "14px",
    fontFamily: "nunito",
    padding: "5px",
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function AddPrefrences(props) {
  const classes = useStyles();
  const [personName, setPersonName] = useState([]);
  const [nextc, setNextc] = useState([]);
  let {
    topicData,
    setTopicData,
    topics,
    folder,
    editObj,
    setEditObj,
    priceininr,
    setPriceininr,
    priceinusd,
    setPriceinusd,
    perDis,
    setPerDis,
    earlyPriceInr,
    setEarlyPriceInr,
    earlyPriceUsd,
    setEarlyPriceUsd,
    earlyDate,
    setEarlyDate,
    priceafterDisInr,
    setPriceafterDisInr,
    priceafterDisUsd,
    setPriceafterDisUsd,
    speakerType,
    setSpeakerType,
    speakerArray,
    setSpeakerArray,
    courseType,
    setCourseType,
    speakerList,
  } = props;
  const [tag, setTag] = useState("");
  const [tagArray, setTagArray] = useState([]);
  const [isLoading, setIstLoading] = useState(false);
  const [defaultSpeaker, setDefaultSpeaker] = useState(true);
  const refselectedCourseFile = useRef();

  useEffect(() => {
    setTagArray(topicData.cgry_taggs);
    let array1 = [...topics];
    console.log(array1);
    let pre_courseidArray = array1?.filter((val) =>
      topicData.pre_courseid?.includes(val?.id || val?.tid)
    );

    const preCourseName = pre_courseidArray?.map((val) => val?.tn);
    // console.log(topicData.pre_courseid);
    setPersonName(preCourseName);
    let nextcourseidArray = array1?.filter((val) =>
      topicData.nextcourseid?.includes(val?.id || val?.tid)
    );
    const newnextcourseName = nextcourseidArray?.map((val) => val?.tn);
    setNextc(newnextcourseName);
    // //console.log("speakerArray", speakerArray);
    // //console.log("speakerList", speakerList);
    return () => {};
  }, []);

  const onChangeHandlerSpeakerType = (event) => {
    setSpeakerType(event.target.value);
  };

  const onChangeHandlerTags = (event) => {
    // //console.log("tv", event.target.value);

    if (tag.toString().replace(/\s/g, "")?.length === 0) {
      swal("Oops!", "Enter the tag value", "error");
      return;
    }
    let speakArray = [...tagArray];
    let obj;

    obj = tag;

    let flag = false;
    for (let j = 0; j < speakArray?.length; j++) {
      if (speakArray[j] == obj) {
        flag = true;
      }
    }
    if (flag === false) {
      speakArray.push(obj.toString());
    }
    // //console.log(speakArray);
    setTagArray(speakArray);
    setTopicData((prevData) => ({ ...prevData, cgry_taggs: speakArray }));
    setTag("");
  };

  const onChangeHandlerSpeaker = (event) => {
    setDefaultSpeaker(false);
    // //console.log("tv", event.target.value);
    // //console.log(event);
    let i = event.target.value;
    let speakArray = [...speakerArray];
    let obj;

    obj = speakerList[i].sp_id;
    let flag = false;
    for (let j = 0; j < speakArray?.length; j++) {
      if (speakArray[j] == obj) {
        flag = true;
      }
    }
    if (flag === false) {
      speakArray.push(obj);
    }

    setSpeakerArray(speakArray);
  };

  // const removeSpeaker = (value) => {
  //   let updatedArray = [...topicData.cgry_taggs];
  //   updatedArray = updatedArray.filter(
  //     (deleting) => deleting !== value.toString()
  //   );
  //   setTagArray(updatedArray);
  //   setTopicData({ ...topicData, cgry_taggs: updatedArray });
  //   // //console.log("updatedspeakerarry", tagArray);
  // };

  const removeTags = (value) => {
    setDefaultSpeaker(true);
    let updatedArray = [...tagArray];
    updatedArray.splice(value, 1);

    setTagArray(updatedArray);
    setTopicData((prevData) => ({ ...prevData, cgry_taggs: updatedArray }));
    //console.log("updatedspeakerarry", speakerArray);
  };
  const removeSpeaker = (value) => {
    setDefaultSpeaker(true);
    let updatedArray = [...speakerArray];
    updatedArray = updatedArray.filter((deleting) => deleting !== value);

    setSpeakerArray(updatedArray);
    //console.log("updatedspeakerarry", speakerArray);
  };

  const handleChange = (event) => {
    let selectArray = event.target.value;

    let temparr = [];
    for (let i = 0; i < topics?.length; i++) {
      let obj = {};
      //console.log(topics[i]);
      obj.id = topics[i].id || topics[i].tid;
      obj.tn = topics[i].tn;
      for (let k = 0; k < selectArray?.length; k++) {
        // //console.log(topics[i].tn, "==", selectArray[k]);
        if (topics[i].tn === selectArray[k]) {
          temparr.push(topics[i].id || topics[i].tid);
        }
      }
    }
    console.log({ topics });
    setPersonName(event.target.value);
    setTopicData((prevTopicData) => ({
      ...prevTopicData,
      pre_courseid: temparr,
    }));
  };

  const handlenextchange = (event) => {
    let selectArray = event.target.value;

    let temparr = [];
    for (let i = 0; i < topics?.length; i++) {
      let obj = {};
      //console.log(topics[i]);
      obj.id = topics[i].id || topics[i].tid;
      obj.tn = topics[i].tn;
      for (let k = 0; k < selectArray?.length; k++) {
        // //console.log(topics[i].tn, "==", selectArray[k]);
        if (topics[i].tn === selectArray[k]) {
          temparr.push(topics[i].id || topics[i].tid);
        }
      }
    }
    setNextc(event.target.value);
    setTopicData((prevTopicData) => ({
      ...prevTopicData,
      nextcourseid: temparr,
    }));
  };

  const onChangeHandlerPerDis = (event) => {
    setPerDis(event.target.value);
    let discountedPriceINR = ((100 - event.target.value) / 100) * priceininr;
    let discountedPriceUSD = ((100 - event.target.value) / 100) * priceinusd;
    if (event.target.value === "") {
      setPriceafterDisInr(0);
      setPriceafterDisUsd(0);
    } else {
      setPriceafterDisInr(discountedPriceINR);
      setPriceafterDisUsd(discountedPriceUSD);
    }
  };

  const onChangeHandlerCourseType = (event) => {
    setCourseType(event.target.value);
  };

  const changeHandler1 = (event) => {
    if (event.target.files == null) {
      return;
    }
    if (event.target.files[0].size > 5242880) {
      swal(
        "Oops!",
        "Image size is too large. File size cannot be greater than 5mb.",
        "error"
      );
      return;
    }
    if (event.target.files[0] !== undefined) {
      refselectedCourseFile.current = event.target.files[0];
      //setSelectedCourseFile((currentState) => (event.target.files[0]));
      var body = {
        filetype: "image/png",
        filename: "cert.png",
        folder: folder,
      };
      // //console.log(event.target.files[0].name);
      setTopicData({ ...topicData, tempimage: event.target.files[0].name });
      uploadfile(body);
    }
  };

  async function uploadfile(body) {
    setIstLoading(true);
    const bodyParam = {
      body,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      // //console.log(JSON.stringify(bodyParam.body));
      const preSignedData = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_PRESIGNED_URL,
        //"/getpreSignedURL",
        bodyParam
      );

      axios
        .put(preSignedData, refselectedCourseFile.current, {
          headers: {
            "Content-Type": "image/png",
            "Cache-Control": "no-cache no-store must-revalidate",
          },
        })
        .then((response) => {
          // //console.log("file upload success");
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
      setIstLoading(false);
    } catch (error) {
      // alert(JSON.stringify(error));
      console.error(error);
    }
  }

  return (
    <div style={{ display: "flex", justifyContent: "center", padding: "20px" }}>
      <BackDrop
        sx={{ color: "#fff" }}
        open={isLoading}
        className={classes.backdrop}
      >
        <CircularProgress color="inherit" />
      </BackDrop>
      <Paper
        sx={{
          maxWidth: "max-content",
        }}
      >
        <div>
          <div className={prefStyle.outer}>
            <p className={prefStyle.lable}>
              Navigation{" "}
              <span style={{ color: "red", marginTop: "-10px" }}>*</span> :
            </p>
            <div className={prefStyle.options}>
              <button
                className={
                  topicData.nav === "false" || topicData.nav === false
                    ? prefStyle.classtagCk
                    : prefStyle.classtag
                }
                style={{ textAlign: "center" }}
                value={true}
                disabled={!editObj.meditmode}
                onClick={(e) =>
                  setTopicData({
                    ...topicData,
                    nav: e.target.value,
                    ctype: "regular",
                  })
                }
              >
                Free
              </button>
              <button
                className={
                  topicData.nav === "true" || topicData.nav === true
                    ? prefStyle.classtagCk
                    : prefStyle.classtag
                }
                style={{ textAlign: "center" }}
                value={false}
                disabled={!editObj.meditmode}
                onClick={(e) =>
                  setTopicData({ ...topicData, nav: e.target.value })
                }
              >
                Regular
              </button>
            </div>
          </div>
          <div className={prefStyle.outer}>
            <p className={prefStyle.lable}>
              {" "}
              Learning Type
              <span style={{ color: "red", marginTop: "-10px" }}>*</span> :
            </p>
            <div className={prefStyle.options}>
              <button
                className={
                  topicData.ctype === ""
                    ? prefStyle.classtagCk
                    : topicData.ctype === "enforced"
                    ? prefStyle.classtag
                    : prefStyle.classtagCk
                }
                style={{ textAlign: "center" }}
                value={"enforced"}
                disabled={
                  !editObj.meditmode ||
                  topicData.nav == true ||
                  topicData.nav == "true"
                }
                onClick={(e) =>
                  setTopicData({ ...topicData, ctype: e.target.value })
                }
              >
                Enforced
              </button>
              <button
                className={
                  topicData.ctype === ""
                    ? prefStyle.classtagCk
                    : topicData.ctype === "regular"
                    ? prefStyle.classtag
                    : prefStyle.classtagCk
                }
                style={{ textAlign: "center" }}
                value={"regular"}
                disabled={!editObj.meditmode}
                onClick={(e) =>
                  setTopicData({ ...topicData, ctype: e.target.value })
                }
              >
                Regular
              </button>
            </div>
          </div>
          <div className={prefStyle.outer}>
            <p className={prefStyle.lable}>
              {" "}
              Course Sequence No
              <span style={{ color: "red", marginTop: "-10px" }}>*</span> :
            </p>
            <div className={prefStyle.options}>
              <input
                placeholder="Sequence No"
                className={prefStyle.textbox}
                value={topicData.sqno}
                disabled={!editObj.meditmode}
                onChange={(e) =>
                  setTopicData({ ...topicData, sqno: e.target.value })
                }
                type="number"
                min="0"
              />
            </div>
          </div>
          <div className={prefStyle.outer}>
            <p className={prefStyle.lable}>
              {" "}
              Prerequisites{" "}
              <span style={{ color: "red", marginTop: "-10px" }}>*</span>:
            </p>
            <div className={prefStyle.options}>
              <button
                className={
                  topicData.pre_course === "false" ||
                  topicData.pre_course === false
                    ? prefStyle.classtagCk
                    : prefStyle.classtag
                }
                style={{ textAlign: "center" }}
                value={"true"}
                disabled={!editObj.meditmode}
                onClick={(e) =>
                  setTopicData({ ...topicData, pre_course: e.target.value })
                }
              >
                Yes
              </button>
              <button
                className={
                  topicData.pre_course === "true" ||
                  topicData.pre_course === true
                    ? prefStyle.classtagCk
                    : prefStyle.classtag
                }
                style={{ textAlign: "center" }}
                value={"false"}
                disabled={!editObj.meditmode}
                onClick={(e) =>
                  setTopicData({ ...topicData, pre_course: e.target.value })
                }
              >
                No
              </button>
            </div>
          </div>
          {topicData.pre_course === "true" || topicData.pre_course === true ? (
            <>
              <div className={prefStyle.outer}>
                <p className={prefStyle.lable}>
                  {" "}
                  Prerequisites Type{" "}
                  <span style={{ color: "red", marginTop: "-10px" }}>*</span>:
                </p>
                <div className={prefStyle.options}>
                  <button
                    className={
                      topicData.pre_course_type === ""
                        ? prefStyle.classtagCk
                        : topicData.pre_course_type === "Suggestion"
                        ? prefStyle.classtag
                        : prefStyle.classtagCk
                    }
                    style={{ textAlign: "center" }}
                    value={"Suggestion"}
                    disabled={!editObj.meditmode}
                    onClick={(e) =>
                      setTopicData({
                        ...topicData,
                        pre_course_type: e.target.value,
                      })
                    }
                  >
                    Suggestion
                  </button>
                  <button
                    className={
                      topicData.pre_course_type === ""
                        ? prefStyle.classtagCk
                        : topicData.pre_course_type === "Required"
                        ? prefStyle.classtag
                        : prefStyle.classtagCk
                    }
                    style={{ textAlign: "center" }}
                    value={"Required"}
                    disabled={!editObj.meditmode}
                    onClick={(e) =>
                      setTopicData({
                        ...topicData,
                        pre_course_type: e.target.value,
                      })
                    }
                  >
                    Required
                  </button>
                </div>
              </div>
              <div className={prefStyle.outer}>
                <p className={prefStyle.lable}>
                  {" "}
                  Prerequisites Course{" "}
                  <span style={{ color: "red", marginTop: "-10px" }}>*</span>:
                </p>
                <div className={prefStyle.options}>
                  <FormControl className={classes.formControl}>
                    <Select
                      className={prefStyle.textbox}
                      labelId="demo-mutiple-checkbox-label"
                      id="demo-mutiple-checkbox"
                      multiple
                      value={personName}
                      disabled={!editObj.meditmode}
                      onChange={handleChange}
                      input={<Input />}
                      renderValue={(selected) => {
                        selected.join(", ");
                        let temp = [];
                        for (let i = 0; i < selected?.length; i++) {
                          temp.push(selected[i]);
                        }
                        return temp;
                      }}
                      MenuProps={MenuProps}
                    >
                      {topics?.map(
                        (item) =>
                          item.tenants.length > 0 && (
                            <MenuItem key={item.id} value={item.tn}>
                              <Checkbox
                                checked={personName.indexOf(item.tn) > -1}
                              />
                              <ListItemText primary={item.tn} />
                            </MenuItem>
                          )
                      )}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </>
          ) : null}
          <div className={prefStyle.outer}>
            <p className={prefStyle.lable}>
              {" "}
              Next Course{" "}
              <span style={{ color: "red", marginTop: "-10px" }}>*</span>:
            </p>
            <div className={prefStyle.options}>
              <button
                className={
                  topicData.nextcourse === "false" ||
                  topicData.nextcourse === false
                    ? prefStyle.classtagCk
                    : prefStyle.classtag
                }
                style={{ textAlign: "center" }}
                value={"true"}
                disabled={!editObj.meditmode}
                onClick={(e) =>
                  setTopicData({ ...topicData, nextcourse: e.target.value })
                }
              >
                Yes
              </button>
              <button
                className={
                  topicData.nextcourse === "true" ||
                  topicData.nextcourse === true
                    ? prefStyle.classtagCk
                    : prefStyle.classtag
                }
                style={{ textAlign: "center" }}
                value={"false"}
                disabled={!editObj.meditmode}
                onClick={(e) =>
                  setTopicData({ ...topicData, nextcourse: e.target.value })
                }
              >
                No
              </button>
            </div>
          </div>
          {topicData.nextcourse === "true" || topicData.nextcourse === true ? (
            <div className={prefStyle.outer}>
              <p className={prefStyle.lable}>
                {" "}
                Next Course Name{" "}
                <span style={{ color: "red", marginTop: "-10px" }}>*</span>:
              </p>
              <div className={prefStyle.options}>
                {/* <FormControl className={classes.formControl}>
                  <select
                    className={prefStyle.textbox}
                    value={nextc}
                    disabled={!editObj.meditmode}
                    onChange={handlenextchange}
                  >
                    <option value="">Select a course </option>
                    {topics?.map((item) => item.tenants.length > 0 && (
                      <option key={item.id} value={item.tid}>
                        {item.tn}
                        {console.log(item.tn)}
                      </option>
                    ))}
                  </select>
                </FormControl> */}
                <FormControl className={classes.formControl}>
                  <Select
                    className={prefStyle.textbox}
                    labelId="demo-mutiple-checkbox-label"
                    id="demo-mutiple-checkbox"
                    multiple
                    value={nextc}
                    disabled={!editObj.meditmode}
                    onChange={handlenextchange}
                    input={<Input />}
                    renderValue={(selected) => {
                      selected.join(", ");
                      let temp = [];
                      for (let i = 0; i < selected?.length; i++) {
                        temp.push(selected[i]);
                      }
                      return temp;
                    }}
                    MenuProps={MenuProps}
                  >
                    {topics?.map(
                      (item) =>
                        item.tenants.length > 0 && (
                          <MenuItem key={item.id} value={item.tn}>
                            <Checkbox checked={nextc.indexOf(item.tn) > -1} />
                            <ListItemText primary={item.tn} />
                          </MenuItem>
                        )
                    )}
                  </Select>
                </FormControl>
              </div>
            </div>
          ) : null}
          <div className={prefStyle.outer}>
            <p className={prefStyle.lable}> Add tags:</p>
            <div className={prefStyle.options}>
              <input
                placeholder="Tags"
                className={prefStyle.textbox}
                value={tag}
                disabled={!editObj.meditmode}
                maxLength="30"
                onChange={(e) => setTag(e.target.value)}
              />
              <button
                className={prefStyle.add}
                disabled={!editObj.meditmode}
                onClick={onChangeHandlerTags}
              >
                Add
              </button>
            </div>
          </div>
          <div className={prefStyle.outer}>
            {tagArray?.map((value, id) => {
              return (
                <div className={prefStyle.speakertag} key={id}>
                  <h4 className={prefStyle.speakername}>{value}</h4>
                  {editObj.meditmode && (
                    <Close
                      className={prefStyle.cancelicon}
                      onClick={() => {
                        removeTags(id);
                      }}
                    />
                  )}
                </div>
              );
            })}
          </div>
          {topicData.nav === "false" || topicData.nav === false ? (
            <div className={prefStyle.outer}>
              <p className={prefStyle.lable}>
                {" "}
                Certification{" "}
                <span style={{ color: "red", marginTop: "-10px" }}>*</span> :
              </p>
              <div className={prefStyle.options}>
                <button
                  className={
                    topicData.cert === "false" || topicData.cert === false
                      ? prefStyle.classtagCk
                      : prefStyle.classtag
                  }
                  style={{ textAlign: "center" }}
                  value={"true"}
                  disabled={!editObj.meditmode}
                  onClick={(e) =>
                    setTopicData({
                      ...topicData,
                      cert: e.target.value,
                    })
                  }
                >
                  Yes
                </button>
                <button
                  className={
                    topicData.cert === "true" || topicData.cert === true
                      ? prefStyle.classtagCk
                      : prefStyle.classtag
                  }
                  style={{ textAlign: "center" }}
                  value={"false"}
                  disabled={!editObj.meditmode}
                  onClick={(e) =>
                    setTopicData({
                      ...topicData,
                      cert: e.target.value,
                    })
                  }
                >
                  No
                </button>
              </div>
            </div>
          ) : null}
          {/* {topicData.cert === "true" || topicData.cert === true ? (
            <div className={prefStyle.outer} style={{ display: "block" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  width: "100%",
                }}
              >
                <p className={prefStyle.lable}>
                  {" "}
                  Template{" "}
                  <span style={{ color: "red", marginTop: "-10px" }}>*</span>:
                </p>
                <div className={prefStyle.options}>
                  <input
                    type="file"
                    id="cfiles"
                    disabled={!editObj.meditmode}
                    onChange={changeHandler1}
                    accept="Image/*"
                  />
                  <label for="cfiles">
                    {topicData.tempimage !== undefined
                      ? topicData.tempimage.name
                      : null}
                  </label>
                </div>
              </div>
            </div>
          ) 
          : 
          null} */}
          <div className={prefStyle.outer}>
            <p className={prefStyle.lable}>
              Speaker{" "}
              <span style={{ color: "red", marginTop: "-10px" }}>*</span>:
            </p>
            <div className={prefStyle.options}>
              <select
                id="speaker"
                className={prefStyle.textbox}
                onChange={onChangeHandlerSpeaker}
                disabled={!editObj.meditmode}
                // value={speaker.name}
              >
                <option disabled selected={defaultSpeaker}>
                  Select Speaker
                </option>
                {speakerList?.map((speaker, i) => {
                  return <option value={i}>{speaker.sp_name}</option>;
                })}
              </select>
            </div>
          </div>
          {speakerArray?.length > 0 && (
            <div className={prefStyle.outer}>
              <p className={prefStyle.lable}>Selected Speakers</p>
              <div className={prefStyle.options}>
                <div className={prefStyle.speakerlists}>
                  {speakerList.map((value, id) => {
                    if (speakerArray.includes(value.sp_id)) {
                      return (
                        <div className={prefStyle.speakertag} key={id}>
                          <h4 className={prefStyle.speakername}>
                            {value.sp_name}
                          </h4>
                          {editObj.meditmode && (
                            <Close
                              className={prefStyle.cancelicon}
                              onClick={() => {
                                removeSpeaker(value.sp_id);
                              }}
                            />
                          )}
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          )}
          <div className={prefStyle.outer}>
            <p className={prefStyle.lable}>
              Course Type
              <span style={{ color: "red", marginTop: "-10px" }}>*</span>:
            </p>
            <div className={prefStyle.options}>
              <select
                id="coursetype"
                disabled={!editObj.meditmode}
                className={prefStyle.textbox}
                onChange={(e) => setCourseType(e.target.value)}
                value={courseType}
              >
                <option value="free">Free</option>
                <option value="paid">Paid</option>
              </select>
            </div>
          </div>
          {courseType === "paid" ? (
            <div>
              <div className={prefStyle.outer}>
                <p className={prefStyle.lable}>
                  Prince in INR{" "}
                  <span style={{ color: "red", marginTop: "-10px" }}>*</span>:
                </p>
                <div className={prefStyle.options}>
                  <input
                    placeholder="Price in INR"
                    type="number"
                    className={prefStyle.textbox}
                    disabled={!editObj.meditmode}
                    value={priceininr}
                    min="0"
                    onChange={(e) => setPriceininr(e.target.value)}
                  />
                </div>
              </div>
              <div className={prefStyle.outer}>
                <p className={prefStyle.lable}>Prince in USD</p>
                <div className={prefStyle.options}>
                  <input
                    placeholder="Price in USD"
                    type="number"
                    disabled={!editObj.meditmode}
                    className={prefStyle.textbox}
                    min="0"
                    value={priceinusd}
                    onChange={(e) => setPriceinusd(e.target.value)}
                  />
                </div>
              </div>
              <div className={prefStyle.outer}>
                <p className={prefStyle.lable}>Percentage Discount</p>
                <div className={prefStyle.options}>
                  <input
                    type="number"
                    placeholder="Discount"
                    className={prefStyle.textbox}
                    onChange={onChangeHandlerPerDis}
                    disabled={!editObj.meditmode}
                    value={perDis}
                    min="0"
                  />
                </div>
              </div>
              <div className={prefStyle.outer}>
                <p className={prefStyle.lable}>Price after Discount INR</p>
                <div className={prefStyle.options}>
                  <input
                    placeholder="Price after discount INR"
                    className={prefStyle.textbox}
                    disabled={!editObj.meditmode}
                    value={priceafterDisInr}
                  />
                </div>
              </div>
              <div className={prefStyle.outer}>
                <p className={prefStyle.lable}>Price after Discount USD</p>
                <div className={prefStyle.options}>
                  <input
                    placeholder="Price after discount USD"
                    className={prefStyle.textbox}
                    value={priceafterDisUsd}
                    disabled={!editObj.meditmode}
                  />
                </div>
              </div>
              <div className={prefStyle.outer}>
                <p className={prefStyle.lable}>Early Birds Date</p>
                <div className={prefStyle.options}>
                  <input
                    type="date"
                    className={prefStyle.textbox}
                    disabled={!editObj.meditmode}
                    value={moment(earlyDate).format("YYYY-MM-DD")}
                    onChange={(e) => setEarlyDate(e.target.value)}
                  />
                </div>
              </div>
              <div className={prefStyle.outer}>
                <p className={prefStyle.lable}>Early Birds Price INR</p>
                <div className={prefStyle.options}>
                  <input
                    type="number"
                    className={prefStyle.textbox}
                    value={earlyPriceInr}
                    disabled={!editObj.meditmode}
                    onChange={(e) => setEarlyPriceInr(e.target.value)}
                    min="0"
                  />
                </div>
              </div>
              <div className={prefStyle.outer}>
                <p className={prefStyle.lable}>Early Birds Price USD</p>
                <div className={prefStyle.options}>
                  <input
                    type="number"
                    className={prefStyle.textbox}
                    value={earlyPriceUsd}
                    disabled={!editObj.meditmode}
                    onChange={(e) => setEarlyPriceUsd(e.target.value)}
                    min="0"
                  />
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </Paper>
    </div>
  );
}
