// Dependencies imports
import React from 'react';

// Local imports


// Style imports
import Add from './AddRoles.module.scss';

const AddRoles = (props) => {
    let {handleAddClose} = props;

    return (
        <div className={Add.mainpopup}>
            <h3 className={Add.heading}>Add User Roles</h3>
            <div className={Add.forminput}>
                <input type="text" placeholder="Role Name" className={Add.inputbox} />
                <select className={Add.inputbox}>
                    <option disabled value={null}>Select Tabs</option>
                    <option>Tenants</option>
                    <option>Content</option>
                    <option>Engage</option>
                    <option>Events</option>
                </select>
            </div>
            <div className={Add.savebutton}>
        <button className={Add.save}>Add Role</button>
        <button className={Add.cancel} onClick={handleAddClose}>
          Cancel
        </button>
      </div>
        </div>
    );
};

export default AddRoles;
