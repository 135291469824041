//Dependencies imports
import React, { useState, useEffect } from "react";
import {
  DataGrid,
  GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import config from "../../../config/aws-exports";
import { Constants } from "../../../config/Constants";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../../redux/auth/authSlice";
import { API } from "aws-amplify";
import moment from "moment";
import Backdrop from "@material-ui/core/Backdrop/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Rating from "@mui/material/Rating";
import FeedbackPopUp from "./FeedbackPopUp";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import NothiongHereYet from "../../../assets/Nothing Here Yet.gif";


//Style imports
import Rep from "./Report.module.scss";
import { Button } from "@mui/material";
import { CSVLink } from "react-csv";
import { FileDownload } from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import session from "redux-persist/lib/storage/session";


// Styles for material components
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
    "& .super-app-theme--header": {
      backgroundColor: Constants.blue_color,
      fontSize: "16px",
      color: "rgb(255,255,255)",
      fontFamily: "nunito",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
    cursor: "pointer",
  },
}));


const SessionReport = (props) => {
  let { type, tableData } = props;
  const [value, setValue] = useState("1");
  const [rows, setRows] = useState([]);
  const [rows1, setRows1] = useState([]);
  const [frows, setFrows] = useState([]);
  const [prows, setProws] = useState([]);
  const [trows, setTrows] = useState([]);
  const [srows, setSrows] = useState([]);
  const [fcrows, setFCrows] = useState([]);
  const [pcrows, setPCrows] = useState([]);
  const [tcrows, setTCrows] = useState([]);
  const [scrows, setSCrows] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [eventDate, setEventDate] = useState("");

  const classes = useStyles();
  const [spin, setSpin] = useState(true);

  let userDetails = useSelector(authData);
  console.log({ userDetails });

  useEffect(() => {
    setSpin(false);
    if (type == 'company_details') {
      getCompanyUsers('All');
    }
    if (type == 'revenue') {
      getRevenueDetails('All');
    }
    if (type == 'participant_details') {
      getParticipants('All');
    }
  }, []);

  const headers = [
    { label: "", key: "id" },
    { label: "", key: "name" },
    { label: "", key: "tname" },
    { label: "", key: "coursefee" },
    { label: "", key: "paid" },
  ];

  const companyHeaders = [
    { label: "", key: "id" },
    { label: "", key: "tname" },
    { label: "", key: "sn" },
    { label: "", key: "count" },
  ];

  const revenueHeaders = [
    { label: "", key: "id" },
    { label: "", key: "sn" },
    { label: "", key: "total" },
    { label: "", key: "count" },
  ];

  const userHeaders = [
    { label: "", key: "id" },
    { label: "", key: "name" },
    { label: "", key: "tname" },
    { label: "", key: "sn" },
    { label: "", key: "regdate" },
  ];

  const tcolumns = [
    {
      field: "id",
      headerName: "S.No.",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "tname",
      headerName: "Company",
      flex: 1,
      headerClassName: "super-app-theme--header",
      disableExport: true,
      minWidth: 150,
    },
    {
      field: "sn",
      headerName: "Course",
      flex: 1,
      headerClassName: "super-app-theme--header",
      minWidth: 250,
    },
    {
      field: "count",
      headerName: "No. of Participants",
      flex: 1,
      headerClassName: "super-app-theme--header",
      minWidth: 100,
    },
  ];

  const pcolumns = [
    {
      field: "id",
      headerName: "S.No.",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "name",
      headerName: "Participant",
      flex: 1,
      headerClassName: "super-app-theme--header",
      disableExport: true,
      minWidth: 150,
    },
    {
      field: "tname",
      headerName: "Company",
      flex: 1,
      headerClassName: "super-app-theme--header",
      minWidth: 250,
    },
    {
      field: "sn",
      headerName: "Course",
      flex: 1,
      headerClassName: "super-app-theme--header",
      minWidth: 100,
    },
    {
      field: "regdate",
      headerName: "Date",
      flex: 1,
      headerClassName: "super-app-theme--header",
      minWidth: 100,
    },
  ];

  const revcolumns = [
    {
      field: "id",
      headerName: "S.No.",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "sn",
      headerName: "Course",
      flex: 1,
      headerClassName: "super-app-theme--header",
      disableExport: true,
      minWidth: 250,
    },
    {
      field: "total",
      headerName: "Revenue",
      flex: 1,
      headerClassName: "super-app-theme--header",
      minWidth: 150,
    },
    {
      field: "count",
      headerName: "No. of Participants",
      flex: 1,
      headerClassName: "super-app-theme--header",
      minWidth: 150,
    },
  ];

  const scolumns = [
    {
      field: "id",
      headerName: "S.No.",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "name",
      headerName: "Participant Name",
      flex: 1,
      headerClassName: "super-app-theme--header",
      disableExport: true,
      minWidth: 250,
    },
    {
      field: "tname",
      headerName: "Company",
      flex: 1,
      headerClassName: "super-app-theme--header",
      minWidth: 150,
    },
    {
      field: "coursefee",
      headerName: "Course Fee",
      flex: 1,
      headerClassName: "super-app-theme--header",
      minWidth: 150,
    },
    {
      field: "paid",
      headerName: "Paid",
      flex: 1,
      headerClassName: "super-app-theme--header",
      minWidth: 150,
    },
  ];
  //Getting Company reports
  async function getCompanyUsers(val) {

    var startdate, enddate;
    startdate = new Date()
    //startdate = new Date(startdate).getTime();

    const today = new Date(); // Get the current date
    const thirtyDaysAgo = new Date(); // Create a new date object
    if (val == 'onemonth') {
      thirtyDaysAgo.setDate(today.getDate() - 30); // Subtract 30 days from the current date
    }
    if (val == 'threemonth') {
      thirtyDaysAgo.setDate(today.getDate() - 90);
    }
    if (val == 'sixmonth') {
      thirtyDaysAgo.setDate(today.getDate() - 180);
    }
    if (val == 'year') {
      thirtyDaysAgo.setDate(today.getDate() - 365);
    }
    enddate = new Date(thirtyDaysAgo); // A date object for March 22, 2023, 12:00:00 local time
    enddate = enddate.getTime();
    startdate = startdate.getTime();

    setSpin(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        startdate,
        enddate,
        schema: Constants.SCHEMA_NAME,
        action: val,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    console.log(JSON.stringify(bodyParam.body));
    try {
      const reg = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_COMPANY_REPORT,
        bodyParam
      );

      const regList = [...reg.body];
      setFCrows(reg.body);
      regList.splice(0, 1);
      regList.splice(0, 1);
      setFrows(regList);
      setSpin(false);
    } catch (error) {
      console.error(error);
      setSpin(false);
    }
  }
  async function getParticipants(val) {

    var startdate, enddate;
    startdate = new Date();

    const today = new Date(); // Get the current date
    const thirtyDaysAgo = new Date(); // Create a new date object
    if (val == 'onemonth') {
      thirtyDaysAgo.setDate(today.getDate() - 30); // Subtract 30 days from the current date
    }
    if (val == 'threemonth') {
      thirtyDaysAgo.setDate(today.getDate() - 90);
    }
    if (val == 'sixmonth') {
      thirtyDaysAgo.setDate(today.getDate() - 180);
    }
    if (val == 'year') {
      thirtyDaysAgo.setDate(today.getDate() - 365);
    }
    enddate = new Date(thirtyDaysAgo); // A date object for March 22, 2023, 12:00:00 local time
    enddate = enddate.getTime();
    startdate = startdate.getTime();

    setSpin(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        schema: Constants.SCHEMA_NAME,
        startdate,
        enddate,
        action: val,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    console.log(JSON.stringify(bodyParam.body));
    try {
      const reg = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_PARTICIPANT_REPORT,
        bodyParam
      );

      const regList = [...reg.body];
      regList.splice(0, 1);
      regList.splice(0, 1);

      setProws(regList);
      setPCrows(reg.body);
      setSpin(false);
    } catch (error) {
      console.error(error);
      setSpin(false);
    }
  }
  async function getRevenueDetails(val) {

    var startdate, enddate;
    startdate = new Date();
    //startdate = new Date(startdate).getTime();

    const today = new Date(); // Get the current date
    const thirtyDaysAgo = new Date(); // Create a new date object
    if (val == 'onemonth') {
      thirtyDaysAgo.setDate(today.getDate() - 30); // Subtract 30 days from the current date
    }
    if (val == 'threemonth') {
      thirtyDaysAgo.setDate(today.getDate() - 90);
    }
    if (val == 'sixmonth') {
      thirtyDaysAgo.setDate(today.getDate() - 180);
    }
    if (val == 'year') {
      thirtyDaysAgo.setDate(today.getDate() - 365);
    }
    enddate = new Date(thirtyDaysAgo); // A date object for March 22, 2023, 12:00:00 local time
    enddate = enddate.getTime();
    startdate = startdate.getTime();

    setSpin(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        startdate,
        enddate,
        schema: Constants.SCHEMA_NAME,
        action: val,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    console.log(JSON.stringify(bodyParam.body));
    try {
      const reg = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_REVENUE_REPORT,
        bodyParam
      );

      const regList = [...reg.body];
      if (regList.length > 0) {
        for (let i = 0; i < regList.length; i++) {
          if (regList[i].total == null) {
            regList[i].total = 0;
          }
        }
      }
      regList.splice(0, 1);
      regList.splice(0, 1);

      setTrows(regList);
      setTCrows(reg.body);
      setSpin(false);
    } catch (error) {
      console.error(error);
      setSpin(false);
    }
  }
  async function getSessionDetails(val) {
    console.log(JSON.stringify(val));
    setSpin(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        sid: val.sid,
        sname: val.sn,
        date: eventDate,
        schema: Constants.SCHEMA_NAME,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    console.log(JSON.stringify(bodyParam.body));
    try {
      const reg = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_SESSION_REPORT,
        bodyParam
      );

      const regList = [...reg.body];
      regList.splice(0, 1);
      regList.splice(0, 1);
      regList.splice(0, 1);
      if (regList.length > 0) {
        for (let i = 0; i < regList.length; i++) {
          if (regList[i].paid == null) {
            regList[i].paid = 0;
          }
          if (regList[i].coursefee == null) {
            regList[i].coursefee = 0;
          }
          regList[i].name = regList[i].name['firstname'] + " " + regList[i].name['lastname'];
          regList[i].tname = regList[i].tname == null ? 'Default' : regList[i].tname;
        }
      }
      setSrows(regList);
      setSCrows(reg.body);
      setSpin(false);
    } catch (error) {
      console.error(error);
      setSpin(false);
    }
  }
  function handleSelect(val) {

    if (type == 'company_details') {
      getCompanyUsers(val);
    } else if (type == 'participant_details') {
      getParticipants(val);
    } else if (type == 'revenue') {
      getRevenueDetails(val);
    } else if (type == 'session') {
      getSessionDetails(val);
    }
  }

  function sessionFinanceDate(data) {

    if (
      data?.date_list &&
      (data?.date_list?.length === 0 || data?.date_list[0].date === "")
    ) {
      return "yet to be added.";
    }

    if (data?.startdate && data?.enddate) {
      return `${moment(parseInt(data?.startdate)).format("DD-MM-YYYY")} [${moment(
        parseInt(data?.startdate)
      ).format("LT")} to ${moment(parseInt(data?.enddate)).format("LT")}]`;
    }

    if (
      data?.date_list &&
      data?.date_list?.length > 0 &&
      data?.date_list[0].date !== ""
    ) {
      return `${moment(
        new Date(data?.date_list[0]?.combineStartTime).getTime()
      ).format("DD-MM-YYYY")} 
      to ${moment(
        new Date(
          data?.date_list[data?.date_list?.length - 1]?.combineEndTime
        ).getTime()
      ).format("DD-MM-YYYY")}
      `;
    }
    if (!data?.date_list || !data?.startdate) {
      return "yet to be added.";
    }
  }
  const onChangeHandlerEventDate = (event) => {
    setEventDate(event.target.value);
    setSessions([]);
    getSessions(event.target.value);
  };
  async function getSessions(val) {
    setSpin(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        date: val,
        schema: Constants.SCHEMA_NAME,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    console.log(JSON.stringify(bodyParam.body));
    try {
      const reg = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_SESSION_ONDATE,
        bodyParam
      );

      const regList = reg.body;
      setSessions(regList);
      setSpin(false);
    } catch (error) {
      console.error(error);
      setSpin(false);
    }
  }
  const beforeHeaderRows = [
    { label: "Ttle", key: "1" },
  ];
  const CustomExportButton = () => {
    return (
      <Button color="primary">
        <CSVLink
          data={type == 'company_details' ? fcrows : type == 'revenue' ? tcrows : type == 'participant_details' ? pcrows : scrows}
          headers={type == 'company_details' ? companyHeaders : type == 'revenue' ? revenueHeaders : type == 'participant_details' ? userHeaders : headers}
          filename={type == 'company_details' ? 'Company Details' : type == 'revenue' ? 'Revenue' : type == 'participant_details' ? 'Participant Details' : 'Session Report'}
          className={Rep.exp_btn}
          beforeTitle={beforeHeaderRows}
        >
          <FileDownload />
          Export
        </CSVLink>
      </Button>

    );
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <CustomExportButton />
      </GridToolbarContainer>
    );
  }

  return (
    <div className={Rep.main}>

      {/* feedback end */}
      <Backdrop sx={{ color: "#fff" }} open={spin} className={classes.backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {type != 'session' ? (<div className={Rep.financemain} style={{ padding: '0 0 10px 0' }}>
        <h2 className={Rep.sessionlabel}>Select Period: </h2>
        <select
          className={Rep.sessionSelect}
          onChange={(e) => {
            handleSelect(e.target.value);
          }}
        >
          <option value="Select" disabled selected>
            Select Period
          </option>
          <option value="onemonth">1 Month</option>
          <option value="threemonth">3 Months</option>
          <option value="sixmonth">6 Months</option>
          <option value="year">1 Year</option>
        </select>
      </div>) :


        <div className={Rep.financemain}>
          <h2 className={Rep.sessionlabel}>Select Date: </h2>
          <input
            type="date"
            // placeholder="Select Date"
            className={Rep.sessionSelect}
            onChange={onChangeHandlerEventDate}
            value={eventDate}
          />
          <h2 className={Rep.sessionlabel}>Select Session: </h2>
          <select
            className={Rep.sessionSelect}
            onChange={(e) => {
              handleSelect(sessions[e.target.value]);
            }}
          >
            <option value="Select" disabled selected>
              Select Session
            </option>
            {sessions?.map((item, i) => {
              return (
                <option value={i} key={item + i + ""}>
                  {item.sn}: {sessionFinanceDate(item)}
                </option>
              );
            })}
          </select>
        </div>
      }
      {type == 'company_details' ? (<div>
        {frows.length === 0 ? (
          <div
            style={{
              height: "60vh",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              marginBottom: "80px",
            }}
          >
            <img src={NothiongHereYet} alt="Nothing Here" />
          </div>
        ) : (
          <div
            style={{ height: 535, width: "100%" }}
            className={classes.root}
          >
            <DataGrid
              rows={frows}
              columns={tcolumns}
              rowHeight={90}
              className={classes[".MuiDataGrid-root"]}
              components={{
                Toolbar: CustomToolbar,
              }}
              disableSelectionOnClick
            />
          </div>
        )}
      </div>) : null}
      {type == 'participant_details' ? (<div>
        {prows.length === 0 ? (
          <div
            style={{
              height: "60vh",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              marginBottom: "80px",
            }}
          >
            <img src={NothiongHereYet} alt="Nothing Here" />
          </div>
        ) : (
          <div
            style={{ height: 535, width: "100%" }}
            className={classes.root}
          >
            <DataGrid
              rows={prows}
              columns={pcolumns}
              rowHeight={90}
              className={classes[".MuiDataGrid-root"]}
              components={{
                Toolbar: CustomToolbar,
              }}
              disableSelectionOnClick
            />
          </div>
        )}
      </div>) : null}
      {type == 'revenue' ? (<div>
        {trows.length === 0 ? (
          <div
            style={{
              height: "60vh",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              marginBottom: "80px",
            }}
          >
            <img src={NothiongHereYet} alt="Nothing Here" />
          </div>
        ) : (
          <div
            style={{ height: 535, width: "100%" }}
            className={classes.root}
          >
            <DataGrid
              rows={trows}
              columns={revcolumns}
              rowHeight={90}
              className={classes[".MuiDataGrid-root"]}
              components={{
                Toolbar: CustomToolbar,
              }}
              disableSelectionOnClick
            />
          </div>
        )}
      </div>) : null}
      {type == 'session' ? (<div>
        {srows.length === 0 ? (
          <div
            style={{
              height: "60vh",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              marginBottom: "80px",
            }}
          >
            <p>There is no data for this session</p>
            <img src={NothiongHereYet} alt="Nothing Here" />
          </div>
        ) : (
          <div
            style={{ height: 535, width: "100%" }}
            className={classes.root}
          >
            <DataGrid
              rows={srows}
              columns={scolumns}
              rowHeight={90}
              className={classes[".MuiDataGrid-root"]}
              components={{
                Toolbar: CustomToolbar,
              }}
              disableSelectionOnClick
            />
          </div>
        )}
      </div>) : null}
    </div>
  );
};

export default SessionReport;
