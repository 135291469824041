import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { API } from "aws-amplify";
import { Constants } from "../../../config/Constants";
import { awsSignIn, authData } from "../../../redux/auth/authSlice";
import CircularProgress from "@material-ui/core/CircularProgress";
import EngTenStyle from "./EngageTenants.module.scss";
import config from "../../../config/aws-exports";
import swal from "@sweetalert/with-react";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function EngageTenant() {
  const [action, setAction] = useState("");
  const [subject, setSubject] = useState("");
  const [bodyMessage, setBodyMessage] = useState("");
  const [url, setUrl] = useState("");
  const [link, setLink] = useState(false);
  const [error1, setError1] = useState(false);
  const [error2, setError2] = useState(false);
  const [error3, setError3] = useState(false);
  const [error4, setError4] = useState(false);
  const [spin, setSpin] = useState(false);
  let userDetails = useSelector(authData);
  const classes = useStyles();

  async function sendMessage() {
    let regex =
      "^(https?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$";
    let result = new RegExp(regex, "i");
    if (action === "" || action === undefined || action === null) {
      setError1(true);
      setError2(false);
      setError3(false);
      setError4(false);
    } else if (subject === "" || subject.replace(/\s/g, "").length === 0) {
      setError1(false);
      setError2(true);
      setError3(false);
      setError4(false);
    } else if (bodyMessage === "" || subject.replace(/\s/g, "").length === 0) {
      setError1(false);
      setError2(false);
      setError3(true);
      setError4(false);
    } else if (link === true && (url === "" || !result.test(url))) {
      setError1(false);
      setError2(false);
      setError3(false);
      setError4(true);
    } else {
      setError3(false);
      setError4(false);
      setSpin(true);
      let arrTenants = [];
      let tenantNameArr = [];
      tenantNameArr.push(userDetails.tenantdata.tenantname);
      arrTenants.push(userDetails.tenantdata.st_id);
      var body1 = {
        atype: 0,
        ntid: 0,
        action: action,
        nttitle: subject,
        ntmsg: bodyMessage,
        tenants: arrTenants,
        tenantName: tenantNameArr,
        ur_id: [],
        oid: config.aws_org_id,
        schema: Constants.SCHEMA_NAME,
      };
      if (link == true) {
        body1.pr_link = url;
      }
      console.log("bb", body1);
      // if (toTenants === true) {
      //     let newListUPD = []
      //     if(mailUserList !== undefined){
      //         for (var i = 0; i < mailUserList.length; i++) {
      //             let obj = {};
      //             if(mailUserList[i].eid !== undefined){
      //             obj.EID = mailUserList[i].eid;
      //             }
      //             if (mailUserList[i].email !== undefined){
      //             obj.EMAILID = mailUserList[i].email;
      //             }
      //             newListUPD.push(obj);
      //         }
      //     }
      //     body1.userslist = newListUPD;
      // } else {
      //     body1.userslist = mailUserList;
      //     body1.oid = config.aws_org_id;
      // }
      // console.log("body", body1)
      // const bodyParam = {
      //     body: body1
      //     ,
      //     headers: {
      //         "Content-Type": "application/json",
      //         Accept: "application/json",
      //     },
      // };
      // try {
      //     console.log(bodyParam.body);
      //     const users = await API.post(
      //         config.aws_cloud_logic_custom_name,
      //         Constants.GET_GROUP_NOTIFICATION,
      //         bodyParam
      //     );
      //     console.log("users", users);
      //     setSelectionModel([]);
      //     swal("Notification sent!")
      //     handleEmailClose();
      //     setSpin(false);
      // }
      // catch (error) {
      //     console.error(error);
      //     swal({ type: "error", text: "Notification not sent to users. Please Contact Production Support Team." })
      // }
      console.log("body", body1);
      const bodyParam = {
        body: body1,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      try {
        console.log(bodyParam.body);
        const users = await API.post(
          config.aws_cloud_logic_custom_name,
          Constants.GET_GROUP_NOTIFICATION,
          bodyParam
        );
        console.log("users", users);
        //setSelectionModel([]);
        swal({
          title: "Success",
          text: "Notification sent successfully!",
          icon: "success",
          dangerMode: false,
          closeOnClickOutside: false,
        }).then((willDelete) => {
          if (willDelete) {
            setSpin(false);
          }
        });
        // handleEmailClose();
        setBodyMessage("");
        setLink("");
        setSubject("");
        setUrl("");
        setLink(true);
        setAction("");

        setSpin(false);
      } catch (error) {
        console.error(error);
        swal({
          title: "Oooops!",
          text: "Soomthing wrong!",
          icon: "warning",
          dangerMode: true,
          closeOnClickOutside: false,
        }).then((willDelete) => {
          if (willDelete) {
            setSpin(false);
          }
        });
      }
    }
  }

  function handleChange(e) {
    setAction(e.target.value);
  }

  function clicking(e) {
    if (e.target.value === "true") {
      setLink(!link);
    }
  }

  return (
    <div className={EngTenStyle.container} style={{ width: "80%" }}>
      <Backdrop className={classes.backdrop} open={spin}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <lable className={EngTenStyle.caution}>
        {error1 === true ? "Please select one" : ""}
      </lable>
      <div className={EngTenStyle.radioholder}>
        <div className={EngTenStyle.inholder}>
          <input
            type="radio"
            value="MAIL"
            name="type-select"
            className={EngTenStyle.input}
            onChange={(e) => {
              handleChange(e);
            }}
          ></input>
          <span for="mail" className={EngTenStyle.radiolable}>
            MAIL
          </span>
        </div>
        <div className={EngTenStyle.inholder}>
          <input
            type="radio"
            value="NOTIFICATION"
            name="type-select"
            className={EngTenStyle.input}
            onChange={(e) => {
              handleChange(e);
            }}
          ></input>
          <span className={EngTenStyle.radiolable} for="notification">
            NOTIFICATION
          </span>
        </div>
        <div className={EngTenStyle.inholder}>
          <input
            type="radio"
            value="BOTH"
            name="type-select"
            className={EngTenStyle.input}
            onChange={(e) => {
              handleChange(e);
            }}
          ></input>
          <span className={EngTenStyle.radiolable} for="both">
            BOTH
          </span>
        </div>
      </div>
      <div className={EngTenStyle.inputholder}>
        <lable className={EngTenStyle.caution}>
          {error2 == true ? "Please enter title" : ""}
        </lable>
        <div className={EngTenStyle.inputblock}>
          <div className={EngTenStyle.labelholder}>
            <label for="sessiondate" className={EngTenStyle.label} required>
              Title
              <span style={{ color: "red", marginTop: "-10px" }}>*</span>
            </label>
          </div>

          <div className={EngTenStyle.inputholder}>
            <input
              type="text"
              className={EngTenStyle.inputfield}
              value={subject}
              onChange={(e) => {
                setSubject(e.target.value);
              }}
            ></input>
          </div>
        </div>
      </div>
      <div className={EngTenStyle.inputholder}>
        <lable className={EngTenStyle.caution}>
          {error3 == true ? "Please enter text" : ""}
        </lable>
        <div className={EngTenStyle.inputblock}>
          <div className={EngTenStyle.labelholder}>
            <label for="sessiondate" className={EngTenStyle.label} required>
              Description
              <span style={{ color: "red", marginTop: "-10px" }}>*</span>
            </label>
          </div>

          <div className={EngTenStyle.inputholder}>
            <textarea
              type="text"
              rows={6}
              value={bodyMessage}
              style={{ width: "100%", fontFamily: "sans-serif" }}
              className={EngTenStyle.textarea}
              onChange={(e) => {
                setBodyMessage(e.target.value);
              }}
            ></textarea>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "65%",
          fontSize: "12px",
          fontWeight: "400",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "5px",
          marginLeft: "20%",
        }}
      >
        <input type="checkbox" value={"true"} onClick={(e) => clicking(e)} />
        <lable>Include link</lable>
      </div>
      {link ? (
        <div className={EngTenStyle.inputholder}>
          <lable className={EngTenStyle.caution}>
            {error4 == true ? "Please include valid url" : ""}
          </lable>
          <div className={EngTenStyle.inputblock}>
            <div className={EngTenStyle.labelholder}>
              <label for="sessiondate" className={EngTenStyle.label} required>
                Url
                <span style={{ color: "red", marginTop: "-10px" }}>*</span>
              </label>
            </div>
            <div className={EngTenStyle.inputholder}>
              <input
                type="text"
                id="linkbox"
                className={EngTenStyle.inputfield}
                value={url}
                onChange={(e) => {
                  setUrl(e.target.value);
                }}
              ></input>
            </div>
          </div>
        </div>
      ) : null}
      <div className={EngTenStyle.buttonholder}>
        <button className={EngTenStyle.submit} onClick={() => sendMessage()}>
          Submit
        </button>
      </div>
    </div>
  );
}
