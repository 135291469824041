import React, {useState, useEffect} from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


const TopCoursesChart = (props) => {
    const {courses} = props;
    const [labelDates, setLabelDates] = useState([]);
    const [gpData, setGpData] = useState([]);


    useEffect(() => {
        covertData(courses);
        return () => {};
      }, []);

    function covertData(val){
        let sortArray = val.sort(function(a, b){return a.sub_cnt - b.sub_cnt});
        console.log("sortArray", sortArray);

        let tarray = [];
        if (sortArray.length > 8) {
            tarray = sortArray.slice(Math.max(sortArray.length - 8, 0));
        } else {
            tarray = sortArray;
        }
        let tlabelDates = [];
        let tgpData = [];

        for (let i=0; i< tarray.length; i++) {
            if(tarray[i].sub_cnt !== null && tarray[i].sub_cnt !== 0){
            tlabelDates.push(tarray[i].topicname);
            tgpData.push(tarray[i].sub_cnt);
            }
          }
          setLabelDates(tlabelDates);
          setGpData(tgpData);
          console.log("tlabelDates", tlabelDates);
          console.log("tgpData", )
    };

    const options = {
        hover: {mode: null},
      };
            
      const data = {
        labels: labelDates,
        datasets: [
          {
            label: "",
            data: gpData,
            backgroundColor: 'rgba(9,48,85,255)',
          },
        ],
      };


    return (
        <div style={{ width: "60%", height: "250px", padding: 20 }}>
            <Bar  data={data} height={30} width={80} options={options} />
        </div>
    )
};

export default TopCoursesChart;
