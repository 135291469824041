import react, { useState, useEffect } from "react";
import Radio from "@mui/material/Radio";
import styled from "styled-components";
import DialogContent from "@mui/material/DialogContent";
import { Box } from "@mui/system";
import { Close } from "@mui/icons-material";
import { DialogTitle, IconButton } from "@mui/material";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import BackDrop from "@material-ui/core/Backdrop/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Stack from "@mui/material/Stack";
import { API } from "aws-amplify";
import config from "../../../config/aws-exports";
import { authData } from "../../../redux/auth/authSlice";
import { useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import Feed from './Feedback.module.scss';
import {ReactComponent as CloseIcon} from "../../../assets/icons/close_black_24dp.svg";

export const StyledGrid = styled.div`
  padding: 1.5rem;
  display: grid;
  grid-template-columns: 350px 100px 100px 130px 100px 100px;
  grid-template-rows: auto;
  grid-row-gap: 0.5rem;
  text-align: center;
  overflow-x: auto;
  .led {
    text-align: left;
  }
  .left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
}));

export default function FeedbackForm({ handleFeedBackClose, feedData }) {
  const userDetails = useSelector(authData);
  const classes = useStyles();
  const [spin, setSpin] = useState(true);
  const [response, setResponse] = useState({});
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedValue2, setSelectedValue2] = useState("");
  const [selectedValue3, setSelectedValue3] = useState("");
  const [selectedValue4, setSelectedValue4] = useState("");
  const [selectedValue5, setSelectedValue5] = useState("");
  const [selectedValue6, setSelectedValue6] = useState("");
  const [selectedValue7, setSelectedValue7] = useState("");
  const [selectedValue8, setSelectedValue8] = useState("");
  const [selectedValue9, setSelectedValue9] = useState("");
  const [selectedValue10, setSelectedValue10] = useState("");
  const [selectedValue11, setSelectedValue11] = useState("");
  const [question2, setQuestion2] = useState("");
  const [question3, setQuestion3] = useState("");
  const [question1Desc, setQuestion1Desc] = useState("");
  const [question2Desc, setQuestion2Desc] = useState("");
  const [question3Desc, setQuestion3Desc] = useState("");
  const [question4Desc, setQuestion4Desc] = useState("");
  const [question5Desc, setQuestion5Desc] = useState("");
  const [question6, setQuestion6] = useState("");
  const [question7, setQuestion7] = useState("");
  const [organization, setOrganization] = useState("");
  const [designation, setDesignation] = useState("");
  const [department, setDepartment] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");

  // Useeffect for api call
  useEffect(() => {
    getFeedback();

    return () => {};
  }, []);


  const getFeedback = async () => {
    setSpin(true);
    const bodyParam = {
      body: {
        sid: feedData.sid,
        ur_id: feedData.ur_id,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/getFeedBack",
        bodyParam
      );
      if(response !== undefined && response !== null){
        setResponse(response);
        setSelectedValue(response.body.q1.s1);
        setSelectedValue2(response.body.q1.s2);
        setSelectedValue3(response.body.q1.s3);
        setSelectedValue4(response.body.q1.s4);
        setSelectedValue5(response.body.q1.s5);
        setSelectedValue6(response.body.q4.s1);
        setSelectedValue7(response.body.q4.s2);
        setSelectedValue8(response.body.q4.s3);
        setSelectedValue9(response.body.q4.s4);
        setSelectedValue10(response.body.q5.s1);
        setSelectedValue11(response.body.q5.s2);
        setQuestion2(response.body.q2.s1);
        setQuestion3(response.body.q3.s1);
        setQuestion6(response.body.q6);
        setQuestion7(response.body.q7);
        setQuestion1Desc(response.body.q1.s6);
        setQuestion2Desc(response.body.q2.s2);
        setQuestion3Desc(response.body.q3.s2);
        setQuestion4Desc(response.body.q4.s5);
        setQuestion5Desc(response.body.q5.s3);
        setOrganization(response.body.org);
        setDesignation(response.body.design);
        setDepartment(response.body.depart);
        setName(response.body.uname);
        setEmail(response.body.emailid);
        setContact(response.body.cno == null ? "" : response.body.cno );
        
      }
      setSpin(false);
    } catch (error) {
      console.log("getUserError", error);
      setSpin(false);
    }
  };


  const controlProps = (item) => ({
    checked: selectedValue === item,
    value: item,
    name: "size-radio-button-demo",
    inputProps: { "aria-label": item },
  });
  const controlProps2 = (item) => ({
    checked: selectedValue2 === item,
    value: item,
    name: "size-radio-button-demo-2",
    inputProps: { "aria-label": item },
  });
  const controlProps3 = (item) => ({
    checked: selectedValue3 === item,
    value: item,
    name: "size-radio-button-demo-3",
    inputProps: { "aria-label": item },
  });
  const controlProps4 = (item) => ({
    checked: selectedValue4 === item,
    value: item,
    name: "size-radio-button-demo-4",
    inputProps: { "aria-label": item },
  });
  const controlProps5 = (item) => ({
    checked: selectedValue5 === item,
    value: item,
    name: "size-radio-button-demo-5",
    inputProps: { "aria-label": item },
  });
  const controlProps6 = (item) => ({
    checked: selectedValue6 === item,
    value: item,
    name: "size-radio-button-demo-6",
    inputProps: { "aria-label": item },
  });
  const controlProps7 = (item) => ({
    checked: selectedValue7 === item,
    value: item,
    name: "size-radio-button-demo-7",
    inputProps: { "aria-label": item },
  });
  const controlProps8 = (item) => ({
    checked: selectedValue8 === item,
    value: item,
    name: "size-radio-button-demo-8",
    inputProps: { "aria-label": item },
  });
  const controlProps9 = (item) => ({
    checked: selectedValue9 === item,
    value: item,
    name: "size-radio-button-demo-9",
    inputProps: { "aria-label": item },
  });
  const controlProps10 = (item) => ({
    checked: selectedValue10 === item,
    value: item,
    name: "size-radio-button-demo-10",
    inputProps: { "aria-label": item },
  });
  const controlProps11 = (item) => ({
    checked: selectedValue11 === item,
    value: item,
    name: "size-radio-button-demo-11",
    inputProps: { "aria-label": item },
  });

  // const handleChangeQ2 = (event) => {
  //   setQuestion2(event.target.value);
  // };
  // const handleChangeQ3 = (event) => {
  //   setQuestion3(event.target.value);
  // };
  const values = {
    qi: {
      s1: parseInt(selectedValue),
      s2: parseInt(selectedValue2),
      s3: parseInt(selectedValue3),
      s4: parseInt(selectedValue4),
      s5: parseInt(selectedValue5),
      s6: question1Desc,
    },
    q2: { s1: parseInt(question2), s2: question2Desc },
    q3: { s1: parseInt(question3), s2: question3Desc },
    q4: {
      s1: parseInt(selectedValue6),
      s2: parseInt(selectedValue7),
      s3: parseInt(selectedValue8),
      s4: parseInt(selectedValue9),
      s6: question4Desc,
    },
    q5: {
      s1: parseInt(selectedValue10),
      s2: parseInt(selectedValue11),
      s6: question5Desc,
    },
    q6: question6,
    q7: question7,
    org: organization,
    design: designation,
    depart: department,
  };

  return (
    <>
      <div className={Feed.closeholder}>

        <CloseIcon className={Feed.closeicon} onClick={() => handleFeedBackClose()} />
      </div>
      <BackDrop sx={{ color: "#fff" }} open={spin} className={classes.backdrop}>
        <CircularProgress color="inherit" />
      </BackDrop>
      <DialogContent>
        <p>
          Q1.Please indicate the extent to which you agree or disagree with each
          statement below.Please select an answer for each statement.
        </p>
        <StyledGrid>
          <p></p>
          <p>Strongly disagree</p>
          <p>Disagree</p>
          <p>Neither disagree nor agree</p>
          <p>Agree</p>
          <p>Strongly agree</p>
          <p className="led">
            Knowledge receiver from the course will positively impact the
            quality of my work
          </p>
          {["0", "1", "2", "3", "4"].map((item) => (
            <p>
              <Radio {...controlProps(item)} />
            </p>
          ))}
          <p className="left">
            <p>Exercises and activities were useful</p>
          </p>
          {["0", "1", "2", "3", "4"].map((item) => (
            <p>
              <Radio {...controlProps2(item)} />
            </p>
          ))}
          <p className="left">
            <p>Training materials / presentation were useful</p>
          </p>
          {["0", "1", "2", "3", "4"].map((item, id) => (
            <p>
              <Radio {...controlProps3(item, id)} />
            </p>
          ))}

          <p className="left">
            <p>I am satisfied with this course overall</p>
          </p>
          {["0", "1", "2", "3", "4"].map((item, id) => (
            <p>
              <Radio {...controlProps4(item, id)} />
            </p>
          ))}
          <p className="led">
            There are others in my organization who would benefit from this USP
            Education course
          </p>
          {["0", "1", "2", "3", "4"].map((item, id) => (
            <p>
              <Radio {...controlProps5(item, id)} />
            </p>
          ))}
        </StyledGrid>
        <Box sx={{ pl: 3, mb: 1.5 }}>
          <p>Additional comments (optional)</p>
          <Box
            component="textarea"
            sx={{ width: "100%", resize: "none", p: 1 }}
            value={question1Desc}
          />
        </Box>
        <p>Q2. Regarding the course content, please choose one</p>

        <FormControl component="fieldset">
          <RadioGroup
            aria-label="gender"
            defaultValue=""
            name="radio-buttons-group-234"
            value={question2}
          >
            <FormControlLabel
              value="0"
              control={<Radio />}
              label="The course content was appropriate for my level of knowledge."
              style={{padding:'10px',marginLeft:'10px'}}
            />
            <FormControlLabel
              value="1"
              control={<Radio />}
              style={{padding:'10px',marginLeft:'10px'}}
              label="The course content was too elementary."
            />
            <FormControlLabel
              value="2"
              control={<Radio />}
              style={{padding:'10px',marginLeft:'10px'}}
              label="The pace of the course was too fast"
            />
          </RadioGroup>
        </FormControl>
        <Box sx={{ pl: 3 }}>
          <p>Additional comments (optional)</p>
          <Box
            component="textarea"
            sx={{ width: "100%", resize: "none", p: 1 }}
            value={question2Desc}
          />
        </Box>
        <p>Q3. Regarding the pace of the course. please choose one</p>

        <FormControl component="fieldset">
          <RadioGroup
            aria-label="pace"
            defaultValue=""
            name="radio-buttons-group-2345"
            value={question3}
          >
            <FormControlLabel
              value="0"
              control={<Radio />}
              style={{padding:'10px',marginLeft:'10px'}}
              label="I am satisfied with the pace of the course."
            />
            <FormControlLabel
              value="1"
              control={<Radio />}
              style={{padding:'10px',marginLeft:'10px'}}
              label="The pace of the was too slow."
            />
            <FormControlLabel
              value="2"
              control={<Radio />}
              style={{padding:'10px',marginLeft:'10px'}}
              label="The pace of the course was too fast."
            />
          </RadioGroup>
        </FormControl>
        <Box sx={{ pl: 3, mb: 1.5 }}>
          <p>Additional comments (optional)</p>
          <Box
            component="textarea"
            sx={{ width: "100%", resize: "none", p: 1 }}
            value={question3Desc}
          />
        </Box>

        <p style={{ marginTop: "2rem" }}>
          Q4.Please indicate the extent to which you agree or disagree with each
          statement below about the instructor.
        </p>
        <StyledGrid>
          <p></p>
          <p>Strongly disagree</p>
          <p>Disagree</p>
          <p>Neither disagree nor agree</p>
          <p>Agree</p>
          <p>Strongly agree</p>

          <p className="led">
            <p>I am satisfied with the instructor overall</p>
          </p>
          {["0", "1", "2", "3", "4"].map((item) => (
            <p>
              <Radio {...controlProps6(item)} />
            </p>
          ))}
          <p className="led">
            <p>The instructor was knowledgeable about the subject matter</p>
          </p>
          {["0", "1", "2", "3", "4"].map((item) => (
            <p>
              <Radio {...controlProps7(item)} />
            </p>
          ))}
          <p className="led">
            <p>The instructor encouraged question and participation</p>
          </p>
          {["0", "1", "2", "3", "4"].map((item) => (
            <p>
              <Radio {...controlProps8(item)} />
            </p>
          ))}
          <p className="left">
            <p>The instructor explained concepts clearly</p>
          </p>
          {["0", "1", "2", "3", "4"].map((item) => (
            <p>
              <Radio {...controlProps9(item)} />
            </p>
          ))}
        </StyledGrid>
        <Box sx={{ pl: 3, mb: 1.5 }}>
          <p>Additional comments (optional)</p>
          <Box
            component="textarea"
            sx={{ width: "100%", resize: "none", p: 1 }}
            value={question4Desc}
          />
        </Box>
        <p style={{ marginTop: "2rem" }}>
          Q5. Please indicate the extent to which you agree or disagree with
          each statement below about Logistics / Organization.
        </p>
        <StyledGrid>
          <p></p>
          <p>Strongly disagree</p>
          <p>Disagree</p>
          <p>Neither disagree nor agree</p>
          <p>Agree</p>
          <p>Strongly agree</p>

          <p className="led">
            <p>The registration process was smooth and effective</p>
          </p>
          {["0", "1", "2", "3", "4"].map((item) => (
            <p>
              <Radio {...controlProps10(item)} />
            </p>
          ))}
          <p className="led">
            <p>The webex tool were conducive to learning</p>
          </p>
          {["0", "1", "2", "3", "4"].map((item) => (
            <p>
              <Radio {...controlProps11(item)} />
            </p>
          ))}
        </StyledGrid>
        <Box sx={{ pl: 3, mb: 1.5 }}>
          <p>What did user like the most during the webinar?</p>
          <Box
            component="textarea"
            sx={{ width: "100%", resize: "none", p: 1 }}
            value={question5Desc}
          />
        </Box>
        <p style={{ marginTop: "2rem" }}>
          Q6. Additional topics user would like to see USP Education cover?
        </p>
        <Box sx={{ pl: 3, mb: 1.5 }}>
          <Box
            component="textarea"
            value={question6}
            sx={{ width: "100%", resize: "none", p: 1 }}
          />
        </Box>
        <p style={{ marginTop: "2rem" }}>
          Q7. Comments of suggestions on how USP Education
          could improve its courses.
        </p>
        <Box sx={{ pl: 3, mb: 1.5 }}>
          <Box
            component="textarea"
            value={question7}
            sx={{ width: "100%", resize: "none", p: 1 }}
          />
        </Box>
        {/* <p style={{ marginTop: "2rem" }}>
          Q8. Below are the user details.
        </p>
        <Stack direction="column" spacing={1}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Box sx={{ minWidth: "116px", textAlign: "right" }}>Name </Box>
            <Box>
              <TextField
                id="outlined-basic"
                inputProps={{ readOnly: true }}
                variant="outlined"
                size="small"
                value={name}
                // defaultValue={userDetails && userDetails.name}
              />
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" j spacing={2}>
            <Box sx={{ minWidth: "116px", textAlign: "right" }}>Email </Box>
            <Box>
              <TextField
                id="outlined-basic"
                variant="outlined"
                inputProps={{ readOnly: true }}
                // defaultValue={userDetails && userDetails.email}
                size="small"
                value={email}
              />
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Box sx={{ minWidth: "116px", textAlign: "right" }}>
              Contact Number{" "}
            </Box>
            <Box>
              <TextField
                id="outlined-basic"
                variant="outlined"
                // defaultValue={
                //   userDetails &&
                //   (userDetails.phone_number || userDetails.uData.cno)
                // }
                size="small"
                value={contact}
              />
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Box sx={{ minWidth: "116px", textAlign: "right" }}>
              Organization
            </Box>
            <Box>
              <TextField
                id="outlined-basic"
                variant="outlined"
                size="small"
                value={organization}
              />
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Box sx={{ minWidth: "116px", textAlign: "right" }}>
              Designation
            </Box>
            <Box>
              <TextField
                id="outlined-basic"
                variant="outlined"
                size="small"
                value={designation}
              />
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Box sx={{ minWidth: "116px", textAlign: "right" }}>Department</Box>
            <Box>
              <TextField
                id="outlined-basic"
                variant="outlined"
                size="small"
                value={department}
              />
            </Box>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{ mt: 2 }}
        >
          <button
            // loading
            // disabled
            type="submit"
            onClick={() => handleFeedBackClose()}
            className={Feed.closebutton}
          >
            Close
          </button>
        </Stack> */}
        {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
      </DialogContent>
    </>
  );
}