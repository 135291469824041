import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import { useLocation } from "react-router-dom";

import { Wrapper } from "./sideNav.styles";
import { Link } from "react-router-dom";
import TenantsSvg from "../../assets/svgjs/Tenants";
import ContentSvg from "../../assets/svgjs/Content";
import EventSvg from "../../assets/svgjs/Events";
import DashboardSvg from "../../assets/svgjs/Dashboard";
import EngageSvg from "../../assets/svgjs/Engage";
import Side from "./SideNav.module.scss";
import { display } from "@mui/system";

export default function SideNav() {
  const [active, setactive] = useState("/landingscreen");
  let userDetails = useSelector(authData);
  const location = useLocation();

  useEffect(() => {
    setactive(active);
    return () => {};
  }, []);

  return (
    <Wrapper>
      <ul className="side-nav" style={{ paddingLeft: "0px" }}>
        <Link to="/landingscreen" style={{ textDecoration: "none" }}>
          <li
            className={
              location.pathname.split("/").indexOf("landingscreen") > -1
                ? "side-nav__item side-nav__item--active "
                : "side-nav__item"
            }
            style={{ display: "flex" }}
          >
            <DashboardSvg
              dashicon="icon-size"
              cls1={
                location.pathname.split("/").indexOf("landingscreen") > -1
                  ? Side.dashcls1
                  : Side.dashcls1active
              }
              cls2={
                location.pathname.split("/").indexOf("landingscreen") > -1
                  ? Side.dashcls2
                  : Side.dashcls2active
              }
              cls3={
                location.pathname.split("/").indexOf("landingscreen") > -1
                  ? Side.dashcls3
                  : Side.dashcls3active
              }
              cls4={
                location.pathname.split("/").indexOf("landingscreen") > -1
                  ? Side.dashcls4
                  : Side.dashcls4active
              }
            />
            <span style={{ paddingTop: "8px", paddingLeft: "4px" }}>
              DASHBOARD
            </span>
          </li>
        </Link>
        {userDetails.role === "Admin" ? (
          userDetails.role === "Manager" ? null : (
            <Link to="/organization" style={{ textDecoration: "none" }}>
              <li
                className={
                  location.pathname.split("/").indexOf("organization") > -1
                    ? "side-nav__item side-nav__item--active"
                    : "side-nav__item"
                }
                style={{ display: "flex" }}
              >
                <TenantsSvg
                  className="icon-size"
                  cls1={
                    location.pathname.split("/").indexOf("organization") > -1
                      ? Side.tencls1
                      : Side.tencls1active
                  }
                  cls2={
                    location.pathname.split("/").indexOf("organization") > -1
                      ? Side.tencls2
                      : Side.tencls2active
                  }
                  cls3={
                    location.pathname.split("/").indexOf("organization") > -1
                      ? Side.tencls3
                      : Side.tencls3active
                  }
                  cls4={
                    location.pathname.split("/").indexOf("organization") > -1
                      ? Side.tencls4
                      : Side.tencls4active
                  }
                />
                <span style={{ paddingTop: "8px", paddingLeft: "4px" }}>
                  ORGANIZATIONS
                </span>
              </li>
            </Link>
          )
        ) : null}
        <Link to="/uspcontent" style={{ textDecoration: "none" }}>
          <li
            className={
              location.pathname.split("/").indexOf("uspcontent") > -1
                ? "side-nav__item side-nav__item--active"
                : "side-nav__item"
            }
            style={{ display: "flex" }}
          >
            <ContentSvg
              className="icon-size"
              cls1={
                location.pathname.split("/").indexOf("uspcontent") > -1
                  ? Side.concls1
                  : Side.concls1active
              }
              cls2={
                location.pathname.split("/").indexOf("uspcontent") > -1
                  ? Side.concls2
                  : Side.concls2active
              }
              cls3={
                location.pathname.split("/").indexOf("uspcontent") > -1
                  ? Side.concls3
                  : Side.concls3active
              }
              cls4={
                location.pathname.split("/").indexOf("uspcontent") > -1
                  ? Side.concls4
                  : Side.concls4active
              }
            />
            <span style={{ paddingTop: "8px", paddingLeft: "4px" }}>
              CONTENT
            </span>
          </li>
        </Link>

        {userDetails.role === "Admin" ? (
          userDetails.role === "Manager" ? null : (
            <Link to="/engage" style={{ textDecoration: "none" }}>
              <li
                className={
                  location.pathname.split("/").indexOf("engage") > -1
                    ? "side-nav__item side-nav__item--active"
                    : "side-nav__item"
                }
                style={{ display: "flex" }}
              >
                <EngageSvg
                  className="icon-size"
                  cls1={
                    location.pathname.split("/").indexOf("engage") > -1
                      ? Side.encls1
                      : Side.encls1active
                  }
                  cls2={
                    location.pathname.split("/").indexOf("engage") > -1
                      ? Side.encls2
                      : Side.encls2active
                  }
                  cls3={
                    location.pathname.split("/").indexOf("engage") > -1
                      ? Side.encls3
                      : Side.encls3active
                  }
                  cls4={
                    location.pathname.split("/").indexOf("engage") > -1
                      ? Side.encls4
                      : Side.encls4active
                  }
                />
                <span style={{ paddingTop: "8px", paddingLeft: "4px" }}>
                  ENGAGE
                </span>
              </li>
            </Link>
          )
        ) : null}
        {userDetails.role === "Admin" ? (
          userDetails.role === "Manager" ? null : (
            <Link to="/events" style={{ textDecoration: "none" }}>
              <li
                className={
                  location.pathname.split("/").indexOf("events") > -1
                    ? "side-nav__item side-nav__item--active"
                    : "side-nav__item"
                }
                style={{ display: "flex" }}
              >
                <EventSvg
                  className="icon-size"
                  cls1={
                    location.pathname.split("/").indexOf("events") > -1
                      ? Side.evcls1
                      : Side.evcls1active
                  }
                  cls2={
                    location.pathname.split("/").indexOf("events") > -1
                      ? Side.evcls2
                      : Side.evcls2active
                  }
                  cls3={
                    location.pathname.split("/").indexOf("events") > -1
                      ? Side.evcls3
                      : Side.evcls3active
                  }
                  cls4={
                    location.pathname.split("/").indexOf("events") > -1
                      ? Side.evcls4
                      : Side.evcls4active
                  }
                />
                <span style={{ paddingTop: "8px", paddingLeft: "4px" }}>
                  EVENTS
                </span>
              </li>
            </Link>
          )
        ) : null}
        {userDetails.role === "Manager" ? (
          <Link to="/users" style={{ textDecoration: "none" }}>
            <li
              className={
                location.pathname.split("/").indexOf("users") > -1
                  ? "side-nav__item side-nav__item--active"
                  : "side-nav__item"
              }
              style={{ display: "flex" }}
            >
              <TenantsSvg
                className="icon-size"
                cls1={
                  location.pathname.split("/").indexOf("users") > -1
                    ? Side.tencls1
                    : Side.tencls1active
                }
                cls2={
                  location.pathname.split("/").indexOf("users") > -1
                    ? Side.tencls2
                    : Side.tencls2active
                }
                cls3={
                  location.pathname.split("/").indexOf("users") > -1
                    ? Side.tencls3
                    : Side.tencls3active
                }
                cls4={
                  location.pathname.split("/").indexOf("users") > -1
                    ? Side.tencls4
                    : Side.tencls4active
                }
              />
              <span style={{ paddingTop: "8px", paddingLeft: "4px" }}>
                Users
              </span>
            </li>
          </Link>
        ) : null}
         {userDetails.role === "Admin" ? (
          userDetails.role === "Manager" ? null : (
         <Link to="/report" style={{ textDecoration: "none" }}>
          <li
            className={
              location.pathname.split("/").indexOf("report") > -1
                ? "side-nav__item side-nav__item--active"
                : "side-nav__item"
            }
            style={{ display: "flex" }}
          >
            <ContentSvg
              className="icon-size"
              cls1={
                location.pathname.split("/").indexOf("report") > -1
                  ? Side.concls1
                  : Side.concls1active
              }
              cls2={
                location.pathname.split("/").indexOf("report") > -1
                  ? Side.concls2
                  : Side.concls2active
              }
              cls3={
                location.pathname.split("/").indexOf("report") > -1
                  ? Side.concls3
                  : Side.concls3active
              }
              cls4={
                location.pathname.split("/").indexOf("report") > -1
                  ? Side.concls4
                  : Side.concls4active
              }
            />
            <span style={{ paddingTop: "8px", paddingLeft: "4px" }}>
              REPORTS
            </span>
          </li>
        </Link>
        )
        ) : null}
        {/*    <Link to="/userRole" style={{ textDecoration: "none" }}>
          <li
            className={
              location.pathname.split("/").indexOf("userRole") > -1
                ? "side-nav__item side-nav__item--active"
                : "side-nav__item"
            }
          >
            
            <span>USER ROLE</span>
          </li>
        </Link> */}
      </ul>
    </Wrapper>
  );
}
