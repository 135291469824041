import React, { useState, useRef } from "react";
import ReactPlayer from "react-player";
import config from "../../../config/aws-exports";
import { API } from "aws-amplify";
import { useSelector } from "react-redux";
import { authData, awsSignIn } from "../../../redux/auth/authSlice";
import { Link, useHistory, useLocation } from "react-router-dom";
import objStyle from "./ObjectPlayer.module.scss";
import { useDispatch } from "react-redux";
import { Constants } from "../../../config/Constants";
import QuizView from "./QuizView";
import BackDrop from "@material-ui/core/Backdrop/Backdrop";
import { Button, Dropdown, ModalActions } from "semantic-ui-react";
import Reject from "../../../assets/svgjs/Reject";
import Done from "../../../assets/svgjs/Done";
import useWindowDimensions from "../../../modules/Window/Window";
import { ReactComponent as Close } from "../../../assets/icons/close_black_24dp.svg";

import {
  Fade,
  InputAdornment,
  Backdrop,
  Modal,
  CircularProgress,
  makeStyles,
  TextField,
  Tooltip,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextareaAutosize,
  CardMedia,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "2rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    height: "600px",
    width: "1100px",
    backgroundColor: "white",
    overflowY: "auto",
    overflowX: "hidden",
    padding: "0rem",
    position: "relative",
  },
  paperVideo: {
    height: "610px",
    width: "1100px",
    backgroundColor: "white",
    overflowY: "hidden",
    overflowX: "hidden",
    position: "relative",
  },
  // backdrop: {
  //   zIndex: theme.zIndex.drawer + 1,
  //   color: '#fff',
  // },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "blue",
  },
  quizroot: {
    display: "flex",
    flexWrap: "wrap",
    gap: "1rem",
  },
  bottomIcons: {
    color: "#000",
    "&:hover": {
      color: "#777",
    },
  },
  volumeButton: {
    color: "white",
    fontSize: 40,
    transform: "scale(0.9)",
    "&:hover": {
      color: "white",
      transform: "scale(1.03)",
      transitionTimingFunction: "cubic-bezier(0.1, 0.1, 0.25, 1)",
    },
  },
  responsive: {
    [theme.breakpoints.up(1300)]: {
      fontSize: "1rem",
    },
  },
}));
const StyledButtonQuiz = withStyles({
  root: {
    background: "#e78022",
    borderRadius: 3,
    border: 0,
    color: "white",
    height: 38,
    padding: "0 50px",
    boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .2)",
    marginTop: "3rem",
    fontSize: "16px",
  },
  label: {
    textTransform: "capitalize",
  },
})(Button);
export default function ObjectPlayer(props) {
  let {
    handleClose,
    courseDetails,
    setCourseDetails,
    objectData,
    setObjectData,
    oIndex,
    setOIndex,
    curObject,
    setcurObject,
    curObRef,
  } = props;
  const userDeatils = useSelector(authData);
  const dispatch = useDispatch();
  const [questions, setQuestions] = React.useState([]);
  const [imgUrl, setImgUrl] = React.useState("");
  const [qLoading, setQLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const [value, setValue] = React.useState("female");
  const retake = useRef(false);

  const [qisLoading, setQIsLoading] = useState(false);

  const [quizQuestion, setQuizQuestion] = useState([]);
  const [btnClick, setBtnClick] = useState(false);
  const [qtype, setQtype] = useState("");
  const [answerReveal, setAnswerReveal] = useState(false);
  const [popUp, setPopup] = useState(false);

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [score, setScore] = useState(0);
  const formData = useRef([]);
  const full = useRef(null);
  const [settingnull, setSettingnull] = useState(0);
  const [summative, setSummative] = useState(false);
  const [sumScore, setSumscore] = useState(0);
  const { height, width } = useWindowDimensions();
  const [fullscreen, setFullScreen] = useState(true);
  const [tryAgain, setTryAgain] = useState(false);
  const [formativeAns, setFormativeAns] = useState([]);
  const [isSubmittingg, setSubmittingg] = useState(false);
  const instanceRef = useRef(null);
  const [typesButtons, setTypeButtons] = useState(false);
  const [totalScore, setTotalScore] = useState("");

  const [istLoading, setIstLoading] = useState(false);
  const [assignmentLoad, setAssignmentLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errvalidatte, setErrvalidatte] = useState("");
  const [ansShow, setAnsShow] = useState(false);
  const [error, setError] = useState("");
  const [tryBtnShow, setTryBtnShow] = useState(false);
  const [isSubmited, setIsSubmited] = useState(false);

  let query = useQuery();

  console.log("CurObj", curObject);

  async function getQuiz(obj) {
    const bodyParam = {
      body: {
        quizid: obj.objid,
        action: "get",
        oid: config.aws_org_id,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        `/getQuiz`,
        bodyParam
      );

      const { qitems, qtype } = response;
      console.log("res", response);
      console.log("qitems", qitems);
      console.log("qtype", qtype);
      setQtype(qtype);
      setQuizQuestion(qitems);
      setBtnClick(true);
      /*  if (qtype == 1 || qtype == "1") {
        // getQuizScore();
      } else {
        setLoading(false);
      } */
      retake.current = false;
      setTryBtnShow(false);
      setLoading(false);
    } catch (error) {
      // alert(JSON.stringify(error));
      console.error(error);
    }
  }

  function renderObjects() {
    //alert(JSON.stringify(curObject));

    let objType = curObject.otype;

    switch (objType) {
      case "video":
        return VideoView(); // loadVideoView(curObject);
      case "audio":
        return AudioView(); // loadAudioView(curObject);
      case "pdf":
        return PdfView(); // loadMultimediaView(curObject);
      case "html":
        return WebView(); // loadMultimediaView(curObject);
      case "interactivity":
        return WebView(); // loadInteractivityView(curObject);
      case "Interactivity":
        return WebView();
      case "quiz":
        return quizView();
      case "vimeo":
        return VimeoView(); // loadVimeoView(curObject);
      case "youtube":
        return youtubeView(); // loadYoutubeView(curObject);
      default:
        return null;
    }
  }

  function VideoView() {
    return (
      <div
        className="player-wrapper"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <ReactPlayer
          url={curObject.url}
          controls={true}
          width="100%"
          height={300}
          config={{
            file: {
              attributes: {
                controlsList: "nodownload", //<- this is the important bit
              },
            },
          }}
        />
      </div>
    );
  }

  function AudioView() {
    return (
      <div>
        <div
          className="player-wrapper"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <ReactPlayer
            url={curObject.url}
            controls={true}
            width="calc(100% - 15%)"
            height={300}
          />
        </div>
      </div>
    );
  }

  function VimeoView() {
    return (
      <div>
        <div
          className="player-wrapper"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <ReactPlayer
            url={`https://player.vimeo.com/video/${curObject.ourl}?title=0&loop=0`}
            controls={true}
            width="calc(100% - 15%)"
            height="calc(100% - 20px)"
          />
        </div>
      </div>
    );
  }

  function youtubeView() {
    return (
      <div>
        <div
          className="player-wrapper"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <ReactPlayer
            url={`https://youtu.be/${curObject.ourl}`}
            controls={true}
            width="calc(100% - 15%)"
            height="calc(100% - 20px)"
          />
        </div>
      </div>
    );
  }

  function PdfView() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          height: 300,
          overflowX: "hidden",
        }}
      >
        <iframe
          src={curObject.url}
          title="pdf"
          height="300"
          width="100%"
        ></iframe>
        {/* <Document file={curObject.curl} onLoadSuccess={onDocumentLoadSuccess}>
              <Page pageNumber={pageNumber} />
            </Document> */}
        {/* <p>Page {pageNumber} of {numPages}</p> */}
      </div>
    );
  }

  function WebView() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          height: 300,
          overflowX: "hidden",
        }}
      >
        <iframe
          src={curObject.url}
          title="pdf"
          height="300"
          width="100%"
        ></iframe>
        {/* <Document file={curObject.curl} onLoadSuccess={onDocumentLoadSuccess}>
              <Page pageNumber={pageNumber} />
            </Document> */}
        {/* <p>Page {pageNumber} of {numPages}</p> */}
      </div>
    );
  }

  function validate() {
    console.log(JSON.stringify(quizQuestion));
    setErrvalidatte("");
    for (let i = 0; i < quizQuestion.length; i++) {
      if (quizQuestion[i].value == undefined) {
        setErrvalidatte("Please answer all the below questions");
        return;
      }
      if (i == quizQuestion.length - 1) {
        setAnsShow(true);
      }
    }
  }
  const handleChange = (event) => {
    console.log(event.target);

    console.log(event.target.value);
    let temp_state = [...quizQuestion];

    // 2. Make a shallow copy of the element you want to mutate
    let temp_element = { ...temp_state[event.target.name] };

    // 3. Update the property you're interested in
    temp_element.value = event.target.value;

    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    temp_state[event.target.name] = temp_element;
    console.log(";;;", JSON.stringify(temp_state));
    // 5. Set the state to our new copy
    setQuizQuestion(temp_state);
    setValue(event.target.value);
  };

  function handleQuizSubmit() {
    if (isSubmited == true) {
      setShowScore(true);
    } else {
      let totalscore = 0;
      setError("");
      console.log("==", quizQuestion);
      for (var j = 0; j < quizQuestion.length; j++) {
        if (quizQuestion[j].value == undefined) {
          setError("Some questions are not answered , Please answer");
          return;
        } else if (quizQuestion[j].iopts !== undefined) {
          for (var z = 0; z < quizQuestion[j].iopts.length; z++) {
            if (
              quizQuestion[j].iopts[z].content == quizQuestion[j].value &&
              quizQuestion[j].iopts[z].correct == true
            ) {
              totalscore++;
            }
          }
        }

        if (j == quizQuestion.length - 1) {
          console.log("totalscore==" + totalscore);
          let objsr = {};
          objsr.oid = query.get("id");
          var date = new Date();
          var year = date.getFullYear();
          var month = date.getMonth() + 1;
          var date1 = date.getDate();
          var sdate = date1 + "." + month + "." + year;
          // objsr.name = curObject.otitle;
          objsr.cdate = sdate;
          objsr.score = totalscore;
          objsr.tquestions = quizQuestion.length;

          // setScoreJson(objsr);
          console.log("persent===" + (totalscore / quizQuestion.length) * 100);
          // setPerScore((totalscore / quizQuestion.length) * 100);
          setTotalScore(totalscore);
          if ((totalscore / quizQuestion.length) * 100 >= 75) {
            console.log(
              "1persss===" + (totalscore / quizQuestion.length) * 100
            );
            setShowScore(true);
            // setMarkComplete(true);
            setTryBtnShow(false);
            //handleScoreSubmit(objsr);
            // syncUserProgresswhilequiz();
          } else {
            console.log(
              "persss222===" + (totalscore / quizQuestion.length) * 100
            );
            //handleScoreSubmit(objsr);
            // setMarkComplete(true);
            setShowScore(true);
            setTryBtnShow(true);
          }
          // setTimerPalyPause("pause");
        }
      }
    }
  }
  function quizView() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "63vh",
          gap: "2rem",
          overflow: "auto",
          paddingTop: "50px",
          paddingLeft: "50px",
        }}
      >
        {btnClick ? (
          <>
            {showScore ? (
              <>
                {" "}
                <div>
                  <div>
                    <h3
                      style={{
                        textTransform: "capitalize",
                        textAlign: "center",
                        marginBottom: "auto",
                        fontWeight: "600",
                        marginBottom: "4rem",
                      }}
                    >
                      {curObject.title}
                    </h3>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <div
                        style={{
                          padding: "24px",
                          // border: "1px solid #777",
                          width: "180px",
                          height: "155px",
                          borderRadius: "8px",
                          boxShadow: "1px 2px 2px 2px #f1f5f8",
                          backgroundColor: "#f1f5f8",
                          textAlign: "center",
                          border: tryBtnShow
                            ? "1px solid red"
                            : "1px solid green",
                        }}
                        className="flex-center"
                      >
                        <h3
                          style={{
                            textTransform: "capitalize",
                            color: "#555555",
                            marginBottom: "1rem",
                          }}
                        >
                          Your score
                        </h3>
                        <h2 style={{ textTransform: "capitalize" }}>
                          {totalScore}
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div>
                    {tryBtnShow ? (
                      <>
                        <Typography
                          variant="h5"
                          style={{
                            textTransform: "capitalize",
                            textAlign: "center",
                            paddingTop: "20px",
                          }}
                        >
                          We're sorry, but you did not achieve a passing score
                          of 75% in order to earn the Certificate of Completion.
                          Please try again.
                        </Typography>
                        <div className="flex-center">
                          <StyledButtonQuiz
                            // style={{ left: "40%" }}
                            onClick={() => {
                              // setMarkComplete(false);
                              // setRetakeExam(true);
                              retake.current = true;
                              getQuiz(curObject);
                              setShowScore(false);
                              setLoading(true);

                              // questionsRef.current = [];
                              // setProgress(Number(curObject.oduration));
                            }}
                          >
                            {" "}
                            Retake exam{" "}
                          </StyledButtonQuiz>
                        </div>
                      </>
                    ) : (
                      <>
                        <StyledButtonQuiz
                          onClick={() => {
                            setBtnClick(false);
                            setTotalScore(0);
                            handleClose();
                          }}
                        >
                          Cancel
                          {/*  Submit */}
                        </StyledButtonQuiz>
                        {/*  <Typography
                          variant="h5"
                          style={{
                            textTransform: "capitalize",
                            textAlign: "center",
                            paddingTop: "20px",
                          }}
                        >
                          Please let us know the feedback by taking a short
                          survey below:
                        </Typography>
                        <div className="flex-center">
                          <StyledButtonQuiz
                            // style={{ left: "40%" }}
                            onClick={() => {
                              // setMarkComplete(false);
                              // setRetakeExam(true);
                              // retake.current = true;
                              // getQuiz(query.get("id"));
                              setShowScore(false);
                              //  setLoading(true);
                              setIsSubmited(true);
                              // questionsRef.current = [];
                              // setProgress(Number(curObject.oduration));
                            }}
                          >
                            {" "}
                            Take Survey{" "}
                          </StyledButtonQuiz>
                        </div> */}
                      </>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <>
                {loading ? (
                  <div
                    style={{
                      width: "600px",
                      height: "1100px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress color="default" size={30} />
                  </div>
                ) : (
                  <div>
                    <p style={{ color: "red", paddingBottom: "10px" }}>
                      {errvalidatte}
                    </p>
                    {quizQuestion.map((question, qind) => (
                      <div>
                        <br />
                        <Typography
                          variant="h5"
                          style={{ marginBottom: "1rem", marginTop: "1rem" }}
                        >
                          Q{qind + 1}. {question.istem}
                        </Typography>
                        {question.imageurl !== undefined ? (
                          <img
                            style={{ width: "400px", height: "300px" }}
                            alt=""
                            src={`${
                              config.aws_cloudfront_url
                            }${config.aws_org_id.toLowerCase()}-resources/images/quiz-images/${
                              curObject.objid
                            }/${question.imageurl}`}
                          />
                        ) : (
                          ""
                        )}
                        <RadioGroup
                          aria-label="gender"
                          name={qind}
                          value={question.value}
                          onChange={handleChange}
                        >
                          <div className={classes.quizroot}>
                            {question.iopts.map((option) => (
                              <div
                                style={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  flex: "0 0 45%",
                                  height: "max-content",
                                  marginBottom: ansShow ? "4rem" : ".6rem",
                                  fontWeight: "1rem",
                                }}
                                className={
                                  ansShow && question.value !== undefined
                                    ? option.correct === "true" ||
                                      option.correct === true
                                      ? "greenborder"
                                      : question.value == option.content &&
                                        (option.correct === "true" ||
                                          option.correct === true)
                                      ? "greenborder"
                                      : question.value == option.content &&
                                        (option.correct === "false" ||
                                          option.correct === false)
                                      ? "redboreder"
                                      : "highlightborder"
                                    : "normalborder"
                                }
                              >
                                <FormControlLabel
                                  value={option.content}
                                  control={<Radio style={{ color: "black" }} />}
                                  label={option.content}
                                  key={option}
                                  style={{
                                    paddingLeft: "22px",
                                    fontWeight: "1rem",
                                  }}
                                />

                                {ansShow && question.value !== undefined ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      flex: "0 0 45%",
                                      height: "auto",
                                    }}
                                  >
                                    {option.feedback != undefined &&
                                    question.value == option.content ? (
                                      <p
                                        style={{
                                          width: "400px",
                                          paddingLeft: "5px",
                                        }}
                                        className={classes.label}
                                      >
                                        Feedback : {option.feedback}
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            ))}
                          </div>
                        </RadioGroup>
                      </div>
                    ))}
                    <h3
                      style={{
                        marginTop: "1rem",
                        color: "red",
                        fontSize: "1rem",
                        fontWeight: "600",
                        textAlign: "center",
                        alignSelf: "flex-start",
                      }}
                    >
                      {error}
                    </h3>

                    <div className="flex-center">
                      {qtype == 1 ? (
                        <>
                          <StyledButtonQuiz
                            onClick={() => {
                              //  setShowScore(true);
                              handleQuizSubmit();
                            }}
                          >
                            Submit
                          </StyledButtonQuiz>
                        </>
                      ) : (
                        <>
                          {ansShow ? (
                            <>
                              <StyledButtonQuiz
                                onClick={() => {
                                  setBtnClick(false);
                                  setAnsShow(false);
                                }}
                              >
                                Retake
                                {/*  Submit */}
                              </StyledButtonQuiz>
                              <StyledButtonQuiz
                                onClick={() => {
                                  handleClose();
                                  setTotalScore(0);
                                }}
                              >
                                Cancel
                                {/*  Submit */}
                              </StyledButtonQuiz>
                            </>
                          ) : (
                            <StyledButtonQuiz
                              onClick={() => {
                                validate();
                              }}
                            >
                              Submit
                              {/* Next */}
                            </StyledButtonQuiz>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        ) : (
          <>
            {curObject.qtype == 1 ? (
              <>
                <Typography
                  variant="h3"
                  style={{ textTransform: "capitalize" }}
                >
                  NXT Training Academy Certificate Exam
                </Typography>

                <div
                  style={{
                    display: "flex",
                    border: "1px solid #777",
                    justifyContent: "center",
                    alignItems: "center",

                    textAlign: "center",
                  }}
                >
                  <div
                    style={{ padding: "2rem", borderRight: "1px solid #777" }}
                  >
                    <p>Icon</p>
                    <p>Questions</p>
                    <p>15</p>
                  </div>
                  <div style={{ padding: "2rem" }}>
                    <p>Icon</p>
                    <p>Time allocated</p>
                    <p>00H 15m</p>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <Typography variant="h4">
                    All questions are required
                  </Typography>
                  <Typography variant="h4">Score per question: 1</Typography>
                  <Typography variant="h4">
                    Minimum score required to pass: 10
                  </Typography>
                </div>
              </>
            ) : (
              <></>
            )}

            <div
              style={
                curObject.qtype === 1
                  ? {
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }
                  : {
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "95%",
                    }
              }
            >
              {loading ? (
                <CircularProgress color="default" size={30} />
              ) : (
                <div className="centeritem" style={{ height: "62vh" }}>
                  <StyledButtonQuiz
                    onClick={() => {
                      getQuiz(curObject);
                      setShowScore(false);
                      setLoading(true);
                      setErrvalidatte("");
                    }}
                  >
                    Start Quiz
                  </StyledButtonQuiz>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    );
  }
  return (
    <div style={{ fontSize: "12px" }}>
      <div className={objStyle.closeholder}>
        <h3 className={objStyle.heading}>{curObject.oname}</h3>
        {/* <Close className={objStyle.closeicon} onClick={handleClose} /> */}
      </div>
      {renderObjects()}
    </div>
  );
}
