// Dependencies imports
import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import { API } from "aws-amplify";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import { useSelector, useDispatch } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";
import { awsSignIn, authData } from "../../../redux/auth/authSlice";

// Local imports
import { ReactComponent as Close } from "../../../assets/icons/close_black_24dp.svg";
import config from "../../../config/aws-exports";
import { Constants } from "../../../config/Constants";
import RejectionMail from "./RejectionMail";

// Style imports
import Ap from "./ApproveUsers.module.scss";
import { TextField } from "@mui/material";
import { Box } from "@mui/system";
import { wait } from "@testing-library/react";

// Styles for material components
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
    "& .super-app-theme--header": {
      backgroundColor: Constants.blue_color,
      fontSize: "16px",
      color: "rgb(255,255,255)",
      fontFamily: "nunito",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
    cursor: "pointer",
  },
}));

const header = {
  color: "white",
  fontFamily: "nunito",
  fontSize: "16px",
  fontWeight: "400px",
};

const ApproveUsers = ({
  handleApproveUserClose,
  waitingData,
  getSessionList,
  tenantoid,
  tenant,
}) => {
  const [registeredUsers, setRegisteredUsers] = useState([]);
  console.log({registeredUsers})
  const classes = useStyles();
  const inputRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [values, setValues] = useState({});
  const [details, setDetails] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [spin, setSpin] = useState(false);
  console.log("waiting list data", waitingData);
  let userDetails = useSelector(authData);

  useEffect(() => {
    getRegisteredUsers();
    return () => {};
  }, []);

  async function getRegisteredUsers() {
    setSpin(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        sid: waitingData.sid,
        typeusers: "wait_app",
        tenantoid: tenantoid,
        tenant: tenant,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log(bodyParam.body);
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_REGISTERED_USERS,
        bodyParam
      );
      setRegisteredUsers(response.body);
      setSpin(false);

      //alert("tenants", JSON.stringify(tenants));
    } catch (error) {
      console.error(error);
      setSpin(false);
    }
  }

  function handleMailOpen() {
    setOpen(true);
  }

  function handleMainClose() {
    setOpen(false);
  }

  function handleSubmit() {}

  async function mailUsers() {
    setSpin(true);

    const bodyParam = {
      body: {
        sn: waitingData.sn,
        eid: details.emailid,
        uname: details.first_name + " " + details.last_name,
        msg: message,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/rejectUserSessionToMail",
        bodyParam
      );
      markResult(details, "reject");
      setMessage("");
    } catch (error) {
      setSpin(false);
    }
  }

  async function markResult(item, status) {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        sid: waitingData.sid,
        type: status,
        ur_id: item.ur_id,
        paylater: waitingData.paylater,
        pstatus: item.pstatus
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    console.log(waitingData,bodyParam.body)
    try {
      setSpin(true);
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.APPROVE_USERS,
        bodyParam
      );
      getRegisteredUsers();
      getSessionList();
      setSpin(false);
    } catch (error) {
      setSpin(false);
    }
  }

  console.log("registered users", registeredUsers);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prev) => ({ ...prev, [name]: value }));
  };

  function Tableview() {
    return (
      <TableBody>
        {(rowsPerPage > 0
          ? registeredUsers.slice(
              page * rowsPerPage,
              page * rowsPerPage + rowsPerPage
            )
          : registeredUsers
        ).map((item) => {
          return (
            <TableRow key={item.emailid}>
              <TableCell>{item.first_name}</TableCell>
              <TableCell>{item.last_name}</TableCell>
              <TableCell>{item.emailid}</TableCell>
              {tenantoid === false ? (
                userDetails.role === "Admin" ? (
                  userDetails.role === "Manager" ? null : (
                    <TableCell>
                      <button
                        className={Ap.upload}
                        onClick={() => markResult(item, "approve")}
                      >
                        Approve
                      </button>
                    </TableCell>
                  )
                ) : null
              ) : null}
              {tenantoid === false ? (
                userDetails.role === "Admin" ? (
                  userDetails.role === "Manager" ? null : (
                    <TableCell>
                      <button
                        className={Ap.upload}
                        onClick={() => {
                          handleMailOpen(item);
                          setDetails(item);
                        }}
                      >
                        Reject
                      </button>
                    </TableCell>
                  )
                ) : null
              ) : null}
              {tenantoid === false ? (
                userDetails.role === "Admin" ? (
                  userDetails.role === "Manager" ? null : (
                    <TableCell>
                      {item.pstatus === 0 ? "NEFT" : null}
                      {item.pstatus === 1 ? "RazorPay" : null}
                      {item.pstatus === 2 ? "Pay Later" : null}{" "}
                      {item.pstatus === 10 ? "Payment in process" : null}
                    </TableCell>
                  )
                ) : null
              ) : null}
              {tenantoid === false ? (
                userDetails.role === "Admin" ? (
                  userDetails.role === "Manager" ? null : (
                    <TableCell>{item.pdetails}</TableCell>
                  )
                ) : null
              ) : null}
              {tenantoid === false ? (
                userDetails.role === "Admin" ? (
                  userDetails.role === "Manager" ? null : (
                    <TableCell>{item.assign}</TableCell>
                  )
                ) : null
              ) : null}

              {tenantoid === false ? (
                userDetails.role === "Admin" ? (
                  userDetails.role === "Manager" ? null : (
                    <TableCell>{item.ur_comment ?? ""}</TableCell>
                  )
                ) : null
              ) : null}
              {tenantoid === false ? (
                userDetails.role === "Admin" ? (
                  userDetails.role === "Manager" ? null : (
                    <React.Fragment>
                      {item.ur_comment && (
                        <TableCell>
                          <TextField
                            ref={inputRef}
                            type="text"
                            placeholder="Comments"
                            size="small"
                            name={item.emailid}
                            value={values[item.emailid]}
                            // error={inputError}
                            onChange={handleChange}
                            // helperText={
                            //   inputError ? "Comment is required." : ""
                            // }
                            required
                          />
                        </TableCell>
                      )}
                    </React.Fragment>
                  )
                ) : null
              ) : null}
            </TableRow>
          );
        })}
      </TableBody>
    );
  }

  return (
    <div className={Ap.maincontainernew}>
      <div className={Ap.topbar}>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <RejectionMail
              handleMainClose={handleMainClose}
              mailUsers={mailUsers}
              handleSubmit={handleSubmit}
              setMessage={setMessage}
              message={message}
            />
          </Fade>
        </Modal>
        <h3 className={Ap.header}>Waiting for approval</h3>
        <Close
          className={Ap.closeicon}
          onClick={() => {
            handleApproveUserClose();
            getSessionList();
          }}
        />
      </div>
      {spin === false ? (
        <Table>
          <TableHead sx={{ background: "#285780" }}>
            <TableRow>
              <TableCell style={header}>First Name</TableCell>
              <TableCell style={header}>Last Name</TableCell>
              <TableCell style={header}>Email</TableCell>
              {tenantoid === false ? (
                userDetails.role === "Admin" ? (
                  userDetails.role === "Manager" ? null : (
                    <TableCell style={header}>Approve</TableCell>
                  )
                ) : null
              ) : null}
              {tenantoid === false ? (
                userDetails.role === "Admin" ? (
                  userDetails.role === "Manager" ? null : (
                    <TableCell style={header}>Reject</TableCell>
                  )
                ) : null
              ) : null}
              {tenantoid === false ? (
                userDetails.role === "Admin" ? (
                  userDetails.role === "Manager" ? null : (
                    <TableCell style={header}>Payment Method</TableCell>
                  )
                ) : null
              ) : null}
              {tenantoid === false ? (
                userDetails.role === "Admin" ? (
                  userDetails.role === "Manager" ? null : (
                    <TableCell style={header}>Payment ID</TableCell>
                  )
                ) : null
              ) : null}
              {tenantoid === false ? (
                userDetails.role === "Admin" ? (
                  userDetails.role === "Manager" ? null : (
                    <TableCell style={header}>Assigness</TableCell>
                  )
                ) : null
              ) : null}
              {tenantoid === false ? (
                userDetails.role === "Admin" ? (
                  userDetails.role === "Manager" ? null : (
                    <TableCell style={header}>User comment</TableCell>
                  )
                ) : null
              ) : null}
              {tenantoid === false ? (
                userDetails.role === "Admin" ? (
                  userDetails.role === "Manager" ? null : (
                    <React.Fragment>
                      <TableCell style={header}>Admin comment</TableCell>
                    </React.Fragment>
                  )
                ) : null
              ) : null}
            </TableRow>
          </TableHead>
          {spin ? null : registeredUsers.length === 0 ? (
            <TableBody>
              <TableRow>
                <TableCell></TableCell>
                {userDetails.role === "Admin" ? (
                  userDetails.role === "Manager" ? null : (
                    <TableCell></TableCell>
                  )
                ) : null}
                {userDetails.role === "Admin" ? (
                  userDetails.role === "Manager" ? null : (
                    <TableCell></TableCell>
                  )
                ) : null}
                <TableCell>No Data</TableCell>
                {userDetails.role === "Admin" ? (
                  userDetails.role === "Manager" ? null : (
                    <TableCell></TableCell>
                  )
                ) : null}
                {userDetails.role === "Admin" ? (
                  userDetails.role === "Manager" ? null : (
                    <TableCell></TableCell>
                  )
                ) : null}
                {userDetails.role === "Admin" ? (
                  userDetails.role === "Manager" ? null : (
                    <TableCell></TableCell>
                  )
                ) : null}
              </TableRow>
            </TableBody>
          ) : (
            <Tableview />
          )}
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={10}
                count={registeredUsers.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Skeleton variant="rect" width={1500} height={400} />{" "}
        </div>
      )}
    </div>
  );
};

export default ApproveUsers;
