import { CloseSharp } from "@mui/icons-material";
import { Box, Button, IconButton, Modal } from "@mui/material";
import React, { useState } from "react";
import { Constants } from "../../../../config/Constants";
import config from "../../../../config/aws-exports";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import { authData } from "../../../../redux/auth/authSlice";
import { API } from "aws-amplify";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const CSVToArray = function (strData, strDelimiter) {
  strDelimiter = strDelimiter || ",";

  var objPattern = new RegExp(
    "(\\" +
      strDelimiter +
      "|\\r?\\n|\\r|^)" +
      '(?:"([^"]*(?:""[^"]*)*)"|' +
      '([^"\\' +
      strDelimiter +
      "\\r\\n]*))",
    "gi"
  );

  var arrData = [[]];

  var arrMatches = null;

  while ((arrMatches = objPattern.exec(strData))) {
    var strMatchedDelimiter = arrMatches[1];

    if (strMatchedDelimiter.length && strMatchedDelimiter !== strDelimiter) {
      arrData.push([]);
    }

    if (arrMatches[2]) {
      var strMatchedValue = arrMatches[2].replace(new RegExp('""', "g"), '"');
    } else {
      // We found a non-quoted value.
      strMatchedValue = arrMatches[3].length === 0 ? "" : arrMatches[3];
    }

    arrData[arrData.length - 1].push(strMatchedValue);
  }

  return arrData;
};
function isHTML(filename) {
  var ext = getExtension(filename);
  switch (ext.toLowerCase()) {
    case "csv":
      return true;
    default:
      return false;
  }
}
function getExtension(filename) {
  var parts = filename.split(".");
  return parts[parts.length - 1];
}
function AddEmailUser({ open, handleClose, ur_id, getUsers }) {
  const [csvData, setCsvData] = useState(null);
  const [csvFile, setCsvFile] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [fileErr, setFileErr] = useState();

  const [errorData, setErrorData] = useState([]);
  let userDetails = useSelector(authData);
  const handleFileUpload = async (e) => {
    try {
      const file = e.target.files[0];
      if (!isHTML(e.target.files[0].name)) {
        swal("Oops!", "Select correct file format..!", "error");
        return;
      }
      setCsvFile(file);
      const reader = new FileReader();

      reader.onload = (e) => {
        const xd = e.target.result;
        console.log(xd);
        var array = CSVToArray(xd);
        var objArray = [];
        for (var i = 1; i < array.length; i++) {
          objArray[i - 1] = {};
          for (var k = 0; k < array[0].length && k < array[i].length; k++) {
            var key = array[0][k];
            if (array[i][k] !== undefined) {
              objArray[i - 1][key] =
                key === "Email" ? array[i][k].toLowerCase() : array[i][k];
            }
          }
        }
        var filteredarray;

        filteredarray = objArray.filter(
          (key) =>
            key.EmployeeID !== undefined &&
            key.Firstname !== undefined &&
            key.Lastname !== undefined &&
            key.Contactno !== undefined &&
            key.gender !== undefined &&
            key.address !== undefined &&
            key.Role !== undefined &&
            key.EmployeeID !== " " &&
            key.Firstname !== " " &&
            key.Lastname !== " " &&
            key.Contactno !== " " &&
            key.gender !== " " &&
            key.address !== " " &&
            key.Role !== " "
        );

        setCsvData(filteredarray);
      };

      reader.readAsText(file);
    } catch (error) {
      console.error("Error handling file upload:", error);
    }
  };

  const uploadUsers = async () => {
    setErrorData([]);
    setFileErr("");
    var temp = [];
    var temp2 = [];
    if (csvFile === undefined || csvFile === "") {
      setFileErr("Please select csv file to upload");
      return;
    }
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    if (csvData.length > 0) {
      for (var i = 0; i < csvData.length; i++) {
        if (
          csvData[i].EmployeeID !== undefined &&
          csvData[i].EmployeeID !== "" &&
          csvData[i].Firstname !== undefined &&
          csvData[i].Firstname !== "" &&
          csvData[i].Lastname !== undefined &&
          csvData[i].Lastname !== "" &&
          csvData[i].gender !== undefined &&
          csvData[i].gender !== "" &&
          csvData[i].Role !== undefined &&
          csvData[i].Role !== "" &&
          (csvData[i].Role === "User" || csvData[i].Role === "Manager") &&
          pattern.test(`${csvData[i].EmployeeID}@email.com`)
        ) {
          temp.push(csvData[i]);
        } else {
          temp2.push(csvData[i]);
        }
      }
    }
    setErrorData(temp2);
    if (csvData.length === 0) {
      setFileErr("File has no records");
      return;
    }
    if (temp2.length === 0) {
      const data = changeFormat(temp);
      await uploadUsersData(data);
      setIsLoading(false);
    }
  };

  function changeFormat(temp) {
    const format = [];
    for (let i = 0; i < temp.length; i++) {
      let obj = {};
      obj.oid = config.aws_org_id;
      obj.ur_id = 0;
      obj.tenant = userDetails.tenantdata.tenantname;
      obj.emailid = `${temp[i].EmployeeID?.toLowerCase()}@email.com`;
      obj.first_name = temp[i].Firstname;
      obj.last_name = temp[i].Lastname;
      obj.dev = 2;
      obj.gen = temp[i].gender === "Male" || temp[i].gender === "male" ? 1 : 2;
      if (temp[i].Role.toLowerCase() === "User".toLowerCase()) {
        obj.urole = "User";
      } else if (temp[i].Role.toLowerCase() === "Manager".toLowerCase()) {
        obj.urole = "Manager";
      } else {
        obj.urole = null;
      }

      obj.st_id = userDetails.tenantdata.st_id;
      obj.address = temp[i].address;
      obj.uid = temp[i].EmployeeID;
      obj.cno = temp[i].Contactno == "" ? null : temp[i].Contactno;
      obj.schema = Constants.SCHEMA_NAME;
      obj.atype = 0;

      format.push(obj);
    }
    return format;
  }

  const uploadUsersData = async (obj) => {
    setIsLoading(true);
    const bodyParam = {
      body: { ur_id: ur_id, emailids: obj },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    console.log("bd" + JSON.stringify(bodyParam.body));
    try {
      await API.post(
        config.aws_cloud_logic_custom_name,
        "/link-email-to-users",
        bodyParam
      );
      await getUsers();
      handleClose();
      return true;
    } catch (error) {
      console.error("Error", error);
      await getUsers();
      handleClose();
      return false;
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={{ display: "flex" }}>
          <h2 id="child-modal-title">Upload User</h2>

          <Box sx={{ flex: 1 }} />
          <IconButton onClick={handleClose}>
            <CloseSharp />
          </IconButton>
        </Box>
        <div>
          <div
            style={{
              color: "#cc0000",
              marginBottom: "12px",
              marginTop: "-10px",
              textAlign: "start",
              fontSize: "13px",
            }}
          >
            <p>{fileErr}</p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              margin: "auto",
              gap: "20px",
              border: "1px solid gray",
              padding: "50px",
              marginBottom: "1.5rem",
            }}
          >
            <input type="file" accept=".csv" onChange={handleFileUpload} />
          </div>
          <a
            href={`https://${config.DOMAIN}/usp-resources/linkuserlist.csv`}
            download
          >
            Download format
          </a>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "1.5rem",
            }}
          >
            <Button
              onClick={uploadUsers}
              disabled={isLoading}
              variant="contained"
            >
              {isLoading ? "Loading..." : "Upload"}
            </Button>
          </Box>

          {errorData.length > 0 && (
            <>
              <p style={{ fontSize: "14px", textAlign: "left" }}>
                Below are the invalid entries please remove from file and upload
                again.
              </p>

              <div
                style={{
                  height: "200px",
                  overflow: "scroll",
                  marginTop: "10px",
                }}
              >
                <table style={{ fontSize: "14px", width: "100%" }}>
                  <thead style={{ backgroundColor: "aliceblue" }}>
                    <th>EmployeeID</th>
                    <th>FirstName</th>
                    <th>LastName</th>
                    <th>Email</th>
                    <th>Contact No</th>
                    <th>Gender</th>
                    <th>Address</th>
                    <th>Role</th>
                  </thead>
                  <tbody>
                    {errorData.map((item, i) => (
                      <tr key={i}>
                        <td>{item.Firstname}</td>
                        <td>{item.Lastname}</td>
                        <td>{item.Email}</td>
                        <td>{item.Contactno}</td>
                        <td>{item.gender}</td>
                        <td>{item.address}</td>
                        <td>{item.Role}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
      </Box>
    </Modal>
  );
}

export default AddEmailUser;
