// Dependencies import
import React, { useState, useRef, useEffect } from "react";
import { styled } from "@mui/system";
import swal from "sweetalert2";
import { API } from "aws-amplify";
import config from "../../../config/aws-exports";
import axios from "axios";
import { Button } from "@mui/material";
import BackDrop from "@material-ui/core/Backdrop/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../../redux/auth/authSlice";

// Local imports
import { ReactComponent as Close } from "../../../assets/icons/close_black_24dp.svg";
import { Constants } from "../../../config/Constants";

// Style imports
import Add from "./AddProgram.module.scss";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
}));

const EditTopic = ({
  handleEditClose,
  dataToEdit,
  getPrograms,
  speakerList,
}) => {
  console.log("datato", dataToEdit);
  const history = useHistory();
  const [name, setName] = useState(dataToEdit.name);
  const [image, setImage] = useState(null);
  let userDetails = useSelector(authData);

  const [nameError, setNameError] = useState("");
  const [imageError, setImageError] = useState("");

  const [imageFileName, setImageFileName] = useState(null);

  const [imageExtentionError, setImageExtentionError] = useState("");

  const [spin, setSpin] = useState(false);
  // const [speakerArray, setSpeakerArray] = useState([]);
  const refselectedImageFile = useRef();
  // const refselectedSlideFile = useRef();
  const [changeimage, setChangeImage] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    return () => {};
  }, []);

  const onChangeHandlerName = (event) => {
    setName(event.target.value);
  };
  const getExtension = (filename) => {
    let parts = filename.split(".");
    return parts[parts.length - 1];
  };

  const isImage = (filename) => {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "jpg":
      case "png":
      case "jpeg":
        return true;
      default:
        return false;
    }
  };

  const onChangeHandlerImage = (event) => {
    setChangeImage(true);
    if (!isImage(event.target.files[0].name)) {
      setImageExtentionError(
        "Wrong file format! Accepted file format: .png/.jpeg/.jpg"
      );
      return;
    }
    setImageExtentionError("");
    setImage({ file: event.target.files[0] });
    refselectedImageFile.current = event.target.files[0];
    console.log("image", image);
  };

  const finalSessionImage = () => {
    if (image.file == null) {
      return;
    }
    if (image.file.size > 5242880) {
      swal(
        "Oops!",
        "Image size is too large. File size cannot be greater than 5mb.",
        "error"
      );
      return;
    }
    if (image.file !== undefined) {
      let uniqueStamp = new Date().getTime();
      setImageFileName(uniqueStamp + ".png");

      let imageName = uniqueStamp + ".png";

      //setSelectedCourseFile((currentState) => (event.target.files[0]));
      var body = {
        type: "category",
        oid: config.aws_org_id,
        filetype: "image/png",
        filename: uniqueStamp + ".png",
      };

      console.log("bodyImg", body);
      uploadfile(body, refselectedImageFile.current);
      editTopic(imageName);
    }
  };

  async function uploadfile(body, file) {
    // setIstLoading(true);
    const bodyParam = {
      body,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const preSignedData = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_PRESIGNED_URL,
        bodyParam
      );
      console.log("file", file);
      axios
        .put(preSignedData, file, {
          headers: {
            "Content-Type": "image/png",
            "Cache-Control": "no-cache no-store must-revalidate",
          },
        })
        .then((response) => {
          console.log("file upload success");
        })
        .catch((error) => {
          console.error(error);
        });

      //   setIstLoading(false);
    } catch (error) {
      console.error(error);
    }
  }

  const submitForm = () => {
    let formIsValid = true;

    setNameError("");
    setImageError("");

    if (name === "" || name === null || name.trim() === "") {
      formIsValid = false;
      setNameError("Please enter topic name");
      return;
    }

    if (formIsValid === true) {
      if (changeimage === true) {
        finalSessionImage();
      } else {
        editTopic();
      }
    }
  };

  const editTopic = async (imgName, sldName) => {
    setSpin(true);
    let bodyObj = {};
    bodyObj.ct_id = dataToEdit.id;
    bodyObj.oid = config.aws_org_id;
    bodyObj.cname = name;
    bodyObj.atype = 1;
    bodyObj.schema = Constants.SCHEMA_NAME;
    if (changeimage === true) {
      bodyObj.imgurl = imgName;
    } else {
      bodyObj.imgurl = dataToEdit.ct_img;
    }

    const bodyParam = {
      body: bodyObj,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      console.log(JSON.stringify(bodyParam.body));
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.UPDATE_CATEGORY,
        bodyParam
      );
      handleEditClose();
      getPrograms();
      swal
        .fire({
          title: "Success",
          text: response.message,
          icon: "success",
          dangerMode: false,
          closeOnClickOutside: false,
        })
        .then((willDelete) => {
          if (willDelete) {
            setSpin(false);
            const tab = window.localStorage.getItem("tab");
            history.push(`/uspcontent?tab=${tab}`);
          }
        });
    } catch (error) {
      swal
        .fire({
          title: "Oooops!",
          text: "Soomthing wrong!",
          icon: "warning",
          dangerMode: true,
        })
        .then((willDelete) => {
          if (willDelete) {
            setSpin(false);
          }
        });
      console.log("getUserError", error);
    }
  };

  const Input = styled("input")({
    display: "none",
  });

  return (
    <div className={Add.maincontainer}>
      <BackDrop sx={{ color: "#fff" }} open={spin} className={classes.backdrop}>
        <CircularProgress color="inherit" />
      </BackDrop>
      <div className={Add.closeholder}>
        <h3 className={Add.heading}>Edit Topic</h3>
        <Close className={Add.closeicon} onClick={handleEditClose} />
      </div>

      <div className={Add.formfieldsholder}>
        <div className={Add.leftside}>
          <div
            style={{
              color: "#cc0000",
              textAlign: "start",
              marginLeft: "50%",
              fontSize: "10px",
            }}
          >
            {nameError}
          </div>
          <div className={Add.inputblock}>
            <div className={Add.labelholder}>
              <label for="name" className={Add.label} required>
                Name<span style={{ color: "red", marginTop: "-10px" }}>*</span>
              </label>
            </div>
            <div className={Add.inputholder}>
              <input
                type="text"
                id="name"
                className={Add.inputfield}
                onChange={onChangeHandlerName}
                value={name}
              ></input>
            </div>
          </div>
          <div
            style={{
              color: "#cc0000",
              textAlign: "start",
              marginLeft: "50%",
              fontSize: "10px",
            }}
          >
            {imageError}
          </div>
          <div
            style={{
              color: "#cc0000",
              textAlign: "start",
              marginLeft: "30%",
              fontSize: "10px",
            }}
          >
            {imageExtentionError}
          </div>
          <div className={Add.inputblock}>
            <div className={Add.labelholder}>
              <label for="image" className={Add.label} required>
                Image<span style={{ color: "red", marginTop: "-10px" }}>*</span>
              </label>
            </div>
            <div className={Add.inputholder}>
              <div className={Add.fileupload}>
                <label htmlFor="session-image" className={Add.labelnot}>
                  <Input
                    accept="image/*"
                    id="session-image"
                    type="file"
                    onChange={(e) => onChangeHandlerImage(e)}
                  />
                  <span className={Add.add}>Upload</span>
                  <h5 style={{ fontSize: "10px", color: "grey" }}>
                    {changeimage === true
                      ? image !== null && image.file !== undefined
                        ? image.file.name
                        : ""
                      : image !== null && image !== undefined
                      ? image
                      : dataToEdit.ct_img}
                  </h5>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={Add.buttonholder}>
        <button className={Add.cancel} onClick={handleEditClose}>
          Cancel
        </button>
        <button className={Add.add} type="submit" onClick={submitForm}>
          Edit
        </button>
      </div>
    </div>
  );
};

export default EditTopic;
