import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import usersStyle from "./Users.module.scss";
import Paper from "@mui/material/Paper";
import Ios from "../../../assets/images/apple-black-logo.png";
import Android from "../../../assets/images/android.png";
import Web from "../../../assets/images/global.png";
import moment from "moment";
import AddUsersTenant from "./AddUsersTenant";
import UserBulkUploadTenant from "./UserBulkUploadTenant";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import { API } from "aws-amplify";
import { Link } from "react-router-dom";
import {
  DataGrid,
  GridToolbar,
  GridToolbarContainer,
} from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/core/styles";

// Local imports
import { Constants } from "../../../config/Constants";

import config from "../../../config/aws-exports";
import DeleteUser from "./DeleteUser";
import EditUser from "./EditUser";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../../redux/auth/authSlice";

import { ReactComponent as Edit } from "../../../assets/icons/edit_black_24dp.svg";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import swal from "sweetalert";
import Swal from "sweetalert2";
import AddEmailUser from "./ModalPopUp/AddEmailUser";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .super-app-theme--header": {
      backgroundColor: Constants.blue_color,
      fontSize: "16px",
      color: "rgb(255,255,255)",
      fontFamily: "nunito",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
    cursor: "pointer",
  },
}));

function CustomToolbar() {
  return (
    <GridToolbarContainer
      style={{ display: "flex", justifyContent: "flex-end" }}
    >
      <GridToolbar />
    </GridToolbarContainer>
  );
}

export default function Users(props) {
  let { userCount, tenantName } = props;

  const [rows, setRows] = useState([]);
  const [editData, setEditData] = useState([]);
  const [deleteData, setDeleteData] = useState([]);

  const classes = useStyles();

  const dispatch = useDispatch();
  let userDetails = useSelector(authData);
  const [openAdd, setOpenAdd] = useState(false);
  const [tenantInfo, setTenantInfo] = useState([]);
  const [getUserID, setUserID] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openBulk, setOpenBulk] = useState(false);
  const [loading, setLoading] = useState(true);
  const [activeCount, setActiveCount] = useState(0);
  const [open, setOpen] = React.useState(false);
  const handleOpen = (id) => {
    setUserID(id);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  useEffect(() => {
    getUsers();
    return () => {};
  }, []);

  async function getUsers() {
    setLoading(true);
    const bodyParam = {
      body: {
        oid: userDetails.tenantdata.tenantname,
        schema: Constants.SCHEMA_NAME,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const userData = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_USERS,
        bodyParam
      );

      const userDetail = userData.body;

      let trows = [];
      for (let i = 0; i < userDetail.length; i++) {
        let obj = {};
        obj.id = i;
        obj.firstname = userDetail[i].first_name;
        obj.lastname = userDetail[i].last_name;
        obj.col2 = userDetail[i].emailid;
        obj.designation = userDetail[i].designation;
        obj.department = userDetail[i].department;
        obj.organization = userDetail[i].organization;
        obj.unit = userDetail[i].business_unit;
        obj.country = userDetail[i].country;
        obj.state = userDetail[i].state;
        obj.city = userDetail[i].city;
        obj.userrole =
          userDetail[i].ur === "User" ? "Participant" : userDetail[i].ur;
        if (userDetail[i].la === undefined || userDetail[i].la === null) {
        } else {
          var diff = moment.utc(parseInt(userDetail[i].la)).fromNow();
          // users[i].LA = diff;
          obj.col4 = diff;
        }
        obj.cno = userDetail[i].cno;
        obj.address = userDetail[i].address;
        obj.ur_id = userDetail[i].ur_id;
        obj.gen = userDetail[i].gen;
        obj.col3 = userDetail[i].oid;
        obj.dev = userDetail[i].dev;
        trows.push(obj);
      }
      setRows(trows);
      getActiveUsers();
    } catch (error) {
      console.error(error);
    }
  }

  async function getActiveUsers() {
    setLoading(true);
    const bodyParam = {
      body: {
        oid: userDetails.tenantdata.tenant,
        schema: Constants.SCHEMA_NAME,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const count = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_TODAY_ACTIVE,
        bodyParam
      );
      if (count !== undefined && count !== null) {
        //setActiveCount(count.body.todayusercount);
        getTenantInfo();
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  async function getTenantInfo() {
    setLoading(true);
    const bodyParam = {
      body: {
        st_id: userDetails.tenantdata.st_id,
        schema: Constants.SCHEMA_NAME,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const tenants = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_TENANT_DATA_INFO,
        bodyParam
      );
      //alert("tenants", JSON.stringify(tenants));
      setTenantInfo(tenants.body.viewData[0]);
      setActiveCount(tenants.body.todayusercount);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  const handleAddOpen = () => {
    setOpenAdd(true);
  };

  const handleAddClose = () => {
    setOpenAdd(false);
  };

  const handleBulkOpen = () => {
    setOpenBulk(true);
  };

  const handleBulkClose = () => {
    setOpenBulk(false);
  };

  const handleDeleteOpen = () => {
    setOpenDelete(true);
  };

  const handleDeleteClose = () => {
    setOpenDelete(false);
  };

  const handleEditOpen = () => {
    setOpenEdit(true);
  };

  const handleEditClose = () => {
    setOpenEdit(false);
  };

  const handleEditClick = (param, event) => {
    handleEditOpen();

    setEditData(param.row);
  };

  const handleDeleteClick = (param, event) => {
    handleDeleteOpen();

    setDeleteData(param.row);
  };

  const tcolumns = [
    {
      field: "firstname",
      headerName: " First Name",
      flex: 1,
      minWidth: 150,
      headerClassName: "super-app-theme--header",
      renderCell: (param) => {
        return (
          <Link
            to="/organization/viewTenant/userInfo"
            className={usersStyle.head}
          >
            <h3
              onClick={() => {
                handleCellClick(param);
              }}
              className={usersStyle.head}
            >
              {param.value}
            </h3>
          </Link>
        );
      },
    },
    {
      field: "lastname",
      headerName: "Last Name",
      flex: 1,
      minWidth: 150,
      headerClassName: "super-app-theme--header",
      renderCell: (param) => {
        return (
          <Link to="#" className={usersStyle.head}>
            <h3
              onClick={() => {
                handleCellClick(param);
              }}
              className={usersStyle.head}
            >
              {param.value}
            </h3>
          </Link>
        );
      },
    },
    {
      field: "col2",
      headerName: "Email",
      minWidth: 150,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "designation",
      headerName: "Designation",
      flex: 1,
      minWidth: 150,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "department",
      headerName: "Department",
      minWidth: 150,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "organization",
      headerName: "Organization",
      minWidth: 150,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "userrole",
      headerName: "User Role",
      minWidth: 150,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "col4",
      headerName: "Last Active",
      minWidth: 150,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "edit",
      headerName: "Edit User",
      minWidth: 150,
      flex: 1,
      sortable: false,
      disableExport: true,
      disableClickEventBubbling: true,
      headerClassName: "super-app-theme--header",
      renderCell: (param) => {
        return (
          <Button
            size="small"
            startIcon={<Edit />}
            onClick={(event) => {
              handleEditClick(param, event);
            }}
          ></Button>
        );
      },
    },
    {
      field: "reset",
      minWidth: 150,
      headerName: "Reset User",
      flex: 1,
      sortable: false,
      disableExport: true,
      disableClickEventBubbling: true,
      headerClassName: "super-app-theme--header",
      renderCell: (param) => {
        return (
          <Button
            size="small"
            startIcon={<RestartAltIcon color="warning" />}
            onClick={() => {
              const first_name = param.row.firstname;
              const last_name = param.row.lastname;
              const email = param.row.col2;
              const name = `${first_name} ${last_name}`;
              const username = email;
              handleReset({ name, username });
            }}
          ></Button>
        );
      },
    },
    {
      field: "add_email_users",
      headerName: "Add user to email",
      minWidth: 150,
      flex: 1,
      sortable: false,
      disableExport: true,
      disableClickEventBubbling: true,
      headerClassName: "super-app-theme--header",
      renderCell: (param) => {
        return (
          <Button
            size="small"
            variant="contained"
            color="info"
            onClick={() => {
              handleOpen(param.row.ur_id);
            }}
          >
            Add user to Email
          </Button>
        );
      },
    },
  ];
  const handleReset = (args) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Reset!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        return await resetUser(args)
          .then((response) => {
            if (response.statusCode !== 200) {
              throw new Error(response.body);
            }
            return response;
          })
          .catch((error) => {
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Success",
          text: result.value?.body,
          icon: "success",
          dangerMode: false,
          closeOnClickOutside: false,
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      }
    });
  };

  async function resetUser(req) {
    try {
      const bodyParam = {
        body: req,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/reset-password",
        bodyParam
      );

      return response;
    } catch (error) {
      throw error;
    }
  }

  async function downloadcsv() {
    try {
      const response = await fetch(
        `https://${config.aws_cloudfront_url}/userlist.csv`
      );
      const data = await response.text();
      const blob = new Blob([data], { type: "data:text/csv;charset=utf-8," });
      const url = window.URL.createObjectURL(blob);
      let dwldLink = document.createElement("a");
      let isSafariBrowser =
        navigator.userAgent.indexOf("Safari") !== -1 &&
        navigator.userAgent.indexOf("Chrome") === -1;
      if (isSafariBrowser) {
        //if Safari open in new window to save file with random filename.
        dwldLink.setAttribute("target", "_blank");
      }
      dwldLink.setAttribute("href", url);
      dwldLink.setAttribute("download", "userlist.csv");
      dwldLink.style.visibility = "hidden";
      document.body.appendChild(dwldLink);
      dwldLink.click();
      document.body.removeChild(dwldLink);
    } catch (error) {
      console.error(error);
    }
  }

  const handleCellClick = (param, event) => {
    let sdata = { ...userDetails };
    sdata.user = param.row;

    let temp = [...sdata.breadcrumb];

    temp[2] = {
      name: param.row.firstname + " " + param.row.lastname,
      path: "/tenants/viewTenant/userInfo",
    };

    sdata.breadcrumb = temp;

    dispatch(awsSignIn(sdata));
  };

  return (
    <div>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div style={{ padding: "20px" }}>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",

            justifyContent: "center",
            gap: "30px",
            width: "100%",
          }}
        >
          <div>
            <Paper className={usersStyle.paper}>
              <div style={{ display: "grid" }}>
                <div className={usersStyle.header}>
                  <div>TOTAL USERS</div>
                  <div>ACTIVE TODAY</div>
                </div>
                <div style={{ display: "flex" }}>
                  <div className={usersStyle.count}>{tenantInfo.user_cnt}</div>
                  <div className={usersStyle.pipeline}></div>
                  <div className={usersStyle.count}>{activeCount}</div>
                </div>
              </div>
            </Paper>
          </div>
          <div>
            <Paper className={usersStyle.paper}>
              <div className={usersStyle.header}>TOTAL USERS</div>
              <div className={usersStyle.row}>
                <img className={usersStyle.iconholder} src={Ios} alt="ios" />

                <div className={usersStyle.device}>
                  <h5 className={usersStyle.type}>Ios</h5>
                </div>
                <div className={usersStyle.percentholder}>
                  <h5 className={usersStyle.percent}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {tenantInfo.user_cnt === 0
                        ? 0
                        : (
                            (tenantInfo.ios_cnt / tenantInfo.user_cnt) *
                            100
                          ).toFixed()}
                      {" %"}
                    </div>
                  </h5>
                </div>
              </div>
              <div className={usersStyle.row}>
                <img
                  className={usersStyle.iconholder}
                  src={Android}
                  alt="android"
                />

                <div className={usersStyle.device}>
                  <h5 className={usersStyle.type}>Android</h5>
                </div>
                <div className={usersStyle.percentholder}>
                  <h5 className={usersStyle.percent}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {tenantInfo.user_cnt === 0
                        ? 0
                        : (
                            (tenantInfo.android_cnt / tenantInfo.user_cnt) *
                            100
                          ).toFixed()}
                      {" %"}
                    </div>
                  </h5>
                </div>
              </div>
              <div className={usersStyle.row}>
                <img className={usersStyle.iconholder} src={Web} alt="web" />

                <div className={usersStyle.device}>
                  <h5 className={usersStyle.type}>Web</h5>
                </div>
                <div className={usersStyle.percentholder}>
                  <h5 className={usersStyle.percent}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {tenantInfo.user_cnt === 0
                        ? 0
                        : (
                            (tenantInfo.web_cnt / tenantInfo.user_cnt) *
                            100
                          ).toFixed()}
                      {" %"}
                    </div>
                  </h5>
                </div>
              </div>
            </Paper>
          </div>
        </Box>
      </div>
      <div style={{ padding: "10px" }}>
        <Paper>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h4 style={{ paddingLeft: "20px", paddingTop: "5px" }}>
              All Users
            </h4>
            <div className={usersStyle.leftside}>
              <a
                style={{ fontSize: "13px", cursor: "pointer", color: "blue" }}
                href={`https://${config.DOMAIN}/userlist.csv`}
                download
              >
                Download Format
              </a>
              <button className={usersStyle.buttonadd} onClick={handleBulkOpen}>
                Bulk Upload
              </button>
              <button className={usersStyle.buttonadd} onClick={handleAddOpen}>
                Create Users
              </button>
            </div>
          </div>

          <div
            style={{ height: 400, width: "100%", overflowY: "auto" }}
            className={classes.root}
          >
            <DataGrid
              rows={rows}
              columns={tcolumns}
              className={classes[".MuiDataGrid-root"]}
              components={{
                Toolbar: CustomToolbar,
              }}
              disableSelectionOnClick
            />
          </div>
        </Paper>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openAdd}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openAdd}>
          <AddUsersTenant
            handleAddClose={handleAddClose}
            tenantName={tenantName}
            getUsers={getUsers}
          />
        </Fade>
      </Modal>
      {open && (
        <AddEmailUser
          open={open}
          handleClose={handleClose}
          ur_id={getUserID}
          getUsers={getUsers}
        />
      )}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openEdit}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openEdit}>
          <EditUser
            handleEditClose={handleEditClose}
            editData={editData}
            getUsers={getUsers}
          />
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openBulk}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openBulk}>
          <UserBulkUploadTenant
            handleClose={handleBulkClose}
            getUsers={getUsers}
            type="user"
          />
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openDelete}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openDelete}>
          <DeleteUser
            handleDeleteClose={handleDeleteClose}
            deleteData={deleteData}
            getUsers={getUsers}
          />
        </Fade>
      </Modal>
    </div>
  );
}
