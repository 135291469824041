import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
//import { StyledButtons } from "./RegisterForm/RegisterFrom";
import { Theme } from "../../../config/aws-exports";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import axios from "axios";
//import { saveAs } from "file-saver";
import config from "../../../config/aws-exports";
import logo from "../../../assets/USP logo R.png";
import usp from "../../../assets/USP.png";
import signature from "../../../assets/signature.png";
import { useSelector } from "react-redux";
import { authData } from "../../../redux/auth/authSlice";
import "./certificate.css";
import Pdf from "react-to-pdf";
import moment from "moment";
export default function CertificatePopUp({ setOpen, open, details, sData }) {
  console.log({ sData });
  const theme = useTheme();
  const certificateDiv = React.useRef();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const userDetails = useSelector(authData);
  const content = React.useRef();
  const handleClose = () => {
    setOpen(false);
  };

  function ImageToPrint2(name, title, date) {
    return `
        <html>
        <head>
        <title>Certificate</title>
        <style>
        
          /* Box sizing rules */
          *,
          *::before,
          *::after {
            box-sizing: border-box;
          }

          /* Remove default margin */
          body,
          h1,
          h2,
          h3,
          h4,
          p,
          figure,
          blockquote,
          dl,
          dd {
            margin: 0;
          }

          /* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
          ul[role='list'],
          ol[role='list'] {
            list-style: none;
          }

          /* Set core root defaults */
          html:focus-within {
            scroll-behavior: smooth;
          }

          /* Set core body defaults */
          body {
            min-height: 100vh;
            text-rendering: optimizeSpeed;
            line-height: 1.5;
          }

          /* A elements that don't have a class get default styles */
          a:not([class]) {
            text-decoration-skip-ink: auto;
          }

          /* Make images easier to work with */
          img,
          picture {
            max-width: 100%;
            display: block;
          }

          /* Inherit fonts for inputs and buttons */
          input,
          button,
          textarea,
          select {
            font: inherit;
          }

          /* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
          @media (prefers-reduced-motion: reduce) {
            html:focus-within {
            scroll-behavior: auto;
            }
            
            *,
            *::before,
            *::after {
              animation-duration: 0.01ms !important;
              animation-iteration-count: 1 !important;
              transition-duration: 0.01ms !important;
              scroll-behavior: auto !important;
            }
          }
         body{
           height: 100vh;
           width: 100vw;
           overflow-x: hidden;
           display: grid;
           place-items: center;
           line-height: 1.1;
         }
        .container {
          max-width: 800px;
          text-align: center;
          border: double;
          padding: 8% 20%;
        }
        .container .img-c {
          display: flex;
          justify-content: center;
        }
        .container .org-container {
          margin: 30px 0;
          line-height: 1;
        }
        .org {
          font-size: 40px;
          font-weight: 900;
        }
        .container .name {
          margin: 0 0 20px 0;
        }
        .container .complete {
          margin: 0 0 20px 0;
        }
        .title-container{
          margin: 0 0 20px 0;
          line-height: 1;
        }
        .title {
          max-width: 550px;
        }
        .container .org-container .org {
          color: ${Theme.color.primary}
        }
        .date{
          margin: 15px 0;
        }
        
        </style>
        </head>
        <body>
          <div class='container'>
            <div class='img-c'>
              <img src="${logo}" alt="logo" height='100'/>
            </div>
            <div class='org-container'>
              <h1 class='org'>USP Education</h1>
              <p>Certificate awarded to</p>
            </div>
            <h1 class='name'>${details.first_name + "" + details.last_name}</h1>
            <p class='complete'>for successfully completing</p>
            <div class='title-container'>
              <h2 class='title'>${sData?.sn}</h2>
              <h2 class='title'>(Live Webinar)</h2>
            </div>
           
            <p class='date'>${moment(parseInt(sData?.startdate)).format(
              "Do MMM YYYY"
            )}</p>
            <div class='img-c'>
             <img src="${signature}" alt="signature" height='130' />
            </div>
          </div>
          <script>
        function step1() {
          setTimeout(() => {
            window.print();
            window.close();
          },1000)
        }
        step1();
        </script>
        </body>
        </html>
      `;
  }

  function PrintImage(source) {
    var Pagelink = "certificate";
    var pwa = window.open(Pagelink, "_new");
    pwa.document.open();
    pwa.document.write(ImageToPrint2(source));
    pwa.document.close();
  }

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="responsive-dialog-title"
        maxWidth="lg"
      >
        <DialogTitle id="responsive-dialog-title" className="clearfix">
          {sData?.sn}
          <IconButton
            onClick={() => {
              handleClose();
            }}
            style={{ position: "absolute", right: 10, top: 10 }}
          >
            <Close sx={{ fontSize: "2rem" }} />
          </IconButton>
        </DialogTitle>
        <DialogContent ref={content} className="overall-container">
          <>
            <div className="container" ref={certificateDiv}>
              <div className="img-c">
                <img src={logo} alt="logo" height="100" />
              </div>
              <div className="org-container">
                <div class="img-c">
                  <img src={usp} alt="USP" height="50" width="auto" />
                </div>
                <p>Certificate awarded to</p>
              </div>
              <h1 className="name">
                {details.first_name + " " + details.last_name}
              </h1>
              <p className="complete">for successfully completing</p>
              <div className="title-container">
                <h2 className="title">{sData?.sn}</h2>
                <h2 className="title">
                  {sData?.stype === 1 && "(Live Webinar)"}
                  {sData?.stype === 2 && "(Classroom)"}
                  {sData?.stype === 3 && "(Hybrid)"}
                </h2>
              </div>
              <p className="date">
                {/* {sData?.date_list
                    ? moment(new Date(sData?.date_list[0]?.date).combineStartTime).format(
                        "DD MMM YYYY"
                      )
                    : moment(new Date(sData?.startdate)).format("DD MMM YYYY")} */}
                Date : &nbsp;
                {sData?.date_list &&
                  sData?.date_list[0]?.combineStartTime &&
                  moment(sData?.date_list[0]?.combineStartTime).format(
                    "DD MMM YYYY"
                  )}
                {sData?.date_list &&
                  sData?.date_list?.length > 1 &&
                  sData?.date_list?.[sData?.date_list?.length - 1]
                    ?.combineEndTime &&
                  " - " +
                    moment(
                      sData?.date_list?.[sData?.date_list?.length - 1]
                        ?.combineEndTime
                    ).format("DD MMM YYYY")}
                {sData?.startdate &&
                  moment(parseInt(sData?.startdate)).format("DD MMM YYYY")}
              </p>
              <div className="img-c">
                <img src={signature} alt="signature" height="130" />
              </div>
            </div>
          </>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Pdf
            targetRef={content}
            filename={`${details.first_name?.replace(
              ".",
              " "
            )}  ${details.last_name?.replace(".", " ")}`}
          >
            {({ toPdf }) => (
              <button className="addbutton" onClick={toPdf}>
                Download
              </button>
            )}
          </Pdf>
        </DialogActions>
      </Dialog>
    </div>
  );
}
