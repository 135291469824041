import React from "react";
import { Select, MenuItem } from '@mui/material';
import "./feedbackform.css";
const FeedbackItem = (props) => {
  const { format, questionNumber, fqi, feedbackresult } = props;

  return (
    <div key={`fqi-${fqi}`}>
      {questionNumber === fqi + 1 && (
        <>
          {format === "multiple-choice-table" && (
            <MultipleChoiceTable {...props} />
          )}
          {format === "multiple-choice" && <MultipleChoice {...props} />}

          {format === "text" && <Text {...props} />}
          {format === "number" && <Number {...props} />}
          {format === "select" && <Selects {...props} />}
        </>
      )}
    </div>
  );
};

function MultipleChoiceTable({
  heading,
  subHeading,
  questionNumber,
  questions,
  fqi,
  feedbackresult
}) {

  return (
    <div className="feedback-item-container">
      <h4>{heading}</h4>
      <p>{subHeading}</p>
      <table className="feedback-table">
        <thead>
          <tr>
            <th></th>
            {questions[0]?.options?.map((option, index) => (
              <th key={`table-header-${index + 1}`}>{option}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {questions.slice(1).map(({ options, question }, sqi) => (
            <tr key={`options-${sqi}`} className="radio-group">
              <td>{question}</td>
              {options.map((option, oi) => (
                <td key={`option-${oi}`}>
                  <input
                    className="radio-option"
                    type="radio"
                    style={{ width: '20px', height: '20px' }}
                    name={`radio-group-${questionNumber}-${sqi}`}
                    value={option}
                    disabled
                    checked={feedbackresult[`q${questionNumber}`][`s${sqi + 1}`] === option}
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

function MultipleChoice({
  heading,
  subHeading,
  question,
  options,
  fqi,
  questionNumber,
  feedbackresult,
}) {
  console.log(feedbackresult[`q${questionNumber}`]);
  return (
    <div className="feedback-item-container">
      <h4>{heading}</h4>
      <p>{subHeading}</p>
      <h4>{question}</h4>

      {options.map((option, oi) => (
        <div key={`option-${oi}`} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
          <input
            type="radio"
            name={`radio-group-${fqi}`}
            id={`radio-group-${fqi}-${oi}`}
            style={{ width: '20px', height: '20px' }}
            checked={feedbackresult[`q${questionNumber}`] === option}
            disabled
          />
          <label style={{ marginLeft: '5px' }} htmlFor={`radio-group-${fqi}-${oi}`}>
            {option} 
          </label>
        </div>
      ))}
    </div>
  );
}

function Text({
  heading,
  subHeading,
  question,
  questionNumber,
  feedbackresult,
}) {
  return (
    <div className="feedback-item-container" style={{ marginTop: "10px" }}>
      <h4>{heading}</h4>
      <p>{subHeading}</p>
      <label>{question}</label>
      <textarea
        rows={3}
        style={{ width: "100%" }}
        required
        value={feedbackresult[`q${questionNumber}`]}
      />
    </div>
  );
}

function Number({
  question,
  questionNumber,
  feedbackresult,
}) {
  return (
    <div className="feedback-item-container" style={{ marginTop: "10px", display: 'grid' }}>

      <label>{question}</label>
      <input
        type="number"
        min={0}
        style={{ width: "10%" }}
        required
        value={feedbackresult[`q${questionNumber}`]}
      />
    </div>
  );
}

function Selects({

  question,
  questionNumber,
  feedbackresult,
}) {
  const selectedValue = feedbackresult[`q${questionNumber}`];
  return (
    <div className="feedback-item-container" style={{ marginTop: "10px", display: 'grid' }}>

      <label>{question}</label>
      <Select
        labelId={`select-label-${questionNumber}`}
        value={selectedValue}
        label={question}
        disabled
      >
        <MenuItem value=""></MenuItem>
        <MenuItem value="USP Education Programs">USP Education Programs</MenuItem>
        <MenuItem value="Reference Standards">Reference Standards</MenuItem>
        <MenuItem value="PAIs">PAIs</MenuItem>
        <MenuItem value="Other USP services">Other USP services</MenuItem>
      </Select>
    </div>
  );
}
export default FeedbackItem;