import React from "react";
import SideNav from "../components/SideNav/sideNav";
import Header from "../components/Header/hearder";
import { Wrapper } from "./layout.styles";
import UserEngagement from "../components/pages/Engage/userengagement";

const UserEngagementLayout = () => {
  return (
    <React.Fragment>
      <Header />
      <Wrapper>
        <SideNav />
        <div style={{ margin: "70px 0px 0px 200px", width: "100%" }}>
          <UserEngagement />
        </div>
      </Wrapper>
    </React.Fragment>
  );
};

export default UserEngagementLayout;
