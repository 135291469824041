import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { makeStyles } from "@material-ui/core/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabPanel from "@mui/lab/TabPanel";
import Skeleton from "@material-ui/lab/Skeleton";
import TabContext from "@mui/lab/TabContext";
import analyticsStyle from "./Analytics.module.scss";
import Paper from "@mui/material/Paper";
import AnalyticsChart from "../Content/Analytics chart/analyticsChart";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../../redux/auth/authSlice";
import { API } from "aws-amplify";
import config from "../../../config/aws-exports";
import DailyLineChart from '../Dashboard/DailyLineChart';
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Constants } from "../../../config/Constants";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  graph: {
    width: "100%",
    height: "fit-content",
    textAlign: "center",
    verticalAlign: "middle",
    margin: "0px",
    padding: "0px",
  },
  paper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
    flexDirection: "column",
    width: "175px",
    height: theme.spacing(14),
  },
  ".css-13xfq8m-MuiTabPanel-root": {
    padding: 0,
  },
}));

export default function Analytics(props) {
  let { userCount } = props;
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState("1");
  const [innerValue, setInnerValue] = useState("1")
  let userDetails = useSelector(authData);

  useEffect(() => {
    getTenantAnalytics();
    return () => {};
  }, []);
  
  async function getTenantAnalytics() {
  setLoading(true);
  const bodyParam = {
    body: {
      oid: userDetails.tenantdata.tenantname,
      schema: Constants.SCHEMA_NAME,
    },
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  try {
    console.log(bodyParam.body);
    const analytics = await API.post(
      config.aws_cloud_logic_custom_name,
      Constants.GET_TENANT_ANALYTICS,
      bodyParam
    );
    if(analytics !== undefined && analytics !== null){
      setData(analytics);
      getTenantInfo();
    }
    
  } catch (error) {
    console.error(error);
    setLoading(false);
  }
};


async function getTenantInfo() {
  setLoading(true);
  const bodyParam = {
    body: {
      st_id: userDetails.tenantdata.st_id,
      schema: Constants.SCHEMA_NAME,
    },
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  try {
    console.log(bodyParam.body);
    const tenants = await API.post(
      config.aws_cloud_logic_custom_name,
      Constants.GET_TENANT_DATA_INFO,
      bodyParam
    );
    //alert("tenants", JSON.stringify(tenants));
    setTotalCount(tenants.body.viewData[0].user_cnt);
    setLoading(false);
  } catch (error) {
    console.error(error);
    setLoading(false);
  }
}

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleInnerChange = (event, newValue) => {
    setInnerValue(newValue);
  };

  return (
    <div>
      <div className={analyticsStyle.paper}>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
        <Paper style={{ height: "150px", width: "300px" }}>
          <div style={{ display: "grid" }}>
            <div className={analyticsStyle.header}>TOTAL USERS</div>
            <div className={analyticsStyle.count}>{totalCount}</div>
          </div>
        </Paper>
      </div>
      <br />
      <div>
        {" "}
        <div className={analyticsStyle.graphholder}>
          {loading === false ? 
          <div className={analyticsStyle.gridholder}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  indicatorColor="primary"
                  textColor="inherit"
                  variant="fullWidth"
                >
                  <Tab label="Active Users" value="1" />
                  <Tab label="Unique Users" value="2" />
                </Tabs>
              </Box>
              <TabPanel
                value="1"
                className={classes[".css-13xfq8m-MuiTabPanel-root"]}
              >
                <TabContext value={innerValue}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={innerValue}
                  onChange={handleInnerChange}
                  aria-label="basic tabs example"
                  indicatorColor="primary"
                  textColor="inherit"
                  variant="fullWidth"
                >
                  <Tab label="Week" value="1" />
                  <Tab label="Month" value="2" />
                  <Tab label="Year" value="3" />
                </Tabs>
              </Box>
              <TabPanel
                value="1"
                className={classes[".css-13xfq8m-MuiTabPanel-root"]}
              >
                <Paper elevation={3} className={classes.graph}>
                  <DailyLineChart data={data.body.activeusers} days={7} />
                </Paper>
              </TabPanel>
              <TabPanel
                value="2"
                className={classes[".css-13xfq8m-MuiTabPanel-root"]}
              >
                <Paper elevation={3} className={classes.graph}>
                  <DailyLineChart data={data.body.activeusers} days={30} />
                </Paper>
              </TabPanel>
              <TabPanel
                value="3"
                className={classes[".css-13xfq8m-MuiTabPanel-root"]}
              >
                <Paper elevation={3} className={classes.graph}>
                  <DailyLineChart data={data.body.activeusers} days={365} />
                </Paper>
              </TabPanel>
              </TabContext>
              </TabPanel>
              <TabPanel
                value="2"
                className={classes[".css-13xfq8m-MuiTabPanel-root"]}
              >
                <TabContext value={innerValue}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={innerValue}
                  onChange={handleInnerChange}
                  aria-label="basic tabs example"
                  indicatorColor="primary"
                  textColor="inherit"
                  variant="fullWidth"
                >
                  <Tab label="Week" value="1" />
                  <Tab label="Month" value="2" />
                  <Tab label="Year" value="3" />
                </Tabs>
              </Box>
              <TabPanel
                value="1"
                className={classes[".css-13xfq8m-MuiTabPanel-root"]}
              >
                <Paper elevation={3} className={classes.graph}>
                  <DailyLineChart data={data.body.uniqueusers} days={7} />
                </Paper>
              </TabPanel>
              <TabPanel
                value="2"
                className={classes[".css-13xfq8m-MuiTabPanel-root"]}
              >
                <Paper elevation={3} className={classes.graph}>
                  <DailyLineChart data={data.body.uniqueusers} days={30} />
                </Paper>
              </TabPanel>
              <TabPanel
                value="3"
                className={classes[".css-13xfq8m-MuiTabPanel-root"]}
              >
                <Paper elevation={3} className={classes.graph}>
                  <DailyLineChart data={data.body.uniqueusers} days={365} />
                </Paper>
              </TabPanel>
              </TabContext>
                
              </TabPanel>
            </TabContext>
          </div> : 
          (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Skeleton
                variant="rect"
                width="100%"
                height={300}
                style={{ marginBottom: "40px" }}
              />{" "}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
