import React, { useState, useEffect, useRef } from "react";

import Table from "@mui/material/Table";
import { API } from "aws-amplify";
import config from "../../../config/aws-exports";
import { Constants } from "../../../config/Constants";
import Button from "@mui/material/Button";
import styled from "styled-components";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import BackDrop from "@material-ui/core/Backdrop/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../../redux/auth/authSlice";
import NothiongHereYet from "../../../assets/Nothing Here Yet.gif";

// Local imports
import { ReactComponent as Close } from "../../../assets/icons/close_black_24dp.svg";
//style impoprts
import Fp from "./FeedbackPopUp.module.scss";
import { Box, DialogContent, FormControl, Radio, RadioGroup } from "@mui/material";

export const StyledGrid = styled.div`
  padding: 1.5rem;
  display: grid;
  grid-template-columns: 350px 100px 100px 130px 100px 100px;
  grid-template-rows: auto;
  grid-row-gap: 0.5rem;
  text-align: center;
  overflow-x: auto;
  .led {
    text-align: left;
  }
  .left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
`;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
}));


const FeedbackPopUp = ({feedback,handleRegClose}) => {
  const classes = useStyles();


  const [selectedValue, setSelectedValue] = useState("");
  const [selectedValue2, setSelectedValue2] = useState("");
  const [selectedValue3, setSelectedValue3] = useState("");
  const [selectedValue4, setSelectedValue4] = useState("");
  const [selectedValue5, setSelectedValue5] = useState("");
  const [selectedValue6, setSelectedValue6] = useState("");
  const [selectedValue7, setSelectedValue7] = useState("");
  const [selectedValue8, setSelectedValue8] = useState("");
  const [selectedValue9, setSelectedValue9] = useState("");
  const [selectedValue10, setSelectedValue10] = useState("");
  const [selectedValue11, setSelectedValue11] = useState("");
  const [question2, setQuestion2] = useState("");
  const [question3, setQuestion3] = useState("");
  const [question1Desc, setQuestion1Desc] = useState("");
  const [question2Desc, setQuestion2Desc] = useState("");
  const [question3Desc, setQuestion3Desc] = useState("");
  const [question4Desc, setQuestion4Desc] = useState("");
  const [question5Desc, setQuestion5Desc] = useState("");
  const [question6, setQuestion6] = useState("");
  const [question7, setQuestion7] = useState("");
  useEffect(()=>{
    if(feedback)  {
      console.log(feedback)
      setSelectedValue(feedback.q1.s1);
        setSelectedValue2(feedback.q1.s2);
        setSelectedValue3(feedback.q1.s3);
        setSelectedValue4(feedback.q1.s4);
        setSelectedValue5(feedback.q1.s5);
        setSelectedValue6(feedback.q4.s1);
        setSelectedValue7(feedback.q4.s2);
        setSelectedValue8(feedback.q4.s3);
        setSelectedValue9(feedback.q4.s4);
        setSelectedValue10(feedback.q5.s1);
        setSelectedValue11(feedback.q5.s2);
        setQuestion2(feedback.q2.s1);
        setQuestion3(feedback.q3.s1);
        setQuestion6(feedback.q6);
        setQuestion7(feedback.q7);
        setQuestion1Desc(feedback.q1.s6);
        setQuestion2Desc(feedback.q2.s2);
        setQuestion3Desc(feedback.q3.s2);
        setQuestion4Desc(feedback.q4.s5);
        setQuestion5Desc(feedback.q5.s3);
    } 
  },[])

  const [spin, setSpin] = useState(false);

  const controlProps = (item) => ({
    checked: selectedValue === item,
    value: item,
    name: "size-radio-button-demo",
    inputProps: { "aria-label": item },
  });
  const controlProps2 = (item) => ({
    checked: selectedValue2 === item,
    value: item,
    name: "size-radio-button-demo-2",
    inputProps: { "aria-label": item },
  });
  const controlProps3 = (item) => ({
    checked: selectedValue3 === item,
    value: item,
    name: "size-radio-button-demo-3",
    inputProps: { "aria-label": item },
  });
  const controlProps4 = (item) => ({
    checked: selectedValue4 === item,
    value: item,
    name: "size-radio-button-demo-4",
    inputProps: { "aria-label": item },
  });
  const controlProps5 = (item) => ({
    checked: selectedValue5 === item,
    value: item,
    name: "size-radio-button-demo-5",
    inputProps: { "aria-label": item },
  });
  const controlProps6 = (item) => ({
    checked: selectedValue6 === item,
    value: item,
    name: "size-radio-button-demo-6",
    inputProps: { "aria-label": item },
  });
  const controlProps7 = (item) => ({
    checked: selectedValue7 === item,
    value: item,
    name: "size-radio-button-demo-7",
    inputProps: { "aria-label": item },
  });
  const controlProps8 = (item) => ({
    checked: selectedValue8 === item,
    value: item,
    name: "size-radio-button-demo-8",
    inputProps: { "aria-label": item },
  });
  const controlProps9 = (item) => ({
    checked: selectedValue9 === item,
    value: item,
    name: "size-radio-button-demo-9",
    inputProps: { "aria-label": item },
  });
  const controlProps10 = (item) => ({
    checked: selectedValue10 === item,
    value: item,
    name: "size-radio-button-demo-10",
    inputProps: { "aria-label": item },
  });
  const controlProps11 = (item) => ({
    checked: selectedValue11 === item,
    value: item,
    name: "size-radio-button-demo-11",
    inputProps: { "aria-label": item },
  });

  

  return (
    <div className={Fp.feedbackmaincontainer}>
      <BackDrop sx={{ color: "#fff" }} open={spin} className={classes.backdrop}>
        <CircularProgress color="inherit" />
      </BackDrop>
      <div className={Fp.topbar}>
        <h3 className={Fp.header}>Total Feedback</h3>
        <Close className={Fp.closeicon} onClick={() => handleRegClose()} />
      </div>
      <DialogContent/>
        <p>
          Q1.Please indicate the extent to which you agree or disagree with each
          statement below.Please select an answer for each statement.
        </p>
        <StyledGrid>
          <p></p>
          <p>Strongly disagree</p>
          <p>Disagree</p>
          <p>Neither disagree nor agree</p>
          <p>Agree</p>
          <p>Strongly agree</p>
          <p className="led">
            Knowledge receiver from the course will positively impact the
            quality of my work
          </p>
          {["0", "1", "2", "3", "4"].map((item) => (
            <p>
              <Radio {...controlProps(item)} />
            </p>
          ))}
          <p className="left">
            <p>Exercises and activities were useful</p>
          </p>
          {["0", "1", "2", "3", "4"].map((item) => (
            <p>
              <Radio {...controlProps2(item)} />
            </p>
          ))}
          <p className="left">
            <p>Training materials / presentation were useful</p>
          </p>
          {["0", "1", "2", "3", "4"].map((item, id) => (
            <p>
              <Radio {...controlProps3(item, id)} />
            </p>
          ))}

          <p className="left">
            <p>I am satisfied with this course overall</p>
          </p>
          {["0", "1", "2", "3", "4"].map((item, id) => (
            <p>
              <Radio {...controlProps4(item, id)} />
            </p>
          ))}
          <p className="led">
            There are others in my organization who would benefit from this USP
            Education course
          </p>
          {["0", "1", "2", "3", "4"].map((item, id) => (
            <p>
              <Radio {...controlProps5(item, id)} />
            </p>
          ))}
        </StyledGrid>
        <Box sx={{ pl: 3, mb: 1.5 }}>
          <p>Additional comments (optional)</p>
          <Box
            component="textarea"
            sx={{ width: "100%", resize: "none", p: 1 }}
            value={question1Desc}
          />
        </Box>
        <p>Q2. Regarding the course content, please choose one</p>

        <FormControl component="fieldset">
          <RadioGroup
            aria-label="gender"
            defaultValue=""
            name="radio-buttons-group-234"
            value={question2}
          >
            <FormControlLabel
              value="0"
              control={<Radio />}
              label="The course content was appropriate for my level of knowledge."
              style={{ padding: "10px", marginLeft: "10px" }}
            />
            <FormControlLabel
              value="1"
              control={<Radio />}
              style={{ padding: "10px", marginLeft: "10px" }}
              label="The course content was too elementary."
            />
            <FormControlLabel
              value="2"
              control={<Radio />}
              style={{ padding: "10px", marginLeft: "10px" }}
              label="The pace of the course was too fast"
            />
          </RadioGroup>
        </FormControl>
        <Box sx={{ pl: 3 }}>
          <p>Additional comments (optional)</p>
          <Box
            component="textarea"
            sx={{ width: "100%", resize: "none", p: 1 }}
            value={question2Desc}
          />
        </Box>
        <p>Q3. Regarding the pace of the course. please choose one</p>

        <FormControl component="fieldset">
          <RadioGroup
            aria-label="pace"
            defaultValue=""
            name="radio-buttons-group-2345"
            value={question3}
          >
            <FormControlLabel
              value="0"
              control={<Radio />}
              style={{ padding: "10px", marginLeft: "10px" }}
              label="I am satisfied with the pace of the course."
            />
            <FormControlLabel
              value="1"
              control={<Radio />}
              style={{ padding: "10px", marginLeft: "10px" }}
              label="The pace of the was too slow."
            />
            <FormControlLabel
              value="2"
              control={<Radio />}
              style={{ padding: "10px", marginLeft: "10px" }}
              label="The pace of the course was too fast."
            />
          </RadioGroup>
        </FormControl>
        <Box sx={{ pl: 3, mb: 1.5 }}>
          <p>Additional comments (optional)</p>
          <Box
            component="textarea"
            sx={{ width: "100%", resize: "none", p: 1 }}
            value={question3Desc}
          />
        </Box>

        <p style={{ marginTop: "2rem" }}>
          Q4.Please indicate the extent to which you agree or disagree with each
          statement below about the instructor.
        </p>
        <StyledGrid>
          <p></p>
          <p>Strongly disagree</p>
          <p>Disagree</p>
          <p>Neither disagree nor agree</p>
          <p>Agree</p>
          <p>Strongly agree</p>

          <p className="led">
            <p>I am satisfied with the instructor overall</p>
          </p>
          {["0", "1", "2", "3", "4"].map((item) => (
            <p>
              <Radio {...controlProps6(item)} />
            </p>
          ))}
          <p className="led">
            <p>The instructor was knowledgeable about the subject matter</p>
          </p>
          {["0", "1", "2", "3", "4"].map((item) => (
            <p>
              <Radio {...controlProps7(item)} />
            </p>
          ))}
          <p className="led">
            <p>The instructor encouraged question and participation</p>
          </p>
          {["0", "1", "2", "3", "4"].map((item) => (
            <p>
              <Radio {...controlProps8(item)} />
            </p>
          ))}
          <p className="left">
            <p>The instructor explained concepts clearly</p>
          </p>
          {["0", "1", "2", "3", "4"].map((item) => (
            <p>
              <Radio {...controlProps9(item)} />
            </p>
          ))}
        </StyledGrid>
        <Box sx={{ pl: 3, mb: 1.5 }}>
          <p>Additional comments (optional)</p>
          <Box
            component="textarea"
            sx={{ width: "100%", resize: "none", p: 1 }}
            value={question4Desc}
          />
        </Box>
        <p style={{ marginTop: "2rem" }}>
          Q5. Please indicate the extent to which you agree or disagree with
          each statement below about Logistics / Organization.
        </p>
        <StyledGrid>
          <p></p>
          <p>Strongly disagree</p>
          <p>Disagree</p>
          <p>Neither disagree nor agree</p>
          <p>Agree</p>
          <p>Strongly agree</p>

          <p className="led">
            <p>The registration process was smooth and effective</p>
          </p>
          {["0", "1", "2", "3", "4"].map((item) => (
            <p>
              <Radio {...controlProps10(item)} />
            </p>
          ))}
          <p className="led">
            <p>The webex tool were conducive to learning</p>
          </p>
          {["0", "1", "2", "3", "4"].map((item) => (
            <p>
              <Radio {...controlProps11(item)} />
            </p>
          ))}
        </StyledGrid>
        <Box sx={{ pl: 3, mb: 1.5 }}>
          <p>What did user like the most during the webinar?</p>
          <Box
            component="textarea"
            sx={{ width: "100%", resize: "none", p: 1 }}
            value={question5Desc}
          />
        </Box>
        <p style={{ marginTop: "2rem" }}>
          Q6. Additional topics user would like to see USP Education cover?
        </p>
        <Box sx={{ pl: 3, mb: 1.5 }}>
          <Box
            component="textarea"
            value={question6}
            sx={{ width: "100%", resize: "none", p: 1 }}
          />
        </Box>
        <p style={{ marginTop: "2rem" }}>
          Q7. Comments of suggestions on how USP Education could improve its
          courses.
        </p>
        <Box sx={{ pl: 3, mb: 1.5 }}>
          <Box
            component="textarea"
            value={question7}
            sx={{ width: "100%", resize: "none", p: 1 }}
          />
        </Box>
      <DialogContent/>
    </div>
  );
};

export default FeedbackPopUp;
