// Dependencies imports
import React, { useState, useEffect } from "react";
import {
  Inject,
  ScheduleComponent,
  Day,
  Month,
  Week,
  WorkWeek,
} from "@syncfusion/ej2-react-schedule";
import Box from "@mui/material/Box";
import { ReactComponent as Edit } from "../../../assets/icons/mode_black_24dp.svg";
import { ReactComponent as Delete } from "../../../assets/icons/delete_outline_black_24dp.svg";

import Paper from "@material-ui/core/Paper";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../../redux/auth/authSlice";
import { Constants } from "../../../config/Constants";
import config from "../../../config/aws-exports";
import { API } from "aws-amplify";
import moment from "moment";
import swal from "@sweetalert/with-react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import Skeleton from "@material-ui/lab/Skeleton";
import TablePagination from "@material-ui/core/TablePagination";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import TabPanel from "@mui/lab/TabPanel";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabContext from "@mui/lab/TabContext";

// Local imports
import AddEvents from "./AddEvent";
import DeleteEvent from "./DeleteEvent";

// Styles imports
import Event from "./Events.module.scss";
import EditEvents from "./EditEvent";

const header = {
  color: "white",
  fontFamily: "nunito",
  fontSize: "15px",
  fontWeight: "400px",
};

const programnames = {
  color: Constants.main_color_1,
  fontFamily: "nunito",
  fontSize: "16px",
  fontWeight: "500px",
  width: "20%",
};

const programtype = {
  color: "#6a6a6a !important",
  fontSize: "14px",
  fontWeight: "400px",
  fontFamily: "nunito",
  paddingBotton: "10px",
  width: "30%",
};

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "start",
    color: theme.palette.text.secondary,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  ".css-13xfq8m-MuiTabPanel-root": {
    padding: 0,
  },
}));
const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

const Events = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [eventsRec, setEvents] = useState([]);
  const [openEdit, setOpenEdit] = useState(false);
  const [value, setValue] = useState("1");
  const [tableData, setTableData] = useState([]);

  const [eventData, setEventData] = useState([]);
  const [events, setevents] = useState([]);
  let userDetails = useSelector(authData);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [loading, setLoading] = useState(true);
  const [deleteData, setDeleteData] = useState({});
  const [EditData, setEditData] = useState({});

  useEffect(() => {
    getEvents();
    return () => {};
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  async function getEvents() {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        st_id: null,
        action: "admin",
        schema: Constants.SCHEMA_NAME,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log(bodyParam.body);
      const events = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_EVENTS_DATA,
        bodyParam
      );
      console.log("events", events);
      let eventsBody = events.events;
      setEvents(eventsBody);
      filter(eventsBody);
      console.log(
        "sort",
        eventsBody.sortBy(function (o) {
          return new Date(parseInt(o.ev_date));
        })
      );
      setTableData(eventsBody);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  }

  (function () {
    if (typeof Object.defineProperty === "function") {
      try {
        Object.defineProperty(Array.prototype, "sortBy", { value: sb });
      } catch (e) {}
    }
    if (!Array.prototype.sortBy) Array.prototype.sortBy = sb;

    function sb(f) {
      for (var i = this.length; i; ) {
        var o = this[--i];
        this[i] = [].concat(f.call(o, o, i), o);
      }
      this.sort(function (a, b) {
        for (var i = 0, len = a.length; i < len; ++i) {
          if (a[i] != b[i]) return a[i] < b[i] ? -1 : 1;
        }
        return 0;
      });
      for (var i = this.length; i; ) {
        this[--i] = this[i][this[i].length - 1];
      }
      return this;
    }
  })();
  // function to open add events modal

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleEditOpen = (item, index) => {
    setEditData(item);
    setOpenEdit(true);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  // function to close add events modal
  const handleClose = () => {
    setOpen(false);
  };
  const handleDeleteOpen = (item) => {
    setDeleteData(item);
    setOpenDelete(true);
  };
  const handleDeleteClose = () => {
    setOpenDelete(false);
  };

  function filter(data) {
    let m = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let temp = [];
    for (let i = 0; i < data.length; i++) {
      let res = data[i].ev_date;
      let timestamp = new Date(res);
      let time = timestamp.getTime();
      let eventDate = timestamp.getDate();

      let seventhday = new Date().setDate(new Date().getDate() + 7);

      let d = new Date().getTime();
      let mon = new Date().getMonth();
      let yea = new Date().getFullYear();
      let date = new Date().getDate();
      let month = 0;
      let dd = timestamp.getDate();
      let mm = timestamp.getMonth() + 1;
      let yyyy = timestamp.getFullYear();

      month = mm;

      if (
        eventDate >= date &&
        time < seventhday &&
        parseInt(yyyy) === yea &&
        month >= mon &&
        parseInt(dd) >= date
      ) {
        let obj = {
          title: data[i].ev_title,
          link: data[i].link,
          start: parseInt(data[i].ev_date),
          amount: data[i].amount,
          payment: data[i].payment,
          description: data[i].ev_desc,
          duration: data[i].duration,
          eid: data[i].eid,
          type: data[i].type,
          bpid: data[i].bpid,
          cbyid: data[i].cbyid,
          topicid: data[i].topicid,
          month: m[month],
          day: yyyy,
        };
        temp.push(obj);
      }
    }

    let cal = [];

    for (let i = 0; i < data.length; i++) {
      let res = new Date(data[i].ev_date);

      let dd = res.getDate();
      let mm = res.getMonth() + 1;
      let yyyy = res.getFullYear();

      let obj = {
        title: data[i].ev_title,
        start: parseInt(data[i].ev_date),
        eid: data[i].ev_id,
        description: data[i].ev_desc,
        link: data[i].link,
        url: data[i].url,

        //description: 'Summer vacation planned for outstation.',
        startTime: new Date(parseInt(data[i].ev_date)),
        endTime: new Date(parseInt(data[i].ev_date)),
      };

      cal.push(obj);
    }
    console.log("cal", cal);

    setevents(cal);
  }

  function EventOpen(args) {
    console.log("args", args);
    args.cancel = true;
    swal({
      buttons: {
        cancel: {
          text: "Close",
          value: null,
          visible: true,
          className: "livesession__btn btn-color-c btn-size-1 btn-hov-c",
          closeModal: true,
        },
        /* confirm: {
            text: "Next",
            value: true,
            visible: true,
            className: `livesession__btn btn-color-cf btn-size-2 btn-hov-cf ${
              args.event.type == "livesession" ? "" : "hideconfirm"
            }`,
            closeModal: true,
          }, */
      },
      closeOnClickOutside: false,
      content: (
        <div style={{ textAlign: "left" }}>
          <h2 style={{ textAlign: "justify", marginBottom: "2rem" }}>
            {args.event.title}
          </h2>
          <h3>
            Date & Time: {moment(args.event.start).format("DD-MM-YYYY hh:mm a")}
          </h3>
          <p style={{ textAlign: "justify" }}>{args.event.description}</p>
          <a href={args.event.link} target="_blank" rel="noreferrer">
            {args.event.link}
          </a>
        </div>
      ),
    }).then((willDelete) => {
      if (willDelete) {
        //handleNavigate(args.event);
      } else {
        // swal("Your imaginary file is safe!");
      }
    });
  }
  let fields = {
    subject: { name: "title", default: "Event" },
    // description: { name: 'link' },
    url: { name: "link" },
    startTime: { name: "startTime" },
    endTime: { name: "endTime" },
  };

  function tableview() {
    return tableData.length >= 1 ? (
      <TableBody>
        {(rowsPerPage > 0
          ? tableData.slice(
              page * rowsPerPage,
              page * rowsPerPage + rowsPerPage
            )
          : tableData
        ).map((item, index) => {
          return (
            <TableRow>
              <TableCell style={programnames}>{`${moment(
                parseInt(item.ev_date)
              ).format("DD-MM-YYYY")}`}</TableCell>
              <TableCell style={programtype}>
                {item.ev_title} <br />
                {item.link === "undefined" || item.link === undefined ? (
                  <div></div>
                ) : (
                  <div>
                    {" "}
                    <a href={item.link} target="blank">
                      {item.link}{" "}
                    </a>
                  </div>
                )}
              </TableCell>
              <TableCell style={{ width: "40%" }}>
                {item.ev_desc.substring(0, 30)}
              </TableCell>
              <TableCell style={{ width: "10%" }}>
                <Edit onClick={() => handleEditOpen(item, index)} />
              </TableCell>
              <TableCell style={{ width: "10%" }}>
                <Delete onClick={() => handleDeleteOpen(item, index)} />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    ) : (
      <TableBody>
        <TableRow>
          <TableCell style={programnames}></TableCell>
          <TableCell style={programtype}>
            <p style={{ paddingLeft: "25%" }}>No Data</p>
          </TableCell>
        </TableRow>
      </TableBody>
    );
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className={Event.main}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <AddEvents
            handleClose={handleClose}
            eventData={eventData}
            setevents={setevents}
            getEvents={getEvents}
          />
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openDelete}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openDelete}>
          <DeleteEvent
            handleDeleteClose={handleDeleteClose}
            eventData={eventData}
            setevents={setevents}
            deleteData={deleteData}
            getEvents={getEvents}
          />
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openEdit}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openEdit}>
          <EditEvents
            handleCloseEdit={handleCloseEdit}
            eventData={eventData}
            setevents={setevents}
            EditData={EditData}
            getEvents={getEvents}
          />
        </Fade>
      </Modal>
      <div className={Event.addholder}>
        <h2 className={Event.heading}></h2>
        <button
          className={Event.add}
          onClick={() => {
            handleOpen();
          }}
        >
          Add
        </button>
      </div>
      <div className={Event.calender}>
        <TabContext value={value}>
          <Box
            sx={{
              borderBottom: 1,
              backgroundColor: "transparent",
              borderColor: "divider",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              indicatorColor="primary"
              textColor="inherit"
              variant="fullWidth"
            >
              <Tab label="Calendar" value="1" />
              <Tab label="Events" value="2" />
            </Tabs>
          </Box>
          <TabPanel
            value="1"
            className={classes[".css-13xfq8m-MuiTabPanel-root"]}
          >
            <Paper className={classes.paper}>
              <ScheduleComponent
                currentView="Month"
                width="100%"
                readonly={true}
                eventClick={(e) => EventOpen(e)}
                eventSettings={{
                  dataSource: events,
                  fields: fields,
                  enableTooltip: true,
                }}
              >
                <Inject services={[Month, Day, Week, WorkWeek]} />
              </ScheduleComponent>
            </Paper>
          </TabPanel>

          <TabPanel
            value="2"
            className={classes[".css-13xfq8m-MuiTabPanel-root"]}
          >
            {loading === false ? (
              <div style={{ marginTop: "20px" }}>
                <TableContainer component={Paper}>
                  <Table aria-label="custom pagination table">
                    <TableHead style={{ background: "#285780" }}>
                      <TableRow>
                        <TableCell style={header}>Event Date</TableCell>
                        <TableCell style={header}>Event Name</TableCell>
                        <TableCell style={header}>Event Description</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    {loading ? "" : tableview()}
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[
                            5,
                            10,
                            25,
                            { label: "All", value: -1 },
                          ]}
                          colSpan={3}
                          count={tableData.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          SelectProps={{
                            inputProps: { "aria-label": "rows per page" },
                            native: true,
                          }}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          ActionsComponent={TablePaginationActions}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Skeleton variant="rect" width={1500} height={500} />{" "}
              </div>
            )}
          </TabPanel>
        </TabContext>
      </div>
    </div>
  );
};

export default Events;
