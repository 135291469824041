// Dependencies import
import React, { useState, useEffect } from "react";
import {
  DataGrid,
  GridToolbar,
  GridToolbarContainer,
} from "@material-ui/data-grid";
import { API } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Rating from "@mui/material/Rating";
import Backdrop from "@material-ui/core/Backdrop";
import Skeleton from "@material-ui/lab/Skeleton";
import moment from "moment";

import CircularProgress from "@material-ui/core/CircularProgress";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../../redux/auth/authSlice";
import Paper from "@mui/material/Paper";
import { Link, useHistory } from "react-router-dom";

// Local imports
import { Constants } from "../../../config/Constants";
import SelectPopup from "./SelectPopup";
import config from "../../../config/aws-exports";
import Breadcrumb from "../../Breadcrumbs/Breadcrumbs";
import TopContentChart from "./TopContentChart";
import video from "../../../assets/objects/video.png";
import audio from "../../../assets/objects/audio.png";
import pdf from "../../../assets/objects/pdf.png";
import inter from "../../../assets/objects/inter.png";
import youtube from "../../../assets/objects/youtube.png";
import vimeo from "../../../assets/objects/vimeo.png";
import quiz from "../../../assets/objects/quiz.png";
import html from "../../../assets/objects/html.png";
import scorm from "../../../assets/objects/scorm.png";

// Style imports
import Tusers from "./TotalUsers.module.scss";

// Custom Toolbard to export and filter for datagrid
function CustomToolbar() {
  return (
    <GridToolbarContainer
      style={{ display: "flex", justifyContent: "flex-end" }}
    >
      <GridToolbar />
    </GridToolbarContainer>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& .super-app-theme--header": {
      backgroundColor: Constants.blue_color,
      fontSize: "16px",
      color: "rgb(255,255,255)",
      fontFamily: "nunito",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
    //cursor: "pointer",
  },
}));

// start of User component
const TotalContent = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState([]);
  const [tenants, setTenants] = useState({});
  const [contentPass, setContentPass] = useState(false);
  const [rows, setRows] = useState([]);
  const [spin, setSpin] = useState(true);
  let userDetails = useSelector(authData);
  const dispatch = useDispatch();

  // Useeffect for api callx
  useEffect(() => {
    getTenants(userDetails.selectedTenant);
    breadcrumb();
    return () => {};
  }, []);

  // function to get Tenants list
  async function getTenants(value) {
    setSpin(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        schema: Constants.SCHEMA_NAME,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log(bodyParam.body);
      const ten = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_TENANTS,
        bodyParam
      );
      if (ten !== undefined && ten !== null) {
        setTenants(ten.body);
        getContent(value);
      }
    } catch (error) {
      console.error(error);
    }
  }

  // function to get Tenants list
  async function getContent(value) {
    setSpin(true);
    const bodyParam = {
      body: {
        org: config.aws_org_id,
        oid: value === value,
        schema: Constants.SCHEMA_NAME,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log(bodyParam.body);
      const allcontent = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.LIST_CONTENT,
        bodyParam
      );
      if (allcontent !== undefined && allcontent !== null) {
        mapAllContent(allcontent.body);
        setContent(allcontent.body);
        setSpin(false);
      }
    } catch (error) {
      console.error(error);
      setSpin(false);
    }
  }

  function mapAllContent(value) {
    let trows = [];
    for (let i = 0; i < value.length; i++) {
      let obj = {};
      obj.id = i;
      obj.title = value[i];
      obj.views = value[i].total_views || "0";
      obj.uniqueviews = value[i].unique_views || "0";
      obj.bounceviews = value[i].bounce_views || "0";
      obj.userrated = value[i].users_rated || "0";
      obj.linkviews = 0;
      obj.ratings = value[i].rating || 0;
      obj.objid = value[i].objid;
      trows.push(obj);
    }
    setRows(trows);
  }

  const tcolumns = [
    {
      field: "title",
      headerName: "Title",
      flex: 1,
      headerClassName: "super-app-theme--header",
      disableExport: false,
      renderCell: (params) => (
        <div
          onClick={() => {
            sendTid(params);
          }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            padding: "2px 3px",
            gap: "5px",
          }}
        >
          <div className={Tusers.objImg}>
            {renderSwitch(params.value.otype)}
          </div>
          <Link
            to="/landingscreen/totalcontent/standalonecontent"
            style={{
              color: "black",
              textDecoration: "none",
              fontFamily: "14px",
              //fontWeight: "bold",
            }}
          >
            {params.value.objectname}
          </Link>
        </div>
      ),
    },
    {
      field: "views",
      headerName: "Views",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "uniqueviews",
      headerName: "Unique Views",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "bounceviews",
      headerName: "Bounce Views",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "userrated",
      headerName: "Users Rated",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    // {
    //     field: "linkviews",
    //     headerName: "Link Views",
    //     flex: 1,
    //     headerClassName: "super-app-theme--header",
    //   },
    {
      field: "ratings",
      headerName: "Ratings",
      flex: 1,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <Rating
          name="read-only"
          value={params.value}
          readOnly
          precision={0.5}
        />
      ),
    },
  ];

  function sendTid(value) {
    console.log("value", value, value.row);
    let sdata = { ...userDetails };
    sdata.objID = value.row.objid;
    sdata.objName = value.row.title.objectname;

    let temp = [...sdata.breadcrumb];

    temp[2] = {
      name: value.row.title.objectname,
      path: "/landingscreen/totalcontent/standalonecontent",
    };

    sdata.breadcrumb = temp;

    dispatch(awsSignIn(sdata));
  }

  const renderSwitch = (param) => {
    if (param === "video") {
      return <img src={video} style={{ height: "32px" }} />;
    } else if (param === "audio") {
      return <img src={audio} style={{ height: "32px" }} />;
    } else if (param === "quiz") {
      return <img src={quiz} style={{ height: "32px" }} />;
    } else if (param === "html") {
      return <img src={html} style={{ height: "32px" }} />;
    } else if (param === "Interactivity" || param === "interactivity") {
      return <img src={inter} style={{ height: "32px" }} />;
    } else if (param === "pdf") {
      return <img src={pdf} style={{ height: "32px" }} />;
    } else if (param === "youtube") {
      return <img src={youtube} style={{ height: "32px" }} />;
    } else if (param === "vimeo") {
      return <img src={vimeo} style={{ height: "32px" }} />;
    } else if (param === "scorm") {
      return <img src={scorm} style={{ height: "32px" }} />;
    } else {
      return param;
    }
  };

  // function to open select teanant popup
  const handleOpen = () => {
    console.log("userDetails", userDetails);
    setOpen(true);
    setContentPass(true);
  };

  // function to close select tenant popup
  const handleTotalClose = () => {
    console.log("userDetails", userDetails);
    setOpen(false);
    setContentPass(false);
  };

  function breadcrumb() {
    let sdata = { ...userDetails };
    if (sdata.breadcrumb == undefined) {
      sdata.breadcrumb = [];
      let temp = [...sdata.breadcrumb];
      temp[0] = {
        name: "Dashboard",
        path: "/landingscreen",
      };
      temp[1] = {
        name: "Total Content",
        path: "/landingscreen/totalcontent",
      };
      sdata.breadcrumb = temp;
    } else {
      let temp = [...sdata.breadcrumb];
      temp[0] = {
        name: "Dashboard",
        path: "/landingscreen",
      };
      temp[1] = {
        name: "Total Content",
        path: "/landingscreen/totalcontent",
      };
      sdata.breadcrumb = temp;
    }
    dispatch(awsSignIn(sdata));
  }

  // Start of main return
  return (
    <div className={Tusers.main}>
      <Breadcrumb Bindex={1} />
      <Backdrop className={classes.backdrop} open={spin}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <SelectPopup
            handleClose={handleTotalClose}
            tenants={tenants}
            getContent={getContent}
            contentPass={contentPass}
          />
        </Fade>
      </Modal>
      {/* <h3 className={Tusers.heading}>Total users</h3> */}
      {userDetails.role === "Admin" ? (
        userDetails.role === "Manager" ? null : (
          <div className={Tusers.tenantselect}>
            <h4 className={Tusers.selecttenant}>Select Organization:</h4>
            <h5
              className={Tusers.tenantname}
              onClick={() => {
                handleOpen();
              }}
            >
              {userDetails.selectedTenant}
            </h5>
          </div>
        )
      ) : null}
      <div className={Tusers.statsholder}>
        <div className={Tusers.total}>
          <h3 className={Tusers.heading}>Total Active Content</h3>
          <h4 className={Tusers.count}>
            {content !== undefined ? content.length : 0}
          </h4>
        </div>
        <div className={Tusers.top}>
          <h3 className={Tusers.heading}>Most Viewed Content</h3>
          {spin === false ? (
            <TopContentChart content={content} />
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Skeleton
                variant="rect"
                width="100%"
                height={100}
                style={{ marginBottom: "40px" }}
              />{" "}
            </div>
          )}
        </div>
      </div>
      <div
        style={{
          height: 600,
          width: "100%",
          overflowY: "auto",
          paddingTop: "20px",
        }}
        className={classes.root}
      >
        <Paper>
          <div
            style={{ height: 500, width: "100%", overflowY: "auto" }}
            className={classes.root}
          >
            <DataGrid
              rows={rows}
              columns={tcolumns}
              className={classes[".MuiDataGrid-root"]}
              components={{
                Toolbar: CustomToolbar,
              }}
            />
          </div>
        </Paper>
      </div>
    </div>
  );
};

export default TotalContent;
