import React, { useState } from "react";
import SignInAndSignUp from "../../SignInAndSignUp/SignInAndSignUp";

export default function Login() {
  const [loginOpen, setLoginOpen] = useState(true);
  return (
    <div>
      <SignInAndSignUp loginOpen={loginOpen} setLoginOpen={setLoginOpen} />
    </div>
  );
}
