import styled, { css } from "styled-components";

export const CustomButton = styled.button`
  background: transparent;
  border-radius: 3px;
  border: 2px solid #093055;
  color: #093055;
  margin: 0 1em;
  padding: 0.25em 1em;
  font-size: 1rem;

  ${(props) =>
    props.primary &&
    css`
      background: #093055;
      color: #ffff;
      border: 2px solid "#ffff";
      font-size: 1.1rem;
    `}
  ${(props) =>
    props.secondary &&
    css`
      background: #ffff;
      color: #093055;
      border: 1px solid #ffff;
    `}
  ${(props) =>
    props.loading &&
    css`
      &:disabled {
        background: transparent;
        border-radius: 3px;
        border: 2px solid #093055;
        color: #093055;
        margin: 0 1em;
        padding: 0.25em 2em;
      }
    `}
`;
