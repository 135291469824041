import { API } from "aws-amplify";
import config from "../../config/aws-exports";
import { useQuery } from "@tanstack/react-query";

const getCountries = async () => {
  try {
    return API.get(config.aws_cloud_logic_custom_name, "/countries");
  } catch (error) {
    // console.error(error)
    throw error;
  }
};

const useCountries = () => {
  return useQuery({
    queryKey: ["/countries"],
    queryFn: getCountries,
  });
};

export default useCountries;
