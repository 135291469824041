import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { API } from "aws-amplify";
import {
  DataGrid,
  GridToolbar,
  GridToolbarContainer,
} from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import Paper from "@mui/material/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";

import { authData, awsSignIn } from "../../../redux/auth/authSlice";
import config from "../../../config/aws-exports";
import { Constants } from "../../../config/Constants";
import SendNotification from "./SendNotification";
import engUser from "./userengagement.module.scss";
import Breadcrumb from "../../Breadcrumbs/Breadcrumbs";

function CustomToolbar() {
  return (
    <GridToolbarContainer
      style={{ display: "flex", justifyContent: "flex-end" }}
    >
      <GridToolbar />
    </GridToolbarContainer>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& .super-app-theme--header": {
      backgroundColor: Constants.blue_color,
      fontSize: "16px",
      color: "rgb(255,255,255)",
      fontFamily: "nunito",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
    cursor: "pointer",
  },
}));
export default function UserEngagement() {
  let userDetails = useSelector(authData);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [spin, setSpin] = useState(false);
  const [rows, setRows] = useState([]);
  const [singleUser, setSingleUser] = useState(false);
  const [singleUserEmail, setSingleUserEmail] = useState({});
  const [suserDetail, setSuserDetail] = useState({});
  const [userList, setUserList] = useState([]);
  const [userListNotify, setUserListNotify] = useState([]);
  const [useremail, setUseremail] = useState([]);
  const dispatch = useDispatch();
  const [selectionModel, setSelectionModel] = useState([]);

  console.log("o", userDetails);
  useEffect(() => {
    getUsers();
    breadcrumb();
    return () => {};
  }, []);

  // function to get Tenants list
  async function getUsers() {
    setSpin(true);
    const bodyParam = {
      body: {
        oid: userDetails.BOID,
        schema: Constants.SCHEMA_NAME,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log(bodyParam.body);
      const list = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_USERS,
        bodyParam
      );
      setUserList(list.body);
      if (list !== undefined && list !== null) {
        const users = list.body;
        let trows = [];
        for (let i = 0; i < users.length; i++) {
          let obj = {};
          obj.id = i;
          obj.firstname = users[i].first_name;
          obj.lastname = users[i].last_name;
          obj.email = users[i].emailid;
          if (users[i].la === undefined || users[i].la === null) {
          } else {
            var diff = moment.utc(parseInt(users[i].la)).fromNow();

            obj.la = diff;
          }

          trows.push(obj);
        }
        setRows(trows);
        setSpin(false);
      }
    } catch (error) {
      console.error(error);
      setSpin(false);
    }
  }
  function checking(index1) {
    // setSuserDetail(index1);
    setSingleUser(false);
    let rowObj = {};
    let rowObjmail = {};
    let userList1 = [];
    let mailids = [];
    console.log("batch", index1);
    for (let i = 0; i < index1.length; i++) {
      rowObj = userList[index1[i]].ur_id;
      rowObjmail = userList[index1[i]].emailid;
      userList1.push(rowObj);
      mailids.push(rowObjmail);
    }

    console.log("batch1", userList1);
    setUserListNotify(userList1);
    setUseremail(mailids);
  }

  const tcolumns = [
    {
      field: "firstname",
      headerName: "First Name",
      flex: 1,
      headerClassName: "super-app-theme--header",

      renderCell: (params) => (
        <div onClick={() => handleOpenSingleUser(params)}>{params.value}</div>
      ),
    },
    {
      field: "lastname",
      headerName: "last Name",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "la",
      headerName: "Last Active",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
  ];
  const handleOpen = () => {
    setOpen(true);
  };

  const handleOpenSingleUser = (params) => {
    setOpen(true);
    setSingleUser(true);
    let user = userList[params.row.id].ur_id;
    let emailid = userList[params.row.id].emailid;
    setSingleUserEmail(emailid);
    setSuserDetail(user);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function breadcrumb() {
    let sdata = { ...userDetails };
    if (sdata.breadcrumb == undefined) {
      sdata.breadcrumb = [];
      let temp = [...sdata.breadcrumb];
      temp[0] = {
        name: "Engage",
        path: "/engage",
      };
      temp[1] = {
        name: userDetails.BOID,
        path: "/engage/userengagement",
      };
      sdata.breadcrumb = temp;
    } else {
      let temp = [...sdata.breadcrumb];
      temp[0] = {
        name: "Engage",
        path: "/engage",
      };
      temp[1] = {
        name: userDetails.BOID,
        path: "/engage/userengagement",
      };
      sdata.breadcrumb = temp;
    }
    dispatch(awsSignIn(sdata));
  }

  return (
    <div className={engUser.main}>
      <Breadcrumb Bindex={1} />
      <Backdrop className={classes.backdrop} open={spin}>
        <CircularProgress color="inherit" />
      </Backdrop>{" "}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <SendNotification
            handleClose={handleClose}
            userListNotify={userListNotify}
            userList={true}
            singleUser={singleUser}
            suserDetail={suserDetail}
            useremail={useremail}
            singleUserEmail={singleUserEmail}
          />
        </Fade>
      </Modal>
      <div className={engUser.topbar}>
        {selectionModel !== undefined && selectionModel.length !== 0 ? (
          <button className={engUser.send} onClick={() => handleOpen()}>
            Send Notification
          </button>
        ) : null}
      </div>
      <Paper>
        <div
          style={{
            height: 600,
            width: "100%",
            overflowY: "auto",
          }}
          className={classes.root}
        >
          <DataGrid
            rows={rows}
            columns={tcolumns}
            className={classes[".MuiDataGrid-root"]}
            components={{
              Toolbar: CustomToolbar,
            }}
            checkboxSelection
            disableSelectionOnClick
            onRowSelected={(e) => console.log(e)}
            onSelectionModelChange={(newSelection) => {
              console.log(newSelection);
              setSelectionModel(newSelection);
              checking(newSelection);
            }}
            selectionModel={selectionModel}
          />
        </div>
      </Paper>
    </div>
  );
}
