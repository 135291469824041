// Dependencies import
import React, { useState, useRef, useEffect } from "react";
import { styled } from "@mui/system";
import swal from "sweetalert2";
import { API } from "aws-amplify";
import config from "../../../config/aws-exports";
import axios from "axios";
import { Button } from "@mui/material";
import BackDrop from "@material-ui/core/Backdrop/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../../redux/auth/authSlice";

// Local imports
import { ReactComponent as Close } from "../../../assets/icons/close_black_24dp.svg";
import { Constants } from "../../../config/Constants";

// Style imports
import Add from "./AddProgram.module.scss";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
}));

const EditProgram = ({
  handleEditClose,
  dataToEdit,
  getPrograms,
  speakerList,
}) => {
  console.log("datato", dataToEdit);
  console.log("speaker", speakerList);
  const [name, setName] = useState(dataToEdit.pname);
  const [image, setImage] = useState(null);
  let userDetails = useSelector(authData);
  // const [startdate, setStartDate] = useState("");
  // const [endDate, setEndDate] = useState("");
  // const [sessionTypeCheck, setSessionTypeCheck] = useState(true);
  // const [paymentTypeCheck, setPaymentTypeCheck] = useState(true);
  // const [speaker, setSpeaker] = useState("");
  // const [slots, setSlots] = useState(0);
  // const [slides, setSlides] = useState("");
  // const [sessionType, setSessionType] = useState("online");
  // const [regStartDate, setRegStartDate] = useState("");
  // const [webLink, setWebLink] = useState("");
  // const [webID, setWebID] = useState("");
  // const [passcode, setPasscode] = useState("");
  // const [paid, setPaid] = useState("no");
  // const [actualPriceINR, setActualPriceINR] = useState("");
  // const [actualPriceUSD, setActualPriceUSD] = useState("");
  // const [priceAftINR, setPriceAftINR] = useState("");
  // const [priceAftUSD, setPriceAftUSD] = useState("");
  // const [earlyBirdINR, setEarlyBirdINR] = useState("");
  // const [earlyBirdUSD, setEarlyBirdUSD] = useState("");
  // const [perDis, setPerDis] = useState("");
  // const [earlyDate, setEarlyDate] = useState("");
  // const [payLater, setPayLater] = useState("");
  // const [payWithin, setPayWithin] = useState("");
  // const [des, setDes] = useState("");
  // const [object, setObject] = useState("");
  // const [offline, setOffline] = useState(false);
  // const [locationValue, setLocationValue] = useState("");
  // const [location, setLocation] = useState(false);
  // const [target, setTarget] = useState("");
  const [nameError, setNameError] = useState("");
  const [imageError, setImageError] = useState("");
  // const [sessionStartTimeError, setSessionStartTimeError] = useState("");
  // const [sessionEndTimeError, setSessionEndTimeError] = useState("");
  // const [speakerError, setSpeakerError] = useState("");
  // const [slotsError, setSlotsError] = useState("");
  // const [slidesError, setSlidesError] = useState("");
  // const [locationError, setLocationError] = useState("");
  // const [sessionTypeError, setSessionTypeError] = useState("");
  // const [webLinkError, setWebLinkError] = useState("");
  // const [webLinkFormatError, setWebLinkFormatError] = useState("");
  // const [paidError, setPaidError] = useState("");
  // const [actualPriceError, setActualPriceError] = useState("");
  // const [view, setView] = useState(false);
  const [imageFileName, setImageFileName] = useState(null);
  // const [sessionFileName, setSessionFileName] = useState(null);
  // const [payLaterDateError, setPayLaterDateError] = useState("");
  const [imageExtentionError, setImageExtentionError] = useState("");
  // const [docExtend, setDocExtend] = useState("");
  // const [docExtentionError, setDocExtentionError] = useState("");
  // const [onChangeSlide, setOnChangeSlides] = useState(false);
  const [spin, setSpin] = useState(false);
  // const [speakerArray, setSpeakerArray] = useState([]);
  const refselectedImageFile = useRef();
  // const refselectedSlideFile = useRef();
  const [changeimage, setChangeImage] = useState(false);
  // const [changeSlide, setChangeSlide] = useState(false);
  // const [earlyError, setEarlyError] = useState("");
  // const [earlyDateError, setEarlyDateError] = useState("");
  // const [startTimeError, setStartTimeError] = useState("");
  // const [wrongDateError, setWrongDateError] = useState("");
  // const [totalSlotsError, setTotalSlotsError] = useState("");
  // const [regStartDateError, setRegStartDateError] = useState("");
  // const [payLaterError, setPayLaterError] = useState("");
  // const [topayment, setTopayment] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    // setName(dataToEdit.sn);
    // setSpeakerArray(dataToEdit.speaker_id);

    // setSlots(dataToEdit.tslots);
    // setWebLink(dataToEdit.weblink);
    // setWebID(dataToEdit.web_id);
    // setPasscode(dataToEdit.web_pass);
    // /*  if (dataToEdit.DTLS.PAID === "yes") { */
    // setActualPriceINR(dataToEdit.priceinr);
    // setActualPriceUSD(dataToEdit.priceusd);
    // setPerDis(dataToEdit.perdis);
    // setPriceAftINR(dataToEdit.priceafterinr);
    // setPriceAftUSD(dataToEdit.priceafterusd);

    // setEarlyBirdINR(dataToEdit.earlypriceinr);
    // setEarlyBirdUSD(dataToEdit.earlypriceusd);
    // if (
    //   !dataToEdit.location_value === "" ||
    //   !dataToEdit.location_value === undefined ||
    //   !dataToEdit.location_value === null
    // ) {
    //   setLocationValue(dataToEdit.location_value);
    // } else {
    //   setLocationValue("");
    // }

    // /*  } */

    // if (dataToEdit.pay_within == null) {
    //   setPayWithin(null);
    // } else {
    //   setPayWithin(convert1(dataToEdit.pay_within));
    // }

    // setStartDate(convert(dataToEdit.startdate));
    // setEndDate(convert(dataToEdit.enddate));
    // setDes(dataToEdit.sdesc);
    // setObject(dataToEdit.lobj);
    // setTarget(dataToEdit.taudi);

    // if (dataToEdit.regdate == null) {
    //   setRegStartDate(null);
    // } else {
    //   setRegStartDate(convert1(dataToEdit.regdate));
    // }

    // if (dataToEdit.slide_oname === null) {
    //   setSlides("");
    // } else {
    //   setSlides(dataToEdit.slide_oname);
    // }

    // if (dataToEdit.oimgname === null) {
    //   setSlides("");
    // } else {
    //   setImage(dataToEdit.oimgname);
    // }
    // setSessionType(dataToEdit.stype);
    // setPayLater(dataToEdit.paylater);
    // setEarlyDate(convert1(dataToEdit.earlydate));
    // setPayWithin(convert1(dataToEdit.pay_within));
    // if (dataToEdit.paid == "true") {
    //   setPaid("yes");
    //   setView(true);
    //   setPaymentTypeCheck(false);
    //   setTopayment(true);
    // } else if (dataToEdit.paid == "false") {
    //   setPaid("no");
    //   setView(false);
    //   setPaymentTypeCheck(true);
    // }

    return () => {};
  }, []);

  // function convert(timestamp) {
  //   let ts = parseInt(timestamp);
  //   var date_not_formatted = new Date(ts);

  //   var formatted_string = date_not_formatted.getFullYear() + "-";

  //   if (date_not_formatted.getMonth() < 9) {
  //     formatted_string += "0";
  //   }
  //   formatted_string += date_not_formatted.getMonth() + 1;
  //   formatted_string += "-";

  //   if (date_not_formatted.getDate() < 10) {
  //     formatted_string += "0";
  //   }
  //   formatted_string += date_not_formatted.getDate();
  //   formatted_string += "T";
  //   if (date_not_formatted.getHours() < 10) {
  //     formatted_string += "0";
  //   }
  //   formatted_string += date_not_formatted.getHours();
  //   formatted_string += ":";
  //   if (date_not_formatted.getMinutes() < 10) {
  //     formatted_string += "0";
  //   }
  //   formatted_string += date_not_formatted.getMinutes();
  //   console.log("sss", formatted_string);

  //   return formatted_string;
  // }
  // function convert1(timestamp) {
  //   let ts = parseInt(timestamp);
  //   var date_not_formatted = new Date(ts);

  //   var formatted_string = date_not_formatted.getFullYear() + "-";

  //   if (date_not_formatted.getMonth() < 9) {
  //     formatted_string += "0";
  //   }
  //   formatted_string += date_not_formatted.getMonth() + 1;
  //   formatted_string += "-";

  //   if (date_not_formatted.getDate() < 10) {
  //     formatted_string += "0";
  //   }
  //   formatted_string += date_not_formatted.getDate();

  //   console.log("sss", formatted_string);

  //   return formatted_string;
  // }

  const onChangeHandlerName = (event) => {
    setName(event.target.value);
  };
  const getExtension = (filename) => {
    let parts = filename.split(".");
    return parts[parts.length - 1];
  };

  const isImage = (filename) => {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "jpg":
      case "png":
      case "jpeg":
        return true;
      default:
        return false;
    }
  };

  // const isDoc = (filename) => {
  //   var ext = getExtension(filename);
  //   switch (ext.toLowerCase()) {
  //     case "pdf":
  //       return true;
  //     default:
  //       return false;
  //   }
  // };

  const onChangeHandlerImage = (event) => {
    setChangeImage(true);
    if (!isImage(event.target.files[0].name)) {
      setImageExtentionError(
        "Wrong file format! Accepted file format: .png/.jpeg/.jpg"
      );
      return;
    }
    setImageExtentionError("");
    setImage({ file: event.target.files[0] });
    refselectedImageFile.current = event.target.files[0];
    console.log("image", image);
  };

  const finalSessionImage = () => {
    if (image.file == null) {
      return;
    }
    if (image.file.size > 5242880) {
      swal(
        "Oops!",
        "Image size is too large. File size cannot be greater than 5mb.",
        "error"
      );
      return;
    }
    if (image.file !== undefined) {
      let uniqueStamp = new Date().getTime();
      setImageFileName(uniqueStamp + ".png");

      let imageName = uniqueStamp + ".png";

      //setSelectedCourseFile((currentState) => (event.target.files[0]));
      var body = {
        type: "program",
        oid: config.aws_org_id,
        filetype: "image/png",
        filename: uniqueStamp + ".png",
      };

      console.log("bodyImg", body);
      uploadfile(body, refselectedImageFile.current);
        addSession(imageName);
    }
  };

  // const onChangeHandlerSlides = (event) => {
  //   setChangeSlide(true);
  //   if (!isDoc(event.target.files[0].name)) {
  //     setDocExtentionError("Wrong file format! Accepted file format: .pdf");
  //     return;
  //   }
  //   setDocExtentionError("");
  //   setOnChangeSlides(true);
  //   setSlides({ file: event.target.files[0] });
  //   refselectedSlideFile.current = event.target.files[0];
  //   console.log("slides", slides.file);
  // };

  // const finalSessionSlide = (imageName) => {
  //   if (slides.file == null) {
  //     return;
  //   }
  //   if (slides.file.size > 5242880) {
  //     swal(
  //       "Oops!",
  //       "File size is too large. File size cannot be greater than 5mb.",
  //       "error"
  //     );
  //     return;
  //   }
  //   let uniqueStamp = new Date().getTime();
  //   let slideName = uniqueStamp + ".pdf";
  //   if (slides.file !== undefined) {
  //     setSessionFileName(uniqueStamp + ".pdf");
  //     //setSelectedCourseFile((currentState) => (event.target.files[0]));
  //     var body = {
  //       type: "sessiondoc",
  //       filetype: refselectedSlideFile.current.type,
  //       filename: uniqueStamp + ".pdf",
  //       oid: config.aws_org_id,
  //     };

  //     console.log("bodyImg", body);
  //     uploadfile(body, refselectedSlideFile.current);
  //     addSession(imageName, slideName);
  //   }
  // };

  async function uploadfile(body, file) {
    // setIstLoading(true);
    const bodyParam = {
      body,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const preSignedData = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_PRESIGNED_URL,
        bodyParam
      );
      console.log("file", file);
      axios
        .put(preSignedData, file, {
          headers: {
            "Content-Type": "image/png",
            "Cache-Control": "no-cache no-store must-revalidate",
          },
        })
        .then((response) => {
          console.log("file upload success");
        })
        .catch((error) => {
          console.error(error);
        });

      //   setIstLoading(false);
    } catch (error) {
      console.error(error);
    }
  }

  // const onChangeHandlerSessionStartDate = (event) => {
  //   setStartDate(event.target.value);
  // };

  // const onChangeHandlerSessionEndDate = (event) => {
  //   setEndDate(event.target.value);
  // };

  // const onChangeHandlerSpeaker = (event) => {
  //   console.log("tv", event.target.value);
  //   console.log(event);
  //   let i = event.target.value;
  //   let speakArray = [...speakerArray];
  //   let obj;

  //   obj = speakerList[i].sp_id;

  //   let flag = false;
  //   for (let j = 0; j < speakArray.length; j++) {
  //     if (speakArray[j] == obj) {
  //       flag = true;
  //     }
  //   }
  //   if (flag === false) {
  //     speakArray.push(obj.toString());
  //   }
  //   setSpeakerArray(speakArray);
  // };

  // console.log("speakerarray", speakerArray);

  // const removeSpeaker = (value) => {
  //   console.log("id", value);

  //   let updatedArray = [...speakerArray];
  //   console.log("updated", updatedArray);
  //   updatedArray = updatedArray.filter(
  //     (deleting) => deleting !== value.toString()
  //   );

  //   setSpeakerArray(updatedArray);
  //   console.log("updatedspeakerarry", speakerArray);
  // };

  // const onChangeHandlerSlots = (event) => {
  //   setSlots(event.target.value);
  // };

  // const onChangeHandleLocation = (event) => {
  //   setLocationValue(event.target.value);
  // };

  // const onChangeHandlerRegStartDate = (event) => {
  //   setRegStartDate(event.target.value);
  // };

  // const onChangeHandlerWebLink = (event) => {
  //   setWebLink(event.target.value);
  // };

  // const onChangeHandlerWebID = (event) => {
  //   setWebID(event.target.value);
  // };

  // const onChangeHandlerPasscode = (event) => {
  //   setPasscode(event.target.value);
  // };

  // const onChangeHandlerPaid = (event) => {
  //   console.log("no", event.target.value);
  //   setPaid(event.target.value);
  //   if (topayment === true) {
  //     setTopayment(false);
  //   } else {
  //     setTopayment(true);
  //   }
  // };

  // const onChangeHandlerActualPriceINR = (event) => {
  //   setActualPriceINR(event.target.value);
  // };

  // const onChangeHandlerActualPriceUSD = (event) => {
  //   setActualPriceUSD(event.target.value);
  // };

  // const onChangeHandlerPerDis = (event) => {
  //   setPerDis(event.target.value);
  //   let discountedPriceINR =
  //     ((100 - event.target.value) / 100) * actualPriceINR;
  //   let discountedPriceUSD =
  //     ((100 - event.target.value) / 100) * actualPriceUSD;
  //   setPriceAftINR(discountedPriceINR);
  //   setPriceAftUSD(discountedPriceUSD);
  // };

  // const onChangeHandlerEarlyBirdINR = (event) => {
  //   setEarlyBirdINR(event.target.value);
  // };

  // const onChangeHandlerEarlyBirdUSD = (event) => {
  //   setEarlyBirdUSD(event.target.value);
  // };

  // const onChangeHandlerPayLater = (event) => {
  //   if (event.target.checked === true) {
  //     setPayLater(true);
  //   } else {
  //     setPayLater(false);
  //   }
  // };
  // const onChangeHandlerEarlyDate = (event) => {
  //   setEarlyDate(event.target.value);
  // };

  // const onChangeHandlerPayWithin = (event) => {
  //   setPayWithin(event.target.value);
  // };

  // const onChangeHandlerDes = (event) => {
  //   setDes(event.target.value);
  // };

  // const onChangeHandlerObject = (event) => {
  //   setObject(event.target.value);
  // };

  // const onChangeHandlerTarget = (event) => {
  //   setTarget(event.target.value);
  // };

  // function handleSessionType(e) {
  //   setSessionType(e.target.value);
  //   setSessionTypeCheck(false);
  // }

  // function validURL(str) {
  //   var pattern = new RegExp(
  //     "^(https?:\\/\\/)?" + // protocol
  //       "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
  //       "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
  //       "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
  //       "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
  //       "(\\#[-a-z\\d_]*)?$",
  //     "i"
  //   ); // fragment locator
  //   return !!pattern.test(str);
  // }

  const submitForm = () => {
    let formIsValid = true;

    setNameError("");
    setImageError("");
    // setEarlyError("");
    // setEarlyDateError("");
    // setSessionStartTimeError("");
    // setStartTimeError("");
    // setWrongDateError("");
    // setTotalSlotsError("");
    // setSessionEndTimeError("");
    // setSpeakerError("");
    // setRegStartDateError("");
    // setSlotsError("");
    // setSlidesError("");
    // setLocationError("");
    // setSessionTypeError("");
    // setWebLinkError("");
    // setWebLinkFormatError("");
    // setPaidError("");
    // setActualPriceError("");
    // setPayLaterError("");
    // setDocExtend("");
    // setPayLaterDateError("");
    // /* setImageExtentionError("");
    // setDocExtentionError("");
    // setDocExtend("");
    // setDocExtentionError(""); */

    if (name === "" || name === null || name.trim() === "") {
      formIsValid = false;
      setNameError("Please enter session name");
      return;
    }
    /*   if (image === null || image.file === undefined) {
      formIsValid = false;
      setImageError("Please upload a session image");
      return;
    } */
    // if (startdate === "" || startdate === null) {
    //   formIsValid = false;
    //   setSessionStartTimeError("Please select session start time");
    //   return;
    // }
    // if (new Date().getTime() > new Date(startdate).getTime()) {
    //   formIsValid = false;
    //   setStartTimeError("Start Time should be in the future");
    //   return;
    // }
    // if (endDate === "" || endDate === null) {
    //   formIsValid = false;
    //   setSessionEndTimeError("Please select session end time");
    //   return;
    // }
    // if (new Date(endDate).getTime() < new Date(startdate).getTime()) {
    //   formIsValid = false;
    //   setWrongDateError("End time should be greater then start time");
    //   return;
    // }
    // /* if (speakerArray.length === 0) {
    //   formIsValid = false;
    //   setSpeakerError("Please select a speaker");
    //   return;
    // } */
    // if (slots !== "") {
    //   if (slots < 0) {
    //     formIsValid = false;
    //     setTotalSlotsError("Slots cannot be in negative");
    //     return;
    //   }
    // }
    // if (location === true) {
    //   if (locationValue === "" || locationValue.trim("") === "") {
    //     formIsValid = false;
    //     setLocationError("Please enter the location");
    //     return;
    //   }
    // }
    // if (regStartDate !== "") {
    //   if (new Date(regStartDate).getTime() > new Date(startdate).getTime()) {
    //     formIsValid = false;
    //     setRegStartDateError(
    //       "Registration start date should be before the session"
    //     );
    //     return;
    //   }
    // }
    // if (offline === false) {
    //   if (webLink === "" || webLink === null) {
    //     formIsValid = false;
    //     setWebLinkError("Please add a webinar link");
    //     return;
    //   }
    // }
    // if (offline === false && webLink !== "") {
    //   if (!validURL(webLink)) {
    //     formIsValid = false;
    //     setWebLinkFormatError("Please enter right URL format");
    //     return;
    //   }
    // }

    // if (paid === "yes") {
    //   if (actualPriceINR === "" || actualPriceINR === null) {
    //     formIsValid = false;
    //     setActualPriceError("Please add the actual sesssion price");
    //     return;
    //   }
    // }
    // if (payLater === true) {
    //   if (payWithin === "") {
    //     formIsValid = false;
    //     setPayLaterError("Please select a date");
    //     return;
    //   }
    //   if (new Date(startdate).getTime() < new Date(payWithin).getTime()) {
    //     formIsValid = false;
    //     setPayLaterDateError("Date should be before the session");
    //     return;
    //   }
    // }
    /*   if (paid === "" || paid === null) {
      formIsValid = false;
      setPaidError("Please select the session payment");
      return;
    } */

    // if (earlyBirdINR !== "") {
    //   if (earlyDate === "") {
    //     formIsValid = false;
    //     setEarlyError("Please select early bird end date");
    //     return;
    //   }
    //   if (new Date(earlyDate).getTime() > new Date(startdate).getTime()) {
    //     formIsValid = false;
    //     setEarlyDateError("Date should be before the session date");
    //     return;
    //   }
    // }

    if (formIsValid === true) {
      if (changeimage === true) {
        finalSessionImage();
      }else {
        addSession();
      }
    }
  };

  const addSession = async (imgName, sldName) => {
    setSpin(true);
    let bodyObj = {};
    bodyObj.pid = dataToEdit.pid;
    bodyObj.pname = name;
    bodyObj.createdon = dataToEdit.created_on;
    bodyObj.subcnt = null ;
    bodyObj.cmpcnt = null;
    bodyObj.cgryid = userDetails.categorydata.ct_id;
    bodyObj.atype = 1;
    bodyObj.schema = Constants.SCHEMA_NAME;
    if (changeimage === true) {
      bodyObj.ptimgname = imgName;
      bodyObj.poimgname = image.file.name;
    } else {
      bodyObj.ptimgname = dataToEdit.ptimgname;
      bodyObj.poimgname = dataToEdit.poimgname;
    }
    

    // if (changeSlide === true) {
    //   bodyObj.slide_oname = sldName;
    //   bodyObj.slide_tname = slides.file.name;
    // } else {
    //   bodyObj.slide_oname = dataToEdit.slide_oname;
    //   bodyObj.slide_tname = dataToEdit.slide_tname;
    // }
    // bodyObj.startdate = new Date(startdate).getTime();
    // bodyObj.enddate = new Date(endDate).getTime();
    // bodyObj.speaker_id = speakerArray;
    // bodyObj.tslots = parseInt(slots);
    // bodyObj.stype = sessionType;
    // bodyObj.regdate = new Date(regStartDate).getTime();
    // bodyObj.web_id = webID;
    // bodyObj.weblink = webLink;
    // bodyObj.web_pass = passcode;

    // if (paid == "no") {
    //   bodyObj.paid = false;
    // } else {
    //   bodyObj.paid = true;
    // }

    // if (paid === "no") {
    //   bodyObj.perdis = null;
    //   bodyObj.priceinr = null;
    //   bodyObj.priceusd = null;
    //   bodyObj.priceafterinr = null;
    //   bodyObj.priceafterusd = null;
    //   bodyObj.earlypriceinr = null;
    //   bodyObj.earlypriceusd = null;
    //   bodyObj.earlydate = null;
    //   bodyObj.paylater = null;
    //   bodyObj.pay_within = null;
    // } else {
    //   bodyObj.perdis = perDis;

    //   bodyObj.priceinr = parseInt(actualPriceINR);
    //   bodyObj.priceusd = parseInt(actualPriceUSD);
    //   bodyObj.priceafterinr = parseInt(priceAftINR);
    //   bodyObj.priceafterusd = parseInt(priceAftUSD);
    //   bodyObj.earlypriceinr = parseInt(earlyBirdINR);
    //   bodyObj.earlypriceusd = parseInt(earlyBirdUSD);
    // }
    // bodyObj.earlydate = new Date(earlyDate).getTime();
    // bodyObj.paylater = payLater;
    // bodyObj.pay_within = new Date(payWithin).getTime();

    // bodyObj.location_value = locationValue;
    // bodyObj.sdesc = des;
    // bodyObj.lobj = object;
    // bodyObj.taudi = target;
    // bodyObj.sn = name;
    // bodyObj.sid = dataToEdit.sid;
    // bodyObj.atype = 1;
    // bodyObj.mt_id = 1;

    const bodyParam = {
      body: bodyObj,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.UPDATE_PROGRAMS,
        bodyParam
      );
      handleEditClose();
      getPrograms();
      swal
        .fire({
          title: "Success",
          text: response.message,
          icon: "success",
          dangerMode: false,
          closeOnClickOutside: false,
        })
        .then((willDelete) => {
          if (willDelete) {
            setSpin(false);
          }
        });
    } catch (error) {
      swal
        .fire({
          title: "Oooops!",
          text: "Soomthing wrong!",
          icon: "warning",
          dangerMode: true,
        })
        .then((willDelete) => {
          if (willDelete) {
            setSpin(false);
          }
        });
      console.log("getUserError", error);
    }
  };

  const Input = styled("input")({
    display: "none",
  });

  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  return (
    <div className={Add.maincontainer}>
      <BackDrop sx={{ color: "#fff" }} open={spin} className={classes.backdrop}>
        <CircularProgress color="inherit" />
      </BackDrop>
      <div className={Add.closeholder}>
        <h3 className={Add.heading}>Edit Live Session</h3>
        <Close className={Add.closeicon} onClick={handleEditClose} />
      </div>

      <div className={Add.formfieldsholder}>
        <div className={Add.leftside}>
          <div
            style={{
              color: "#cc0000",
              textAlign: "start",
              marginLeft: "50%",
              fontSize: "10px",
            }}
          >
            {nameError}
          </div>
          <div className={Add.inputblock}>
            <div className={Add.labelholder}>
              <label for="name" className={Add.label} required>
                Name<span style={{ color: "red", marginTop: "-10px" }}>*</span>
              </label>
            </div>
            <div className={Add.inputholder}>
              <input
                type="text"
                id="name"
                className={Add.inputfield}
                onChange={onChangeHandlerName}
                value={name}
              ></input>
            </div>
          </div>
          <div
            style={{
              color: "#cc0000",
              textAlign: "start",
              marginLeft: "50%",
              fontSize: "10px",
            }}
          >
            {imageError}
          </div>
          <div
            style={{
              color: "#cc0000",
              textAlign: "start",
              marginLeft: "30%",
              fontSize: "10px",
            }}
          >
            {imageExtentionError}
          </div>
          <div className={Add.inputblock}>
            <div className={Add.labelholder}>
              <label for="image" className={Add.label} required>
                Image<span style={{ color: "red", marginTop: "-10px" }}>*</span>
              </label>
            </div>
            <div className={Add.inputholder}>
              <div className={Add.fileupload}>
                <label htmlFor="session-image" className={Add.labelnot}>
                  <Input
                    accept="image/*"
                    id="session-image"
                    type="file"
                    onChange={(e) => onChangeHandlerImage(e)}
                  />
                  <span className={Add.add}>Upload</span>
                  <h5 style={{ fontSize: "10px", color: "grey" }}>
                    {changeimage === true
                      ? image !== null && image.file !== undefined
                        ? image.file.name
                        : ""
                      : image !== null && image !== undefined
                      ? image
                      : dataToEdit.poimgname}
                  </h5>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={Add.buttonholder}>
        <button className={Add.cancel} onClick={handleEditClose}>
          Cancel
        </button>
        <button className={Add.add} type="submit" onClick={submitForm}>
          Edit
        </button>
      </div>
    </div>
  );
};

export default EditProgram;
