// Dependencies imports
import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridToolbar,
  GridToolbarContainer,
} from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/styles";
import Paper from "@mui/material/Paper";
import { awsSignIn, authData } from "../../../redux/auth/authSlice";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";

// Local imports
import { Constants } from "../../../config/Constants";
import config from "../../../config/aws-exports";
import { API } from "aws-amplify";
import { useHistory } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";
import { useSelector, useDispatch } from "react-redux";
import AddTenant from "./AddTenant";
import { ReactComponent as Delete } from "../../../assets/icons/delete_black_24dp.svg";
import { ReactComponent as Edit } from "../../../assets/icons/edit_black_24dp.svg";
import Button from "@material-ui/core/Button";
import EditTenant from './EditTenant';
import DeleteTenant from'./DeleteTenant' ;

// Style imports
import Ten from "./Tenants.module.scss";

// Custom Toolbard to export and filter for datagrid
function CustomToolbar() {
  return (
    <GridToolbarContainer
      style={{ display: "flex", justifyContent: "flex-end" }}
    >
      <GridToolbar />
    </GridToolbarContainer>
  );
}

const useStyles = makeStyles({
  root: {
    "& .super-app-theme--header": {
      backgroundColor: Constants.blue_color,
      fontSize: "16px",
      color: "rgb(255,255,255)",
      fontFamily: "nunito",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
    cursor: "pointer",
  },
});

// Start of main Tenant Component
export default function Tenants() {
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openAdd, setOpenAdd] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const classes = useStyles();
  const history = useHistory();
  const [editData, setEditData] = useState([]);
  const [deleteData, setDeleteData] = useState([]);
  const dispatch = useDispatch();
  const [tenants, setTenants] = useState([]);

  let userDetails = useSelector(authData);
  console.log("data", userDetails);

  useEffect(() => {
    getTenants();
    return () => {};
  }, []);

  const handleEditClick = (param, event) => {
    handleEditOpen();
    console.log("param edit", param.row);
    setEditData(param.row);
  };

  const handleDeleteClick = (param, event) => {
    handleDeleteOpen();
    console.log("param" , param.row)
    setDeleteData(param.row);
  };

  const handleAddOpen = () => {
    setOpenAdd(true);
  };

  const handleAddClose = () => {
    setOpenAdd(false);
  };

  const handleCellClick = (param) => {
    console.log("param", param);
    console.log("param row", param.row);

    let sdata = { ...userDetails };
    sdata.tenantdata= param.row;
    sdata.tenantOID = param.row.st_id;

    if (sdata.breadcrumb == undefined) {
      sdata.breadcrumb = [];
      let temp = [...sdata.breadcrumb];
      temp[0] = {
        name: "Organizations",
        path: "/organization",
      };
      temp[1] = {
        name: param.row.tenant,
        path: "/organization/viewTenant",
      };
      sdata.breadcrumb = temp;
    } else {
      let temp = [...sdata.breadcrumb];
      temp[0] = {
        name: "Organizations",
        path: "/organization",
      };
      temp[1] = {
        name: param.row.tenant,
        path: "/organization/viewTenant",
      };

      sdata.breadcrumb = temp;
    }
    dispatch(awsSignIn(sdata));

    history.push("/organization/viewTenant");
    // console.log(event);
  };

  const handleDeleteOpen = () => {
    setOpenDelete(true);
  };


  const handleDeleteClose = () => {
    setOpenDelete(false);
  }

  const handleEditOpen = () => {
    setOpenEdit(true)
  }

  const handleEditClose = () => {
    setOpenEdit(false)
  }

  

  async function getTenants() {
    setLoading(true);
    const bodyParam = {
      body: {
        org: config.aws_org_id,
        schema: Constants.SCHEMA_NAME
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log(bodyParam.body);
      const tenants = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_TENANT_DATA,
        bodyParam
      );
      //alert("tenants", JSON.stringify(tenants));
      const tenantsAll = tenants.body.viewData;
      let trows = [];
      
      for (let i = 0; i < tenantsAll.length; i++) {
        let obj = {};
        obj.id = i;
        obj.tenant = tenantsAll[i].displayname;
        obj.userCount = tenantsAll[i].user_cnt;
        obj.otenant = tenantsAll[i].oid;
        obj.st_id = tenantsAll[i].st_id;
        obj.iphoneusers = tenantsAll[i].ios_cnt;
        obj.androidusers = tenantsAll[i].android_cnt;
        obj.webusers = tenantsAll[i].web_cnt;
        obj.tenantname = tenantsAll[i].tenantname;
        trows.push(obj);
      };

      const tcolumns = [
        {
          field: "tenant",
          headerName: "Organizations",
          flex: 1,
          headerClassName: "super-app-theme--header",
          renderCell: (param) => {
            return (
              <h3 onClick={() => handleCellClick(param)} className={Ten.head}>{param.value}</h3>
            );
          }
        },
        {
          field: "userCount",
          headerName: "No of users",
          flex: 1,
          headerClassName: "super-app-theme--header",
        },
        {
          field: "edit",
          headerName: "Edit Organization",
          flex: 1,
          sortable: false,
          disableExport: true,
          disableClickEventBubbling: true,
          headerClassName: "super-app-theme--header",
          renderCell: (param) => {
            return (
              <Button
                size="small"
                // style={{ color: "white", background: config.main_color_1, fontSize: "12px", fontWeight: "bold" }}
                startIcon={<Edit />}
                onClick={(event) => {
                  handleEditClick(param, event);
                }}
              ></Button>
            );
          },
        },
        {
          field: "delete",
          headerName: "Delete Organization",
          flex: 1,
          sortable: false,
          disableExport: true,
          disableClickEventBubbling: true,
          headerClassName: "super-app-theme--header",
          renderCell: (param) => {
            return (
              <Button
                size="small"
                // style={{ color: "white", background: "#f32013", fontSize: "12px", fontWeight: "bold" }}
                startIcon={<Delete />}
                onClick={(event) => {
                  handleDeleteClick(param, event);
                }}
              ></Button>
            );
          },
        },
      ];
      //alert(JSON.stringify(trows));
      setRows(trows);
      setColumns(tcolumns);
      setLoading(false);
      setTenants(tenants.body);
    } catch (error) {
      console.error(error);
    }
  }

  // Start of main return
  return (
    <div className={Ten.main}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openAdd}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openAdd}>
          <AddTenant 
          handleAddClose={handleAddClose} 
          getTenants={getTenants}
           />
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openEdit}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openEdit}>
          <EditTenant
            handleEditClose={handleEditClose}
            editData={editData}
            getTenants={getTenants}
          />
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openDelete}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openDelete}>
          <DeleteTenant
            handleDeleteClose={handleDeleteClose}
            deleteData={deleteData}
            getTenants={getTenants}
          />
        </Fade>
      </Modal>
      <div className={Ten.buttonholder}>
        <button className={Ten.addbutton} onClick={handleAddOpen}>
          Add Organization
        </button>
      </div>
      <Paper>
        <div
          style={{ height: 600, width: "100%", overflowY: "auto" }}
          className={classes.root}
        >
          {loading === false ? (
            <DataGrid
              rows={rows}
              columns={columns}
              className={classes[".MuiDataGrid-root"]}
              components={{
                Toolbar: CustomToolbar,
              }}
              disableSelectionOnClick
            />
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Skeleton variant="rect" width="100%" height={600} />{" "}
            </div>
          )}
        </div>
      </Paper>
    </div>
  );
}
