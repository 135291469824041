// Dependencies imports
import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import { API } from "aws-amplify";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import BackDrop from "@material-ui/core/Backdrop/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

// Local imports
import { ReactComponent as Close } from "../../../assets/icons/close_black_24dp.svg";
import config from "../../../config/aws-exports";
import { Constants } from "../../../config/Constants";

// Style imports
import Ap from "./ApproveUsers.module.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
}));

const header = {
  color: "white",
  fontFamily: "nunito",
  fontSize: "16px",
  fontWeight: "400px",
};

const InterestedUsers = ({
  handleCloseInterested,
  interestedData,
  tenantoid,
  tenant,
}) => {
  const classes = useStyles();
  const [registeredUsers, setRegisteredUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [spin, setSpin] = useState(false);
  console.log("waiting list data", interestedData);

  useEffect(() => {
    getRegisteredUsers();
    return () => {};
  }, []);

  async function getRegisteredUsers() {
    setSpin(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        sid: interestedData.sid,
        typeusers: "exp_int",
        tenantoid: tenantoid,
        tenant: tenant,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log(bodyParam.body);
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_REGISTERED_USERS,
        bodyParam
      );
      setRegisteredUsers(response.body);
      setSpin(false);
      //alert("tenants", JSON.stringify(tenants));
    } catch (error) {
      console.error(error);
    }
  }

  console.log("registered users", registeredUsers);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function Tableview() {
    return (
      <TableBody>
        {(rowsPerPage > 0
          ? registeredUsers.slice(
              page * rowsPerPage,
              page * rowsPerPage + rowsPerPage
            )
          : registeredUsers
        ).map((item) => {
          return (
            <TableRow>
              <TableCell>{item.first_name}</TableCell>
              <TableCell>{item.last_name}</TableCell>
              <TableCell>{item.emailid}</TableCell>
              {/*  <TableCell>{}</TableCell> */}
            </TableRow>
          );
        })}
      </TableBody>
    );
  }

  return (
    <div className={Ap.maincontainernew}>
      <BackDrop sx={{ color: "#fff" }} open={spin} className={classes.backdrop}>
        <CircularProgress color="inherit" />
      </BackDrop>
      <div className={Ap.topbar}>
        <h3 className={Ap.header}>Interested Participants</h3>
        <Close className={Ap.closeicon} onClick={handleCloseInterested} />
      </div>
      <Table>
        <TableHead sx={{ background: "#285780" }}>
          <TableRow>
            <TableCell style={header}>First Name</TableCell>
            <TableCell style={header}>Last Name</TableCell>
            <TableCell style={header}>Email</TableCell>
            {/*  <TableCell style={header}>Tenant</TableCell> */}
          </TableRow>
        </TableHead>
        {spin ? null : registeredUsers.length === 0 ? (
          <TableBody>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>No Data</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <Tableview />
        )}
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
              colSpan={4}
              count={registeredUsers.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { "aria-label": "rows per page" },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </div>
  );
};

export default InterestedUsers;
