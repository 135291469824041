import React from "react";

const TenantsSvg = ({ className, cls1, cls2, cls3, cls4 }) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      className={className}
    >
      <defs></defs>

      {/* <path
        className={cls1}
        d="M70.3,53.2H47c-1,0-1.9-1.4-1.9-3.2s.8-3.2,1.9-3.2H70.3c1,0,1.9,1.4,1.9,3.2S71.4,53.2,70.3,53.2Z"
      /> */}
      {/* <path
        className={cls1}
        d="M51.5,21.5V49.9c0,1.3-1.4,2.3-3.2,2.3s-3.2-1-3.2-2.3V21.5c0-1.3,1.4-2.3,3.2-2.3S51.5,20.2,51.5,21.5Z"
      /> */}
      <circle className={cls2} cx="51.32" cy="51.32" r="35.2" />
      <path
        className={cls3}
        d="M62.52,58.6V54.33A5.41,5.41,0,0,0,60.23,50,10.42,10.42,0,0,0,55,48.36a1.82,1.82,0,0,0-.68.13,6.76,6.76,0,0,1-4.24.22,3.24,3.24,0,0,0-2-.32c-1.17.33-2.38.58-3.51,1a5.14,5.14,0,0,0-3.44,3.89,39,39,0,0,0-.35,5.2Z"
      />
      <path
        className={cls1}
        d="M51.74,35.11A5.77,5.77,0,1,1,46,40.82,5.73,5.73,0,0,1,51.74,35.11Z"
      />
      <path
        className={cls4}
        d="M62.52,58.51a33.72,33.72,0,0,1,.29-4.39,4.33,4.33,0,0,1,2.82-3.3c.93-.37,1.93-.58,2.89-.86a2.62,2.62,0,0,1,1.63.27,5.39,5.39,0,0,0,3.48-.18,1.43,1.43,0,0,1,.56-.11,8.41,8.41,0,0,1,4.29,1.4A4.66,4.66,0,0,1,80.36,55V58.6Z"
      />
      <path
        className={cls1}
        d="M71.5,38.9a4.74,4.74,0,1,1-4.75,4.68A4.71,4.71,0,0,1,71.5,38.9Z"
      />
      <path
        className={cls4}
        d="M40.11,58.6V55a4.63,4.63,0,0,0-1.88-3.65A8.35,8.35,0,0,0,34,49.94a1.4,1.4,0,0,0-.56.11,5.42,5.42,0,0,1-3.49.18A2.6,2.6,0,0,0,28.28,50c-1,.28-2,.49-2.89.86a4.31,4.31,0,0,0-2.82,3.3,33.72,33.72,0,0,0-.29,4.39Z"
      />
      <path
        className={cls1}
        d="M31.26,38.9a4.74,4.74,0,1,1-4.76,4.68A4.7,4.7,0,0,1,31.26,38.9Z"
      />
      {/* <rect class="cls-2" x="19.3" y="58.6" width="62.94" height="2.26" /> */}
    </svg>
  );
};

export default TenantsSvg;
