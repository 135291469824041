// Dependencies import
import React, { useState, useEffect } from "react";
import {
  DataGrid,
  GridToolbar,
  GridToolbarContainer,
} from "@material-ui/data-grid";
import { API } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../../redux/auth/authSlice";
import Paper from "@mui/material/Paper";
import { Link, useHistory } from "react-router-dom";

// Local imports
import { Constants } from "../../../config/Constants";
import SelectPopup from "./SelectPopup";
import config from "../../../config/aws-exports";
import Breadcrumb from "../../Breadcrumbs/Breadcrumbs";

// Style imports
import Tusers from "./TotalUsers.module.scss";
import { Skeleton } from "@mui/material";

import Dash from "./Dashboard.module.scss";
import { Box } from "@material-ui/core";
// Custom Toolbard to export and filter for datagrid
function CustomToolbar() {
  return (
    <GridToolbarContainer
      style={{ display: "flex", justifyContent: "flex-end" }}
    >
      <GridToolbar />
    </GridToolbarContainer>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& .super-app-theme--header": {
      backgroundColor: Constants.blue_color,
      fontSize: "16px",
      color: "rgb(255,255,255)",
      fontFamily: "nunito",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
    //cursor: "pointer",
  },
}));

// start of User component
const TotalAttendance = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [tenants, setTenants] = useState({});
  const [totalPass, setTotalPass] = useState(false);
  const [rows, setRows] = useState([]);
  const [spin, setSpin] = useState(false);
  let userDetails = useSelector(authData);
  const dispatch = useDispatch();

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);

  const [value, setValue] = useState(null);
  const [value2, setValue2] = useState(null);

  // Useeffect for api call
  useEffect(() => {
    const getAttendanceCount = async () => {
      setLoading(true);
      const bodyParam = {
        body: {
          oid: config.aws_org_id,
          startdate: startDate,
          enddate: endDate,
          schema: Constants.SCHEMA_NAME,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      try {
        console.log(bodyParam.body);
        const response = await API.post(
          config.aws_cloud_logic_custom_name,
          Constants.GET_ATTENDANCE_COUNT,
          bodyParam
        );
        console.log(response);
        const count = response.body[0].count;
        setCount(count);
        // if (ten !== undefined && ten !== null) {
        //   setTenants(ten.body);
        //   getAllTenantsUsers(value);
        // }
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };
    if (startDate && endDate) {
      getAttendanceCount();
    }
    return () => {};
  }, [startDate, endDate]);
  useEffect(() => {
    async function getTenants(value) {
      setSpin(true);
      const bodyParam = {
        body: {
          oid: config.aws_org_id,
          schema: Constants.SCHEMA_NAME,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      try {
        console.log(bodyParam.body);
        const ten = await API.post(
          config.aws_cloud_logic_custom_name,
          Constants.GET_TENANTS,
          bodyParam
        );
        if (ten !== undefined && ten !== null) {
          setTenants(ten.body);
          getAllTenantsUsers(value);
        }
      } catch (error) {
        console.error(error);
      }
    }
    function breadcrumb() {
      let sdata = { ...userDetails };
      if (sdata.breadcrumb == undefined) {
        sdata.breadcrumb = [];
        let temp = [...sdata.breadcrumb];
        temp[0] = {
          name: "Dashboard",
          path: "/landingscreen",
        };
        temp[1] = {
          name: "Total Users",
          path: "/landingscreen/totalattendance",
        };
        sdata.breadcrumb = temp;
      } else {
        let temp = [...sdata.breadcrumb];
        temp[0] = {
          name: "Dashboard",
          path: "/landingscreen",
        };
        temp[1] = {
          name: "Total Attendance",
          path: "/landingscreen/totalattendance",
        };
        sdata.breadcrumb = temp;
      }
      dispatch(awsSignIn(sdata));
    }
    getTenants(userDetails.selectedTenant);
    breadcrumb();
  }, []);

  // function to get Tenants list
  async function getAllTenantsUsers(value) {
    setSpin(true);
    const bodyParam = {
      body: {
        oid: value === "ALL ORGANIZATION" ? "ALL TENANT" : value,
        org: config.aws_org_id,
        schema: Constants.SCHEMA_NAME,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log(bodyParam.body);
      const allten = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_USERS,
        bodyParam
      );
      if (allten !== undefined && allten !== null) {
        mapAllUsers(allten.body);
        setSpin(false);
      }
    } catch (error) {
      console.error(error);
      setSpin(false);
    }
  }

  // function to get Tenants list

  function mapAllUsers(value) {
    let trows = [];
    for (let i = 0; i < value.length; i++) {
      let obj = {};
      obj.id = i;
      obj.firstname = value[i].first_name;
      obj.lastname = value[i].last_name || "";
      obj.lastname = obj.lastname == "null" ? "" : obj.lastname;
      obj.col2 = value[i].emailid;
      obj.col3 = value[i].oid;
      if (value[i].la === null) {
      } else {
        console.log("last", value[i].la);
        var diff = moment(parseInt(value[i].la)).fromNow();
        console.log("Last Active" + JSON.stringify(diff));
        obj.col4 = diff;
      }
      obj.ur_id = value[i].ur_id;
      trows.push(obj);
    }
    setRows(trows);
  }

  // function to open select teanant popup
  const handleOpen = () => {
    console.log("userDetails", userDetails);
    setOpen(true);
    setTotalPass(true);
  };

  // function to close select tenant popup
  const handleTotalClose = () => {
    console.log("userDetails", userDetails);
    setOpen(false);
    setTotalPass(false);
  };

  function onClick(param) {
    console.log("onClick param", param.row);
    let sdata = { ...userDetails };
    sdata.user = param.row;
    let temp = [...sdata.breadcrumb];

    temp[2] = {
      name: param.row.firstname + " " + param.row.lastname,
      path: "/landingscreen/userInfo",
    };
    sdata.breadcrumb = temp;
    dispatch(awsSignIn(sdata));
  }

  // Start of main return
  return (
    <div className={Tusers.main}>
      <Breadcrumb Bindex={1} />
      <Backdrop className={classes.backdrop} open={spin}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <SelectPopup
            handleClose={handleTotalClose}
            tenants={tenants}
            getAllTenantsUsers={getAllTenantsUsers}
            totalPass={totalPass}
          />
        </Fade>
      </Modal>
      {/* <h3 className={Tusers.heading}>Total users</h3> */}
      {userDetails.role === "Admin" ? (
        userDetails.role === "Manager" ? null : (
          <div className={Tusers.tenantselect}>
            <h4 className={Tusers.selecttenant}>Select Organization:</h4>
            <h5
              className={Tusers.tenantname}
              onClick={() => {
                handleOpen();
              }}
            >
              {userDetails.selectedTenant}
            </h5>
          </div>
        )
      ) : null}
      <div
        style={{
          height: 600,
          width: "100%",
          overflowY: "auto",
          paddingTop: "20px",
        }}
        className={classes.root}
      >
        <Paper>
          <div
            style={{ height: 500, width: "100%", overflowY: "auto" }}
            className={classes.root}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                m: "1rem",
                p: "1rem 0 0 0",
              }}
            >
              <dt>Start Date :</dt>
              <dd>
                <input
                  type="date"
                  name="startdate"
                  id="startdate"
                  value={startDate}
                  onChange={(e) => {
                    setStartDate(e.target.value);
                  }}
                />{" "}
              </dd>
              <dt style={{ marginLeft: "5px" }}>End Date :</dt>
              <dd>
                <input
                  type="date"
                  name="enddate"
                  id="enddate"
                  value={endDate}
                  onChange={(e) => {
                    setEndDate(e.target.value);
                  }}
                />{" "}
              </dd>
            </Box>

            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Paper
                elevation={4}
                sx={{
                  width: 200,
                  height: 200,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  margin: "3rem auto",
                }}
              >
                <h4>
                  {!loading ? (
                    count
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Skeleton variant="rect" width={50} height={28} />{" "}
                    </div>
                  )}
                </h4>
                <h4 className={Dash.type}>Attendance</h4>
              </Paper>
            </Box>

            {/* <DataGrid
              rows={rows}
              columns={tcolumns}
              className={classes[".MuiDataGrid-root"]}
              components={{
                Toolbar: CustomToolbar,
              }}
              disableSelectionOnClick
            /> */}

            {JSON.stringify({
              s: moment(startDate).format(),
              e: moment(endDate).format(),
            })}
          </div>
        </Paper>
      </div>
    </div>
  );
};

export default TotalAttendance;
