import React, { useState, useRef } from "react";
import { CustomButton } from "../../CustomButton/CustomButton";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import swal from "sweetalert2";
import { API } from "aws-amplify";
import { styled } from "@mui/system";

import { Constants } from "../../../config/Constants";
import axios from "axios";
import { ReactComponent as Close } from "../../../assets/icons/close_black_24dp.svg";
import BackDrop from "@material-ui/core/Backdrop/Backdrop";
import AddSpeakerStyle from "./AddSpeaker.module.scss";
// Local imports
import config from "../../../config/aws-exports";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
  formControl: {
    border: "1px solid #6a6a6a",
    height: "fit-content",
    width: "100%",
    borderRadius: "5px",
    fontSize: "14px",
    fontFamily: "nunito",
    padding: "5px",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  program: {
    fontSize: "14px",
    color: "black",
    fontWeight: "400",
    paddingLeft: "5px",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const uploadBtn = {
  color: "#fff",
  background: "#01498e",
  padding: ".5rem 5rem",
  fontSize: "1.4rem",
  "&:hover": {
    backgroundColor: "#01498e",
    color: "#fff",
    boxShadow: "#fff",
  },
};

const AddTopic = (props) => {
  const classes = useStyles();
  let { handleClose, setLoading, loading } = props;
  const refselectedTopicFile = useRef();
  const [topicName, setTopicName] = useState("");
  const [topicNameErr, setTopicNameErr] = useState(false);
  const [topicPicErr, setTopicPicErr] = useState(false);
  const [topicImgErr, setTopicImgErr] = useState("");
  const [topicDesErr, setTopicDesErr] = useState(false);
  const [topicimg, setTopicimg] = useState(null);
  const [userName, setUserName] = useState("");
  const [topicFileName, setTopicFileName] = useState(null);
  // useEffect(() => {
  //     getUsersDetails();
  //   }, []);
  function handleSave() {
    if (topicName === "" || topicName.replace(/\s/g, "").length === 0) {
      setTopicNameErr(true);
      setTopicPicErr(false);
    } else if (topicimg === null || topicimg.file === undefined) {
      setTopicNameErr(false);
      setTopicPicErr(true);
    } else {
      setTopicNameErr(false);
      setTopicPicErr(false);

      let obj = {};
      obj.name = userName;

      handleimageUpload();
    }
  }
  function handleimageUpload() {
    if (topicimg.file == null) {
      return;
    }

    if (topicimg.file.size > 5242880) {
      swal.fire(
        "Oops!",
        "Image size is too large. File size cannot be greater than 5mb.",
        "error"
      );
      return;
    }
    if (topicimg.file !== undefined) {
      let uniqueStamp = new Date().getTime();
      setTopicFileName(uniqueStamp + ".png");
      let imageName = uniqueStamp + ".png";

      var body = {
        type: "category",
        oid: config.aws_org_id,
        filetype: "image/png",
        filename: imageName,
      };

      console.log("bodyImg", body);
      uploadfile(body, refselectedTopicFile.current, imageName);
    }
  }

  async function uploadfile(body, file, imageName) {
    setLoading(true);
    //setIstLoading(true);
    const bodyParam = {
      body,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const preSignedData = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_PRESIGNED_URL,
        /* "/getpreSignedURL", */
        bodyParam
      );
      console.log(file.type);
      axios
        .put(preSignedData, file, {
          headers: {
            "Content-Type": "image/png",
            "Cache-Control": "no-cache no-store must-revalidate",
          },
        })
        .then((response) => {
          //alert(response);
          console.log("file upload success");
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
      addTopic(imageName);
      //setIstLoading(false);
    } catch (error) {
      console.error(error);
    }
  }
  const Input = styled("input")({
    display: "none",
  });

  const getExtension = (filename) => {
    let parts = filename.split(".");
    return parts[parts.length - 1];
  };

  const isImage = (filename) => {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "jpg":
      case "png":
      case "jpeg":
        return true;
      default:
        return false;
    }
  };

  const handleimage = (event) => {
    if (!isImage(event.target.files[0].name)) {
      setTopicImgErr(
        "Wrong file format! Accepted file format: .png/.jpeg/.jpg"
      );
      return;
    }
    setTopicImgErr("");
    setTopicimg({ file: event.target.files[0] });
    refselectedTopicFile.current = event.target.files[0];

    //console.log("image", image);
  };
  const addTopic = async (imageName) => {
    setLoading(true);

    const bodyParam = {
      body: {
        ct_id: 0,
        cname: topicName,
        imgurl: imageName,
        oid: config.aws_org_id,
        atype: 0,
        schema: Constants.SCHEMA_NAME,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      console.log("topic", JSON.stringify(bodyParam.body));
      await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.UPDATE_CATEGORY,
        bodyParam
      );
      handleClose();
      setLoading(false);
      swal
        .fire({
          title: "Success",
          text: "Topic Added successfully",
          icon: "success",
          dangerMode: false,
        })
        .then((willDelete) => {
          if (willDelete) {
            window.location.reload();
          }
        });
    } catch (error) {
      swal
        .fire({
          title: "Oooops!",
          text: "Soomthing wrong!",
          icon: "warning",
          dangerMode: true,
        })
        .then((willDelete) => {
          if (willDelete) {
            setLoading(false);
          }
        });
      console.log("getUserError", error);
    }
  };
  return (
    <div className={AddSpeakerStyle.maindiv}>
      <div className={AddSpeakerStyle.maincontainer}>
        <BackDrop
          sx={{ color: "#fff" }}
          open={loading}
          className={classes.backdrop}
        >
          <CircularProgress color="inherit" />
        </BackDrop>
        <div className={AddSpeakerStyle.closeholder}>
          {/* <h3 className={AddSpeakerStyle.heading}></h3>
          <Close className={AddSpeakerStyle.closeicon} onClick={handleClose} /> */}
        </div>
        <div
          style={{
            color: "#cc0000",
            marginBottom: "12px",
            marginTop: "-10px",
            textAlign: "start",
            marginLeft: "30%",
            fontSize: "12px",
          }}
        >
          {topicNameErr ? "Please enter Topic Name" : null}
        </div>
        <div className={AddSpeakerStyle.field}>
          <label
            style={{
              fontSize: "16px",
              fontWeight: "500px",
              fontFamily: "nunito",
            }}
            for="name"
            required
          >
            Topic Name
          </label>
          <input
            style={{
              border: "1px solid black",
              width: "73%",
              borderRadius: "5px",
              background: "white",
              height: "30px",
              marginBottom: "20px",
              fontSize: "15px",
              paddingLeft: "10px",
              fontWeight: "200px",
            }}
            type="text"
            id="name"
            value={topicName}
            onChange={(e) => setTopicName(e.target.value)}
            required
          />
        </div>
        <div
          style={{
            color: "#cc0000",
            marginBottom: "12px",
            marginTop: "-10px",
            textAlign: "start",
            marginLeft: "30%",
            fontSize: "12px",
          }}
        >
          {topicPicErr ? "Please upload a image" : null}
        </div>
        <div
          style={{
            color: "#cc0000",
            marginBottom: "12px",
            marginTop: "-10px",
            textAlign: "start",
            marginLeft: "20%",
            fontSize: "12px",
          }}
        >
          {topicImgErr}
        </div>
        <div className={AddSpeakerStyle.field}>
          <label
            style={{
              fontSize: "16px",
              fontWeight: "500px",
              fontFamily: "nunito",
            }}
            for="name"
            required
          >
            Topic Image
          </label>
          <div
            style={{
              height: "150px",
              width: "73%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              border: "1px dashed #000",
              marginBottom: "40px",
            }}
          >
            <label
              htmlFor="contained-button-file"
              className={AddSpeakerStyle.hoverbox}
            >
              <Input
                accept="image/*"
                id="contained-button-file"
                multiple
                type="file"
                onChange={(e) => handleimage(e)}
              />

              <span className={AddSpeakerStyle.savebutton}>Upload Image</span>
              <h5>
                {" "}
                {topicimg !== null && topicimg.file !== undefined
                  ? topicimg.file.name
                  : ""}
              </h5>
            </label>
          </div>
        </div>
        <div
          style={{
            color: "#cc0000",
            marginBottom: "12px",
            marginTop: "-10px",
            textAlign: "start",
            marginLeft: "30%",
            fontSize: "12px",
          }}
        >
          {topicDesErr ? "please enter speaker designation" : null}
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
            padding: "0px 20px",
          }}
        >
          <button onClick={handleClose} className={AddSpeakerStyle.closebutton}>
            Close
          </button>
          <button
            className={AddSpeakerStyle.savebutton}
            onClick={() => {
              handleSave();
            }}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddTopic;
