import React, { useState, useRef } from "react";
import { CustomButton } from "../../CustomButton/CustomButton";
import Button from "@mui/material/Button";

import AddSpeakerStyle from "./AddSpeaker.module.scss";

import { styled } from "@mui/system";
import swal from "sweetalert2";
import swal1 from "@sweetalert/with-react";

import { Constants } from "../../../config/Constants";
import BackDrop from "@material-ui/core/Backdrop/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

import axios from "axios";
import config from "../../../config/aws-exports";
import { API } from "aws-amplify";
import { ReactComponent as Close } from "../../../assets/icons/close_black_24dp.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
}));

const AddSpeaker = (props) => {
  let { handleAddSpeakersClose, setpeakerTable, rows, setLoading, loading } =
    props;
  const classes = useStyles();

  const [speakerNameErr, setSpeakerNameErr] = useState(false);
  const [speakerPicErr, setSpeakerPicErr] = useState(false);
  const [speakerImgErr, setSpeakerImgErr] = useState("");
  const [speakerBioErr, setSpeakerBioErr] = useState(false);
  const [speakerDesErr, setSpeakerDesErr] = useState(false);
  const [speakerimg, setSpeakerimg] = useState(null);
  const [speakerFileName, setSpeakerFileName] = useState(null);
  const [userName, setUserName] = useState("");
  const [designation, setDesignation] = useState("");
  const [biography, setBiography] = useState("");
  // const [spin, setSpin] = useState(false);

  const refselectedSpeakerFile = useRef();

  // useEffect(() => {
  //     getUsersDetails();
  //   }, []);
  function handleSave() {
    console.log("hello");
    console.log(userName, "df");
    if (userName === "" || userName.replace(/\s/g, "").length === 0) {
      setSpeakerNameErr(true);
      setSpeakerPicErr(false);
      setSpeakerBioErr(false);
      setSpeakerDesErr(false);
    } else if (speakerimg === null || speakerimg.file === undefined) {
      setSpeakerNameErr(false);
      setSpeakerPicErr(true);
      setSpeakerBioErr(false);
      setSpeakerDesErr(false);
    } else if (
      designation === "" ||
      designation.replace(/\s/g, "").length === 0
    ) {
      setSpeakerNameErr(false);
      setSpeakerPicErr(false);
      setSpeakerBioErr(false);
      setSpeakerDesErr(true);
    } else if (biography === "" || biography.replace(/\s/g, "").length === 0) {
      setSpeakerNameErr(false);
      setSpeakerPicErr(false);
      setSpeakerBioErr(true);
      setSpeakerDesErr(false);
    } else {
      setSpeakerNameErr(false);
      setSpeakerPicErr(false);
      setSpeakerBioErr(false);
      setSpeakerDesErr(false);
      // setSpin(true);
      let obj = {};
      obj.name = userName;
      obj.designation = designation;
      obj.bio = biography;
      // alert(JSON.stringify(obj));
      handleimageUpload();
    }
  }
  const Input = styled("input")({
    display: "none",
  });

  const getExtension = (filename) => {
    let parts = filename.split(".");
    return parts[parts.length - 1];
  };

  const isImage = (filename) => {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "jpg":
      case "png":
      case "jpeg":
        return true;
      default:
        return false;
    }
  };

  const handleimage = (event) => {
    if (!isImage(event.target.files[0].name)) {
      setSpeakerImgErr(
        "Wrong file format! Accepted file format: .png/.jpeg/.jpg"
      );
      return;
    }
    setSpeakerImgErr("");
    setSpeakerimg({ file: event.target.files[0] });
    refselectedSpeakerFile.current = event.target.files[0];

    //console.log("image", image);
  };

  function handleimageUpload() {
    if (speakerimg.file == null) {
      return;
    }

    if (speakerimg.file.size > 5242880) {
      swal1(
        "Oops!",
        "Image size is too large. File size cannot be greater than 5mb.",
        "error"
      );
      return;
    }
    if (speakerimg.file !== undefined) {
      let uniqueStamp = new Date().getTime();
      setSpeakerFileName(uniqueStamp + ".png");
      let imageName = uniqueStamp + ".png";

      var body = {
        type: "speakerimage",
        oid: config.aws_org_id,
        filetype: refselectedSpeakerFile.current.type,
        filename: imageName,
      };

      console.log("bodyImg", body);
      uploadfile(body, refselectedSpeakerFile.current, imageName);
    }
  }

  async function uploadfile(body, file, imageName) {
    setLoading(true);
    //setIstLoading(true);
    const bodyParam = {
      body,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const preSignedData = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_PRESIGNED_URL,
        /* "/getpreSignedURL", */
        bodyParam
      );

      axios
        .put(preSignedData, file, {
          headers: {
            "Content-Type": file.type,
            "Cache-Control": "no-cache no-store must-revalidate",
          },
        })
        .then((response) => {
          console.log("file upload success");
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
      addSpeaker(imageName);
      //setIstLoading(false);
    } catch (error) {
      // alert(JSON.stringify(error));
      console.error(error);
    }
  }

  const addSpeaker = async (imageName) => {
    setLoading(true);

    let uniqueStamp = new Date().getTime();

    const bodyParam = {
      /*   {
        "sp_id": 0,
        "sp_name": "Sumana",
        "designation": "Senior QA",
        "bio": "Working as a professor since 2020",
        "timgname": "3434543322",
        "oimgname": "sumana.png12",
        "mt_id": 1,
        "atype": 0
      } */
      body: {
        sp_id: 0,
        sp_name: userName,
        designation: designation,
        bio: biography,
        timgname: imageName,
        oimgname: speakerimg.file.name,
        mt_id: 1,
        atype: 0,
        schema: Constants.SCHEMA_NAME,
        /*  action: "put",
        speakerdata: {
          id: uniqueStamp + "sid",
          image: {
            timestampname: imageName,
            filename: speakerimg.file.name,
          },
          name: userName,
          designation: designation,
          bio: biography,
        }, */
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.UPDATE_SPEAKER,
        bodyParam
      );
      handleAddSpeakersClose();
      setpeakerTable();
      swal
        .fire({
          title: "Success",
          text: "Speaker Added successfully",
          icon: "success",
          dangerMode: false,
        })
        .then((willDelete) => {
          if (willDelete) {
            setLoading(false);
          }
        });
    } catch (error) {
      swal
        .fire({
          title: "Oooops!",
          text: "Soomthing wrong!",
          icon: "warning",
          dangerMode: true,
        })
        .then((willDelete) => {
          if (willDelete) {
            setLoading(false);
          }
        });
      console.log("getUserError", error);
    }
  };

  return (
    <div className={AddSpeakerStyle.maindiv}>
      <div className={AddSpeakerStyle.maincontainer}>
        <BackDrop
          sx={{ color: "#fff" }}
          open={loading}
          className={classes.backdrop}
        >
          <CircularProgress color="inherit" />
        </BackDrop>
        <div className={AddSpeakerStyle.closeholder}>
          <h3 className={AddSpeakerStyle.heading}>Add Speaker</h3>
          <Close
            className={AddSpeakerStyle.closeicon}
            onClick={handleAddSpeakersClose}
          />
        </div>
        <div
          style={{
            color: "#cc0000",
            marginBottom: "12px",
            marginTop: "-10px",
            textAlign: "start",
            marginLeft: "30%",
            fontSize: "12px",
          }}
        >
          {speakerNameErr ? "Please enter name" : null}
        </div>
        <div className={AddSpeakerStyle.field}>
          <label
            style={{
              fontSize: "16px",
              fontWeight: "500px",
              fontFamily: "nunito",
            }}
            for="name"
            required
          >
            Name
          </label>
          <input
            style={{
              border: "1px solid black",
              width: "73%",
              borderRadius: "5px",
              background: "white",
              height: "30px",
              marginBottom: "20px",
              fontSize: "15px",
              paddingLeft: "10px",
              fontWeight: "200px",
            }}
            type="text"
            id="name"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            required
          />
        </div>
        <div
          style={{
            color: "#cc0000",
            marginBottom: "12px",
            marginTop: "-10px",
            textAlign: "start",
            marginLeft: "30%",
            fontSize: "12px",
          }}
        >
          {speakerPicErr ? "Please upload a image" : null}
        </div>
        <div
          style={{
            color: "#cc0000",
            marginBottom: "12px",
            marginTop: "-10px",
            textAlign: "start",
            marginLeft: "20%",
            fontSize: "12px",
          }}
        >
          {speakerImgErr}
        </div>
        <div className={AddSpeakerStyle.field}>
          <label
            style={{
              fontSize: "16px",
              fontWeight: "500px",
              fontFamily: "nunito",
            }}
            for="name"
            required
          >
            Image
          </label>
          <div
            style={{
              height: "150px",
              width: "73%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              border: "1px dashed #000",
              marginBottom: "40px",
            }}
          >
            <label
              htmlFor="contained-button-file"
              className={AddSpeakerStyle.hoverbox}
            >
              <Input
                accept="image/*"
                id="contained-button-file"
                multiple
                type="file"
                onChange={(e) => handleimage(e)}
              />

              <span className={AddSpeakerStyle.savebutton}>Upload Image</span>
              <h5>
                {" "}
                {speakerimg !== null && speakerimg.file !== undefined
                  ? speakerimg.file.name
                  : ""}
              </h5>
            </label>
          </div>
        </div>
        <div
          style={{
            color: "#cc0000",
            marginBottom: "12px",
            marginTop: "-10px",
            textAlign: "start",
            marginLeft: "30%",
            fontSize: "12px",
          }}
        >
          {speakerDesErr ? "please enter speaker designation" : null}
        </div>

        <div className={AddSpeakerStyle.field}>
          <label
            style={{
              fontSize: "16px",
              fontWeight: "500px",
              fontFamily: "nunito",
            }}
            for="role"
          >
            Designation
          </label>
          <textarea
            style={{
              border: "1px solid black",
              borderRadius: "5px",
              width: "73%",
              background: "white",
              height: "70px",
              marginBottom: "20px",
              fontSize: "15px",
              paddingLeft: "10px",
              fontWeight: "200px",
              resize: "none",
            }}
            id="address"
            rows="10"
            value={designation}
            onChange={(e) => setDesignation(e.target.value)}
          />
        </div>
        <div
          style={{
            color: "#cc0000",
            marginBottom: "12px",
            marginTop: "-10px",
            textAlign: "start",
            marginLeft: "30%",
            fontSize: "12px",
          }}
        >
          {speakerBioErr ? "please enter speaker biography" : null}
        </div>

        <div className={AddSpeakerStyle.field}>
          <label
            style={{
              fontSize: "16px",
              fontWeight: "500px",
              fontFamily: "nunito",
            }}
            for="role"
          >
            Biography
          </label>
          <textarea
            style={{
              border: "1px solid black",
              width: "73%",
              borderRadius: "5px",
              background: "white",
              height: "120px",
              marginBottom: "20px",
              fontSize: "15px",
              paddingLeft: "10px",
              fontWeight: "200px",
              resize: "none",
            }}
            id="address"
            rows="10"
            value={biography}
            onChange={(e) => setBiography(e.target.value)}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
            padding: "0px 20px",
          }}
        >
          <button
            onClick={handleAddSpeakersClose}
            className={AddSpeakerStyle.closebutton}
          >
            Close
          </button>
          <button
            className={AddSpeakerStyle.savebutton}
            onClick={() => {
              handleSave();
            }}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddSpeaker;
