import React, { useState, useEffect, useRef } from "react";

import Table from "@mui/material/Table";
import { API } from "aws-amplify";
import config from "../../../config/aws-exports";
import Button from "@mui/material/Button";
import { styled } from "@mui/system";
import swal from "sweetalert2";
import swal1 from "@sweetalert/with-react";
import { CSVLink } from "react-csv";

import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import BackDrop from "@material-ui/core/Backdrop/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { Constants } from "../../../config/Constants";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { authData } from "../../../redux/auth/authSlice";
// Local imports
import { ReactComponent as Close } from "../../../assets/icons/close_black_24dp.svg";
import Feedback from "./Feedback";
import { FileDownload } from "@mui/icons-material";

// Styles imports
import Ap from "./ApproveUsers.module.scss";
import Li from "./ListofUsers.module.scss";
import CertificatePopUp from "./CertificatePopUp";
import ProInvoice from "../ProformaInvoice/ProInvoice";
import listTopStyle from "./listTopics.module.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
}));

const header = {
  color: "white",
  fontSize: "16px",
  fontWeight: "400px",
};

export default function RegisteredUsers(props) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  let { handleProformaUserClose, proformaData, tenantoid, tenant, getSessionList } = props;
  const [page, setPage] = useState(0);
  const [imageExtentionError, setImageExtentionError] = useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [invoiceimg, setInvoiceimg] = useState(null);

  const [invoiceFileName, setInvoiceFileName] = useState(null);
  const [certificateimg, setCertificateImg] = useState(null);
  const [certificateFileName, setCertificateFileName] = useState(null);
  const [registeredUsers, setRegisteredUsers] = useState([]);

  const [spin, setSpin] = useState(false);
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [invoiceOpen, setInvoiceOpen] = useState(false);
  const [viewingState, setViewingState] = useState({});
  const [certificateOpen, setCertificateOpen] = useState(false);
  const [userDetailsReg, setUserDetailsReg] = useState({});
  const [viewingStatecertificate, setViewingStatecertificate] = useState({});
  const refselectedInvoiceFile = useRef();
  const refselectedCertificateFile = useRef();
  const [newArray, setNewArray] = useState([]);
  const [feedData, setFeedData] = useState({});
  const [inputValue, setInputValue] = useState([]);
  const [showButton, setShowButton] = useState(false);
  let userDetails = useSelector(authData);
  const [openProforma, setOpenProforma] = useState(false);
  const [openProformaEdit, setOpenProformaEdit] = useState(false);
  const [userInvoiceData, setUserInvoiceData] = useState({});
  const [actaualAmt, setActualAmt] = useState("");
  const [actaualAmtUnit, setActualAmtUnit] = useState("");
  const [data, setData] = useState([]);
  const [headers, setHeaders] = useState();
  function handleOpenProforma(item) {
    setOpenProforma(true);
    setUserInvoiceData(item);
  }

  useEffect(() => {
    setHeaders([
      { label: "First Name", key: "first_name" },
      { label: "Last Name", key: "last_name" },
      { label: "Email", key: "emailid" },
      { label: "Attendance", key: "attendance" },
      { label: "Actual Amount", key: "actual_amount" },
    ]);

    if (proformaData.paid == true || proformaData.paid == "true") {
      setHeaders(prevHeaders => [
        ...prevHeaders,
        { label: "Payment Method", key: "paymentstatus" }
      ]);
    }
    getRegisteredUsers();
   
  }, []);

  const handleViewClick = (item) => {
    
    setViewingState(item);
    setInvoiceOpen(true);
  };
 

  const handleFeedBackOpen = (item) => {
    setFeedbackOpen(true);
    setFeedData(item);
  
  };

  const handleFeedBackClose = () => {
    setFeedbackOpen(false);
  };

  async function getRegisteredUsers() {
    setSpin(true);
    const bodyParam = {
      body: {
        sid: proformaData.sid,
        type: "session",
        oid: config.aws_org_id,
        typeusers: "proforma_wait_app",
        tenantoid: tenantoid,
        tenant: tenant,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const reg = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_REGISTERED_USERS,
        bodyParam
      );
     
      setData(funData(reg.body));
      for (let i = 0; i < reg.body.length; i++) {
        reg.body[i].updated_amount = false;
      }
      setRegisteredUsers(reg.body);
      CopyOldAttendence(reg.body);
      setSpin(false);
      
    } catch (error) {
      
      console.error(error);
      setSpin(false);
    }
  }
  const funData = (data) => {
   
    let datalist = data.map((item) => {

      item.paymentstatus = item?.pstatus == 0 ? "NEFT" : item?.pstatus == 1 ? "RazorPay" : item?.pstatus == 2 ? "Pay Later" : "Payment in process";
      item.attendance = item?.attend == 1 ? "Attended" : "Not Attended";
      return item;
    });
    return datalist;
  };
  const getExtension = (filename) => {
    let parts = filename.split(".");
    return parts[parts.length - 1];
  };

  const isDoc = (filename) => {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "pdf":
        return true;
      default:
        return false;
    }
  };

  const handleimageRE = (event, item) => {
   
    if (!isDoc(event.target.files[0].name)) {
      swal1("Wrong file format! Accepted file format: .pdf");
      return;
    }
    setImageExtentionError("");
    setSpin(true);

    setInvoiceimg({ file: event.target.files[0] });
    refselectedInvoiceFile.current = event.target.files[0];
  
    handleimageUpload(event.target.files[0], item);
  };

  function handleimageUpload(imageGot, item) {
   
    if (imageGot == null) {
      return;
    }

    if (imageGot.size > 5242880) {
      swal1(
        "Oops!",
        "File size is too large. File size cannot be greater than 5mb.",
        "error"
      );
      return;
    }
    if (imageGot !== undefined) {
      let uniqueStamp = new Date().getTime();
      let invoiceID = uniqueStamp + ".pdf";
      setInvoiceFileName(uniqueStamp + ".pdf");

      var body = {
        type: "uploadInvoice",
        oid: config.aws_org_id,
        filetype: imageGot.type,
        filename: invoiceID,
      };

      uploadfile(body, imageGot, invoiceID, item);
    }
  }

  async function uploadfile(body, file, invoiceID, item) {
   
    const bodyParam = {
      body,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const preSignedData = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_PRESIGNED_URL,
        bodyParam
      );

      axios
        .put(preSignedData, file, {
          headers: {
            "Content-Type": file.type,
            "Cache-Control": "no-cache no-store must-revalidate",
          },
        })
        .then(() => {
          
          uploadInvoice(invoiceID, item);
        })

        .catch((error) => {
          console.error("There was an error!", error);
        });

    
    } catch (error) {
    
      console.error(error);
    }
  }

  async function uploadInvoice(invoiceID, item) {
   
    const bodyParam = {
      body: {
        sid: proformaData.sid,
        ur_id: item.ur_id,
        invoiceID: invoiceID,
        oid: config.aws_org_id,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const upload = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.UPLOAD_INVOICE,

        bodyParam
      );

      refselectedInvoiceFile.current = null;
      getRegisteredUsers();
      setSpin(false);
      swal1("Uploded Sucessfully");
     
    } catch (error) {
     
      swal1("Uploded unsucessfull");
      console.error(error);
      setSpin(false);
    }
  }

  async function updateInvoice(item) {
   
    setSpin(true);
    let obj = {};
    obj.sn = item.invoice_dtls.sn;
    obj.price = item.invoice_dtls.price;
    obj.date = item.invoice_dtls.sessionDate;
    obj.unit = item.invoice_dtls.unit;
    obj.gst = item.invoice_dtls.gst === "" ? 0 : item.invoice_dtls.gst;
    obj.discount = item.invoice_dtls.discount === "" ? 0 : item.invoice_dtls.discount;
    obj.sid = item.invoice_dtls.sid;
    obj.gstNo = item.invoice_dtls.gstNo;
    obj.address = item.invoice_dtls.address;
    obj.pstatus = null;
    obj.totalAmount = calculateDiscountedPrice(+item.invoice_dtls.price, +item.invoice_dtls.gst, item.invoice_dtls.discount);
    obj.sessionDate = item.invoice_dtls.sessionDate;

    const bodyParam = {
      body: {
        sid: item.invoice_dtls.sid,
        invoiceDtls: obj,
        schema: "uspsm",
        actualamount: calculateDiscountedPrice(+item.invoice_dtls?.price, +item.invoice_dtls?.gst, +item.invoice_dtls?.discount),
        ur_id: item.ur_id,
        emailid: item.emailid,
        action: 'reject'
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      await API.post(
        config.aws_cloud_logic_custom_name,
        "/updateInvoice",
        bodyParam
      );
      setSpin(false);
      handleProformaUserClose();
      getSessionList();
      swal1("Success", "Invoice Updated Successfully", "success");

    } catch (error) {
      console.log(JSON.stringify(error));
    }
}
function calculateDiscountedPrice(price, gst, discount) {
  
  const numericPrice = +price;
  const numericGst = +gst;
  const numericDiscount = +discount;

  if (isNaN(numericPrice) || isNaN(numericGst) || isNaN(numericDiscount)) {
    console.error("Invalid input. Please provide valid numeric values.");
    return null;
  }
  const discountedPrice = numericPrice * (1 - numericDiscount / 100);
  const gstAmount = (discountedPrice * numericGst) / 100;

  var totalAmount = discountedPrice + gstAmount;
  totalAmount = Math.round(totalAmount);
  return totalAmount;
}
async function updateAttendence(unmark) {
  setSpin(true);
 
  const bodyParam = {
    body: {
      sid: proformaData.sid,
      unmark: unmark,
      mark: newArray,
      oid: config.aws_org_id,
    },
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  try {
    await API.post(
      config.aws_cloud_logic_custom_name,
      "/markAttendence ",

      bodyParam
    );

    getRegisteredUsers();
    setSpin(false);
    swal1("Attendence marked Sucessfully");
  } catch (error) {
    setSpin(false);
  }
}

function handleimageUploadCertificate(imageGot, item) {
  if (imageGot == null) {
    return;
  }

  if (imageGot.size > 5242880) {
    swal1(
      "Oops!",
      "Image size is too large. File size cannot be greater than 5mb.",
      "error"
    );
    return;
  }
  if (imageGot !== undefined) {
    let uniqueStamp = new Date().getTime();
    let CertID = uniqueStamp + ".png";
    setCertificateFileName(uniqueStamp + ".png");

    var body = {
      type: "uploadCert",
      oid: config.aws_org_id,
      filetype: imageGot.type,
      filename: CertID,
    };

    uploadfileCertificate(body, imageGot, CertID, item);
  }
}
async function uploadfileCertificate(body, file, CertID, item) {
  
  const bodyParam = {
    body,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  try {
    const preSignedData = await API.post(
      config.aws_cloud_logic_custom_name,
      Constants.GET_PRESIGNED_URL,
      bodyParam
    );

    axios
      .put(preSignedData, file, {
        headers: {
          "Content-Type": file.type,
          "Cache-Control": "no-cache no-store must-revalidate",
        },
      })
      .then(() => {
       
        uploadCertificate(CertID, item);
      })

      .catch((error) => {
        console.error("There was an error!", error);
      });

  
  } catch (error) {
    
    console.error(error);
  }
}
function CopyOldAttendence(registeredUsersGot) {
  let temp = [];
  if (registeredUsersGot !== null) {
    if (registeredUsersGot !== undefined && registeredUsersGot.length > 0) {
      for (let i = 0; i < registeredUsersGot.length; i++) {
        if (registeredUsersGot[i].attend) {
          temp.push(registeredUsersGot[i].ur_id.toString());
        }
      }
    }
  }
  setNewArray(temp);
}

async function uploadCertificate(CertID, item) {
  item.certid = CertID;
  const bodyParam = {
    body: {
      sessionID: proformaData.SID,
      uData: item,

      eid: item.eid,
      tenant: item.tenant,

      oid: config.aws_org_id,
    },
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  try {
    const upload = await API.post(
      config.aws_cloud_logic_custom_name,
      "/uploadCert",

      bodyParam
    );
    getRegisteredUsers();
    setSpin(false);
    swal
      .fire({
        title: "Success",
        text: "Uploaded successfully",
        icon: "success",
        dangerMode: false,
      })
      .then((willDelete) => {
        if (willDelete) {
          setSpin(false);
        }
      });
  
  } catch (error) {
    
    swal
      .fire({
        title: "Oooops!",
        text: "Soomthing wrong!",
        icon: "warning",
        dangerMode: true,
      })
      .then((willDelete) => {
        if (willDelete) {
          setSpin(false);
        }
      });
    console.error(error);
    setSpin(false);
  }
}

const handleChangePage = ( newPage) => {
  setPage(newPage);
};

const handleChangeRowsPerPage = (event) => {
  setRowsPerPage(parseInt(event.target.value, 10));
  setPage(0);
};

function markAttendance() {
  let unmark = [];
  for (let i = 0; i < registeredUsers.length; i++) {
    if (!newArray.includes(registeredUsers[i].ur_id.toString())) {
      unmark.push(registeredUsers[i].ur_id.toString());
    }
  }
  updateAttendence(unmark);
}

const handleSave = async (i) => {
  let newArr = [];
  let upd_value = [...registeredUsers];
  upd_value?.forEach((value) => {
    if (value.actual_amount) {
      let obj = {};
      obj["amount"] = parseFloat(value?.actual_amount);
      obj["ur_id"] = value?.ur_id;
      newArr.push(obj);
    }
  });
 
  const bodyParam = {
    body: {
      type: "session",
      id: proformaData.sid,
      userJson: newArr,
      schema: "uspsm",
    },
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  try {
    await API.post(
      config.aws_cloud_logic_custom_name,
      Constants.UPDATE_ACTUAL_AMOUNT,
      bodyParam
    );
    
    swal1("Success", "Amount Updated Successfully", "success");
    getSessionList();
  } catch (error) {
    console.error(error);
  }
  setInputValue("");
};


return (
  <div className={Ap.maincontainer}>
    <BackDrop sx={{ color: "#fff" }} open={spin} className={classes.backdrop}>
      <CircularProgress color="inherit" />
    </BackDrop>
    <div className={Ap.topbar}>
      <h3 className={Ap.header}>Proforma Waiting Participants</h3>
      <Close className={Ap.closeicon} onClick={() => handleProformaUserClose()} />
    </div>
    {userDetails.role !== "Manager" &&
      proformaData.startdate != null &&
      proformaData.date_list == null && (
        <div className={Ap.attendencediv}>
          {tenantoid === false ? (
            newArray.length === 0 ? (
              <div></div>
            ) : parseInt(proformaData.startdate) > new Date().getTime() ? (
              "Session not started"
            ) : (
              <div>
                <button
                  className={Li.addbutton}
                  onClick={() => markAttendance()}
                >
                  Submit Attendance
                </button>

              </div>
            )
          ) : null}
        </div>
      )}
    {showButton ? (
      <div className={Ap.attendencediv}>
        <button className={Li.savebutton} onClick={handleSave}>
          Save
        </button>
      </div>
    ) : null}
    {userDetails.role !== "Manager" &&
      proformaData.date_list != null &&
      proformaData.startdate == null && (
        <div className={Ap.attendencediv}>
          {tenantoid === false ? (
            newArray.length === 0 ? (
              <div></div>
            ) : new Date(proformaData.date_list.combineStartTime).getTime() >
              new Date().getTime() ? (
              "Session not started"
            ) : (
              <div>
                <button
                  className={Li.addbutton}
                  onClick={() => markAttendance()}
                >
                  Submit Attendance
                </button>
              </div>
            )
          ) : null}
          <Button color="primary">
            <CSVLink
              data={data}
              headers={headers}
              filename="Registered-Users"
              className={listTopStyle.exp_btn}
            >
              <FileDownload />
              Export
            </CSVLink>
          </Button>
        </div>
      )}
   
    <Dialog
      open={feedbackOpen}
      aria-labelledby="responsive-dialog-title"
      scroll="paper"
      maxWidth="lg"
    >
      <Feedback
        handleFeedBackClose={handleFeedBackClose}
        feedData={feedData}
      />
    </Dialog>
  
    <Table>
      <TableHead sx={{ background: "#285780" }}>
        <TableRow>
          <TableCell style={header}>First Name</TableCell>
          <TableCell style={header}>Last Name</TableCell>
          <TableCell style={header}>Email</TableCell>
          {tenantoid === true ? (
            <TableCell style={header}>Feedback</TableCell>
          ) : null}

          {userDetails.role === "Manager" ? null : tenantoid === false ? (
            proformaData.paid === "false" ? null : (
              <TableCell style={header}>Invoice</TableCell>
            )
          ) : null}
        </TableRow>
      </TableHead>

      <TableBody>
        {(rowsPerPage > 0
          ? registeredUsers?.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          )
          : registeredUsers
        )?.map((item, i) => (
          <TableRow>
            <TableCell>{item?.first_name}</TableCell>
            <TableCell>{item?.last_name}</TableCell>
            <TableCell>{item?.emailid}</TableCell>
            {tenantoid === true ? (
              <TableCell>
                {item?.fbstatus || item?.feedback ? (
                  <h3 style={{ fontSize: "14px", fontWeight: "300" }}>
                    No Feedback
                  </h3>
                ) : (
                  <button
                    onClick={() => handleFeedBackOpen(item)}
                    className={Ap.feedbackbutton}
                  >
                    View Feedback
                  </button>
                )}
              </TableCell>
            ) : null}

            {userDetails.role === "Manager" ? null : tenantoid === false ? (
              proformaData.paid === "false" ? null : item?.inid === undefined ||
                item?.inid === null ? (
                <TableCell>
                  <div >
                    <button
                      className={Li.addbutton}
                      onClick={() => {
                        handleOpenProforma(item);
                      }}
                      style={{ marginRight: '5px' }}
                    >
                      Approve
                    </button>
                    <button
                      className={Li.addbutton}
                      onClick={() => {
                        updateInvoice(item);
                      }}
                    >
                      Reject
                    </button>
                  
                  </div>
                </TableCell>
              ) : (
                <TableCell>
                  {item.invoice_dtls === null ? (
                    <div>
                      <div>
                        <button
                          className={Li.addbutton}
                          onClick={() => handleViewClick(item)}
                          style={{
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          View
                        </button>
                      </div>
                      <div>
                        <span>To Reupload </span>
                        <input
                          accept=".pdf"
                       
                          multiple
                          type="file"
                         
                          onChange={(e) => handleimageRE(e, item)}
                        />
                      </div>
                    </div>
                  ) : (
                    <div>
                      <button
                        className={Li.addbutton}
                        onClick={() => {
                          handleOpenProforma(item);
                        }}
                      >
                        Update Invoice
                      </button>
                    </div>
                  )}
                </TableCell>
              )
            ) : null}

          </TableRow>
        ))}
      </TableBody>

      <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
            colSpan={tenantoid === true ? 5 : 7}
            count={registeredUsers?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: { "aria-label": "rows per page" },
              native: true,
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableRow>
      </TableFooter>
    </Table>

    <Dialog
      fullScreen={fullScreen}
      open={invoiceOpen}
      aria-labelledby="responsive-dialog-title-2"
      maxWidth="md"
    >
      <div style={{ padding: "20px" }}>
        <Close
          className={Ap.closeicon}
          style={{ float: "right" }}
          onClick={() => setInvoiceOpen(false)}
        />
        <h5>Invoice - {viewingState.first_name} </h5>
        <iframe
          style={{
            width: "750px",
            height: "800px",
            borderRadius: "10px",
          }}
          src={`https://${config.DOMAIN
            }/${config.aws_org_id.toLowerCase()}-resources/documents/invoices/${viewingState.inid
            }`}
          height="100%"
          width="100%"
          title="pdfView"
        />
       
      </div>
    </Dialog>
   
    <div style={{ padding: "20px" }}>
      <CertificatePopUp
        open={certificateOpen}
        setOpen={setCertificateOpen}
        details={userDetailsReg}
        sData={proformaData}
      />
    </div>
   

    <ProInvoice
      handleCloseProforma={handleProformaUserClose}
      open={openProforma}
      openProformaEdit={openProformaEdit}
      listForReg={proformaData}
      userInvoiceData={userInvoiceData}
      setActualAmt={setActualAmt}
      setActualAmtUnit={setActualAmtUnit}
      getRegisteredUsers={getRegisteredUsers}
      listSessions={getSessionList}
    />
  </div>
);
}
