// Dependencies imports
import React, { useState, useEffect } from "react";
import {
  DataGrid,
  GridToolbar,
  GridToolbarContainer,
} from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/core/styles";
import Rating from "@mui/material/Rating";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../../redux/auth/authSlice";
import { Link } from "react-router-dom";
// Local imports
import { Constants } from "../../../config/Constants";
import { API } from "aws-amplify";
import Breadcrumb from "../../Breadcrumbs/Breadcrumbs";
import SelectPopup from "./SelectPopup";
import config from "../../../config/aws-exports";
import moment from "moment";
import Paper from "@mui/material/Paper";
import image1 from "../Content/2.png";
// Style imports
import Tlive from "./TotalLiveSession.module.scss";

// Custom Toolbard to export and filter for datagrid
function CustomToolbar() {
  return (
    <GridToolbarContainer
      style={{ display: "flex", justifyContent: "flex-end" }}
    >
      <GridToolbar />
    </GridToolbarContainer>
  );
}
const useStyles = makeStyles((theme) => ({
  root: {
    "& .super-app-theme--header": {
      backgroundColor: Constants.blue_color,
      fontSize: "16px",
      color: "rgb(255,255,255)",
      fontFamily: "nunito",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
    //cursor: "pointer",
  },
}));

// Start of totallivesession component
const TotalLiveSession = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [spin, setSpin] = useState(false);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [sessionPass, setSessionPass] = useState(false);
  let userDetails = useSelector(authData);
  const dispatch = useDispatch();
  const [session, setSessions] = useState([]);
  const [tenants, setTenants] = useState([]);

  useEffect(() => {
    breadcrumb();
    getTenants(userDetails.selectedTenant);
    return () => {};
  }, []);

  async function getTenants(value) {
    setSpin(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        schema: Constants.SCHEMA_NAME,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log(bodyParam.body);
      const tenants = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_TENANTS,
        bodyParam
      );
      setTenants(tenants.body);
      getSession(value);
      // setUserCount(user);
      // getSessionCount(value);
    } catch (error) {
      console.error(error);
    }
  }

  async function getSession(value) {
    setSpin(true);
    const bodyParam = {
      body: {
        oid: value === "ALL ORGANIZATION" ? "ALL TENANT" : value,
        schema: Constants.SCHEMA_NAME,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log(bodyParam.body);
      const Session = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_SESSION,
        bodyParam
      );
      if (Session !== undefined && Session !== null) {
        setSessions(Session.body);
        setSpin(false);
        const listAll = Session.body;
        console.log("rows", listAll);
        let trows = [];
        for (let i = 0; i < listAll.length; i++) {
          let obj = {};
          obj.allItem = listAll[i];
          obj.id = i;
          obj.sessionImage = listAll[i].timgname;
          obj.name = listAll[i].sn;

          if (listAll[i]?.date_list) {
            if (listAll[i]?.date_list.length > 0) {
              if (listAll[i]?.date_list[0]?.combineStartTime) {
                obj.datetime = `${moment(
                  new Date(listAll[i]?.date_list[0]?.combineStartTime).getTime()
                ).format("DD-MM-YYYY")} ${moment(
                  new Date(listAll[i]?.date_list[0]?.combineStartTime).getTime()
                ).format("LT")}`;
              }
            }
          } else {
            obj.datetime = "yet to be added!";
          }

          obj.registered = listAll[i].approved_count;
          trows.push(obj);
        }
        const tcolumns = [
          {
            field: "sessionImage",
            headerName: "Session Image",
            flex: 1,
            headerClassName: "super-app-theme--header",
            disableExport: true,
            renderCell: (params) => (
              <img
                alt=""
                src={
                  params.value === null
                    ? image1
                    : `https://${
                        config.DOMAIN
                      }/${config.aws_org_id.toLowerCase()}-resources/images/session-images/${
                        params.value
                      }`
                }
                width="100"
                style={{ margin: "10px" }}
              />
            ),
          },
          {
            field: "name",
            headerName: "Session Title",
            flex: 1,
            headerClassName: "super-app-theme--header",
            // renderCell: (params) => (
            //   <div>
            //     <Link
            //       to="/uspcontent/sessionView"
            //       style={{
            //         color: "black",
            //         textDecoration: "none",
            //         fontFamily: "14px",
            //         //fontWeight: "bold",
            //       }}
            //       // onClick={() => {
            //       //   onSessionTitleClick(params);
            //       // }}
            //     >
            //       {params.value}
            //     </Link>
            //   </div>
            // ),
          },
          {
            field: "datetime",
            headerName: "Date and Time",
            flex: 1,
            // disableExport: true,
            headerClassName: "super-app-theme--header",
          },
          {
            field: "registered",
            headerName: "Total Registrations",
            flex: 1,
            headerClassName: "super-app-theme--header",
          },
        ];
        //alert(JSON.stringify(trows));
        setRows(trows);
        setColumns(tcolumns);
        setSpin(false);
      }
    } catch (error) {
      console.error(error);
    }
  }

  function onSessionTitleClick(item) {
    let sdata = { ...userDetails };
    console.log(item);
    sdata.sessiondata = item.allItem;

    if (sdata.breadcrumb === undefined) {
      sdata.breadcrumb = [];
      let temp = [...sdata.breadcrumb];
      temp[0] = {
        name: "Total Live Session",
        path: "/landingscreen/totallivesession",
      };
      temp[1] = {
        name: item.sn,
        path: "/uspcontent/sessionView",
      };
      sdata.breadcrumb = temp;
    } else {
      let temp = [...sdata.breadcrumb];
      temp[0] = {
        name: "Total Live Session",
        path: "/landingscreen/totallivesession",
      };
      temp[1] = {
        name: item.sn,
        path: "/uspcontent/sessionView",
      };
      sdata.breadcrumb = temp;
    }
    dispatch(awsSignIn(sdata));
  }

  // function to open select teanant popup
  const handleOpen = () => {
    setOpen(true);
    setSessionPass(true);
  };
  // function to close select tenant popup
  const handleClose = () => {
    setOpen(false);
    setSessionPass(false);
  };

  function breadcrumb() {
    let sdata = { ...userDetails };
    if (sdata.breadcrumb == undefined) {
      sdata.breadcrumb = [];
      let temp = [...sdata.breadcrumb];
      temp[0] = {
        name: "Dashboard",
        path: "/landingscreen",
      };
      temp[1] = {
        name: "Total LiveSession",
        path: "/landingscreen/totallivesession",
      };
      sdata.breadcrumb = temp;
    } else {
      let temp = [...sdata.breadcrumb];
      temp[0] = {
        name: "Dashboard",
        path: "/landingscreen",
      };
      temp[1] = {
        name: "Total LiveSession",
        path: "/landingscreen/totallivesession",
      };
      sdata.breadcrumb = temp;
    }
    dispatch(awsSignIn(sdata));
  }
  // Start of main return
  return (
    <div className={Tlive.main}>
      <Breadcrumb Bindex={1} />
      <Backdrop className={classes.backdrop} open={spin}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <SelectPopup
            handleClose={handleClose}
            tenants={tenants}
            getSession={getSession}
            sessionPass={sessionPass}
          />
        </Fade>
      </Modal>
      {/* <h3 className={Tlive.heading}>Total Live Session</h3> */}
      {userDetails.role === "Admin" ? (
        userDetails.role === "Manager" ? (
          <div className={Tlive.tenantselect}>
            <h4 className={Tlive.selecttenant}>Select Organization:</h4>
            <h5
              className={Tlive.tenantname}
              onClick={() => {
                handleOpen();
              }}
            >
              {userDetails.selectedTenant}
            </h5>
          </div>
        ) :  null
      ) : null}
      <div
        style={{
          height: 600,
          width: "100%",
          overflowY: "auto",
          paddingTop: "20px",
        }}
        className={classes.root}
      >
        <Paper>
          <div
            style={{ height: 500, width: "100%", overflowY: "auto" }}
            className={classes.root}
          >
            <DataGrid
              rows={rows}
              columns={columns}
              className={classes[".MuiDataGrid-root"]}
              components={{
                Toolbar: CustomToolbar,
              }}
              disableSelectionOnClick
            />
          </div>
        </Paper>
      </div>
    </div>
  );
};
export default TotalLiveSession;
