// Dependencies imports
import React, { useState, useEffect } from "react";
import {
  DataGrid,
  GridToolbar,
  GridToolbarContainer,
} from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { API } from "aws-amplify";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../../redux/auth/authSlice";

// Local imports
import { Constants } from "../../../config/Constants";
import SelectPopup from "./SelectPopup";
import config from "../../../config/aws-exports";
import Paper from "@mui/material/Paper";
import Breadcrumb from '../../Breadcrumbs/Breadcrumbs';
import NothiongHereYet from "../../../assets/Nothing Here Yet.gif";

// Style imports
import Tusers from "./TotalUsers.module.scss";

// Custom Toolbard to export and filter for datagrid
function CustomToolbar() {
  return (
    <GridToolbarContainer
      style={{ display: "flex", justifyContent: "flex-end" }}
    >
      <GridToolbar />
    </GridToolbarContainer>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& .super-app-theme--header": {
      backgroundColor: Constants.blue_color,
      fontSize: "16px",
      color: "rgb(255,255,255)",
      fontFamily: "nunito",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
    //cursor: "pointer",
  },
}));

// Start of UsersToday component
const UsersToday = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [tenants, setTenants] = useState({});
  const [todayUserPass, setTodayUserPass] = useState(false);
  const [rows, setRows] = useState([]);
  const [spin, setSpin] = useState(false);
  const dispatch = useDispatch();
  let userDetails = useSelector(authData);

  // Useeffect for api call
  useEffect(() => {
    getTenants(userDetails.selectedTenant);
    breadcrumb();
    return () => {};
  }, []);

  // function to get Tenants list
  async function getTodayUsers(value) {
    setSpin(true);
    const bodyParam = {
      body: {
        oid: value === "ALL ORGANIZATION" ? "ALL TENANT" : value,
        schema: Constants.SCHEMA_NAME,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log(bodyParam.body);
      const allten = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_TODAY_USER,
        bodyParam
      );
      if (allten !== undefined && allten !== null) {
        mapAllUsers(allten.body);
        setSpin(false);
      }
    } catch (error) {
      console.error(error);
      setSpin(false);
    }
  }

  // function to get Tenants list
  async function getTenants(value) {
    setSpin(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        schema: Constants.SCHEMA_NAME
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log(bodyParam.body);
      const ten = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_TENANTS,
        bodyParam
      );
      if (ten !== undefined && ten !== null) {
        setTenants(ten.body);
        getTodayUsers(value);
      }
    } catch (error) {
      console.error(error);
    }
  }

  function mapAllUsers(value) {
    let trows = [];
    for (let i = 0; i < value.length; i++) {
      let obj = {};
      obj.id = i;
      obj.col1 = value[i].first_name;
      obj.col2 = value[i].last_name;
      obj.col3 = value[i].emailid;
      obj.col4 = value[i].oid;
      if (value[i].la === undefined) {
      } else {
        var diff = moment.utc(parseInt(value[i].la)).fromNow();
        console.log("Last Active" + JSON.stringify(diff));
        obj.col5 = diff;
      }
      obj.ur_id = value[i].ur_id;
      trows.push(obj);
    }
    setRows(trows);
  }


  const tcolumns = [
    {
      field: "col1",
      headerName: "First Name",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "col2",
      headerName: "Last Name",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "col3",
      headerName: "Email",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "col4",
      headerName: "Organization",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "col5",
      headerName: "Last Active",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
  ];

  // function to open select teanant popup
  const handleOpen = () => {
    setOpen(true);
    setTodayUserPass(true);
  };

  // function to close select tenant popup
  const handleClose = () => {
    setOpen(false);
    setTodayUserPass(false);
  };

  function breadcrumb() {
    let sdata = { ...userDetails };
    if (sdata.breadcrumb == undefined) {
        sdata.breadcrumb = [];
        let temp = [...sdata.breadcrumb];
        temp[0] = {
          name: "Dashboard",
          path: "/landingscreen",
        };
        temp[1] = {
            name: "Active Users Today",
            path: "/landingscreen/userstoday"
        };
        sdata.breadcrumb = temp;
    } else {
        let temp = [...sdata.breadcrumb];
        temp[0] = {
          name: "Dashboard",
          path: "/landingscreen",
        };
        temp[1] = {
          name: "Active Users Today",
          path: "/landingscreen/userstoday"
        };
        sdata.breadcrumb = temp;
    }
    dispatch(awsSignIn(sdata));
}

  // Start of main return
  return (
    <div className={Tusers.main}>
      <Backdrop className={classes.backdrop} open={spin}>
        <CircularProgress color="inherit" />
      </Backdrop>
       <Breadcrumb Bindex={1}/>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <SelectPopup 
          handleClose={handleClose}
          tenants={tenants}
          getTodayUsers={getTodayUsers}
          todayUserPass={todayUserPass}
          />
        </Fade>
      </Modal>
      {/* <h3 className={Tusers.heading}>Active Users Today</h3> */}
      {userDetails.role === "Admin" ? (
        userDetails.role === "Manager" ? null : (
          <div className={Tusers.tenantselect}>
          <h4 className={Tusers.selecttenant}>Select Organization:</h4>
          <h5
            className={Tusers.tenantname}
            onClick={() => {
              handleOpen();
            }}
          >
            {userDetails.selectedTenant}
          </h5>
      </div>
        )
      ) : null}
      <div
        style={{
          height: 550,
          width: "100%",
          overflowY: "auto",
          paddingTop: "20px",
        }}
        className={classes.root}
      >
        <Paper>
          <div
            style={{ height: 500, width: "100%", overflowY: "auto" }}
            className={classes.root}
          >
            {rows.length === 0 ? (
                      <div
                        style={{
                          height: "60vh",
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <img src={NothiongHereYet} alt="Nothing Here" />
                      </div>
                    ) : (
                      <DataGrid
                        rows={rows}
                        columns={tcolumns}
                        className={classes[".MuiDataGrid-root"]}
                        disableSelectionOnClick
                        components={{
                          Toolbar: CustomToolbar,
                        }}
                      />
                    )}
          </div>
        </Paper>
      </div>
    </div>
  );
};

export default UsersToday;
