import React, { useEffect, useState } from "react";
import { Constants } from "../../../config/Constants";
import config from "../../../config/aws-exports";
import { API } from "aws-amplify";
import PublishStyle from "./publish.module.scss";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { CustomButton } from "../../CustomButton/CustomButton";
import swal from "sweetalert2";
import { ReactComponent as Close } from "../../../assets/icons/close_black_24dp.svg";
import BackDrop from "@material-ui/core/Backdrop/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import Paper from "@mui/material/Paper";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
}));

export default function Publish(props) {
  let { tenants, sessionRowData, handlePublishClose, object, getSessionList } =
    props;
  const classes = useStyles();
  const history = useHistory();
  const [spin, setSpin] = useState(false);
  const [checked, setChecked] = React.useState(false);
  const [selected, setSelected] = React.useState([]);
  const [newArray, setNewArray] = useState([]);
  console.log("sessionPUBLISH", tenants);
  console.log("session", sessionRowData);
  let empty = [];

  useEffect(() => {
    CopyOldTenants();
  }, []);

  function CopyOldTenants() {
    let temp = [];
    if (sessionRowData.tenants_id !== null) {
      if (
        sessionRowData.tenants_id !== undefined &&
        sessionRowData.tenants_id.length > 0
      ) {
        temp = sessionRowData.tenants_id;
      }
    }
    //console.log(sessionRowData.tenants.length);

    setNewArray(temp);
  }

  const handleClick = (event, item) => {
    let tempArray = [...newArray];
    if (tempArray.includes(item)) {
      var index = tempArray.indexOf(item);
      tempArray.splice(index, 1);
    } else {
      tempArray.push(item);
    }
    console.log("aten", tempArray);
    setNewArray(tempArray);
  };
  const handleAllClick = (event) => {
    let tempArray = [...newArray];

    if (!event.target.checked) {
      tempArray = [];
    } else {
      for (var i = 0; i < tenants.length; i++) {
        if (!tempArray.includes(tenants[i].st_id)) {
          tempArray = tempArray.concat(tenants[i].st_id);
        }
      }
    }

    setNewArray(tempArray);
  };
  function save() {
    let stenants = [];
    let copiedNewArray = [...newArray];

    if (tenants.length > 0) {
      for (var i = 0; i < tenants.length; i++) {
        if (copiedNewArray.includes(tenants[i].st_id)) {
          stenants.push(tenants[i].oid);
        }
      }
    }

    publishUnpublish(stenants, copiedNewArray);
  }

  async function publishUnpublish(allTenants, newTenants) {
    setSpin(true);
    const bodyParam = {
      body: {
        sid: sessionRowData.sid,
        oid: config.aws_org_id,
        tenants: newTenants,
        stenants: allTenants,
        schema: Constants.SCHEMA_NAME,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log(JSON.stringify(bodyParam.body));
      const deleter = await API.post(
        config.aws_cloud_logic_custom_name,
        "/publishUnpublishSession",
        bodyParam
      );
      handlePublishClose();
      getSessionList();
      setSpin(false);
      swal
        .fire({
          title: "Success",
          icon: "success",
          dangerMode: false,
        })
        .then((willDelete) => {
          if (willDelete) {
            setSpin(false);
          }
        });
      // console.log("delete", deleter);

      // setIstLoading(false);
    } catch (error) {
      swal
        .fire({
          title: "Oooops!",
          text: "Soomthing wrong!",
          icon: "warning",
          dangerMode: true,
        })
        .then((willDelete) => {
          if (willDelete) {
            setSpin(false);
          }
        });
      // alert(JSON.stringify(error));
      console.error(error);
    }
  }

  const isSelected = (name) => selected.indexOf(name) !== -1;
  return (
    <div className={PublishStyle.maincontainer}>
      <BackDrop sx={{ color: "#fff" }} open={spin} className={classes.backdrop}>
        <CircularProgress color="inherit" />
      </BackDrop>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h3>Publish</h3>

        <Close
          className={PublishStyle.closeicon}
          onClick={handlePublishClose}
        />
      </div>
      <div style={{ paddingBottom: "5px" }}>
        <FormControlLabel
          label="Select All"
          control={
            <Checkbox
              onChange={(e) => handleAllClick(e)}
              checked={newArray.length === tenants.length}
            />
          }
        />
      </div>
      {tenants === undefined || tenants === null ? (
        <h3>No Tenants</h3>
      ) : (
        tenants.map((item, id) => {
          const isItemSelected = isSelected(item.st_id);
          return (
            <FormControlLabel
              label={item.did}
              control={
                <Checkbox
                  checked={newArray.includes(item.st_id)}
                  onChange={(e) => handleClick(e, item.st_id)}
                />
              }
            />
          );
        })
      )}

      <button
        onClick={() => {
          save();
        }}
        className={PublishStyle.savebutton}
      >
        Save
      </button>
    </div>
  );
}
