// Dependencies imports
import React, { useState, useEffect } from 'react';
import {
  DataGrid,
  GridToolbar,
  GridToolbarContainer,
} from "@material-ui/data-grid";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { API } from "aws-amplify";
import moment from "moment";
import Paper from "@mui/material/Paper";
import Button from "@material-ui/core/Button";

// Local imports
import { Constants } from "../../../config/Constants";
import {ReactComponent as Delete} from '../../../assets/icons/delete_black_24dp.svg';
import { ReactComponent as Edit } from "../../../assets/icons/edit_black_24dp.svg";

import { awsSignIn, authData } from "../../../redux/auth/authSlice";
import AddUsers from './AddUsers';
import config from "../../../config/aws-exports";
import UserBulkUpload from './UserBulkUpload';

// Style imports
import User from './Users.module.scss';

// Custom Toolbard to export and filter for datagrid
function CustomToolbar() {
  return (
    <GridToolbarContainer
      style={{ display: "flex", justifyContent: "flex-end" }}
    >
      <GridToolbar />
    </GridToolbarContainer>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& .super-app-theme--header": {
      backgroundColor: Constants.blue_color,
      fontSize: "16px",
      color: "rgb(255,255,255)",
      fontFamily: "nunito",
    },
  },
  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
    cursor: "pointer",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
}));


const Users = () => {
  const classes = useStyles();
  const [openAdd, setOpenAdd] = useState(false);
  const [openBulk, setOpenBulk] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [editUserData, setEditUserData] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const [rows, setRows] = useState([]);

  let userDetails = useSelector(authData);

  console.log("userDetails", userDetails);

  useEffect(() => {
    getUser();
    return () => { };
  }, []);

  const handleDeleteOpen = () => {
    setOpenDelete(true);
    console.log("userDetails", userDetails);
  };

  const handleCellClick = (param, event) => {
    console.log(param.row);
    if (param.field == "edit") {
      setOpenEdit(true);
      setEditUserData(param.row);
    } else {
      handleDeleteOpen(true);
      setEditUserData(param.row);
    }

    // console.log(event);
  };


  async function getUser() {
    const bodyParam = {
      body: {
        oid: userDetails.locale,
        schema: Constants.SCHEMA_NAME,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log(bodyParam.body);
      const userData = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_USERS,
        bodyParam
      );
      const userDetail = userData.body;
      let trows = [];
      for (let i=0; i< userDetail.length ; i++){
        if(userDetail[i].ur !== "Manager"){
          let obj = {};
          obj.id = i;
          obj.firstname = userDetail[i].first_name;
          obj.lastname = userDetail[i].last_name;
          obj.col2 = userDetail[i].emailid;
          obj.userrole = userDetail[i].ur;
          if (userDetail[i].la === undefined || userDetail[i].la === null) {
          } else {
            var diff = moment.utc(parseInt(userDetail[i].la)).fromNow();
            // users[i].LA = diff;
            obj.col4 = diff;
          }
          obj.cno = userDetail[i].cno;
          obj.address = userDetail[i].address;
          obj.ur_id = userDetail[i].ur_id;
          obj.gen = userDetail[i].gen;
          obj.col3 = userDetail[i].oid;
          obj.dev = userDetail[i].dev;
          trows.push(obj);
        }
    }
    setRows(trows)
      
    } catch (error) {
      console.error(error);
    }
  }


  const handleAddOpen = () => {
    setOpenAdd(true);
  };

  const handleAddClose = () => {
    setOpenAdd(false)
  };

  const handleBulkOpen = () => {
    setOpenBulk(true);
  };

  const handleBulkClose = () => {
    setOpenBulk(false);
  };


  const tcolumns = [
    {
      field: "firstname",
      headerName: "First Name",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "lastname",
      headerName: "Last Name",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "col2",
      headerName: "Email",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "userrole",
      headerName: "User Role",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "col4",
      headerName: "Last Active",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    // {
    //   field: "edit",
    //   headerName: "Edit User",
    //   flex: 1,
    //   sortable: false,
    //   disableExport: true,
    //   disableClickEventBubbling: true,
    //   headerClassName: "super-app-theme--header",
    //   renderCell: (param) => {
    //     return (
    //       <Button
    //         size="small"
    //         // style={{ color: "white", background: config.main_color_1, fontSize: "12px", fontWeight: "bold" }}
    //         startIcon={<Edit />}
    //         onClick={(event) => {
    //           handleCellClick(param, event);
    //         }}
    //       ></Button>
    //     );
    //   },
    // },
    // {
    //   field: "delete",
    //   headerName: "Delete User",
    //   flex: 1,
    //   sortable: false,
    //   disableExport: true,
    //   disableClickEventBubbling: true,
    //   headerClassName: "super-app-theme--header",
    //   renderCell: (param) => {
    //     return (
    //       <Button
    //         size="small"
    //         // style={{ color: "white", background: "#f32013", fontSize: "12px", fontWeight: "bold" }}
    //         startIcon={<Delete />}
    //         onClick={(event) => {
    //           handleCellClick(param, event);
    //         }}
    //       ></Button>
    //     );
    //   },
    // },
  ];


  async function downloadcsv() {
    const response = await fetch("https://enhanzed-content.s3.amazonaws.com/csvTemplate/userlist.csv");
    const data = await response.text();
    const blob = new Blob([data], { type: "data:text/csv;charset=utf-8," });
    const url = window.URL.createObjectURL(blob);
    let dwldLink = document.createElement("a");
    let isSafariBrowser =
      navigator.userAgent.indexOf("Safari") != -1 &&
      navigator.userAgent.indexOf("Chrome") == -1;
    if (isSafariBrowser) {
      //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", "userlist.csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }



  return (
    <div className={User.main}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openAdd}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openAdd}>
          <AddUsers 
          handleAddClose={handleAddClose}
          getUser={getUser}
          />
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openBulk}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openBulk}>
          <UserBulkUpload 
          handleClose={handleBulkClose} 
          getUser={getUser}
          type="user" />
        </Fade>
      </Modal>
      <div className={User.topbar}>
        <h3 className={User.heading}>Users</h3>
        <div className={User.leftside}>
        <a
            style={{ fontSize: "13px", cursor: "pointer", color: "blue" }}
            onClick={downloadcsv}
          >
            Download Format
          </a>
          <button className={User.buttonadd} onClick={handleBulkOpen}>Bulk Upload</button>
          <button className={User.buttonadd} onClick={handleAddOpen}>Add Users</button>
        </div>
      </div>
      <div style={{ padding: "10px" }}>
        <Paper>
      <div
        style={{ height: 600, width: "100%", overflowY: "auto" }}
        className={classes.root}
      >
        <DataGrid
          rows={rows}
          columns={tcolumns}
          className={classes[".MuiDataGrid-root"]}
          components={{
            Toolbar: CustomToolbar,
          }}
          disableSelectionOnClick
          onRowSelected={(e) => console.log(e)}
        />
      </div>
      </Paper>
      </div>
    </div>
  );
};

export default Users;