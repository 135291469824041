import React from "react";
import ProformaInvoice from "../components/pages/ProformaInvoice/ProInvoice";
import SideNav from "../components/SideNav/sideNav";
import Header from "../components/Header/hearder";
import { Wrapper } from "./layout.styles";

const CourseCreationLayout = () => {
  return (
    <React.Fragment>
      <Header />
      <Wrapper>
        <SideNav />
        <div style={{ margin: "70px 0px 0px 200px", width: "100%" }}>
          <ProformaInvoice />
        </div>
      </Wrapper>
    </React.Fragment>
  );
};

export default CourseCreationLayout;
