import React, { useState, useEffect, useRef } from "react";

import Table from "@mui/material/Table";
import { API } from "aws-amplify";
import config from "../config/aws-exports";
import { Constants } from "../config/Constants";
import Button from "@mui/material/Button";
import { styled } from "@mui/system";
import swal from "sweetalert2";
import swal1 from "@sweetalert/with-react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";

import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import BackDrop from "@material-ui/core/Backdrop/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../redux/auth/authSlice";
import NothiongHereYet from "../assets/Nothing Here Yet.gif";

// Local imports
import { ReactComponent as Close } from "../assets/icons/close_black_24dp.svg";
//style impoprts
import Fp from "../components/pages/Report/FeedbackPopUp.module.scss";
import FeedbackPopUp from "../components/pages/Report/FeedbackPopUp";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
}));

const header = {
  color: "white",
  fontSize: "16px",
  fontWeight: "400px",
};

const UsersFeedback = () => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [feedbackDetails, setFeedbackDetails] = useState([]);
  const [spin, setSpin] = useState(false);
  const [feedback, setFeedback] = useState({});
  const [openFeedback, setOpenFeedback] = useState(false);



  let userDetails = useSelector(authData);
  console.log(userDetails);

  useEffect(() => {
    getFeedbackDetails();
    return () => {};
  }, []);

  async function getFeedbackDetails(item) {
    setSpin(true);
    const bodyParam = {
      body: {
        sid: userDetails?.params?.row?.sid,
        schema: "uspsm",
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_FEEDBACK_DETAILS,
        bodyParam
      );
      setFeedbackDetails(response.body);
      console.log(response)
      setSpin(false);
    } catch (error) {
      console.error(error);
      setSpin(false);
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleFeedbackOpen = (feedback) => {
    setOpenFeedback(true);
    setFeedback(feedback)
  };
  const handleFeedbackClose = () => {
    setOpenFeedback(false);
  };

  return (
    <div className={Fp.feedbackmaincontainer}>
      <Dialog
        open={openFeedback}
        aria-labelledby="responsive-dialog-title"
        scroll="paper"
        maxWidth="lg"
      ></Dialog>
      <Modal
      style={{width:"70%",marginLeft:'15%',marginTop:'2%'}}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openFeedback}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openFeedback}>
          <FeedbackPopUp
          openFeedback={openFeedback}
            handleRegClose={handleFeedbackClose}
            feedback={feedback}
           
          />
        </Fade>
      </Modal>
    
      <BackDrop sx={{ color: "#fff" }} open={spin} className={classes.backdrop}>
        <CircularProgress color="inherit" />
      </BackDrop>
      <div className={Fp.topbar}>
        <h3 className={Fp.header}>Total Feedback</h3>
      </div>
      <Table>
        <TableHead sx={{ background: "#285780" }}>
          <TableRow>
            <TableCell style={header}> Name</TableCell>
            <TableCell style={header}>Feedback Description</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {(rowsPerPage > 0
            ? feedbackDetails.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
            : feedbackDetails
          )?.map((item) => {
            return (
              <TableRow>
                <TableCell
                  style={{ textTransform: "capitalize", cursor: "pointer" }}
                  onClick={()=>handleFeedbackOpen(item)}
                >
                  {item?.fullname?.firstname + " " + item?.fullname?.lastname}
                </TableCell>
                <TableCell>{item?.description}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
              count={feedbackDetails.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { "aria-label": "rows per page" },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </div>
  );
};

export default UsersFeedback;
