// Dependencies imports
import React from 'react';

// Local imports


// Style imports
import Add from './AddRoles.module.scss';

const AddUsers = (props) => {
    let {handleAddUserClose} = props;

    return (
        <div className={Add.mainpopup}>
            <h3 className={Add.heading}>Add New User</h3>
            <div className={Add.forminput}>
                <input type="text" placeholder="Name" className={Add.inputbox} />
                <input type="email" placeholder="Email Id" className={Add.inputbox} />
                <select className={Add.inputbox}>
                    <option disabled value={null}>Select Role</option>
                    <option>Role 1</option>
                    <option>Role 2</option>
                </select>
                <select className={Add.inputbox}>
                    <option disabled value={null}>Select Organization</option>
                    <option>Tenant 1</option>
                    <option>Tenant 2</option>
                </select>
            </div>
            <div className={Add.savebutton}>
        <button className={Add.save}>Create User</button>
        <button className={Add.cancel} onClick={handleAddUserClose}>
          Cancel
        </button>
      </div>
        </div>
    );
};

export default AddUsers;
