import React from "react";

const DashboardSvg = ({ dashicon, cls1, cls2, cls3, cls4 }) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      className={dashicon}
    >
      <defs></defs>

      <path
        className={cls1}
        d="M70.3,53.2H47c-1,0-1.9-1.4-1.9-3.2s.8-3.2,1.9-3.2H70.3c1,0,1.9,1.4,1.9,3.2S71.4,53.2,70.3,53.2Z"
      />
      <path
        className={cls1}
        d="M51.5,21.5V49.9c0,1.3-1.4,2.3-3.2,2.3s-3.2-1-3.2-2.3V21.5c0-1.3,1.4-2.3,3.2-2.3S51.5,20.2,51.5,21.5Z"
      />
      <circle className={cls2} cx="50" cy="50" r="36.58" />
      <path className={cls3} d="M47.15,32.31V50.79H32.31V32.31Z" />
      <path className={cls3} d="M67.69,67.71H52.89V49.18h14.8Z" />
      <path className={cls3} d="M67.69,43.35H52.88V32.29H67.69Z" />
      <path className={cls3} d="M32.32,56.6H47.11V67.67H32.32Z" />
    </svg>
  );
};

export default DashboardSvg;

{
  /* <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><defs><style>.cls-1,.cls-3{fill:#fff;}.cls-2{fill:#777;stroke:#777;}.cls-2,.cls-3{stroke-miterlimit:10;stroke-width:2.53px;}.cls-3{stroke:#fff;}</style></defs><path class="cls-1" d="M70.3,53.2H47c-1,0-1.9-1.4-1.9-3.2s.8-3.2,1.9-3.2H70.3c1,0,1.9,1.4,1.9,3.2S71.4,53.2,70.3,53.2Z"/><path class="cls-1" d="M51.5,21.5V49.9c0,1.3-1.4,2.3-3.2,2.3s-3.2-1-3.2-2.3V21.5c0-1.3,1.4-2.3,3.2-2.3S51.5,20.2,51.5,21.5Z"/><circle class="cls-2" cx="50" cy="50" r="36.58"/><path class="cls-3" d="M47.15,32.31V50.79H32.31V32.31Z"/><path class="cls-3" d="M67.69,67.71H52.89V49.18h14.8Z"/><path class="cls-3" d="M67.69,43.35H52.88V32.29H67.69Z"/><path class="cls-3" d="M32.32,56.6H47.11V67.67H32.32Z"/></svg> */
}
