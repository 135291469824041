import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import config from "../../../config/aws-exports";
import { API } from "aws-amplify";
import useWindowDimensions from "../../../modules/Window/Window";
import {
  Backdrop,
  Button,
  CircularProgress,
  InputAdornment,
  makeStyles,
  TextareaAutosize,
  TextField,
} from "@material-ui/core";
import "./CoursePlayer.scss";
import Cviewstyle from "./CourseCreation/courseCreation.module.scss";
import RegexTextField from "./CourseCreation/RegexTextField";
import { EDITOR_JS_TOOLS } from "./CourseCreation/EditorConstants";
import CreateQuiz from "../../CreateQuiz/CreateQuiz";
import edjsHTML from "editorjs-html";
import axios from "axios";
import { Constants } from "../../../config/Constants";
import Done from "../../../assets/svgjs/Done";
import Reject from "../../../assets/svgjs/Reject";

export default function CoursePlayer(props) {
  let {
    handleClose,
    courseDetails,
    setCourseDetails,
    objectData,
    setObjectData,
    oIndex,
    setOIndex,
    curObject,
    editObj,
    setEditObj,
    selectedObj,
    setSelectedObj,
    folder,
    nuggets,
    qisLoading,
    setQIsLoading,
    setNuggets,
    setOpen,
    summative,
    sumScore,
    open,
    setcurObject,
    curObRef,
  } = props;
  const { height, width } = useWindowDimensions();
  const [quizQuestion, setQuizQuestion] = useState([]);
  const formData = useRef([]);
  const full = useRef(null);
  const instanceRef = useRef(null);
  const [typesButtons, setTypeButtons] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [istLoading, setIstLoading] = useState(false);
  const [tryAgain, setTryAgain] = useState(false);
  const [formativeAns, setFormativeAns] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [editQuiz, setEditQuiz] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [score, setScore] = useState(0);
  const [qtype, setQtype] = useState("");
  const [btnClick, setBtnClick] = useState(false);
  const [answerReveal, setAnswerReveal] = useState(false);
  const [assignmentLoad, setAssignmentLoad] = useState(true);
  const [cutoffval, setCutoffval] = useState();
  const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiTextField-root": {
        color: "black !important",
      },
      "& .MuiFormLabel-root": {
        color: "black !important",
      },
    },
    textarea11: {
      "& .MuiInputBase-input": {
        color: " #black !important",
        fontSize: "12.5px !important",
      },
    },
    paper: {
      backgroundColor: theme.palette.background.paper,

      height: "200px",
      width: "500px",
      borderRadius: "4px",
      border: "none",
      padding: theme.spacing(2, 4, 3),
    },
    modalConfirm: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",

      border: "none",
      transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },

    tooltipFull: {
      fontSize: 30,
    },
    bottomIcons: {
      color: "#000",
      "&:hover": {
        color: "#777",
      },
    },
    volumeButton: {
      color: "#000",
      fontSize: 40,
      transform: "scale(0.9)",
      "&:hover": {
        color: "#777",
        transform: "scale(1.03)",
        transitionTimingFunction: "cubic-bezier(0.1, 0.1, 0.25, 1)",
      },
    },
    volumeButtonExit: {
      color: "lightgray",
      fontSize: 40,
      transform: "scale(0.9)",
      "&:hover": {
        color: "#777",
        transform: "scale(1.03)",
        transitionTimingFunction: "cubic-bezier(0.1, 0.1, 0.25, 1)",
      },
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: "98%",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  }));
  console.log(editObj);
  useEffect(() => {
    setLoaded(true);
    setCutoffval(curObject.cutoff);
  }, []);
  const classes = useStyles();
  async function getQuiz(obj) {
    setEditQuiz(true);
    setQIsLoading(true);
    const bodyParam = {
      body: {
        quizid: obj.oid,
        oid: config.aws_org_id,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        `/getQuiz`,
        bodyParam
      );

      const { qitems, qtype, cutoff } = response;

      setQtype(qtype);
      setCutoffval(cutoff);
      setQuizQuestion(qitems);

      if (obj.edit === true) {
        selectedObj.quizQuestion = qitems;
        selectedObj.qtype = qtype;
        selectedObj.cutoff = cutoff;
        selectedObj.qtitle = curObject.otitle;
        setSelectedObj(selectedObj);
        editObj.oeditmode = true;
        setEditObj({ ...editObj });
      }

      setBtnClick(true);
      setQIsLoading(false);
      setAssignmentLoad(false);
    } catch (error) {
      // alert(JSON.stringify(error));
      console.error(error);
    }
  }

  function VideoView() {
    return (
      <div
        className="player-wrapper"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <ReactPlayer
          url={curObject.url}
          controls={true}
          width="100%"
          height={height - 300}
          config={{
            file: {
              attributes: {
                controlsList: "nodownload", //<- this is the important bit
              },
            },
          }}
        />
      </div>
    );
  }

  function AudioView() {
    return (
      <div>
        <div
          className="player-wrapper"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <ReactPlayer
            url={curObject.url}
            controls={true}
            width="calc(100% - 15%)"
            height={height - 600}
          />
        </div>
      </div>
    );
  }

  function VimeoView() {
    return (
      <div>
        <div
          className="player-wrapper"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <ReactPlayer
            url={`https://player.vimeo.com/video/${curObject.ourl}?title=0&loop=0`}
            controls={true}
            width="calc(100% - 15%)"
            height={height - 300}
          />
        </div>
      </div>
    );
  }

  function youtubeView() {
    return (
      <div>
        <div
          className="player-wrapper"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <ReactPlayer
            url={`https://www.youtube.com/embed/${curObject.ourl}?rel=0&showinfo=0`}
            controls={true}
            width="calc(100% - 15%)"
            height={height - 300}
          />
        </div>
      </div>
    );
  }

  function PdfView() {
    console.log(curObject);
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          height: height - 300,
          overflowX: "hidden",
        }}
      >
        <iframe src={curObject.url} height="100%" width="100%"></iframe>
      </div>
    );
  }

  function WebView() {
    return (
      <div
        style={{
          display: "flex",

          justifyContent: "center",
        }}
      >
        <iframe
          src={curObject.url}
          width={"100%"}
          //height={"100%"}
          height={height - 100}
          title="WebView"
        />
      </div>
    );
  }
  const handleAnswerOptionClick = (ansOpts, idx) => {
    if (ansOpts.correct == "true" || ansOpts.correct === true) {
      setScore(score + 1);
    }
    let answer = [...formativeAns];
    answer.push(idx);
    setFormativeAns(answer);
    handleNextQuestionClick();
  };
  const handleNextQuestionClick = () => {
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < quizQuestion.length) {
      setCurrentQuestion(nextQuestion);
    } else {
      setShowScore(true);
      console.log(formData.current);
    }
  };
  const imgUrl = `https://${
    config.DOMAIN
  }/${config.aws_org_id.toLowerCase()}-resources/images/quiz-images/${curObject.oid.substring(
    0,
    7
  )}/`;
  function answerPrev() {
    const prevQuestion = currentQuestion - 1;

    setCurrentQuestion(prevQuestion);
  }

  function answerNext() {
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < quizQuestion.length) {
      setCurrentQuestion(nextQuestion);
    }
  }
  function quizView() {
    return (
      <div className="body">
        {/* <button onClick={() => getQuiz(curObject)}>start</button>

        {qtype === 0 && (
          <Feedback
            qtype={qtype}
            questions={quizQuestion}
            currentQuestion={currentQuestion}
            imgUrl={imgUrl}
          />
        )}
        {qtype == 1 && (
          <Summative
            qtype={qtype}
            questions={quizQuestion}
            currentQuestion={currentQuestion}
            imgUrl={imgUrl}
          />
        )}
        {qtype === 2 && (
          <Formative
            qtype={qtype}
            questions={quizQuestion}
            currentQuestion={currentQuestion}
            imgUrl={imgUrl}
          />
        )} */}

        {btnClick ? (
          <div className="app1">
            {showScore ? (
              <div className="score-section">
                {qtype !== 3 ? (
                  <div>
                    You scored {score} out of {quizQuestion.length}
                    {/* ;
                    {(score / quizQuestion.length) * 100} */}
                    {qtype == 2 &&
                    ((score / quizQuestion.length) * 100 >= cutoffval ||
                      tryAgain) ? (
                      <div>
                        <button
                          className="btnQuizLD"
                          style={{
                            width: "200px",
                            border: "3px solid white",
                            fontSize: "15px",
                          }}
                          onClick={() => {
                            setScore(0);
                            setTryAgain(false);
                            setOpen(false);
                          }}
                        >
                          Exit
                        </button>
                        <button
                          className="btnQuizLD"
                          style={{
                            width: "200px",
                            border: "3px solid white",
                            fontSize: "15px",
                          }}
                          onClick={() => {
                            getQuiz(curObject);
                            setCurrentQuestion(0);
                            setAnswerReveal(true);
                            setShowScore(false);
                            //setQIsLoading(true);
                          }}
                        >
                          {qisLoading ? (
                            <CircularProgress color="default" size={30} />
                          ) : (
                            "Answers"
                          )}
                        </button>
                      </div>
                    ) : qtype == 2 ? (
                      <div style={{ fontSize: "16px" }}>
                        {" "}
                        You should score more than {cutoffval}% to complete this
                        Quiz.
                      </div>
                    ) : (
                      <div>Submitted</div>
                    )}
                  </div>
                ) : (
                  <div>
                    <h3>Goto Home Page</h3>
                    <div>
                      <button
                        className="buttonQuizCt"
                        onClick={() => {
                          setScore(0);
                          setCurrentQuestion(0);
                          getQuiz(curObject);
                        }}
                      >
                        Exit
                      </button>
                    </div>
                  </div>
                )}
                {qtype !== 1 &&
                qtype !== 3 &&
                (score / quizQuestion.length) * 100 < 75 &&
                tryAgain === false ? (
                  <div>
                    <button
                      className="buttonQuizCt"
                      onClick={() => {
                        setBtnClick(false);
                        getQuiz(curObject);
                        setScore(0);
                        setShowScore(false);
                        setCurrentQuestion(0);
                        setTryAgain(true);
                        setFormativeAns([]);
                      }}
                    >
                      Try Again
                    </button>
                    <button
                      className="buttonQuizCt"
                      onClick={() => {
                        setBtnClick(false);
                        setOpen(false);
                        setScore(0);
                        setCurrentQuestion(0);
                        setTryAgain(false);
                        setFormativeAns([]);
                      }}
                    >
                      Exit
                    </button>
                  </div>
                ) : qtype == 1 ? (
                  <div>
                    {" "}
                    <button
                      className="buttonQuizCt"
                      onClick={() => {
                        setBtnClick(false);
                        handleClose();
                        setScore(0);
                        setCurrentQuestion(0);
                      }}
                    >
                      Exit
                    </button>
                  </div>
                ) : null}
              </div>
            ) : answerReveal ? (
              <div>
                <div className="scrollQuiz">
                  <div className="question-section">
                    <div className="question-count">
                      <span>Question {currentQuestion + 1}</span>/
                      {quizQuestion.length}
                    </div>
                    <div
                      className="question-text"
                      dangerouslySetInnerHTML={{
                        __html: quizQuestion[currentQuestion].istem,
                      }}
                    >
                      {/* {quizQuestion[currentQuestion].istem} */}
                    </div>

                    {quizQuestion[currentQuestion].imageurl ? (
                      <div className="img_container">
                        <img
                          src={`${imgUrl}${quizQuestion[currentQuestion].imageurl}`}
                          alt="item"
                        />
                      </div>
                    ) : null}
                  </div>
                  {qtype !== 3 ? (
                    <div className="answer-section">
                      {quizQuestion[currentQuestion].iopts.map(
                        (answerOption, idx) => (
                          <div style={{ display: "flex" }}>
                            {" "}
                            {formativeAns[currentQuestion] !== idx ? (
                              <button className={`buttonQuizAR`}>
                                {answerOption.content}
                              </button>
                            ) : (
                              <button
                                className={`buttonQuizAR`}
                                style={{
                                  backgroundColor: "lightgray",
                                  border: "2px solid #234668",
                                }}
                              >
                                {answerOption.content}
                              </button>
                            )}
                            {answerOption.correct == "true" ||
                            answerOption.correct == true ? (
                              <Done
                                className="right_size"
                                cls1="cls1_D"
                                cls2="cls2_D"
                              />
                            ) : (
                              <Reject
                                className="Wrong_size"
                                cls1="cls1_D"
                                cls2="cls2_D"
                              />
                            )}
                            {formativeAns[currentQuestion] !== idx ? null : (
                              <p
                                style={{
                                  fontSize: "14px",
                                  paddingLeft: "8px",
                                  paddingTop: "8px",
                                }}
                              >
                                Your Response
                              </p>
                            )}
                          </div>
                        )
                      )}
                      <br />
                    </div>
                  ) : null}
                  {currentQuestion > 0 ? (
                    <Button
                      color="primary"
                      variant="contained"
                      fullWidth
                      className="btn-size"
                      onClick={() => answerPrev()}
                      style={{ fontSize: "12px" }}
                    >
                      Prev
                    </Button>
                  ) : null}
                  {currentQuestion + 1 === quizQuestion.length ? (
                    <div></div>
                  ) : (
                    <Button
                      color="primary"
                      variant="contained"
                      fullWidth
                      className="btn-siz"
                      onClick={() => answerNext()}
                      style={{ float: "right", fontSize: "12px" }}
                    >
                      Next
                    </Button>
                  )}

                  <br />
                  <br />
                  <br />

                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    className="btn-sizeClose"
                    style={{
                      backgroundColor: "white",
                      color: "black",
                      border: "1px solid black",
                      width: "100px",
                      marginLeft: "45%",
                      fontSize: "12px",
                    }}
                    onClick={() => {
                      //handleClose();
                      setCurrentQuestion(0);
                      setShowScore(true);
                      setAnswerReveal(false);
                    }}
                  >
                    Close
                  </Button>
                </div>
              </div>
            ) : (
              <div className="scrollQuiz">
                <div className="question-section">
                  <div className="question-count">
                    <span>Question {currentQuestion + 1}</span>/
                    {quizQuestion.length}
                  </div>
                  <div
                    className="question-text"
                    dangerouslySetInnerHTML={{
                      __html: quizQuestion[currentQuestion].istem,
                    }}
                  ></div>
                  {quizQuestion[currentQuestion].imageurl ? (
                    <div className="img_container">
                      <img
                        src={`${imgUrl}${quizQuestion[currentQuestion].imageurl}`}
                        alt="item"
                      />
                    </div>
                  ) : null}
                </div>
                {qtype != 3 ? (
                  <div className="answer-section">
                    {quizQuestion[currentQuestion].iopts.map(
                      (answerOption, idx) => (
                        <button
                          key={"a" + idx}
                          // className="buttonQuiz"
                          className={`buttonQuiz`}
                          data-id={`${
                            answerOption.correct === "true"
                              ? answerOption.correct
                              : null
                          }`}
                          onClick={() => {
                            handleAnswerOptionClick(answerOption, idx);
                            console.log("ans", answerOption, idx);
                            /* alert("next"); */
                          }}
                        >
                          {answerOption.content}
                        </button>
                      )
                    )}
                  </div>
                ) : (
                  <div>
                    <TextareaAutosize
                      maxRows={6}
                      style={{ height: "200px", width: "100%" }}
                      aria-label="maximum height"
                      placeholder="Maximum 6 rows"
                    />
                    {currentQuestion > 0 ? (
                      <Button
                        color="primary"
                        variant="contained"
                        fullWidth
                        className="btn-size"
                        onClick={() => answerPrev()}
                        style={{ fontSize: "12px" }}
                      >
                        Prev
                      </Button>
                    ) : null}
                    {currentQuestion + 1 === quizQuestion.length ? (
                      <div></div>
                    ) : (
                      <Button
                        color="primary"
                        variant="contained"
                        fullWidth
                        className="btn-siz"
                        onClick={() => answerNext()}
                        style={{ float: "right", fontSize: "12px" }}
                      >
                        Next
                      </Button>
                    )}

                    <br />
                    <br />
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          <div>
            {curObject.qtype == 3 ? (
              <div>
                {navigator.onLine ? (
                  <button
                    className="btnQuizLD"
                    onClick={() => {
                      console.log(JSON.stringify(curObject, null, 2));
                      //alert(JSON.stringify(curObject))
                      if (curObject.filechanges) {
                        // setQtype(curObject.qtype);
                        // setQuizQuestion(curObject.qitems);
                        // if (obj.edit === true) {
                        //   selectedObj.quizQuestion = qitems;
                        //   selectedObj.qtitle = curObject.otitle;
                        //   setSelectedObj(selectedObj);
                        //   editObj.oeditmode = true;
                        //   setEditObj({ ...editObj });
                        // }
                        // setBtnClick(true);
                      } else {
                        getQuiz(curObject);
                        setShowScore(false);
                      }

                      setQIsLoading(true);
                      setAssignmentLoad(true);
                    }}
                  >
                    Assignment
                  </button>
                ) : (
                  <p>you are offline</p>
                )}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                }}
              >
                {summative ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <h1
                      style={{
                        color: "#000",
                        textAlign: "center",
                        margin: "2.5rem 0",
                        boxShadow: "0 0 0 5px #e35f14",
                        borderRadius: "100%",
                        height: "120px",
                        width: "120px",
                        position: "relative",
                      }}
                    >
                      <span
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      >
                        {sumScore} %
                      </span>
                    </h1>
                  </div>
                ) : (
                  <>
                    <br />
                    {curObject.qtype == 2 ? (
                      <div style={{ padding: "0 40px", textAlign: "justify" }}>
                        <h3 style={{ marginLeft: "-14px" }}>Instructions:</h3>
                        <div style={{ fontSize: "14px" }}>
                          <ol>
                            <li>
                              {" "}
                              Unit formative quiz consists of Multiple Choice
                              Questions.
                            </li>
                            <li> Click on "START QUIZ" button.</li>
                            <li>
                              {" "}
                              On starting the quiz, One question appears at a
                              time.
                            </li>
                            <li>
                              {" "}
                              Once you select an answer, you will get the next
                              question.
                            </li>
                            <li>
                              On answering all the questions, you should get a
                              score of {cutoffval}% or more to view the right
                              answers and then click on "MARK COMPLETE" button.
                            </li>
                            <li>
                              Once you click on "MARK COMPLETE", the system
                              would allow you to move to the next Unit.
                            </li>
                            <li>
                              If you get {cutoffval}% score, you must click on
                              "TRY AGAIN" button to retake the quiz and improve
                              your score.
                            </li>
                          </ol>
                        </div>
                        <br />
                      </div>
                    ) : (
                      <div></div>
                    )}
                    <button
                      className="btnQuizLD"
                      onClick={() => {
                        getQuiz(curObject);
                        setShowScore(false);
                        setQIsLoading(true);
                      }}
                    >
                      {qisLoading ? (
                        <CircularProgress color="default" size={30} />
                      ) : (
                        "Start Quiz"
                      )}
                    </button>{" "}
                    <br />
                  </>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
  const ochangeHandler = (event) => {
    // setSelectedFile(new File([""], "filename"));
    if (event.target.files[0] !== undefined) {
      setSelectedFile(event.target.files[0]);
      selectedObj.otherdata.files = event.target.files[0].name;
      setSelectedObj(selectedObj);
    }
  };
  const checkData = async () => {
    selectedObj.otherdata.dsval = false;
    selectedObj.otherdata.tval = false;
    selectedObj.otherdata.mval = false;
    selectedObj.otherdata.sval = false;

    if (
      selectedObj.otherdata.title === undefined ||
      selectedObj.otherdata.title == "" ||
      selectedObj.otherdata.title.replace(/\s/g, "").length === 0
    ) {
      selectedObj.otherdata.tval = true;

      setSelectedObj((prevselectedObj) => ({
        ...prevselectedObj,
        ...selectedObj,
      }));

      return;
    }

    if (
      selectedObj.otherdata.desc === undefined ||
      selectedObj.otherdata.desc === "" ||
      selectedObj.otherdata.desc.replace(/\s/g, "").length === 0
    ) {
      selectedObj.otherdata.dsval = true;
      selectedObj.otherdata.tval = false;
      setSelectedObj((prevselectedObj) => ({
        ...prevselectedObj,
        ...selectedObj,
      }));
      return;
    }

    if (
      selectedObj.otherdata.min === undefined ||
      selectedObj.otherdata.min === "" ||
      selectedObj.otherdata.min.toString().replace(/\s/g, "").length === 0
    ) {
      selectedObj.otherdata.dsval = false;
      selectedObj.otherdata.tval = false;
      selectedObj.otherdata.mval = true;
      setSelectedObj((prevselectedObj) => ({
        ...prevselectedObj,
        ...selectedObj,
      }));
      return;
    }

    if (
      selectedObj.otherdata.sec === undefined ||
      selectedObj.otherdata.sec === "" ||
      selectedObj.otherdata.sec <= 0 ||
      selectedObj.otherdata.sec.toString().replace(/\s/g, "").length === 0
    ) {
      selectedObj.otherdata.dsval = false;
      selectedObj.otherdata.tval = false;
      selectedObj.otherdata.mval = false;
      selectedObj.otherdata.sval = true;
      setSelectedObj((prevselectedObj) => ({
        ...prevselectedObj,
        ...selectedObj,
      }));
      return;
    }

    // if (selectedObj.otherdata.min * 60 + selectedObj.otherdata.sec > 0) {
    //   selectedObj.otherdata.dsval = false;
    //   selectedObj.otherdata.tval = false;
    //   selectedObj.otherdata.sval = true;
    //   selectedObj.otherdata.mval = true;
    //   setSelectedObj((prevselectedObj) => ({
    //     ...prevselectedObj,
    //     ...selectedObj,
    //   }));
    //   return;
    // }

    finalSave();
  };
  const finalSave = async () => {
    console.log("hi", selectedObj);
    selectedObj.otherdata.dur =
      Number(selectedObj.otherdata.min) * 60 +
      Number(selectedObj.otherdata.sec);
    let file;
    let id = Math.floor(Math.random() * (999 - 100 + 1) + 100);
    let filename = "";

    let object = {
      otitle: selectedObj.otherdata.title,
      odescription: selectedObj.otherdata.desc,
      otype: selectedObj.otype,
      oduration: selectedObj.otherdata.dur,
      ourl: selectedObj.ourl,
      oid: selectedObj.oid,
      qtype: parseInt(selectedObj.qtype),
      cutoff: parseInt(selectedObj.cutoff),
    };

    var body = {
      folder: folder,
    };
    if (selectedObj.stype !== undefined && selectedObj.stype == "chtml") {
      body.filetype = "text/html";
      body.filename =
        Math.floor(Math.random() * (999 - 100 + 1) + 100) + ".html";
      object.newfile =
        Math.floor(Math.random() * (999 - 100 + 1) + 100) + ".html";
      object.filechanges = true;
      const savedData = await instanceRef.current.save();

      const edjsParser = edjsHTML();
      // blocks
      file = edjsParser.parse(savedData);
      file = JSON.parse(JSON.stringify(file));
      let html = "<html><heaed></heaed><body>";
      for (let i = 0; i < file.length; i++) {
        html = html + file[i];
      }
      html = html + "</html></body>";
      file = html;
    } else if (selectedObj.otype == "quiz") {
      body.filename =
        Math.floor(Math.random() * (999 - 100 + 1) + 100) + ".json";
      object.newfile = body.filename;
      object.filechanges = true;
      object.oduration = selectedObj.quizdata.qitems.length * 60;
      file = selectedObj.quizdata;
      body.filetype = "application/json";
    } else if (selectedFile !== undefined) {
      file = selectedFile;
      body.filename = id + selectedFile.name;
      body.filetype = selectedFile.type;
      object.newfile = id + selectedFile.name;
      object.filechanges = true;
      object.curl = URL.createObjectURL(selectedFile);
    }

    //var typejson = {"type" :"uploadfile","file":selectedFile,"body":body };
    //setisBookmarkedLoaded(true);
    if (object.filechanges === true) {
      uploadfile(body, file, object);
    } else {
      nuggets[selectedObj.nindex].nchanges = true;
      nuggets[selectedObj.nindex].objects[selectedObj.oindex] = object;
      console.log(
        JSON.stringify(
          nuggets[selectedObj.nindex].objects[selectedObj.oindex],
          null,
          2
        )
      );
      setOpen(false);
      setCourseDetails((prev) => ({
        ...prev,
        dur: selectedObj.otherdata.min * 60 + selectedObj.otherdata.sec,
      }));
      setTypeButtons(true);
      editObj.oeditmode = false;
      setEditObj({ ...editObj });
    }
  };
  async function uploadfile(body, file, object) {
    setIstLoading(true);
    const bodyParam = {
      body,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log(bodyParam.body);
      const preSignedData = await API.post(
        config.aws_cloud_logic_custom_name,
        //Constants.GET_PRESIGNED_URL,
        "/getPreSignedURL",
        bodyParam
      );
      let ftype = "";
      if (selectedObj.stype !== undefined && selectedObj.stype == "chtml") {
        ftype = "text/html";
      } else if (selectedObj.otype == "quiz") {
        ftype = "application/json";
      } else {
        ftype = file.type;
      }

      axios
        .put(preSignedData, file, {
          headers: {
            "Content-Type": ftype,
            "Cache-Control": "no-cache no-store must-revalidate",
          },
        })
        .then((response) => {
          nuggets[selectedObj.nindex].nchanges = true;
          nuggets[selectedObj.nindex].objects[selectedObj.oindex] = object;
          // setNuggets(nuggets);
          console.log(
            JSON.stringify(
              nuggets[selectedObj.nindex].objects[selectedObj.oindex],
              null,
              2
            )
          );
          setOpen(false);
          editObj.oeditmode = false;
          setEditObj({ ...editObj });
          setIstLoading(false);
          setTypeButtons(true);
          //setisBookmarkedLoaded(false);
        })
        .catch((error) => {
          //setisBookmarkedLoaded(false);
          console.error("There was an error!", error);
        });
    } catch (error) {
      // alert(JSON.stringify(error));
      console.error(error);
    }
  }
  function RenderObjects() {
    console.log(JSON.stringify(curObject));

    let objType = curObject.otype;

    console.log("s " + curObject);

    switch (objType) {
      case "video":
        return VideoView(); // loadVideoView(curObject);
      case "audio":
        return AudioView(); // loadAudioView(curObject);
      case "pdf":
        return PdfView(); // loadMultimediaView(curObject);
      case "html":
        return WebView(); // loadMultimediaView(curObject);
      case "Interactivity":
        return WebView();
      case "interactivity":
        return WebView(); // loadInteractivityView(curObject);
      case "quiz":
        return quizView();
      case "vimeo":
        return VimeoView(); // loadVimeoView(curObject);
      case "youtube":
        return youtubeView(); // loadYoutubeView(curObject);
      case "scorm":
        return WebView(); // loadYoutubeView(curObject);
      default:
        return null;
    }
  }
  return (
    <div style={{ height: "100%", width: "100%" }}>
      {istLoading && (
        <Backdrop className={classes.backdrop} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <div style={{ width: "100%" }}>
        {/* {modallans()} */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              fontSize: "18px",
              paddingLeft: "2%",
              paddingTop: "1%",
              fontWeight: "500",
              width: "66%",
            }}
          >
            {curObject.otitle}
          </div>
          {editObj.meditmode && !editObj.oeditmode && (
            <button
              className={Cviewstyle.savebutton}
              onClick={() => {
                if (curObject.otype == "quiz") {
                  let crobj = { ...curObject };
                  crobj.edit = true;
                  getQuiz(crobj);
                } else {
                  editObj.oeditmode = true;
                  setEditObj({ ...editObj });
                }
              }}
            >
              {" "}
              Edit Object
            </button>
          )}
          <span
            style={{
              float: "right",
              cursor: "pointer",
              color: "black",
              fontSize: "26px",
              paddingRight: "20px",
            }}
            onClick={() => {
              handleClose();
              setTryAgain(false);
              setFormativeAns([]);
              editObj.oeditmode = false;
              setEditObj({ ...editObj });
            }}
          >
            x
          </span>
        </div>
      </div>
      {/* <div className="btn-toggle" onClick={() => handleToggleSidebar(true)}>
    <FaBars />
  </div>
  <UserHeader /> */}

      {loaded == true ? (
        <div style={{ height: "100%", width: "100%" }}>
          <div
            ref={full}
            style={{
              width: "100%",
              padding: "2%",
              position: "relative",
            }}
          >
            {editObj.oeditmode ? (
              <>
                <div
                  style={{ margin: "0px 50px 0px 50px", textAlign: "center" }}
                >
                  {selectedObj.seltype && selectedObj.sobject != "quiz" && (
                    <div>
                      <div style={{ margin: "20px 15px", overflow: "hidden" }}>
                        {selectedObj.error1 && (
                          <p className={Cviewstyle.verror1}>
                            {selectedObj.sobject}
                            title cannot be empty
                          </p>
                        )}

                        <form autoComplete="off">
                          <TextField
                            label="Title"
                            id="outlined-margin-normal"
                            className={classes.textField}
                            defaultValue={selectedObj.otherdata.title}
                            error={selectedObj.otherdata.tval}
                            helperText={
                              selectedObj.otherdata.tval
                                ? "Please Enter Title"
                                : ""
                            }
                            margin="normal"
                            variant="outlined"
                            onChange={(e) => {
                              selectedObj.otherdata.title = e.target.value;
                              setSelectedObj(selectedObj);
                            }}
                          />
                          <TextField
                            label="Description"
                            id="outlined-margin-normal"
                            className={classes.textField}
                            defaultValue={selectedObj.otherdata.desc}
                            error={selectedObj.otherdata.dsval}
                            helperText={
                              selectedObj.otherdata.dsval
                                ? "Please Enter Description"
                                : ""
                            }
                            margin="normal"
                            variant="outlined"
                            onChange={(e) => {
                              selectedObj.otherdata.desc = e.target.value;
                              setSelectedObj(selectedObj);
                            }}
                          />

                          <div style={{ display: "flex" }}>
                            <RegexTextField
                              label="Duration"
                              error={selectedObj.otherdata.mval}
                              defaultValue={selectedObj.otherdata.min}
                              helperText={
                                selectedObj.otherdata.mval
                                  ? "Please Enter Min"
                                  : ""
                              }
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    Min
                                  </InputAdornment>
                                ),
                              }}
                              onChange={(e) => {
                                selectedObj.otherdata.min = e.target.value;
                                setSelectedObj(selectedObj);
                              }}
                            />
                            <RegexTextField
                              label=""
                              error={selectedObj.otherdata.sval}
                              defaultValue={selectedObj.otherdata.sec}
                              helperText={
                                selectedObj.otherdata.sval
                                  ? "Please Enter Sec"
                                  : ""
                              }
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    Sec
                                  </InputAdornment>
                                ),
                              }}
                              onChange={(e) => {
                                selectedObj.otherdata.sec = e.target.value;
                                setSelectedObj(selectedObj);
                              }}
                            />
                          </div>
                        </form>
                      </div>
                      {selectedObj.stype == "chtml" && (
                        <>
                          {selectedObj.ferror && (
                            <p
                              style={{
                                color: "red",
                                margin: "0 60px",
                                textAlign: "left",
                              }}
                            >
                              {" "}
                              Please enter some data.{" "}
                            </p>
                          )}
                          <div
                            style={{
                              height: "300px",
                              border: "1px solid #ddd",
                              margin: "0px 60px",
                              padding: "6px 28px",
                              overflow: "hidden auto",
                            }}
                          >
                            <EDITOR_JS_TOOLS
                              tools={EDITOR_JS_TOOLS}
                              instanceRef={(instance) =>
                                (instanceRef.current = instance)
                              }
                            />
                          </div>
                        </>
                      )}
                      {(selectedObj.stype === undefined ||
                        selectedObj.stype != "chtml") && (
                        <>
                          <p className={Cviewstyle.pvalid}>
                            Please select a {selectedObj.sobject} to upload
                          </p>
                          <div
                            className={Cviewstyle.insidediv}
                            style={{
                              margin: "20px 59px 19px 24px",
                              width: "95%",
                            }}
                          >
                            <div className={Cviewstyle.centeritem}>
                              <input
                                type="file"
                                id="ofiles"
                                style={{
                                  visibility: "hidden",
                                  position: "absolute",
                                }}
                                onChange={ochangeHandler}
                                accept={selectedObj.afile}
                              />
                              <label for="ofiles">
                                <p
                                  variant="contained"
                                  style={{
                                    backgroundColor: "rgb(227, 95, 20)",
                                    color: "#fff",
                                    width: "100px",
                                    height: "36px",
                                    marginRight: "10px",
                                    alignSelf: "center",
                                    padding: "10px",
                                    cursor: "pointer",
                                    borderRadius: "2px",
                                  }}
                                >
                                  Browse
                                </p>
                              </label>
                            </div>

                            {selectedObj.otherdata.files != "" && (
                              <p className={Cviewstyle.pfile}>
                                {selectedObj.otherdata.files}
                              </p>
                            )}
                            {selectedObj.otherdata.files == "" && (
                              <p className={Cviewstyle.pfile}>
                                {selectedObj.sobject}
                                file not selected{" "}
                              </p>
                            )}
                            {selectedObj.ferror && (
                              <p
                                className={Cviewstyle.pfile}
                                style={{ color: "red" }}
                              >
                                {" "}
                                Please select file{" "}
                              </p>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  )}
                  {selectedObj.sobject != "quiz" && (
                    <>
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: "rgb(227, 95, 20)",
                          color: "#fff",
                          width: "100px",
                          height: "36px",
                          marginRight: "10px",
                          alignSelf: "center",
                        }}
                        onClick={() => checkData()}
                      >
                        Save
                      </Button>
                      <Button
                        variant="outlined"
                        style={{
                          width: "100px",
                          height: "36px",
                          alignSelf: "center",
                        }}
                        onClick={() => {
                          editObj.oeditmode = false;
                          setEditObj({ ...editObj });
                        }}
                      >
                        Cancel
                      </Button>
                    </>
                  )}
                  {selectedObj.seltype && selectedObj.sobject == "quiz" && (
                    <>
                      <CreateQuiz
                        selectedObj={selectedObj}
                        setSelectedObj={setSelectedObj}
                        finalSave={finalSave}
                        folder={folder}
                        typesButtons={typesButtons}
                        setTypeButtons={setTypeButtons}
                        quizQuestion={quizQuestion}
                        setQuizQuestion={setQuizQuestion}
                        editQuiz={editQuiz}
                      />
                    </>
                  )}
                </div>
              </>
            ) : (
              <RenderObjects />
            )}

            {/* {!editObj.oeditmode && renderObjects()} */}
          </div>
        </div>
      ) : null}
    </div>
  );
}
function Formative() {
  return <h1>formative</h1>;
}
function Summative(props) {
  const { questions, qtype, currentQuestion, imgUrl } = props;

  return (
    <div className="scrollQuiz">
      <div className="question-section">
        <div className="question-count">
          <span>Question {currentQuestion + 1}</span>/{questions.length}
        </div>
        <div
          className="question-text"
          dangerouslySetInnerHTML={{
            __html: questions[currentQuestion]?.istem,
          }}
        >
          {/* {questions[currentQuestion].istem} */}
        </div>

        {questions[currentQuestion]?.imageurl ? (
          <div className="img_container">
            <img
              src={`${imgUrl}${questions[currentQuestion]?.imageurl}`}
              alt="item"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}
function Feedback() {
  return <h1>Feedback</h1>;
}
