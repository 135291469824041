import { API } from "aws-amplify";

import config from "../../config/aws-exports";
import { useQuery } from "@tanstack/react-query";

const getStates = async (country_code) => {
  try {
    return API.get(
      config.aws_cloud_logic_custom_name,
      `/countries/${country_code}/states`
    );
  } catch (error) {
    // console.error(error)
    throw error;
  }
};

const useStates = ({ country_code }) => {
  return useQuery({
    queryKey: [`/countries/${country_code}/states`],
    enabled: country_code ? true : false,
    queryFn: () => getStates(country_code),
  });
};

export default useStates;
