// Dependencies imports
import React, { useState } from "react";

// Local imports
import { ReactComponent as CloseBlack } from "../../../assets/icons/close_black_24dp.svg";
import { ReactComponent as CloseWhite } from "../../../assets/icons/close_white_24dp.svg";
import { ReactComponent as Search } from "../../../assets/icons/search_black_24dp.svg";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../../redux/auth/authSlice";
import { Constants } from "../../../config/Constants";
import config from "../../../config/aws-exports";

// Style imports
import Select from "./SelectPopup.module.scss";

// Start of main component
const SelectPopup = (props) => {
  let {
    handleClose,
    tenants,
    tStandCoursePass,
    tStandContentPass,
    getStandAloneCourse,
    getStandAloneContent,
    getTodayContent,
    contentTodayPass,
    programsPass,
    getTodayUsers,
    todayCoursePass,
    getTodayCourses,
    todayUserPass,
    contentPass,
    getContent,
    coursePass,
    getCourses,
    getPrograms,
    getUserCount,
    getAllTenantsUsers,
    totalPass,
    dashChange,
    getSession,
    sessionPass,
    todaySessionPass,
    getTodaySession,
  } = props;
  const [search, setSearch] = useState("");
  let userDetails = useSelector(authData);
  const dispatch = useDispatch();

  function selected(value, did) {
    let sdata = { ...userDetails };
    sdata.selectedTenant = value;
    sdata.buttonText = did;
    dispatch(awsSignIn(sdata));
    if (totalPass === true) {
      getAllTenantsUsers(value);
    }
    if (dashChange === true) {
      getUserCount(value);
    }
    if (programsPass === true) {
      getPrograms(value);
    }
    if (coursePass === true) {
      getCourses(value);
    }
    if (contentPass === true) {
      getContent(value);
    }
    if (todayUserPass === true) {
      getTodayUsers(value);
    }
    if (todayCoursePass === true) {
      getTodayCourses(value);
    }
    if (contentTodayPass === true) {
      getTodayContent(value);
    }
    if (tStandCoursePass === true) {
      getStandAloneCourse(value);
    }
    if (tStandContentPass === true) {
      getStandAloneContent(value);
    }
    if (sessionPass === true) {
      getSession(value);
    }
    if (todaySessionPass === true) {
      getTodaySession(value);
    }
    handleClose();
  }

  function clearSelection() {
    let sdata = { ...userDetails };
    sdata.selectedTenant = "ALL ORGANIZATION";
    sdata.buttonText = "ALL ORGANIZATION";
    dispatch(awsSignIn(sdata));
    if (totalPass === true) {
      getAllTenantsUsers("ALL TENANT");
    }
    if (dashChange === true) {
      getUserCount("ALL TENANT");
    }
    if (programsPass === true) {
      getPrograms("ALL TENANT");
    }
    if (coursePass === true) {
      getCourses("ALL TENANT");
    }
    if (contentPass === true) {
      getContent("ALL TENANT");
    }
    if (todayUserPass === true) {
      getTodayUsers("ALL TENANT");
    }
    if (todayCoursePass === true) {
      getTodayCourses("ALL TENANT");
    }
    if (contentTodayPass === true) {
      getTodayContent("ALL TENANT");
    }
    if (tStandCoursePass === true) {
      getStandAloneCourse("ALL TENANT");
    }
    if (tStandContentPass === true) {
      getStandAloneContent("ALL TENANT");
    }
    if (sessionPass === true) {
      getSession("ALL TENANT");
    }
    if (todaySessionPass === true) {
      getTodaySession("ALL TENANT");
    }
    handleClose();
  }

  const filterTenants = tenants.filter((tenant) =>
    tenant.did.toLowerCase().includes(search.toLowerCase())
  );

  // Start of main return
  return (
    <div className={Select.main}>
      <div className={Select.closeholder}>
        <CloseBlack className={Select.close} onClick={handleClose} />
      </div>
      <div className={Select.searchbar}>
        <div className={Select.searchholder}>
          <input
            type="text"
            className={Select.searchinput}
            placeholder="Search Organization"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          ></input>
          <Search />
        </div>
        <div
          className={Select.clearbar}
          onClick={() => {
            clearSelection();
            handleClose();
          }}
        >
          <CloseWhite />
          <h4 className={Select.clearheading}>Clear Selection</h4>
        </div>
      </div>
      <div className={Select.tenantlist}>
        {tenants === undefined || tenants === null ? (
          <h3 className={Select.tenantnames}>No Organizations</h3>
        ) : (
          filterTenants.map((value, index) => {
            return (
              <h3
                className={Select.tenantnames}
                onClick={() => {
                  selected(value.oid, value.did);
                  console.log("value", value);
                }}
              >
                {value.did}
              </h3>
            );
          })
        )}
      </div>
    </div>
  );
};

export default SelectPopup;
