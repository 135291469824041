// Dependencies imports
import Rect from 'react';

// Local imports


// Style imports
import Delete from './DeleteConfirmation.module.scss';

const DeleteConfirmation = (props) => {
    let {handleDeleteClose} = props;
    return(
        <div className={Delete.main}>
           <h3 className={Delete.heading}>Are you sure you want to Delete?</h3>
           <div className={Delete.buttonholders}>
               <button className={Delete.delete}>Delete</button>
               <button className={Delete.cancel} onClick={handleDeleteClose}>Cancel</button>
           </div>
        </div>
    );
};

export default DeleteConfirmation;