// Dependencies imports
import React, { useState, useEffect } from "react";
import {
  DataGrid,
  GridToolbar,
  GridToolbarContainer,
} from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import Rating from "@mui/material/Rating";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../../redux/auth/authSlice";
import Paper from "@mui/material/Paper";
import { API } from "aws-amplify";
import moment from "moment";

// Local imports
import { Constants } from "../../../config/Constants";
import image from "../../../assets/images/2.png";
import SelectPopup from "./SelectPopup";
import Breadcrumb from "../../Breadcrumbs/Breadcrumbs";
import config from "../../../config/aws-exports";
import image1 from "../Content/2.png";

// Style imports
import Tcourses from "./TotalCourses.module.scss";

// Custom Toolbard to export and filter for datagrid
function CustomToolbar() {
  return (
    <GridToolbarContainer
      style={{ display: "flex", justifyContent: "flex-end" }}
    >
      <GridToolbar />
    </GridToolbarContainer>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& .super-app-theme--header": {
      backgroundColor: Constants.blue_color,
      fontSize: "16px",
      color: "rgb(255,255,255)",
      fontFamily: "nunito",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
    cursor: "pointer",
  },
}));

// Start of CoursesToday component
const SessionToday = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  let userDetails = useSelector(authData);
  const [columns, setColumns] = useState([]);
  const [todaySessionPass, setTodaySessionPass] = useState(false);
  const [sessions, setSessions] = useState([]);
  const [rows, setRows] = useState([]);
  const [spin, setSpin] = useState(false);
  const [tenants, setTenants] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    getTenants(userDetails.selectedTenant);
    breadcrumb();
    return () => {};
  }, []);

  // function to get Tenants list
  async function getTenants(value) {
    console.log("userDetails", userDetails);
    setSpin(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        schema: Constants.SCHEMA_NAME
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log(bodyParam.body);
      const ten = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_TENANTS,
        bodyParam
      );
      if (ten !== undefined && ten !== null) {
        setTenants(ten.body);
        getTodaySession(value);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function getTodaySession(value) { 
    setSpin(true);
    const bodyParam = {
      body: {
        oid: value === "ALL ORGANIZATION" ? "ALL TENANT" : value,
        schema: Constants.SCHEMA_NAME,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log(bodyParam.body);
      const Session = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_SESSION,
        bodyParam
      );
      if (Session !== undefined && Session !== null) {
        setSessions(Session.body);
        setSpin(false);
        const listAll = Session.body;
        console.log("rows", listAll);
        var myDateT = moment().format("L");
        const arrayDateFilter = listAll.filter((innArr, index) => {
          let convertedDate = moment(new Date(innArr?.date_list?.[0]?.combineStartTime).getTime()).format("L");

          console.log("trueSTA");
          console.log("trueCON", convertedDate);

          console.log("true", convertedDate === myDateT);
          if (convertedDate === myDateT) {
            return innArr;
          }
        });
        console.log("arrayDateFileter", arrayDateFilter);
        let trows = [];
        for (let i = 0; i < arrayDateFilter.length; i++) {
          let obj = {};
          obj.id = i;
          obj.sessionImage = arrayDateFilter[i].timgname;
          obj.name = arrayDateFilter[i].sn;
          obj.datetime = `${moment(arrayDateFilter[i]?.date_list?.[0]?.combineStartTime).format(
            "DD-MM-YYYY")} ${moment(arrayDateFilter[i]?.date_list?.[0]?.combineStartTime).format("LT")}`;
          obj.registered = arrayDateFilter[i].approved_count;
          trows.push(obj);
        }
        const tcolumns = [
          {
            field: "sessionImage",
            headerName: "Session Image",
            flex: 1,
            headerClassName: "super-app-theme--header",
            disableExport: true,
            renderCell: (params) => (
              <img
                alt=""
                src={
                  params.value=== null
                    ? image1
                    : `https://${
                        config.DOMAIN
                      }/${config.aws_org_id.toLowerCase()}-resources/images/session-images/${
                        params.value
                      }`
                }
                style={{ width: "100px", height: "auto", borderRadius: "10px" }}
              />
            ),
          },
          {
            field: "name",
            headerName: "Live Session Name",
            flex: 1,
            headerClassName: "super-app-theme--header",
          },
          {
            field: "datetime",
            headerName: "Date and Time",
            flex: 1,
            headerClassName: "super-app-theme--header",
          },
          {
            field: "registered",
            headerName: "Total Registrations",
            flex: 1,
            headerClassName: "super-app-theme--header",
          },
        ];
        //alert(JSON.stringify(trows));
        setRows(trows);
        setColumns(tcolumns);
        setSpin(false);
      }
    } catch (error) {
      console.error(error);
    }
  }


  // function to open select teanant popup
  const handleOpen = () => {
    setOpen(true);
    setTodaySessionPass(true);
  };

  // function to close select tenant popup
  const handleClose = () => {
    setOpen(false);
    setTodaySessionPass(false);
  };

  const tcolumns = [
    {
      field: "col1",
      headerName: "Course Image",
      flex: 1,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <img
          style={{
            width: "100px",
            height: "100px",
            borderRadius: "10px",
          }}
          src={`https://${
            config.DOMAIN
          }/${config.aws_org_id.toLowerCase()}-resources/images/topic-images/${
            params.value
          }.png`}
          alt=""
        ></img>
      ),
    },
    {
      field: "col2",
      headerName: "Course Name",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "col3",
      headerName: "Participants Started",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "col4",
      headerName: "Participants Completed",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "col5",
      headerName: "Ratings",
      flex: 1,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <Rating
          name="read-only"
          value={params.value}
          readOnly
          precision={0.5}
        />
      ),
    },
  ];

  const trows = [{ id: 1, col2: "Physics" }];

  function breadcrumb() {
    let sdata = { ...userDetails };
    if (sdata.breadcrumb == undefined) {
      sdata.breadcrumb = [];
      let temp = [...sdata.breadcrumb];
      temp[0] = {
        name: "Dashboard",
        path: "/landingscreen",
      };
      temp[1] = {
        name: "Courses Started Today",
        path: "/landingscreen/coursestoday",
      };
      sdata.breadcrumb = temp;
    } else {
      let temp = [...sdata.breadcrumb];
      temp[0] = {
        name: "Dashboard",
        path: "/landingscreen",
      };
      temp[1] = {
        name: "Courses Started Today",
        path: "/landingscreen/coursestoday",
      };
      sdata.breadcrumb = temp;
    }
    dispatch(awsSignIn(sdata));
  }

  // Start of main return
  return (
    <div className={Tcourses.main}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <SelectPopup
            handleClose={handleClose}
            tenants={tenants}
            todaySessionPass={todaySessionPass}
            getTodaySession={getTodaySession}
          />
        </Fade>
      </Modal>
      <Backdrop className={classes.backdrop} open={spin}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Breadcrumb Bindex={1} />
      {userDetails.role === "Admin" ? (
        userDetails.role === "Manager" ? null : (
          <div className={Tcourses.tenantselect}>
            <h4 className={Tcourses.selecttenant}>Select Organization:</h4>
            <h5
              className={Tcourses.tenantname}
              onClick={() => {
                handleOpen();
              }}
            >
              {userDetails.selectedTenant}
            </h5>
          </div>
        )
      ) : null}
      <div
        style={{
          height: 600,
          width: "100%",
          overflowY: "auto",
          paddingTop: "20px",
        }}
        className={classes.root}
      >
        <Paper>
          <div
            style={{
              height: 500,
              width: "100%",
              overflowY: "auto",
            }}
            className={classes.root}
          >
            <DataGrid
              rows={rows}
              columns={columns}
              className={classes[".MuiDataGrid-root"]}
              components={{
                Toolbar: CustomToolbar,
              }}
              disableSelectionOnClick
            />
          </div>
        </Paper>
      </div>
    </div>
  );
};

export default SessionToday;
