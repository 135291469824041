// Dependencies imports
import React, { useState } from "react";
import swal from "sweetalert";
import Paper from "@material-ui/core/Paper";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { Constants } from "../../../config/Constants";
import config from "../../../config/aws-exports";
import { API } from "aws-amplify";
import { useSelector } from "react-redux";
import { authData } from "../../../redux/auth/authSlice";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

// Local imports

// Styles imports
import Add from "./AddEvent.module.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
  },
}));

const DeleteEvent = (props) => {
  let { index, handleDeleteClose, getEvents, deleteData, getEvents1 } = props;
  const [spin, setSpin] = useState(false);
  const classes = useStyles();
  // alert("1"+JSON.stringify(editEventData))

  let userDetails = useSelector(authData);
  console.log("log", deleteData);

  const onsubmit = () => {
    addEvent();
  };

  async function addEvent() {
    setSpin(true);
    const bodyParam = {
      body: {
        atype: 2,
        schema: Constants.SCHEMA_NAME,
        evid: deleteData.ev_id,
        ev_date: null,
        evtitle: null,
        ev_desc: null,
        stnt_id: null,
        oid: config.aws_org_id,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.ADD_EVENTS,
        bodyParam
        //`${Constants.GET_MY_PROGRAMS}`, bodyParam,
      );
      // const users = response;
      handleDeleteClose();
      // getEvents1();
      getEvents();
      swal({
        title: "Success",
        text: "Event Delete successfully",
        icon: "success",
        dangerMode: false,
      }).then((willDelete) => {
        if (willDelete) {
          handleDeleteClose();
        }
      });
      setSpin(false);
      getEvents();
      //getEvents1();
    } catch (error) {
      swal({
        title: "Oooops!",
        text: "Soomthing wrong!",
        icon: "warning",
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          setSpin(false);
        }
      });
      console.log("getUserError", error);
    }
    console.log("Mount");
  }

  return (
    <div>
      {spin === false ? (
        <div className={Add.mainpopup} style={{ width: "100%" }}>
          {/* <h3 className={Add.header}>Delete Event</h3> */}
          <div className={Add.form}>
            <form>
              <h3 className={Add.headingdel}>
                Are you sure you want to delete this event?
              </h3>
              {/* <input type="date" className={Add.date} onChange={onChangeHandlerEventDate} value={eventDate} /><br />
                    <div style={{color: "#cc0000", marginBottom: "12px", marginTop: "-10px", textAlign:"start", marginLeft: "20%"}}>{eventNameErr}</div>
                    <textarea placeholder="Type your event here..." rows={5} className={Add.area} onChange={onChangeHandlerEventName} value={evnentName} ></textarea>
                    <div className={Add.cautionholder}>
                        <h4 className={Add.caution}>* Event description should be less than 160 characters.</h4>
                    </div>
                    <input type="text" placeholder={"Add your link"} className={Add.date} onChange={onChangeHandlerEventLink} value={eventLink} ></input> */}
              <div
                className={Add.buttonholder}
                style={{
                  display: "flex",
                  gap: "50px",
                  justifyContent: "center",
                  padding: "10px",
                }}
              >
                <button className={Add.save} onClick={onsubmit}>
                  Yes
                </button>
                <button
                  className={Add.cancel}
                  onClick={() => handleDeleteClose()}
                >
                  No
                </button>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <div className={classes.root}>
          <CircularProgress style={{ color: Constants.main_color_1 }} />
        </div>
      )}
    </div>
  );
};

export default DeleteEvent;
