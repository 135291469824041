import React from "react";
import SideNav from "../../components/SideNav/sideNav";
import Header from "../../components/Header/hearder";
import Dashboard from "../../components/pages/Dashboard/Dashboard";
import { Wrapper } from "../layout.styles";

const DashboardLayout = () => {
  return (
    <React.Fragment>
      <Header />
      <Wrapper>
        <SideNav />
        <div style={{ margin: "70px 0px 0px 200px", width: "100%" }}>
          <Dashboard />
        </div>
      </Wrapper>
    </React.Fragment>
  );
};

export default DashboardLayout;
