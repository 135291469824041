// Dependencies imports
import React, { useState, useEffect } from "react";
import {
  DataGrid,
  GridToolbar,
  GridToolbarContainer,
} from "@material-ui/data-grid";
import { Constants } from "../../../config/Constants";
import Paper from "@material-ui/core/Paper";
import Breadcrumb from "../../Breadcrumbs/Breadcrumbs";
import { API } from "aws-amplify";
import config from "../../../config/aws-exports";
import { makeStyles } from "@material-ui/core/styles";
import Stand from "./objectUsers.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../../redux/auth/authSlice";
import CircularProgress from "@material-ui/core/CircularProgress";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .super-app-theme--header": {
      backgroundColor: Constants.blue_color,
      fontSize: "16px",
      color: "rgb(255,255,255)",
      fontFamily: "nunito",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
    cursor: "pointer",
  },
}));

const ObjectUsers = () => {
  const [open, setOpen] = useState(false);
  const [spin, setSpin] = useState(true);
  const [rows, setRows] = useState([]);
  const [tenants, setTenants] = useState({});
  const [tStandContentPass, setTStandContentPass] = useState(false);
  let userDetails = useSelector(authData);
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    getStandAloneContent(userDetails.tenantdata.tenantname);
    breadcrumb();
    return () => {};
  }, []);

  // function to get Tenants list

  // function to get Tenants list
  async function getStandAloneContent(value) {
    console.log("userDetails", userDetails);
    setSpin(true);
    const bodyParam = {
      body: {
        oid: value === value,
        objid: userDetails.OBJID,
        schema: Constants.SCHEMA_NAME,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log(bodyParam.body);
      const users = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_OBJECT_ANALYTICS,
        bodyParam
      );
      if (users !== undefined && users !== null) {
        mapData(users.body);
        setSpin(false);
      }
    } catch (error) {
      console.error(error);
      setSpin(false);
    }
  }

  function mapData(value) {
    let trows = [];
    for (let i = 0; i < value.length; i++) {
      let obj = {};
      obj.id = i;
      obj.col1 = value[i].first_name;
      obj.col2 = value[i].last_name;
      obj.col3 = value[i].oid;
      obj.col4 = value[i].view_val === null ? 0 : value[i].view_val;
      obj.col5 = value[i].bounce === null ? 0 : value[i].bounce;
      obj.col6 = value[i].bookmark === true ? "Yes" : "No";
      trows.push(obj);
    }
    setRows(trows);
  }

  // Custom Toolbard to export and filter for datagrid
  function CustomToolbar() {
    return (
      <GridToolbarContainer
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <GridToolbar />
      </GridToolbarContainer>
    );
  }

  const tcolumns = [
    {
      field: "col1",
      headerName: "First Name",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "col2",
      headerName: "Last Name",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "col3",
      headerName: "Organization Name",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "col4",
      headerName: "Views",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "col5",
      headerName: "Bounce Views",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "col6",
      headerName: "Bookmarked",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
  ];

  // function to open select teanant popup
  const handleOpen = () => {
    setOpen(true);
    setTStandContentPass(true);
  };

  // function to close select tenant popup
  const handleClose = () => {
    setOpen(false);
    setTStandContentPass(true);
  };

  function breadcrumb() {
    let sdata = { ...userDetails };
    let temp = [...sdata.breadcrumb];

    temp[2] = {
      name: userDetails.objectName,
      path: "/tenants/viewTenant/objectUsers",
    };

    sdata.breadcrumb = temp;

    dispatch(awsSignIn(sdata));
  }

  return (
    <div className={Stand.main}>
      <Breadcrumb Bindex={2} />

      <Backdrop className={classes.backdrop} open={spin}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Paper style={{ marginTop: "20px" }}>
        <div
          style={{
            height: 350,
            width: "100%",
            overflowY: "auto",
          }}
          className={classes.root}
        >
          <DataGrid
            rows={rows}
            columns={tcolumns}
            className={classes[".MuiDataGrid-root"]}
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </div>
      </Paper>
    </div>
  );
};

export default ObjectUsers;
