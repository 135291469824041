import React, { useState, useEffect, useRef } from "react";
import { API } from "aws-amplify";
import Cviewstyle from "./courseCreation.module.scss";
import config from "../../../../config/aws-exports";
import { useSelector } from "react-redux";
import { Constants } from "../../../../config/Constants";
import { authData } from "../../../../redux/auth/authSlice";
import {
  Modal,
  Button,
  Backdrop,
  makeStyles,
  TextField,
  InputAdornment,
  CircularProgress,
} from "@material-ui/core";
import RegexTextField from "./RegexTextField";
import axios from "axios";
import CloseIcon from "@material-ui/icons/Close";
import { EDITOR_JS_TOOLS } from "./EditorConstants";
import edjsHTML from "editorjs-html";
import JSZip from "jszip";
import CreateQuiz from "../../../../components/CreateQuiz/CreateQuiz";
import produce from "immer";
import swal from "sweetalert";
import EditorJs from "react-editor-js";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #fff",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "1100px",
    height: "660px",
    borderRadius: ".8rem",
    overflowX: "hidden",
    overflowY: "auto",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.73)",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "98%",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
export default function ObjectPopUp({
  objOpen,
  setObjOpen,
  renderSwitch,
  folder,
  cNugget,
  setNuggets,
  nuggets,
}) {
  const classes = useStyles();
  const iniotypes = [
    { type: "Narrative", id: "2", img: "html" },
    { type: "PDF", id: "3", img: "pdf" },
    { type: "Quiz", id: "4", img: "quiz" },
    { type: "Interactivity", id: "5", img: "Interactivity" },
    { type: "Video", id: "6", img: "video" },
    { type: "Audio", id: "7", img: "audio" },
    { type: "Youtube", id: "8", img: "youtube" },
    { type: "Vimeo", id: "9", img: "vimeo" },
    { type: "Scorm", id: "10", img: "scorm" },
  ];
  let userDetails = useSelector(authData);
  const [loading, setLoading] = useState(false);
  const [selectType, setSelectType] = useState(true);
  const [otypes, setOtypes] = useState(iniotypes);
  const [selectedFile, setSelectedFile] = useState();
  const refobject = useRef({});
  const [isBookmarkedLoaded, setisBookmarkedLoaded] = useState(false);
  const [typesButtons, setTypeButtons] = useState(true);
  const [quizQuestion, setQuizQuestion] = useState([]);
  const [err, setErr] = useState("");
  const handleOpen = () => {
    setObjOpen(true);
  };
  const [selectedObj, setSelectedObj] = useState({});
  const onlyAlphanumericRegex = /[^0-9]/gi;
  const instanceRef = useRef(null);
  useEffect(() => {
    // action on update of movies
  }, [selectedObj]);
  const handleClose = () => {
    setSelectedObj({});
    setSelectType(true);
    setObjOpen(false);
    setTypeButtons(true);
    setQuizQuestion([]);
    // setEditObj({ ...editObj, objectView: false });
    setSelectedFile(undefined);
  };
  const sobjecttype = function (id) {
    let obj = {};
    obj.otherdata = { title: "", desc: "", dur: "", files: "" };
    if (id == "3" || id == 3) {
      obj.pdft = true;
      obj.sobject = "PDF";
      obj.afile = ".pdf";
      obj.otype = "pdf";
      //obj.data = [{"ptitle":"","pdur":"","pfile":""}];
    } else if (id == "7" || id == 7) {
      obj.audiot = true;
      obj.sobject = "Audio";
      obj.otherdata.ytcode = "";
      obj.afile = "audio/*";
      obj.otype = "audio";
    } else if (id == "2" || id == 2) {
      obj.htmlt = true;
      obj.sobject = "Narrative";
      obj.selecthtmltype = true;
      obj.afile = ".html";
      obj.otype = "html";
      //obj.htmldata =  [{"quiloutput":"","htitle":"","hdur":"","hdursecs":"","hfile":""}];
      obj.otherdata.quiloutput = "";
    } else if (id == "5" || id == 5) {
      obj.htmlt = true;
      obj.sobject = "Interactivity";
      obj.selecthtmltype = true;
      obj.afile = ".html, application/zip, application/x-zip-compressed, .zip";
      obj.otype = "Interactivity";
      //obj.htmldata =  [{"quiloutput":"","htitle":"","hdur":"","hdursecs":"","hfile":""}];
      obj.otherdata.quiloutput = "";
    } else if (id == "4" || id == 4) {
      obj.quizt = true;
      obj.sobject = "Quiz";
      obj.qid = "0";
      obj.itemsPerPage = 1;
      obj.noques = false;
      obj.quizid = 1;
      obj.otype = "quiz";
      obj.quizquestions = [];
    } else if (id === "8" || id === 8) {
      obj.sobject = "youtube";
      obj.stype = "youtube";
      obj.otype = "youtube";
    } else if (id === "9" || id === 9) {
      obj.sobject = "vimeo";
      obj.stype = "vimeo";
      obj.otype = "vimeo";
    } else if (id == "10" || id == 10) {
      obj.htmlt = true;
      obj.sobject = "scorm";
      obj.selecthtmltype = true;
      obj.afile = "application/zip, application/x-zip-compressed, .zip";
      obj.otype = "scorm";

      obj.otherdata.quiloutput = "";
    } else {
      obj.videot = true;
      obj.sobject = "Video";
      obj.afile = "video/*";
      obj.otype = "video";
    }

    obj.seltype = true;
    obj.error1 = false;
    setSelectedObj(obj);
    if (id != "2" && id != 2) {
      setSelectType(false);
    }
  };
  const isImage = (filename) => {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "jpg":
      case "png":
      case "jpeg":
        return true;
      default:
        return false;
    }
  };
  function isVideo(filename) {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "m4v":
      case "avi":
      case "mpg":
      case "mp4":
        // etc
        return true;
      default:
        return false;
    }
  }
  function isInteractivity(filename) {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "zip":
      case "html":
        return true;
      default:
        return false;
    }
  }
  function isScorm(filename) {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "zip":
        return true;
      default:
        return false;
    }
  }
  function isAudio(filename) {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "mp3":
      case "wav":
      case "msv":
      case "m4p":
        return true;
      default:
        return false;
    }
  }
  function isPDF(filename) {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "pdf":
        return true;
      default:
        return false;
    }
  }
  function isHTML(filename) {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "html":
        return true;
      default:
        return false;
    }
  }
  function getExtension(filename) {
    var parts = filename.split(".");
    return parts[parts.length - 1];
  }
  const changeHandler = (event) => {
    // setSelectedFile(new File([""], "filename"));
    if (event.target.files[0] !== undefined) {
      if (
        !isVideo(event.target.files[0].name) &&
        selectedObj.afile === "video/*"
      ) {
        swal("Oops!", "Select correct file format..!", "error");
        return;
      }
      if (
        !isInteractivity(event.target.files[0].name) &&
        selectedObj.afile ===
          ".html, application/zip, application/x-zip-compressed, .zip"
      ) {
        swal("Oops!", "Select correct file format..!", "error");
        return;
      }
      if (
        !isScorm(event.target.files[0].name) &&
        selectedObj.afile ===
          "application/zip, application/x-zip-compressed, .zip"
      ) {
        swal("Oops!", "Select correct file format..!", "error");
        return;
      }
      if (!isPDF(event.target.files[0].name) && selectedObj.afile === ".pdf") {
        swal("Oops!", "Select correct file format..!", "error");
        return;
      }
      if (
        !isHTML(event.target.files[0].name) &&
        selectedObj.afile === ".html"
      ) {
        swal("Oops!", "Select correct file format..!", "error");
        return;
      }
      if (
        !isAudio(event.target.files[0].name) &&
        selectedObj.afile === "audio/*"
      ) {
        swal("Oops!", "Select correct file format..!", "error");
        return;
      }
      let uri = URL.createObjectURL(event.target.files[0]);

      setSelectedFile(event.target.files[0]);
      selectedObj.otherdata.files = event.target.files[0].name;
      setSelectedObj(selectedObj);
    }
  };
  const finalSave = async () => {
    selectedObj.otherdata.dur =
      Number(selectedObj.otherdata.min) * 60 +
      Number(selectedObj.otherdata.sec);
    let file;
    let id = Math.floor(Math.random() * (999 - 100 + 1) + 100);
    let filename = "";
    if (selectedObj.stype !== undefined && selectedObj.stype === "chtml") {
      filename =
        Number(Math.floor(Math.random() * (999 - 100 + 1) + 100)) + ".html";
    } else if (selectedObj.otype === "quiz") {
      filename =
        Number(Math.floor(Math.random() * (999 - 100 + 1) + 100)) + ".json";
    } else if (selectedFile !== undefined) {
      filename = id + selectedFile.name;
    }

    let object = {
      otitle: selectedObj.otherdata.title,
      odescription: selectedObj.otherdata.desc,
      otype: selectedObj.otype,
      oduration: selectedObj.otherdata.dur,
      ourl: filename,
      filechanges: true,
      newfile: filename,
    };
    console.log(selectedObj.quizdata);
    if (
      selectedObj.quizdata !== undefined &&
      selectedObj.quizdata.cutoff !== undefined &&
      selectedObj.quizdata.qtype === 2
    ) {
      object.cutoff = parseInt(selectedObj.quizdata.cutoff);
    } else {
      object.cutoff = 0;
    }

    if (selectedObj.otherdata.quesType !== undefined) {
      object.qtype = parseInt(selectedObj.otherdata.quesType);
    }
    console.log(object);
    if (cNugget !== undefined && cNugget.nid !== undefined) {
      if (cNugget.objects.length > 0) {
        object.oid =
          cNugget.nid +
          "-" +
          (Number(
            cNugget.objects[cNugget.objects.length - 1].oid.substr(
              cNugget.objects[cNugget.objects.length - 1].oid.length - 1
            )
          ) +
            1);
      } else {
        object.oid = cNugget.nid + "-1";
      }
      if (cNugget.cedit !== undefined) {
        var filetype = filename.split(".").pop();
        object.ourl = object.oid + "." + filetype;
      }
    }
    if (selectedObj.otype == "scorm") {
      object.ourl = selectedObj.sname;
    }
    refobject.current = object;

    var body = {
      filename: filename,
      folder: folder,
    };
    if (selectedObj.stype !== undefined && selectedObj.stype === "chtml") {
      body.filetype = "text/html";
      const savedData = await instanceRef.current.save();

      const edjsParser = edjsHTML();
      // blocks
      object.stype = "chtml";
      file = edjsParser.parse(savedData);
      file = JSON.parse(JSON.stringify(file));
      let tempFile = [];
      let html = "<html><head></head><body>";
      for (let i = 0; i < file.length; i++) {
        html = html + file[i];
        tempFile.push(file[i]);
      }
      html = html + "</html></body>";
      file = html;
      object.fileHtml = tempFile;
    } else if (selectedObj.otype === "quiz") {
      file = selectedObj.quizdata;
      object.file = selectedObj.quizdata;
      body.filetype = "application/json";
    } else if (
      selectedObj.otype === "youtube" ||
      selectedObj.otype === "vimeo"
    ) {
      object.embeddedcode = selectedObj.otherdata.embeddedcode;
      object.url = "";
    } else {
      file = selectedFile;

      object.file = selectedFile;
      body.filetype = selectedFile.type;
    }

    //var typejson = {"type" :"uploadfile","file":selectedFile,"body":body };
    setisBookmarkedLoaded(true);
    setSelectedFile(undefined);
    uploadfile(body, file);
  };
  const checkData = async () => {
    // console.log(JSON.stringify(selectedObj.stype,null,2))

    if (
      selectedObj.otherdata.title === undefined ||
      selectedObj.otherdata.title === "" ||
      selectedObj.otherdata.title.replace(/\s/g, "").length === 0
    ) {
      selectedObj.otherdata.eval = false;
      selectedObj.otherdata.dsval = false;
      selectedObj.otherdata.mval = false;
      selectedObj.otherdata.sval = false;
      selectedObj.otherdata.tval = true;

      setSelectedObj((prevselectedObj) => ({
        ...prevselectedObj,
        ...selectedObj,
      }));

      return;
    }

    if (
      selectedObj.otherdata.desc === undefined ||
      selectedObj.otherdata.desc === "" ||
      selectedObj.otherdata.desc.replace(/\s/g, "").length === 0
    ) {
      selectedObj.otherdata.dsval = true;
      selectedObj.otherdata.tval = false;
      selectedObj.otherdata.eval = false;
      selectedObj.otherdata.mval = false;
      selectedObj.otherdata.sval = false;
      setSelectedObj((prevselectedObj) => ({
        ...prevselectedObj,
        ...selectedObj,
      }));
      return;
    }
    if (
      selectedObj.otherdata.min === undefined ||
      selectedObj.otherdata.min === "" ||
      selectedObj.otherdata.min.replace(/\s/g, "").length === 0
    ) {
      selectedObj.otherdata.dsval = false;
      selectedObj.otherdata.tval = false;
      selectedObj.otherdata.eval = false;
      selectedObj.otherdata.mval = true;
      setSelectedObj((prevselectedObj) => ({
        ...prevselectedObj,
        ...selectedObj,
      }));
      return;
    }
    if (
      selectedObj.otherdata.sec === undefined ||
      selectedObj.otherdata.sec === "" ||
      selectedObj.otherdata.sec.replace(/\s/g, "").length === 0
    ) {
      selectedObj.otherdata.dsval = false;
      selectedObj.otherdata.tval = false;
      selectedObj.otherdata.mval = false;
      selectedObj.otherdata.eval = false;
      selectedObj.otherdata.sval = true;
      setSelectedObj((prevselectedObj) => ({
        ...prevselectedObj,
        ...selectedObj,
      }));
      return;
    }

    let totalDurationInSec =
      selectedObj.otherdata.min * 60 + selectedObj.otherdata.sec;
    if (totalDurationInSec > 0) {
      setErr("");
    } else {
      setErr("Duration should be greater than zero");
      selectedObj.otherdata.dsval = false;
      selectedObj.otherdata.tval = false;
      selectedObj.otherdata.mval = false;
      selectedObj.otherdata.eval = false;
      selectedObj.otherdata.sval = true;
      setSelectedObj((prevselectedObj) => ({
        ...prevselectedObj,
        ...selectedObj,
      }));
      return;
    }

    if (
      selectedObj.sobject !== "youtube" &&
      selectedObj.sobject !== "vimeo" &&
      selectedObj.stype !== "chtml"
    ) {
      if (selectedFile === undefined) {
        selectedObj.otherdata.dsval = false;
        selectedObj.otherdata.tval = false;
        selectedObj.otherdata.mval = false;
        selectedObj.otherdata.sval = false;
        selectedObj.otherdata.eval = false;
        selectedObj.ferror = true;
        setSelectedObj((prevselectedObj) => ({
          ...prevselectedObj,
          ...selectedObj,
        }));
        return;
      }
    }

    if (selectedObj.stype !== undefined && selectedObj.stype === "chtml") {
      const savedData = await instanceRef.current.save();

      if (savedData.blocks.length === 0) {
        selectedObj.otherdata.dsval = false;
        selectedObj.otherdata.tval = false;
        selectedObj.otherdata.mval = false;
        selectedObj.otherdata.sval = false;
        selectedObj.otherdata.eval = false;
        selectedObj.ferror = true;
        setSelectedObj((prevselectedObj) => ({
          ...prevselectedObj,
          ...selectedObj,
        }));
        return;
      }
    } else if (
      selectedObj.sobject === "Interactivity" ||
      selectedObj.sobject === "scorm" ||
      (selectedFile === undefined &&
        selectedObj.sobject !== "youtube" &&
        selectedObj.sobject !== "vimeo")
    ) {
      if (selectedFile === undefined) {
        selectedObj.otherdata.dsval = false;
        selectedObj.otherdata.tval = false;
        selectedObj.otherdata.mval = false;
        selectedObj.otherdata.sval = false;
        selectedObj.otherdata.eval = false;
        selectedObj.ferror = true;
        setSelectedObj((prevselectedObj) => ({
          ...prevselectedObj,
          ...selectedObj,
        }));
        return;
      }
      JSZip.loadAsync(selectedFile).then(function (content) {
        var tmp = JSON.stringify(content);
        let fileexist = false;
        var t1 = tmp.toString();
        t1 = JSON.parse(t1);

        if (selectedObj.sobject === "scorm") {
          Object.keys(content.files).forEach(function (filename) {
            if (
              content.files[filename].name.toString().includes("indexAPI.html")
            ) {
              fileexist = true;
              selectedObj.sname = content.files[filename].name;
              setSelectedObj(selectedObj);
            }
          });
          if (fileexist === false) {
            Object.keys(content.files).forEach(function (filename) {
              if (
                content.files[filename].name.toString().includes("index.html")
              ) {
                fileexist = true;
                selectedObj.sname = content.files[filename].name;
                setSelectedObj(selectedObj);
              }
            });
          }
        } else {
          try {
            if (t1.files["index.html"].name) {
              fileexist = true;
            }
          } catch (e) {
            fileexist = false;
          }
        }
        if (!fileexist) {
          selectedObj.otherdata.dsval = false;
          selectedObj.otherdata.tval = false;
          selectedObj.otherdata.mval = false;
          selectedObj.otherdata.sval = false;
          selectedObj.otherdata.eval = false;
          selectedObj.ferror = true;
          setSelectedObj((prevselectedObj) => ({
            ...prevselectedObj,
            ...selectedObj,
          }));
          swal("Oops!", "Select correct file format..!", "error");
          return;
        } else {
          finalSave();
        }
      });
    } else if (
      selectedFile === undefined &&
      selectedObj.stype !== "vimeo" &&
      selectedObj.stype !== "youtube"
    ) {
      selectedObj.otherdata.dsval = false;
      selectedObj.otherdata.tval = false;
      selectedObj.otherdata.mval = false;
      selectedObj.otherdata.sval = false;
      selectedObj.otherdata.eval = false;
      selectedObj.ferror = true;
      setSelectedObj((prevselectedObj) => ({
        ...prevselectedObj,
        ...selectedObj,
      }));
      return;
    } else if (
      selectedObj.stype === "vimeo" ||
      selectedObj.stype === "youtube"
    ) {
      if (
        selectedObj.otherdata.embeddedcode === undefined ||
        selectedObj.otherdata.embeddedcode === "" ||
        selectedObj.otherdata.embeddedcode.replace(/\s/g, "").length === 0
      ) {
        selectedObj.otherdata.dsval = false;
        selectedObj.otherdata.tval = false;
        selectedObj.otherdata.mval = false;
        selectedObj.otherdata.sval = false;
        selectedObj.otherdata.eval = true;

        setSelectedObj((prevselectedObj) => ({
          ...prevselectedObj,
          ...selectedObj,
        }));

        return;
      }
    }
    if (
      selectedObj.sobject !== "Interactivity" &&
      selectedObj.sobject !== "scorm"
    ) {
      finalSave();
    }
  };
  async function uploadfile(body, file) {
    const bodyParam = {
      body,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    let refObj = { ...refobject.current };

    try {
      const preSignedData = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_PRESIGNED_URL,
        //"/GET_PRESIGNED_URL",
        bodyParam
      );
      let ftype = "";

      if (selectedObj.stype !== undefined && selectedObj.stype === "chtml") {
        ftype = "text/html";
      } else if (selectedObj.otype === "quiz") {
        ftype = "application/json";

        refObj.oduration = file.qitems.length * 60;
      } else if (selectedFile !== undefined) {
        ftype = selectedFile.type;
      } else {
        ftype = "application/json";
      }

      axios
        .put(preSignedData, file, {
          headers: {
            "Content-Type": ftype,
            "Cache-Control": "no-cache no-store must-revalidate",
          },
        })
        .then((response) => {
          let nuggts = [...nuggets];

          nuggts = produce(nuggts, (v) => {
            v[cNugget.idx].nchanges = true;
            if (selectedObj.otype !== "quiz") {
              v[cNugget.idx].objects.push(refobject.current);
            } else {
              v[cNugget.idx].objects.push(refObj);
            }
          });

          setNuggets(nuggts);
          setTypeButtons(true);
          setObjOpen(false);
          setQuizQuestion([]);
          setisBookmarkedLoaded(false);

          handleClose();
        })
        .catch((error) => {
          setisBookmarkedLoaded(false);
          console.error("There was an error!", error);
        });
    } catch (error) {
      console.error(error);
    }
  }
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={objOpen}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          style={{ width: "800px", backgroundColor: "#fff" }}
        >
          <div className={Cviewstyle.modalpopup + " modal-content"}>
            <div
              className="modal-header"
              style={{
                backgroundColor: "#393939",
                color: "#fff",
                display: "flex",
                alignItems: "center",
                height: "50px",
                padding: "15px",
                justifyContent: "space-between",
              }}
            >
              <h3 className="modal-title">
                Add Objects{" "}
                {selectedObj.sobject !== "" && (
                  <span> - {selectedObj.sobject}</span>
                )}
              </h3>
              <button
                onClick={handleClose}
                style={{
                  border: "none",
                  background: "transparent",
                  cursor: "pointer",
                  color: "#fff",
                }}
              >
                <CloseIcon fontSize="large" />
              </button>
            </div>
            <div className="modal-body" style={{ backgroundColor: "#fff" }}>
              <div className={classes.ratingroot}>
                {selectType ? (
                  <div style={{ margin: "0px 50px 0px 50px" }}>
                    <p
                      style={{
                        fontSizesize: "14px",
                        margin: "10px 0",
                        color: "#484848",
                        fontfamily: "MyWebFont1",
                      }}
                    >
                      Please select the type of object
                    </p>
                    <div className={Cviewstyle.objbtnsmain}>
                      <div className={Cviewstyle.objbtns}>
                        {selectedObj.sobject === undefined && (
                          <>
                            {otypes.map((type, oind) => (
                              <Button
                                variant="outlined"
                                className={Cviewstyle.objecttype}
                                onClick={() => sobjecttype(type.id)}
                              >
                                <span style={{ marginRight: "5px" }}>
                                  {renderSwitch(type.img)}
                                </span>
                                {type.type}
                              </Button>
                            ))}
                          </>
                        )}
                        {selectedObj.sobject !== undefined && (
                          <>
                            <Button
                              variant="contained"
                              color="primary"
                              style={{ marginRight: "10px" }}
                              onClick={() => {
                                selectedObj.stype = "chtml";
                                setSelectedObj(
                                  (prevselectedObj) => selectedObj
                                );
                                setSelectType(false);
                              }}
                            >
                              Create HTML
                            </Button>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                selectedObj.stype = "uhtml";
                                setSelectedObj(
                                  (prevselectedObj) => selectedObj
                                );
                                setSelectType(false);
                              }}
                            >
                              Upload HTML
                            </Button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    {selectedObj.seltype && selectedObj.sobject !== "Quiz" && (
                      <div>
                        <div
                          style={{ margin: "20px 60px", overflow: "hidden" }}
                        >
                          {selectedObj.error1 && (
                            <p className={Cviewstyle.verror1}>
                              {selectedObj.sobject}
                              title cannot be empty
                            </p>
                          )}

                          <form autoComplete="off">
                            <TextField
                              label="Title"
                              id="outlined-margin-normal"
                              className={classes.textField}
                              error={selectedObj.otherdata.tval}
                              helperText={
                                selectedObj.otherdata.tval
                                  ? "Please Enter Title"
                                  : ""
                              }
                              margin="normal"
                              variant="outlined"
                              onChange={(e) => {
                                selectedObj.otherdata.title = e.target.value;
                                setSelectedObj(selectedObj);
                              }}
                            />
                            <TextField
                              label="Description"
                              id="outlined-margin-normal"
                              className={classes.textField}
                              error={selectedObj.otherdata.dsval}
                              helperText={
                                selectedObj.otherdata.dsval
                                  ? "Please Enter Description"
                                  : ""
                              }
                              margin="normal"
                              variant="outlined"
                              onChange={(e) => {
                                selectedObj.otherdata.desc = e.target.value;
                                setSelectedObj(selectedObj);
                              }}
                            />
                            <RegexTextField
                              regex={onlyAlphanumericRegex}
                              label="Duration"
                              error={selectedObj.otherdata.mval}
                              helperText={
                                selectedObj.otherdata.mval
                                  ? "Please Enter Min"
                                  : ""
                              }
                              min="1"
                              required
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    Min
                                  </InputAdornment>
                                ),
                              }}
                              onChange={(e) => {
                                selectedObj.otherdata.min = e.target.value;
                                setSelectedObj(selectedObj);
                              }}
                            />
                            <RegexTextField
                              regex={onlyAlphanumericRegex}
                              label=""
                              error={selectedObj.otherdata.sval}
                              helperText={
                                selectedObj.otherdata.sval
                                  ? "Please Enter Sec"
                                  : ""
                              }
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    Sec
                                  </InputAdornment>
                                ),
                              }}
                              onChange={(e) => {
                                selectedObj.otherdata.sec = e.target.value;
                                setSelectedObj(selectedObj);
                              }}
                            />
                            <p style={{ color: "red", fontSize: "12px",marginLeft:'7px' }}>
                              {err}
                            </p>
                            {(selectedObj.sobject === "youtube" ||
                              selectedObj.sobject === "vimeo") && (
                              <TextField
                                label="Embed ID"
                                id="outlined-margin-normal"
                                className={classes.textField}
                                error={selectedObj.otherdata.eval}
                                helperText={
                                  selectedObj.otherdata.eval
                                    ? "Please Enter Embed ID"
                                    : ""
                                }
                                margin="normal"
                                variant="outlined"
                                onChange={(e) => {
                                  selectedObj.otherdata.embeddedcode =
                                    e.target.value;
                                  setSelectedObj(selectedObj);
                                }}
                              />
                            )}
                          </form>
                        </div>

                        {selectedObj.stype === "chtml" && (
                          <>
                            {selectedObj.ferror && (
                              <p
                                style={{
                                  color: "red",
                                  margin: "0 60px",
                                  textAlign: "left",
                                }}
                              >
                                {" "}
                                Please enter some data.{" "}
                              </p>
                            )}
                            <div
                              style={{
                                height: "260px",
                                border: "1px solid #ddd",
                                margin: "0px 60px",
                                padding: "6px 28px",
                                overflow: "hidden auto",
                              }}
                            >
                              <EditorJs
                                tools={EDITOR_JS_TOOLS}
                                instanceRef={(instance) =>
                                  (instanceRef.current = instance)
                                }
                              />
                            </div>
                          </>
                        )}

                        {(selectedObj.stype === undefined ||
                          (selectedObj.stype !== "chtml" &&
                            selectedObj.stype !== "youtube" &&
                            selectedObj.stype !== "vimeo")) && (
                          <>
                            <p className={Cviewstyle.pvalid}>
                              Please select a{" "}
                              {selectedObj.sobject === "Narrative"
                                ? "HTML"
                                : selectedObj.sobject}{" "}
                              to upload
                            </p>
                            <div className={Cviewstyle.insidediv}>
                              <div className={Cviewstyle.centeritem}>
                                <input
                                  type="file"
                                  id="files"
                                  style={{
                                    visibility: "hidden",
                                    position: "absolute",
                                  }}
                                  onChange={changeHandler}
                                  accept={selectedObj.afile}
                                />
                                <label for="files">
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    component="span"
                                  >
                                    Browse
                                  </Button>
                                </label>
                              </div>

                              {selectedObj.otherdata.files !== "" && (
                                <p className={Cviewstyle.pfile}>
                                  {selectedObj.otherdata.files}{" "}
                                </p>
                              )}
                              {selectedObj.otherdata.files === "" && (
                                <p className={Cviewstyle.pfile}>
                                  {selectedObj.sobject}
                                  file not selected{" "}
                                </p>
                              )}
                              {selectedObj.ferror && (
                                <p
                                  className={Cviewstyle.pfile}
                                  style={{ color: "red" }}
                                >
                                  {" "}
                                  Please select file{" "}
                                </p>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    )}
                    {selectedObj.sobject === "Quiz" && (
                      <CreateQuiz
                        selectedObj={selectedObj}
                        setSelectedObj={setSelectedObj}
                        finalSave={finalSave}
                        folder={folder}
                        setTypeButtons={setTypeButtons}
                        typesButtons={typesButtons}
                        quizQuestion={quizQuestion}
                        setQuizQuestion={setQuizQuestion}
                      />
                    )}
                  </>
                )}
              </div>
              {isBookmarkedLoaded && (
                <Backdrop className={classes.backdrop} open={true}>
                  <CircularProgress color="inherit" />
                </Backdrop>
              )}
            </div>
            <div className={Cviewstyle.modalfooter + " " + "modal-footer"}>
              {!selectType && selectedObj.sobject !== "Quiz" && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => checkData()}
                >
                  Save
                </Button>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
