import React, { useEffect, useState } from "react";

import { ReactComponent as Close } from "../../../assets/icons/close_black_24dp.svg";

import User from "./AddUsersTenant.module.scss";
import { Constants } from "../../../config/Constants";
import config from "../../../config/aws-exports";
import { API } from "aws-amplify";
import swal from "sweetalert";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSelector } from "react-redux";
import { authData } from "../../../redux/auth/authSlice";
import useCountries from "../../Hooks/useCountries";
import useStates from "../../Hooks/useStates";
import useCities from "../../Hooks/useCities";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
  },
}));

export const BUSINESS_UNIT_INJECTABLE = [
  "API",
  "Oral solids",
  "capsules",
  "biologics",
  "corporate",
  "Pharmaceuticals",
  "Nutraceuticals",
  "Biotech",
  "CDMO",
  "CRO",
  "Others",
  "Country",
  "State",
  "Adress",
];

const AddUsersTenant = (props) => {
  let { handleAddClose, getUsersDetails, rows, tenantName, getUsers } = props;
  const [userNameErr, setUserNameErr] = useState("");
  const [lastNameErr, setLastNameErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [pnoErr, setPnoErr] = useState("");
  const [genderErr, setGenderErr] = useState("");
  const [spin, setSpin] = useState(false);
  const classes = useStyles();
  const [userName, setUserName] = useState("");
  const [lastName, setLastName] = useState("");
  const [designation, setDesignation] = useState("");
  const [department, setDepartment] = useState("");
  const [organization, setOrganization] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [email, setEmail] = useState("");
  const [unit, setUnit] = useState("");
  const [pno, setPno] = useState("");
  const [gender, setGender] = useState("0");
  const [address, setAddress] = useState("");
  const [urole, setUrole] = useState("User");

  let userDetails = useSelector(authData);
  const { data: countries, isLoading: cLoading } = useCountries();
  const { data: states, isLoading: sLoading } = useStates({
    country_code: country,
  });
  const { data: cities, isLoading } = useCities({
    country_code: country,
    state_code: state,
  });

  const onChangeHandlerUser = (event) => {
    setUserName(event.target.value);
  };

  const onChangeHandlerLastName = (event) => {
    setLastName(event.target.value);
  };

  const onChangeHandlerEmail = (event) => {
    setEmail(event.target.value);
  };
  const onChangeHandlerPno = (event) => {
    setPno(event.target.value);
  };
  const onChangeHandlerGender = (event) => {
    setGender(event.target.value);
  };
  const onChangeHandlerAddress = (event) => {
    setAddress(event.target.value);
  };
  const onChangeHandlerUrole = (event) => {
    setUrole(event.target.value);
  };

  useEffect(() => {
    console.log(country);
  }, [country]);

  const onsubmit = () => {
    console.log("data" + JSON.stringify(rows));
    let formIsValid = true;
    setUserNameErr("");
    setLastNameErr("");
    setEmailErr("");
    setPnoErr("");
    setGenderErr("");

    if (
      userName === "" ||
      userName === null ||
      (userName != undefined && userName.trim() === "")
    ) {
      formIsValid = false;
      setUserNameErr("Please enter first name");
      return;
    }
    if (
      lastName === "" ||
      lastName === null ||
      (lastName != undefined && lastName.trim() === "")
    ) {
      formIsValid = false;
      setLastNameErr("Please enter Last name");
      return;
    }
    if (
      email === "" ||
      email === null ||
      (email != undefined && email.trim() === "")
    ) {
      formIsValid = false;
      setEmailErr("*Please enter your email-ID.");
      return;
    }
    if (
      pno !== null &&
      pno !== undefined &&
      pno.trim() !== "" &&
      pno.length < 10
    ) {
      formIsValid = false;
      setPnoErr(
        "Please enter a valid phone number (should be at least 10 digits)"
      );
      return;
    }
    if (gender === null) {
      formIsValid = false;
      setGenderErr("Please select gender");
      return;
    }
    if (userName !== "") {
      if (!userName.match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        setUserNameErr("*Please enter alphabet characters only.");
        return;
      }
    }
    if (email !== "") {
      //regular expression for email validation
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(email)) {
        formIsValid = false;
        setEmailErr("*Please enter valid email-ID.");
        return;
      }
    }
    if (rows != undefined && rows.length > 0) {
      for (let i = 0; i < rows.length; i++) {
        if (email == rows[i].col2) {
          setEmailErr("User Already Exists as " + rows[i].col3);
          return;
        }
      }
    }

    if (formIsValid == true) {
      Updateuser();
    }

    async function Updateuser() {
      setSpin(true);
      const bodyParam = {
        body: {
          oid: config.aws_org_id,
          ur_id: 0,
          tenant: userDetails.tenantdata.tenantname,
          emailid: email.toLowerCase(),
          department: department,
          designation: designation,
          organization: organization,
          businessUnit: unit,
          country: country,
          state: state,
          city: city,
          first_name: userName,
          last_name: lastName,
          dev: 2,
          urole: urole,
          st_id: userDetails.tenantdata.st_id,
          gen: parseInt(gender),
          uid: "",
          address: address,
          cno: pno === "" ? null : pno,
          atype: 0,
          schema: Constants.SCHEMA_NAME,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      console.log("bd" + JSON.stringify(bodyParam.body));
      try {
        const response = await API.post(
          config.aws_cloud_logic_custom_name,
          Constants.ADD_USER,
          bodyParam
          //`${Constants.GET_MY_PROGRAMS}`, bodyParam,
        );
        // const users = response;
        console.log(JSON.stringify(response));
        handleAddClose();
        getUsers();
        if (
          response.errorMessage !== undefined &&
          response.errorMessage === "User already exists"
        ) {
          swal({
            title: "Oops!",
            text: "User already exists",
            icon: "warning",
            dangerMode: false,
            closeOnClickOutside: false,
          }).then((willDelete) => {
            if (willDelete) {
              setSpin(false);
            }
          });
        } else {
          swal({
            title: "Success",
            text: response.message,
            icon: "success",
            dangerMode: false,
            closeOnClickOutside: false,
          }).then((willDelete) => {
            if (willDelete) {
              setSpin(false);
            }
          });
        }
      } catch (error) {
        setSpin(false);
        swal({
          title: "Oooops!",
          text: "Something went wrong!",
          icon: "warning",
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            setSpin(false);
          }
        });
        console.log("getUserError", error);
      }
      console.log("Mount");
    }
  };

  return (
    <div className={User.maindiv}>
      {spin === false ? (
        <div className={User.maincontainer}>
          <div className={User.iconcontainer}>
            <h3 className={User.create}>Add user</h3>
            <Close className={User.closebutton} onClick={handleAddClose} />
          </div>

          <div className={User.form}>
            <div className={User.field}>
              <label
                style={{
                  fontSize: "14px",
                  fontWeight: "400px",
                }}
                for="name"
                required
              >
                First Name
                <span style={{ color: "red", marginTop: "-10px" }}>*</span>
              </label>
              <input
                style={{
                  border: "1px solid lightgrey",
                  borderRadius: "5px",
                  width: "80%",
                  background: "white",
                  height: "30px",
                  marginBottom: "20px",
                  fontSize: "15px",
                  paddingLeft: "10px",
                  fontWeight: "200px",
                }}
                type="text"
                id="name"
                onChange={onChangeHandlerUser}
                value={userName}
                required
              />
            </div>
            <div
              style={{
                color: "#cc0000",
                marginBottom: "12px",
                marginTop: "-10px",
                textAlign: "start",
                marginLeft: "20%",
              }}
            >
              {userNameErr}
            </div>
            <div className={User.field}>
              <label
                style={{
                  fontSize: "14px",
                  fontWeight: "400px",
                }}
                for="name"
                required
              >
                Last Name
                <span style={{ color: "red", marginTop: "-10px" }}>*</span>
              </label>
              <input
                style={{
                  border: "1px solid lightgrey",
                  borderRadius: "5px",
                  width: "80%",
                  background: "white",
                  height: "30px",
                  marginBottom: "20px",
                  fontSize: "15px",
                  paddingLeft: "10px",
                  fontWeight: "200px",
                }}
                type="text"
                id="name"
                onChange={onChangeHandlerLastName}
                value={lastName}
                required
              />
            </div>
            <div
              style={{
                color: "#cc0000",
                marginBottom: "12px",
                marginTop: "-10px",
                textAlign: "start",
                marginLeft: "20%",
              }}
            >
              {lastNameErr}
            </div>

            <div className={User.field}>
              <label
                style={{
                  fontSize: "14px",
                  fontWeight: "400px",
                }}
                for="email"
                required
              >
                Email
                <span style={{ color: "red", marginTop: "-10px" }}>*</span>
              </label>
              <input
                style={{
                  border: "1px solid lightgrey",
                  borderRadius: "5px",
                  width: "80%",
                  background: "white",
                  height: "30px",
                  marginBottom: "20px",
                  fontSize: "15px",
                  paddingLeft: "10px",
                  fontWeight: "200px",
                }}
                type="email"
                id="email"
                onChange={onChangeHandlerEmail}
                value={email}
                required
              />
            </div>
            <div
              style={{
                color: "#cc0000",
                marginBottom: "12px",
                marginTop: "-10px",
                textAlign: "start",
                marginLeft: "20%",
              }}
            >
              {emailErr}
            </div>

            <div className={User.field}>
              <label
                style={{
                  fontSize: "14px",
                  fontWeight: "500px",
                }}
                for="Designation"
              >
                Designation
              </label>
              <input
                style={{
                  border: "1px solid lightgrey",
                  borderRadius: "5px",
                  width: "80%",
                  background: "white",
                  height: "30px",
                  marginBottom: "20px",
                  fontSize: "15px",
                  paddingLeft: "10px",
                  fontWeight: "200px",
                }}
                id="Designation"
                onChange={(e) => {
                  setDesignation(e.target.value);
                }}
                value={designation}
              />
            </div>
            <div className={User.field}>
              <label
                style={{
                  fontSize: "14px",
                  fontWeight: "500px",
                }}
                for="Department"
              >
                Department
              </label>
              <input
                style={{
                  border: "1px solid lightgrey",
                  borderRadius: "5px",
                  width: "80%",
                  background: "white",
                  height: "30px",
                  marginBottom: "20px",
                  fontSize: "15px",
                  paddingLeft: "10px",
                  fontWeight: "200px",
                }}
                id="Department"
                onChange={(e) => {
                  setDepartment(e.target.value);
                }}
                value={department}
              />
            </div>
            <div className={User.field}>
              <label
                style={{
                  fontSize: "14px",
                  fontWeight: "500px",
                }}
                for="Organization"
              >
                Organization
              </label>
              <input
                style={{
                  border: "1px solid lightgrey",
                  borderRadius: "5px",
                  width: "80%",
                  background: "white",
                  height: "30px",
                  marginBottom: "20px",
                  fontSize: "15px",
                  paddingLeft: "10px",
                  fontWeight: "200px",
                }}
                id="Organization"
                onChange={(e) => {
                  setOrganization(e.target.value);
                }}
                value={organization}
              />
            </div>
            <div className={User.field}>
              <label
                style={{
                  fontSize: "14px",
                  fontWeight: "500px",
                }}
                for="gender"
              >
                Business unit-Injectable
              </label>
              <select
                style={{
                  border: "1px solid lightgrey",
                  borderRadius: "5px",
                  width: "80%",
                  background: "white",
                  height: "30px",
                  marginBottom: "20px",
                  fontSize: "15px",
                  paddingLeft: "10px",
                  fontWeight: "200px",
                }}
                name="unit"
                id="unit"
                onChange={(e) => {
                  const { value } = e.target;
                  setUnit(value);
                }}
                value={unit}
              >
                <option value="">----Select a business unit----</option>
                {BUSINESS_UNIT_INJECTABLE?.map((unit, index) => (
                  <option key={index} value={unit}>
                    {unit}
                  </option>
                ))}
              </select>
            </div>

            <div className={User.field}>
              <label
                style={{
                  fontSize: "14px",
                  fontWeight: "500px",
                }}
                for="contact"
              >
                Ph. No
              </label>
              <input
                style={{
                  border: "1px solid lightgrey",
                  borderRadius: "5px",
                  width: "80%",
                  background: "white",
                  height: "30px",
                  marginBottom: "20px",
                  fontSize: "15px",
                  paddingLeft: "10px",
                  fontWeight: "200px",
                }}
                type="number"
                id="contact"
                onChange={onChangeHandlerPno}
                value={pno}
              />
            </div>
            <div
              style={{
                color: "#cc0000",
                marginBottom: "12px",
                marginTop: "-10px",
                textAlign: "start",
                marginLeft: "20%",
              }}
            >
              {pnoErr}
            </div>

            <div className={User.field}>
              <label
                style={{
                  fontSize: "14px",
                  fontWeight: "500px",
                }}
                for="gender"
              >
                Country
              </label>
              <select
                style={{
                  border: "1px solid lightgrey",
                  borderRadius: "5px",
                  width: "80%",
                  background: "white",
                  height: "30px",
                  marginBottom: "20px",
                  fontSize: "15px",
                  paddingLeft: "10px",
                  fontWeight: "200px",
                }}
                name="country"
                id="country"
                onChange={(e) => {
                  const { value } = e.target;
                  setCountry(value);
                }}
                value={country}
              >
                {cLoading && <option>Loading...</option>}
                <option value="">----Select a country----</option>
                {!cLoading &&
                  countries?.map((country) => (
                    <option key={country.id} value={country.iso2}>
                      {country.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className={User.field}>
              <label
                style={{
                  fontSize: "14px",
                  fontWeight: "500px",
                }}
                for="gender"
              >
                State
              </label>
              <select
                style={{
                  border: "1px solid lightgrey",
                  borderRadius: "5px",
                  width: "80%",
                  background: "white",
                  height: "30px",
                  marginBottom: "20px",
                  fontSize: "15px",
                  paddingLeft: "10px",
                  fontWeight: "200px",
                }}
                name="state"
                id="state"
                onChange={(e) => setState(e.target.value)}
                value={state}
              >
                {sLoading && <option>Loading...</option>}
                <option value="">----Select a state----</option>
                {!sLoading &&
                  states?.map((state) => (
                    <option key={state.id} value={state.state_code}>
                      {state.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className={User.field}>
              <label
                style={{
                  fontSize: "14px",
                  fontWeight: "500px",
                }}
                for="gender"
              >
                City
              </label>
              <select
                style={{
                  border: "1px solid lightgrey",
                  borderRadius: "5px",
                  width: "80%",
                  background: "white",
                  height: "30px",
                  marginBottom: "20px",
                  fontSize: "15px",
                  paddingLeft: "10px",
                  fontWeight: "200px",
                }}
                name="city"
                id="city"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              >
                {isLoading && <option>Loading...</option>}
                <option value="">----Select a city----</option>
                {!isLoading &&
                  cities?.map((city) => (
                    <option key={city.id} value={city.city_code}>
                      {city.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className={User.field}>
              <label
                style={{
                  fontSize: "14px",
                  fontWeight: "500px",
                }}
                for="gender"
              >
                Gender
              </label>
              <select
                style={{
                  border: "1px solid lightgrey",
                  borderRadius: "5px",
                  width: "80%",
                  background: "white",
                  height: "30px",
                  marginBottom: "20px",
                  fontSize: "15px",
                  paddingLeft: "10px",
                  fontWeight: "200px",
                }}
                name="gender"
                id="gender"
                onChange={onChangeHandlerGender}
                value={gender}
              >
                <option value="0">Select</option>
                <option value="1">Male</option>
                <option value="2">Female</option>
              </select>
            </div>
            <div
              style={{
                color: "#cc0000",
                marginBottom: "12px",
                marginTop: "-10px",
                textAlign: "start",
                marginLeft: "20%",
              }}
            >
              {genderErr}
            </div>

            <div className={User.field}>
              <label
                style={{
                  fontSize: "14px",
                  fontWeight: "500px",
                }}
                for="address"
              >
                Address
              </label>
              <textarea
                style={{
                  border: "1px solid lightgrey",
                  borderRadius: "5px",
                  width: "80%",
                  background: "white",
                  height: "30px",
                  marginBottom: "20px",
                  fontSize: "15px",
                  paddingLeft: "10px",
                  fontWeight: "200px",
                }}
                id="address"
                rows="4"
                onChange={onChangeHandlerAddress}
                value={address}
              />
            </div>
            <div className={User.field}>
              <label
                style={{
                  fontSize: "14px",
                  fontWeight: "500px",
                }}
                for="role"
              >
                User role
              </label>
              <select
                style={{
                  border: "1px solid lightgrey",
                  borderRadius: "5px",
                  width: "80%",
                  background: "white",
                  height: "30px",
                  marginBottom: "20px",
                  fontSize: "15px",
                  paddingLeft: "10px",
                  fontWeight: "200px",
                }}
                name="role"
                id="role"
                required
                onChange={onChangeHandlerUrole}
                value={urole}
              >
                <option value="User">Participant</option>
                <option value="Manager">Manager</option>
              </select>
            </div>
            <div className={User.buttonholder}>
              <button className={User.cancel} onClick={handleAddClose}>
                Cancel
              </button>
              <button type="submit" onClick={onsubmit} className={User.submit}>
                Submit
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.root}>
          <CircularProgress style={{ color: config.main_color_1 }} />
        </div>
      )}
    </div>
  );
};

export default AddUsersTenant;
