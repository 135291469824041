import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { makeStyles } from "@material-ui/core/styles";
import Analytics from "./Analytics";
import Users from "./Users";
import viewTenantStyle from "./viewTenant.module.scss";
import ContentTenant from "./ContentTenant";
import EngageTenant from "./EngageTenant";
import EventsTenant from "./EventsTenant";
import Paper from "@mui/material/Paper";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../../redux/auth/authSlice";
import { Constants } from "../../../config/Constants";
import config from "../../../config/aws-exports";
import { API } from "aws-amplify";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Breadcrumb from '../../Breadcrumbs/Breadcrumbs';

// Internal Styles for the material UI components
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  ".css-13xfq8m-MuiTabPanel-root": {
    padding: 0,
  },
}));

export default function ViewTenant() {
  const [value, setValue] = useState("1");
  const [loading, setLoading] = useState(true);
  const [userCount, setUserCount] = useState({});
  const [tenantName, setTenantName] = useState();
  const [eventsRec, setEvents] = useState([]);
  const [topicsList, setTopicsList] = useState({});

  const classes = useStyles();
  const dispatch = useDispatch();

  let userDetails = useSelector(authData);
  console.log("data1234", userDetails);

  useEffect(() => {
    getUserCount();
    breadcrumb();
    setTenantName(userDetails.BOID);
    return () => {};
  }, []);
  
  async function getUserCount() {
    const bodyParam = {
      body: {
        oid: userDetails.BOID,
        schema: Constants.SCHEMA_NAME,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log(bodyParam.body);
      const userCount = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_USERS,
        bodyParam
      );
      console.log("userCount", JSON.stringify(userCount));
      setUserCount(userCount);
      setLoading(false);
      // getEvents();
    } catch (error) {
      console.error(error);
    }
  }

  
  async function listTopics() {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        admin: "true",
        tenant: userDetails.tenant,
        ttopic: "1",
        schema: Constants.SCHEMA_NAME,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log(bodyParam.body);
      const topics = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.LIST_TOPICS,
        bodyParam
      );
      console.log("topics", topics);
      setTopicsList(topics);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function breadcrumb() {
    let sdata = { ...userDetails };
    if (sdata.breadcrumb == undefined) {
        sdata.breadcrumb = [];
        let temp = [...sdata.breadcrumb];
        temp[0] = {
          name: "Organizations",
          path: "/organization",
        };
        temp[1] = {
            name: userDetails.tenantdata.tenant,
            path: "/organization/viewTenant",
        };
        sdata.breadcrumb = temp;
    } else {
        let temp = [...sdata.breadcrumb];
        temp[0] = {
          name: "Organizations",
          path: "/organization",
        };
        temp[1] = {
          name: userDetails.tenantdata.tenant,
          path: "/organization/viewTenant",
        };
        sdata.breadcrumb = temp;
    }


    dispatch(awsSignIn(sdata));
}

  return (
    <div className={viewTenantStyle.main}>
      <Breadcrumb Bindex={1}/>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <h3 className={viewTenantStyle.heading}>Tenant View</h3> */}
      <Paper>
        <div clssName={viewTenantStyle.tabholder}>
          <TabContext value={value}>
            <Box
              sx={{
                borderBottom: 1,
                backgroundColor: "transparent",
                borderColor: "divider",
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                indicatorColor="primary"
                textColor="inherit"
                variant="fullWidth"
              >
                <Tab label="Analytics" value="1" />
                <Tab label="Users" value="2" />
                <Tab label="Content" value="3" />
                <Tab label="Engage" value="4" />
                <Tab label="Events" value="5" />
              </Tabs>
            </Box>
            <TabPanel
              value="1"
              className={classes[".css-13xfq8m-MuiTabPanel-root"]}
            >
              <Analytics userCount={userCount} />
            </TabPanel>

            <TabPanel
              value="2"
              className={classes[".css-13xfq8m-MuiTabPanel-root"]}
            >
              <Users userCount={userCount} tenantName={tenantName} />
            </TabPanel>
            <TabPanel
              value="3"
              className={classes[".css-13xfq8m-MuiTabPanel-root"]}
            >
              <ContentTenant
                topicList={topicsList}
                className={classes[".css-13xfq8m-MuiTabPanel-root"]}
              />
            </TabPanel>
            <TabPanel
              value="4"
              className={classes[".css-13xfq8m-MuiTabPanel-root"]}
            >
              <EngageTenant />
            </TabPanel>
            <TabPanel
              value="5"
              className={classes[".css-13xfq8m-MuiTabPanel-root"]}
            >
              <EventsTenant  />
            </TabPanel>
          </TabContext>
        </div>
      </Paper>
    </div>
  );
}
