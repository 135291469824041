// Dependencies imports
import React, { useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSelector, useDispatch } from "react-redux";
import swal from "@sweetalert/with-react";

import { API } from "aws-amplify";
import { awsSignIn, authData } from "../../../redux/auth/authSlice";
// Local import
import config from "../../../config/aws-exports";
import { Constants } from "../../../config/Constants";
import { ReactComponent as Close } from "../../../assets/icons/close_black_24dp.svg";

// Style imports
import Send from "./SendNotification.module.scss";

const SendNotification = (props) => {
  let {
    handleClose,
    tenantsnotify,
    userList,
    userListNotify,
    singleUser,
    suserDetail,
    useremail,
    singleUserEmail,
    tenantname,
  } = props;
  console.log("tenS", singleUser);
  const [action, setAction] = useState("");
  const [subject, setSubject] = useState("");
  const [bodyMessage, setBodyMessage] = useState("");
  const [url, setUrl] = useState("");
  const [link, setLink] = useState(false);
  const [error1, setError1] = useState(false);
  const [error2, setError2] = useState(false);
  const [error3, setError3] = useState(false);
  const [error4, setError4] = useState(false);
  const [spin, setSpin] = useState(false);
  let userDetails = useSelector(authData);
  console.log("det", userDetails);

  async function sendMessage() {
    let regex =
      "^(https?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$";
    let result = new RegExp(regex, "i");
    if (action === "" || action === undefined || action === null) {
      setError1(true);
      setError2(false);
      setError3(false);
      setError4(false);
    } else if (subject === "" || subject.replace(/\s/g, "").length === 0) {
      setError1(false);
      setError2(true);
      setError3(false);
      setError4(false);
    } else if (bodyMessage === "" || subject.replace(/\s/g, "").length === 0) {
      setError1(false);
      setError2(false);
      setError3(true);
      setError4(false);
    } else if (link === true && (url === "" || !result.test(url))) {
      setError1(false);
      setError2(false);
      setError3(false);
      setError4(true);
    } else {
      setError3(false);
      setError4(false);
      setSpin(true);

      if (userList === false) {
        var body1 = {
          atype: 0,
          ntid: 0,
          action: action,
          nttitle: subject,
          ntmsg: bodyMessage,
          //tenant: userDetails.BOID,
          tenants: tenantsnotify,
          tenantName: tenantname,
          ur_id: [],
          oid: config.aws_org_id,
          schema: Constants.SCHEMA_NAME,
        };
        if (link == true) {
          //body1.link = true;
          body1.pr_link = url;
        } else {
          body1.pr_link = null;
        }
        console.log("bb", body1);
        const bodyParam = {
          body: body1,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        };
        try {
          console.log(JSON.stringify(bodyParam.body));
          const users = await API.post(
            config.aws_cloud_logic_custom_name,
            Constants.GET_GROUP_NOTIFICATION,
            bodyParam
          );
          console.log("users", users);
          //setSelectionModel([]);
          swal("Notification sent!");
          handleClose();
          // handleEmailClose();
          setBodyMessage("");
          setLink("");
          setAction("");
          setSubject("");
          setSpin(false);
        } catch (error) {
          console.error(error);
          swal({
            type: "error",
            text: "Notification not sent to users. Please Contact Production Support Team.",
          });
        }
      } else if (singleUser === true) {
        let arrayStu = [];
        let emailid = [];
        emailid.push(singleUserEmail);
        arrayStu.push(suserDetail);
        var body123 = {
          atype: 0,
          ntid: 0,
          action: action,
          nttitle: subject,
          ntmsg: bodyMessage,
          //tenant: userDetails.BOID,
          email: emailid,
          ur_id: arrayStu,
          tenants: [],
          oid: config.aws_org_id,
          schema: Constants.SCHEMA_NAME,
        };
        if (link == true) {
          //body123.link = true;
          body123.pr_link = url;
        } else {
          body123.pr_link = null;
        }
        console.log("bb12", body123);
        const bodyParam = {
          body: body123,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        };
        try {
          console.log(JSON.stringify(bodyParam.body));
          const users = await API.post(
            config.aws_cloud_logic_custom_name,
            Constants.GET_GROUP_NOTIFICATION,
            bodyParam
          );
          console.log("users", users);
          //setSelectionModel([]);
          swal("Notification sent!");
          handleClose();
          // handleEmailClose();
          setBodyMessage("");
          setLink("");
          setAction("");
          setSubject("");
          setSpin(false);
        } catch (error) {
          console.error(error);
          swal({
            type: "error",
            text: "Notification not sent to users. Please Contact Production Support Team.",
          });
        }
      } else {
        var body12 = {
          atype: 0,
          ntid: 0,
          action: action,
          nttitle: subject,
          ntmsg: bodyMessage,
          // tenant: userDetails.BOID,
          ur_id: userListNotify,
          email: useremail,
          tenants: [],
          oid: config.aws_org_id,
          schema: Constants.SCHEMA_NAME,
        };
        if (link == true) {
          // body12.link = true;
          body12.pr_link = url;
        } else {
          body12.pr_link = null;
        }

        console.log("bb12", body12);
        const bodyParam = {
          body: body12,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        };
        try {
          console.log(bodyParam.body);
          const users = await API.post(
            config.aws_cloud_logic_custom_name,
            Constants.GET_GROUP_NOTIFICATION,
            bodyParam
          );
          console.log("users", users);
          //setSelectionModel([]);
          swal("Notification sent!");
          handleClose();
          // handleEmailClose();
          setBodyMessage("");
          setLink("");
          setAction("");
          setSubject("");
          setSpin(false);
        } catch (error) {
          console.error(error);
          swal({
            type: "error",
            text: "Notification not sent to users. Please Contact Production Support Team.",
          });
        }
      }
    }
  }

  function handleChange(e) {
    setAction(e.target.value);
  }

  function clicking(e) {
    if (e.target.value == "true") {
      setLink(!link);
    }
  }

  return (
    <div>
      {spin === false ? (
        <div className={Send.popupcontainer}>
          <div className={Send.topbar}>
            <h3 className={Send.header}>Send Notification</h3>
            <Close className={Send.closeicon} onClick={handleClose} />
          </div>

          <lable className={Send.caution}>
            {error1 == true ? "Please select one" : ""}
          </lable>
          <div className={Send.radioholder}>
            <div className={Send.inholder}>
              <input
                type="radio"
                value="MAIL"
                name="type-select"
                className={Send.input}
                onChange={(e) => {
                  handleChange(e);
                }}
              ></input>
              <span for="mail" className={Send.radiolable}>
                Mail
              </span>
            </div>
            <div className={Send.inholder}>
              <input
                type="radio"
                value="NOTIFICATION"
                name="type-select"
                className={Send.input}
                onChange={(e) => {
                  handleChange(e);
                }}
              ></input>
              <span className={Send.radiolable} for="notification">
                Notification
              </span>
            </div>
            <div className={Send.inholder}>
              <input
                type="radio"
                value="BOTH"
                name="type-select"
                className={Send.input}
                onChange={(e) => {
                  handleChange(e);
                }}
              ></input>
              <span className={Send.radiolable} for="both">
                Both
              </span>
            </div>
          </div>
          <div className={Send.inputholder}>
            <lable className={Send.caution}>
              {error2 == true ? "Please enter title" : ""}
            </lable>
            <div className={Send.inputblock}>
              <div className={Send.labelholder}>
                <label for="sessiondate" className={Send.label} required>
                  Title
                  <span style={{ color: "red", marginTop: "-10px" }}>*</span>
                </label>
              </div>
              <div className={Send.inputholder}>
                <input
                  type="text"
                  className={Send.inputfield}
                  value={subject}
                  onChange={(e) => {
                    setSubject(e.target.value);
                  }}
                ></input>
              </div>
            </div>
          </div>
          <div className={Send.inputholder}>
            <lable className={Send.caution}>
              {error3 == true ? "Please enter text" : ""}
            </lable>
            <div className={Send.inputblock}>
              <div className={Send.labelholder}>
                <label for="sessiondate" className={Send.label} required>
                  Description
                  <span style={{ color: "red", marginTop: "-10px" }}>*</span>
                </label>
              </div>
              <div className={Send.inputholder}>
                <textarea
                  rows={8}
                  type="text"
                  className={Send.textarea}
                  value={bodyMessage}
                  onChange={(e) => {
                    setBodyMessage(e.target.value);
                  }}
                />
              </div>
            </div>

            <div
              style={{
                width: "65%",
                fontSize: "12px",
                fontWeight: "400",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "5px",
                marginLeft: "20%",
              }}
            >
              <input
                type="checkbox"
                value="true"
                onClick={(e) => clicking(e)}
              />
              <lable>Include link</lable>
            </div>
            {link ? (
              <div className={Send.inputholder}>
                <lable className={Send.caution}>
                  {error4 == true ? "Please include valid url" : ""}
                </lable>
                <div className={Send.inputblock}>
                  <div className={Send.labelholder}>
                    <label for="sessiondate" className={Send.label} required>
                      Url
                      <span style={{ color: "red", marginTop: "-10px" }}>
                        *
                      </span>
                    </label>
                  </div>
                  <div className={Send.inputholder}>
                    <input
                      type="text"
                      id="linkbox"
                      className={Send.inputfield}
                      value={url}
                      onChange={(e) => {
                        setUrl(e.target.value);
                      }}
                    ></input>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <div className={Send.buttonholder}>
            <button className={Send.cancel} onClick={handleClose}>
              Cancel
            </button>
            <button className={Send.submit} onClick={() => sendMessage()}>
              Submit
            </button>
          </div>
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress style={{ color: config.main_color_1 }} />
        </div>
      )}
    </div>
  );
};

export default SendNotification;
