// Dependencies import
import React, { useState, useEffect } from "react";
import {
  DataGrid,
  GridToolbar,
  GridToolbarContainer,
} from "@material-ui/data-grid";
import { API } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../../redux/auth/authSlice";
import Paper from "@mui/material/Paper";
import { Link, useHistory } from "react-router-dom";

// Local imports
import { Constants } from "../../../config/Constants";
import SelectPopup from "./SelectPopup";
import config from "../../../config/aws-exports";
import Breadcrumb from '../../Breadcrumbs/Breadcrumbs';
// Style imports
import Tusers from "./TotalUsers.module.scss";

// Custom Toolbard to export and filter for datagrid
function CustomToolbar() {
  return (
    <GridToolbarContainer
      style={{ display: "flex", justifyContent: "flex-end" }}
    >
      <GridToolbar />
    </GridToolbarContainer>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& .super-app-theme--header": {
      backgroundColor: Constants.blue_color,
      fontSize: "16px",
      color: "rgb(255,255,255)",
      fontFamily: "nunito",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
    //cursor: "pointer",
  },
}));

// start of User component
const TotalPrograms = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [tenants, setTenants] = useState({});
  const [programsPass, setProgramsPass] = useState(false);
  const [allTenants, setAllTenantsUsers] = useState({});
  const [rows, setRows] = useState([]);
  const [spin, setSpin] = useState(false);
  let userDetails = useSelector(authData);
  const dispatch = useDispatch();

  // Useeffect for api call
  useEffect(() => {
    getPrograms(userDetails.selectedTenant);
    getTenants(config.aws_org_id);
    breadcrumb();
    return () => {};
  }, []);

  // function to get Programs
  async function getPrograms(value) {
    const bodyParam = {
      body: {
        oid: value === value,
        schema: Constants.SCHEMA_NAME,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log(bodyParam.body);
      const programs = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_PROGRAMS,
        bodyParam
      );
      if (programs !== undefined && programs !== null) {
        mapPrograms(programs.programs);
        setSpin(false);
      }
    } catch (error) {
      console.error(error);
      setSpin(false);
    }
  }

  // function to get Tenants list
  async function getTenants(value) {
    setSpin(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        schema: Constants.SCHEMA_NAME
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log(bodyParam.body);
      const ten = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_TENANTS,
        bodyParam
      );
      if (ten !== undefined && ten !== null) {
        setTenants(ten.body);
        // getAllTenantsUsers(value);
      }
    } catch (error) {
      console.error(error);
    }
  }

  function mapPrograms(value) {
    let trows = [];
    for (let i = 0; i < value.length; i++) {
      let obj = {};
      obj.id = i;
      obj.programname = value[i].pname;
      obj.usersenrolled = value[i].sub_cnt;
      obj.usercompleted = value[i].cmp_cnt;
      obj.col3 = value[i].oid;
      trows.push(obj);
    }
    setRows(trows);
  }

  const tcolumns = [
    {
      field: "programname",
      headerName: "Program Name",
      flex: 1,
      headerClassName: "super-app-theme--header",
    //   renderCell: (params) => (
    //     <div>
    //       <Link
    //         to="/landingscreen/userInfo"
    //         style={{
    //           color: "black",
    //           textDecoration: "none",
    //           fontFamily: "14px",
    //           //fontWeight: "bold",
    //         }}
    //         onClick={() => {
    //           onClick(allTenants[params.row.id].firstname);
    //           settingUserInfo(params);
    //         }}
    //       >
    //         {params.value}
    //       </Link>
    //     </div>
    //),
    },
    {
      field: "usersenrolled",
      headerName: "Users Enrolled",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "usercompleted",
      headerName: "Users Completed",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
  ];

//   const settingUserInfo = (params) => {
//     console.log("params", allTenants);
//     console.log("params1", allTenants[params.row.id]);
//     // alert("hello");

//     let sdata = { ...userDetails };
//     sdata.user = allTenants[params.row.id];
//     //sdata.user.uid = userCount.userlist[param.row.id].UID;
//     dispatch(awsSignIn(sdata));
//     //history.push("/tenants/viewTenant/userInfo");
//   };

  const trows = [
    {
      id: 1,
      col1: "Sharath",
      col2: "Vasan",
      col3: "sharath@enhanzed.com",
      col4: "EnhanzED",
      col5: "2 days ago",
    },
    {
      id: 2,
      col1: "Naresh",
      col2: "P",
      col3: "naresh@enhanzed.com",
      col4: "EnhanzED",
      col5: "a month ago",
    },
  ];

  // function to open select teanant popup
  const handleOpen = () => {
    console.log("userDetails", userDetails);
    setOpen(true);
    setProgramsPass(true);
  };

  // function to close select tenant popup
  const handleTotalClose = () => {
    console.log("userDetails", userDetails);
    setOpen(false);
    setProgramsPass(false);
  };


  function breadcrumb() {
    let sdata = { ...userDetails };
    if (sdata.breadcrumb == undefined) {
        sdata.breadcrumb = [];
        let temp = [...sdata.breadcrumb];
        temp[0] = {
          name: "Dashboard",
          path: "/landingscreen",
        };
        temp[1] = {
            name: "Total Programs",
            path: "/landingscreen/totalprograms"
        };
        sdata.breadcrumb = temp;
    } else {
        let temp = [...sdata.breadcrumb];
        temp[0] = {
          name: "Dashboard",
          path: "/landingscreen",
        };
        temp[1] = {
          name: "Total Programs",
          path: "/landingscreen/totalprograms"
        };
        sdata.breadcrumb = temp;
    }


    dispatch(awsSignIn(sdata));
}

  function onClick() {
    let sdata = { ...userDetails };
    let temp = [...sdata.breadcrumb];

    temp[2] = {
      name: "User Info",
      path: "/landingscreen/userInfo",
    };

    sdata.breadcrumb = temp;

    dispatch(awsSignIn(sdata));
  }

  // Start of main return
  return (
    <div className={Tusers.main}>
      <Breadcrumb Bindex={1}/>
      <Backdrop className={classes.backdrop} open={spin}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <SelectPopup
            handleClose={handleTotalClose}
            tenants={tenants}
            getPrograms={getPrograms}
            programsPass={programsPass}
          />
        </Fade>
      </Modal>
      {/* <h3 className={Tusers.heading}>Total users</h3> */}
      {userDetails.role === "Admin" ? (
        userDetails.role === "Manager" ? null : (
          <div className={Tusers.tenantselect}>
            <h4 className={Tusers.selecttenant}>Select Organization:</h4>
            <h5
              className={Tusers.tenantname}
              onClick={() => {
                handleOpen();
              }}
            >
              {userDetails.selectedTenant}
            </h5>
          </div>
        )
      ) : null}
      <div
        style={{
          height: 600,
          width: "100%",
          overflowY: "auto",
          paddingTop: "20px",
        }}
        className={classes.root}
      >
        <Paper>
          <div
            style={{ height: 500, width: "100%", overflowY: "auto" }}
            className={classes.root}
          >
            <DataGrid
              rows={rows}
              columns={tcolumns}
              className={classes[".MuiDataGrid-root"]}
              components={{
                Toolbar: CustomToolbar,
              }}
            />
          </div>
        </Paper>
      </div>
    </div>
  );
};

export default TotalPrograms;
