// Dependencies imports
import React, { useState, useEffect } from "react";
import {
  DataGrid,
  GridToolbar,
  GridToolbarContainer,
} from "@material-ui/data-grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Rating from "@mui/material/Rating";
import { API } from "aws-amplify";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import Skeleton from "@material-ui/lab/Skeleton";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../../redux/auth/authSlice";
import { Link } from "react-router-dom";

// Local imports
import { Constants } from "../../../config/Constants";
import image from "../../../assets/images/2.png";
import SelectPopup from "./SelectPopup";
import config from "../../../config/aws-exports";
import Breadcrumb from "../../Breadcrumbs/Breadcrumbs";
import TopCoursesChart from "./TopCoursesChart";

// Style imports
import Tcourses from "./TotalCourses.module.scss";

// Custom Toolbard to export and filter for datagrid
function CustomToolbar() {
  return (
    <GridToolbarContainer
      style={{ display: "flex", justifyContent: "flex-end" }}
    >
      <GridToolbar />
    </GridToolbarContainer>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& .super-app-theme--header": {
      backgroundColor: Constants.blue_color,
      fontSize: "16px",
      color: "rgb(255,255,255)",
      fontFamily: "nunito",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
    cursor: "pointer",
  },
}));

// Start of totalCourses component
const TotalCourses = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [courses, setCourses] = useState([]);
  const [tenants, setTenants] = useState({});
  const [rows, setRows] = useState([]);
  const [spin, setSpin] = useState(true);
  const [coursePass, setCoursePass] = useState(false);
  let userDetails = useSelector(authData);
  const dispatch = useDispatch();

  // Useeffect for api call
  useEffect(() => {
    getTenants(userDetails.selectedTenant);
    breadcrumb();
    return () => {};
  }, []);

  // function to get Tenants list
  async function getTenants(value) {
    setSpin(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        schema: Constants.SCHEMA_NAME
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log(bodyParam.body);
      const ten = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_TENANTS,
        bodyParam
      );
      if (ten !== undefined && ten !== null) {
        setTenants(ten.body);
        getCourses(value);
      }
    } catch (error) {
      console.error(error);
    }
  }

  // function to get Topic list
  async function getCourses(value) {
    setSpin(true);
    const bodyParam = {
      body: {
        org: config.aws_org_id,
        oid: value === value,
        schema: Constants.SCHEMA_NAME,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log(bodyParam.body);
      const topic = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.LIST_COURSES,
        bodyParam
      );
      if (topic !== undefined && topic !== null) {
        setCourses(topic.body);
        mapCourses(topic.body);
        setSpin(false);
        console.log("topic", topic);
      }
    } catch (error) {
      console.error(error);
      setSpin(false);
    }
  }

  function mapCourses(value) {
    let allTopic = value;
    let trows = [];
    if (allTopic.length > 0) {
      for (let i = 0; i < allTopic.length; i++) {
        let obj = {};
        obj.id = i;
        obj.col1 = allTopic[i].tid;
        obj.col2 = allTopic[i].topicname;
        obj.col3 = allTopic[i].sub_cnt || "0";
        obj.col4 = allTopic[i].comp_cnt || "0";
        obj.col5 = allTopic[i].rating || 0;

        trows.push(obj);
      }
    }
    setRows(trows);
  }

  const tcolumns = [
    {
      field: "col1",
      headerName: "Course Image",
      flex: 1,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <img
          style={{
            width: "100px",
            height: "100px",
            borderRadius: "10px",
          }}
          src={`https://${
            config.DOMAIN
          }/${config.aws_org_id.toLowerCase()}-resources/images/topic-images/${
            params.value
          }.png`}
          alt=""
        ></img>
      ),
    },
    {
      field: "col2",
      headerName: "Course Name",
      flex: 1,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <div
          onClick={() => {
            sendTid(params);
          }}
        >
          <Link
            to="/landingscreen/totalcourses/standalonecourses"
            style={{
              color: "black",
              textDecoration: "none",
              fontFamily: "14px",
              //fontWeight: "bold",
            }}
          >
            {params.value}
          </Link>
        </div>
      ),
    },
    {
      field: "col3",
      headerName: "Users Started",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "col4",
      headerName: "Users Completed",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "col5",
      headerName: "Ratings",
      flex: 1,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <Rating
          name="read-only"
          value={params.value}
          readOnly
          precision={0.5}
        />
      ),
    },
  ];

  const trows = [
    { id: 1, col2: "Physics", col3: "468", col4: "234", col5: "4" },
  ];

  function sendTid(value) {
    console.log("value", value, value.row);
    let sdata = { ...userDetails };
    sdata.topicID = value.row.col1;
    sdata.topicName = value.row.col2;

    let temp = [...sdata.breadcrumb];

    temp[2] = {
      name: value.row.col2,
      path: "/landingscreen/totalcourses/standalonecourses",
    };

    sdata.breadcrumb = temp;

    dispatch(awsSignIn(sdata));
  }

  // function to open select teanant popup
  const handleOpen = () => {
    setOpen(true);
    setCoursePass(true);
  };

  // function to close select tenant popup
  const handleClose = () => {
    setOpen(false);
    setCoursePass(false);
  };

  function breadcrumb() {
    let sdata = { ...userDetails };
    if (sdata.breadcrumb == undefined) {
      sdata.breadcrumb = [];
      let temp = [...sdata.breadcrumb];
      temp[0] = {
        name: "Dashboard",
        path: "/landingscreen",
      };
      temp[1] = {
        name: "Total Courses",
        path: "/landingscreen/totalCourses",
      };
      sdata.breadcrumb = temp;
    } else {
      let temp = [...sdata.breadcrumb];
      temp[0] = {
        name: "Dashboard",
        path: "/landingscreen",
      };
      temp[1] = {
        name: "Total Courses",
        path: "/landingscreen/totalCourses",
      };
      sdata.breadcrumb = temp;
    }

    dispatch(awsSignIn(sdata));
  }

  // Start of main return
  return (
    <div className={Tcourses.main}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <SelectPopup
            handleClose={handleClose}
            tenants={tenants}
            coursePass={coursePass}
            getCourses={getCourses}
          />
        </Fade>
      </Modal>
      <Backdrop className={classes.backdrop} open={spin}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Breadcrumb Bindex={1} />
      {userDetails.role === "Admin" ? (
        userDetails.role === "Manager" ? null : (
          <div className={Tcourses.tenantselect}>
            <h4 className={Tcourses.selecttenant}>Select Tenant:</h4>
            <h5
              className={Tcourses.tenantname}
              onClick={() => {
                handleOpen();
              }}
            >
              {userDetails.selectedTenant}
            </h5>
          </div>
        )
      ) : null}
      <div className={Tcourses.statsholder}>
        <div className={Tcourses.total}>
          <h3 className={Tcourses.heading}>Total Active Courses</h3>
          <h4 className={Tcourses.count}>
            {courses !== undefined ? courses.length : 0}
          </h4>
        </div>
        <div className={Tcourses.top}>
          <h3 className={Tcourses.heading}>Top Active Courses</h3>
          {spin === false ? (
            <TopCoursesChart courses={courses} />
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Skeleton
                variant="rect"
                width="100%"
                height={100}
                style={{ marginBottom: "40px" }}
              />{" "}
            </div>
          )}
        </div>
      </div>
      <Paper style={{ marginTop: "20px" }}>
        <div
          style={{
            height: 350,
            width: "100%",
            overflowY: "auto",
          }}
          className={classes.root}
        >
          <DataGrid
            rows={rows}
            columns={tcolumns}
            className={classes[".MuiDataGrid-root"]}
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </div>
      </Paper>
    </div>
  );
};

export default TotalCourses;
