// Dependencies imports
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from '@mui/material/Tabs';
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import {
  DataGrid,
  GridToolbar,
  GridToolbarContainer,
} from '@material-ui/data-grid';
import Button from '@material-ui/core/Button';
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import Paper from "@mui/material/Paper";

// Local imports
import { Constants } from "../../../config/Constants";
import DeleteConfirmation from './DeleteConfirmation';
import AddRoles from "./AddRoles";
import EditRoles from "./EditRoles";
import AddUsers from "./AddUsers";
import EditUsers from "./EditUsers";

// Style imports
import Use from './UserRole.module.scss';



// usestyles for the page
const useStyles = makeStyles({
  root: {
    padding: 0,
    margin: 0,
    "& .super-app-theme--header": {
      backgroundColor: Constants.blue_color,
      fontSize: "16px",
      color: "rgb(255,255,255)",
      fontFamily: "nunito",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
    cursor: "pointer",
  },
});

// Start of main userole component
export default function UserRole() {
  const [value, setValue] = useState("1");
  const [open, setOpen] = useState(false);
  const [addOpen, setAddOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [addUserOpen, setAddUserOpen] = useState(false);
  const [editUserOpen, setEditUserOpen] = useState(false);
  const classes = useStyles();

  // function to handle tab change
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // function to open delete popup
  const handleDeleteOpen = () => {
    setOpen(true);
  };

  // function to close delete popup
  const handleDeleteClose = () => {
    setOpen(false);
  };

  // function to open user role add popup
  const handleAddOpen = () => {
    setAddOpen(true);
  };

  // function to close user role add popup
  const handleAddClose = () => {
    setAddOpen(false);
  };

  // function to open user role edit popup
  const handleEditOpen = () => {
    setEditOpen(true);
  };

  // function to close user role edit popup
  const handleEditClose = () => {
    setEditOpen(false);
  };
  

  // function to open user add popup
  const handleAddUserOpen = () => {
    setAddUserOpen(true);
  };

  // function to close user add popup
  const handleAddUserClose = () => {
    setAddUserOpen(false);
  };

  // function to open user edit popup
  const handleEditUserOpen = () => {
    setEditUserOpen(true);
  };

  // function to close user edit popup
  const handleEditUserClose = () => {
    setEditUserOpen(false);
  };




  // Custom Toolbard to export and filter for datagrid
  function CustomToolbar() {
    return (
      <GridToolbarContainer style={{ display: "flex", justifyContent: "flex-end" }}>
        <GridToolbar />
      </GridToolbarContainer>
    );
  }

  // Columns for Roles tab in DataGrid
  const rcolumns = [
    {
      field: "col1",
      headerName: "Role Name",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "col2",
      headerName: "Tabs Viewed",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "Edit",
      headerName: "",
      headerClassName: 'super-app-theme--header',
      flex: 1,
      width: 400,
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            size="small"
            style={{ marginLeft: 16, background: Constants.orange_color, color: "white", fontSize: "12px" }}
            onClick={() => handleEditOpen()}
          >
            Edit
          </Button>
        </strong>
      ),
    },
    {
      field: "Delete",
      headerName: "",
      headerClassName: 'super-app-theme--header',
      flex: 1,
      width: 400,
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            size="small"
            style={{ marginLeft: 16, background: "red", color: "white", fontSize: "12px" }}
            onClick={() => {handleDeleteOpen();}}
          >
            Delete
          </Button>
        </strong>
      ),
    },
  ]

  // Rows for roles tab in datagrid
  const rrows = [
    { id: 1, col1: 'Tenant 1', col2: 'Tenants' },
    { id: 2, col1: 'Tenant 2', col2: 'Tenants' },
    { id: 3, col1: 'Tenant 3', col2: 'Tenants' },
    { id: 4, col1: 'Tenant 4', col2: 'Tenants' },
    { id: 5, col1: 'Tenant 5', col2: 'Tenants' },
    { id: 6, col1: 'Tenant 6', col2: 'Tenants' },
  ];

  // Columns for Users tab in DataGrid
  const ucolumns = [
    {
      field: "col1",
      headerName: "UserName",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "col2",
      headerName: "Email ID",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "col3",
      headerName: "Role",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "Edit",
      headerName: "",
      headerClassName: 'super-app-theme--header',
      flex: 1,
      width: 400,
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            size="small"
            style={{ marginLeft: 16, background: Constants.orange_color, color: "white", fontSize: "14px", fontFamily: "nunito" }}
            onClick={() => handleEditUserOpen()}
          >
            Edit
          </Button>
        </strong>
      ),
    },
    {
      field: "Delete",
      headerName: "",
      headerClassName: 'super-app-theme--header',
      flex: 1,
      width: 400,
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            size="small"
            style={{ marginLeft: 16, background: "red", color: "white", fontSize: "14px", fontFamily: "nunito" }}
            onClick={() => {handleDeleteOpen();}}
          >
            Delete
          </Button>
        </strong>
      ),
    },
  ]

  // Rows for roles tab in datagrid
  const urows = [
    { id: 1, col1: 'Nisarga', col2: 'Nisarga@enhanzed.com', col3: 'User' },
    { id: 2, col1: 'Sharath', col2: 'sharath@enhanzed.com', col3: 'User' },
    { id: 3, col1: 'Naresh', col2: 'naresh@enhanzed.com', col3: 'User' },
    { id: 4, col1: 'Prajwal', col2: 'prajwal@enhanzed.com', col3: 'User' },
    { id: 5, col1: 'Babu', col2: 'babu@enhanzed.com', col3: 'User' },
    { id: 6, col1: 'Meghana', col2: 'meghana@enhanzed.com', col3: 'User' },
  ];


  // Start of main return
  return (
    <div className={Use.main}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={addOpen}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={addOpen}>
          <AddRoles handleAddClose={handleAddClose} />
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={editOpen}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={editOpen}>
          <EditRoles handleEditClose={handleEditClose} />
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <DeleteConfirmation handleDeleteClose={handleDeleteClose} />
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={addUserOpen}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={addUserOpen}>
          <AddUsers handleAddUserClose={handleAddUserClose} />
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={editUserOpen}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={editUserOpen}>
          <EditUsers handleEditUserClose={handleEditUserClose} />
        </Fade>
      </Modal>
      <div className={Use.topbar}>
        <h2 className={Use.heading}>User Roles</h2>
        {value === "1" ? (
        <button className={Use.add} onClick={() => handleAddOpen()}>Add Roles</button>
        ) : value === "2" ? 
        ( <button className={Use.add} onClick={() => handleAddUserOpen()}>Add Users</button>
        ) : null}
      </div>
      <Paper>
      <div className={Use.tabholder}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" indicatorColor="primary" textColor="inherit" variant="fullWidth">
              <Tab label="Roles" value="1" />
              <Tab label="Users" value="2" />
            </Tabs>
          </Box>
          <TabPanel value="1" style={{ padding: '0px', margin: '0px' }}>
            <div style={{ height: 500, width: "100%", overflowY: "auto", paddingTop: '20px', margin: '0px' }} className={classes.root}>
              <DataGrid
                rows={rrows}
                columns={rcolumns}
                components={{ Toolbar: CustomToolbar }}
                disableSelectionOnClick
                className={classes['.MuiDataGrid-root']}
              />
            </div>
          </TabPanel>
          <TabPanel value="2" style={{ padding: '0px', margin: '0px' }}>
          <div style={{ height: 500, width: "100%", overflowY: "auto", paddingTop: '20px', margin: '0px'  }} className={classes.root}>
              <DataGrid
                rows={urows}
                columns={ucolumns}
                components={{ Toolbar: CustomToolbar }}
                disableSelectionOnClick
                className={classes['.MuiDataGrid-root']}
              />
            </div>
          </TabPanel>

        </TabContext>
      </div>
      </Paper>
    </div>
  );
}
