import React, { useState, useEffect, useRef } from "react";
import { API } from "aws-amplify";
import Cviewstyle from "./courseCreation.module.scss";
import ObjectPopUp from "./AddObjects";
import { Constants } from "../../../../config/Constants";
import config from "../../../../config/aws-exports";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../../../redux/auth/authSlice";
import AddPrefrences from "./addPrefrences";
import {
  Typography,
  Input,
  Backdrop,
  makeStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextareaAutosize,
  CircularProgress,
} from "@material-ui/core";
import Breadcrumb from "../../../Breadcrumbs/Breadcrumbs";
import Skeleton from "@material-ui/lab/Skeleton";
import video from "../../../../assets/objects/video.png";
import audio from "../../../../assets/objects/audio.png";
import pdf from "../../../../assets/objects/pdf.png";
import inter from "../../../../assets/objects/inter.png";
import quiz from "../../../../assets/objects/quiz.png";
import youtube from "../../../../assets/objects/youtube.png";
import vimeo from "../../../../assets/objects/vimeo.png";
import scorm from "../../../../assets/objects/scorm.png";
import html from "../../../../assets/objects/html.png";
import black from "../../../../assets/images/black.png";
import addpluse from "../../../../assets/images/add.png";
import addimg from "../../../../assets/images/addimg.png";

import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import CloseIcon from "@material-ui/icons/Close";
import RegexTextField from "./RegexTextField";
import swal from "sweetalert";
import axios from "axios";
import produce from "immer";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  "& > *": {
    margin: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  ".css-13xfq8m-MuiTabPanel-root": {
    padding: 0,
  },
}));

// Start of main Course Creation Component
export default function CourseCreation({ handleToggleSidebar }) {
  const classes = useStyles();
  const history = useHistory();

  let topicdata = {
    tn: "",
    tdesc: "",
    nav: false,
    cert: false,
    tcertauth: "Enhanzed",
    tnoofcredits: 0,
    tduration: 0,
    ver: 1,
    nugcnt: 0,
    tobj: 0,
    tvid: 0,
    tdoc: 0,
    tfq: 0,
    tsq: 0,
    tasmt: 0,
    nextcourse: false,
    nextcourseid: "",
    pre_course: false,
    pre_courseid: [],
    pre_course_type: "",
    ctype: "regular",
    sqno: "",
    cgry_taggs: [],
  };
  const onlyAlphanumericRegex = /[^0-9]/gi;
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [istLoading, setIstLoading] = useState(false);
  const [nuggets, setNuggets] = useState([]);
  const [value, setValue] = useState("1");
  const [objOpen, setObjOpen] = useState(false);
  const [topicData, setTopicData] = useState(topicdata);
  const [priceininr, setPriceininr] = useState("");
  const [priceinusd, setPriceinusd] = useState("");
  const [earlyPriceInr, setEarlyPriceInr] = useState("");
  const [earlyPriceUsd, setEarlyPriceUsd] = useState("");
  const [earlyDate, setEarlyDate] = useState("");
  const [courseType, setCourseType] = useState("free");
  const [perDis, setPerDis] = useState("");
  const [priceafterDisInr, setPriceafterDisInr] = useState("");
  const [priceafterDisUsd, setPriceafterDisUsd] = useState("");
  const [speakerType, setSpeakerType] = useState("");
  const [sepaker, setSpeaker] = useState([]);
  const [speakerArray, setSpeakerArray] = useState([]);
  const refselectedCourseFile = useRef();
  const redux = useRef({});
  const refUnitValue = useRef("");
  const refnuggetValue = useRef("");
  const [activeIndex, setActiveIndex] = useState(1);
  const [folder, setFolder] = useState(Math.round(new Date().getTime() / 1000));
  const [cNugget, setcNugget] = useState({});
  const [topics, setTopics] = useState([]);
  const [speakerList, setSpeakerList] = useState([]);
  //const [refUnitValue, setrefUnitValue] = useState({})
  const [editObj, setEditObj] = useState({
    editbtn: true,
    meditmode: true,
    oeditmode: false,
    nuggetchanges: false,
    topicimgchanges: false,
  });
  let userDetails = useSelector(authData);
  const dispatch = useDispatch();
  // console.log(JSON.stringify(userDetails));
  useEffect(() => {
    reduxRestore();
    getTopicList();
    setIsLoading(false);
    breadcrumb();
    getSpeaker();
  }, []);

  async function getSpeaker() {
    setIstLoading(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        action: "get",
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const speakerData = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.SPEAKER_LIST,
        bodyParam
      );
      // console.log("speaker", speakerData);

      setSpeakerList(speakerData.body);

      setIstLoading(false);
    } catch (error) {
      // console.log("getUserError", error);
    }
  }

  async function getTopicList() {
    let sdata = { ...userDetails };
    setIsLoading(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        tid: sdata?.categorydata?.id,
        admin: true,
        schema: Constants.SCHEMA_NAME,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      // console.log(JSON.stringify(bodyParam.body));
      const list = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_CATEGORY_DATA,
        bodyParam
      );

      // setCatdata(list.topics);
      const rows = list.courses.map((item, index) => {
        var obj = {};
        obj.col1 = item.tid;
        obj.id = item.tid;
        obj.tn = item.tn;
        obj.cby = item.cby;
        obj.publish = item?.tenants?.length;
        obj.tenants = item.tenants;
        return obj;
      });
      // console.log(rows);

      setTopics(rows);
    } catch (error) {
      console.error(error);
    }
  }

  const renderSwitch = (param) => {
    if (param === "video") {
      return <img src={video} style={{ height: "32px" }} />;
    } else if (param === "audio") {
      return <img src={audio} style={{ height: "32px" }} />;
    } else if (param === "quiz") {
      return <img src={quiz} style={{ height: "32px" }} />;
    } else if (param === "html") {
      return <img src={html} style={{ height: "32px" }} />;
    } else if (param === "Interactivity" || param === "interactivity") {
      return <img src={inter} style={{ height: "32px" }} />;
    } else if (param === "pdf") {
      return <img src={pdf} style={{ height: "32px" }} />;
    } else if (param === "youtube") {
      return <img src={youtube} style={{ height: "32px" }} />;
    } else if (param === "vimeo") {
      return <img src={vimeo} style={{ height: "32px" }} />;
    } else if (param === "scorm") {
      return <img src={scorm} style={{ height: "32px" }} />;
    } else {
      return param;
    }
  };

  function reduxRestore() {
    let sdata = { ...userDetails };
    redux.current = sdata;
  }

  function CourseStructure() {
    return (
      <div>
        <Typography component="div" variant="h1">
          {isLoading ? <Skeleton /> : null}
        </Typography>
        <Typography component="div" variant="h1">
          {isLoading ? <Skeleton /> : null}
        </Typography>
        <Typography component="div" variant="h1">
          {isLoading ? <Skeleton /> : null}
        </Typography>
        <Typography component="div" variant="h1">
          {isLoading ? <Skeleton /> : null}
        </Typography>
        <div>
          <ObjectPopUp
            objOpen={objOpen}
            setObjOpen={setObjOpen}
            renderSwitch={renderSwitch}
            folder={folder}
            cNugget={cNugget}
            setNuggets={setNuggets}
            nuggets={nuggets}
          />
          {nuggets.map(
            ({ nid, unit, ntitle, nduration, objects, nenabled }, idx) => (
              <div className={Cviewstyle.nuggets} key={nid}>
                <div className={Cviewstyle.displayflex + " padding2"}>
                  {/* <h3 className="item" style={{ fontWeight: "bold" }}>
                    
                      {ntitle}
                    </h3> */}
                  <Input
                    defaultValue={ntitle}
                    style={{ fontWeight: "bold" }}
                    className={Cviewstyle.inputsize}
                    onClick={(event) => event.stopPropagation()}
                    onFocus={(event) => event.stopPropagation()}
                    inputProps={{ "aria-label": "description" }}
                  />
                  <button
                    onClick={() => {
                      // nuggets.splice(idx, 1);
                      // setNuggets([...nuggets]);
                      setNuggets((currentTopic) =>
                        produce(currentTopic, (draft) => {
                          draft.splice(idx, 1);
                        })
                      );
                    }}
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      padding: "0px 20px",
                      background: "#e35f14",
                      border: "none",
                      borderRadius: "5px",
                      color: "#fff",
                      width: "195px",
                      height: "30px",
                    }}
                  >
                    Remove Module
                  </button>
                </div>
                <div className={Cviewstyle.objects}>
                  {objects.map(
                    (
                      {
                        oid,
                        otitle,
                        odescription,
                        otype,
                        oduration,
                        ourl,
                        userOP,
                      },
                      oidx
                    ) => (
                      <div
                        className="flex size"
                        style={{
                          justifyContent: "space-between",
                          width: "calc(100% - 4.5%)",
                          display: "flex",
                        }}
                        key={oid}
                      >
                        <p
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <span>{renderSwitch(otype)}</span>
                          <a
                            style={{ paddingLeft: "10px" }}
                            href
                            onClick={() => {}}
                          >
                            {otitle}
                          </a>
                        </p>

                        <span style={{ display: "flex" }}>
                          <button
                            onClick={() => {
                              // // console.log(idx, nuggets);
                              // let nug = nuggets[idx].objects.splice(oidx, 1);

                              // setNuggets([...nug]);
                              setNuggets((currentTopic) =>
                                produce(currentTopic, (draft) => {
                                  draft[idx].objects.splice(oidx, 1);
                                })
                              );
                            }}
                            style={{
                              border: "none",
                              background: "transparent",
                            }}
                          >
                            <CloseIcon
                              fontSize="10px"
                              style={{ color: "red" }}
                            />
                          </button>
                        </span>
                      </div>
                    )
                  )}
                  <div className={Cviewstyle.addnugget}>
                    <div>
                      <p
                        style={{
                          fontSizesize: "14px",
                          margin: "5px 0",
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setcNugget({
                            nid,
                            ntitle,
                            objects,
                            nduration,
                            idx,
                            cedit: true,
                          });
                          setObjOpen(true);
                        }}
                      >
                        <img
                          style={{
                            height: "25px",
                            border: "none",
                            cursor: "pointer",
                            margin: "0 15px",
                          }}
                          src={addpluse}
                          alt=""
                        />
                        Add objects
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )
          )}
        </div>

        <div className={Cviewstyle.addnugget}>
          <div>
            <p
              style={{
                fontSize: "14px",
                textAlign: "center",
                cursor: "pointer",
              }}
              onClick={handleClickOpen}
            >
              <img
                style={{
                  height: "20px",
                  border: "none",
                  cursor: "pointer",
                  margin: "0 15px",
                }}
                src={addpluse}
                alt=""
              />
              Add Modules
            </p>
          </div>
          <Dialog
            open={open}
            fullWidth="true"
            maxWidth="sm"
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              <h2 className={Cviewstyle.popadd}>Add Module</h2>
            </DialogTitle>

            <DialogContent>
              <div className={Cviewstyle.inputholder}>
                {/* <input
                    id="standard-error-helper-text"
                    placeholder="Unit Name"
                    type="text"
                    className={Cviewstyle.inputbox}
                    onChange={(event) => {
                      const { value } = event.target;
                      refUnitValue.current = value;
                    }}
                  /> */}
                <input
                  id="name"
                  placeholder="Modules Name"
                  type="text"
                  className={Cviewstyle.inputbox}
                  onChange={(event) => {
                    const { value } = event.target;
                    refnuggetValue.current = value;
                    //setrefUnitValue({nugget: value});
                  }}
                />
              </div>
            </DialogContent>
            <div className={Cviewstyle.buttonholder}>
              <DialogActions>
                <button onClick={handleClose} className={Cviewstyle.cancel}>
                  Cancel
                </button>
                <button
                  onClick={() => {
                    // errors.uval = false;
                    if (
                      refnuggetValue.current === undefined ||
                      refnuggetValue.current.toString().replace(/\s/g, "")
                        .length === 0
                    ) {
                      swal("Oops!", "Please Enter Module Name", "error");
                      return;
                    } else {
                      let objs = {};
                      objs.nid = nuggets.length + 1;
                      objs.nenabled = false;
                      objs.ntitle = refnuggetValue.current;

                      objs.objects = [];
                      // objs.unit = refUnitValue.current;

                      //nuggets.push(objs);
                      setNuggets((previousnugget) => [...previousnugget, objs]);
                      // refUnitValue.current = "";
                      refnuggetValue.current = "";
                      handleClose();
                    }
                  }}
                  className={Cviewstyle.save}
                >
                  Save
                </button>
              </DialogActions>
            </div>
          </Dialog>
        </div>
      </div>
    );
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const changeHandler = (event) => {
    if (event.target.files == null) {
      return;
    }
    if (event.target.files[0].size > 5242880) {
      swal(
        "Oops!",
        "Image size is too large. File size cannot be greater than 5mb.",
        "error"
      );
      return;
    }
    if (event.target.files[0] !== undefined) {
      refselectedCourseFile.current = event.target.files[0];
      //setSelectedCourseFile((currentState) => (event.target.files[0]));
      var body = {
        filetype: "image/png",
        filename: "topic.png",
        folder: folder,
      };

      uploadfile(body);
    }
  };

  async function uploadfile(body) {
    setIstLoading(true);
    const bodyParam = {
      body,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const preSignedData = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_PRESIGNED_URL,
        //"/getpreSignedURL",
        bodyParam
      );
      console.log(refselectedCourseFile.current);
      axios
        .put(preSignedData, refselectedCourseFile.current, {
          headers: {
            "Content-Type": "image/png",
            "Cache-Control": "no-cache no-store must-revalidate",
          },
        })
        .then((response) => {
          // console.log("file upload success");
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
      setIstLoading(false);
    } catch (error) {
      // alert(JSON.stringify(error));
      console.error(error);
    }
  }

  function checkData() {
    setIstLoading(true);
    if (
      topicData.tn === "" ||
      topicData.tn.length === 0 ||
      topicData.tn.trim() === ""
    ) {
      swal("Oops!", "Course title cannot be empty", "error");
      setIstLoading(false);
      return;
    }
    if (topicData.tdesc === "" || topicData.tdesc.length === 0) {
      swal(
        "Oops!",
        "Course description cannot be empty in Overview section",
        "error"
      );
      setIstLoading(false);
      return;
    }
    if (
      topicData.targetAudience === "" ||
      topicData.targetAudience.length === 0
    ) {
      swal(
        "Oops!",
        "Target Audience cannot be empty in Overview section",
        "error"
      );
      setIstLoading(false);
      return;
    }
    if (
      topicData.learningObjectives === "" ||
      topicData.learningObjectives.length === 0
    ) {
      swal(
        "Oops!",
        "Learning Objectives cannot be empty in Overview section",
        "error"
      );
      setIstLoading(false);
      return;
    }

    if (topicData.sqno === "" || topicData.sqno.length === 0) {
      swal(
        "Oops!",
        "Sequence no cannot be empty in Preferences section",
        "error"
      );
      setIstLoading(false);
      return;
    }
    if ((courseType === "paid" && priceininr === "") || priceininr === null) {
      swal("Oops", "Please add the price in INR", "error");
      setIstLoading(false);
      return;
    }
    if (speakerArray.length === 0) {
      swal("Oops", "Please select the speaker", "error");
      setIstLoading(false);
      return;
    }

    if (topicData.pre_course === "true" || topicData.pre_course === true) {
      if (
        topicData.pre_course_type === "" ||
        topicData.pre_course_type === null
      ) {
        swal(
          "Oops!",
          "Select Prerequisites Type in Preferences section",
          "error"
        );
        setIstLoading(false);
        return;
      }
    }
    if (topicData.nextcourse) {
      if (
        topicData.nextcourseid === "" ||
        topicData.nextcourseid === null ||
        topicData.nextcourseid === undefined
      ) {
        swal("Oops!", "Please select next course name.", "error");
        setIstLoading(false);
        return;
      }
    }

    if (refselectedCourseFile.current === undefined) {
      swal("Oops!", "Please select a Course image.", "error");
      setIstLoading(false);
      return;
    }
    if (nuggets.length === 0) {
      swal("Oops!", "You need to add atleast 1 Module.", "error");
      setIstLoading(false);
      return;
    }
    if (nuggets.length > 0) {
      for (var i = 0; i < nuggets.length; i++) {
        if (
          nuggets[i].objects.length === 0 ||
          nuggets[i].objects.length === "0"
        ) {
          swal(
            "Oops!",
            "You need to add atleast 1 Content in each Module",
            "error"
          );
          setIstLoading(false);
          return;
        }
      }
      saveTopicGetID();
      // swal(
      //   "Success",
      //   "Course Created Successfully",
      //   "success"
      // );
    }
  }

  async function saveTopicGetID() {
    setIstLoading(true);
    let ndur = 0,
      tobj = 0,
      tvid = 0,
      tdoc = 0,
      tsq = 0,
      tfq = 0,
      tdur = 0;
    let nug = [...nuggets];
    nug = produce(nug, (v) => {
      for (let i = 0; i < v.length; i++) {
        ndur = 0;

        for (let o = 0; o < v[i].objects.length; o++) {
          tobj++;
          ndur = ndur + Number(v[i].objects[o].oduration);
          tdur = tdur + Number(v[i].objects[o].oduration);
          if (
            v[i].objects[o].otype === "video" ||
            v[i].objects[o].otype === "audio"
          ) {
            tvid++;
          } else if (v[i].objects[o].otype === "quiz") {
            tsq++;
          } else {
            tdoc++;
          }
          if (o === v[i].objects.length - 1) {
            v[i].nduration = ndur;
          }
          // console.log(tdur);
        }
      }
    });
    setNuggets(nug);
    topicData.toduration = tdur;
    topicData.dur = tdur;
    topicData.tobj = tobj;
    topicData.tvid = tvid;
    topicData.tdoc = tdoc;
    topicData.tsq = tsq;

    setTopicData((prevtopicData) => ({ ...prevtopicData, ...topicData }));
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        NAME: userDetails.name,
        TOPICNAME: topicData.tn,
        schema: Constants.SCHEMA_NAME,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const getTopicId = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_TOPIC_ID,
        bodyParam
      );
      //alert(JSON.stringify(getTopicId.body[0].tid));
      saveTopicDetails(getTopicId.body[0].tid, nug);
      swal("Success", "Course Created Successfully", "success");
    } catch (error) {
      setIstLoading(false);
      console.error(error);
    }
  }

  async function saveTopicDetails(topicid, nug) {
    try {
      const bodyParam = {
        body: {
          oid: config.aws_org_id,
          cby: userDetails.name,
          folder,
          tid: topicid,
          ttitle: topicData.tn,
          tdescription: topicData.tdesc,
          learningObjectives: topicData.learningObjectives,
          targetAudience: topicData.targetAudience,
          freenavigation: topicData.nav,
          certification: topicData.cert,
          tcertauth: topicData.tcertauth,
          tnoofcredits: topicData.nugcnt,
          tduration: topicData.dur,
          toduration: topicData.toduration,
          noofnuggets: nuggets.length,
          catlist: userDetails?.categorydata,
          nuggets: nug,
          version: 1,
          tobj: topicData.tobj,
          tvid: topicData.tvid,
          tdoc: topicData.tdoc,
          tfq: topicData.tfq,
          tsq: topicData.tsq,
          tasmt: topicData.tasmt,
          nextcourse: topicData.nextcourse,
          nextcourseid: topicData.nextcourseid,
          precourse: topicData.pre_course,
          precourseid: topicData.pre_courseid,
          precoursetype: topicData.pre_course_type,
          ctype: topicData.ctype,
          sqno: topicData.sqno,
          ttags: topicData.cgry_taggs,
          schema: Constants.SCHEMA_NAME,
          coursetype: courseType,
          priceinr: parseInt(priceininr),
          priceusd: parseInt(priceinusd),
          perdis: perDis,
          priceafterDisInr: parseFloat(priceafterDisInr),
          priceafterDisUsd: parseFloat(priceafterDisUsd),
          earlyDate: earlyDate,
          earlyPriceUsd: parseInt(earlyPriceUsd),
          earlyPriceInr: parseInt(earlyPriceInr),
          speakerArray: speakerArray,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      if (userDetails?.curprgcou !== undefined) {
        if (userDetails?.curprgcou.bpid !== undefined) {
          bodyParam.body.pid = userDetails?.curprgcou.pid;
          bodyParam.body.bpid = userDetails?.curprgcou.bpid;
        }
      }
      // console.log("topicData===" + JSON.stringify(bodyParam.body, null, 2));
      const getTopicDetails = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.CREATE_TOPIC,
        bodyParam
      );
      // console.log("create success");
      setIstLoading(false);
      const tab = window.localStorage.getItem("tab");
      history.push(`/uspcontent?tab=${tab}`);
    } catch (error) {
      setIstLoading(false);
      console.error("createtopic" + error);
    }
  }

  function breadcrumb() {
    let sdata = { ...userDetails };
    let temp = [...sdata?.breadcrumb];

    temp[2] = {
      name: "Course Creation",
      path: "/uspcontent/coursecreation",
    };
    sdata !== undefined && (sdata.breadcrumb = temp);

    dispatch(awsSignIn(sdata));
  }

  const handleDiscardChange = () => {
    swal({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willsave) => {
      if (willsave) {
        // setIstLoading(true);
        saveTopicDetails();
      }
    });
  };
  // Start of main return
  return (
    <div className={Cviewstyle.maincontainer}>
      <Breadcrumb Bindex={1} />
      {istLoading && (
        <Backdrop className={classes.backdrop} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <div
        className="btn-toggle"
        onClick={() => handleToggleSidebar(true)}
      ></div>

      <div className={Cviewstyle.management_info}>
        <div className={Cviewstyle.h1}>
          <h1 className={Cviewstyle.header}>Course Creation</h1>
          <button
            className={Cviewstyle.discard}
            onClick={() => handleDiscardChange()}
          >
            Discard
          </button>
          <button className={Cviewstyle.savebutton} onClick={() => checkData()}>
            Save
          </button>
        </div>

        <div className={Cviewstyle.flex_box} style={{ position: "relative" }}>
          {refselectedCourseFile.current === undefined && (
            <img
              className={Cviewstyle.imagebox}
              style={{ objectFit: "scale-down" }}
              src={addimg}
              alt=""
            />
          )}
          {refselectedCourseFile.current !== undefined && (
            <img
              className={Cviewstyle.imagebox}
              src={URL.createObjectURL(refselectedCourseFile.current)}
              alt=""
            />
          )}
          <img src={black} className={Cviewstyle.imgbl} alt="" />
          <Input
            className={Cviewstyle.inputsize + " " + Cviewstyle.ctitle}
            onChange={(e) => {
              topicData.tn = e.target.value;
              // topicData.tn = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
              setTopicData((prevtopicData) => ({
                ...prevtopicData,
                ...topicData,
              }));
              console.log({ topicData });
            }}
            value={topicData.tn}
            inputProps={{
              "aria-label": "description",
              placeholder: "Title",
              style: { textAlign: "center" },
            }}
          />
          <input
            type="file"
            id="cfiles"
            style={{ visibility: "hidden", position: "absolute" }}
            onChange={changeHandler}
            accept="Image/*"
          />
          <label
            htmlFor="cfiles"
            style={{ position: "absolute", right: "10px", bottom: "6px" }}
          >
            <AddAPhotoIcon style={{ color: "#ddd", cursor: "pointer" }} />
          </label>
        </div>
      </div>
      <div className={Cviewstyle.tabsholder}>
        <TabContext value={value}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              backgroundColor: "#fff",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              indicatorColor="primary"
              textColor="inherit"
              variant="fullWidth"
            >
              <Tab label="Overview" value="1" />
              <Tab label="Content" value="2" />
              <Tab label="Preferences" value="3" />
            </Tabs>
          </Box>
          <TabPanel
            value="1"
            className={classes[".css-13xfq8m-MuiTabPanel-root"]}
          >
            <div>
              <TextareaAutosize
                maxRows={10}
                minRows={5}
                aria-label="maximum height"
                placeholder="Description"
                defaultValue={topicData.tdesc}
                style={{
                  width: "100%",
                  fontSize: "14px",
                  padding: "10px",
                  fontFamily: "Oxygen, sans-serif",
                }}
                onChange={(e) => {
                  topicData.tdesc = e.target.value;
                  setTopicData((prevtopicData) => ({
                    ...prevtopicData,
                    ...topicData,
                  }));
                }}
              />
              <TextareaAutosize
                maxRows={10}
                minRows={5}
                aria-label="maximum height"
                placeholder="Learning objectives"
                defaultValue={topicData.learningObjectives}
                style={{
                  width: "100%",
                  fontSize: "14px",
                  padding: "10px",
                  fontFamily: "Oxygen, sans-serif",
                }}
                onChange={(e) => {
                  topicData.learningObjectives = e.target.value;
                  setTopicData((prevtopicData) => ({
                    ...prevtopicData,
                    ...topicData,
                  }));
                }}
              />
              <TextareaAutosize
                maxRows={10}
                minRows={5}
                aria-label="maximum height"
                placeholder="Target audience"
                defaultValue={topicData.targetAudience}
                style={{
                  width: "100%",
                  fontSize: "14px",
                  padding: "10px",
                  fontFamily: "Oxygen, sans-serif",
                }}
                onChange={(e) => {
                  topicData.targetAudience = e.target.value;
                  setTopicData((prevtopicData) => ({
                    ...prevtopicData,
                    ...topicData,
                  }));
                }}
              />
            </div>
          </TabPanel>
          <TabPanel
            value="2"
            className={classes[".css-13xfq8m-MuiTabPanel-root"]}
          >
            <CourseStructure />
          </TabPanel>
          <TabPanel
            value="3"
            className={classes[".css-13xfq8m-MuiTabPanel-root"]}
          >
            <AddPrefrences
              topics={topics}
              topicData={topicData}
              folder={folder}
              setTopicData={setTopicData}
              priceafterDisInr={priceafterDisInr}
              priceafterDisUsd={priceafterDisUsd}
              setPriceafterDisInr={setPriceafterDisInr}
              setPriceafterDisUsd={setPriceafterDisUsd}
              editObj={editObj}
              setEditObj={setEditObj}
              priceininr={priceininr}
              setPriceininr={setPriceininr}
              priceinusd={priceinusd}
              setPriceinusd={setPriceinusd}
              setEarlyPriceInr={setEarlyPriceInr}
              earlyPriceUsd={earlyPriceUsd}
              setEarlyPriceUsd={setEarlyPriceUsd}
              earlyPriceInr={earlyPriceInr}
              earlyDate={earlyDate}
              setEarlyDate={setEarlyDate}
              perDis={perDis}
              setPerDis={setPerDis}
              speakerType={speakerType}
              setSpeakerType={setSpeakerType}
              setSpeaker={setSpeaker}
              speakerArray={speakerArray}
              setSpeakerArray={setSpeakerArray}
              courseType={courseType}
              setCourseType={setCourseType}
              speakerList={speakerList}
            />
          </TabPanel>
        </TabContext>
      </div>
    </div>
  );
}
