import styled from "styled-components";

export const Wrapper = styled.div`
  min-height: 92vh;
  width: 200px;
  background-color: white;
  position: fixed;
  top: 70px;
  left: 0px;
  z-index: 50;
  flex: 0 0 15%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .side-nav {
    font-size: 14px;
    list-style: none;
    margin-top: 0rem;
  }

  .side-nav .side-nav__item {
    position: relative;
    color: black;
  }

  .sidebar {
    padding-bottom: auto;
  }

  /* .side-nav .side-nav__item:not(:last-child) {
  margin-bottom: 0.5rem;
} */

  .side-nav .side-nav__item:nth-child(2) {
    margin-bottom: 0;
  }
  .side-nav .side-nav__item:nth-child(3) {
    margin-bottom: 0;
  }

  .side-nav .side-nav__item::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 4px;
    background-color: #285780;
    transform: scaleY(0);
    transition: transform 0.2s, width 0.8s cubic-bezier(1, 0.01, 0, 1);
  }

  /* .side-nav .side-nav__item:hover::before {
	transform: scaleY(1);
	width: 100%;
	
  } */

  .side-nav .side-nav__item--active::before {
    //border-left: 0.2rem solid #d2d2d2;
    transform: scaleY(1);
  }
  /* .dropdown-container {
  max-width: 200px;
} */

  .dropdown-container .side-nav__item {
    padding: 1rem !important;
  }

  .dropdown-container .side-nav__item .side-nav__link {
    padding: 1rem 1rem 1rem 5rem !important;
  }

  .side-nav .side-nav__item--active {
    font-weight: 600;
    color: #285780;
    background-color: #ededed;
  }
  .side-nav .side-nav__item {
    text-decoration: none;
    display: block;
    padding: 1rem 1rem 1rem 2rem;
    /* font-weight: 300; */
    /* text-transform: uppercase; */

    border-bottom: 1px solid #ededed;
  }
  .side-nav .side-nav__item:focus {
    outline: none;
    /* text-transform: uppercase; */

    /* border-bottom: 0.1rem solid var(--color-grey-dark-2); */
  }
  .side-nav .side-nav__item:hover {
    background-color: #ededed;
    /* text-transform: uppercase; */

    /* border-bottom: 0.1rem solid var(--color-grey-dark-2); */
  }

  .side-nav__link {
    display: flex;
    align-items: center;
    color: black;
    text-decoration: none;
  }
  .side-nav__link:focus {
    color: black;
  }

  .side-nav__link:hover {
    text-decoration: none;
    color: black;
  }
  .cls-1,
  .cls-3,
  .cls-4 {
    fill: #fff;
  }
  .cls-2 {
    fill: #777;
    stroke: #777;
  }
  .cls-2,
  .cls-3,
  .cls-4 {
    stroke-miterlimit: 10;
  }
  .cls-2,
  .cls-4 {
    stroke-width: 2.53px;
  }
  .cls-3,
  .cls-4 {
    stroke: #fff;
  }
  .cls-3 {
    stroke-width: 2.49px;
  }
  .icon-size {
    height: 2rem;
    width: 2rem;
  }

  .cls-1-c {
    fill: #fff;
  }
  .cls-2-c,
  .cls-3-c {
    fill: #777;
    stroke: #777;
    stroke-miterlimit: 10;
  }
  .cls-2-c {
    stroke-width: 2.53px;
  }
  .cls-3-c {
    stroke-width: 2.14px;
  }
  .cls-1-e {
    fill: #fff;
  }
  .cls-2-e {
    fill: #6b6b6b;
  }
  .cls-3-e {
    fill: none;
    stroke: #6b6b6b;
    stroke-miterlimit: 10;
    stroke-width: 0.25px;
  }
  .cls-1-d,
  .cls-3-d {
    fill: #fff;
  }
  .cls-2-d {
    fill: #777;
    stroke: #777;
  }
  .cls-2-d,
  .cls-3-d {
    stroke-miterlimit: 10;
    stroke-width: 2.53px;
  }
  .cls-3-d {
    stroke: #fff;
  }
  .cls-1-eg {
    fill: #fff;
  }
  .cls-2-eg {
    fill: #777;
    stroke: #777;
    stroke-miterlimit: 10;
    stroke-width: 2.53px;
  }
`;
