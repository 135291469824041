import React, { forwardRef, useMemo, useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";

import DialogContent from "@mui/material/DialogContent";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import Slide from "@mui/material/Slide";
import { CardMedia } from "@mui/material";
import { Box } from "@mui/system";
import config from "../../config/aws-exports";
import "./phoneInput.styles.css";
import SignIn from "./SignIn";
import { Theme } from "../../config/aws-exports";

export const inputBoxStyle = {
  width: { xs: 300, sm: 450 },
  margin: { xs: "1rem 0", sm: "1rem" },
};
export const displayCenter = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

export const inputIconStyle = {
  color: Theme.color.primary,
};

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const SignInAndSignUpValues = React.createContext();
const SignInAndSignUp = ({ loginOpen, setLoginOpen, setForgotPassword }) => {
  //Responsive Dialog
  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  //Bool State for SignInAndSignUp
  const [signIn, setSignIn] = useState(true);

  const SignInAndSignUpProvider = useMemo(
    () => ({
      signIn,
      setSignIn,
    }),
    [signIn, setSignIn]
  );

  //Dialog close Function
  const handleClose = () => {
    setLoginOpen(true);
    setForgotPassword(false);
  };

  // const handleCognitoResetPasswordError = (error) => {
  //   console.error(error);
  //   if (error.code === "InvalidParameterException") {
  //     setError("Invalid code entered");
  //     return;
  //   } else {
  //     setError("");
  //   }
  //   if (error.code === "CodeMismatchException") {
  //     setError("Code mismatch");
  //     return;
  //   } else {
  //     setError("");
  //   }
  //   if (error.code === "InvalidPasswordException") {
  //     setError("Please match the password type.");
  //     return;
  //   } else {
  //     setError("");
  //   }
  // };
  // const handleResetPassword = () => {
  //   if (values.confirmPassword === "") {
  //     // setResetPasswordMatchFirst("Please enter a password");
  //     alert("Please enter a password");
  //     return;
  //   } else {
  //     // setResetPasswordMatchFirst("");
  //   }
  //   if (values.resetPassword !== values.confirmPassword) {
  //     alert("Passwords don't Match!");
  //     return;
  //     // setResetPasswordMatchFirst("Passwords don't Match!");
  //   } else {
  //     // setResetPasswordMatchFirst("");
  //   }
  //   if (!regularExpressionFORPassword.test(values.resetPassword)) {
  //     // setResetPasswordMatchFirst("Please match password pattern");
  //     alert("Please match password pattern");
  //     return;
  //   } else {
  //     // setResetPasswordMatchFirst("");
  //   }
  // };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={loginOpen}
        TransitionComponent={Transition}
        transitionDuration={140}
        keepMounted
        aria-labelledby="responsive-dialog-title"
        maxWidth="md"
        scroll="body"
      >
        
        <DialogContent
          sx={{
            margin: { xs: 0, sm: 0, md: "0 4rem", xl: "0 7rem" },
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CardMedia
              component="img"
              src={`https://${
                config.DOMAIN
              }/${config.aws_org_id.toLowerCase()}-resources/images/org-images/logo-dark.png`}
              alt="logo"
              sx={{ maxWidth: "250px" }}
            />
          </Box>

          <SignInAndSignUpValues.Provider value={SignInAndSignUpProvider}>
            {signIn && <SignIn />}
          </SignInAndSignUpValues.Provider>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default SignInAndSignUp;
