import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import { Link } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import Dialog from "@mui/material/Dialog";
import BackDrop from "@material-ui/core/Backdrop/Backdrop";
import { Constants } from "../../../config/Constants";
import AddTopic from "./AddTopic";
import { API } from "aws-amplify";
import config from "../../../config/aws-exports";
import CircularProgress from "@material-ui/core/CircularProgress";
import image from "./2.png";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../../redux/auth/authSlice";
import { useHistory } from "react-router-dom";
import EditTopic from "./EditTopic";
import { ReactComponent as Edit } from "../../../assets/icons/mode_black_24dp.svg";
import contentStyle from "./content.module.scss";
// Styles for material components
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
    "& .super-app-theme--header": {
      backgroundColor: Constants.blue_color,
      fontSize: "16px",
      color: "rgb(255,255,255)",
      fontFamily: "nunito",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
    cursor: "pointer",
  },
}));
export default function Elearning() {
  let userDetails = useSelector(authData);
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [catdata, setCatdata] = useState([]);
  const [openAddTopic, setOpenAddTopic] = useState(false);
  const [editopen, setEditOpen] = useState(false);
  const [dataToEdit, setDataToEdit] = useState({});
  const handleAddTopicOpen = () => setOpenAddTopic(true);
  const handleAddTopicClose = () => setOpenAddTopic(false);
  useEffect(() => {
    getTopicList();

    return () => {};
  }, []);
  async function getTopicList() {
    setLoading(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        schema: Constants.SCHEMA_NAME,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log(bodyParam.body);
      const list = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_CATEGORIES,
        bodyParam
      );
      console.log(JSON.stringify(list.topics));
      setCatdata(list.topics);

      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  }
  function handleEditOpen(item) {
    setEditOpen(true);
    setDataToEdit(item);
  }
  const handleEditClose = () => {
    setEditOpen(false);
  };
  const gotocontent = (item) => {
    let sdata = { ...userDetails };
    sdata.categorydata = item;
    if (sdata.breadcrumb == undefined) {
      sdata.breadcrumb = [];
      let temp = [...sdata.breadcrumb];
      temp[0] = {
        name: "Topic",
        path: "/category",
      };
      temp[1] = {
        name: item.name,
        path: "/category/content",
      };
      sdata.breadcrumb = temp;
    } else {
      let temp = [...sdata.breadcrumb];
      temp[0] = {
        name: "Topic",
        path: "/category",
      };
      temp[1] = {
        name: item.name,
        path: "/category/content",
      };
      sdata.breadcrumb = temp;
    }
    dispatch(awsSignIn(sdata));
    history.push("/category/content");
  };

  return (
    <div className={contentStyle.main}>
      <div className={contentStyle.topbar}>
        <h3 className={contentStyle.heading}> </h3>
        <div className={contentStyle.buttonholder}>
          <button
            className={contentStyle.addbutton}
            onClick={handleAddTopicOpen}
          >
            Add Topic
          </button>
        </div>
      </div>
      <Dialog
        open={openAddTopic}
        aria-labelledby="responsive-dialog-title-2"
        maxWidth="md"
      >
        <AddTopic
          handleClose={handleAddTopicClose}
          setLoading={setLoading}
          loading={loading}
        />
      </Dialog>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={editopen}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={editopen}>
          <EditTopic
            handleEditClose={handleEditClose}
            dataToEdit={dataToEdit}
            getPrograms={getTopicList}
          />
        </Fade>
      </Modal>

      {!loading ? (
        <Box
          sx={{
            display: "flex",

            "& > :not(style)": {
              m: 1,
              width: 1,
              height: "100%",
            },
          }}
        >
          <Paper elevation={3}>
            <List>
              {catdata?.map((item) => (
                <ListItem
                  key={item.name}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <img
                    style={{
                      width: "200px",
                      height: "100px",
                      borderRadius: "10px",
                    }}
                    src={`https://${
                      config.DOMAIN
                    }/${config.aws_org_id.toLowerCase()}-resources/images/category-images/${
                      item.ct_img
                    }`}
                    alt=""
                  ></img>
                  <Link
                    style={{ padding: "10px", color: "black", fontSize: "18px", textDecoration: "none" }}
                    to="#"
                    onClick={() => gotocontent(item)}
                  >
                    <h5> {item.name} </h5>
                  </Link>
                  <Box sx={{ flexGrow: 1 }} />
                  <Edit
                    sx={{ m: 3 }}
                    className={contentStyle.icons}
                    onClick={() => handleEditOpen(item)}
                  />
                </ListItem>
              ))}

              <Divider />
            </List>
          </Paper>

          {catdata != undefined && catdata.length == 0 ? (
            <h2>no data</h2>
          ) : null}
        </Box>
      ) : null}

      <BackDrop
        sx={{ color: "#fff" }}
        open={loading}
        className={classes.backdrop}
      >
        <CircularProgress color="inherit" />
      </BackDrop>
    </div>
  );
}
