import {TextField,makeStyles} from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useCallback } from "react";

export const matchNothingRegex = /(?!)/;

const RegexTextField = ({ regex, onChange, ...rest }) => {
  const classes = useStyles();
  const handleChange = useCallback(
    (e) => {
      e.currentTarget.value = e.currentTarget.value.replace(regex, "");
      onChange(e);
    },
    [onChange, regex]
  );

  return <TextField 
  className={classes.textField}
  inputProps={{
    maxLength: 3,
  }}
        margin="normal"
        variant="outlined"  id="outlined-margin-normal" onChange={handleChange} {...rest} />;
};
const useStyles = makeStyles((theme) => ({
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '47.5%',
    },
    
}));

export default React.memo(RegexTextField);

RegexTextField.propTypes = {
  onChange: PropTypes.func.isRequired,
  regex: PropTypes.instanceOf(RegExp)
};

RegexTextField.defaultProps = {
  regex: matchNothingRegex
};
