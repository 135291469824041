import React, {useState} from 'react';


import Rej from './RejectionMail.module.scss';




const RejectionMail = (props) => {
    const [err, setErr] = useState("");
    const {handleMainClose, setMessage, message, mailUsers} = props;

    function sendEmail() {
        let formIsValid = true;
        setErr("");

        if(message === "" || message == null || message.trim() === ""){
            formIsValid = false;
            setErr("Please enter the reason for being rejected");
            return;
        }

        if (formIsValid === true) {
            handleMainClose();
            mailUsers();
        }
    }

    return (
        <div className={Rej.main}>
            <h3 className={Rej.heading}>Reason for Rejecting?</h3>
            <div
            style={{
              color: "#cc0000",
              textAlign: "center",
              fontSize: "12px",
              paddingBottom: "10px"
            }}
          >
            {err}
          </div>
            <textarea className={Rej.area} rows={5} onChange={(event) => setMessage(event.target.value)}/>
            <div className={Rej.buttonholder}>
                <button className={Rej.cancel} onClick={()=> handleMainClose()}>
                    Cancel
                </button>
                <button className={Rej.submit} onClick={() => sendEmail()}>
                    Submit
                </button>
            </div>
        </div>
    )
};


export default RejectionMail;