import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import Table from "@mui/material/Table";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import { Link, useHistory } from "react-router-dom";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { API } from "aws-amplify";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../../redux/auth/authSlice";
import Skeleton from "@material-ui/lab/Skeleton";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

// Local imports
import { ReactComponent as Edit } from "../../../assets/icons/mode_black_24dp.svg";
import { ReactComponent as Delete } from "../../../assets/icons/delete_outline_black_24dp.svg";
import { ReactComponent as EditGrey } from "../../../assets/icons/greayEdit.svg";
import { ReactComponent as DeleteGrey } from "../../../assets/icons/greyDelete.svg";
import { ReactComponent as Search } from "../../../assets/icons/search_black_24dp.svg";
import { Constants } from "../../../config/Constants";
import { CustomButton } from "../../CustomButton/CustomButton";
import AddSpeaker from "./AddSpeaker";
import image1 from "./2.png";
import RegisteredUsers from "./ListOfUsers";
import DeleteSession from "./DeleteSession";
import ListOfTopics from "./listTopics";
import AddTopic from "./AddTopic";
import config from "../../../config/aws-exports";
import SessionAdd from "./SessionAdd";
import EditSession from "./EditSession";
import ApproveUsers from "./ApproveUsers";
import SpeakersGrid from "./SpeakersGrid";
import RegistrationConfirmation from "./RegistrationConfirmation";
import MarkPopular from "./MarkPopular";
import Publish from "./publish";
import BackDrop from "@material-ui/core/Backdrop/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import AssignUsers from "./AssignUsers";
import InterestedUsers from "./InterestedUsers";
import EditSpeaker from "./EditSpeaker";
import DeleteSpeaker from "./DeleteSpeaker";
import {
  DataGrid,
  GridToolbar,
  GridToolbarContainer,
} from "@material-ui/data-grid";

// Style imports
import contentStyle from "./content.module.scss";
import { useQueryParams } from "../../Hooks/useQueryParams";
import { Button } from "@material-ui/core";
import { FileDownload } from "@mui/icons-material";
import { CSVLink } from "react-csv";
import listTopStyle from "./listTopics.module.scss";
import swal from "sweetalert2";
import NothiongHereYet from "../../../assets/Nothing Here Yet.gif";
import ProformaUsers from "./ProformaUsers";

function CustomToolbar() {
  return (
    <GridToolbarContainer
      style={{ display: "flex", justifyContent: "flex-end" }}
    >
      <GridToolbar />
    </GridToolbarContainer>
  );
}

// Styles for material components
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
    "& .super-app-theme--header": {
      backgroundColor: Constants.blue_color,
      fontSize: "16px",
      color: "rgb(255,255,255)",
      fontFamily: "nunito",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
    cursor: "pointer",
  },
}));

const programNames = {
  color: Constants.blue_color,
  fontSize: "14px",
  fontWeight: "300",
  "&:hover": {
    cursor: "pointer",
    transform: "scale(1.05)",
  },
};

const divprogramNames = {
  width: 80,
  color: Constants.blue_color,
  fontSize: "14px",
  fontWeight: "300",
  "&:hover": {
    cursor: "pointer",
    transform: "scale(1.05)",
  },
};

const header = {
  color: "white",
  fontSize: "14px",
  fontWeight: "bold",
};

const headers = [
  { label: "Session Name", key: "sessionname" },
  { label: "Session Id", key: "sessionid" },
  { label: "Date and Time", key: "sessiondate" },
  { label: "Interested Participants", key: "intpart" },
  { label: "Registered Participants", key: "resgisteredusers" },
  { label: "Waiting For Approval", key: "waitinglist" },
];
const funData = (data) => {
  console.log({ data });
  let datalist = data.map((item) => {
    let obj = {};
    obj.sessionname = item.sn;
    obj.sessionid = item.msid;
    if (
      item?.date_list &&
      item?.date_list.length > 0 &&
      item?.date_list?.[0]?.date
    ) {
      let sessiondate = "";
      item?.date_list.map((item) => {
        sessiondate = `
          ${moment(new Date(item?.combineStartTime)).format("DD/MM/YYYY")};
          ${moment(new Date(item?.combineStartTime)).format("LT")} to
          ${moment(new Date(item?.combineEndTime)).format("LT")}
        `;
        obj.sessiondate =
          obj?.sessiondate !== undefined
            ? obj?.sessiondate?.concat(sessiondate)
            : sessiondate;
      });
    } else if (item.startdate && item.enddate) {
      obj.sessiondate = `
        ${moment(new Date(parseInt(item.startdate)).getTime()).format(
          "DD-MM-YYYY"
        )} ;${moment(new Date(parseInt(item.startdate)).getTime()).format(
        "LT"
      )} to ${moment(new Date(parseInt(item.enddate)).getTime()).format("LT")}`;
    } else {
      obj.sessiondate = "Yet to de added.";
    }
    obj.intpart = item?.ei_count;
    obj.resgisteredusers = item.approved_count;
    obj.waitinglist = item.waiting_app;
    return obj;
  });
  return datalist;
};
export default function UspContent({ amount }) {
  const theme = useTheme();
  const query = useQueryParams();
  const history = useHistory();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [openAddLive, setOpenAddLive] = useState(false);
  const [value, setValue] = useState("1");
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  console.log(rowsPerPage);
  const [tabledata, setTableData] = useState([]);
  const [openList, setOpenList] = useState(false);
  const [loading, setLoading] = useState(true);
  const [editopen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [openApprove, setOpenApprove] = useState(false);
  const [assignOpen, setAssignOpen] = useState(false);
  const [waitingData, setWaitingData] = useState({});
  const [interestedData, setInterestedData] = useState({});
  const [dataToEdit, setDataToEdit] = useState({});
  const [deleteData, setDeleteData] = useState({});
  const [registerOpen, setRegisterOpen] = useState(false);
  const [sessionData, setSessionData] = useState({});
  const [assignData, setAssignData] = useState({});
  const [openPopular, setOpenPopular] = useState(false);
  const [openInterested, setOpenInterested] = useState(false);
  const [publishOpen, setPublishOpen] = useState(false);
  const [tenants, setTenants] = useState([]);
  const [sessionRowData, setSessionrowData] = useState({});
  const [listForReg, setListForReg] = useState({});
  const [openProforma, setOpenProforma] = useState(false);
  const [proformaData, setProformaData] = useState({});
  console.log(listForReg);
  const [speakerList, setSpeakerList] = useState([]);
  let userDetails = useSelector(authData);
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [searchCourse, setSearchCourse] = useState("");
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [rows, setRows] = useState([]);
  console.log(rows);
  const [columns, setColumns] = useState([]);
  const [dataToEditS, setDataToEditS] = useState({});
  const [dataToDelete, setDataToDelete] = useState({});
  const [EditSpeakerOpen, setEditSpeakerOpen] = useState(false);
  const [deleteSpeaker, setDeleteSpeaker] = useState(false);
  // const [loading, setLoading] = useState(true);
  const [spin, setSpin] = useState(false);
  const [addSpeakerOpen, setAddSpeakerOpen] = useState(false);

  const [asc, setAsc] = useState(false);
  const [data, setData] = useState([]);
  console.log({ data });

  const [openPopularCourse, setOpenPopularCourse] = useState(false);
  const handleOpenPopularCourse = () => setOpenPopularCourse(true);
  const handleClosePopularCourse = () => setOpenPopularCourse(false);
  useEffect(() => {
    getSessionList();
    getTenants();
    SpeakerList();
    setpeakerTable();
    if (query.get("tab")) {
      history.push(`/uspcontent?tab=${query.get("tab")}`);
      setValue(query.get("tab"));
    } else {
      history.push(`/uspcontent?tab=${1}`);
      setValue(1);
    }
    return () => {};
  }, [value, query.get("tab")]);

  async function getTenants() {
    const bodyParam = {
      body: {
        tcount: true,
        oid: config.aws_org_id,
        schema: Constants.SCHEMA_NAME,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      // // console.log(bodyParam.body);
      const tenants = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_TENANTS,
        bodyParam
      );
      setTenants(tenants.body);
    } catch (error) {
      console.error(error);
    }
  }

  async function getSessionList() {
    let sessionApi, oid;
    if (userDetails.role === "Admin") {
      sessionApi = Constants.LIST_SESSION_ALL;
      oid = config.aws_org_id;
    } else {
      sessionApi = Constants.LIST_SESSION_TENANTS;
      oid = userDetails.locale;
    }
    setLoading(true);
    setSpin(true);
    const bodyParam = {
      body: {
        oid: oid,
        schema: Constants.SCHEMA_NAME,
        st_id: userDetails.st_id,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const list = await API.post(
        config.aws_cloud_logic_custom_name,
        sessionApi,
        bodyParam
      );
      console.log("list123", list.body);
      setData(funData(list.body));
      setTableData(list.body);
      setLoading(false);
      setSpin(false);
    } catch (error) {
      console.error(error);
    }
  }

  const filterTenants = tabledata.filter((tabledata) =>
    tabledata.sn.toLowerCase().includes(search.toLowerCase())
  );
  console.log({ filterTenants });

  async function SpeakerList() {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        action: "get",
        schema: Constants.SCHEMA_NAME,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const speakerData = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.SPEAKER_LIST,
        bodyParam
      );
      // // console.log("speaker", speakerData);

      const speakerslist = speakerData.body;
      setSpeakerList(speakerslist);
    } catch (error) {
      // // console.log("getUserError", error);
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
    history.push(`/uspcontent?tab=${newValue}`);
    window.localStorage.setItem("tab", newValue);
  };

  const handleOpenList = (item) => {
    setOpenList(true);
    setListForReg(item);
    // // console.log("item", item);
  };
  const handleCloseList = () => setOpenList(false);
  const handleOpenPopular = () => setOpenPopular(true);
  const handleClosePopular = () => setOpenPopular(false);
  function handleEditOpen(item) {
    setEditOpen(true);
    setDataToEdit(item);
  }
  const handleEditClose = () => {
    setEditOpen(false);
  };

  const handleRegistrationOpen = (item) => {
    setRegisterOpen(true);
    setSessionData(item);
  };
  const handleRegistrationClose = () => {
    setRegisterOpen(false);
    getSessionList();
  };
  const handleDeleteOpen = (item) => {
    setDeleteOpen(true);
    setDeleteData(item);
  };
  const handleDeleteClose = () => setDeleteOpen(false);
  const handleAddLiveOpen = () => setOpenAddLive(true);
  const handleAddLiveClose = () => setOpenAddLive(false);
  const handleOpenInterested = (item) => {
    setOpenInterested(true);
    setInterestedData(item);
  };
  const handleCloseInterested = () => setOpenInterested(false);

  const handleApproveUserOpen = (item) => {
    setOpenApprove(true);
    setWaitingData(item);
  };
  const handleProformaUserOpen = (item) => {
    setOpenProforma(true);
    setProformaData(item);
  };
  const handleApproveUserClose = () => setOpenApprove(false);
  const handleProformaUserClose = () => setOpenProforma(false);
  const handlePublishOpen = (item) => {
    // if (!(item?.date_list[0].combineStartTime || item.startdate)) {
    //   swal.fire("Oops!", "Please add session start date", "error");
    //   return;
    // }
    // if (
    //   item?.weblink === undefined ||
    //   item?.weblink === null ||
    //   item?.weblink.replace(/ +/g, " ") === ""
    // ) {
    //   swal.fire("Oops!", "Please add session Web link", "error");
    //   return;
    // }
    console.log("item===", item);
    setPublishOpen(true);
    setSessionrowData(item);
  };
  const handlePublishClose = () => setPublishOpen(false);
  const handleOpenAssign = (item) => {
    setAssignOpen(true);
    setAssignData(item);
  };
  const handleCloseAssign = () => setAssignOpen(false);

  /*  let tabledataa = [];
  tabledataa.push([
  ]); */

  function onSessionTitleClick(item) {
    let sdata = { ...userDetails };
    sdata.sessiondata = item;

    if (sdata.breadcrumb == undefined) {
      sdata.breadcrumb = [];
      let temp = [...sdata.breadcrumb];
      temp[0] = {
        name: "Content",
        path: "/uspcontent",
      };
      temp[1] = {
        name: item.sn,
        path: "/uspcontent/sessionView",
      };
      sdata.breadcrumb = temp;
    } else {
      let temp = [...sdata.breadcrumb];
      temp[0] = {
        name: "Content",
        path: "/uspcontent",
      };
      temp[1] = {
        name: item.sn,
        path: "/uspcontent/sessionView",
      };
      sdata.breadcrumb = temp;
    }
    dispatch(awsSignIn(sdata));
  }

  function onAddSessionClick() {
    let sdata = { ...userDetails };

    if (sdata.breadcrumb == undefined) {
      sdata.breadcrumb = [];
      let temp = [...sdata.breadcrumb];
      temp[0] = {
        name: "Content",
        path: "/uspcontent",
      };
      temp[1] = {
        name: "Course Creation",
        path: "/uspcontent/coursecreation",
      };
      sdata.breadcrumb = temp;
    } else {
      let temp = [...sdata.breadcrumb];
      temp[0] = {
        name: "Content",
        path: "/uspcontent",
      };
      temp[1] = {
        name: "Course Creation",
        path: "/uspcontent/coursecreation",
      };
      sdata.breadcrumb = temp;
    }
    dispatch(awsSignIn(sdata));
  }

  const registerCount = (item) => {
    console.log(item);
    if (item.REG === undefined) {
      item.REG = [];
    }
    let count = 0;

    for (let i = 0; i < item.REG.length; i++) {
      if (
        item.REG[i].aprstatus !== undefined &&
        item.REG[i].aprstatus === "approved"
      ) {
        count++;
      }
    }
    return (
      <TableCell style={{ width: "10%" }}>
        {/* <h3 style={programNames} onClick={() => handleOpenList(item)}>
          {item.reg_count}
        </h3> */}
        <h3 style={programNames} onClick={() => handleOpenList(item)}>
          {item.approved_count}
        </h3>
      </TableCell>
    );
  };
  const proformaCount = (item) => {
    console.log(item);
    if (item.REG === undefined) {
      item.REG = [];
    }
    let count = 0;

    for (let i = 0; i < item.REG.length; i++) {
      if (item.REG[i].aprstatus !== undefined && item.REG[i].aprstatus === 0) {
        count++;
      }
    }
    return (
      <TableCell style={{ width: "10%" }}>
        {/* <h3 style={programNames} onClick={() => handleOpenList(item)}>
          {item.reg_count}
        </h3> */}
        <h3 style={programNames} onClick={() => handleProformaUserOpen(item)}>
          {item.waiting_proforma}
        </h3>
      </TableCell>
    );
  };
  const WaitingCount = (item) => {
    if (item.REG === undefined) {
      item.REG = [];
    }
    let count = 0;

    for (let i = 0; i < item.REG.length; i++) {
      if (
        item.REG[i].aprstatus == undefined /* ||
        item.WLIST[i].aprstatus !== "approved" */
      ) {
        count++;
      }
    }
    return (
      <TableCell style={{ width: "10%" }}>
        {/*  <h3 style={programNames} onClick={() => handleApproveUserOpen(item)}>
          {item.wl_count}
        </h3> */}
        <h3 style={programNames} onClick={() => handleApproveUserOpen(item)}>
          {item.waiting_app}
        </h3>
      </TableCell>
    );
  };

  function regiButton(item) {
    return (
      <button
        className={`${
          item?.reg_start == null || item?.reg_start === false
            ? contentStyle.addbuttongreen
            : contentStyle.addbutton
        }`}
        onClick={() => handleRegistrationOpen(item)}
      >
        {item?.reg_start == null || item?.reg_start === false
          ? "Start Registration"
          : "End Registration"}
      </button>
    );
  }

  function Tableview() {
    // // console.log("tabledata" + JSON.stringify(tabledata));
    return (
      <TableBody>
        {(rowsPerPage > 0
          ? filterTenants?.slice(
              page * rowsPerPage,
              page * rowsPerPage + rowsPerPage
            )
          : filterTenants
        ).map((item, index) => {
          return (
            <TableRow>
              <TableCell style={{ width: "5%" }}>
                {" "}
                <div style={{ position: "relative" }}>
                  <img
                    style={{
                      width: "150px",
                      height: "80px",
                      borderRadius: "10px",
                    }}
                    src={`https://${
                      config.DOMAIN
                    }/${config.aws_org_id.toLowerCase()}-resources/images/session-images/${
                      item?.timgname
                    }`}
                    alt=""
                  />
                  <h2
                    className={
                      item?.stype === 1
                        ? contentStyle.badgeonline
                        : item?.stype === 2
                        ? contentStyle.badgeoffline
                        : contentStyle.badgehybrid
                    }
                  >
                    {item?.stype === 1
                      ? "Live Web"
                      : item?.stype === 2
                      ? "Classroom"
                      : "Hybrid"}
                  </h2>
                </div>
              </TableCell>
              <TableCell
                style={{ width: "10%" }}
                onClick={() => onSessionTitleClick(item)}
              >
                <Link to="/uspcontent/sessionView">
                  <h3 style={programNames}>
                    {item?.sn?.length >= 45
                      ? item?.sn?.substring(0, 45) + "..."
                      : item?.sn}
                  </h3>
                </Link>
              </TableCell>
              <TableCell style={{ width: "10%" }}>
                <h3 style={programNames}>
                  {item?.msid?.length >= 15
                    ? item?.msid?.substring(0, 15) + "..."
                    : item?.msid}
                </h3>
              </TableCell>
              <TableCell style={{ width: "200px" }}>
                {item.date_list != null &&
                item.date_list.length > 0 &&
                item?.date_list[0].combineStartTime &&
                item?.date_list[0].date !== "" &&
                item?.date_list[0].combineEndTime ? (
                  <div style={{ width: "230px" }}>
                    {item?.date_list?.map((item, idx) => (
                      <p>
                        {`${moment(
                          new Date(item?.combineStartTime).getTime()
                        ).format("DD-MM-YYYY")}`}
                        ;<span style={{ marginLeft: "5px" }}></span>
                        {`${moment(
                          new Date(item?.combineStartTime).getTime()
                        ).format("LT")}`}
                        <span style={{ marginLeft: "5px" }}></span>
                        to
                        <span style={{ marginLeft: "5px" }}></span>
                        {`${moment(
                          new Date(item?.combineEndTime).getTime()
                        ).format("LT")}`}
                      </p>
                    ))}
                  </div>
                ) : item.startdate && item.enddate ? (
                  <p>
                    {`${moment(parseInt(item.startdate)).format("DD-MM-YYYY")}`}
                    ;<span style={{ marginLeft: "5px" }}></span>
                    {`${moment(parseInt(item.startdate)).format("LT")}`}
                    <span style={{ marginLeft: "5px" }}></span>
                    to
                    <span style={{ marginLeft: "5px" }}></span>
                    {`${moment(parseInt(item.enddate)).format("LT")}`}
                  </p>
                ) : (
                  <h4 style={programNames}>Yet to be added</h4>
                )}
              </TableCell>
              {userDetails.role === "Admin" ? (
                userDetails.role === "Manager" ? null : (
                  <TableCell
                    style={{ width: "5%" }}
                    onClick={() => handleOpenInterested(item)}
                  >
                    <h3 style={programNames}>
                      {item.ei_count === null || item.ei_count === undefined
                        ? 0
                        : item.ei_count}
                    </h3>
                  </TableCell>
                )
              ) : null}
              {proformaCount(item)}
              {registerCount(item)}
              {WaitingCount(item)}
              {userDetails.role === "Admin" ? (
                userDetails.role === "Manager" ? null : (
                  <TableCell style={{ width: "5%" }}>
                    <button
                      className={
                        item.tenants_id === undefined ||
                        item.tenants_id === null ||
                        item.tenants_id?.length === 0
                          ? contentStyle.addbutton
                          : contentStyle.addbuttongreen
                      }
                      onClick={() => handlePublishOpen(item)}
                      // disabled={
                      //   item?.date_list[0].combineStartTime || item.startdate
                      //     ? false
                      //     : true
                      // }
                    >
                      Publish/
                      <br />
                      Unpublish
                    </button>
                  </TableCell>
                )
              ) : null}
              {userDetails.role === "Admin" ? (
                userDetails.role === "Manager" ? null : (
                  <TableCell style={{ width: "5%" }}>
                    <h3 style={programNames}>
                      {item.tenants_id === undefined || item.tenants_id === null
                        ? 0
                        : item.tenants_id.length}
                    </h3>
                  </TableCell>
                )
              ) : null}
              {userDetails.role === "Manager" ? (
                <TableCell style={{ width: "15%" }}>
                  {new Date(
                    // item?.date_list[item?.date_list?.length - 1]?.combineEndTime
                    item?.date_list?.[0]?.combineEndTime
                  ).getTime() < new Date().getTime() ? (
                    <h4 style={{ color: "#228C22" }}>Session Completed</h4>
                  ) : item.reg_start === null || item.reg_start === false ? (
                    item.regdate !== null || item.regdate !== "" ? (
                      parseInt(item.regdate) < new Date().getTime() ? (
                        <h4 style={{ fontWeight: "500", color: "green" }}>
                          Registration Started
                        </h4>
                      ) : (
                        <h4 style={{ fontWeight: "500", color: "red" }}>
                          Registration yet to start
                        </h4>
                      )
                    ) : (
                      <h4 style={{ fontWeight: "500", color: "red" }}>
                        Registration yet to start
                      </h4>
                    )
                  ) : (
                    <h4 style={{ fontWeight: "500", color: "green" }}>
                      Registration Started
                    </h4>
                  )}
                </TableCell>
              ) : (
                <TableCell style={{ width: "15%" }}>
                  {item?.date_list != null && item?.date_list !== undefined ? (
                    <>{regiButton(item)}</>
                  ) : (
                    "Add Session Date"
                  )}
                </TableCell>
              )}
              {userDetails.role === "Admin" ? (
                userDetails.role === "Manager" ? null : item.tenants_id ===
                    undefined ||
                  item?.tenants_id === null ||
                  item?.tenants_id?.length === 0 ? (
                  <TableCell style={{ width: "5%" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "15px",
                        flexDirection: "column",
                      }}
                    >
                      <Edit
                        className={contentStyle.icons}
                        onClick={() => handleEditOpen(item)}
                        // setDataToEdit(item)
                      />
                      <Delete
                        className={contentStyle.icons}
                        onClick={() => handleDeleteOpen(item)}
                      />
                    </div>
                  </TableCell>
                ) : (
                  <TableCell style={{ width: "5%" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "15px",
                        flexDirection: "column",
                      }}
                    >
                      <EditGrey
                        style={{ cursor: "not-allowed" }}
                        //className={contentStyle.icons}
                        //onClick={() => handleEditOpen(item)}
                        // setDataToEdit(item)
                      />
                      <DeleteGrey
                        style={{ cursor: "not-allowed" }}
                        //className={contentStyle.icons}
                        //onClick={() => handleDeleteOpen(item)}
                      />
                    </div>
                  </TableCell>
                )
              ) : null}
              {userDetails.role === "Manager" ? (
                <TableCell style={{ width: "15%" }}>
                  {parseInt(item.enddate) < new Date().getTime() ? (
                    <button
                      className={contentStyle.disabledbutton}
                      onClick={() => handleOpenAssign(item)}
                      disabled
                    >
                      Assign users
                    </button>
                  ) : item.reg_start === null || item.reg_start === false ? (
                    item.regdate !== null || item.regdate !== "" ? (
                      parseInt(item.regdate) < new Date().getTime() ? (
                        <button
                          className={contentStyle.addbutton}
                          onClick={() => handleOpenAssign(item)}
                        >
                          Assign users
                        </button>
                      ) : (
                        <button
                          className={contentStyle.disabledbutton}
                          onClick={() => handleOpenAssign(item)}
                          disabled
                        >
                          Assign users
                        </button>
                      )
                    ) : (
                      <button
                        className={contentStyle.disabledbutton}
                        onClick={() => handleOpenAssign(item)}
                        disabled
                      >
                        Assign users
                      </button>
                    )
                  ) : (
                    <button
                      className={contentStyle.addbutton}
                      onClick={() => handleOpenAssign(item)}
                    >
                      Assign users
                    </button>
                  )}
                </TableCell>
              ) : null}
            </TableRow>
          );
        })}
      </TableBody>
    );
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //Speaker

  const handleAddSpeakersOpen = () => setAddSpeakerOpen(true);
  const handleAddSpeakersClose = () => setAddSpeakerOpen(false);

  async function setpeakerTable() {
    setLoading(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        action: "get",
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const speakerData = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.SPEAKER_LIST,
        bodyParam
      );
      // // console.log("speaker", speakerData);

      const speakerslist = speakerData.body;
      let trows = [];
      for (let i = 0; i < speakerslist.length; i++) {
        let obj = {};
        obj.id = i;
        obj.speakerImage = speakerslist[i].timgname;
        obj.speakerName = speakerslist[i].sp_name;
        obj.desg = speakerslist[i].designation;
        obj.sessionCount = speakerslist[i].speaker_sessioncount;
        obj.params = speakerslist[i];

        trows.push(obj);
      }
      const tcolumns = [
        {
          field: "speakerImage",
          headerName: "Speaker Image",
          flex: 1,
          headerClassName: "super-app-theme--header",
          disableExport: true,
          renderCell: (params) => (
            <img
              alt=""
              src={`https://${
                config.DOMAIN
              }/${config.aws_org_id.toLowerCase()}-resources/images/speaker-images/${
                params.value
              }`}
              style={{ width: "150px", height: "120px", borderRadius: "10px" }}
            />
          ),
        },
        {
          field: "speakerName",
          headerName: "Speaker Name",
          flex: 1,
          headerClassName: "super-app-theme--header",
          width: 400,
        },
        {
          field: "desg",
          headerName: "Speaker Designation",
          flex: 1,
          headerClassName: "super-app-theme--header",
          width: 400,
        },
        {
          field: "sessionCount",
          headerName: "Count of sessions assigned to",
          flex: 1,
          headerClassName: "super-app-theme--header",
          width: 400,
        },
        {
          field: "params",
          headerName: "Edit/Delete",
          flex: 1,
          headerClassName: "super-app-theme--header",
          disableExport: true,
          renderCell: (params) => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
                flexDirection: "column",
              }}
            >
              <Edit
                className={contentStyle.icons}
                onClick={() => {
                  handleEditSpeakersOpen(params.value);
                  // // console.log("params", params);
                }}
              />
              {params.value.speaker_sessioncount === 0 ? (
                <Delete
                  className={contentStyle.icons}
                  onClick={() => handledeleteSpeakersOpen(params.value)}
                />
              ) : (
                <DeleteGrey className={contentStyle.disicons} />
              )}
            </div>
          ),
        },
      ];
      setRows(trows);
      setColumns(tcolumns);
      setLoading(false);
    } catch (error) {
      // // console.log("getUserError", error);
    }
  }

  function handleEditSpeakersClose() {
    setEditSpeakerOpen(false);
  }
  function handleEditSpeakersOpen(params) {
    // // console.log("params", params);
    setDataToEditS(params);
    setEditSpeakerOpen(true);
  }
  function handledeleteSpeakersClose() {
    setDeleteSpeaker(false);
  }
  function handledeleteSpeakersOpen(params) {
    // // console.log("params", params);
    setDataToDelete(params);
    setDeleteSpeaker(true);
  }

  const sortColumn = (sortKey) => () => {
    const newFilterTenants = [...filterTenants];
    setAsc(!asc);
    // // console.log(sortKey);
    // // console.log(newFilterTenants);
    newFilterTenants.sort((a, b) => {
      let valueA;
      let valueB;
      if (sortKey === "combineStartTime") {
        if (
          b["date_list"] &&
          a["date_list"] &&
          a["date_list"].length > 0 &&
          a["date_list"][0][sortKey] &&
          a["date_list"][0].date &&
          b["date_list"][0][sortKey] &&
          b["date_list"].length > 0 &&
          b["date_list"][0].date
        ) {
          valueA = a["date_list"][0][sortKey];
          valueB = b["date_list"][0][sortKey];
        } else if (a.startdate && b.startdate) {
          valueA = a.startdate;
          valueB = b.startdate;
        }
      } else {
        valueA = a[sortKey];
        valueB = b[sortKey];
      }

      if (asc) {
        if (valueA < valueB) {
          return -1;
        }
        if (valueA > valueB) {
          return 1;
        }
        // a must be equal to b
        return 0;
      } else {
        if (valueA > valueB) {
          return -1;
        }
        if (valueA < valueB) {
          return 1;
        }
        // a must be equal to b
        return 0;
      }
    });
    setData(funData(newFilterTenants));
    setTableData(newFilterTenants);
  };

  return (
    <div className={contentStyle.main}>
      <div className={contentStyle.topbar}>
        <h3 className={contentStyle.heading}> </h3>
        {value === "1" ? (
          userDetails.role === "Admin" ? (
            userDetails.role === "Manager" ? null : (
              <div className={contentStyle.buttonholder}>
                <div className={contentStyle.searchholder}>
                  <input
                    type="text"
                    className={contentStyle.searchinput}
                    placeholder="Search Session"
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  ></input>
                  <Search />
                </div>
                <button
                  className={contentStyle.addbutton}
                  onClick={handleOpenPopular}
                >
                  Mark Popular
                </button>
                <button
                  className={contentStyle.addbutton}
                  onClick={handleAddLiveOpen}
                >
                  Add Session
                </button>
              </div>
            )
          ) : null
        ) : value === "2" ? (
          <button
            className={contentStyle.addbutton}
            onClick={handleAddSpeakersOpen}
          >
            Add Speaker
          </button>
        ) : (
          value === "3" &&
          userDetails.role === "Admin" && (
            <>
              <div className={contentStyle.buttonholder}>
                <div className={contentStyle.searchholder}>
                  <input
                    type="text"
                    className={contentStyle.searchinput}
                    placeholder="Search Course"
                    onChange={(e) => {
                      setSearchCourse(e.target.value);
                    }}
                  ></input>
                  <Search />
                </div>
                <button
                  className={contentStyle.addbutton}
                  onClick={handleOpenPopularCourse}
                >
                  Mark Popular
                </button>
                <Link to="/uspcontent/coursecreation">
                  <button
                    className={contentStyle.addbutton}
                    onClick={() => onAddSessionClick()}
                  >
                    Add Course
                  </button>
                </Link>
              </div>
            </>
          )
        )}
      </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openAddLive}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openAddLive}>
          <SessionAdd
            handleAddLiveClose={handleAddLiveClose}
            getSessionList={getSessionList}
            speakerList={speakerList}
            sessionList={filterTenants}
            setSpin={setSpin}
            spin={spin}
          />
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openInterested}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openInterested}>
          <InterestedUsers
            handleCloseInterested={handleCloseInterested}
            interestedData={interestedData}
            tenantoid={false}
            tenant={""}
          />
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={assignOpen}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={assignOpen}>
          <AssignUsers
            handleCloseAssign={handleCloseAssign}
            assignData={assignData}
            getSessionList={getSessionList}
          />
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openPopular}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openPopular}>
          <MarkPopular
            handleClosePopular={handleClosePopular}
            tabledata={tabledata}
          />
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openApprove}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openApprove}>
          <ApproveUsers
            handleApproveUserClose={handleApproveUserClose}
            waitingData={waitingData}
            getSessionList={getSessionList}
            tenantoid={false}
            tenant={""}
          />
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={registerOpen}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={registerOpen}>
          <RegistrationConfirmation
            handleRegistrationClose={handleRegistrationClose}
            sessionData={sessionData}
            getSessionList={getSessionList}
          />
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={editopen}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={editopen}>
          <EditSession
            handleEditClose={handleEditClose}
            dataToEdit={dataToEdit}
            getSessionList={getSessionList}
            speakerList={speakerList}
          />
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openList}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openList}>
          <RegisteredUsers
            handleCloseList={handleCloseList}
            listForReg={listForReg}
            tenantoid={false}
            tenant={""}
            amount={amount}
          />
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openProforma}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openProforma}>
          <ProformaUsers
            handleProformaUserClose={handleProformaUserClose}
            proformaData={proformaData}
            getSessionList={getSessionList}
            tenantoid={false}
            tenant={""}
          />
        </Fade>
      </Modal>
      <Dialog
        fullScreen={fullScreen}
        open={deleteOpen}
        aria-labelledby="responsive-dialog-title-2"
        maxWidth="md"
      >
        <DeleteSession
          handleDeleteClose={handleDeleteClose}
          deleteData={deleteData}
          getSessionList={getSessionList}
          filterTenants={filterTenants}
        />
      </Dialog>

      <Dialog
        fullScreen={fullScreen}
        open={publishOpen}
        aria-labelledby="responsive-dialog-title-2"
        maxWidth="md"
      >
        <Publish
          tenants={tenants}
          sessionRowData={sessionRowData}
          handlePublishClose={handlePublishClose}
          getSessionList={getSessionList}
        />
      </Dialog>
      <BackDrop
        sx={{ color: "#fff" }}
        open={loading}
        className={classes.backdrop}
      >
        <CircularProgress color="inherit" />
      </BackDrop>
      <Paper>
        <div className={contentStyle.tabsholder}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                indicatorColor="primary"
                textColor="inherit"
                variant="fullWidth"
              >
                <Tab label="All sessions" value="1" />

                {userDetails.role === "Admin" ? (
                  userDetails.role === "Manager" ? null : (
                    <Tab label="Speakers" value="2" />
                  )
                ) : null}
                <Tab label="On Demand Courses" value="3" />
              </Tabs>
            </Box>

            <TabPanel value="1">
              <Box
                sx={{
                  outline: "1px solid rgba(119, 119, 119, 0.2)",
                  borderRadius: "5px",
                  paddingTop: "10px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {filterTenants.length <= 0 ? (
                  <div
                    style={{
                      height: "50vh",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      marginBottom: "70px",
                    }}
                  >
                    <img src={NothiongHereYet} alt="Nothing Here" />
                  </div>
                ) : (
                  <>
                    <Box sx={{ display: "flex" }}>
                      <Box sx={{ flexGrow: 1 }} />
                      <Button color="primary">
                        <CSVLink
                          data={data}
                          headers={headers}
                          filename="session-details"
                          className={listTopStyle.exp_btn}
                        >
                          <FileDownload />
                          Export
                        </CSVLink>
                      </Button>
                    </Box>
                    <TableContainer style={{ height: "410px" }}>
                      <Table>
                        <TableHead style={{ background: "#285780" }}>
                          <TableRow>
                            <TableCell style={header}> Image</TableCell>

                            <TableCell
                              style={{
                                color: "white",
                                fontSize: "14px",
                                fontWeight: "bold",
                                width: "20%",
                              }}
                              className={contentStyle.hoverCell}
                              onClick={sortColumn("sn")}
                            >
                              Session Title
                              <span className={contentStyle.hidden}>
                                {asc ? (
                                  <ArrowDownwardIcon />
                                ) : (
                                  <ArrowUpwardIcon />
                                )}
                              </span>
                            </TableCell>
                            <TableCell
                              style={{
                                color: "white",
                                fontSize: "14px",
                                fontWeight: "bold",
                                width: "20%",
                              }}
                              className={contentStyle.hoverCell}
                              onClick={sortColumn("msid")}
                            >
                              Session Id
                              <span className={contentStyle.hidden}>
                                {asc ? (
                                  <ArrowDownwardIcon />
                                ) : (
                                  <ArrowUpwardIcon />
                                )}
                              </span>
                            </TableCell>
                            <TableCell
                              style={header}
                              className={contentStyle.hoverCell}
                              onClick={sortColumn("combineStartTime")}
                            >
                              Date and Time
                              <span className={contentStyle.hidden}>
                                {asc ? (
                                  <ArrowDownwardIcon />
                                ) : (
                                  <ArrowUpwardIcon />
                                )}
                              </span>
                            </TableCell>
                            {userDetails.role === "Admin" ? (
                              userDetails.role === "Manager" ? null : (
                                <TableCell style={header}>
                                  Interested Participants
                                </TableCell>
                              )
                            ) : null}
                            <TableCell style={header}>
                              Proforma waiting Participants
                            </TableCell>
                            <TableCell style={header}>
                              Registered Participants
                            </TableCell>

                            <TableCell style={header}>
                              Waiting for approval
                            </TableCell>
                            {userDetails.role === "Admin" ? (
                              userDetails.role === "Manager" ? null : (
                                <TableCell style={header}>Publish</TableCell>
                              )
                            ) : null}
                            {userDetails.role === "Admin" ? (
                              userDetails.role === "Manager" ? null : (
                                <TableCell style={header}>
                                  Organization Published
                                </TableCell>
                              )
                            ) : null}
                            <TableCell style={header}>
                              Start registration
                            </TableCell>
                            {userDetails.role === "Admin" ? (
                              userDetails.role === "Manager" ? null : (
                                <TableCell></TableCell>
                              )
                            ) : null}
                            {userDetails.role === "Manager" ? (
                              <TableCell style={header}>
                                Add Participants
                              </TableCell>
                            ) : null}
                          </TableRow>
                        </TableHead>
                        {spin ? null : <Tableview />}
                        <TableFooter>
                          <TableRow>
                            <TablePagination
                              rowsPerPageOptions={[
                                5,
                                10,
                                25,
                                { label: "All", value: -1 },
                              ]}
                              colSpan={11}
                              count={
                                tabledata.length === undefined
                                  ? 0
                                  : tabledata.length
                              }
                              rowsPerPage={rowsPerPage}
                              page={page}
                              SelectProps={{
                                inputProps: { "aria-label": "rows per page" },
                                native: true,
                              }}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </TableContainer>
                  </>
                )}
              </Box>
            </TabPanel>
            <TabPanel value="2">
              <div style={{ width: "100%" }}>
                {/*  <TableContainer component={Paper}>
                  <Table>
                    <TableHead style={{ background: "#285780" }}>
                      <TableRow>
                        <TableCell style={header}> Image</TableCell>
                        <TableCell style={header}>Name</TableCell>
                        <TableCell style={header}>Date and Time</TableCell>
                        <TableCell style={header}>Duration</TableCell>

                        <TableCell style={header}>Location</TableCell>
                        <TableCell style={header}>Instructor</TableCell>
                      </TableRow>
                    </TableHead>

                    {tableviewInPerson()}
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[
                            5,
                            10,
                            25,
                            { label: "All", value: -1 },
                          ]}
                          colSpan={3}
                          count={tabledataInperson.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          SelectProps={{
                            inputProps: { "aria-label": "rows per page" },
                            native: true,
                          }}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer> */}
                {/* <SpeakersGrid /> */}
                {loading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Skeleton
                      variant="rect"
                      width="100%"
                      height={300}
                      style={{ marginBottom: "40px" }}
                    />{" "}
                  </div>
                ) : (
                  <div
                    style={{ height: 450, width: "100%", overflowY: "auto" }}
                    className={classes.root}
                  >
                    {rows.length <= 0 ? (
                      <div
                        style={{
                          height: "50vh",
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <img src={NothiongHereYet} alt="Nothing Here" />
                      </div>
                    ) : (
                      <DataGrid
                        rows={rows}
                        columns={columns}
                        rowHeight={150}
                        className={classes[".MuiDataGrid-root"]}
                        components={{
                          Toolbar: CustomToolbar,
                        }}
                        disableSelectionOnClick
                      />
                    )}
                  </div>
                )}
                <Dialog
                  fullScreen={fullScreen}
                  open={EditSpeakerOpen}
                  aria-labelledby="responsive-dialog-title-2"
                  maxWidth="md"
                >
                  <EditSpeaker
                    handleEditSpeakersClose={handleEditSpeakersClose}
                    dataToEditS={dataToEditS}
                    setpeakerTable={setpeakerTable}
                    setLoading={setLoading}
                    loading={loading}
                  />
                </Dialog>
                <Dialog
                  fullScreen={fullScreen}
                  open={deleteSpeaker}
                  aria-labelledby="responsive-dialog-title-2"
                  maxWidth="md"
                >
                  <DeleteSpeaker
                    handledeleteSpeakersClose={handledeleteSpeakersClose}
                    dataToDelete={dataToDelete}
                    setpeakerTable={setpeakerTable}
                  />
                </Dialog>
                <Dialog
                  fullScreen={fullScreen}
                  open={addSpeakerOpen}
                  aria-labelledby="responsive-dialog-title-2"
                  maxWidth="md"
                >
                  <AddSpeaker
                    handleAddSpeakersClose={handleAddSpeakersClose}
                    setpeakerTable={setpeakerTable}
                    setLoading={setLoading}
                    loading={loading}
                  />
                </Dialog>
              </div>
            </TabPanel>
            <TabPanel value="3">
              <ListOfTopics
                searchText={searchCourse}
                open={openPopularCourse}
                close={handleClosePopularCourse}
                listForReg={listForReg}
              />
            </TabPanel>
          </TabContext>
        </div>
      </Paper>
    </div>
  );
}
