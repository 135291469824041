// Dependencies import
import React, { useState, useEffect } from "react";
import { Constants } from "../../../config/Constants";
import config from "../../../config/aws-exports";
import { API } from "aws-amplify";
import { awsSignIn, authData } from "../../../redux/auth/authSlice";
import { useSelector, useDispatch } from "react-redux";
import swal from "@sweetalert/with-react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

// Local imports
import { ReactComponent as Close } from "../../../assets/icons/close_black_24dp.svg";

// Style imports
import Add from "./AddEvent.module.scss";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const EditEvents = (props) => {
  const classes = useStyles();

  let { handleCloseEdit, eventData, setevents, getEvents, EditData } = props;
  console.log("edit", EditData);
  const [state, setState] = useState(false);
  const [eventDate, setEventDate] = useState("");
  const [evnentName, setEventName] = useState("");
  const [eventLink, setEventLink] = useState("");
  const [eventtitle, setEventtitle] = useState("");
  const [eventId, setEventId] = useState("");
  const [eventDateErr, setEventDateErr] = useState("");
  const [eventNameErr, setEventNameErr] = useState("");
  const [spin, setSpin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState("");
  const [link, setLink] = useState(false);
  const [linkErr, setLinkErr] = useState("");
  const [eventTitleErr, setEventTitleErr] = useState("");

  let userDetails = useSelector(authData);

  const onChangeHandlerEventName = (event) => {
    setEventName(event.target.value);
  };
  const onChangeHandlerEventDate = (event) => {
    setEventDate(event.target.value);
  };
  const onChangeHandlerEventLink = (event) => {
    setEventLink(event.target.value);
  };
  const onChangeHandlerEventTitle = (event) => {
    setEventtitle(event.target.value);
  };
  useEffect(() => {
    setEventDate(convert(EditData.ev_date));
    setEventName(EditData.ev_desc);
    setEventtitle(EditData.ev_title);
    if (
      EditData.link === "undefined" ||
      EditData.link === undefined ||
      EditData.link === null
    ) {
      setLink(false);
      setUrl("");
    } else {
      setLink(true);
      setUrl(EditData.link);
    }
  }, []);

  function convert(timestamp) {
    let ts = parseInt(timestamp);
    var date_not_formatted = new Date(ts);

    var formatted_string = date_not_formatted.getFullYear() + "-";

    if (date_not_formatted.getMonth() < 9) {
      formatted_string += "0";
    }
    formatted_string += date_not_formatted.getMonth() + 1;
    formatted_string += "-";

    if (date_not_formatted.getDate() < 10) {
      formatted_string += "0";
    }
    formatted_string += date_not_formatted.getDate();
    formatted_string += "T";
    if (date_not_formatted.getHours() < 10) {
      formatted_string += "0";
    }
    formatted_string += date_not_formatted.getHours();
    formatted_string += ":";
    if (date_not_formatted.getMinutes() < 10) {
      formatted_string += "0";
    }
    formatted_string += date_not_formatted.getMinutes();
    console.log("sss", formatted_string);

    return formatted_string;
  }

  const onsubmit = () => {
    let regex =
      "^(https?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$";
    let result = new RegExp(regex, "i");
    // setname(name)
    let formIsValid = true;
    let neweid;
    setEventDateErr("");
    setEventNameErr("");
    setEventTitleErr("");
    if (eventtitle.trim() === "") {
      formIsValid = false;
      setEventTitleErr("*Please Enter Event Title");
      return;
    }
    if (eventDate === "") {
      formIsValid = false;
      setEventDateErr("Please Enter Date");
      return;
    }
    if (evnentName.trim() === "") {
      formIsValid = false;
      setEventNameErr("*Please Enter Event description");
      return;
    }

    if (evnentName.trim() != "" && evnentName.trim().length > 160) {
      formIsValid = false;
      setEventNameErr(
        "*Please Enter Event description length less than 160 character"
      );
      return;
    }
    if (link === true && (url === "" || !result.test(url))) {
      formIsValid = false;
      setLinkErr("Please enter valid url");
      return;
    }
    if (formIsValid === true) {
      if (eventData.length === 0 || eventData.length === undefined) {
        // setEventId(1);
        neweid = 1;
      } else {
        neweid = eventData[eventData.length - 1].eid + 1;
      }
      console.log("id", neweid);
      addEvent();
      setevents((prev) => [
        ...prev,
        {
          title: eventtitle,
          start: eventDate,
          description: evnentName,
          startTime: new Date(eventDate),
          endTime: new Date(eventDate),
        },
      ]);
    }
  };

  async function addEvent() {
    setLoading(true);
    // setSpin(true);
    let timestamp = new Date().getTime();
    var eventData = {
      atype: 1,
      evid: EditData.ev_id,
      ev_date: new Date(eventDate).getTime(),
      evtitle: eventtitle,
      ev_desc: evnentName,
      stnt_id: null,
      oid: config.aws_org_id,
      schema: Constants.SCHEMA_NAME,
      //link: eventLink,
    };
    if (link == true) {
      eventData.pr_link = url;
    } else if (link == false) {
      eventData.pr_link = null;
    }
    const bodyParam = {
      body: eventData,

      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.ADD_EVENTS,
        bodyParam

        //`${Constants.GET_MY_PROGRAMS}`, bodyParam,
      );
      console.log("res", response);
      setLoading(false);
      handleCloseEdit();
      getEvents();
      // const users = response;
      swal({
        title: "Success",
        text: "Event Edited successfully",
        icon: "success",
        dangerMode: false,
      }).then((willDelete) => {
        if (willDelete) {
          handleCloseEdit();
          getEvents();
        }
      });
      //setSpin(false);
      //getEvents();
    } catch (error) {
      swal({
        title: "Oooops!",
        text: "Soomthing wrong!",
        icon: "warning",
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          // setSpin(false);
        }
      });
      console.log("getUserError", error);
    }
    console.log("Mount");
  }
  function handleSave() {
    let one = {};
    one.title = eventtitle;
    one.desc = evnentName;
    one.da = eventDate;
    console.log("one", one);
  }
  function clicking(e) {
    if (e.target.value == "true") {
      setLink(!link);
    }
  }
  return (
    <div className={Add.mainpopup}>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className={Add.topbar}>
        <h3 className={Add.heading}>Edit Events</h3>
        <Close className={Add.closeicon} onClick={() => handleCloseEdit()} />
      </div>
      <div className={Add.forminput}>
        <div className={Add.inputblock}>
          <div className={Add.labelholder}>
            <label for="sessiondate" className={Add.label} required>
              Title
              <span style={{ color: "red", marginTop: "-10px" }}>*</span>
            </label>
          </div>
          <div className={Add.inputholder}>
            <input
              type="text"
              placeholder="Title"
              className={Add.inputfield}
              onChange={onChangeHandlerEventTitle}
              value={eventtitle}
            />
          </div>
        </div>
        <div
          style={{
            color: "#cc0000",
            marginBottom: "12px",
            marginTop: "-10px",
            textAlign: "start",
            marginLeft: "0%",
          }}
        >
          {eventTitleErr}
        </div>
        <div className={Add.inputblock}>
          <div className={Add.labelholder}>
            <label for="sessiondate" className={Add.label} required>
              Description
              <span style={{ color: "red", marginTop: "-10px" }}>*</span>
            </label>
          </div>
          <div className={Add.inputholder}>
            <textarea
              type="text"
              // placeholder="Description"
              className={Add.textfield}
              rows={6}
              onChange={onChangeHandlerEventName}
              value={evnentName}
            />
          </div>
        </div>
        <div
          style={{
            color: "#cc0000",
            marginBottom: "12px",
            marginTop: "-10px",
            textAlign: "start",
            marginLeft: "20%",
          }}
        >
          {eventNameErr}
        </div>
        <div className={Add.inputblock}>
          <div className={Add.labelholder}>
            <label for="sessiondate" className={Add.label} required>
              Select Date
              <span style={{ color: "red", marginTop: "-10px" }}>*</span>
            </label>
          </div>
          <div className={Add.inputholder}>
            <input
              type="datetime-local"
              //placeholder="Select Date"
              className={Add.inputfield}
              onChange={onChangeHandlerEventDate}
              value={eventDate}
            />
          </div>
        </div>

        <div
          style={{
            color: "#cc0000",
            marginBottom: "12px",
            marginTop: "-10px",
            textAlign: "start",
            marginLeft: "20%",
          }}
        >
          {eventDateErr}
        </div>
        <div
          style={{
            width: "65%",
            fontSize: "12px",
            fontWeight: "400",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "5px",
          }}
        >
          <input
            type="checkbox"
            value="true"
            onClick={(e) => clicking(e)}
            checked={link}
          />
          <lable>Include url</lable>
        </div>
        {/*  <span className={Add.radiolable}>Include link</span> */}
        {link ? (
          <div className={Add.inputblock}>
            <div className={Add.labelholder}>
              <label for="sessiondate" className={Add.label} required>
                Url
                <span style={{ color: "red", marginTop: "-10px" }}>*</span>
              </label>
            </div>
            <div className={Add.inputholder}>
              <input
                type="text"
                //placeholder="Url"
                className={Add.inputfield}
                value={url}
                onChange={(e) => {
                  setUrl(e.target.value);
                }}
              />
            </div>
          </div>
        ) : null}
        <div
          style={{
            color: "#cc0000",
            marginBottom: "12px",
            marginTop: "6px",
            textAlign: "start",
            marginLeft: "20%",
          }}
        >
          {linkErr}
        </div>
      </div>
      <div className={Add.savebutton}>
        <button className={Add.cancel} onClick={handleCloseEdit}>
          Cancel
        </button>
        <button className={Add.save} onClick={onsubmit}>
          Save
        </button>
      </div>
    </div>
  );
};

export default EditEvents;
