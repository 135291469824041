import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField } from "@mui/material";

import React from "react";

const CustomInput = ({
  placeholder,
  type,
  name,
  onChange,
  inputValues,
  value,
  handleClickShowPassword,
  handleMouseDownPassword,
  error,
  ...props
}) => (
  <>
  <TextField
    placeholder={placeholder}
    InputProps={{
      startAdornment: props.children && (
        <InputAdornment position="start">{props.children}</InputAdornment>
      ),
      endAdornment: type === "password" && (
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
          >
            {inputValues.showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>
      ),
      autoComplete: "off",
      style: { WebkitBoxShadow: "0 0 0 1000px white inset" },
    }}
    type={
      type === "password"
        ? inputValues.showPassword
          ? "text"
          : "password"
        : type
    }
    name={name}
    value={value}
    onChange={onChange}
    variant="standard"
    fullWidth
  />
  <p style={{fontSize: "12px", color: "red"}}>{error}</p>
  </>
  
);

export default CustomInput;
