import React from "react";

const EngageSvg = ({ className, cls1, cls2, cls3, cls4 }) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      className={className}
    >
      <defs></defs>

      {/* <path
        className={cls1}
        d="M70.3,53.2H47c-1,0-1.9-1.4-1.9-3.2s.8-3.2,1.9-3.2H70.3c1,0,1.9,1.4,1.9,3.2S71.4,53.2,70.3,53.2Z"
      /> */}
      {/* <path
        className={cls1}
        d="M51.5,21.5V49.9c0,1.3-1.4,2.3-3.2,2.3s-3.2-1-3.2-2.3V21.5c0-1.3,1.4-2.3,3.2-2.3S51.5,20.2,51.5,21.5Z"
      /> */}
      <path
        className={cls2}
        d="M85.16,48.39a28.69,28.69,0,0,0-7.72-16.48C69.24,23.23,59.07,20,47.36,21.77A31.13,31.13,0,0,0,27.53,33.09,29.29,29.29,0,0,0,21.17,58,28.68,28.68,0,0,0,26,69.31a1.36,1.36,0,0,1,.27,1.37q-1,2.55-2.12,5.1c-.5,1.19-1,2.37-1.52,3.57a2.87,2.87,0,0,0-.14,1,.72.72,0,0,0,.69.8,2.52,2.52,0,0,0,.82,0c1.14-.29,2.29-.57,3.41-.93,2.07-.66,4.12-1.41,6.2-2.07a2.71,2.71,0,0,1,2.3.32A34.11,34.11,0,0,0,53.82,83.1c.84-.07,2.54-.13,4.21-.38A32.23,32.23,0,0,0,73.69,76,31.6,31.6,0,0,0,82,66.07,29.17,29.17,0,0,0,85.16,48.39Z"
      />
      <path
        className={cls1}
        d="M53.43,68.39a3.33,3.33,0,0,1-3.59-1.33,2.93,2.93,0,0,1-.45-3.28L56,66A3.42,3.42,0,0,1,53.43,68.39Z"
      />
      <path
        className={cls1}
        d="M64.6,48.73c-1.14,3.57-2.34,7.12-3.52,10.68-.44,1.34-.18,2,1.1,2.6a1.8,1.8,0,0,1-1.36,3.33l-9.1-3L38.93,58.07a1.79,1.79,0,0,1,.59-3.52l.37.05a1.7,1.7,0,0,0,2.26-1.29c1.19-3.59,2.37-7.19,3.58-10.78a8.15,8.15,0,0,1,9.78-5.41.59.59,0,0,0,.77-.26,1.72,1.72,0,0,1,2-.68A1.78,1.78,0,0,1,59.51,38c0,.37.09.5.41.64A8.13,8.13,0,0,1,64.6,48.73Z"
      />
    </svg>
  );
};

export default EngageSvg;

{
  /* <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><defs><style>.cls-1{fill:#fff;}.cls-2{fill:#777;stroke:#777;stroke-miterlimit:10;stroke-width:2.53px;}</style></defs><path class="cls-1" d="M70.3,53.2H47c-1,0-1.9-1.4-1.9-3.2s.8-3.2,1.9-3.2H70.3c1,0,1.9,1.4,1.9,3.2S71.4,53.2,70.3,53.2Z"/><path class="cls-1" d="M51.5,21.5V49.9c0,1.3-1.4,2.3-3.2,2.3s-3.2-1-3.2-2.3V21.5c0-1.3,1.4-2.3,3.2-2.3S51.5,20.2,51.5,21.5Z"/><path class="cls-2" d="M85.16,48.39a28.69,28.69,0,0,0-7.72-16.48C69.24,23.23,59.07,20,47.36,21.77A31.13,31.13,0,0,0,27.53,33.09,29.29,29.29,0,0,0,21.17,58,28.68,28.68,0,0,0,26,69.31a1.36,1.36,0,0,1,.27,1.37q-1,2.55-2.12,5.1c-.5,1.19-1,2.37-1.52,3.57a2.87,2.87,0,0,0-.14,1,.72.72,0,0,0,.69.8,2.52,2.52,0,0,0,.82,0c1.14-.29,2.29-.57,3.41-.93,2.07-.66,4.12-1.41,6.2-2.07a2.71,2.71,0,0,1,2.3.32A34.11,34.11,0,0,0,53.82,83.1c.84-.07,2.54-.13,4.21-.38A32.23,32.23,0,0,0,73.69,76,31.6,31.6,0,0,0,82,66.07,29.17,29.17,0,0,0,85.16,48.39Z"/><path class="cls-1" d="M53.43,68.39a3.33,3.33,0,0,1-3.59-1.33,2.93,2.93,0,0,1-.45-3.28L56,66A3.42,3.42,0,0,1,53.43,68.39Z"/><path class="cls-1" d="M64.6,48.73c-1.14,3.57-2.34,7.12-3.52,10.68-.44,1.34-.18,2,1.1,2.6a1.8,1.8,0,0,1-1.36,3.33l-9.1-3L38.93,58.07a1.79,1.79,0,0,1,.59-3.52l.37.05a1.7,1.7,0,0,0,2.26-1.29c1.19-3.59,2.37-7.19,3.58-10.78a8.15,8.15,0,0,1,9.78-5.41.59.59,0,0,0,.77-.26,1.72,1.72,0,0,1,2-.68A1.78,1.78,0,0,1,59.51,38c0,.37.09.5.41.64A8.13,8.13,0,0,1,64.6,48.73Z"/></svg> */
}
