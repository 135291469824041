import { Box } from "@mui/system";
import React from "react";
import CustomInput from "../CustomInput/CustomInput";
import {
  inputBoxStyle,
  inputIconStyle,
  displayCenter,
} from "./SignInAndSignUp";
import CodeIcon from "@mui/icons-material/Code";

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { CustomButton } from "../CustomButton/CustomButton";
import { CircularProgress, Typography } from "@mui/material";
import { HeadingPrimary } from "../Styles/Heading";

import ReactCodeInput from "react-verification-code-input";
const ResetPassword = ({
  resetPasswordOpenFirst,
  passwordResetFC,
  resetPasswordFC,
  resetPasswordLoader,
  emailVerification,
  setValues,
  values,
  handleChange,
  handleClickShowPassword,
  handleMouseDownPassword,
  errors,
  setErrors,
  setSignInForm,
  setResetPasswordOpenFirst,
  setForgotPassword,
  setEmailVerification,
}) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    if (emailVerification) {
      passwordResetFC(event);
    } else {
      resetPasswordFC(event);
    }
  };

  console.log("emailvar", emailVerification);
  const handleClose = () => {
    setSignInForm(true);
    setEmailVerification(false);
    setForgotPassword(false);
    //  setResetPasswordOpenFirst(false);
  };

  return (
    <React.Fragment>
      <Box sx={{ textAlign: "center" }}>
        <HeadingPrimary>Password Reset</HeadingPrimary>
      </Box>
      {emailVerification && (
        <Box sx={inputBoxStyle}>
          <CustomInput
            placeholder="verificationCode"
            name="verificationCode"
            inputValues={values}
            value={values.verificationCode || ""}
            error={errors?.verificationCode}
            onChange={handleChange("verificationCode")}
          >
            <CodeIcon sx={inputIconStyle} />
          </CustomInput>
        </Box>
      )}
      {!emailVerification && (
        <Box sx={inputBoxStyle}>
          <CustomInput
            placeholder="Name"
            name="name"
            type="text"
            inputValues={values}
            value={values.name || ""}
            error={errors.name}
            handleClickShowPassword={handleClickShowPassword}
            handleMouseDownPassword={handleMouseDownPassword}
            onChange={handleChange("name")}
          >
            <LockOutlinedIcon sx={inputIconStyle} />
          </CustomInput>
        </Box>
      )}

      <Box sx={inputBoxStyle}>
        <CustomInput
          placeholder="Password"
          name="resetPassword"
          type="password"
          inputValues={values}
          value={values.resetPassword || ""}
          error={errors.resetPassword}
          handleClickShowPassword={handleClickShowPassword}
          handleMouseDownPassword={handleMouseDownPassword}
          onChange={handleChange("resetPassword")}
        >
          <LockOutlinedIcon sx={inputIconStyle} />
        </CustomInput>
      </Box>
      <Box sx={inputBoxStyle}>
        <CustomInput
          placeholder="Confirm Password"
          type="password"
          name="confirmPassword"
          inputValues={values}
          value={values.confirmPassword || ""}
          error={errors.passwordConfirm}
          onChange={handleChange("confirmPassword")}
          handleClickShowPassword={handleClickShowPassword}
          handleMouseDownPassword={handleMouseDownPassword}
        >
          <LockOutlinedIcon sx={inputIconStyle} />
        </CustomInput>
      </Box>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          variant="caption"
          display="block"
          sx={{
            maxWidth: { xs: 300, sm: 450 },
            textAlign: "center",
          }}
          gutterBottom
        >
          *Password should be at least 8 characters, contain at least one
          number,one lowercase letter & one special character.
        </Typography>
      </Box>

      <Box sx={{ textAlign: "center" }}>
        {resetPasswordLoader ? (
          <CustomButton disabled loading="true">
            <CircularProgress color="inherit" size={20} />
          </CustomButton>
        ) : (
          <>
            <CustomButton primary onClick={handleClose}>
              Cancel
            </CustomButton>
            <CustomButton primary onClick={handleSubmit}>
              Submit
            </CustomButton>
          </>
        )}
      </Box>
    </React.Fragment>
  );
};

export default ResetPassword;
