import React, { useEffect, useState } from "react";

import { ReactComponent as Close } from "../../../assets/icons/close_black_24dp.svg";

import User from "./AddUsersTenant.module.scss";
import { Constants } from "../../../config/Constants";
import config from "../../../config/aws-exports";
import { API, JS } from "aws-amplify";
import swal from "sweetalert";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import useCountries from "../../Hooks/useCountries";
import useStates from "../../Hooks/useStates";
import useCities from "../../Hooks/useCities";
import { BUSINESS_UNIT_INJECTABLE } from "./AddUsersTenant";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
  },
}));

const EditUser = (props) => {
  let { handleEditClose, getUsers, editData } = props;
  console.log("editData==", editData);
  const [userNameErr, setUserNameErr] = useState("");
  const [lastNameErr, setLastNameErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [pnoErr, setPnoErr] = useState("");
  const [genderErr, setGenderErr] = useState("");
  const [spin, setSpin] = useState(false);
  const classes = useStyles();
  const [initialTenant, setInitialTenant] = useState(editData.col3);
  const [userName, setUserName] = useState(editData.firstname);
  const [lastName, setLastName] = useState(editData.lastname);
  const [designation, setDesignation] = useState(editData?.designation || "");
  const [department, setDepartment] = useState(editData?.department || "");
  const [country, setCountry] = useState(editData?.country || "");
  const [state, setState] = useState(editData?.state || "");
  const [city, setCity] = useState(editData?.city || "");
  const [organization, setOrganization] = useState(
    editData?.organization || ""
  );
  const [email, setEmail] = useState(editData.col2);
  const [unit, setUnit] = useState(editData?.unit || "");
  const [pno, setPno] = useState(editData.cno);
  const [gender, setGender] = useState(editData.gen);
  const [address, setAddress] = useState(editData.address);
  const [urole, setUrole] = useState(editData.userrole);
  const [loading, setLoading] = useState(false);

  const { data: countries, isLoading: cLoading } = useCountries();
  const { data: states, isLoading: sLoading } = useStates({
    country_code: country,
  });
  const { data: cities, isLoading } = useCities({
    country_code: country,
    state_code: state,
  });

  const onChangeHandlerUser = (event) => {
    setUserName(event.target.value);
  };

  const onChangeHandlerLastName = (event) => {
    setLastName(event.target.value);
  };

  // const onChangeHandlerEmail = (event) => {
  //   setEmail(event.target.value);
  // };
  const onChangeHandlerPno = (event) => {
    setPno(event.target.value);
  };
  const onChangeHandlerGender = (event) => {
    setGender(event.target.value);
  };
  const onChangeHandlerAddress = (event) => {
    setAddress(event.target.value);
  };
  const onChangeHandlerUrole = (event) => {
    setUrole(event.target.value);
  };

  useEffect(() => {
    console.log("editData", editData);
  }, []);

  const onsubmit = () => {
    let formIsValid = true;
    setUserNameErr("");
    setLastNameErr("");
    setEmailErr("");
    setPnoErr("");
    setGenderErr("");

    if (
      userName === "" ||
      userName === null ||
      (userName != undefined && userName.trim() === "")
    ) {
      formIsValid = false;
      setUserNameErr("Please enter first name");
      return;
    }
    if (
      lastName === "" ||
      lastName === null ||
      (lastName != undefined && lastName.trim() === "")
    ) {
      formIsValid = false;
      setLastNameErr("Please enter Last name");
      return;
    }
    if (
      email === "" ||
      email === null ||
      (email != undefined && email.trim() === "")
    ) {
      formIsValid = false;
      setEmailErr("*Please enter your email-ID.");
      return;
    }
    if (
      pno !== null &&
      pno !== undefined &&
      pno.trim() !== "" &&
      pno.length < 10
    ) {
      formIsValid = false;
      setPnoErr(
        "Please enter a valid phone number (should be atleast 10 digits)"
      );
      return;
    }
    if (gender === null) {
      formIsValid = false;
      setGenderErr("Please select gender");
      return;
    }
    if (userName !== "") {
      if (!userName.match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        setUserNameErr("*Please enter alphabet characters only.");
        return;
      }
    }
    if (email !== "") {
      //regular expression for email validation
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(email)) {
        formIsValid = false;
        setEmailErr("*Please enter valid email-ID.");
        return;
      }
    }

    if (formIsValid == true) {
      Updateuser();
    }
  };

  async function Updateuser() {
    setSpin(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        ur_id: editData.ur_id,
        tenant: initialTenant,
        emailid: editData.col2,
        department: department,
        designation: designation,
        organization: organization,
        businessUnit: unit,
        country: country,
        state: state,
        city: city,
        first_name: userName,
        last_name: lastName,
        dev: editData.dev,
        urole: urole,
        gen: parseInt(gender),
        address: address,
        schema: Constants.SCHEMA_NAME,
        cno: pno,
        type: "edit",
        atype: 1,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    console.log("bd" + JSON.stringify(bodyParam.body));
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.ADD_USER,
        bodyParam
        //`${Constants.GET_MY_PROGRAMS}`, bodyParam,
      );
      // const users = response;
      console.log(JSON.stringify(response));
      handleEditClose();
      getUsers();
      swal({
        title: "Success",
        text: response.message,
        icon: "success",
        dangerMode: false,
      }).then((willDelete) => {
        if (willDelete) {
          setSpin(false);
        }
      });
    } catch (error) {
      setSpin(false);
      swal({
        title: "Oooops!",
        text: "Something went wrong!",
        icon: "warning",
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          setSpin(false);
        }
      });
      console.log("getUserError", error);
    }
    console.log("Mount");
  }

  return (
    <div className={User.maindiv}>
      {spin === false ? (
        <div className={User.maincontainer}>
          <div className={User.iconcontainer}>
            <h3 className={User.create}>Edit user</h3>
            <Close className={User.closebutton} onClick={handleEditClose} />
          </div>

          <div className={User.form}>
            <div className={User.field}>
              <label
                style={{
                  fontSize: "14px",
                  fontWeight: "400px",
                }}
                for="name"
                required
              >
                First Name
                <span style={{ color: "red", marginTop: "-10px" }}>*</span>
              </label>
              <input
                style={{
                  border: "1px solid lightgrey",
                  borderRadius: "5px",
                  width: "80%",
                  background: "white",
                  height: "30px",
                  marginBottom: "20px",
                  fontSize: "15px",
                  paddingLeft: "10px",
                  fontWeight: "200px",
                }}
                type="text"
                id="name"
                onChange={onChangeHandlerUser}
                value={userName}
                required
              />
            </div>
            <div
              style={{
                color: "#cc0000",
                marginBottom: "12px",
                marginTop: "-10px",
                textAlign: "start",
                marginLeft: "20%",
              }}
            >
              {userNameErr}
            </div>
            <div className={User.field}>
              <label
                style={{
                  fontSize: "14px",
                  fontWeight: "400px",
                }}
                for="name"
                required
              >
                Last Name
                <span style={{ color: "red", marginTop: "-10px" }}>*</span>
              </label>
              <input
                style={{
                  border: "1px solid lightgrey",
                  borderRadius: "5px",
                  width: "80%",
                  background: "white",
                  height: "30px",
                  marginBottom: "20px",
                  fontSize: "15px",
                  paddingLeft: "10px",
                  fontWeight: "200px",
                }}
                type="text"
                id="name"
                onChange={onChangeHandlerLastName}
                value={lastName}
                required
              />
            </div>
            <div
              style={{
                color: "#cc0000",
                marginBottom: "12px",
                marginTop: "-10px",
                textAlign: "start",
                marginLeft: "20%",
              }}
            >
              {lastNameErr}
            </div>

            <div className={User.field}>
              <label
                style={{
                  fontSize: "14px",
                  fontWeight: "400px",
                }}
                for="email"
                required
              >
                Email
                <span style={{ color: "red", marginTop: "-10px" }}>*</span>
              </label>
              <input
                style={{
                  border: "1px solid lightgrey",
                  borderRadius: "5px",
                  width: "80%",
                  background: "white",
                  height: "30px",
                  marginBottom: "20px",
                  fontSize: "15px",
                  paddingLeft: "10px",
                  fontWeight: "200px",
                  color: "grey",
                }}
                type="email"
                id="email"
                value={editData.col2}
                required
              />
            </div>
            <div
              style={{
                color: "#cc0000",
                marginBottom: "12px",
                marginTop: "-10px",
                textAlign: "start",
                marginLeft: "20%",
              }}
            >
              {emailErr}
            </div>
            <div className={User.field}>
              <label
                style={{
                  fontSize: "14px",
                  fontWeight: "500px",
                }}
                for="Designation"
              >
                Designation
              </label>
              <input
                style={{
                  border: "1px solid lightgrey",
                  borderRadius: "5px",
                  width: "80%",
                  background: "white",
                  height: "30px",
                  marginBottom: "20px",
                  fontSize: "15px",
                  paddingLeft: "10px",
                  fontWeight: "200px",
                }}
                id="Designation"
                onChange={(e) => {
                  setDesignation(e.target.value);
                }}
                value={designation}
              />
            </div>
            <div className={User.field}>
              <label
                style={{
                  fontSize: "14px",
                  fontWeight: "500px",
                }}
                for="Department"
              >
                Department
              </label>
              <input
                style={{
                  border: "1px solid lightgrey",
                  borderRadius: "5px",
                  width: "80%",
                  background: "white",
                  height: "30px",
                  marginBottom: "20px",
                  fontSize: "15px",
                  paddingLeft: "10px",
                  fontWeight: "200px",
                }}
                id="Department"
                onChange={(e) => {
                  setDepartment(e.target.value);
                }}
                value={department}
              />
            </div>
            <div className={User.field}>
              <label
                style={{
                  fontSize: "14px",
                  fontWeight: "500px",
                }}
                for="Organization"
              >
                Organization
              </label>
              <input
                style={{
                  border: "1px solid lightgrey",
                  borderRadius: "5px",
                  width: "80%",
                  background: "white",
                  height: "30px",
                  marginBottom: "20px",
                  fontSize: "15px",
                  paddingLeft: "10px",
                  fontWeight: "200px",
                }}
                id="Organization"
                onChange={(e) => {
                  setOrganization(e.target.value);
                }}
                value={organization}
              />
            </div>
            <div className={User.field}>
              <label
                style={{
                  fontSize: "14px",
                  fontWeight: "500px",
                }}
                for="gender"
              >
                Business unit-Injectable
              </label>
              <select
                style={{
                  border: "1px solid lightgrey",
                  borderRadius: "5px",
                  width: "80%",
                  background: "white",
                  height: "30px",
                  marginBottom: "20px",
                  fontSize: "15px",
                  paddingLeft: "10px",
                  fontWeight: "200px",
                }}
                name="unit"
                id="unit"
                onChange={(e) => {
                  const { value } = e.target;
                  setUnit(value);
                }}
                value={unit}
              >
                <option value="">----Select a business unit----</option>
                {BUSINESS_UNIT_INJECTABLE?.map((unit, index) => (
                  <option key={index} value={unit}>
                    {unit}
                  </option>
                ))}
              </select>
            </div>
            <div className={User.field}>
              <label
                style={{
                  fontSize: "14px",
                  fontWeight: "500px",
                }}
                for="contact"
              >
                Ph. No
              </label>
              <input
                style={{
                  border: "1px solid lightgrey",
                  borderRadius: "5px",
                  width: "80%",
                  background: "white",
                  height: "30px",
                  marginBottom: "20px",
                  fontSize: "15px",
                  paddingLeft: "10px",
                  fontWeight: "200px",
                }}
                type="number"
                id="contact"
                onChange={onChangeHandlerPno}
                value={pno}
              />
            </div>
            <div
              style={{
                color: "#cc0000",
                marginBottom: "12px",
                marginTop: "-10px",
                textAlign: "start",
                marginLeft: "20%",
              }}
            >
              {pnoErr}
            </div>

            <div className={User.field}>
              <label
                style={{
                  fontSize: "14px",
                  fontWeight: "500px",
                }}
                for="gender"
              >
                Country
              </label>
              <select
                style={{
                  border: "1px solid lightgrey",
                  borderRadius: "5px",
                  width: "80%",
                  background: "white",
                  height: "30px",
                  marginBottom: "20px",
                  fontSize: "15px",
                  paddingLeft: "10px",
                  fontWeight: "200px",
                }}
                name="country"
                id="country"
                onChange={(e) => {
                  const { value } = e.target;
                  setCountry(value);
                }}
                value={country}
              >
                {cLoading && <option>Loading...</option>}
                <option value="">----Select a country----</option>
                {!cLoading &&
                  countries?.map((country) => (
                    <option key={country.id} value={country.iso2}>
                      {country.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className={User.field}>
              <label
                style={{
                  fontSize: "14px",
                  fontWeight: "500px",
                }}
                for="gender"
              >
                State
              </label>
              <select
                style={{
                  border: "1px solid lightgrey",
                  borderRadius: "5px",
                  width: "80%",
                  background: "white",
                  height: "30px",
                  marginBottom: "20px",
                  fontSize: "15px",
                  paddingLeft: "10px",
                  fontWeight: "200px",
                }}
                name="state"
                id="state"
                onChange={(e) => setState(e.target.value)}
                value={state}
              >
                {sLoading && <option>Loading...</option>}
                <option value="">----Select a state----</option>
                {!sLoading &&
                  states?.map((state) => (
                    <option key={state.id} value={state.state_code}>
                      {state.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className={User.field}>
              <label
                style={{
                  fontSize: "14px",
                  fontWeight: "500px",
                }}
                for="gender"
              >
                City
              </label>
              <select
                style={{
                  border: "1px solid lightgrey",
                  borderRadius: "5px",
                  width: "80%",
                  background: "white",
                  height: "30px",
                  marginBottom: "20px",
                  fontSize: "15px",
                  paddingLeft: "10px",
                  fontWeight: "200px",
                }}
                name="city"
                id="city"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              >
                {isLoading && <option>Loading...</option>}
                <option value="">----Select a city----</option>
                {!isLoading &&
                  cities?.map((city) => (
                    <option key={city.id} value={city.city_code}>
                      {city.name}
                    </option>
                  ))}
              </select>
            </div>

            <div className={User.field}>
              <label
                style={{
                  fontSize: "14px",
                  fontWeight: "500px",
                }}
                for="gender"
              >
                Gender
              </label>
              <select
                style={{
                  border: "1px solid lightgrey",
                  borderRadius: "5px",
                  width: "80%",
                  background: "white",
                  height: "30px",
                  marginBottom: "20px",
                  fontSize: "15px",
                  paddingLeft: "10px",
                  fontWeight: "200px",
                }}
                name="gender"
                id="gender"
                onChange={onChangeHandlerGender}
                value={gender}
              >
                <option value="0">Select</option>
                <option value="1">Male</option>
                <option value="2">Female</option>
              </select>
            </div>
            <div
              style={{
                color: "#cc0000",
                marginBottom: "12px",
                marginTop: "-10px",
                textAlign: "start",
                marginLeft: "20%",
              }}
            >
              {genderErr}
            </div>

            <div className={User.field}>
              <label
                style={{
                  fontSize: "14px",
                  fontWeight: "500px",
                }}
                for="address"
              >
                Address
              </label>
              <textarea
                style={{
                  border: "1px solid lightgrey",
                  borderRadius: "5px",
                  width: "80%",
                  background: "white",
                  height: "30px",
                  marginBottom: "20px",
                  fontSize: "15px",
                  paddingLeft: "10px",
                  fontWeight: "200px",
                }}
                id="address"
                rows="4"
                onChange={onChangeHandlerAddress}
                value={address}
              />
            </div>
            <div className={User.field}>
              <label
                style={{
                  fontSize: "14px",
                  fontWeight: "500px",
                }}
                for="role"
              >
                User role
              </label>
              <select
                style={{
                  border: "1px solid lightgrey",
                  borderRadius: "5px",
                  width: "80%",
                  background: "white",
                  height: "30px",
                  marginBottom: "20px",
                  fontSize: "15px",
                  paddingLeft: "10px",
                  fontWeight: "200px",
                }}
                name="role"
                id="role"
                required
                disabled={true}
                value={urole}
              >
                <option value="User">Participant</option>
                <option value="Manager">Manager</option>
              </select>
            </div>
            <div className={User.buttonholder}>
              <button className={User.cancel} onClick={handleEditClose}>
                Cancel
              </button>
              <button type="submit" onClick={onsubmit} className={User.submit}>
                Submit
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.root}>
          <CircularProgress style={{ color: config.main_color_1 }} />
        </div>
      )}
    </div>
  );
};

export default EditUser;
