// Dependencies imports
import React, { useState, useEffect, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import { API } from "aws-amplify";
import config from "../../../config/aws-exports";
import moment from "moment";
import swal1 from "@sweetalert/with-react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useForm } from "../../Hooks/useForm";
import ProStyle from "./ProInvoice.module.scss";
// Style imports
import logo from "../../../assets/USP logo R.png";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "2rem",
  },
  header: {
    background: "#DED7CF",
    padding: "0.5rem 2rem",
    "& > h2": {
      margin: 0,
      color: "#E96138",
    },
    "& > div": {
      position: "absolute",
      right: "2rem",
      top: "5rem",
    },
  },
  body: {
    margin: "2rem 6rem",
    width: "83%",
    "& > h4": {
      textAlign: "center",
      textDecoration: "underline",
    },
  },
  table: {
    border: "3px solid",
    borderCollapse: "collapse",
    width: "100%",
    margin: "1rem 0rem",
    "& > tr > td": {
      border: "2px solid",
      padding: "0px 6px",
    },
    "& > tr > th": {
      border: "2px solid",
      textAlign: "left",
      padding: "0px 6px",
    },
  },
  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0.5rem 2rem 2rem",
    borderTop: "3px solid #B26253",
    borderBottom: "1rem solid #E01E5A",
    "& > h3": {
      margin: 0,
    },
  },
  alignRight: {
    textAlign: "right",
  },
  alignCenter: {
    textAlign: "center",
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
}));

function createData(line, description, HSN, Qty, unit, amount) {
  return { line, description, HSN, Qty, unit, amount };
}

const rows = [
  createData(
    1,
    "USP India - Deviation Investigations Including RCA on July 27, 2022",
    999293,
    7,
    "11,250.00",
    "78,750.00"
  ),
  createData(2, "IGST @ 18%", "", "", "", "14,175.00"),
  createData("", "", "", "", "", "92,925.00"),
];

const ProInvoice = ({
  handleCloseProforma,
  open,
  listForReg,
  userInvoiceData,
  setActualAmt,
  setActualAmtUnit,
  getRegisteredUsers,
  listSessions,
}) => {
  const classes = useStyles();

  const theme = useTheme();
  const [editOpen, setEditOpen] = useState(false);
  const [addressErr, setAddressErr] = useState("");
  const [gstnoErr, setGstnoErr] = useState("");
  const [priceErr, setPriceErr] = useState("");
  const [spin, setSpin] = useState(false);

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { handleChange, values, setValues } = useForm({});

  useEffect(() => {
    if (
      userInvoiceData.invoice_dtls === null ||
      userInvoiceData.invoice_dtls === undefined
    ) {
      setValues({
        ...values,
        sn: listForReg.sn || "",
        price: listForReg?.priceinr || 0,
        date: moment().format("LL") || null,
        unit: "INR",
        gst: 0,
        discount: listForReg?.perdis || 0,
        sid: listForReg.sid,
        pstatus: 5,
        address: "",
        gstNo: "",
      });
    } else {
      setValues({
        ...values,
        sn: userInvoiceData.invoice_dtls?.sn || "",
        price:
          userInvoiceData.invoice_dtls?.price === 0 ||
            userInvoiceData.invoice_dtls?.price === "0"
            ? 0
            : userInvoiceData.invoice_dtls?.price,
        date: userInvoiceData.invoice_dtls?.date || null,
        unit: userInvoiceData.invoice_dtls?.unit,
        gst:
          userInvoiceData.invoice_dtls?.gst === 0 ||
            userInvoiceData.invoice_dtls?.gst === "0"
            ? 0
            : userInvoiceData.invoice_dtls?.gst,
        address: userInvoiceData.invoice_dtls?.address,
        gstNo: userInvoiceData.invoice_dtls?.gstNo,
      });
    }
  }, [
    listForReg?.sn,
    listForReg?.priceinr,
    listForReg?.date_list[0].date,
    listForReg?.sid,
    userInvoiceData.invoice_dtls?.sn,
    userInvoiceData.invoice_dtls?.price,
    userInvoiceData.invoice_dtls?.date,
    userInvoiceData.invoice_dtls?.unit,
    userInvoiceData.invoice_dtls?.gst,
    userInvoiceData.invoice_dtls?.discount,
    userInvoiceData.invoice_dtls?.address,
    userInvoiceData.invoice_dtls?.gstNo,
  ]);

  var a = [
    "",
    "one ",
    "two ",
    "three ",
    "four ",
    "five ",
    "six ",
    "seven ",
    "eight ",
    "nine ",
    "ten ",
    "eleven ",
    "twelve ",
    "thirteen ",
    "fourteen ",
    "fifteen ",
    "sixteen ",
    "seventeen ",
    "eighteen ",
    "nineteen ",
  ];
  var b = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];

  function numberToWordsOnly(num) {
    if (num != null) {
      if ((num = num.toString()).length > 9) return "overflow";
      let n = ("000000000" + num)
        .substr(-9)
        .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
      if (!n) return;
      var str = "";
      str +=
        n[1] != 0
          ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "crore "
          : "";
      str +=
        n[2] != 0
          ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "lakh "
          : "";
      str +=
        n[3] != 0
          ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "thousand "
          : "";
      str +=
        n[4] != 0
          ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "hundred "
          : "";
      str +=
        n[5] != 0
          ? (str != "" ? "and " : "") +
          (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) +
          "only "
          : "";
      return str;
    }
  }

  function onChangeUnit(e) {
    setValues((prevValues) => ({
      ...prevValues,
      unit: e.target.value,
    }));
  }

  const unitReturn = useMemo(() => {
    if (values.unit === "INR") {
      setValues((prevValues) => ({
        ...prevValues,
        price: userInvoiceData?.invoice_dtls
          ? userInvoiceData?.invoice_dtls.unit === "INR"
            ? userInvoiceData?.invoice_dtls?.price
            : 0
          : listForReg?.priceinr,
      }));
      return "INR";
    }
    if (values.unit === "USD") {
      setValues((prevValues) => ({
        ...prevValues,
        price: userInvoiceData?.invoice_dtls
          ? userInvoiceData?.invoice_dtls.unit === "USD"
            ? userInvoiceData?.invoice_dtls?.price
            : 0
          : listForReg?.priceusd,
      }));
      return "USD";
    }
  }, [values.unit, setValues, listForReg]);

  async function saveInvoice() {

    let formIsValid = true;
    setAddressErr("");
    setGstnoErr("");
    setPriceErr("");

    const { price, address, gstNo } = values;
    if (
      address === "" ||
      address === null ||
      (address !== undefined && address.trim() === "")
    ) {
      formIsValid = false;
      setAddressErr("Please enter bill to address");
      return;
    }

    if (gstNo !== undefined &&
      (gstNo === "" ||
        gstNo === null)
    ) {
      formIsValid = false;
      setGstnoErr("Please enter GST number");
      return;
    }

    if (price === null || price === "") {
      formIsValid = false;
      console.log("price", price);
      setPriceErr("Please enter amount ");
      return;
    }

    if (formIsValid === true) {
      setSpin(true);
      let obj = {};
      obj.sn = listForReg.sn;
      obj.price = values.price;
      obj.date = values.date;
      obj.unit = values.unit;
      obj.gst = values.gst === "" ? 0 : values.gst;
      obj.discount = values.discount === "" ? 0 : values.discount;
      obj.sid = listForReg.sid;
      obj.gstNo = values.gstNo;
      obj.address = values.address;
      obj.pstatus = null;
      obj.totalAmount = calculateDiscountedPrice(+values.price, +values.gst, values.discount);
      obj.sessionDate = listForReg.date_list.at().date;

      const bodyParam = {
        body: {
          sid: listForReg.sid,
          invoiceDtls: obj,
          schema: "uspsm",
          actualamount: calculateDiscountedPrice(+values?.price, +values?.gst, +values?.discount),
          ur_id: userInvoiceData.ur_id,
          emailid: userInvoiceData.emailid,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      try {
        const mark = await API.post(
          config.aws_cloud_logic_custom_name,
          "/updateInvoice",
          bodyParam
        );
        setSpin(false);
        handleCloseProforma();
        listSessions();
        swal1("Success", "Invoice Updated Successfully", "success");

      } catch (error) {
        console.log(JSON.stringify(error));
      }
      setEditOpen(false);
      setActualAmt(values.price);
      setActualAmtUnit(values.unit);
    }
  }
  function calculateDiscountedPrice(price, gst, discount) {
    // Convert values to numbers
    const numericPrice = +price;
    const numericGst = +gst;
    const numericDiscount = +discount;

    // Check if the values are valid numbers
    if (isNaN(numericPrice) || isNaN(numericGst) || isNaN(numericDiscount)) {
      console.error("Invalid input. Please provide valid numeric values.");
      return null;
    }

    // Calculate discounted price
    const discountedPrice = numericPrice * (1 - numericDiscount / 100);

    // Step 2: Calculate GST amount
    const gstAmount = (discountedPrice * numericGst) / 100;

    // Step 3: Calculate total amount
    var totalAmount = discountedPrice + gstAmount;
    totalAmount = Math.round(totalAmount);
    return totalAmount;
  }

  return (
    <div>

      <Dialog
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="responsive-dialog-title"
        maxWidth="lg"
      >
        <DialogTitle
          id="responsive-dialog-title"
          style={{ margin: "1.3rem 5rem", padding: "0" }}
        >
          {!editOpen ? (
            <button
              onClick={() => setEditOpen("true")}
              className={ProStyle.add}
              style={{ float: "right" }}
            >
              Edit Invoice
            </button>
          ) : null}
          <IconButton
            onClick={() => {
              handleCloseProforma();
              getRegisteredUsers();
              setEditOpen(false);
            }}
            style={{ position: "absolute", right: 10, top: 10 }}
          >
            <Close sx={{ fontSize: "2rem" }} />
          </IconButton>
        </DialogTitle>
        <DialogContent
          className="overall-container"
          style={{
            minHeight: !editOpen ? "1045px" : "1245px",
            width: "100%",
            overflow: "hidden",
          }}
        >
          <>
            {!editOpen ? (
              <div className={classes.container}>

                <div className={classes.header}>
                  <h2>USP Education</h2>
                  <div>
                    <img src={logo} alt="logo" height="80" />
                  </div>
                </div>
                <div className={classes.body}>
                  <p>
                    {userInvoiceData?.invoice_dtls === null ||
                      userInvoiceData?.invoice_dtls === undefined
                      ? moment().format("LL")
                      : values.date}
                  </p>
                  <h4>Proforma Invoice</h4>
                  <span>
                    Bill to: -<br />
                    {values.address === "" || values.address === undefined
                      ? "Edit Invoice to add address"
                      : values.address}
                  </span>
                  <p>
                    GST:{" "}
                    {values.gstNo === "" || values.gstNo === undefined
                      ? "Edit Invoice to add GST number"
                      : values.gstNo}
                  </p>
                  <p style={{ margin: "2rem 0rem" }}>
                    Payment Terms: 100% Advance Payment
                  </p>
                  <div>
                    <TableContainer component={Paper}>
                      <Table
                        sx={{ minWidth: 650, background: "#ededed" }}
                        size="small"
                        aria-label="a dense table"
                      >
                        <TableHead>
                          <TableRow sx={{ "th, td": { border: 1.5 } }}>
                            <TableCell>Line</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>HSN</TableCell>
                            <TableCell>Qty.</TableCell>

                            <TableCell>{`Unit(${unitReturn})`}</TableCell>
                            <TableCell>{`Amount(${unitReturn})`}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow
                            
                            sx={{ "th, td": { border: 1.5 } }}
                          >
                            <TableCell component="th" scope="row" align="center">
                              1
                            </TableCell>
                            <TableCell>{`${values.sn} on ${listForReg.date_list.at().date
                              }`}</TableCell>
                            <TableCell align="center">999293</TableCell>
                            <TableCell align="center">1</TableCell>
                            <TableCell align="center">{values.price}</TableCell>
                            <TableCell align="right">{values.price}</TableCell>
                          </TableRow>
                          <TableRow
                            
                            sx={{ "th, td": { border: 1.5 } }}
                          >
                            <TableCell component="th" scope="row" align="center">
                              2
                            </TableCell>
                            <TableCell>Discount(%)</TableCell>
                            <TableCell align="center"></TableCell>
                            <TableCell align="center"></TableCell>
                            <TableCell align="center"></TableCell>
                            <TableCell align="right">{values.discount}</TableCell>
                          </TableRow>
                          <TableRow
                            
                            sx={{ "th, td": { border: 1.5 } }}
                          >
                            <TableCell component="th" scope="row" align="center">
                              3
                            </TableCell>
                            <TableCell>IGST @ 18%</TableCell>
                            <TableCell align="center"></TableCell>
                            <TableCell align="center"></TableCell>
                            <TableCell align="center"></TableCell>
                            <TableCell align="right">{values.gst}</TableCell>
                          </TableRow>
                          <TableRow
                          
                            sx={{ "th, td": { border: 1.5 } }}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                            ></TableCell>
                            <TableCell></TableCell>
                            <TableCell align="center"></TableCell>
                            <TableCell align="center"></TableCell>
                            <TableCell align="center"></TableCell>
                            <TableCell align="right">
                              {calculateDiscountedPrice(+values?.price, +values?.gst, +values?.discount)}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                  <div>
                    <p style={{ margin: "1rem 0rem", fontWeight: "700" }}>
                      {`${unitReturn} ${numberToWordsOnly(
                        calculateDiscountedPrice(+values?.price, +values?.gst, +values?.discount)
                      )}`}
                    </p>
                    <table style={{ width: "40%" }}>
                      <tr>
                        <td>USP India GSTIN</td>
                        <td>: 36AAACU7542C1ZI</td>
                      </tr>
                      <tr>
                        <td>USP India PAN No</td>
                        <td>: AAACU7542C</td>
                      </tr>
                    </table>

                    <p>
                      Nature of services: "Commercial Training and Coaching
                      Services".
                      <br />
                      Please inform us in advance if your unit is in SEZ for GST
                      amount.
                    </p>

                    <p>
                      Please Remit to:
                      <br />
                      Beneficiary: "United States Pharmacopeia India (P) Ltd."
                      <br />
                      Bank: ICICI Bank Ltd
                      <br />
                      Address: 6-2-1012, TGV Mansion, Khairtabad, Hyderabad.
                      <br />
                      Account: 000805005833
                      <br />
                      IFSC Code: ICIC0000008
                      <br />
                      Swift Code: ICICINBB008
                      <br />
                      Note: Taxes are subject to changes as per Government rules.
                    </p>

                    <h4 style={{ textAlign: "center", margin: "3rem 0rem" }}>
                      This is an electronic document hence signature is not
                      required
                    </h4>
                  </div>
                </div>

                <div className={classes.footer}>
                  <h3>education.usp.org</h3>
                  <h3>Empowering a healthy tomorrow</h3>
                </div>
               
              </div>
            ) : (
              <div className={classes.container}>
                <Backdrop
                  sx={{ color: "#fff" }}
                  open={spin}
                  className={classes.backdrop}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
                <div className={classes.header}>
                  <h2>USP Education</h2>
                  <div>
                    <img src={logo} alt="logo" height="80" />
                  </div>
                </div>
                <div className={classes.body}>
                  <p>
                    {" "}
                    <div className={ProStyle.inputholder}>
                      {moment().format("LL")}
                    </div>
                  </p>
                  <h4>Proforma Invoice</h4>
                  <span>
                    Bill to: -<br />
                    <div className={ProStyle.inputholder}>
                      <textarea
                        rows={4}
                        id="address"
                        className={ProStyle.textfield}
                        onChange={handleChange("address")}
                        value={values?.address || ""}
                      ></textarea>
                      <p
                        style={{ margin: "0px", fontSize: "14px", color: "red" }}
                      >
                        {addressErr}
                      </p>
                    </div>
                  </span>
                  <p>
                    GST:{" "}
                    <div className={ProStyle.inputholder}>
                      <input
                        type="text"
                        id="name"
                        className={ProStyle.inputfield}
                        onChange={handleChange("gstNo")}
                        value={values?.gstNo || ""}
                      ></input>
                      <p
                        style={{ margin: "0px", fontSize: "14px", color: "red" }}
                      >
                        {gstnoErr}
                      </p>
                    </div>
                  </p>
                  <p style={{ margin: "2rem 0rem" }}>
                    Payment Terms: 100% Advance Payment
                  </p>
                  <div>
                    <TableContainer component={Paper}>
                      <Table
                        sx={{ minWidth: 650, background: "#ededed" }}
                        size="small"
                        aria-label="a dense table"
                      >
                        <TableHead>
                          <TableRow sx={{ "th, td": { border: 1.5 } }}>
                            <TableCell>Line</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>HSN</TableCell>
                            <TableCell>Qty.</TableCell>
                            <TableCell>
                              {" "}
                              <select
                                id="unitType"
                                className={ProStyle.inputfield}
                                onChange={(e) => onChangeUnit(e)}
                                value={values.unit}
                         
                              >
                                <option value="INR">Unit&nbsp;(INR) </option>
                                <option value="USD">Unit&nbsp;(USD)</option>
                              </select>
                            </TableCell>
                            <TableCell>{`Amount(${unitReturn})`}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow
                            
                            sx={{ "th, td": { border: 1.5 } }}
                          >
                            <TableCell component="th" scope="row" align="center">
                              1
                            </TableCell>
                            <TableCell>{`${values.sn} on ${listForReg.date_list.at().date
                              }`}</TableCell>
                            <TableCell align="center">999293</TableCell>
                            <TableCell align="center">1</TableCell>
                            <TableCell align="center">
                              {" "}
                              <input
                                type="number"
                                id="name"
                                className={ProStyle.inputfield}
                                onChange={handleChange("price")}
                                value={values?.price}
                              ></input>
                            </TableCell>
                            <TableCell align="right">
                              {" "}
                              <input
                                type="number"
                                id="name"
                                className={ProStyle.inputfield}
                                onChange={handleChange("price")}
                                value={values?.price}
                              ></input>
                            </TableCell>
                          </TableRow>
                          <TableRow
                            
                            sx={{ "th, td": { border: 1.5 } }}
                          >
                            <TableCell component="th" scope="row" align="center">
                              2
                            </TableCell>
                            <TableCell>Discount(%)</TableCell>
                            <TableCell align="center"></TableCell>
                            <TableCell align="center"></TableCell>
                            <TableCell align="center"></TableCell>
                            <TableCell align="right">
                              <input
                                type="number"
                                id="name"
                                className={ProStyle.inputfield}
                                onChange={handleChange("discount")}
                                value={values?.discount}
                              ></input>
                            </TableCell>
                          </TableRow>
                          <TableRow
                            
                            sx={{ "th, td": { border: 1.5 } }}
                          >
                            <TableCell component="th" scope="row" align="center">
                              3
                            </TableCell>
                            <TableCell>IGST @ 18%</TableCell>
                            <TableCell align="center"></TableCell>
                            <TableCell align="center"></TableCell>
                            <TableCell align="center"></TableCell>
                            <TableCell align="right">
                              <input
                                type="number"
                                id="name"
                                className={ProStyle.inputfield}
                                onChange={handleChange("gst")}
                                value={values?.gst}
                              ></input>
                            </TableCell>
                          </TableRow>
                          <TableRow
                            
                            sx={{ "th, td": { border: 1.5 } }}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                            ></TableCell>
                            <TableCell></TableCell>
                            <TableCell align="center"></TableCell>
                            <TableCell align="center"></TableCell>
                            <TableCell align="center"></TableCell>
                            <TableCell align="right">
                              {calculateDiscountedPrice(+values?.price, +values?.gst, +values?.discount)}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                  <p style={{ margin: "0px", fontSize: "14px", color: "red" }}>
                    {priceErr}
                  </p>
                  <div>
                    <p style={{ margin: "1rem 0rem", fontWeight: "700" }}>
                      {`${unitReturn}
                    
                    ${numberToWordsOnly(calculateDiscountedPrice(+values?.price, +values?.gst, +values?.discount))}`}
                    </p>
                    <table style={{ width: "40%" }}>
                      <tr>
                        <td>USP India GSTIN</td>
                        <td>: 36AAACU7542C1ZI</td>
                      </tr>
                      <tr>
                        <td>USP India PAN No</td>
                        <td>: AAACU7542C</td>
                      </tr>
                    </table>

                    <p>
                      Nature of services: "Commercial Training and Coaching
                      Services".
                      <br />
                      Please inform us in advance if your unit is in SEZ for GST
                      amount.
                    </p>

                    <p>
                      Please Remit to:
                      <br />
                      Beneficiary: "United States Pharmacopeia India (P) Ltd."
                      <br />
                      Bank: ICICI Bank Ltd
                      <br />
                      Address: 6-2-1012, TGV Mansion, Khairtabad, Hyderabad.
                      <br />
                      Account: 000805005833
                      <br />
                      IFSC Code: ICIC0000008
                      <br />
                      Swift Code: ICICINBB008
                      <br />
                      Note: Taxes are subject to changes as per Government rules.
                    </p>

                    <h4 style={{ textAlign: "center", margin: "3rem 0rem" }}>
                      This is an electronic document hence signature is not
                      required
                    </h4>
                  </div>
                </div>

                <div className={classes.footer}>
                  <h3>education.usp.org</h3>
                  <h3>Empowering a healthy tomorrow</h3>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "12px",
                  }}
                >
                  <button
                    className={ProStyle.add}
                    onClick={() => {
                      saveInvoice();
                    }}
                  >
                    Save Invoice
                  </button>
                </div>
              </div>
            )}
          </>
        
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        ></DialogActions>
      </Dialog>
    </div>
  );
};

export default ProInvoice;
