import React from "react";
import UserInfo from "../components/pages/Tenants/userInfo";
import SideNav from "../components/SideNav/sideNav";
import Header from "../components/Header/hearder";
import { Wrapper } from "./layout.styles";

const UserInfoLayout = () => {
  return (
    <React.Fragment>
      <Header />
      <Wrapper>
        <SideNav />
        <div style={{margin: "70px 0px 0px 200px", width: "100%"}}>
        <UserInfo />
        </div>
      </Wrapper>
    </React.Fragment>
  );
};

export default UserInfoLayout;
