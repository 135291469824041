// Dependencies imports
import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { API } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import { Link } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import moment from "moment";

import CircularProgress from "@material-ui/core/CircularProgress";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../../redux/auth/authSlice";
// Local imports
import DailyLineChart from "./DailyLineChart";
import SelectPopup from "./SelectPopup";
import { Constants } from "../../../config/Constants";
import config from "../../../config/aws-exports";

// Style imports
import Dash from "./Dashboard.module.scss";

// Internal Styles for the material UI components
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  graph: {
    width: "100%",
    height: "300px",
    textAlign: "center",
    verticalAlign: "middle",
    margin: "0px",
    padding: "0px",
  },
  paper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
    flexDirection: "column",
    width: "180px",
    height: theme.spacing(19),
    textDecoration: "none",
    cursor: "pointer",
    "&:hover": {
      transform: "scale(1.05)",
      background: "#093055",
      color: "white",
    },
  },
  ".css-13xfq8m-MuiTabPanel-root": {
    padding: 50,
  },
}));

// Start of main Dashboard Component
export default function Dashboard() {
  const classes = useStyles();
  const [value, setValue] = useState("1");
  const [open, setOpen] = useState(false);
  const [dashChange, setDashChange] = useState(false);
  const [userCount, setUserCount] = useState({});
  console.log(userCount);
  const [todaySessionCount, setTodaySessionCount] = useState(0);
  // const [sessionCount, setSesssionCount] = useState({});
  const [tenants, setTenants] = useState([]);
  // const [todayLiveSessionCount, setTodayLiveSessionCount] = useState(0);
  const [loading, setLoading] = useState(true);
  let userDetails = useSelector(authData);
  const dispatch = useDispatch();

  // Useeffect for api call
  useEffect(() => {
    getUserCount(userDetails.selectedTenant);
    return () => {};
  }, [userDetails.selectedTenant]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // function to open select teanant popup
  const handleOpen = () => {
    setOpen(true);
    setDashChange(true);
  };

  // function to close select tenant popup
  const handleClose = () => {
    setOpen(false);
    setDashChange(false);
  };

  // function to get user count
  async function getUserCount(value) {
    setLoading(true);
    const bodyParam = {
      body: {
        org: config.aws_org_id,
        oid: value === "ALL ORGANIZATION" ? "ALL TENANT" : value,
        schema: Constants.SCHEMA_NAME,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log(bodyParam.body);
      const user = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_USER_COUNT,
        bodyParam
      );
      if (user !== undefined && user !== null) {
        setUserCount(user.body);
        getTenants();
        getTodaySession(value);
      }
    } catch (error) {
      console.error(error);
      getTenants();
    }
  }

  async function getTodaySession(value) {
    setLoading(true);
    const bodyParam = {
      body: {
        oid: value === "ALL ORGANIZATION" ? "ALL TENANT" : value,
        schema: Constants.SCHEMA_NAME,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log(bodyParam.body);
      const Session = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_SESSION,
        bodyParam
      );
      if (Session !== undefined && Session !== null) {
        const listAll = Session.body;
        console.log("rows", listAll);
        var myDateT = moment().format("L");
        const arrayDateFilter = listAll.filter((innArr) => {
          let startDate = innArr?.date_list?.[0]?.date;
          let convertedDate = moment(startDate).format("L");
          console.log("trueSTA", moment(startDate).format("DD MM YYYY"));
          console.log("trueCON", convertedDate);

          console.log("true", convertedDate === myDateT);
          if (innArr?.date_list === null) return false;
          if (convertedDate === myDateT) {
            return true;
          }

          return false;
        });
        console.log("arrayDateFileter", arrayDateFilter);
        let trows = [];
        for (let i = 0; i < arrayDateFilter.length; i++) {
          let obj = {};
          obj.id = i;
          obj.sessionImage = arrayDateFilter[i].timgname;
          obj.name = arrayDateFilter[i].sn;
          obj.datetime = `${moment(
            parseInt(arrayDateFilter[i].startdate)
          ).format("DD-MM-YYYY")} \n
        ${moment(parseInt(arrayDateFilter[i].startdate)).format("LT")}`;
          obj.registered = arrayDateFilter[i].approved_count;
          trows.push(obj);
        }
        setTodaySessionCount(arrayDateFilter.length);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  }

  // API to get Session count
  // async function getSessionCount(val) {
  //   const bodyParam = {
  //     body: {
  //       oid: config.aws_org_id,
  //       tenant: val,
  //     },
  //     headers: {
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //     },
  //   };
  //   try {
  //     console.log(bodyParam.body);
  //     const count = await API.post(
  //       config.aws_cloud_logic_custom_name,
  //       Constants.GET_SESSION,
  //       bodyParam
  //     );
  //     console.log("count" + count);
  //     let listAll = count.body;
  //     let totalCount = count.body.length;
  //     if (count !== undefined && count !== null) {
  //       setSesssionCount(totalCount);
  //       getTenants();
  //       var myDateT = moment().format("L");
  //       /* console.log("gotDate", myDateT);
  //       myDateT = myDateT.split("-");
  //       var newSessionDate = new Date(myDateT[2], myDateT[1] - 1, myDateT[0]);
  //       let today1 = newSessionDate.getTime();

  //       let sessionDate; */

  //       const arrayDateFilter = listAll.filter((innArr, index) => {
  //         let startDate = innArr.DTLS.STARTDATE;
  //         let convertedDate = moment(startDate).format("L");
  //         console.log("trueSTA", startDate);
  //         console.log("trueCON", convertedDate);

  //         console.log("true", convertedDate === myDateT);
  //         if (convertedDate == myDateT) {
  //           return innArr;
  //         }
  //       });

  //       console.log("filtered", arrayDateFilter);
  //       setTodayLiveSessionCount(arrayDateFilter);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  // function to get Tenants list
  async function getTenants() {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        schema: Constants.SCHEMA_NAME,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log(bodyParam.body);
      const ten = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_TENANTS,
        bodyParam
      );

      if (ten !== undefined && ten !== null) {
        setTenants(ten.body);
        //console.log("tenantsa", ten);

        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  function titleclick(name, path) {
    let sdata = { ...userDetails };

    if (sdata?.breadcrumb === undefined) {
      sdata.breadcrumb = [];
      let temp = [...sdata.breadcrumb];
      temp[0] = {
        name: "Dashboard",
        path: "/landingscreen",
      };
      temp[1] = {
        name: name,
        path: path,
      };
      sdata.breadcrumb = temp;
    } else {
      let temp = [...sdata.breadcrumb];
      temp[0] = {
        name: "Dashboard",
        path: "/landingscreen",
      };
      temp[1] = {
        name: name,
        path: path,
      };
      sdata.breadcrumb = temp;
    }

    dispatch(awsSignIn(sdata));
  }

  // Start of main return
  return (
    <div className={Dash.main}>
      {/* <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop> */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <SelectPopup
            handleClose={handleClose}
            tenants={tenants}
            getUserCount={getUserCount}
            dashChange={dashChange}
          />
        </Fade>
      </Modal>
      {/* <h3 className={Dash.heading}>Dashboard</h3> */}

      {userDetails.role === "Admin" ? (
        userDetails.role === "Manager" ? null : (
          <div className={Dash.tenantselect}>
            <h4 className={Dash.selecttenant}>Select Organization:</h4>
            <h5
              className={Dash.tenantname}
              onClick={() => {
                handleOpen();
              }}
            >
              {userDetails?.buttonText}
            </h5>
          </div>
        )
      ) : null}
      <div className={Dash.statsholder}>
        <div className={Dash.totalcount}>
          <h3 className={Dash.subheading}>Total</h3>
          <div className={Dash.cardholder}>
            <Link
              to="/landingscreen/totalusers"
              style={{ textDecoration: "none" }}
            >
              <Paper
                elevation={4}
                className={classes.paper}
                onClick={() =>
                  titleclick("Total Users", "/landingscreen/totalusers")
                }
              >
                <h4 className={Dash.count}>
                  {loading === false ? (
                    userCount?.totalusers
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Skeleton variant="rect" width={50} height={28} />{" "}
                    </div>
                  )}
                </h4>
                <h4 className={Dash.type}>Users</h4>
              </Paper>
            </Link>
            <Link
              to="/landingscreen/totallivesession"
              style={{ textDecoration: "none" }}
            >
              <Paper
                elevation={4}
                className={classes.paper}
                onClick={() =>
                  titleclick(
                    "Total LiveSession",
                    "/landingscreen/totallivesession"
                  )
                }
              >
                <h4 className={Dash.count}>
                  {loading === false ? (
                    userCount?.totalsessions
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Skeleton variant="rect" width={50} height={28} />{" "}
                    </div>
                  )}
                </h4>
                <h4 className={Dash.type}>Sessions</h4>
              </Paper>
            </Link>

            {/* <Link
              to="/landingscreen/totalattendance"
              style={{ textDecoration: "none" }}
            >
              <Paper
                elevation={4}
                className={classes.paper}
                onClick={() =>
                  titleclick(
                    "Total Attendance",
                    "/landingscreen/totalattendance"
                  )
                }
              >
                <h4 className={Dash.count}>
                  {loading === false ? (
                    <span>
                      {userCount?.totalAttendance
                        ? userCount?.totalAttendance
                        : 0}
                    </span>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Skeleton variant="rect" width={50} height={28} />{" "}
                    </div>
                  )}
                </h4>
                <h4 className={Dash.type}>Attendance</h4>
              </Paper>
            </Link> */}

            {/* <Link
              to="/landingscreen/totalrevenue"
              style={{ textDecoration: "none" }}
            >
              <Paper
                elevation={4}
                className={classes.paper}
                onClick={() =>
                  titleclick("Total Revenue", "/landingscreen/totalrevenue")
                }
              >
                <h4 className={Dash.count}>
                  {loading === false ? (
                    <span>
                      &#8377;
                      {userCount?.totalRevenue ? userCount?.totalRevenue : 0}
                    </span>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Skeleton variant="rect" width={50} height={28} />{" "}
                    </div>
                  )}
                </h4>
                <h4 className={Dash.type}>Revenue</h4>
              </Paper>
            </Link> */}

            {/* <Link
              to="/landingscreen/totalcourses"
              style={{ textDecoration: "none" }}
            >
              <Paper elevation={4} className={classes.paper} onClick={() => titleclick("Total Courses", "/landingscreen/totalCourses")}>
                <h4 className={Dash.count}>
                  {loading === false ? (
                    userCount?.course
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Skeleton variant="rect" width={50} height={28} />{" "}
                    </div>
                  )}
                </h4>
                <h4 className={Dash.type}>Courses</h4>
              </Paper>
            </Link>
            <Link
              to="/landingscreen/totalcontent"
              style={{ textDecoration: "none" }}
            >
              <Paper elevation={4} className={classes.paper} onClick={() => titleclick("Total Content", "/landingscreen/totalcontent")}>
                <h4 className={Dash.count}>
                  {loading === false ? (
                    userCount?.content
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Skeleton variant="rect" width={50} height={28} />{" "}
                    </div>
                  )}
                </h4>
                <h4 className={Dash.type}>Content & Collaterals</h4>
              </Paper>
            </Link> */}
          </div>
        </div>
        <div className={Dash.todaycount}>
          <h3 className={Dash.subheading}>Today</h3>
          <div className={Dash.cardholder}>
            <Link
              to="/landingscreen/userstoday"
              style={{ textDecoration: "none" }}
            >
              <Paper
                elevation={4}
                className={classes.paper}
                onClick={() =>
                  titleclick("Active Users Today", "/landingscreen/userstoday")
                }
              >
                <h4 className={Dash.count}>
                  {loading === false ? (
                    userCount?.todayusercount
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Skeleton variant="rect" width={50} height={28} />{" "}
                    </div>
                  )}
                </h4>
                <h4 className={Dash.type}>Active Users</h4>
              </Paper>
            </Link>
            <Link
              to="/landingscreen/todaysession"
              style={{ textDecoration: "none" }}
            >
              <Paper
                elevation={4}
                className={classes.paper}
                onClick={() =>
                  titleclick("Sessions Today", "/landingscreen/todaysession")
                }
              >
                <h4 className={Dash.count}>
                  {loading === false ? (
                    todaySessionCount
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Skeleton variant="rect" width={50} height={28} />{" "}
                    </div>
                  )}
                </h4>
                <h4 className={Dash.type}>Sessions Today</h4>
              </Paper>
            </Link>
          </div>
        </div>
      </div>
      <div className={Dash.graphholder}>
        <h3 className={Dash.subheading}>User Activity</h3>
        {loading === false ? (
          <div className={Dash.gridholder}>
            <Paper>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    onChange={handleChange}
                    value={value}
                    aria-label="basic tabs example"
                    indicatorColor="primary"
                    textColor="inherit"
                    variant="fullWidth"
                  >
                    <Tab label="Daily" value="1" />
                    <Tab label="Monthly" value="2" />
                    <Tab label="Yearly" value="3" />
                  </Tabs>
                </Box>
                <TabPanel
                  value="1"
                  className={classes[".css-13xfq8m-MuiTabPanel-root"]}
                >
                  <Paper elevation={3} className={classes.graph}>
                    <DailyLineChart
                      data={
                        userCount?.useractive === undefined
                          ? {}
                          : userCount?.useractive
                      }
                      days={7}
                    />
                  </Paper>
                </TabPanel>
                <TabPanel
                  value="2"
                  className={classes[".css-13xfq8m-MuiTabPanel-root"]}
                >
                  <Paper elevation={3} className={classes.graph}>
                    <DailyLineChart
                      data={
                        userCount?.useractive === undefined
                          ? {}
                          : userCount?.useractive
                      }
                      days={30}
                    />
                  </Paper>
                </TabPanel>
                <TabPanel
                  value="3"
                  className={classes[".css-13xfq8m-MuiTabPanel-root"]}
                >
                  <Paper elevation={3} className={classes.graph}>
                    <DailyLineChart
                      data={
                        userCount?.useractive === undefined
                          ? {}
                          : userCount?.useractive
                      }
                      days={365}
                    />
                  </Paper>
                </TabPanel>
              </TabContext>
            </Paper>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Skeleton
              variant="rect"
              width="100%"
              height={300}
              style={{ marginBottom: "40px" }}
            />{" "}
          </div>
        )}
      </div>
    </div>
  );
}
