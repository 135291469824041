// Dependencies import
import React, { useState } from "react";
import { Constants } from "../../../config/Constants";
import config from "../../../config/aws-exports";
import { API } from "aws-amplify";
import { awsSignIn, authData } from "../../../redux/auth/authSlice";
import { useSelector, useDispatch } from "react-redux";
import swal from "@sweetalert/with-react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

// Local imports
import { ReactComponent as Close } from "../../../assets/icons/close_black_24dp.svg";

// Style imports
import Add from "./AddEvent.module.scss";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const AddEvent = (props) => {
  const classes = useStyles();

  let { handleClose, eventData, setevents, getEvents } = props;
  const [state, setState] = useState(false);
  const [eventDate, setEventDate] = useState("");
  const [evnentName, setEventName] = useState("");
  const [eventLink, setEventLink] = useState("");
  const [eventtitle, setEventtitle] = useState("");
  const [eventId, setEventId] = useState("");
  const [eventDateErr, setEventDateErr] = useState("");
  const [eventNameErr, setEventNameErr] = useState("");
  const [eventTitleErr, setEventTitleErr] = useState("");
  const [spin, setSpin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState("");
  const [link, setLink] = useState(false);
  const [linkErr, setLinkErr] = useState("");

  let userDetails = useSelector(authData);

  const onChangeHandlerEventName = (event) => {
    setEventName(event.target.value);
  };
  const onChangeHandlerEventDate = (event) => {
    setEventDate(event.target.value);
  };
  const onChangeHandlerEventLink = (event) => {
    setEventLink(event.target.value);
  };
  const onChangeHandlerEventTitle = (event) => {
    setEventtitle(event.target.value);
  };

  const onsubmit = () => {
    // setLoading(true);
    // setname(name)
    let regex =
      "^(http[s]?:\\/\\/){0,1}(www\\.){0,1}[a-zA-Z0-9\\.\\-]+\\.[a-zA-Z]{2,5}[\\.]{0,1}";

    function validURL(str) {
      var pattern = new RegExp(
        "^(https:\\/\\/)" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // fragment locator
      return !!pattern.test(str);
    }

    //let result = new RegExp(regex, "i");
    let formIsValid = true;
    let neweid;
    setEventDateErr("");
    setEventNameErr("");
    setEventTitleErr("");
    if (eventtitle.trim() === "") {
      formIsValid = false;
      setEventTitleErr("*Please Enter Event Title");
      return;
    }

    if (evnentName.trim() === "") {
      formIsValid = false;
      setEventNameErr("*Please Enter Event description");
      return;
    }
    if (eventDate === "") {
      formIsValid = false;
      setEventDateErr("Please Enter Date");
      return;
    }

    if (evnentName.trim() != "" && evnentName.trim().length > 160) {
      formIsValid = false;
      setEventNameErr(
        "*Please Enter Event description length less than 160 character"
      );
      return;
    }
    if (link === true && (url === "" || !validURL(url))) {
      formIsValid = false;
      setLinkErr("Please enter valid url");
      return;
    }

    /*   if (!validURL(eventLink)) {
      formIsValid = false;
      setEventLinkErr("Please enter right URL format");
      return;
    } */

    if (formIsValid === true) {
      if (eventData.length === 0 || eventData.length === undefined) {
        // setEventId(1);
        neweid = 1;
      } else {
        neweid = eventData[eventData.length - 1].eid + 1;
      }
      console.log("id", neweid);
      addEvent();
      setevents((prev) => [
        ...prev,
        {
          title: eventtitle,
          start: eventDate,
          description: evnentName,
          startTime: new Date(eventDate),
          endTime: new Date(eventDate),
          /* link: link,
          url: url, */
        },
      ]);
      if (link === true) {
        setevents((prev) => [
          ...prev,
          {
            url: url,
          },
        ]);
      }
    }
  };

  async function addEvent() {
    setLoading(true);
    var eventData = {
      atype: 0,
      evid: 0,
      ev_date: new Date(eventDate).getTime(),
      evtitle: eventtitle,
      ev_desc: evnentName,
      stnt_id: null,
      schema: Constants.SCHEMA_NAME,
      oid: config.aws_org_id,
      //link: eventLink,
    };
    if (link === true) {
      eventData.pr_link = url;
    }
    // setSpin(true);

    const bodyParam = {
      body: eventData,
      

      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.ADD_EVENTS,
        bodyParam

        //`${Constants.GET_MY_PROGRAMS}`, bodyParam,
      );
      console.log("res", response);
      setLoading(false);
      handleClose();
      getEvents();
      // const users = response;
      swal({
        title: "Success",
        text: "Event Added successfully",
        icon: "success",
        dangerMode: false,
      }).then((willDelete) => {
        if (willDelete) {
          handleClose();
          getEvents();
        }
      });
      //setSpin(false);
      //getEvents();
    } catch (error) {
      swal({
        title: "Oooops!",
        text: "Soomthing wrong!",
        icon: "warning",
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          // setSpin(false);
        }
      });
      console.log("getUserError", error);
    }
    console.log("Mount");
  }
  function handleSave() {
    let one = {};
    one.title = eventtitle;
    one.desc = evnentName;
    one.da = eventDate;
    console.log("one", one);
  }
  function clicking(e) {
    if (e.target.value === "true") {
      setLink(!link);
    }
  }

  return (
    <div className={Add.mainpopup}>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className={Add.topbar}>
        <h3 className={Add.heading}>Add Events</h3>
        <Close className={Add.closeicon} onClick={() => handleClose()} />
      </div>

      <div className={Add.forminput}>
        <div className={Add.inputblock}>
          <div className={Add.labelholder}>
            <label for="sessiondate" className={Add.label} required>
              Title
              <span style={{ color: "red", marginTop: "-10px" }}>*</span>
            </label>
          </div>
          <div className={Add.inputholder}>
            <input
              type="text"
              //placeholder="Title"
              className={Add.inputfield}
              onChange={onChangeHandlerEventTitle}
              value={eventtitle}
            />
          </div>
        </div>
        <div
          style={{
            color: "#cc0000",
            marginBottom: "12px",
            marginTop: "-10px",
            textAlign: "start",
            marginLeft: "20%",
          }}
        >
          {eventTitleErr}
        </div>
        <div className={Add.inputblock}>
          <div className={Add.labelholder}>
            <label for="sessiondate" className={Add.label} required>
              Description
              <span style={{ color: "red", marginTop: "-10px" }}>*</span>
            </label>
          </div>
          <div className={Add.inputholder}>
            <textarea
              type="text"
              rows={6}
              //placeholder="Description"
              className={Add.textfield}
              onChange={onChangeHandlerEventName}
              value={evnentName}
            />
          </div>
        </div>
        <div
          style={{
            color: "#cc0000",
            marginBottom: "12px",
            marginTop: "-10px",
            textAlign: "start",
            marginLeft: "20%",
          }}
        >
          {eventNameErr}
        </div>
        <div className={Add.inputblock}>
          <div className={Add.labelholder}>
            <label for="sessiondate" className={Add.label} required>
              Select Date
              <span style={{ color: "red", marginTop: "-10px" }}>*</span>
            </label>
          </div>
          <div className={Add.inputholder}>
            <input
              type="datetime-local"
              // placeholder="Select Date"
              className={Add.inputfield}
              onChange={onChangeHandlerEventDate}
              value={eventDate}
            />
          </div>
        </div>
        <div
          style={{
            color: "#cc0000",
            marginBottom: "12px",
            marginTop: "-10px",
            textAlign: "start",
            marginLeft: "20%",
          }}
        >
          {eventDateErr}
        </div>
        <div
          style={{
            width: "65%",
            fontSize: "12px",
            fontWeight: "400",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "5px",
          }}
        >
          <input type="checkbox" value="true" onClick={(e) => clicking(e)} />
          <lable>Include url</lable>
        </div>
        {/*  <span className={Add.radiolable}>Include link</span> */}
        {link ? (
          <div className={Add.inputblock}>
            <div className={Add.labelholder}>
              <label for="sessiondate" className={Add.label} required>
                Url
                <span style={{ color: "red", marginTop: "-10px" }}>*</span>
              </label>
            </div>
            <div className={Add.inputholder}>
              <input
                type="text"
                // placeholder="Url"
                className={Add.inputfield}
                value={url}
                onChange={(e) => {
                  setUrl(e.target.value);
                }}
              />
            </div>
          </div>
        ) : null}
        <div
          style={{
            color: "#cc0000",
            marginBottom: "12px",
            marginTop: "-10px",
            textAlign: "start",
            marginLeft: "0%",
          }}
        >
          {linkErr}
        </div>
      </div>
      <div className={Add.savebutton}>
        <button className={Add.cancel} onClick={handleClose}>
          Cancel
        </button>
        <button className={Add.save} onClick={onsubmit}>
          Add
        </button>
      </div>
    </div>
  );
};

export default AddEvent;
