// Default styles for the platform

export const Constants = {
  orange_color: "#e78022",
  blue_color: "#093055",
  button_font_size: "12px",
  main_screen_headings: "18px",

  //API
  SCHEMA_NAME: "uspsm",
  GET_ATTENDANCE_COUNT: "/getAttendanceCount",
  GET_TOPIC: "/getTopic",
  GET_TENANTS: "/getTenants",
  GET_USER_COUNT: "/getUserCount",
  GET_ALL_TENANTS_USER: "/getAllTenantUsers",
  GET_LIST_TOPICS: "/listTopics",
  GET_EVENTS: "/addEvent",
  LIST_TOPICS: "/listTopics",
  GET_USER_INFO: "/getUserInfo",
  GET_USER_DATA_WEB: "/getUserDataWeb",
  GET_GROUP_NOTIFICATION: "/groupNotification",
  GET_PRESIGNED_URL: "/getPreSignedURL",
  DELETE_SESSION: "/deleteSession",
  ADD_EVENTS: "/addEvent",
  GET_SESSION: "/getSession",
  ADD_USER: "/addUser",
  GET_USERS: "/getUsers",
  GET_CATEGORIES: "/getCategories",
  GET_CATEGORY_DATA: "/getCategoryData",
  UPDATE_CATEGORY: "/addCategory",
  ADD_CONTENT: "/addContent",
  PUBLISH_TOPICS: "/publishUnpublishCourse",
  CREATE_TOPIC: "/createTopic",
  EDIT_TOPIC: "/editTopic",
  GET_CONTENT_OBJECT: "/getContentObject",
  GET_CONTENT_REPORT: "/getContentReport",
  GET_COURSE_ANALYTICS: "/getCourseAnalytics",
  GET_EVENTS_DATA: "/getEventsData",
  GET_OBJECT_ANALYTICS: "/getObjectAnalytics",
  GET_POPULAR_COURSE_OR_SESSION: "/getPopularCourseOrSession",
  GET_PROGRAMS: "/getPrograms",
  GET_TENANT_ANALYTICS: "/getTenantAnalytics",
  GET_TENANT_DATA: "/getTenantData",
  GET_TENANT_DATA_INFO: "/getTenantDataInfo",
  GET_TODAY_ACTIVE: "/getTodayActive",
  GET_TODAY_CONTENT: "/getTodayContent",
  GET_TODAY_COURSE: "/getTodayCourse",
  GET_TODAY_USER: "/getTodayUser",
  GET_TOPIC_ID: "/getTopicID",
  GET_TOPIC_REPORT: "/getTopicReport",
  GET_USER_PROGRESS_INFO: "/getUserProgressInfo",
  LIST_CONTENT: "/listContent",
  LIST_COURSES: "/listCourses",
  POPULAR_SESSION: "/popularsession",
  UNPUBLISH_CONTENT: "/unPublishContent",
  UNPUBLISH_COURSE: "/unPublishCourse",
  UPDATE_PROGRAMS: "/updatePrograms",
  UPDATE_TENANT: "/updateTenant",
  UPLOAD_OBJECT_SWIPER: "/uploadObjectSwiper",

  LIST_SESSION_ALL: "/listSessions",
  LIST_SESSION_TENANTS: "/getSession",
  SPEAKER_LIST: "/speakerList",
  UPDATE_SPEAKER: "/updateSpeaker",
  GET_REGISTERED_USERS: "/getRegisteredUsers",
  GET_TOPIC_REGISTERED_USERS:"/getTopicRegisteredUsers",
  APPROVE_USERS: "/approveUsers",
  BULK_SESSION_REGISTRATION: "/bulkSessionRegistration",
  EDIT_SESSION: "/editSession",
  UPLOAD_INVOICE: "/uploadInvoice ",
  GET_FEEDBACK_DETAILS:"/getfeedbackdetails",
  UPDATE_ACTUAL_AMOUNT:"/updateactualamount",
  GET_SESSION_REPORT: "/getSessionReport",
  GET_COMPANY_REPORT: "/getCompanyDetails",
  GET_PARTICIPANT_REPORT: "/getParticipantDetails",
  GET_REVENUE_REPORT: "/getRevenueDetails",
  GET_SESSION_ONDATE: "/getSessionOnDate",
};
