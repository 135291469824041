import React, { useState } from "react";
import Del from "./DeleteSession.module.scss";
import config from "../../../config/aws-exports";
import { API } from "aws-amplify";
import swal from "sweetalert2";
import BackDrop from "@material-ui/core/Backdrop/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

import { Constants } from "../../../config/Constants";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
}));

export default function DeleteSpeaker(props) {
  let { handledeleteSpeakersClose, dataToDelete, setpeakerTable } = props;
  const classes = useStyles();
  const [spin, setSpin] = useState(false);

  console.log("delete", dataToDelete);

  async function handleDelete() {
    setSpin(true);
    const bodyParam = {
      body: {
        atype: 2,
        sp_id: dataToDelete.sp_id,
        schema: Constants.SCHEMA_NAME,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const deleter = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.UPDATE_SPEAKER,

        bodyParam
      );
      handledeleteSpeakersClose();
      setpeakerTable();
      swal
        .fire({
          title: "Success",
          text: deleter.msg,
          icon: "success",
          dangerMode: false,
        })
        .then((willDelete) => {
          if (willDelete) {
            setSpin(false);
          }
        });
      console.log("delete", deleter);

      //setIstLoading(false);
    } catch (error) {
      swal
        .fire({
          title: "Oooops!",
          text: "Soomthing wrong!",
          icon: "warning",
          dangerMode: true,
        })
        .then((willDelete) => {
          if (willDelete) {
            setSpin(false);
          }
        });
      // alert(JSON.stringify(error));
      console.error(error);
    }
  }

  return (
    <div className={Del.mainpopup}>
      <BackDrop sx={{ color: "#fff" }} open={spin} className={classes.backdrop}>
        <CircularProgress color="inherit" />
      </BackDrop>
      <h3 className={Del.heading}>
        Are you sure you want to delete this Speaker?
      </h3>
      <div className={Del.buttonholder}>
        <button className={Del.buttonyes} onClick={() => handleDelete()}>
          Yes
        </button>
        <button className={Del.buttonno} onClick={handledeleteSpeakersClose}>
          No
        </button>
      </div>
    </div>
  );
}
