// Dependencies imports
import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import moment from "moment";

// Style imports
import { Constants } from "../../../config/Constants";

// Component linechart
const DailyLineChart = ({ data, days }) => {
  const [labelDates, setLabelDates] = useState([]); // to passs in the days (week, month, year) for y-axis as labels to the graph
  const [gpData, setGpData] = useState([]); // to passs in the user count as data to the graph

  // To show the week data by default once the component mounts
  useEffect(() => {
    dateFormat();
    return () => {};
  }, []);

  var getDaysArray = function (start, end) {
    for (
      var arr = [], dt = new Date(start);
      dt <= end;
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(new Date(dt));
    }
    return arr;
  };

  function dateFormat() {
    var daylist = getDaysArray(new Date("2021-02-01"), new Date());
    let temp = [];
    for (let i = 0; i < daylist.length; i++) {
      let tempObj = {};
      let formatDate = moment(daylist[i]).format("ddd MMM DD YYYY");
      console.log("formatDate", formatDate);
      tempObj.ts = formatDate;
      if (data[formatDate] !== undefined) {
        tempObj.uc = data[formatDate];
      } else {
        tempObj.uc = 0;
      }
      temp.push(tempObj);
    }
    ConvertData(days, temp);
  }

  function ConvertData(val, tempData) {
    let tarray = [];
    if (tempData.length > val) {
      tarray = tempData.slice(Math.max(tempData.length - val, 0));
    } else {
      tarray = tempData;
    }

    function Dates(stamp) {
      const d = new Date(stamp);
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      console.log(d.getDate() + " " + months[d.getMonth()]);
      return d.getDate() + " " + months[d.getMonth()];
    }

    let tlabelDates = [];
    let tgpData = [];
    // for (let i = 0; i < tarray.length; i++) {
    //     tlabelDates.push(Dates(tarray[i].TS));
    //     tgpData.push(tarray[i].UC);
    // }
    for (let i = 0; i < tarray.length; i++) {
      tlabelDates.push(tarray[i].ts);
      tgpData.push(tarray[i].uc);
    }
    console.log(tlabelDates);
    console.log(tgpData);
    setLabelDates(tlabelDates);
    setGpData(tgpData);
  }

  // data for graph
  const Gdata = {
    type: "Line",
    animation: true,
    labels: labelDates,
    datasets: [
      {
        data: gpData,
        label: "No.of users",
        display: false,
        min: 0,
        max: 1,
        count: 1,
        fill: false,
        backgroundColor: "#fff",
        borderColor: Constants.blue_color,
      },
    ],
  };

  const options = {
    legend: {
      display: true,
      position: "top",
      align: "end",
      maintainAspectRatio: false,
    },
  };

  return (
    <div>
      <div style={{ width: "95%", height: "400px", padding: 20 }}>
        <Line data={Gdata} height={40} width={200} options={options} />
      </div>
    </div>
  );
};

export default DailyLineChart;
