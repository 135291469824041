import React from "react";

const EventSvg = ({ className, cls1, cls2, cls3, cls4 }) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      className={className}
    >
      <defs></defs>

      {/* <path
        className={cls1}
        d="M70.3,53.2H47c-1,0-1.9-1.4-1.9-3.2s.8-3.2,1.9-3.2H70.3c1,0,1.9,1.4,1.9,3.2S71.4,53.2,70.3,53.2Z"
      /> */}
      {/* <path
        className={cls1}
        d="M51.5,21.5V49.9c0,1.3-1.4,2.3-3.2,2.3s-3.2-1-3.2-2.3V21.5c0-1.3,1.4-2.3,3.2-2.3S51.5,20.2,51.5,21.5Z"
      /> */}
      <circle className={cls2} cx="50" cy="52.2" r="34.26" />
      <path
        className={cls1}
        d="M40,40.15c.12-1.48-.22-3,1.12-4A3.78,3.78,0,0,1,44,35.58a3.5,3.5,0,0,1,1.83.74A3.31,3.31,0,0,1,46.74,38a10.15,10.15,0,0,1,0,2.15h6.81c.11-1.49-.18-3,1.24-4.09a3.93,3.93,0,0,1,2.77-.5c2.92.2,2.89,2.37,2.82,4.59h2.22A2.22,2.22,0,0,1,65,42.55q0,11.27,0,22.54a2.26,2.26,0,0,1-2.47,2.44H38a2.26,2.26,0,0,1-2.47-2.44q0-11.23,0-22.47a2.22,2.22,0,0,1,2.42-2.47ZM62.68,47H37.77V65.27H62.68ZM44.55,41c0-.77,0-1.54,0-2.3s-.51-.87-1.12-.86-1.23-.07-1.2.88c0,1.33,0,2.67,0,4s0,1.44,1.5,1.41c.6,0,.82-.26.8-.85C44.52,42.54,44.55,41.77,44.55,41Zm11.35-.1c0,.82,0,1.64,0,2.46,0,.57.24.8.79.77h.73c.51,0,.78-.2.78-.72,0-1.62,0-3.23,0-4.84,0-.47-.2-.72-.68-.72h-.91c-.52,0-.73.25-.71.76C55.92,39.38,55.9,40.14,55.9,40.91Z"
      />
      <path
        className={cls3}
        d="M40,40.15c.12-1.48-.22-3,1.12-4A3.78,3.78,0,0,1,44,35.58a3.5,3.5,0,0,1,1.83.74A3.31,3.31,0,0,1,46.74,38a10.15,10.15,0,0,1,0,2.15h6.81c.11-1.49-.18-3,1.24-4.09a3.93,3.93,0,0,1,2.77-.5c2.92.2,2.89,2.37,2.82,4.59h2.22A2.22,2.22,0,0,1,65,42.55q0,11.27,0,22.54a2.26,2.26,0,0,1-2.47,2.44H38a2.26,2.26,0,0,1-2.47-2.44q0-11.23,0-22.47a2.22,2.22,0,0,1,2.42-2.47ZM62.68,47H37.77V65.27H62.68ZM44.55,41c0-.77,0-1.54,0-2.3s-.51-.87-1.12-.86-1.23-.07-1.2.88c0,1.33,0,2.67,0,4s0,1.44,1.5,1.41c.6,0,.82-.26.8-.85C44.52,42.54,44.55,41.77,44.55,41Zm11.35-.1c0,.82,0,1.64,0,2.46,0,.57.24.8.79.77h.73c.51,0,.78-.2.78-.72,0-1.62,0-3.23,0-4.84,0-.47-.2-.72-.68-.72h-.91c-.52,0-.73.25-.71.76C55.92,39.38,55.9,40.14,55.9,40.91Z"
      />
      <polygon
        className={cls1}
        points="49.84 62.7 47.87 58.35 43.17 57.51 46.7 54.3 46.04 49.57 50.19 51.93 54.48 49.85 53.52 54.52 56.83 57.96 52.09 58.49 49.84 62.7"
      />
    </svg>
  );
};

export default EventSvg;
